import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./index.scss";

const Avatar = (props) => {
  const [showPicture, setShowPicture] = useState(props.avatarUrl);
  useEffect(() => {
    setShowPicture(!!props.avatarUrl);
  }, [props.avatarUrl]);

  return (
    <div
      className={`avatar avatar-${props.size || "medium"} ${
        props.placement === "navbar" ? "navbar-av" : ""
      } avatar__circular`}
      style={{ backgroundColor: `${showPicture ? "transparent" : "#F2EDEB"}` }}
      onClick={props.onClick}
    >
      {showPicture ? (
        <img
          className="avatar-pic "
          src={props.avatarUrl}
          alt=""
          onError={() => setShowPicture(false)}
        />
      ) : (
        <UserInitials initials={props.initials} placement={props.placement} />
      )}
      {props.children}
    </div>
  );
};

const UserInitials = (props) => {
  const firstChar = useSelector(
    (state) =>
      (props.initials && props.initials.charAt(0)) ||
      state.auth.details?.givenName?.charAt(0) ||
      ""
  );

  const secondChar = useSelector(
    (state) =>
      (props.initials && props.initials.charAt(1)) ||
      state.auth.details?.surname?.charAt(0) ||
      ""
  );

  return (
    <span
      className={`avatar__initials ${
        props.placement === "navbar" ? "navbar-av" : ""
      }`}
    >{`${firstChar}${secondChar}`}</span>
  );
};

export default Avatar;
