export const types = {
  LOAD_RESULTS: "LOAD_RESULTS",
  EXIT_RESULTS: "EXIT_RESULTS",
  SET_RESULTS_VIEW: "SET_RESULTS_VIEW",
  SET_RESULTS_CRS_DEF: "SET_RESULTS_CRS_DEF",
  TOGGLE_RESULTS_MAP_EXPORT_MODE: "TOGGLE_RESULTS_MAP_EXPORT_MODE",
  TOGGLE_RESULTS_MAP_POI_MODE: "TOGGLE_RESULTS_MAP_POI_MODE",
  TOGGLE_LAYER_SETTINGS: "TOGGLE_LAYER_SETTINGS",
  TOGGLE_LAYER_SETTINGS_FORM: "TOGGLE_LAYER_SETTINGS_FORM",
  TOGGLE_GEODATA_DOWNLOAD: "TOGGLE_GEODATA_DOWNLOAD",
  TOGGLE_POI: "TOGGLE_POI",
  TOGGLE_POI_VISIBILITY: "TOGGLE_POI_VISIBILITY",
  TOGGLE_POI_CURSOR_DISPLAY: "TOGGLE_POI_CURSOR_DISPLAY",
  GET_POI_CURSOR_COORDS: "GET_POI_CURSOR_COORDS",
  ADD_POI: "ADD_POI",
  SELECT_POI: "SELECT_POI",
  REMOVE_POI: "REMOVE_POI",
  UPDATE_TEMP_POI_INPUT: "UPDATE_TEMP_POI_INPUT",
  UPDATE_POI_POSITION_ON_INPUT_CHANGE: "UPDATE_POI_POSITION_ON_INPUT_CHANGE",
  UPDATE_POI_POSITION_ON_DRAGEND: "UPDATE_POI_POSITION_ON_DRAGEND",
  ON_LAYER_NAME_CHANGE: "ON_LAYER_NAME_CHANGE",

  TOGGLE_AOI: "TOGGLE_AOI",
  START_NEW_AOI: "START_NEW_AOI",
  DELETE_AOI: "DELETE_AOI",
  DRAW_AOI: "DRAW_AOI",
  DRAG_AOI_POINT_WG: "DRAG_AOI_POINT_WG",
  DRAGEND_AOI_POINT_WG: "DRAGEND_AOI_POINT_WG",
  ADD_AOI_POINT_WG: "ADD_AOI_POINT_WG",
  ADD_AOI_COORDS_ROW: "ADD_AOI_COORDS_ROW",
  REMOVE_AOI_COORDS_ROW: "REMOVE_AOI_COORDS_ROW",
  HANDLE_AOI_COORD_INPUT_CHANGE: "HANDLE_AOI_COORD_INPUT_CHANGE",
  SELECT_AOI: "SELECT_AOI",
  ON_AREA_LAYER_NAME_CHANGE: "ON_AREA_NAME_CHANGE",

  SET_RESULTS_GEOTIFF_VISIBILITY: "SET_RESULTS_GEOTIFF_VISIBILITY",
  UPDATE_RESULTS_JSON_ORIGIN: "UPDATE_RESULTS_JSON_ORIGIN",
  SHOW_RESULTS_GEOTIFF_LEGEND: "SHOW_RESULTS_GEOTIFF_LEGEND",
  HIDE_RESULTS_GEOTIFF_LEGEND: "HIDE_RESULTS_GEOTIFF_LEGEND",

  LOAD_LAYER_SETTINGS_JSON: "LOAD_LAYER_SETTINGS_JSON",
  TOGGLE_READ_ONLY_LAYER_FORM: "TOGGLE_READ_ONLY_LAYER_FORM",
  TOGGLE_CREATE_LAYER_FORM: "TOGGLE_CREATE_LAYER_FORM",
  CHANGE_NEW_LAYER_CASE: "CHANGE_NEW_LAYER_CASE",
  CHANGE_NEW_LAYER_TYPE: "CHANGE_NEW_LAYER_TYPE",
  CHANGE_NEW_LAYER_PARAMS: "CHANGE_NEW_LAYER_PARAMS",
  CHANGE_NEW_LAYER_HEIGHT_LEVEL: "CHANGE_NEW_LAYER_HEIGHT_LEVEL",
  CHANGE_NEW_LAYER_TIMESTEP: "CHANGE_NEW_LAYER_TIMESTEP",
  CHANGE_NEW_LAYER_EXCL_BLDGS: "CHANGE_NEW_LAYER_EXCL_BLDGS",
  CHANGE_NEW_LAYER_POSTPROC: "CHANGE_NEW_LAYER_POSTPROC",

  TOGGLE_LAYER_APPEARANCE: "TOGGLE_LAYER_APPEARANCE",
  BRING_GEOTIFF_TO_FRONT: "BRING_GEOTIFF_TO_FRONT",
  BRING_GEOTIFF_FORWARD: "BRING_GEOTIFF_FORWARD",
  BRING_GEOTIFF_BACKWARD: "BRING_GEOTIFF_BACKWARD",

  DELETE_LAYER: "DELETE_LAYER",
  DELETE_PAGE: "DELETE_PAGE",

  CENTER_MAP: "CENTER_MAP",
  CENTER_MAP_SUCCESS: "CENTER_MAP_SUCCESS",

  SET_LAYER_COLOR_PALETTE: "SET_LAYER_COLOR_PALETTE",
  SET_LAYER_CATEGORY_COLOR: "SET_LAYER_CATEGORY_COLOR",
  SET_LAYER_OPACITY: "SET_LAYER_OPACITY",
  SET_LAYER_MIN: "SET_LAYER_MIN",
  SET_LAYER_MAX: "SET_LAYER_MAX",

  CREATE_PAGE: "CREATE_PAGE",
  INVERT_LAYER_COLOR: "INVERT_LAYER_COLOR",

  ADD_NEW_LAYER: "ADD_NEW_LAYER",
  VALIDATE_NEW_PAGE_NAME: "VALIDATE_NEW_PAGE_NAME",

  CHANGE_NEW_LAYER_TYPE_FIELD: "CHANGE_NEW_LAYER_TYPE_FIELD",

  LOAD_NEW_LAYER_DIFF_CASE_DATA: "LOAD_NEW_LAYER_DIFF_CASE_DATA",
  CHANGE_NEW_LAYER_DIFF_CASE: "CHANGE_NEW_LAYER_DIFF_CASE",
  CHANGE_NEW_LAYER_DIFF_PAGE: "CHANGE_NEW_LAYER_DIFF_PAGE",
  CHANGE_NEW_DIFF_LAYER: "CHANGE_NEW_DIFF_LAYER",
  LOAD_NEW_LAYER_DIFF_CHILD_CASE_DATA: "LOAD_NEW_LAYER_DIFF_CHILD_CASE_DATA",
  CHANGE_NEW_LAYER_DIFF_CHILD_CASE: "CHANGE_NEW_LAYER_DIFF_CHILD_CASE",
  CHANGE_NEW_LAYER_DIFF_CHILD_PAGE: "CHANGE_NEW_LAYER_DIFF_CHILD_PAGE",
  CHANGE_NEW_DIFF_CHILD_LAYER: "CHANGE_NEW_DIFF_CHILD_LAYER",
  CHANGE_DIFF_TYPE: "CHANGE_DIFF_TYPE",
  CHANGE_DIFF_UNIT: "CHANGE_DIFF_UNIT",

  CHANGE_SPAT_CASE: "CHANGE_SPAT_CASE",
  CHANGE_SPAT_FILE_TYPE: "CHANGE_SPAT_FILE_TYPE",
  CHANGE_SPAT_PARAMS: "CHANGE_SPAT_PARAMS",
  CHANGE_SPAT_HEIGHT_FROM: "CHANGE_SPAT_HEIGHT_FROM",
  CHANGE_SPAT_HEIGHT_TO: "CHANGE_SPAT_HEIGHT_TO",
  CHANGE_SPAT_TIME_FROM: "CHANGE_SPAT_TIME_FROM",
  CHANGE_SPAT_TIME_TO: "CHANGE_SPAT_TIME_TO",
  CHANGE_SPAT_AREA: "CHANGE_SPAT_AREA",
  CHANGE_SPAT_PROCESSING: "CHANGE_SPAT_PROCESSING",

  CHANGE_CAT_CUSTOM_PAGE: "CHANGE_CAT_CUSTOM_PAGE",
  CHANGE_CAT_CUSTOM_LAYER: "CHANGE_CAT_CUSTOM_LAYER",
  CHANGE_CAT_CUSTOM_NUMBER: "CHANGE_CAT_CUSTOM_NUMBER",
  CHANGE_CAT_CUSTOM_COLOR_PALETTE: "CHANGE_CAT_CUSTOM_COLOR_PALETTE",
  INVERT_CAT_CUSTOM_COLOR_PALETTE: "INVERT_CAT_CUSTOM_COLOR_PALETTE",

  RESET_NEW_LAYER_SETTINGS: "RESET_NEW_LAYER_SETTINGS",

  CHANGE_CUSTOM_LAYER_BREAK_VALUE: "CHANGE_CUSTOM_LAYER_BREAK_VALUE",
  CHANGE_CUSTOM_LAYER_BREAK_NAME: "CHANGE_CUSTOM_LAYER_BREAK_NAME",
  CHANGE_CUSTOM_LAYER_MIN: "CHANGE_CUSTOM_LAYER_MIN",

  CHANGE_WINDFIELD_CASE: "CHANGE_WINDFIELD_CASE",
  CHANGE_WINDFIELD_FILE_TYPE: "CHANGE_WINDFIELD_FILE_TYPE",
  CHANGE_WINDFIELD_PARAMS: "CHANGE_WINDFIELD_PARAMS",
  CHANGE_WINDFIELD_HEIGHT: "CHANGE_WINDFIELD_HEIGHT",
  CHANGE_WINDFIELD_TIMESTEP: "CHANGE_WINDFIELD_TIMESTEP",

  CHANGE_WIND_STREAM_STROKE_WIDTH: "CHANGE_WIND_STREAM_STROKE_WIDTH",
  CHANGE_WIND_STREAM_COLOR: "CHANGE_WIND_STREAM_COLOR",
  CHANGE_WIND_STREAM_PATHS: "CHANGE_WIND_STREAM_PATHS",
  CHANGE_WIND_STREAM_MAX_AGE: "CHANGE_WIND_STREAM_MAX_AGE",
  CHANGE_WIND_STREAM_V_SCALE: "CHANGE_WIND_STREAM_V_SCALE",
  CHANGE_WIND_STREAM_FADE: "CHANGE_WIND_STREAM_FADE",
  CHANGE_WIND_STREAM_OPACITY: "CHANGE_WIND_STREAM_OPACITY",

  CHANGE_WIND_DIR_COLOR: "CHANGE_WIND_DIR_COLOR",
  CHANGE_WIND_DIR_SIZE: "CHANGE_WIND_DIR_SIZE",
  CHANGE_WIND_DIR_OPACITY: "CHANGE_WIND_DIR_OPACITY",

  TOGGLE_EXPORT_SETTINGS: "TOGGLE_EXPORT_SETTINGS",

  CHANGE_PAGE_SIZE: "CHANGE_PAGE_SIZE",
  CHANGE_SCALE: "CHANGE_SCALE",
  CHANGE_TITLE: "CHANGE_TITLE",
  CHANGE_SUBTITLE: "CHANGE_SUBTITLE",
  CHANGE_ADDITIONAL_TEXT: "CHANGE_ADDITIONAL_TEXT",
  CHANGE_AUTHOR: "CHANGE_AUTHOR",
  CHANGE_INSTITUTION: "CHANGE_INSTITUTION",
  CHANGE_ADRESS: "CHANGE_ADRESS",
  CHANGE_CONTACT: "CHANGE_CONTACT",
  CHANGE_DATE: "CHANGE_DATE",
  CHANGE_LAYERS_FOR_EXPORT: "CHANGE_LAYERS_FOR_EXPORT",
  TOGGLE_INCLUDE_NORTH_ARROW: "TOGGLE_INCLUDE_NORTH_ARROW",
  TOGGLE_INCLUDE_SCALE: "TOGGLE_INCLUDE_SCALE",
  TOGGLE_INCLUDE_ASSESMENT_POINTS: "TOGGLE_INCLUDE_ASSESMENT_POINTS",
  CHANGE_NORTH_ARROW_POSITION: "CHANGE_NORTH_ARROW_POSITION",
  CHANGE_SCALE_POSITION: "CHANGE_SCALE_POSITION",
  CHANGE_BACKGROUND_MAP_COLOR: "CHANGE_BACKGROUND_MAP_COLOR",
  CHANGE_BACKGROUND_HEADER_COLOR: "CHANGE_BACKGROUND_HEADER_COLOR",
  CHANGE_AREA_TO_CROP: "CHANGE_AREA_TO_CROP",
  TOGGLE_ARROW_SCALE: "TOGGLE_ARROW_SCALE",
  CHANGE_ARROW_GRIDPOINT_SKIP: "CHANGE_ARROW_GRIDPOINT_SKIP",
  CHANGE_ARROW_LENGTH: "CHANGE_ARROW_LENGTH",
  CHANGE_ARROW_MIN_MAGNITUDE: "CHANGE_ARROW_MIN_MAGNITUDE",
  CHANGE_ARROW_SCALE_POISITION: "CHANGE_ARROW_SCALE_POISITION",
  GET_EXPORT_REQUEST: "GET_EXPORT_REQUEST",

  SET_MAP_PLOT_CENTER_LAT_WG: "SET_MAP_PLOT_CENTER_LAT_WG",
  SET_MAP_PLOT_CENTER_LNG_WG: "SET_MAP_PLOT_CENTER_LNG_WG",
  SET_MAP_PLOT_CENTER_LAT_UTM: "SET_MAP_PLOT_CENTER_LAT_UTM",
  SET_MAP_PLOT_CENTER_LNG_UTM: "SET_MAP_PLOT_CENTER_LNG_UTM",
  SET_INIT_MAP_PLOT_CENTER: "SET_INIT_MAP_PLOT_CENTER",
  TOGGLE_MAP_PLOT_DRAGGABLE: "TOGGLE_MAP_PLOT_DRAGGABLE",
  UPDATE_MAP_PLOT_POSITION: "UPDATE_MAP_PLOT_POSITION",

  CENTER_MAP_PLOT: "CENTER_MAP_PLOT",
  CENTER_MAP_PLOT_SUCCESS: "CENTER_MAP_PLOT_SUCCESS",

  SET_MAP_PLOT_LOGO: "SET_MAP_PLOT_LOGO",
  CLEAR_MAP_PLOT_LOGO: "CLEAR_MAP_PLOT_LOGO",

  TOGGLE_TIME_CHART: "TOGGLE_TIME_CHART",
  TOGGLE_VERTICAL_CHART: "TOGGLE_VERTICAL_CHART",
  CHANGE_CHART_FILE_TYPE: "CHANGE_CHART_FILE_TYPE",
  CHANGE_CHART_PARAMS: "CHANGE_CHART_PARAMS",
  CHANGE_CHART_HEIGHT_LEVEL: "CHANGE_CHART_HEIGHT_LEVEL",
  CHANGE_CHART_TIMESTEP: "CHANGE_CHART_TIMESTEP",
  CHANGE_CHART_POINT: "CHANGE_CHART_POINT",
};
