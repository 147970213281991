import React from "react";
import { withRouter } from "react-router-dom";
import ProjectInfo from "../ProjectInfo/ProjectInfo";
import ApplicationField from "./../ApplicationField/ApplicationField";
import Geodata from "./../Geodata/Geodata";
import WizardForm, {
  Step,
} from "../../../Components/Common/WizardForm/WizardForm";
import { Loader } from "../../../Components/Loader/index";
import CheckFilesLoader from "./CheckFilesLoader";
import { createProject } from "../../../redux/actions/projectsActions";
import {
  initCreateProject,
  checkGeojson,
  convertTopography,
} from "../../../redux/actions/createProjectActions";
import {
  checkGeojsonFile,
  checkGeotiffFile,
  resetCheckFileState,
  resetGeojsonCheckFile,
  resetGeotiffCheckFile,
} from "../../../redux/actions/checkFileActions";
import {
  checkProjectName,
  checkProjectDescription,
  checkProjectPicture,
  checkNewProjectCaseName,
} from "../../../redux/actions/checkNameActions";
import { loadProjects } from "../../../redux/actions/projectsActions";
import { resetCreateCase } from "../../../redux/actions/caseActions";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class NewProjectWizard extends React.Component {
  constructor(props) {
    super();
    this.state = {
      projectName: "",
      information: "",
      picture: "",
      caseName: "",
      caseInformation: "",
      geoTiff: "",
      staticDriver: null,
      geoJson: "",
      applicationFieldId: 0,
      geodataTypeIndex: 0,
      currentStep: 1,
      geoJsonEpsg: null,
      isFileCheckReady: false,
    };

    this.createNewProject = this.createNewProject.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onPictureChange = this.onPictureChange.bind(this);
    this.onGeodataTypeIndexChange = this.onGeodataTypeIndexChange.bind(this);
    this.onGeoTiffChange = this.onGeoTiffChange.bind(this);
    this.onStaticDriverChange = this.onStaticDriverChange.bind(this);
    this.onGeoJsonChange = this.onGeoJsonChange.bind(this);
    this.onEpsgChange = this.onEpsgChange.bind(this);
    this.clearUploadedFile = this.clearUploadedFile.bind(this);
    this.goNextStep = this.goNextStep.bind(this);
    this.goPreviousStep = this.goPreviousStep.bind(this);
  }

  componentDidMount() {
    this.props.resetCreateCase();
    this.props.resetCheckFileState();
    if (this.props.projects?.length === 0) {
      this.props.loadProjects();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.isCreating || this.state.geodataTypeIndex !== 0) {
      return;
    }
    if (
      this.state.geoJson &&
      !this.props.geojsonUploadInProgress &&
      !this.props.geojsonCheckInProgress &&
      !this.props.uploadedGeojsonUrl &&
      !this.props.geojsonReady
    ) {
      this.props.checkGeojson({
        geojson: this.state.geoJson,
        geojsonUrl: `${new Date().getTime().toString()}/${
          this.state.projectName
        }/${this.state.geoJson.name}`,
      });
    }
    if (
      this.state.geoTiff &&
      this.props.geojsonReady &&
      !this.props.topographyReady &&
      !this.props.topographyUploadInProgress &&
      !this.props.topoConversionInProgress &&
      !this.props.uploadedTopographyUrl
    ) {
      this.props.convertTopography({
        topography: this.state.geoTiff,
        topographyUrl: `${new Date().getTime().toString()}/${
          this.state.projectName
        }/${this.state.geoTiff.name}`,
      });
    }

    if (
      this.props.geojsonReady &&
      this.props.topographyReady &&
      (prevProps.geojsonReady !== this.props.geojsonReady ||
        prevProps.topographyReady !== this.props.topographyReady)
    ) {
      this.props.createProject({
        ...this.props.newProject,
        originalGeojsonUrl: this.props.uploadedGeojsonUrl,
        geojsonUrl: this.props.geojsonCheckResultUrl,
        originalTopographyUrl: this.props.uploadedTopographyUrl,
        topographyUrl: this.props.topoConversionResultUrl,
      });
    }
  }

  async createNewProject() {
    let epsg = null;

    if (this.props.checkFile.geojsonEpsg.value !== undefined) {
      epsg = this.props.checkFile.geojsonEpsg.value;
    } else {
      epsg = this.props.checkFile.geojsonEpsg;
    }

    if (this.props.checkFile.geojsonName === "") {
      epsg = this.props.osmCrs.value.split(":").pop();
    }
    
    let newProject = {
      projectName: this.state.projectName,
      caseName: this.state.caseName,
      caseInformation: this.state.caseInformation,
      type: this.state.geodataTypeIndex,
      description: this.state.information,
      picture: this.state.picture,
      applicationField: this.state.applicationFieldId,
      epsg: epsg,
    };

    switch (this.state.geodataTypeIndex) {
      case 0: {
        newProject.geoJson = this.state.geoJson;
        newProject.geoTiff = this.state.geoTiff;
        break;
      }
      case 1: {
        newProject.staticDriver = this.state.staticDriver;
        break;
      }
      default:
        break;
    }

    if (this.state.geodataTypeIndex === 1) {
      this.props.createProject(newProject);
      return;
    }

    const folderName = new Date().getTime().toString();
    let geojsonUrl = "";
    if (newProject.geoJson?.name) {
      geojsonUrl = `${folderName}/${newProject.caseName}/${newProject.geoJson.name}`;
      newProject.geojsonUrl = geojsonUrl;
    }
    let topographyUrl = "";
    if (newProject.geoTiff?.name) {
      topographyUrl = `${folderName}/${newProject.caseName}/${newProject.geoTiff.name}`;
      newProject.topographyUrl = topographyUrl;
    }

    this.props.initCreateProject({
      geojsonUrl: geojsonUrl,
      topographyUrl: topographyUrl,
      newProject: newProject,
    });
    this.props.resetCheckFileState();
  }

  onFileCheckStatusChange(isFileCheckReady) {
    this.setState({ isFileCheckReady: isFileCheckReady });
  }

  onInputChange(e) {
    const { name, value } = e.target;

    if (name === "projectName") {
      this.props.checkProjectName({
        projectName: value,
        projects: this.props.projects,
      });
    } else if (name === "information") {
      this.props.checkProjectDescription(value);
    } else if (name === "caseName") {
      this.props.checkNewProjectCaseName(value);
    }
    if (value === " ") {
      return;
    }
    this.setState({
      [name]: value,
    });
  }

  onPictureChange(file) {
    if (!file) {
      return;
    } else if (file.type === "image/png" || file.type === "image/jpeg") {
      this.setState({ picture: file });
    } else {
      this.setState({
        picture: {
          name: file.name,
          size: null,
        },
      });
    }
    this.props.checkProjectPicture(file);
  }

  onGeoTiffChange(file) {
    this.props.checkGeotiffFile(file);
    if (!file) {
      return;
    } else if (file.type === "image/tiff") {
      this.setState({ geoTiff: file });
    } else {
      this.setState({
        geoTiff: {
          name: file.name,
          size: null,
        },
      });
    }
  }

  async onGeoJsonChange(file) {
    if (!file) {
      return;
    } else if (
      file.type === "application/json" ||
      file.type === "" ||
      file.type === "application/geo+json"
    ) {
      this.setState({ geoJson: file });
    } else {
      this.setState({
        geoJson: {
          name: file.name,
          size: null,
        },
      });
    }
    const geojsonString = await file.text();

    if (
      file.type === "application/json" ||
      file.name.substr(-7) === "geojson"
    ) {
      const geojsonParsed = JSON.parse(geojsonString);
      const epsg = geojsonParsed.crs?.properties?.name?.split(":").pop();
      if (epsg !== undefined) {
        this.props.checkGeojsonFile({ geojson: file, epsg: epsg });
      } else {
        this.props.checkGeojsonFile({ geojson: file, epsg: "" });
      }
    } else {
      this.props.checkGeojsonFile({ geojson: file, epsg: "" });
    }
  }

  onEpsgChange(geoJsonEpsg) {
    this.setState({ geoJsonEpsg: geoJsonEpsg });
  }

  onStaticDriverChange(file) {
    if (!file) {
      return;
    } else if (file.name?.endsWith(".nc")) {
      this.setState({ staticDriver: file });
    }
  }

  onGeodataTypeIndexChange(geodataTypeIndex) {
    this.setState({ geodataTypeIndex });
  }

  clearUploadedFile(fieldName) {
    if (fieldName === "geoTiff") {
      this.props.resetGeotiffCheckFile();
    } else if (fieldName === "geoJson") {
      this.props.resetGeojsonCheckFile();
    } else if (fieldName === "picture") {
      this.props.checkProjectPicture(null);
    }
    this.setState({
      [fieldName]: "",
    });
  }

  goPreviousStep() {
    this.setState({ currentStep: this.state.currentStep - 1 });
  }

  goNextStep() {
    this.setState({ currentStep: this.state.currentStep + 1 });
  }

  render() {
    const { t } = this.props;
    if (this.props.isCreating) {
      const steps = [];
      if (this.state.geoJson?.name) {
        steps.push({
          text: t("UploadingGeojson"),
          active: this.props.geojsonUploadInProgress,
        });
        steps.push({
          text: t("GeojsonCheckInProgress"),
          active: this.props.geojsonCheckInProgress,
        });
      }
      if (this.state.geoTiff?.name) {
        steps.push({
          text: t("UploadingTopography"),
          active: this.props.topographyUploadInProgress,
        });
        steps.push({
          text: t("TopoConvInProgress"),
          active: this.props.topoConversionInProgress,
        });
      }
      return steps.length > 0 ? <CheckFilesLoader steps={steps} /> : <Loader />;
    }
    return (
      <>
        <WizardForm>
          <Step
            isActive={this.state.currentStep === 1 ? true : false}
            text={t("ProjectInformation")}
            currentStep={this.state.currentStep}
          ></Step>
          <Step
            isActive={this.state.currentStep === 2 ? true : false}
            text={t("CreateCase")}
            currentStep={this.state.currentStep}
          ></Step>
          <Step
            isActive={this.state.currentStep === 3 ? true : false}
            text={t("UploadData")}
            currentStep={this.state.currentStep}
          ></Step>
        </WizardForm>
        {this.state.currentStep === 1 && (
          <ProjectInfo
            projectName={this.state.projectName}
            information={this.state.information}
            picture={this.state.picture}
            onInputChange={this.onInputChange}
            onPictureChange={this.onPictureChange}
            clearUploadedFile={this.clearUploadedFile}
            goNextStep={this.goNextStep}
          />
        )}
        {this.state.currentStep === 2 && (
          <ApplicationField
            caseName={this.state.caseName}
            caseInformation={this.state.caseInformation}
            applicationFieldId={this.state.applicationFieldId}
            onInputChange={this.onInputChange}
            goNextStep={this.goNextStep}
            goPreviousStep={this.goPreviousStep}
          />
        )}
        {this.state.currentStep === 3 && (
          <Geodata
            geoJson={this.state.geoJson}
            geoTiff={this.state.geoTiff}
            submit={this.createNewProject}
            onGeoJsonChange={this.onGeoJsonChange}
            onGeoTiffChange={this.onGeoTiffChange}
            onGeodataTypeIndexChange={this.onGeodataTypeIndexChange}
            onEpsgChange={this.onEpsgChange}
            clearUploadedFile={this.clearUploadedFile}
            goPreviousStep={this.goPreviousStep}
            applicationField={this.state.applicationFieldId}
            staticDriver={this.state.staticDriver}
            onStaticDriverChange={this.onStaticDriverChange}
            errors={this.props.errors}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    newProject: state.createProject.newProject,
    isCreating: state.createProject.isCreating,
    geojsonCheckResultUrl: state.createProject.geojsonCheckResultUrl,
    geojsonCheckInProgress: state.createProject.geojsonCheckInProgress,
    topoConversionInProgress: state.createProject.topoConversionInProgress,
    topoConversionResultUrl: state.createProject.topoConversionResultUrl,
    geojsonUploadInProgress: state.createProject.geojsonUploadInProgress,
    topographyUploadInProgress: state.createProject.topographyUploadInProgress,
    topographyReady: state.createProject.topographyReady,
    geojsonReady: state.createProject.geojsonReady,
    uploadedGeojsonUrl: state.createProject.uploadedGeojsonUrl,
    uploadedTopographyUrl: state.createProject.uploadedTopographyUrl,
    errors: state.createProject.errors,
    checkFile: state.checkFile,
    projects: state.projects.projects,
    osmCrs: state.tools.osmCrs,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createProject: (payload) => dispatch(createProject(payload)),
    initCreateProject: (payload) => dispatch(initCreateProject(payload)),
    checkGeojson: (geojson) => dispatch(checkGeojson(geojson)),
    convertTopography: (topo) => dispatch(convertTopography(topo)),
    resetCreateCase: () => dispatch(resetCreateCase()),
    checkGeojsonFile: (payload) => dispatch(checkGeojsonFile(payload)),
    checkGeotiffFile: (payload) => dispatch(checkGeotiffFile(payload)),
    resetGeojsonCheckFile: () => dispatch(resetGeojsonCheckFile()),
    resetGeotiffCheckFile: () => dispatch(resetGeotiffCheckFile()),
    resetCheckFileState: () => dispatch(resetCheckFileState()),
    checkProjectName: (payload) => dispatch(checkProjectName(payload)),
    checkProjectDescription: (payload) =>
      dispatch(checkProjectDescription(payload)),
    checkProjectPicture: (payload) => dispatch(checkProjectPicture(payload)),
    checkNewProjectCaseName: (payload) =>
      dispatch(checkNewProjectCaseName(payload)),
    loadProjects: () => dispatch(loadProjects()),
  };
};

export default withRouter(
  withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(NewProjectWizard)
  )
);
