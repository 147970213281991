import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as actions from "../../../../redux/actions/authActions";
import Avatar from "../../../../Components/Avatar";
import "./DropdownUserContent.scss";

class DropdownUserContent extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    document.addEventListener(
      "click",
      this.handleClickOutsideDropdownUserContent,
      true
    );
  }

  componentWillUnmount() {
    document.removeEventListener(
      "click",
      this.handleClickOutsideDropdownUserContent,
      true
    );
  }

  handleClickOutsideDropdownUserContent = (event) => {
    const domNode = ReactDOM.findDOMNode(this);
    if (
      (!domNode || !domNode.contains(event.target)) &&
      !event.target.classList.contains("navbar-av")
    ) {
      this.props.hideDropdownUserContent();
    }
  };

  render() {
    const { t } = this.props;
    return (
      <div className="dropdown-user-wrapper">
        <div className="dropdown-logo-wrapper">
          <Avatar avatarUrl={this.props.pictureUrl} size="medium" />
        </div>
        <div className="dropdown-user-data-wrapper">
          <p className="dropdown-data dropdown-surname">{`${this.props.givenName} ${this.props.surname}`}</p>
        </div>
        <div className=" dropdown-mail-wrapper dropdown-user-data-wrapper">
          <p className="dropdown-data dropdown-email">{`${this.props.mail}`}</p>
        </div>
        <Link className="dropdown-option__link" to={{ pathname: "/settings" }}>
          <div
            className="dropdown-option-wrapper "
            onClick={() => this.props.hideDropdownUserContent()}
          >
            <p className="dropdown-data">{t("MyProfile")}</p>
          </div>
        </Link>
        <hr className="dropdown-ruler" />
        <div
          className="dropdown-option-wrapper sign-out-wrapper"
          onClick={this.props.signOut}
        >
          <p className="dropdown-data">{t("SignOut")}</p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    givenName: state.auth.details.givenName,
    surname: state.auth.details.surname,
    mail: state.auth.details.mail,
    pictureUrl: state.userSettings?.settings?.pictureDownloadUrl,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(actions.logoutUser()),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(DropdownUserContent)
);
