import React from "react";
import { ArrowLeftCircle } from "../../../../../../node_modules/react-feather/dist/index";
import LayerValue from "./LayerValue/LayerValue";
import CategoryLayer from "./CategoryLayer/CategoryLayer";
import WindStreamLayer from "./WindStreamLayer/WindStreamLayer";
import WindDirLayer from "./WindDirLayer/WindDirLayer";
import { toggleLayerAppearance } from "../../../../../redux/actions/resultsActions";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import "../../../../../Components/Common/PaletteDropdown/index.scss";

class LayerAppearance extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { t } = this.props;
    return (
      <>
        {this.props.jsonOriginPartSelected && (
          <>
            <h3
              className="raster-settings-header"
              style={{ marginBottom: "20px" }}
            >{`${t("LayerAppearance")}`}</h3>
            {this.props.jsonOriginPartSelected.plot_preset_cols ? (
              <CategoryLayer />
            ) : this.props.jsonOriginPartSelected.type === "wind_stream" ? (
              <WindStreamLayer />
            ) : this.props.jsonOriginPartSelected.type === "wind_dir" ? (
              <WindDirLayer />
            ) : (
              <LayerValue />
            )}

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button
                className="raster-settings-btn"
                onClick={this.props.toggleLayerAppearance}
              >
                <div className="btn-content-wrapper">
                  <ArrowLeftCircle
                    color={"#494949"}
                    size={"16px"}
                    strokeWidth={"2px"}
                  />
                  <div className="raster-btn-lbl">{t("Back")}</div>
                </div>
              </button>
            </div>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    jsonOriginPartSelected: state.results.jsonOriginPartSelected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleLayerAppearance: () => dispatch(toggleLayerAppearance()),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(LayerAppearance)
);
