import React from "react";
import Avatar from "../../../../Components/Avatar";
import DropdownUserContent from "../DropdownUserContent/DropdownUserContent";
import Notifications from "../Notifications";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import "./LoginWrapper.scss";

class LoginWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDropdownUserContentVisible: false,
    };
    this.toggleDropdownUserContentVisibility = this.toggleDropdownUserContentVisibility.bind(
      this
    );
    this.hideDropdownUserContent = this.hideDropdownUserContent.bind(this);
  }

  toggleDropdownUserContentVisibility() {
    this.setState((prevState) => ({
      isDropdownUserContentVisible: !prevState.isDropdownUserContentVisible,
    }));
  }

  hideDropdownUserContent() {
    this.setState({ isDropdownUserContentVisible: false });
  }

  render() {
    const { t } = this.props;
    return (
      <div className="login-wrapper">
        <div
          style={{
            color: "white",
            fontFamily: "Segoe UI",
            fontSize: "12px",
            letterSpacing: "0.1px",
            cursor: "default",
          }}
        >
          {t("ApplicationStillInDevelopment")}
        </div>
        <Notifications />
        {this.state.isDropdownUserContentVisible && (
          <DropdownUserContent
            hideDropdownUserContent={this.hideDropdownUserContent}
          />
        )}
        <Avatar
          size="small"
          avatarUrl={this.props.pictureUrl}
          onClick={this.toggleDropdownUserContentVisibility}
          placement={"navbar"}
        />
        {this.props.children}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pictureUrl: state.userSettings?.settings?.pictureDownloadUrl,
  };
};

export default connect(mapStateToProps, null)(withTranslation()(LoginWrapper));
