import React from "react";
import SidebarButton from "./SidebarButton/SidebarButton";
import "./Sidebar.scss";

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div
          className={`panel 
          panel-${this.props.isSidebarVisible ? "table" : "hidden"}
          ${
            (this.props.inTreeDrawingMode || this.props.inPolygonDrawingMode) &&
            this.props.isSidebarVisible
              ? "panel-tree"
              : ""
          }
          ${
            (this.props.inTreeDrawingMode || this.props.inPolygonDrawingMode) &&
            !this.props.isSidebarVisible
              ? "panel-hidden"
              : ""
          }

          panel-${this.props.inResultsMap ? "results-map" : ""}
        `}
        >
          {this.props.children}
        </div>
        <SidebarButton
          inResultsMap={this.props.inResultsMap}
          handleClick={this.props.handleSidebarButtonClick}
          isSidebarVisible={this.props.isSidebarVisible}
        />
      </>
    );
  }
}
export default Sidebar;
