import React, { Component } from "react";
import { updateCheckboxExpertTable } from "../../../../redux/actions/expertActions";
import { connect } from "react-redux";

class CheckboxRenderer extends Component {
  constructor(props) {
    super(props);
    this.checkedHandler = this.checkedHandler.bind(this);
  }
  checkedHandler(event) {
    const checked = event.target.checked;
    const colId = this.props.column.colId;

    this.props.node.setDataValue(colId, checked);

    this.props.updateCheckboxExpertTable({
      page: this.props.colDef.page,
      subpage: this.props.colDef.subpage,
      key: this.props.colDef.key,
      rowIndex: this.props.rowIndex,
      colId: this.props.column.colId,
      checked: checked,
    });
  }

  render() {
    const { rowIndex } = this.props;
    return (
      <input
        type="checkbox"
        onClick={this.checkedHandler}
        checked={this.props.value}
        disabled={
          !this.props.colDef.editable ||
          (this.props.colDef.subpage === "land_surface" &&
            ((this.props.data.meaning === "Soil temperature" &&
              rowIndex === 11) ||
              (this.props.data.meaning === "Volumetric soil moisture" &&
                rowIndex === 6))) ||
          (this.props.colDef.subpage === "general" &&
            (rowIndex === 1 ||
              rowIndex === 3 ||
              rowIndex === 4 ||
              rowIndex === 7 ||
              rowIndex === 8 ||
              rowIndex === 10 ||
              rowIndex === 11 ||
              rowIndex === 13 ||
              rowIndex === 16 ||
              rowIndex === 18 ||
              rowIndex === 23 ||
              rowIndex === 25 ||
              rowIndex === 26 ||
              rowIndex === 27 ||
              rowIndex === 28 ||
              rowIndex === 29 ||
              rowIndex === 30 ||
              rowIndex === 31 ||
              rowIndex === 32 ||
              rowIndex === 33 ||
              rowIndex === 34 ||
              rowIndex === 45 ||
              rowIndex === 47 ||
              rowIndex === 48) &&
            (this.props.colDef.headerName === "mask_tf" ||
              this.props.colDef.headerName === "3D" ||
              this.props.colDef.headerName === "POI_pr" ||
              this.props.colDef.headerName === "POI_ts")) ||
          (this.props.colDef.subpage === "land_surface" &&
            (rowIndex === 6 ||
              rowIndex === 12 ||
              ((rowIndex === 1 ||
                rowIndex === 2 ||
                rowIndex === 3 ||
                rowIndex === 4 ||
                rowIndex === 5 ||
                rowIndex === 6 ||
                rowIndex === 7 ||
                rowIndex === 8 ||
                rowIndex === 9 ||
                rowIndex === 10 ||
                rowIndex === 11) &&
                (this.props.colDef.headerName === "mask_tf" ||
                  this.props.colDef.headerName === "3D" ||
                  this.props.colDef.headerName === "POI_pr" ||
                  this.props.colDef.headerName === "POI_ts")))) ||
          (this.props.colDef.subpage === "radiation" &&
            (rowIndex === 0 ||
              rowIndex === 1 ||
              rowIndex === 2 ||
              rowIndex === 3 ||
              rowIndex === 4) &&
            (this.props.colDef.headerName === "mask_tf" ||
              this.props.colDef.headerName === "3D" ||
              this.props.colDef.headerName === "POI_pr" ||
              this.props.colDef.headerName === "POI_ts")) ||
          (this.props.colDef.subpage === "radiation" &&
            this.props.radiation_scheme !== "rrtmg" &&
            (rowIndex === 5 ||
              rowIndex === 6 ||
              rowIndex === 7 ||
              rowIndex === 8 ||
              rowIndex === 9 ||
              rowIndex === 10 ||
              rowIndex === 11 ||
              rowIndex === 12)) ||
          (this.props.colDef.subpage === "radiation" &&
            this.props.radiation_scheme === "rrtmg" &&
            (rowIndex === 5 ||
              rowIndex === 6 ||
              rowIndex === 7 ||
              rowIndex === 8 ||
              rowIndex === 9 ||
              rowIndex === 10 ||
              rowIndex === 11 ||
              rowIndex === 12) &&
            (this.props.colDef.headerName === "POI_pr" ||
              this.props.colDef.headerName === "POI_ts")) ||
          (this.props.colDef.subpage === "radiation" &&
            this.props.radiation_interactions_on !== true &&
            (rowIndex === 13 || rowIndex === 14 || rowIndex === 15)) ||
          (this.props.colDef.subpage === "radiation" &&
            this.props.radiation_interactions_on === true &&
            (rowIndex === 13 || rowIndex === 14 || rowIndex === 15) &&
            (this.props.colDef.headerName === "POI_pr" ||
              this.props.colDef.headerName === "POI_ts"))
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    radiation_scheme:
      state.expert.expertJson.modules.radiation.parameters.radiation_scheme,
    radiation_interactions_on:
      state.expert.expertJson.modules.radiation.parameters
        .radiation_interactions_on,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCheckboxExpertTable: (payload) =>
      dispatch(updateCheckboxExpertTable(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckboxRenderer);
