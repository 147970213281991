import React, { useRef, useState } from "react";
import ExpertMenuAccordion from "./ExpertMenuAccordion";
import { Loader } from "../../../Components/Loader/index";
import { CheckCircle, XCircle } from "react-feather";
import { postCaseSetup } from "../../../api/simulationApi";
import { getDownloadLink } from "../../../api/storageApi";
import { uploadBlobToAzure, downloadBlobAsync } from "../../../api/storageApi";
import { expertPages, expertSubpages } from "../expertConst";
import {
  changeExpertPage,
  loadExpertJson,
} from "../../../redux/actions/expertActions";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const runtimeTemplate = [
  "Constant Profiles",
  "Dynamic Upload",
  "Dynamic Wind Profile",
];

const ExpertMenu = () => {
  const dispatch = useDispatch();
  const fileInputRef = useRef();
  const projectId = useSelector((state) => state.projects?.loadedProject?.id);
  const currentCaseId = useSelector((state) => state.projects?.currentCaseId);
  const currentCase = useSelector((state) => state.projects?.currentCase);
  const currentExpertPage = useSelector(
    (state) => state.expert?.currentExpertPage
  );
  const expertJson = useSelector((state) => state.expert.expertJson);
  const runtimeTemplateIndex = useSelector(
    (state) =>
      state.expert?.expertJson?.initialization?.runtime_template.template
  );
  const childRasterParameters = useSelector(
    (state) => state.projects?.currentCase?.childRasterParameters
  );
  const childRasterParamsOnMount = useSelector(
    (state) => state.rasterArea?.currentCase?.childRasterParameters
  );

  const [isSetupSaving, setIsSaving] = useState(false);
  const [isSaveCompleted, setIsSaveCompleted] = useState(false);

  const files = useSelector((state) => state.projects?.loadedProject?.files);

  const downloadJsonFile = (jsonString, filename) => {
    const dataUrl =
      "data:text/json;charset=utf-8," + encodeURIComponent(jsonString);
    const downloadAnchorElem = document.createElement("a");
    downloadAnchorElem.setAttribute("href", dataUrl);
    downloadAnchorElem.setAttribute("download", filename);
    document.body.appendChild(downloadAnchorElem);
    downloadAnchorElem.click();
    document.body.removeChild(downloadAnchorElem);
  };

  const exportTemplate = async () => {
    if (!childRasterParameters && !childRasterParamsOnMount) {
      expertJson.grid.single_domain.isActive = true;
    } else {
      expertJson.grid.nested_domain.isActive = true;
    }

    if (
      expertJson.initialization.template_dynamic_upload.name_dynamic_driver !==
      "no files selected"
    ) {
      const ncFileName =
        expertJson.initialization.template_dynamic_upload.name_dynamic_driver;

      const ncUrl = files.find((x) => {
        return x.name === ncFileName;
      }).path;

      const ncDownloadLink = await getDownloadLink(ncUrl);

      expertJson.initialization.template_dynamic_upload.url_dynamic_driver =
        ncDownloadLink;
    }
    console.log(JSON.stringify(expertJson));

    downloadJsonFile(JSON.stringify(expertJson), "expert_settings.json");
  };

  const saveTemplate = async () => {
    setIsSaving(true);
    if (!childRasterParameters && !childRasterParamsOnMount) {
      expertJson.grid.single_domain.isActive = true;
    } else {
      expertJson.grid.nested_domain.isActive = true;
    }

    if (
      expertJson.initialization.template_dynamic_upload.name_dynamic_driver !==
      "no files selected"
    ) {
      const ncFileName =
        expertJson.initialization.template_dynamic_upload.name_dynamic_driver;

      const ncUrl = files.find((x) => {
        return x.name === ncFileName;
      }).path;

      const ncDownloadLink = await getDownloadLink(ncUrl);

      expertJson.initialization.template_dynamic_upload.url_dynamic_driver =
        ncDownloadLink;
    }
    try {
      const setup = {
        caseId: currentCaseId,
        settings: {
          initialization_parameters: {},
          runtime_parameters: {},
          expert_parameters: expertJson,
        },
      };
      console.log(JSON.stringify(setup, null, 2));
      await postCaseSetup(setup);
      setIsSaveCompleted(true);
      setTimeout(() => setIsSaveCompleted(false), 5000);
    } catch (err) {
      console.log(err);
    } finally {
      setIsSaving(false);
    }
  };

  const importTemplate = async () => {
    fileInputRef.current.click();
  };

  const onChangeFile = async (e) => {
    e.stopPropagation();
    e.preventDefault();

    const file = e.target.files[0];
    const extensionName = file.name.split(".").pop();

    if (extensionName !== "json") {
      return;
    }
    const expertSetupUrl = `${projectId}/${currentCaseId}/"expert_settings.json"`;
    await uploadBlobToAzure(expertSetupUrl, file);
    const importedExpertSetup = await downloadBlobAsync(expertSetupUrl);
    dispatch(loadExpertJson(importedExpertSetup.data));
  };

  const { t } = useTranslation();

  return (
    <div
      className="building-accordion-wrapper"
      style={{
        height: "fit-content",
        width: "300px",
        margin: "30px",
      }}
    >
      <button
        className="raster-settings-btn"
        style={{ width: "100%", margin: 0 }}
        onClick={importTemplate}
        disabled={!currentCase.staticDriverJob?.resultUrl}
      >
        {t("Import Template")}
      </button>
      <input
        type="file"
        id="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        accept="application/JSON"
        onChange={onChangeFile}
      />

      <button
        className="raster-settings-btn"
        style={{ width: "100%", marginTop: "12px" }}
        onClick={exportTemplate}
        disabled={!currentCase.staticDriverJob?.resultUrl}
      >
        {t("Export Template")}
      </button>
      <button
        className="raster-settings-btn save-expert-btn"
        onClick={saveTemplate}
        style={{
          display: "flex",
          justifyContent: "center",
          maxHeight: 29.3333,
        }}
        disabled={!currentCase.staticDriverJob?.resultUrl}
      >
        {isSetupSaving && <Loader size="x-small" mode="light" />}
        {!isSetupSaving && t("Save")}
      </button>

      {isSaveCompleted && (
        <div
          className="setup-save-status-wrapper"
          style={{
            padding: 4,
            paddingBottom: 2,
            borderRadius: 4,
            paddingLeft: 8,
            // marginBottom: 8,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CheckCircle width="14" stroke="rgb(57, 204, 73)" strokeWidth="2" />
          <div
            className="save-status-text"
            style={{
              fontWeight: 600,
              color: "rgb(57, 204, 73)",
              marginLeft: "4px",
              fontSize: "12px",
              fontFamily: "Segoe UI",
            }}
          >
            {t("Setup has been saved")}
          </div>
        </div>
      )}
      {currentCase?.staticDriverJob?.status !== 2 &&
        currentCase?.staticDriverJob?.status !== "Completed" && (
          <div
            className="setup-save-status-wrapper"
            style={{ marginTop: 20, marginBottom: 10 }}
          >
            <div style={{ minWidth: 14 }}>
              <XCircle size="14" stroke="#a81d1dcf" strokeWidth="2" />
            </div>
            <div
              className="save-status-disabled"
              style={{ fontSize: "12px", fontFamily: "Segoe UI" }}
            >
              {t("SavingSetupDisabledNoStaticDriver")}
            </div>
          </div>
        )}
      <div
        className="acc-exp-sec exp-acc-sec"
        style={{ justifyContent: "flex-start" }}
      >
        <span
          className={`acc-exp-sec_btn`}
          onClick={() =>
            dispatch(
              changeExpertPage({
                currentExpertSection: "Summary",
                currentExpertPage: null,
              })
            )
          }
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
            className={`${
              currentExpertPage === "Summary"
                ? "expert-subpage-tree-selected"
                : ""
            }`}
          >
            {t("Summary")}
          </div>
        </span>
      </div>
      {expertPages.map((currentExpertSection, i) => {
        return (
          <ExpertMenuAccordion title={currentExpertSection}>
            <div className="expert-subpage-tree-container">
              {expertSubpages[i].map((pageName) => {
                if (i === 0) {
                  if (
                    pageName === "Nested Domain" &&
                    !childRasterParameters &&
                    !childRasterParamsOnMount
                  ) {
                    return;
                  } else if (
                    pageName === "Single Domain" &&
                    (childRasterParameters || childRasterParamsOnMount)
                  ) {
                    return;
                  }
                }
                if (i === 1 && pageName !== "Runtime Template") {
                  pageName = runtimeTemplate[runtimeTemplateIndex - 1];
                } else if (
                  i === 3 &&
                  (pageName === "POI:Vertical Profile Chart" ||
                    pageName === "POI:Timeseries")
                ) {
                  return (
                    <div
                      className={`expert-subpage-tree expert-subpage-tree-disabled`}
                      // onClick={() =>
                      //   dispatch(
                      //     changeExpertPage({
                      //       currentExpertSection: currentExpertSection,
                      //       currentExpertPage: pageName,
                      //     })
                      //   )
                      // }
                    >
                      {pageName}
                    </div>
                  );
                }

                return (
                  <div
                    className={`expert-subpage-tree ${
                      pageName === currentExpertPage
                        ? "expert-subpage-tree-selected"
                        : ""
                    }`}
                    onClick={() =>
                      dispatch(
                        changeExpertPage({
                          currentExpertSection: currentExpertSection,
                          currentExpertPage: pageName,
                        })
                      )
                    }
                  >
                    {pageName}
                  </div>
                );
              })}
            </div>
          </ExpertMenuAccordion>
        );
      })}
    </div>
  );
};

export default ExpertMenu;
