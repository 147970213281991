import React from "react";
import Select from "react-select";
import { CheckCircle, XCircle } from "react-feather";
import { FormSettingsSeparator } from "../../Components/Common/Form/FormSeparator";
import { Loader } from "../../Components/Loader";
import { EmissionsFactorTable, PointEmissionsTable } from "./ChemistryTable";
import { emissionsSubtypes } from "../../Components/Map/consts/consts";
import { windMultiDirections } from "../../Components/Map/consts/consts";
import { getCaseSetup, postCaseSetup } from "../../api/simulationApi";
import { calculateRasterCenterWgFromDb } from "../Domain/raster-area-helpers/index";
import { selectCaseSimulationNotifications } from "../../redux/selectors/simulationSelector";
import { selectSetupStyle } from "../../Components/Common/PaletteDropdown/index";
import {
  setChSeason,
  setChStartDay,
  setChDuration,
  setChAirTemperatureSurface,
  setChRelHumidity,
  setChWindSpeed,
  setChWindDirection,
  setChMeanTemperature,
  setChAmplitude,
  setChSoilMoisture,
  setChSoilTemperature,
  setChUserDefinedSoilMoisture,
  setChUserDefinedSoilTemperatue,
  setChInputPresets,
  setChOutputPresets,
  setSurfaceCsFlux25,
  setSurfaceCsFlux10,
  setChOutputParameters,
  setChMapOutputInterval,
  setChVerticalOutputInterval,
  setChTimeSeriesOutputInterval,
  setChVerticalPoi,
  setChTimeSeriesPoi,
  setChInitialPoiList,
  setChAssesmentTimeFrom,
  setChAssesmentTimeTo,
  setPointEmissionTableType,
  loadChemistrySettings,
  resetChemistrySettings,
  setChPoiOptions,
} from "../../redux/actions/chemistryActions";
import { downloadBlobAsync } from "../../api/storageApi";
import { cloneDeep } from "lodash";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class Chemistry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      saveCompleted: false,
      savedStaticDriver: false,
      isLoaded: false,
    };

    this.saveCaseSetup = this.saveCaseSetup.bind(this);
  }

  async componentDidMount() {
    if (!this.props.currentCase) {
      return;
    }

    const chemistryCaseSetup = await getCaseSetup(this.props.currentCaseId);
    if (typeof chemistryCaseSetup === "string") {
      this.props.resetChemistrySettings();
    } else {
      this.props.loadChemistrySettings(chemistryCaseSetup.setup.settings);
    }

    const geoJsonUrl = this.props.currentCase.geojsonUrl;
    const drawnGeoJsonUrl = this.props.currentCase.drawnGeojsonUrl;
    const polygonDataUrl = this.props.currentCase.polygonDataUrl;

    const mapPoiGeoJsonFeature = [];

    if (drawnGeoJsonUrl) {
      const drawnGeoJsonResponse = await downloadBlobAsync(drawnGeoJsonUrl);

      const drawnGeoJson = drawnGeoJsonResponse.data;
      drawnGeoJson.features.map((x) => {
        if (x.properties.t === 9) {
          mapPoiGeoJsonFeature.push({
            value: `POI ${x.properties.id}`,
            label: `POI ${x.properties.id}`,
          });
        }
      });
    }

    if (geoJsonUrl) {
      const geoJsonResponse = await downloadBlobAsync(geoJsonUrl);
      const geoJson = geoJsonResponse.data;

      geoJson.features.map((x) => {
        if (x.properties.t === 9) {
          mapPoiGeoJsonFeature.push({
            value: `POI ${x.properties.id}`,
            label: `POI ${x.properties.id}`,
          });
        }
      });
    }

    if (!!polygonDataUrl) {
      const polygonDataResponse = await downloadBlobAsync(polygonDataUrl);
      const polygonData = polygonDataResponse.data;

      polygonData.map((x, i) => {
        if (
          x.name &&
          mapPoiGeoJsonFeature.map((y) => {
            return y.value.includes(x.id.toString());
          })
        ) {
          const poiId = x.id;

          const indexPoiGeoJsonFeature = mapPoiGeoJsonFeature.findIndex((z) => {
            return z.value.includes(poiId.toString());
          });
          if (indexPoiGeoJsonFeature > -1) {
            mapPoiGeoJsonFeature[indexPoiGeoJsonFeature].value =
              polygonData[i].name;
            mapPoiGeoJsonFeature[indexPoiGeoJsonFeature].label =
              polygonData[i].name;
          }
        }
      });
    }

    if (typeof chemistryCaseSetup === "string") {
      this.props.setChInitialPoiList(mapPoiGeoJsonFeature);
    } else if (typeof chemistryCaseSetup !== "string") {
      this.props.setChPoiOptions(mapPoiGeoJsonFeature);
    }

    if (this.props.staticDriverNotifications.length > 0) {
      for (
        let index = this.props.staticDriverNotifications.length - 1;
        index >= 0;
        index--
      ) {
        if (
          this.props.staticDriverNotifications[index].type === "staticDriver" &&
          this.props.staticDriverNotifications[index].body.caseId ===
            this.props.currentCase.id &&
          this.props.staticDriverNotifications[index].body.status === 2
        ) {
          this.setState({ savedStaticDriver: true });
        }
      }
    }
    this.setState({ isLoaded: true });
  }

  async componentDidUpdate(prevProps) {
    if (this.props.currentCase !== prevProps.currentCase) {
      const drawnGeoJsonUrl = this.props.currentCase.drawnGeojsonUrl;
      const polygonDataUrl = this.props.currentCase.polygonDataUrl;
      const mapPoiGeoJsonFeature = [];

      if (drawnGeoJsonUrl) {
        const drawnGeoJsonResponse = await downloadBlobAsync(drawnGeoJsonUrl);

        const drawnGeoJson = drawnGeoJsonResponse.data;

        drawnGeoJson.features.map((x) => {
          if (x.properties.t === 9) {
            mapPoiGeoJsonFeature.push({
              value: `POI ${x.properties.id}`,
              label: `POI ${x.properties.id}`,
            });
          }
        });
      }

      const geoJsonUrl = this.props.currentCase.geojsonUrl;
      if (geoJsonUrl) {
        const geoJsonResponse = await downloadBlobAsync(geoJsonUrl);
        const geoJson = geoJsonResponse.data;
        geoJson.features.map((x) => {
          if (x.properties.t === 9) {
            mapPoiGeoJsonFeature.push({
              value: `POI ${x.properties.id}`,
              label: `POI ${x.properties.id}`,
            });
          }
        });
      }

      if (!!polygonDataUrl) {
        const polygonDataResponse = await downloadBlobAsync(polygonDataUrl);
        const polygonData = polygonDataResponse.data;

        polygonData.map((x, i) => {
          if (
            x.name &&
            mapPoiGeoJsonFeature.map((y) => {
              return y.value.includes(x.id.toString());
            })
          ) {
            const poiId = x.id;

            const indexPoiGeoJsonFeature = mapPoiGeoJsonFeature.findIndex(
              (z) => {
                return z.value.includes(poiId.toString());
              }
            );

            if (indexPoiGeoJsonFeature > -1) {
              mapPoiGeoJsonFeature[indexPoiGeoJsonFeature].value =
                polygonData[i].name;
              mapPoiGeoJsonFeature[indexPoiGeoJsonFeature].label =
                polygonData[i].name;
            }
          }
        });
      }

      if (
        this.props.currentCaseId &&
        prevProps.currentCaseId !== this.props.currentCaseId
      ) {
        const chemistryCaseSettings = await getCaseSetup(
          this.props.currentCase.id
        );

        if (typeof chemistryCaseSettings === "string") {
          this.props.resetChemistrySettings();
          this.props.setChInitialPoiList(mapPoiGeoJsonFeature);
        } else {
          this.props.loadChemistrySettings(
            chemistryCaseSettings.setup.settings
          );
          this.props.setChPoiOptions(mapPoiGeoJsonFeature);
        }

        this.setState({ isLoaded: true });
      }
    }

    if (
      this.props.currentCase?.staticDriverJob?.status === "Completed" &&
      !this.state.savedStaticDriver
    ) {
      this.setState({ savedStaticDriver: true });
      return;
    } else if (
      this.props.staticDriverNotifications !==
      prevProps.staticDriverNotifications
    ) {
      if (!this.props.currentCase) {
        return;
      }

      for (
        let index = this.props.staticDriverNotifications.length - 1;
        index >= 0;
        index--
      ) {
        if (
          this.props.staticDriverNotifications[index].type === "staticDriver" &&
          this.props.staticDriverNotifications[index].body.caseId ===
            this.props.currentCase.id &&
          this.props.staticDriverNotifications[index].body.status === 2
        ) {
          this.setState({ savedStaticDriver: true });
          return;
        }
      }
    }
  }

  componentWillUnmount() {
    this.props.resetChemistrySettings();
  }

  async saveCaseSetup() {
    this.setState({ isSaving: true });

    // const originDataTimeAdjusted = `${
    //   this.props.chemistry.startDay + " 00:00:00 "
    // }`;

    let longitude = null;
    let latitude = null;

    if (!this.props.rasterArea.rasterCenterLatWg) {
      const rasterCenterWg = calculateRasterCenterWgFromDb(
        this.props.currentCase.rasterParameters.y_min,
        this.props.currentCase.rasterParameters.y_max,
        this.props.currentCase.rasterParameters.x_min,
        this.props.currentCase.rasterParameters.x_max,
        this.props.epsg
      );
      latitude = rasterCenterWg.lat.toFixed(6);
      longitude = rasterCenterWg.lng.toFixed(6);
    } else {
      latitude = Number(this.props.rasterArea.rasterCenterLatWg).toFixed(6);
      longitude = Number(this.props.rasterArea.rasterCenterLngWg).toFixed(6);
    }

    const airTemperatureSurfaceStr = (
      Number(cloneDeep(this.props.chemistry.airTemperatureSurface)) + 273.15
    ).toFixed(2);
    const airTemperatureSurface = parseFloat(airTemperatureSurfaceStr);

    const meanTemperatureStr = (
      Number(cloneDeep(this.props.chemistry.meanTemperature)) + 273.15
    ).toFixed(2);
    const meanTemperature = parseFloat(meanTemperatureStr);
    const mandatoryVariablesAveraging = cloneDeep(
      this.props.chemistry.mandatoryOutputParameters
    );
    const chemistryVariablesAveraging = cloneDeep(
      this.props.chemistry.outputParameters
    );

    let dataOutputMasks = mandatoryVariablesAveraging;

    if (this.props.chemistry.outputPresets.value === "UserDefined") {
      dataOutputMasks = mandatoryVariablesAveraging.concat(
        chemistryVariablesAveraging
      );
    }

    const dataOutput = dataOutputMasks.filter((x) => {
      return (
        x.label !== "NearSurfaceTemperature" &&
        x.label !== "NearSurfaceWindfield"
      );
    });

    try {
      const minimalSetup = {
        caseId: this.props.currentCaseId,
        settings: {
          initialization_parameters: {
            origin_date_time: this.props.chemistry.startDay + " 02:00:00 +01",
            nx: this.props.rasterArea.rasterGridXNumber - 1,
            ny: this.props.rasterArea.rasterGridYNumber - 1,
            resolution: this.props.rasterArea.rasterGridWidth,
            longitude: longitude,
            latitude: latitude,
            pt_surface: airTemperatureSurface,
            q_surface: this.props.chemistry.relHumidity,
            spinup_pt_mean: meanTemperature,
            spinup_pt_amplitude: this.props.chemistry.amplitude,
          },
          runtime_parameters: {
            data_output: dataOutput,
            data_output_masks: dataOutputMasks,
            end_time: Number(this.props.chemistry.duration) * 3600,
          },
          chemistry_parameters: {
            season: this.props.chemistry.season.value,
            start_day: this.props.chemistry.startDay,
            output_interval: 3600,
            wind_speed: this.props.chemistry.windSpeed,
            wind_direction: this.props.chemistry.windDirection.value,
            soil_moisture: this.props.chemistry.soilMoisture.value,
            soil_temperature: this.props.chemistry.soilTemperature.value,
            user_soil_moisture:
              this.props.chemistry.soilMoisture.value ===
              "UserDefinedConstantMoisture"
                ? this.props.chemistry.userDefinedSoilMoisture
                : null,
            user_soil_temperature:
              this.props.chemistry.soilTemperature.value ===
              "UserDefinedTemperatureTopLayer"
                ? this.props.chemistry.userDefinedSoilTemperature
                : null,
          },
        },
      };

      if (this.props.chemistry.inputPresets.value === "UserDefinedEmissions") {
        const inputPresetsSetup = {
          surface_cs_flux_25: this.props.chemistry.surfaceCsFlux25,
          surface_cs_flux_10: this.props.chemistry.surfaceCsFlux10,
          emissions_factor_table: this.props.chemistry.emissionsFactorTable,
          point_emissions_table: this.props.chemistry.arrayOfPointEmissionTable,
        };

        minimalSetup.settings.chemistry_parameters = {
          ...minimalSetup.settings.chemistry_parameters,
          ...inputPresetsSetup,
        };
      }

      if (this.props.chemistry.outputPresets.value === "UserDefined") {
        const vertSelectedMapPoi =
          this.props.chemistry.drawnMapPoiSelectedVerticalProfile.map((x) => {
            return x.value;
          });

        const timeSelectedMapPoi =
          this.props.chemistry.drawnMapPoiSelectedTimeSeries.map((x) => {
            return x.value;
          });

        const outputPresetsSetup = {
          map_output_interval: this.props.chemistry.mapOutputInterval.value,
          output_type: ["AverageOutput"],
          vertical_output_interval:
            this.props.chemistry.verticalOutputInterval.value,
          vertical_map_poi_selected: vertSelectedMapPoi,
          time_series_output_interval:
            this.props.chemistry.timeSeriesOutputInterval.value,
          time_series_map_poi_selected: timeSelectedMapPoi,
          assesment_time_from: this.props.chemistry.assesmentTimeFrom.value,
          assesment_time_to: this.props.chemistry.assesmentTimeTo.value,
        };
        minimalSetup.settings.chemistry_parameters = {
          ...minimalSetup.settings.chemistry_parameters,
          ...outputPresetsSetup,
        };
      }

      console.log(JSON.stringify(minimalSetup, null, 2));
      await postCaseSetup(minimalSetup);

      this.setState({ saveCompleted: true });
      setTimeout(() => this.setState({ saveCompleted: false }), 5000);
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ isSaving: false });
    }
  }

  render() {
    const { t } = this.props;

    return (
      <>
        {this.props.currentCase && this.state.isLoaded ? (
          <div className="page setup-page" style={{ minWidth: 900 }}>
            <div className="settings-form">
              <div className="wind-wrapper">
                <FormSettingsSeparator label={t("Time")} />
                <label className="form-label user-settings-form-label">
                  {t("Season")}
                </label>
                <Select
                  isSearchable={false}
                  styles={selectSetupStyle}
                  options={[
                    { value: "Winter", label: t("Winter") },
                    { value: "Summer", label: t("Summer"), isDisabled: true },
                    {
                      value: "DynamicDriver",
                      label: t("DynamicDriver"),
                      isDisabled: true,
                    },
                  ]}
                  onChange={(e) => this.props.setChSeason(e)}
                  value={{
                    label: t(this.props.chemistry.season.label),
                    value: this.props.chemistry.season.value,
                  }}
                  maxMenuHeight="500px"
                  disabled
                />
                <label
                  style={{ marginTop: "10px" }}
                  className="form-label user-settings-form-label"
                >
                  {t("StartDay")}
                </label>
                <input
                  className="setup-input"
                  type="date"
                  id="startDay"
                  name="startDay"
                  value={this.props.chemistry.startDay}
                  onChange={(e) => this.props.setChStartDay(e.target.value)}
                  min="2022-01-12"
                  max="2022-02-28"
                  required
                ></input>
                <label className="form-label user-settings-form-label">
                  {t("Duration")} [h]
                </label>
                <input
                  className="setup-input"
                  type="number"
                  name="num"
                  min="30"
                  max="54"
                  step="1"
                  value={this.props.chemistry.duration}
                  onChange={(e) => this.props.setChDuration(e.target.value)}
                />
                <FormSettingsSeparator label={t("BoundaryConditions")} />
                <label className="form-label user-settings-form-label">
                  {t("AirTemperatureSurface")} [°C]
                </label>
                <input
                  className="setup-input"
                  name="num"
                  type="number"
                  min="-50"
                  max="10"
                  step="0.1"
                  value={this.props.chemistry.airTemperatureSurface}
                  onChange={(e) =>
                    this.props.setChAirTemperatureSurface(e.target.value)
                  }
                />
                <label className="form-label user-settings-form-label">
                  {t("RelativeHumiditySurface")} [%]
                </label>
                <input
                  className="setup-input"
                  name="num"
                  type="number"
                  min="1"
                  max="100"
                  step="0.1"
                  value={this.props.chemistry.relHumidity}
                  onChange={(e) => this.props.setChRelHumidity(e.target.value)}
                />
                <label className="form-label user-settings-form-label">
                  {t("WindSpeed")} [m/s]
                </label>
                <input
                  className="setup-input"
                  name="num"
                  type="number"
                  min="1.5"
                  max="3"
                  step="0.1"
                  value={this.props.chemistry.windSpeed}
                  onChange={(e) => this.props.setChWindSpeed(e.target.value)}
                />
                <label className="form-label user-settings-form-label">
                  {t("WindDirection")}
                </label>
                <Select
                  isSearchable={false}
                  styles={selectSetupStyle}
                  options={windMultiDirections.map((x) => {
                    return { label: t(x.label), value: x.value };
                  })}
                  onChange={(e) => this.props.setChWindDirection(e)}
                  value={{
                    label: t(this.props.chemistry.windDirection.label),
                    value: this.props.chemistry.windDirection.value,
                  }}
                  maxMenuHeight="500px"
                />
                <FormSettingsSeparator label={t("Spinup")} />
                <label className="form-label user-settings-form-label">
                  {t("MeanTemperature")} [°C]
                </label>
                <input
                  className="setup-input"
                  name="num"
                  type="number"
                  min="-50"
                  max="10"
                  step="0.1"
                  value={this.props.chemistry.meanTemperature}
                  onChange={(e) =>
                    this.props.setChMeanTemperature(e.target.value)
                  }
                />
                <label className="form-label user-settings-form-label">
                  {t("Amplitude")} [K]
                </label>
                <input
                  className="setup-input"
                  name="num"
                  type="number"
                  min="0"
                  max="50"
                  step="0.1"
                  value={this.props.chemistry.amplitude}
                  onChange={(e) => this.props.setChAmplitude(e.target.value)}
                />
                <FormSettingsSeparator label={t("LandSurfaceModel")} />
                <label className="form-label user-settings-form-label">
                  {t("SoilMoisture")}
                </label>
                <Select
                  isSearchable={false}
                  styles={selectSetupStyle}
                  options={[
                    { value: "DrySoil", label: t("DrySoil") },
                    //{ value: "MoistSoil", label: t("MoistSoil") },
                    { value: "PalmDefault", label: t("PalmDefault") },
                    //{ value: "Gehrkeetal", label: t("Gehrkeetal") },
                    {
                      value: "UserDefinedConstantMoisture",
                      label: t("UserDefinedConstantMoisture"),
                    },
                  ]}
                  onChange={(e) => this.props.setChSoilMoisture(e)}
                  value={{
                    label: t(this.props.chemistry.soilMoisture.label),
                    value: this.props.chemistry.soilMoisture.value,
                  }}
                  maxMenuHeight="500px"
                />
                <div style={{ marginBottom: 12 }}></div>
                {this.props.chemistry.soilMoisture.value ===
                  "UserDefinedConstantMoisture" && (
                  <>
                    <label className="form-label user-settings-form-label">
                      {t("UserDefinedSoilMoisture")} [m³/m³]
                    </label>
                    <input
                      className="setup-input"
                      name="num"
                      type="number"
                      min="0"
                      max="1"
                      step="0.01"
                      value={this.props.chemistry.userDefinedSoilMoisture}
                      onChange={(e) =>
                        this.props.setChUserDefinedSoilMoisture(e.target.value)
                      }
                    />
                  </>
                )}
                <label className="form-label user-settings-form-label">
                  {t("SoilTemperature")}
                </label>
                <Select
                  isSearchable={false}
                  styles={selectSetupStyle}
                  options={[
                    // {
                    //   value: "DerivedFromBoundaryConditions",
                    //   label: t("DerivedFromBoundaryConditions"),
                    // },
                    {
                      value: "PALMDefaultAutomaticAdjustmentToAirTemperature",
                      label: t(
                        "PALMDefaultAutomaticAdjustmentToAirTemperature"
                      ),
                    },
                    //{ value: "Gehrkeetal", label: t("Gehrkeetal") },
                    {
                      value: "PalmDefaultDefaultProfile",
                      label: t("PalmDefaultDefaultProfile"),
                    },
                    {
                      value: "UserDefinedTemperatureTopLayer",
                      label: t("UserDefinedTemperatureTopLayer"),
                    },
                  ]}
                  onChange={(e) => this.props.setChSoilTemperature(e)}
                  value={{
                    label: t(this.props.chemistry.soilTemperature.label),
                    value: this.props.chemistry.soilTemperature.value,
                  }}
                  maxMenuHeight="500px"
                />
                <div style={{ marginBottom: 12 }}></div>
                {this.props.chemistry.soilTemperature.value ===
                  "UserDefinedTemperatureTopLayer" && (
                  <>
                    <label className="form-label user-settings-form-label">
                      {t("UserDefinedSoilTemperature")} [K]
                    </label>
                    <input
                      className="setup-input"
                      name="num"
                      type="number"
                      min="274"
                      max="372"
                      step="0.1"
                      value={this.props.chemistry.userDefinedSoilTemperature}
                      onChange={(e) =>
                        this.props.setChUserDefinedSoilTemperatue(
                          e.target.value
                        )
                      }
                    />
                  </>
                )}
                {/* <div style={{ marginBottom: "30px" }} /> */}
                <FormSettingsSeparator label={t("Chemistry")} />
                <label className="form-label user-settings-form-label">
                  {t("InputPresets")}
                </label>
                <Select
                  isSearchable={false}
                  styles={selectSetupStyle}
                  options={[
                    {
                      value: "Minimal",
                      label: t("Minimal"),
                    },
                    {
                      value: "UserDefinedEmissions",
                      label: t("UserDefinedEmissions"),
                    },
                  ]}
                  onChange={(e) => this.props.setChInputPresets(e)}
                  value={{
                    label: t(this.props.chemistry.inputPresets.label),
                    value: this.props.chemistry.inputPresets.value,
                  }}
                  maxMenuHeight="500px"
                />

                {this.props.chemistry.inputPresets.value ===
                  "UserDefinedEmissions" && (
                  <>
                    <FormSettingsSeparator label={t("StreetEmissions")} />
                    <label
                      style={{ marginTop: "12px" }}
                      className="form-label user-settings-form-label"
                    >
                      {t("SurfaceCsFlux25")} [kg/m²/d]
                    </label>
                    <input
                      className="setup-input"
                      name="num"
                      type="number"
                      min="0"
                      max="999"
                      step="0.0000001"
                      ///to nie ma
                      value={this.props.chemistry.surfaceCsFlux25}
                      onChange={(e) =>
                        this.props.setSurfaceCsFlux25(e.target.value)
                      }
                    />
                    <label
                      style={{ marginTop: "12px" }}
                      className="form-label user-settings-form-label"
                    >
                      {t("SurfaceCsFlux50")} [kg/m²/d]
                    </label>
                    <input
                      className="setup-input"
                      name="num"
                      type="number"
                      min="0"
                      max="999"
                      step="0.0000001"
                      ///to nie ma
                      value={this.props.chemistry.surfaceCsFlux10}
                      onChange={(e) =>
                        this.props.setSurfaceCsFlux10(e.target.value)
                      }
                    />
                    <div className="table-animation">
                      <EmissionsFactorTable />
                      <div
                        style={{ marginBottom: "22px" }}
                        className="form_section"
                      >
                        <h4 className="form-section__txt">
                          {t("PointEmissions")}
                        </h4>
                      </div>

                      <Select
                        isSearchable={false}
                        styles={selectSetupStyle}
                        options={emissionsSubtypes.map((x) => {
                          return { label: t(x.label), value: x.value };
                        })}
                        value={{
                          label: t(
                            emissionsSubtypes[
                              this.props.chemistry
                                .currentPointEmissionsTableIndex
                            ].label
                          ),
                          value:
                            emissionsSubtypes[
                              this.props.chemistry
                                .currentPointEmissionsTableIndex
                            ].value,
                        }}
                        onChange={(e) => {
                          this.props.setPointEmissionTableType(e.value);
                        }}
                        maxMenuHeight="500px"
                        disabled
                      />
                      <div style={{ marginTop: "22px" }} />

                      <PointEmissionsTable />
                    </div>
                  </>
                )}
                {/* <div style={{ marginBottom: "30px" }} /> */}
                <FormSettingsSeparator label={t("Results")} />
                <label className="form-label user-settings-form-label">
                  {t("OutputPreset")}
                </label>
                <Select
                  isSearchable={false}
                  styles={selectSetupStyle}
                  options={[
                    {
                      value: "Minimal",
                      label: t("Minimal"),
                    },
                    { value: "UserDefined", label: t("UserDefined") },
                  ]}
                  onChange={(e) => this.props.setChOutputPresets(e)}
                  value={{
                    label: t(this.props.chemistry.outputPresets.label),
                    value: this.props.chemistry.outputPresets.value,
                  }}
                  maxMenuHeight="500px"
                />

                {this.props.chemistry.outputPresets.value === "UserDefined" && (
                  <>
                    <label
                      style={{ marginTop: "12px" }}
                      className="form-label user-settings-form-label"
                    >
                      {t("OutputParameters")}
                    </label>
                    <Select
                      isMulti
                      menuPlacement="top"
                      isClearable
                      closeMenuOnSelect={false}
                      styles={selectSetupStyle}
                      placeholder={`${t("Select")}...`}
                      options={[
                        {
                          label: `${t("Mandatory")}`,
                          options:
                            this.props.chemistry.mandatoryOutputParameters.map(
                              (o) => ({ ...o, label: t(o.label) })
                            ),
                        },
                        {
                          label: `${t("Optional")}`,
                          options:
                            this.props.chemistry.optionalOutputParameters.map(
                              (o) => ({ ...o, label: t(o.label) })
                            ),
                        },
                      ]}
                      onChange={(e) => this.props.setChOutputParameters(e)}
                      value={this.props.chemistry.outputParameters}
                      classNamePrefix="select"
                    />
                    <FormSettingsSeparator label={t("MapOutput")} />
                    <label className="form-label user-settings-form-label">
                      {t("OutputInterval")}
                    </label>
                    <Select
                      isSearchable={false}
                      styles={selectSetupStyle}
                      options={[
                        {
                          value: 60,
                          label: 60,
                        },
                        { value: 30, label: 30 },
                      ]}
                      onChange={(e) => this.props.setChMapOutputInterval(e)}
                      value={{
                        label: `${
                          this.props.chemistry.mapOutputInterval.label
                        } ${t("Minutes")}`,
                        value: `${this.props.chemistry.mapOutputInterval.value}
                    ${t("Minutes")}`,
                      }}
                      maxMenuHeight="500px"
                    />
                    <div style={{ marginBottom: 12 }}></div>
                    <FormSettingsSeparator label={t("VerticalProfileOutput")} />
                    <label className="form-label user-settings-form-label">
                      {t("OutputInterval")}
                    </label>
                    <Select
                      isSearchable={false}
                      styles={selectSetupStyle}
                      options={[
                        {
                          value: 60,
                          label: 60,
                        },
                        { value: 30, label: 30 },
                        { value: 15, label: 15 },
                      ]}
                      onChange={(e) =>
                        this.props.setChVerticalOutputInterval(e)
                      }
                      value={{
                        label: `${
                          this.props.chemistry.verticalOutputInterval.label
                        } ${t("Minutes")}`,
                        value: `${
                          this.props.chemistry.verticalOutputInterval.value
                        }
                    ${t("Minutes")}`,
                      }}
                      maxMenuHeight="500px"
                    />

                    <label
                      style={{ marginTop: "12px" }}
                      className="form-label user-settings-form-label"
                    >
                      {t("PoiSelection")}
                    </label>
                    <Select
                      isMulti
                      isClearable
                      closeMenuOnSelect={false}
                      styles={selectSetupStyle}
                      placeholder={`${t("Select")}...`}
                      options={[
                        {
                          label: `${t("Available")}`,
                          options:
                            this.props.chemistry
                              .drawnMapPoiSelectedVerticalProfileOptions,
                        },
                      ]}
                      onChange={(e) => this.props.setChVerticalPoi(e)}
                      value={
                        this.props.chemistry.drawnMapPoiSelectedVerticalProfile
                      }
                      classNamePrefix="select"
                    />

                    <FormSettingsSeparator label={t("TimeSeriesOutput")} />
                    <label className="form-label user-settings-form-label">
                      {t("OutputInterval")}
                    </label>
                    <Select
                      isSearchable={false}
                      styles={selectSetupStyle}
                      options={[
                        {
                          value: 60,
                          label: 60,
                        },
                        { value: 30, label: 30 },
                        { value: 15, label: 15 },
                        { value: 5, label: 5 },
                      ]}
                      onChange={(e) =>
                        this.props.setChTimeSeriesOutputInterval(e)
                      }
                      value={{
                        label: `${
                          this.props.chemistry.timeSeriesOutputInterval.label
                        } ${t("Minutes")}`,
                        value: `${
                          this.props.chemistry.timeSeriesOutputInterval.value
                        }
                    ${t("Minutes")}`,
                      }}
                      maxMenuHeight="500px"
                    />
                    <label
                      style={{ marginTop: "12px" }}
                      className="form-label user-settings-form-label"
                    >
                      {t("PoiSelection")}
                    </label>
                    <Select
                      isMulti
                      isClearable
                      closeMenuOnSelect={false}
                      styles={selectSetupStyle}
                      placeholder={`${t("Select")}...`}
                      options={[
                        {
                          label: `${t("Available")}`,
                          options:
                            this.props.chemistry
                              .drawnMapPoiSelectedTimeSeriesOptions,
                        },
                      ]}
                      onChange={(e) => this.props.setChTimeSeriesPoi(e)}
                      value={this.props.chemistry.drawnMapPoiSelectedTimeSeries}
                      classNamePrefix="select"
                    />
                    <FormSettingsSeparator
                      label={t("PostprocessingSettings")}
                    />
                    <label className="form-label user-settings-form-label">
                      {t("AssesmentTime") + " 1"}
                    </label>
                    <Select
                      isSearchable={false}
                      styles={selectSetupStyle}
                      options={this.props.chemistry.assesmentTimeOptions}
                      onChange={(e) => this.props.setChAssesmentTimeFrom(e)}
                      value={this.props.chemistry.assesmentTimeFrom}
                      maxMenuHeight="500px"
                    />
                    <label
                      className="form-label user-settings-form-label"
                      style={{ marginTop: "12px" }}
                    >
                      {t("AssesmentTime") + " 2"}
                    </label>
                    <Select
                      isSearchable={false}
                      styles={selectSetupStyle}
                      options={this.props.chemistry.assesmentTimeOptions}
                      onChange={(e) => this.props.setChAssesmentTimeTo(e)}
                      value={this.props.chemistry.assesmentTimeTo}
                      maxMenuHeight="500px"
                    />
                  </>
                )}
                <div style={{ marginBottom: 36 }}></div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <button
                    id="thermal-btn__save"
                    className="wizard-button wizard-button-submit"
                    onClick={this.saveCaseSetup}
                    disabled={
                      this.props.chemistry.outputTypeSelected?.length === 0 ||
                      this.props.simulationJobs?.length ||
                      this.props.simulationNotifications
                        ? true
                        : false ||
                          (this.props.currentCase?.staticDriverJob?.status !==
                            "Completed" &&
                            !this.state.savedStaticDriver)
                    }
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "34px",
                    }}
                  >
                    {!this.state.isSaving && <span>{t("Save")}</span>}
                    {this.state.isSaving && (
                      <Loader size="x-small" mode="light" />
                    )}
                  </button>
                  {this.state.saveCompleted && (
                    <div className="setup-save-status-wrapper">
                      <CheckCircle
                        width="17"
                        stroke="rgb(6,137,108)"
                        strokeWidth="2"
                      />
                      <div className="save-status-text">
                        {t("SetupHasBeenSaved")}
                      </div>
                    </div>
                  )}
                  {this.props.simulationJobs?.length ||
                  !!this.props.simulationNotifications ? (
                    <div className="setup-save-status-wrapper">
                      <div>
                        <XCircle size="15" stroke="#a81d1dcf" strokeWidth="2" />
                      </div>
                      <div className="save-status-disabled">
                        {t("SavingSetupDisabledSimStarted")}
                      </div>
                    </div>
                  ) : this.props.currentCase?.staticDriverJob?.status !==
                      "Completed" && !this.state.savedStaticDriver ? (
                    <div className="setup-save-status-wrapper">
                      <div>
                        <XCircle
                          size="15"
                          stroke="#a81d1dcf"
                          strokeWidth="2.2"
                        />
                      </div>
                      <div className="save-status-disabled">
                        {t("SavingSetupDisabledNoStaticDriver")}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    chemistry: state.chemistry,
    currentCase: state.projects.currentCase,
    rasterArea: state.rasterArea,
    currentCaseId: state.projects?.currentCaseId,
    simulationJobs: state.projects?.currentCase?.simulationJobs,
    simulationNotifications: selectCaseSimulationNotifications(state),
    staticDriverNotifications: state.notifications.notifications,
    epsg: state.projects.loadedProject?.epsg,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setChSeason: (payload) => dispatch(setChSeason(payload)),
    setChStartDay: (payload) => dispatch(setChStartDay(payload)),
    setChDuration: (payload) => dispatch(setChDuration(payload)),
    setChAirTemperatureSurface: (payload) =>
      dispatch(setChAirTemperatureSurface(payload)),
    setChRelHumidity: (payload) => dispatch(setChRelHumidity(payload)),
    setChWindSpeed: (payload) => dispatch(setChWindSpeed(payload)),
    setChWindDirection: (payload) => dispatch(setChWindDirection(payload)),
    setChMeanTemperature: (payload) => dispatch(setChMeanTemperature(payload)),
    setChAmplitude: (payload) => dispatch(setChAmplitude(payload)),
    setChSoilMoisture: (payload) => dispatch(setChSoilMoisture(payload)),
    setChSoilTemperature: (payload) => dispatch(setChSoilTemperature(payload)),
    setChUserDefinedSoilMoisture: (payload) =>
      dispatch(setChUserDefinedSoilMoisture(payload)),
    setChUserDefinedSoilTemperatue: (payload) =>
      dispatch(setChUserDefinedSoilTemperatue(payload)),
    setChInputPresets: (payload) => dispatch(setChInputPresets(payload)),
    setChOutputPresets: (payload) => dispatch(setChOutputPresets(payload)),
    setSurfaceCsFlux25: (payload) => dispatch(setSurfaceCsFlux25(payload)),
    setSurfaceCsFlux10: (payload) => dispatch(setSurfaceCsFlux10(payload)),
    setChOutputParameters: (payload) =>
      dispatch(setChOutputParameters(payload)),
    setChMapOutputInterval: (payload) =>
      dispatch(setChMapOutputInterval(payload)),
    setChVerticalOutputInterval: (payload) =>
      dispatch(setChVerticalOutputInterval(payload)),
    setChTimeSeriesOutputInterval: (payload) =>
      dispatch(setChTimeSeriesOutputInterval(payload)),
    setChVerticalPoi: (payload) => dispatch(setChVerticalPoi(payload)),
    setChTimeSeriesPoi: (payload) => dispatch(setChTimeSeriesPoi(payload)),
    setChInitialPoiList: (payload) => dispatch(setChInitialPoiList(payload)),
    setChAssesmentTimeFrom: (payload) =>
      dispatch(setChAssesmentTimeFrom(payload)),
    setChAssesmentTimeTo: (payload) => dispatch(setChAssesmentTimeTo(payload)),
    setPointEmissionTableType: (payload) =>
      dispatch(setPointEmissionTableType(payload)),
    loadChemistrySettings: (payload) =>
      dispatch(loadChemistrySettings(payload)),
    setChPoiOptions: (payload) => dispatch(setChPoiOptions(payload)),
    resetChemistrySettings: () => dispatch(resetChemistrySettings()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(Chemistry)));
