import { apiRequest } from "./axiosApi";

const apiUrl = process.env.REACT_APP_FRONT_SERVICE_API;

export async function getCase(caseId) {
  const response = await apiRequest({
    method: "get",
    url: `${apiUrl}/projects/cases/${caseId}`,
  });
  return response.data;
}

export async function postQgisCase(projectId, data) {
  const response = await apiRequest({
    method: "post",
    url: `${apiUrl}/projects/${projectId}/cases/qgis`,
    data: data,
  });
  return response.data;
}

export async function postSdCase(projectId, data) {
  const response = await apiRequest({
    method: "post",
    url: `${apiUrl}/projects/${projectId}/cases/sc`,
    data: data,
  });
  return response.data;
}

export async function postEmptyCase(projectId, data) {
  const response = await apiRequest({
    method: "post",
    url: `${apiUrl}/projects/${projectId}/cases/empty`,
    data: data,
  });
  return response.data;
}

export async function cloneQgisCase(data) {
  const response = await apiRequest({
    method: "post",
    url: `${apiUrl}/projects/cases/qgis/copy`,
    data: data,
  });
  return response.data;
}

export async function cloneEmptyCase(data) {
  const response = await apiRequest({
    method: "post",
    url: `${apiUrl}/projects/cases/empty/copy`,
    data: data,
  });
  return response.data;
}

export async function cloneSdCase(data) {
  const response = await apiRequest({
    method: "post",
    url: `${apiUrl}/projects/cases/static-driver/copy`,
    data: data,
  });
  return response.data;
}
export async function putQgisCase(data) {
  const response = await apiRequest({
    method: "put",
    url: `${apiUrl}/projects/cases/qgis`,
    data: data,
  });
  return response.data;
}

export async function putSdCase(data) {
  const response = await apiRequest({
    method: "put",
    url: `${apiUrl}/projects/cases/static-driver`,
    data: data,
  });
  return response.data;
}

export async function deleteCaseById(caseId) {
  const response = await apiRequest({
    method: "delete",
    url: `${apiUrl}/projects/cases/${caseId}`,
  });
  return response.data;
}

export async function putCaseGeo(caseId, data) {
  const response = await apiRequest({
    method: "put",
    url: `${apiUrl}/projects/cases/${caseId}/geo`,
    data: data,
  });

  console.log("RESPONSE");
  console.log(response);

  return response.data;
}

export async function postCustomTask(data) {
  const response = await apiRequest({
    method: "post",
    url: `${apiUrl}/task`,
    data: data,
  });
  return response.data;
}
