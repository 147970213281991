import React from "react";
import { useTranslation } from "react-i18next";

import "./LegendItem.scss";

const LegendItem = (props) => {
  const { t } = useTranslation();
  return (
    <label className="legend-row-container">
      <input
        id={props.id}
        type="checkbox"
        className="legend-checkbox"
        checked={props.checked}
        onChange={props.onChange}
        name={props.name}
        disabled={props.disabled}
      />
      <span className="legend-object-name">{t(`${props.name}`)}</span>
    </label>
  );
};

export default LegendItem;
