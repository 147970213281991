import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Plus } from "react-feather";
import { TextInput } from "../Form";
import CircleButton from "../../Button/Circle/CircleButton";
import useClickOutside from "../../../../hooks/useClickOutside";

export const NewWsMapInput = (props) => {
  const ref = useRef();
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [nameError, setNameError] = useState("");

  useClickOutside(ref, () => {
    props.onClose();
  });

  const onNameChange = (e) => {
    const newName = e.target.value.trim();
    setName(newName);
    if (props.options?.some((o) => o.value.name === newName)) {
      setNameError(t("MapNameDuplicateError"));
    } else {
      setNameError("");
    }
  };

  const canSubmit = () => {
    return !!name && !!url && !nameError;
  };

  return (
    <div className="map-input" ref={ref}>
      <div className="map-input__heading">
        <div>{t("Add")}</div>
        <CircleButton
          icon={Plus}
          disabled={!canSubmit()}
          onClick={() => {
            props.onNew({ name, url });
            setName("");
            setUrl("");
            props.onClose();
          }}
        />
      </div>
      <TextInput
        label={t("Name")}
        name={"name"}
        value={name}
        onChange={onNameChange}
      />
      {nameError && <div className="validation-wrapper">{nameError}</div>}
      <TextInput
        label={t("Url")}
        name={"url"}
        value={url}
        onChange={(e) => setUrl(e.target.value)}
      />
    </div>
  );
};
