import React from "react";
import StaticDriver from "./StaticDriver";
import StaticDriverMapPanel from "../StaticDriverMapPanel/StaticDriverMapPanel";
import SidebarContainer from "../../Domain/SidebarContainer/SidebarContainer";

class StaticDriverContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lat: 52.475,
      lng: 13.42,
      zoom: 16,
    };
  }

  render() {
    return (
      <>
        <StaticDriver
          center={[this.state.lat, this.state.lng]}
          zoom={this.state.zoom}
        />
        <StaticDriverMapPanel />
        <SidebarContainer />
      </>
    );
  }
}

export default StaticDriverContainer;
