import React from "react";
import { useTranslation } from "react-i18next";
import { minutesToHoursMinutesSeconds } from "../../utils/date/dateHelpers";
import "./UserBudget.scss";

const UserBudget = (props) => {
  const { t } = useTranslation();
  return (
    <div className="hours-details">
      <div>
        <span className="text-input-form-label">
          {t("AvailableHours")}:&nbsp;
        </span>
        <span className="text-input-form-label">
          {minutesToHoursMinutesSeconds(props.user.simulationMinutes)}
        </span>
      </div>
      <div>
        <span className="text-input-form-label">
          {t("ReservedHours")}:&nbsp;
        </span>
        <span className="text-input-form-label">
          {minutesToHoursMinutesSeconds(props.user.reservedMinutes)}
        </span>
      </div>
      <div>
        <span className="text-input-form-label">{t("UsedHours")}:&nbsp;</span>
        <span className="text-input-form-label">
          {minutesToHoursMinutesSeconds(props.user.usedMinutes)}
        </span>
      </div>
    </div>
  );
};

export default UserBudget;
