import React from "react";
import { Box } from "../../Form/Box";
import { Database, HelpCircle } from "react-feather";
import { withTranslation } from "react-i18next";
import {
  selectEmission,
  removeEmission,
  updateEmissionPositionOnInputChange,
  goToEmissionProperties,
  updateTempInput,
} from "../../../../redux/actions/mapActions";
import { connect } from "react-redux";
import "../../TreeEditor/TreeEditor.scss";

class EmissionEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(e) {
    const reg = new RegExp(/^[+-]?\d*(?:[.,]\d*)?$/);
    const inputValue = e.target.value;
    const name = e.target.name;
    if (reg.test(inputValue) || inputValue === "") {
      if (
        inputValue.slice(-1) === "" ||
        inputValue.slice(-1) === "." ||
        inputValue.slice(-1) === "0" ||
        (name === "lat" && (inputValue < 0 || inputValue > 10000000)) ||
        (name === "lng" && (inputValue < 0 || inputValue > 1000000))
      ) {
        this.props.updateTempInput({
          tempKey: name,
          tempValue: inputValue,
        });
      } else {
        this.props.updateEmissionPositionOnInputChange({
          emissionIndex: this.props.selectedEmissionIndex,
          e: e,
        });
      }
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div className="tree-editor-container">
        <h3 className="tree-editor-hdr">{t("AddEmissionByClickingOnMap")}</h3>
        <Box objectType={"point_emission"} />
        {this.props.applicationField !== 3 && (
          <div
            style={{
              alignItems: "center",
              color: "#4f4f4f",
              fontSize: "12px",
              display: "flex",
              justifyContent: "center",
              paddingTop: "12px",
            }}
          >
            <HelpCircle
              size="14px"
              color="#4f4f4f"
              strokeWidth={"2px"}
              style={{ minWidth: "13px" }}
            />
            <div style={{ paddingLeft: "12px" }}>
              {t("EmissionInactiveWarning")}
            </div>
          </div>
        )}
        {this.props.allDrawnEmissionCoordinatesUtm.length > 0 && (
          <div className="tree-coord-label-container">
            <div className="long-icon minified-icon"></div>
            <label>{t("Easting")}</label>
            <div className="lat-icon minified-icon"></div>
            <label style={{ marginRight: "-20px" }}>{t("Northing")}</label>
          </div>
        )}

        {this.props.allDrawnEmissionCoordinatesUtm.map((tree, i) => {
          return (
            <div className="tree-coords-line">
              <div
                className={`tree-icon emission-icon ${
                  this.props.selectedEmissionIndex === i
                    ? "tree-icon emission-highlighted-icon"
                    : ""
                }`}
              />
              <label className="tree-index">{`${i}`}</label>
              <input
                className="tree-editor-coord-row"
                placeholder="Longitude"
                name="lng"
                value={
                  this.props.selectedEmissionIndex === i &&
                  this.props.tempKey === "lng" &&
                  (this.props.tempValue || this.props.tempValue === "")
                    ? this.props.tempValue
                    : tree.lng
                }
                onChange={(e) => this.handleInputChange(e)}
                onFocus={() => this.props.selectEmission(i)}
                onBlur={() =>
                  this.props.updateTempInput({ tempValue: "", tempKey: "" })
                }
              />

              <input
                className="tree-editor-coord-row"
                placeholder="Lattitude"
                name="lat"
                value={
                  this.props.selectedEmissionIndex === i &&
                  this.props.tempKey === "lat" &&
                  (this.props.tempValue || this.props.tempValue === "")
                    ? this.props.tempValue
                    : tree.lat
                }
                onChange={(e) => this.handleInputChange(e)}
                onFocus={() => this.props.selectEmission(i)}
                onBlur={() =>
                  this.props.updateTempInput({ tempValue: "", tempKey: "" })
                }
              />

              <button
                className="tree-form-btn"
                title={t("GoToHomeParametrization")}
                onClick={() => {
                  this.props.goToEmissionProperties(i);
                }}
              >
                <Database size="16px" color="Gray" strokeWidth={"1.7px"} />
              </button>

              <button
                className="remove-tree-btn"
                onClick={() => this.props.removeEmission(i)}
                title={t("DeleteHome")}
              >
                X
              </button>
            </div>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allDrawnEmissionCoordinatesUtm: state.map.allDrawnEmissionCoordinatesUtm,
    selectedEmissionIndex: state.map.selectedEmissionIndex,
    tempKey: state.map.tempKey,
    tempValue: state.map.tempValue,
    applicationField: state.currentCase?.applicationField,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectEmission: (payload) => dispatch(selectEmission(payload)),
    removeEmission: (payload) => dispatch(removeEmission(payload)),
    updateEmissionPositionOnInputChange: (payload) =>
      dispatch(updateEmissionPositionOnInputChange(payload)),
    goToEmissionProperties: (payload) =>
      dispatch(goToEmissionProperties(payload)),
    updateTempInput: (payload) => dispatch(updateTempInput(payload)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(EmissionEditor)
);
