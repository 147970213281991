import React from "react";
import { PageHeader } from "../../../Components/Page/Page";
import Select from "../../../Components/Common/Select/Select";
import { WizardButtonSubmit } from "../../../Components/Common/WizardButton/WizardButton";
import { TextInput } from "../../../Components/Common/Form/Form";
import { TextArea } from "../../../Components/Map/Form/TextArea";
import { XCircle, CheckCircle } from "react-feather";
import {
  createPage,
  validateNewPageName,
} from "../../../redux/actions/resultsActions";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import "./CreatePageMenu.scss";

class CreatePageMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newPageName: "",
      newPageDescription: "",
      newPageType: "Map",
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.onNewPageCreation = this.onNewPageCreation.bind(this);
  }

  onNewPageCreation() {
    this.props.createPage({
      newPageName: this.state.newPageName,
      newPageDescription: this.state.newPageDescription,
    });
  }

  onInputChange(e) {
    const { name, value } = e.target;

    if (name === "pageName") {
      this.setState({
        newPageName: value,
      });

      this.props.validateNewPageName(value);
    } else if (name === "description") {
      this.setState({
        newPageDescription: value,
      });
    }
  }

  onSelectChange(e) {
    this.setState({
      newPageType: e.target.value,
    });
  }

  render() {
    const { t } = this.props;
    return (
      <div className="wizard-section">
        <PageHeader text={t("CreatePage")} />
        <div className="text-input-container">
          <TextInput
            label={`${t("PageName")} *`}
            name={"pageName"}
            value={this.state.newPageName}
            onChange={this.onInputChange}
          />
          {this.state.newPageName.length > 0 && (
            <div className="validation-container">
              {this.props.isPageNameValid ? (
                <>
                  <div className="validation-icon-container">
                    <CheckCircle width="17" stroke="green" strokeWidth="2" />
                  </div>
                  <div className="validation-wrapper"></div>
                </>
              ) : (
                <>
                  <div className="validation-icon-container">
                    <XCircle width="17" stroke="#fc3939e3" strokeWidth="2" />
                  </div>
                  <div className="validation-wrapper">
                    {t(this.props.pageNameError)}
                  </div>
                </>
              )}
            </div>
          )}
        </div>

        <div className="text-input-container">
          <TextArea
            label={t("Description")}
            name={"description"}
            onChange={this.onInputChange}
            value={this.state.newPageDescription}
          />
        </div>
        <Select
          label={t("SelectPageType")}
          value={this.state.newPageType}
          options={newPageTypes.map((x) => {
            return {
              label: t(x.name),
              value: x.name,
              disabled: x.disabled,
            };
          })}
          onChange={this.onSelectChange}
        />
        <div className="app-field-button-wrapper end">
          <WizardButtonSubmit
            text={t("CreatePage")}
            onClick={this.onNewPageCreation}
            disabled={
              this.state.newPageName === "" ||
              this.props.isPageNameValid === false
            }
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isPageNameValid: state.results.isPageNameValid,
    pageNameError: state.results.pageNameError,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    createPage: (payload) => dispatch(createPage(payload)),
    validateNewPageName: (payload) => dispatch(validateNewPageName(payload)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(CreatePageMenu)
);

const newPageTypes = [
  { name: "Map", disabled: false },
  // { name: "MapSplitView", disabled: true },
  // { name: "ChartTimeSeries", disabled: true },
  // { name: "ChartVerticalProfiles", disabled: true },
  // { name: "ChartDistributions", disabled: true },
];
