import React from "react";
import BurgerIcon from "../../../../static/icons/burger-rectangle.svg";
import "./SidebarButton.scss";

const SidebarButton = (props) => {
  return (
    <button
      className={`sidebar-btn sidebar-btn-${
        props.isSidebarVisible ? "expanded" : "collapsed"
      }
      
      ${props.inResultsMap ? "sidebar-btn-results-map" : ""}
      
      `}
      onClick={props.handleClick}
    >
      {!props.isSidebarVisible && (
        <img className={`sidebar-burger`} src={BurgerIcon} alt="logo"></img>
      )}
    </button>
  );
};

export default SidebarButton;
