import React, { useState } from "react";
import InputCheck from "./AllTabs/InputCheck";
import SimulationStatus from "./AllTabs/SimulationStatus";
import PalmMonitoring from "./AllTabs/PalmMonitoring";
import { useTranslation } from "react-i18next";
import "./SimTabs.scss";

const SimTabs = (props) => {
  const [activeTab, setActiveTab] = useState("InputCheck");

  const handleInputCheck = () => setActiveTab("InputCheck");
  const handleSimulationStatus = () => setActiveTab("SimulationStatus");
  const handlePalmMonitoring = () => setActiveTab("PalmMonitoring");

  const { t } = useTranslation();

  return (
    <div className="sim-tabs-wrapper">
      <ul className="sim-tabs-nav">
        <li
          className={
            !props.inputChecked
              ? "sim-tabs-disabled"
              : activeTab === "InputCheck"
              ? "sim-tabs-active"
              : ""
          }
          onClick={handleInputCheck}
        >
          {t("InputCheck")}
        </li>
        <li
          className={
            activeTab === "SimulationStatus"
              ? "sim-tabs-active"
              : props.simulationNotifications?.progress >= 0 ||
                props.simulationNotifications?.status === "finished"
              ? ""
              : "sim-tabs-disabled"
          }
          style={
            !props.inputChecked
              ? {}
              : activeTab === "InputCheck"
              ? {
                  borderLeft: "1px solid gainsboro",
                  borderRight: "1px solid transparent",
                }
              : activeTab === "SimulationStatus"
              ? {
                  borderLeft: "1px solid gainsboro",
                  borderRight: "1px solid gainsboro",
                }
              : {
                  borderRight: "1px solid gainsboro",
                  borderLeft: "1px solid transparent",
                }
          }
          onClick={handleSimulationStatus}
        >
          {t("SimulationStatus")}
        </li>
        <li
          className={
            activeTab === "PalmMonitoring"
              ? "sim-tabs-active"
              : props.simulationNotifications?.status === "finished"
              ? ""
              : "sim-tabs-disabled"
          }
          onClick={handlePalmMonitoring}
        >
          {t("PalmMonitoring")}
        </li>
      </ul>
      <div className="sim-tabs-content">
        {activeTab === "InputCheck" ? (
          <InputCheck
            isInfoLoading={props.isInfoLoading}
            inputChecked={props.inputChecked}
            setup={props.setup}
            storage={props.storage}
            results={props.results}
            staticDriver={props.staticDriver}
            isWindTableUserDefined={props.isWindTableUserDefined}
          />
        ) : activeTab === "SimulationStatus" ? (
          <SimulationStatus
            currentCase={props.currentCase}
            simulationNotifications={props.simulationNotifications}
          />
        ) : activeTab === "PalmMonitoring" ? (
          <PalmMonitoring
            simulationNotifications={props.simulationNotifications}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
export default SimTabs;
