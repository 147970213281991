import React from "react";
import L from "leaflet";
import { Marker, Polyline, Pane, CircleMarker } from "react-leaflet";
import {
  drawPolygon,
  dragPointWg,
  dragendPointWg,
  selectPolygonPoint,
} from "../../../redux/actions/mapActions";
import { connect } from "react-redux";

class PolygonDuringDrawing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        {!this.props.inSelectMode &&
          this.props.polygonPointsWg.length > 0 &&
          !this.props.polygonDrawn && (
            <Pane zIndex={1}>
              <Polyline
                color={this.props.inStreetDrawingMode ? "orange" : "#3388ff"}
                positions={[
                  ...this.props.polygonPointsWg,
                  this.props.currentCursorCoords,
                ]}
              />
            </Pane>
          )}
        {this.props.polygonPointsWg.map((x, idx) => (
          <>
            <Pane zIndex={100}>
              <Marker
                bubblingMouseEvents
                position={[x[0], x[1]]}
                icon={vertexMarker}
                draggable={this.props.inSelectMode}
                ondrag={() =>
                  this.props.dragPointWg({
                    pointCoords: this.props.currentCursorCoords,
                    indexOfPoint: idx,
                  })
                }
                onMouseOver={() => {
                  this.props.selectPolygonPoint(idx);
                }}
                onMouseOut={() => this.props.selectPolygonPoint(null)}
                onDragend={(e) =>
                  this.props.dragendPointWg({
                    pointCoords: e.target._latlng,
                    idx: idx,
                  })
                }
              />
            </Pane>
            {this.props.isPolygonEditorInputFocused &&
              idx === this.props.selectedPolygonPoint && (
                <CircleMarker
                  center={[x[0], x[1]]}
                  stroke={"#db2828"}
                  color={"#db2828"}
                  radius={6}
                  weight={6}
                />
              )}
          </>
        ))}
        {!this.props.inSelectMode &&
          this.props.currentCursorCoords !== null &&
          !this.props.polygonDrawn && (
            <Pane zIndex={200}>
              <Marker
                position={this.props.currentCursorCoords}
                icon={vertexMarker}
              />
            </Pane>
          )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    inSelectMode: state.map.inSelectMode,
    polygonDrawn: state.map.polygonDrawn,
    polygonPointsWg: state.map.polygonPointsWg,
    currentCursorCoords: state.map.currentCursorCoords,
    canDrawPolygon: state.map.canDrawPolygon,
    inStreetDrawingMode: state.map.inStreetDrawingMode,
    objectTypeIndex: state.map.objectTypeIndex,
    selectedPolygonPoint: state.map.selectedPolygonPoint,
    isPolygonEditorInputFocused: state.map.isPolygonEditorInputFocused,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    drawPolygon: () => dispatch(drawPolygon()),
    dragPointWg: (payload) => dispatch(dragPointWg(payload)),
    dragendPointWg: (payload) => dispatch(dragendPointWg(payload)),
    selectPolygonPoint: (payload) => dispatch(selectPolygonPoint(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PolygonDuringDrawing);

const vertexMarker = new L.Icon({
  iconUrl: require("./vertex-point.svg"),
  iconRetinaUrl: require("./vertex-point.svg"),
  iconAnchor: null,
  popupAnchor: [0, -20],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(15, 15),
  className: "vertex",
});
