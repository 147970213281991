import React, { Suspense, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { Loader } from "./Components/Loader";
import Layout from "./containers/Layout/index";
import NotificationProvider from "./containers/NotificationProvider/index";
import SteeringProvider from "./containers/NotificationProvider/SteeringProvider";
import SimulationProvider from "./containers/NotificationProvider/SimulationProvider";
import PostprocessingProvider from "./containers/NotificationProvider/PostprocessingProvider";
import CustomTaskProvider from "./containers/NotificationProvider/CustomTaskProvider";
import * as actions from "./redux/actions/authActions";

export function AuthenticatedApp() {
  const { accounts } = useMsal();
  const dispatch = useDispatch();
  const roles = useSelector((state) => state.auth.roles);
  const isAuthenticating = useSelector(
    (state) => state.auth.isAuthenticating || !state.auth.rolesLoaded
  );
  useEffect(() => {
    if (accounts.length > 0) {
      dispatch(actions.loginUser(accounts[0]));
    }
  }, [dispatch, accounts]);
  return (
    <Suspense fallback={<Loader />}>
      {isAuthenticating && <Loader />}
      {!isAuthenticating && (
        <>
          <NotificationProvider />
          <SteeringProvider />
          <SimulationProvider />
          <PostprocessingProvider />
          <CustomTaskProvider />
          <Layout roles={roles} />
        </>
      )}
    </Suspense>
  );
}
