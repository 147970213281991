import React, { useRef, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import { landSurfaceSoilTableData } from "../expertConst";
import { updateLandSurfaceTable } from "../../../redux/actions/expertActions";
import { useSelector, useDispatch } from "react-redux";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

const ExpertStandardTable = (props) => {
  const gridRef = useRef();
  const dispatch = useDispatch();
  const soilTable = useSelector(
    (state) =>
      state.expert.expertJson.modules.land_surface.parameters.soil_params
  );

  const numberFormatter = (params) => {
    return params.value?.toString();
  };

  const defaultColDef = useMemo(() => {
    return {
      suppressMovable: true,
      editable: true,
      headerClass: "expert-hdr",
      cellClass: "expert-cell expert-right",
      flex: 1,
      valueFormatter: numberFormatter,
      valueSetter: (params) => {
        const value = params.newValue;
        if (!isNaN(value)) {
          const fieldName = params.colDef.field;

          if (fieldName === "dz_soil" && (value < 0.01 || value > 99.99)) {
            return false;
          } else if (
            fieldName === "soil_temperature" &&
            (value < 274.15 || value > 372.15)
          ) {
            return false;
          } else if (
            (fieldName === "soil_moisture" && (value < 0 || value > 1)) ||
            value === ""
          ) {
            return false;
          } else if (
            fieldName === "root_fraction" &&
            (value < 0 || value > 1)
          ) {
            return false;
          }
          const rowData = params.data;
          const rowIndex = params.node.rowIndex;

          rowData[params.colDef.field] = value;
          soilTable[rowIndex] = rowData;

          dispatch(
            updateLandSurfaceTable({
              rowValue: rowData,
              rowIndex: rowIndex,
            })
          );
          return true;
        }
        return false;
      },
    };
  }, []);

  return (
    <div
      className="ag-theme-alpine"
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <AgGridReact
        {...props}
        ref={gridRef}
        rowData={soilTable}
        columnDefs={landSurfaceSoilTableData.columnDefs}
        defaultColDef={defaultColDef}
        domLayout={"autoHeight"}
        suppressHorizontalScroll
        suppressCellSelection={false}
        // onGridReady={(params) => {
        //   params.api.sizeColumnsToFit();
        // }}
      />
    </div>
  );
};

export default ExpertStandardTable;
