import React from "react";
import { NumericInput } from "../../../../Components/Map/Form/NumericInput";
import { Crosshair, Move } from "react-feather";
import {
  toggleMapPlotDraggable,
  setMapPlotCenterLatUtm,
  setMapPlotCenterLngUtm,
  centerMapPlot,
} from "../../../../redux/actions/resultsActions";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class ExportAreaSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <h3
          className="raster-settings-header"
          style={{ marginBottom: "24px", marginTop: "12px" }}
        >{`${t("MapPlotSettings")}`}</h3>
        <div
          className={`raster-settings-btn-wrapper`}
          style={{ marginBottom: "18px" }}
        >
          <button
            className={`raster-settings-btn ${
              this.props.isMapPlotDraggable ? `raster-settings-btn-active` : ""
            }`}
            style={{
              marginTop: "8px",
              borderRadius: "2px",
              padding: "7px 10px 7px 10px",
              fontSize: "11px",
            }}
            onClick={this.props.toggleMapPlotDraggable}
          >
            <div className="btn-content-wrapper">
              <Move color={"#494949"} size={"12px"} strokeWidth={"1.8px"} />
              <div className="raster-btn-lbl">{t("MoveCentre")}</div>
            </div>
          </button>
          <button
            className="raster-settings-btn"
            style={{
              marginTop: "8px",
              borderRadius: "2px",
              padding: "7px 10px 7px 10px",
              fontSize: "11px",
            }}
            onClick={this.props.centerMapPlot}
          >
            <div className="btn-content-wrapper">
              <Crosshair
                color={"#494949"}
                size={"12px"}
                strokeWidth={"1.8px"}
              />
              <div className="raster-btn-lbl">{t("DefaultCentre")}</div>
            </div>
          </button>
        </div>
        <NumericInput
          name={"CentreEasting"}
          label={t("CentreEasting")}
          value={this.props.mapPlotCenterLngUtm}
          maxLength={"9"}
          onChange={(e) =>
            this.props.setMapPlotCenterLngUtm({
              mapPlotCenterLngUtm: e.target.value,
              onDragend: false,
            })
          }
          unit={"m"}
        />
        <NumericInput
          name={"CentreNorthing"}
          label={t("CentreNorthing")}
          value={this.props.mapPlotCenterLatUtm}
          maxLength={"12"}
          onChange={(e) =>
            this.props.setMapPlotCenterLatUtm({
              mapPlotCenterLatUtm: e.target.value,
              onDragend: false,
            })
          }
          step={1}
          unit={"m"}
        />
        <hr className="raster-settings-ruler" />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    resultsJsonOrigin: state.results.resultsJsonOrigin,
    currentViewIndex: state.results.currentViewIndex,
    mapPlotCenterLatUtm: state.results.mapPlotCenterLatUtm,
    mapPlotCenterLngUtm: state.results.mapPlotCenterLngUtm,
    isMapPlotDraggable: state.results.isMapPlotDraggable,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleMapPlotDraggable: () => dispatch(toggleMapPlotDraggable()),
    setMapPlotCenterLatUtm: (payload) =>
      dispatch(setMapPlotCenterLatUtm(payload)),
    setMapPlotCenterLngUtm: (payload) =>
      dispatch(setMapPlotCenterLngUtm(payload)),
    centerMapPlot: () => dispatch(centerMapPlot()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(ExportAreaSettings)));
