import React from "react";
import { useSelector } from "react-redux";
import { Polygon } from "react-leaflet";

export const ObjectPropCopies = () => {
  const assignedCopyCoords = useSelector(
    (state) => state.map.assignedCopyCoords
  );
  return (
    <>
      {assignedCopyCoords &&
        assignedCopyCoords.map((x) => (
          <Polygon
            positions={x}
            color="red"
            fillColor="yellow"
            fillOpacity={"1"}
            dashArray={"30 10"}
          />
        ))}
    </>
  );
};
