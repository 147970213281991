import { types } from "../types/wind";
import {
  smallWindTableHeader,
  smallWindTable,
  bigWindTableHeader,
  bigWindTable,
} from "../../Components/Map/consts/consts";

const initialState = {
  windStatisticSource: {
    label: "DWDClimateDataCenter",
    value: "DWDClimateDataCenter",
  },
  windDirectionsNumber: {
    label: "8WindDirections",
    value: 8,
  },
  measurementHeight: 10,
  measurementLocation: {
    label: "CityCentre",
    value: "CityCentre",
  },
  measurementAlpha: 0.27,
  simDomainLocation: {
    label: "FromStaticDriver",
    value: "FromStaticDriver",
  },
  simDomainAlpha: 0.27,
  optionalVariablesAveraging: [
    {
      value: ["MeanWindfield3D"],
      label: "MeanWindfield3D",
    },
  ],
  windHeader: smallWindTableHeader,
  windTable: smallWindTable,
  windTableFileName: "",
  windTableFile: "",
};

const windReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_WIND_STATISTIC_SOURCE:
      if (action.payload.value === "UserDefinedTable") {
        return {
          ...state,
          windStatisticSource: action.payload,
          windHeader: smallWindTableHeader,
          windTable: smallWindTable,
          windTableFileName: "",
          windTableFile: "",
          windDirectionsNumber: {
            label: "8WindDirections",
            value: 8,
          },
        };
      }
      return {
        ...state,
        windStatisticSource: action.payload,
        windTableFileName: "",
        windTableFile: "",
      };
    case types.SET_MEAUSEREMENT_HEIGHT:
      return {
        ...state,
        measurementHeight: action.payload,
      };
    case types.SET_WIND_DIRECTIONS_NUMBER:
      if (action.payload.value === 12) {
        return {
          ...state,
          windDirectionsNumber: action.payload,
          windTable: bigWindTable,
          windHeader: bigWindTableHeader,
        };
      }
      return {
        ...state,
        windDirectionsNumber: action.payload,
        windTable: smallWindTable,
        windHeader: smallWindTableHeader,
      };
    case types.SET_MEASUREMENT_LOCATION: {
      const measurementLocation = action.payload;
      let locationValue = action.payload.value;
      let measurementAlpha = state.measurementAlpha;
      if (locationValue === "CityCentre") {
        measurementAlpha = 0.27;
      } else if (locationValue === "City") {
        measurementAlpha = 0.25;
      } else if (locationValue === "Grassland") {
        measurementAlpha = 0.12;
      } else if (locationValue === "Forest") {
        measurementAlpha = 0.25;
      } else if (locationValue === "Farmland") {
        measurementAlpha = 0.16;
      }

      return {
        ...state,
        measurementLocation: measurementLocation,
        measurementAlpha: measurementAlpha,
      };
    }

    case types.SET_MEASUREMENT_ALPHA: {
      return {
        ...state,
        measurementAlpha: action.payload,
      };
    }

    case types.SET_SIM_DOMAIN_LOCATION: {
      const simDomainLocation = action.payload;
      let locationValue = action.payload.value;
      let simDomainAlpha = state.simDomainAlpha;
      if (locationValue === "CityCentre") {
        simDomainAlpha = 0.27;
      } else if (locationValue === "City") {
        simDomainAlpha = 0.25;
      } else if (locationValue === "Grassland") {
        simDomainAlpha = 0.12;
      } else if (locationValue === "Forest") {
        simDomainAlpha = 0.25;
      } else if (locationValue === "Farmland") {
        simDomainAlpha = 0.16;
      }

      return {
        ...state,
        simDomainLocation: simDomainLocation,
        simDomainAlpha: simDomainAlpha,
      };
    }
    case types.SET_SIM_DOMAIN_ALPHA: {
      return {
        ...state,
        simDomainAlpha: action.payload,
      };
    }

    case types.UPDATE_WIND_TABLE:
      return {
        ...state,
        windTable: action.payload,
      };

    case types.RESET_WIND_SETTINGS: {
      return {
        ...initialState,
      };
    }

    case types.SET_WIND_TABLE_FILE: {
      return {
        ...state,
        windTableFile: action.payload.windTableFile,
        windTableFileName: action.payload.windTableFileName,
      };
    }

    case types.LOAD_WIND_SETTINGS: {
      const settings = action.payload.setup.settings;
      const windParameters = settings.wind_comfort_parameters;
      let windTableFileName = windParameters.inputFile;

      if (windTableFileName) {
        windTableFileName = windParameters.inputFile.substring(
          windParameters.inputFile.lastIndexOf("/") + 1
        );
      }

      const windStatisticSource = {
        label: windParameters.windStatisticSource.value,
        value: windParameters.windStatisticSource.value,
      };

      const windDirectionsNumber = {
        label: windParameters.windDirectionsNumber + "WindDirections",
        value: windParameters.windDirectionsNumber,
      };
      const measurementHeight = windParameters.measurementHeight;

      const measurementLocation = {
        label: windParameters.measurementLocation,
        value: windParameters.measurementLocation,
      };
      const measurementAlpha = windParameters.measurementAlpha;

      const simDomainLocation = {
        label: windParameters.simDomainLocation,
        value: windParameters.simDomainLocation,
      };

      const simDomainAlpha = windParameters.simDomainAlpha;
      const windHeader = windParameters.windHeader;
      const windTable = windParameters.windTable;

      return {
        ...state,
        windStatisticSource: windStatisticSource,
        windDirectionsNumber: windDirectionsNumber,
        measurementHeight: measurementHeight,
        measurementLocation: measurementLocation,
        measurementAlpha: measurementAlpha,
        simDomainLocation: simDomainLocation,
        simDomainAlpha: simDomainAlpha,
        windHeader: windHeader,
        windTable: windTable,
        windTableFileName: windTableFileName,
      };
    }

    default:
      return state;
  }
};

export default windReducer;
