import React from "react";
import Select from "react-select";
import ExpertCheckboxTable from "../../ExpertUtils/ExpertCheckboxTable/ExpertCheckboxTable";
import {
  ExpertHelpLabel,
  ExpertInput,
} from "../../ExpertUtils/ExpertComponent";
import { FormSettingsSeparator } from "../../../../Components/Common/Form/FormSeparator/index";
import { plantCanopyTableData } from "../../expertConst";
import { selectSetupStyle } from "../../../../Components/Common/PaletteDropdown/index";
import { helpLink } from "../../expertConst";
import { changeExpertInput } from "../../../../redux/actions/expertActions";
import { useSelector, useDispatch } from "react-redux";

const [page, subpage] = ["modules", "plant_canopy"];

const PlantCanopy = (props) => {
  const plant_canopy = useSelector(
    (state) => state.expert.expertJson.modules.plant_canopy
  );
  const dispatch = useDispatch();

  return (
    <>
      <ExpertHelpLabel label={"Activate Module"} helpLink={``} />
      <Select
        isSearchable={false}
        styles={selectSetupStyle}
        options={[
          {
            value: "True",
            label: "True",
          },
          { value: "False", label: "False" },
        ]}
        value={{
          label: !!plant_canopy.isActive ? "True" : "False",
          value: !!plant_canopy.isActive ? "True" : "False",
        }}
        onChange={(e) =>
          dispatch(
            changeExpertInput({
              page: page,
              subpage: subpage,
              key: "",
              subkey: "isActive",
              value: e.value === "True" ? true : false,
            })
          )
        }
      />
      <div style={{ marginBottom: 12 }} />

      {plant_canopy.isActive && (
        <>
          <ExpertHelpLabel
            label={"Plant Canopy Mode"}
            helpLink={`${helpLink}/plant_canopy_parameters#canopy_mode`}
          />
          <Select
            isSearchable={false}
            styles={selectSetupStyle}
            options={[
              {
                value: "read_from_file",
                label: "Read from file",
              },
            ]}
            value={{
              value: "read_from_file",
              label: "Read from file",
            }}
          />
          <div style={{ marginBottom: 12 }} />
          <ExpertHelpLabel
            label={"Plant Canopy Drag Coefficient [-]"}
            helpLink={`${helpLink}/plant_canopy_parameters#canopy_drag_coeff`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="parameters"
            subkey="canopy_drag_coeff"
            min={0.01}
            max={999.9}
            step={0.01}
            constValue={plant_canopy.parameters.canopy_drag_coeff}
          />
          <ExpertHelpLabel
            label={"Av. Heat Flux at top of plant canopy [K m/s]"}
            helpLink={`${helpLink}/plant_canopy_parameters#cthf`}
          />
          <input
            className="setup-input"
            name="num"
            type="number"
            min={0}
            max={999999.9}
            step={0.01}
            value={plant_canopy.parameters.cthf}
            onChange={(e) =>
              dispatch(
                changeExpertInput({
                  page: page,
                  subpage: subpage,
                  key: "parameters",
                  subkey: "cthf",
                  value: e.target.value,
                })
              )
            }
          />

          <ExpertHelpLabel
            label={"Allow Plant Canopy Transpiration"}
            helpLink={`${helpLink}/plant_canopy_parameters#plant_canopy_transpiration`}
          />
          <Select
            isSearchable={false}
            styles={selectSetupStyle}
            options={[
              {
                value: "True",
                label: "True",
              },
              { value: "False", label: "False" },
            ]}
            value={{
              label: !!plant_canopy.parameters.plant_canopy_transpiration
                ? "True"
                : "False",
              value: !!plant_canopy.parameters.plant_canopy_transpiration
                ? "True"
                : "False",
            }}
            onChange={(e) =>
              dispatch(
                changeExpertInput({
                  page: page,
                  subpage: subpage,
                  key: "parameters",
                  subkey: "plant_canopy_transpiration",
                  value: e.value === "True" ? true : false,
                })
              )
            }
          />
          <FormSettingsSeparator
            style={{ fontSize: "17px" }}
            label={"Output Quantities"}
          />
          <div
            className={"table-animation"}
            style={{ marginTop: "12px", minWidth: "805px" }}
          >
            <ExpertCheckboxTable
              tableData={plantCanopyTableData}
              rowData={props.rowData}
            />
          </div>
        </>
      )}
    </>
  );
};

export default PlantCanopy;
