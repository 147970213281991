export const types = {
  CHANGE_EXPERT_PAGE: "CHANGE_EXPERT_PAGE",
  CHANGE_EXPERT_INPUT: "CHANGE_EXPERT_INPUT",
  CHANGE_EXPERT_TIME_INPUT: "CHANGE_EXPERT_TIME_INPUT",
  CHANGE_EXPERT_TEMPERATURE_INPUT: "CHANGE_EXPERT_TEMPERATURE_INPUT",
  CHANGE_EXPERT_TEMP_INPUT: "CHANGE_EXPERT_TEMP_INPUT",
  CHANGE_EXPERT_DROPDOWN_INPUT: "CHANGE_EXPERT_DROPDOWN_INPUT",
  UPDATE_CHECKBOX_EXPERT_TABLE: "UPDATE_CHECKBOX_EXPERT_TABLE",
  UPDATE_LAND_SURFACE_TABLE: "UPDATE_LAND_SURFACE_TABLE",
  UPDATE_LAND_SURFACE_TABLE_ROWS: "UPDATE_LAND_SURFACE_TABLE_ROWS",
  CHANGE_EXPERT_DATE_TIME: "CHANGE_EXPERT_DATE_TIME",
  UPDATE_VECTOR_TABLE_ROWS: "UPDATE_VECTOR_TABLE_ROWS",
  UPDATE_VECTOR_TABLE: "UPDATE_VECTOR_TABLE",
  CHANGE_EXPERT_RUNTIME_TEMPLATE: "CHANGE_EXPERT_RUNTIME_TEMPLATE",
  CHANGE_EXPERT_RADIATION_SCHEME: "CHANGE_EXPERT_RADIATION_SCHEME",
  CHANGE_EXPERT_RADIATION_MODEL: "CHANGE_EXPERT_RADIATION_MODEL",
  LOAD_EXPERT_JSON: "LOAD_EXPERT_JSON",
  RESET_EXPERT_JSON: "RESET_EXPERT_JSON",
};
