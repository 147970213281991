import React from "react";
import Select from "react-select";
import {
  ExpertHelpLabel,
  ExpertTimeAccordion,
} from "../../ExpertUtils/ExpertComponent";
import { selectSetupStyle } from "../../../../Components/Common/PaletteDropdown/index";
import { helpLink } from "../../expertConst";
import { changeExpertDropdownInput } from "../../../../redux/actions/expertActions";
import { useSelector, useDispatch } from "react-redux";

const [page, subpage] = ["modules", "synthetic_turbulence"];

const SyntheticTurbulence = () => {
  const synthetic_turbulence = useSelector(
    (state) => state.expert.expertJson.modules.synthetic_turbulence
  );
  const dispatch = useDispatch();

  return (
    <>
      <ExpertHelpLabel label={"Activate Module"} helpLink={``} />
      <Select
        isSearchable={false}
        styles={selectSetupStyle}
        options={[
          {
            value: "True",
            label: "True",
          },
          { value: "False", label: "False" },
        ]}
        value={{
          label: !!synthetic_turbulence.isActive ? "True" : "False",
          value: !!synthetic_turbulence.isActive ? "True" : "False",
        }}
        onChange={(e) =>
          dispatch(
            changeExpertDropdownInput({
              page: page,
              subpage: subpage,
              key: "",
              subkey: "isActive",
              value: e.value,
            })
          )
        }
      />
      <div style={{ marginBottom: 12 }} />

      {synthetic_turbulence.isActive && (
        <>
          <ExpertHelpLabel
            label={"Local Computation of Velocity Seeds"}
            helpLink={`${helpLink}/stg_par#compute_velocity_seeds_local`}
          />
          <Select
            isSearchable={false}
            styles={selectSetupStyle}
            options={[
              {
                value: "True",
                label: "True",
              },
              { value: "False", label: "False" },
            ]}
            value={{
              label: !!synthetic_turbulence.parameters
                .compute_velocity_seeds_local
                ? "True"
                : "False",
              value: !!synthetic_turbulence.parameters
                .compute_velocity_seeds_local
                ? "True"
                : "False",
            }}
            onChange={(e) =>
              dispatch(
                changeExpertDropdownInput({
                  page: page,
                  subpage: subpage,
                  key: "parameters",
                  subkey: "compute_velocity_seeds_local",
                  value: e.value,
                })
              )
            }
          />
          <div style={{ marginBottom: 12 }} />

          <ExpertHelpLabel
            label={"Time interval to adjust turbulence statistics [s]"}
            helpLink={`${helpLink}/stg_par#dt_stg_adjust`}
          />
          <ExpertTimeAccordion
            page={page}
            subpage={subpage}
            section="parameters"
            subkey="dt_stg_adjust"
            min={0}
            max={9999999}
            constValue={synthetic_turbulence.parameters.dt_stg_adjust}
          />
          <div style={{ marginBottom: 12 }} />
          <ExpertHelpLabel
            label={"Time interval to call STG [s]"}
            helpLink={`${helpLink}/stg_par#dt_stg_call`}
          />
          <ExpertTimeAccordion
            page={page}
            subpage={subpage}
            section="parameters"
            subkey="dt_stg_call"
            min={0}
            max={9999999}
            constValue={synthetic_turbulence.parameters.dt_stg_call}
          />
        </>
      )}
    </>
  );
};

export default SyntheticTurbulence;
