import React from "react";
import Select from "react-select";
import ExpertCheckboxTable from "../../ExpertUtils/ExpertCheckboxTable/ExpertCheckboxTable";
import {
  ExpertHelpLabel,
  ExpertInput,
  ExpertTimeAccordion,
} from "../../ExpertUtils/ExpertComponent";
import { FormSettingsSeparator } from "../../../../Components/Common/Form/FormSeparator/index";
import { radiationTableData } from "../../expertConst";
import { selectSetupStyle } from "../../../../Components/Common/PaletteDropdown/index";
import { helpLink } from "../../expertConst";
import {
  changeExpertInput,
  changeExpertRadiationScheme,
} from "../../../../redux/actions/expertActions";
import { useSelector, useDispatch } from "react-redux";

const rowClassRules = {
  "expert-disabled": "rowIndex===6",
  "expert-disabled-prim": "rowIndex===12",
};

const [page, subpage] = ["modules", "radiation"];

const Radiation = (props) => {
  const radiation = useSelector(
    (state) => state.expert.expertJson.modules.radiation
  );
  const dispatch = useDispatch();

  return (
    <>
      <ExpertHelpLabel label={" Activate Module"} helpLink={""} />
      <Select
        isSearchable={false}
        styles={selectSetupStyle}
        options={[
          {
            value: "True",
            label: "True",
          },
          { value: "False", label: "False" },
        ]}
        value={{
          label: !!radiation.isActive ? "True" : "False",
          value: !!radiation.isActive ? "True" : "False",
        }}
        onChange={(e) =>
          dispatch(
            changeExpertInput({
              page: page,
              subpage: subpage,
              key: "",
              subkey: "isActive",
              value: e.value === "True" ? true : false,
            })
          )
        }
      />
      {radiation.isActive && (
        <>
          <FormSettingsSeparator
            style={{ fontSize: "17px" }}
            label={"Radiation Model"}
          />

          <ExpertHelpLabel
            label={"Radiation Scheme"}
            helpLink={`${helpLink}/radiation_parameters#radiation_scheme`}
          />
          <Select
            isSearchable={false}
            styles={selectSetupStyle}
            options={[
              {
                value: "clear-sky",
                label: "clear-sky",
              },
              { value: "rrtmg", label: "rrtmg" },
              { value: "external", label: "external" },
            ]}
            value={{
              value: radiation.parameters.radiation_scheme,
              label: radiation.parameters.radiation_scheme,
            }}
            onChange={(e) =>
              dispatch(
                changeExpertRadiationScheme({
                  value: e.value,
                })
              )
            }
          />
          <div style={{ marginBottom: 12 }} />
          <ExpertHelpLabel
            label={"Timestep of Radiation Model [s]"}
            helpLink={`${helpLink}/radiation_parameters#dt_radiation`}
          />
          <ExpertTimeAccordion
            page={page}
            subpage={subpage}
            section="parameters"
            subkey="dt_radiation"
            min={10}
            max={3600}
            constValue={radiation.parameters.dt_radiation}
          />
          <div style={{ marginBottom: 12 }} />

          <ExpertHelpLabel
            label={"Time after Radiation Model is switched on [s]"}
            helpLink={`${helpLink}/radiation_parameters#skip_time_do_radiation`}
          />
          <ExpertTimeAccordion
            page={page}
            subpage={subpage}
            section="parameters"
            subkey="skip_time_do_radiation"
            min={0}
            max={9999999}
            constValue={radiation.parameters.skip_time_do_radiation}
          />
          <div style={{ marginBottom: 12 }} />

          {radiation.parameters.radiation_scheme === "rrtmg" && (
            <>
              <ExpertHelpLabel
                label={"Switch off calculation of Longwave Radiation"}
                helpLink={`${helpLink}/radiation_parameters#lw_radiation`}
              />
              <Select
                isSearchable={false}
                styles={selectSetupStyle}
                options={[
                  {
                    value: "True",
                    label: "True",
                  },
                  { value: "False", label: "False" },
                ]}
                value={{
                  label: !!radiation.parameters.lw_radiation ? "True" : "False",
                  value: !!radiation.parameters.lw_radiation ? "True" : "False",
                }}
                onChange={(e) =>
                  dispatch(
                    changeExpertInput({
                      page: page,
                      subpage: subpage,
                      key: "parameters",
                      subkey: "lw_radiation",
                      value: e.value === "True" ? true : false,
                    })
                  )
                }
              />
              <div style={{ marginBottom: 12 }} />

              <ExpertHelpLabel
                label={"Switch off calculation of Shortwave Radiation"}
                helpLink={`${helpLink}/radiation_parameters#sw_radiation`}
              />
              <Select
                isSearchable={false}
                styles={selectSetupStyle}
                options={[
                  {
                    value: "True",
                    label: "True",
                  },
                  { value: "False", label: "False" },
                ]}
                value={{
                  label: !!radiation.parameters.sw_radiation ? "True" : "False",
                  value: !!radiation.parameters.sw_radiation ? "True" : "False",
                }}
                onChange={(e) =>
                  dispatch(
                    changeExpertInput({
                      page: page,
                      subpage: subpage,
                      key: "parameters",
                      subkey: "sw_radiation",
                      value: e.value === "True" ? true : false,
                    })
                  )
                }
              />
              <div style={{ marginBottom: 12 }} />
            </>
          )}

          <ExpertHelpLabel
            label={"Fix Surface Albedos"}
            helpLink={`${helpLink}/radiation_parameters#constant_albedo`}
          />
          <Select
            isSearchable={false}
            styles={selectSetupStyle}
            options={[
              {
                value: "True",
                label: "True",
              },
              { value: "False", label: "False" },
            ]}
            value={{
              label: !!radiation.parameters.constant_albedo ? "True" : "False",
              value: !!radiation.parameters.constant_albedo ? "True" : "False",
            }}
            onChange={(e) =>
              dispatch(
                changeExpertInput({
                  page: page,
                  subpage: subpage,
                  key: "parameters",
                  subkey: "constant_albedo",
                  value: e.value === "True" ? true : false,
                })
              )
            }
          />

          <FormSettingsSeparator
            style={{ fontSize: "17px" }}
            label={"Radiative Transfer Model (RTM)"}
          />
          <ExpertHelpLabel
            label={"Activate Radiative Transfer Model"}
            helpLink={`${helpLink}/radiation_parameters#radiation_interactions_on`}
          />
          <Select
            isSearchable={false}
            styles={selectSetupStyle}
            options={[
              {
                value: "True",
                label: "True",
              },
              { value: "False", label: "False" },
            ]}
            value={{
              label: !!radiation.parameters.radiation_interactions_on
                ? "True"
                : "False",
              value: !!radiation.parameters.radiation_interactions_on
                ? "True"
                : "False",
            }}
            onChange={(e) =>
              dispatch(
                changeExpertInput({
                  page: page,
                  subpage: subpage,
                  key: "parameters",
                  subkey: "radiation_interactions_on",
                  value: e.value === "True" ? true : false,
                })
              )
            }
          />
          <div style={{ marginBottom: 12 }} />

          <ExpertHelpLabel
            label={"Number of Reflection Steps [-]"}
            helpLink={`${helpLink}/radiation_parameters#nrefsteps`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="parameters"
            subkey="nrefsteps"
            min={1}
            max={9}
            step={1}
            constValue={radiation.parameters.nrefsteps}
          />

          <ExpertHelpLabel
            label={"LW Radiation-Interaction of Plant Canopy"}
            helpLink={`${helpLink}/radiation_parameters#plant_lw_interact`}
          />
          <Select
            isSearchable={false}
            styles={selectSetupStyle}
            options={[
              {
                value: "True",
                label: "True",
              },
              { value: "False", label: "False" },
            ]}
            value={{
              label: !!radiation.parameters.plant_lw_interact
                ? "True"
                : "False",
              value: !!radiation.parameters.plant_lw_interact
                ? "True"
                : "False",
            }}
            onChange={(e) =>
              dispatch(
                changeExpertInput({
                  page: page,
                  subpage: subpage,
                  key: "parameters",
                  subkey: "plant_lw_interact",
                  value: e.value === "True" ? true : false,
                })
              )
            }
          />
          <div style={{ marginBottom: 12 }} />

          <ExpertHelpLabel
            label={"Definition of MRT-Levels"}
            helpLink={`${helpLink}/radiation_parameters#mrt_nlevels`}
          />
          <Select
            isSearchable={false}
            styles={selectSetupStyle}
            options={[
              {
                value: "Derived from topography",
                label: "Derived from topography",
              },
              { value: "user-defined", label: "user-defined" },
            ]}
            value={{
              value:
                radiation.parameters.mrt_nlevels_mode === 1
                  ? "Derived from topography"
                  : "user-defined",
              label:
                radiation.parameters.mrt_nlevels_mode === 1
                  ? "Derived from topography"
                  : "user-defined",
            }}
            onChange={(e) =>
              dispatch(
                changeExpertInput({
                  page: page,
                  subpage: subpage,
                  key: "parameters",
                  subkey: "mrt_nlevels_mode",
                  value: e.value === "Derived from topography" ? 1 : 2,
                })
              )
            }
          />
          <div style={{ marginBottom: 12 }} />
          {radiation.parameters.mrt_nlevels_mode === 2 && (
            <>
              <ExpertHelpLabel
                label={"MRT Levels above surface [-]"}
                helpLink={`${helpLink}/radiation_parameters#mrt_nlevels`}
              />
              <ExpertInput
                page={page}
                subpage={subpage}
                section="parameters"
                subkey="mrt_nlevels"
                min={0}
                max={99999}
                step={1}
                constValue={radiation.parameters.mrt_nlevels}
              />
            </>
          )}
          <ExpertHelpLabel
            label={"Skip MRT-calculation above roof levels"}
            helpLink={`${helpLink}/radiation_parameters#mrt_skip_roof`}
          />
          <Select
            isSearchable={false}
            styles={selectSetupStyle}
            options={[
              {
                value: "True",
                label: "True",
              },
              { value: "False", label: "False" },
            ]}
            value={{
              label: !!radiation.parameters.rad_mrt_skip_roof
                ? "True"
                : "False",
              value: !!radiation.parameters.rad_mrt_skip_roof
                ? "True"
                : "False",
            }}
            onChange={(e) =>
              dispatch(
                changeExpertInput({
                  page: page,
                  subpage: subpage,
                  key: "parameters",
                  subkey: "rad_mrt_skip_roof",
                  value: e.value === "True" ? true : false,
                })
              )
            }
          />
          <div style={{ marginBottom: 12 }} />

          <ExpertHelpLabel
            label={"Allow Additional Calls of Radiation Code"}
            helpLink={`${helpLink}/radiation_parameters#unscheduled_radiation_calls`}
          />
          <Select
            isSearchable={false}
            styles={selectSetupStyle}
            options={[
              {
                value: "True",
                label: "True",
              },
              { value: "False", label: "False" },
            ]}
            value={{
              label: !!radiation.parameters.unscheduled_radiation_calls
                ? "True"
                : "False",
              value: !!radiation.parameters.unscheduled_radiation_calls
                ? "True"
                : "False",
            }}
            onChange={(e) =>
              dispatch(
                changeExpertInput({
                  page: page,
                  subpage: subpage,
                  key: "parameters",
                  subkey: "unscheduled_radiation_calls",
                  value: e.value === "True" ? true : false,
                })
              )
            }
          />
          <div style={{ marginBottom: 24 }} />

          <ExpertHelpLabel
            label={"Output Quantities"}
            helpLink={`${helpLink}/runtime_parameters#data_output`}
          />
          <div style={{ borderTop: "1px solid rgba(100, 105, 110, 0.62)" }} />
          <div
            className={"table-animation"}
            style={{ marginTop: "12px", minWidth: "805px" }}
          >
            <ExpertCheckboxTable
              tableData={radiationTableData}
              rowData={props.rowData}
              rowClassRules={rowClassRules}
            />
          </div>
        </>
      )}
    </>
  );
};

export default Radiation;
