import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import * as moment from "moment";
import { ArrowRight, Trash2 } from "react-feather";
import Page from "../../Components/Page/Page";
import Footer from "./Footer/Footer";
import * as projectActions from "./../../redux/actions/projectsActions";
import Table from "../../Components/Common/Table";
import IconCellRenderer from "../../Components/Common/Table/CellRenderers/IconCellRenderer";
import DefaultCellRenderer from "../../Components/Common/Table/CellRenderers/DefaultCellRenderer";
import DeleteModal from "../../Components/Common/DeleteModal/index";
import BMBF_en from "./BMBF_en.jpg";
import BMBF_de from "./BMBF_de.jpg";

import "moment/locale/de";
import "./Projects.scss";

const Projects = (props) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.projects?.projects);
  const prefferedLanguage = useSelector(
    (state) => state.userSettings?.settings?.preferredLanguage
  );

  const [deleteModal, setDeleteModal] = useState({
    show: false,
    data: null,
  });

  const showDeleteModal = (data) => setDeleteModal({ show: true, data: data });
  const closeDeleteModal = () =>
    setDeleteModal({
      show: false,
      data: null,
    });

  useEffect(() => {
    dispatch(projectActions.loadProjects());
  }, [dispatch]);

  const handleNewProjectCreation = async () => {
    history.push(`/projects/create`);
  };

  const showSelectedProject = async (loadedProject) => {
    history.push(`/projects/${loadedProject.id}/view`);
  };
  const deleteProject = async (loadedProject) => {
    dispatch(projectActions.deleteProject(loadedProject.id));
    closeDeleteModal();
  };
  const colDefs = [
    {
      headerName: t("Name"),
      field: "name",
      sortable: true,
    },
    {
      headerName: t("Description"),
      field: "description",
      sortable: true,
    },
    {
      headerName: t("CasesCount"),
      flex: 1,
      field: "casesCount",
      sortable: true,
    },
    {
      headerName: t("LastModified"),
      flex: 1,
      field: "lastModifiedTs",
      sortable: true,
      sort: "desc",
      valueFormatter: (params) => {
        const germanDateFormat = " D[.] MMMM Y HH:mm";
        if (prefferedLanguage === "de-DE") {
          return moment(params.value).local("de").format(germanDateFormat);
        }
        return moment(params.value).local("LLL").format("LLL");
      },
    },
    {
      headerName: "",
      cellRenderer: "iconCell",
      width: 40,
      cellRendererParams: {
        icon: ArrowRight,
        onClick: (data) => showSelectedProject(data),
      },
    },
    {
      headerName: "",
      cellRenderer: "iconCell",
      width: 40,
      cellRendererParams: {
        icon: Trash2,
        onClick: (data) => showDeleteModal(data),
      },
    },
  ];

  return (
    <>
      {
        <Page className="page-full-height">
          <div className="projects-header">
            <h1 style={{ fontSize: 32 }}>{t("RecentProjects")}</h1>
            <button
              className="wizard-button wizard-button-submit"
              onClick={handleNewProjectCreation}
            >
              {t("NewProject")}
            </button>
          </div>
          <Table
            colDefs={colDefs}
            rowData={projects}
            frameworkComponents={{
              iconCell: IconCellRenderer,
              defaultCell: DefaultCellRenderer,
            }}
          />
          <DeleteModal
            modalHdr={t("DeleteProject")}
            modalInfo={t(`DeleteProjectModalInfo`)}
            isOpen={deleteModal.show}
            closeDeleteModal={closeDeleteModal}
            deleteModalData={() =>
              deleteModal.data ? deleteProject(deleteModal.data) : null
            }
          />
        </Page>
      }
      <img
        className="start-img"
        src={i18n.language === "en-US" ? BMBF_en : BMBF_de}
        alt="flag"
      />
      <Footer />
    </>
  );
};

export default Projects;
