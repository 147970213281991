import React from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getDownloadLink } from "./../../api/storageApi";
import Page from "../../Components/Page/Page";
import { Loader } from "../../Components/Loader/index";
import { FileList } from "../../Components/Common/FileList/FileList";
import { ReactComponent as TiffLogo } from "../../static/icons/tiff.svg";
import { ReactComponent as JsonLogo } from "../../static/icons/json.svg";
import { ReactComponent as NcLogo } from "../../static/icons/nc.svg";
import { ReactComponent as TextLogo } from "../../static/icons/text.svg";
import { ReactComponent as SimLogo } from "../../static/icons/sim.svg";
import { selectCurrentCase } from "../../redux/selectors/caseSelector";

import "./CaseFileBrowser.scss";

const simTextResults = [
  "cpu",
  "header",
  "parin",
  "runControl",
  "stdout",
  "cpuChld",
  "headerChld",
  "parinChld",
  "runControlChld",
  "stdoutChld",
];

const CaseFileBrowser = () => {
  const { t } = useTranslation();
  const caseDto = useSelector(selectCurrentCase);

  const downloadFile = async (filePath) => {
    const url = await getDownloadLink(filePath);
    var link = document.createElement("a");
    link.download = "download";
    link.href = url;
    link.click();
  };

  const originalGeojson = {
    name: t("CityModel"),
    path: caseDto?.geojsonUrl || caseDto?.originalGeojsonUrl,
    icon: JsonLogo,
    readonly: true,
  };

  const geojson = {
    name: t("CheckedCityModel"),
    path: caseDto?.verifiedGeojsonUrl,
    icon: JsonLogo,
    readonly: true,
  };

  const originalTopography = {
    name: t("Topography"),
    path: caseDto?.originalTopographyUrl,
    icon: TiffLogo,
    readonly: true,
  };

  const userEdits = {
    name: t("UserEdits"),
    path: caseDto?.drawnGeojsonUrl,
    icon: JsonLogo,
    readonly: true,
  };

  const staticDriver = {
    name: t("StaticDriver"),
    path: caseDto?.staticDriverJob?.resultUrl || caseDto?.staticDriverUrl,
    readonly: true,
    icon: NcLogo,
  };

  const childStaticDriver = {
    name: t("ChildStaticDriver"),
    path: caseDto?.staticDriverJob?.childResultUrl,
    readonly: true,
    icon: NcLogo,
  };

  const simulationSetup = {
    name: t("SimulationSetup"),
    path: caseDto?.steering?.resultUrl,
    icon: SimLogo,
    readonly: true,
  };

  const inputFiles = [
    geojson,
    userEdits,
    staticDriver,
    childStaticDriver,
    simulationSetup,
  ].filter((f) => !!f.path);

  const monitoringFiles =
    caseDto?.simulationJobs?.reduce((files, job) => {
      return [
        ...files,
        ...job.results
          .filter((r) => simTextResults.includes(r.fileType))
          .map((r) => {
            return {
              name: t(r.fileType),
              path: r.filePath,
              icon: TextLogo,
              readonly: true,
            };
          }),
      ];
    }, []) ?? [];

  const outputFiles =
    caseDto?.simulationJobs?.reduce((files, job) => {
      return [
        ...files,
        ...job.results
          .filter((r) => !simTextResults.includes(r.fileType))
          .map((r) => {
            return {
              name: t(r.fileType),
              path: r.filePath,
              icon: NcLogo,
              readonly: true,
            };
          }),
      ];
    }, []) ?? [];

  const userUploadFiles = [originalGeojson, originalTopography].filter(
    (f) => !!f.path
  );

  return (
    <Page>
      {caseDto ? (
        <>
          <h2 style={{ fontSize: 28 }}>{t("Files")}</h2>
          <FileList
            items={userUploadFiles}
            label={t("UserUpload")}
            onDownload={(f) => downloadFile(f.path)}
          />
          <FileList
            items={inputFiles}
            label={t("Input")}
            onDownload={(f) => downloadFile(f.path)}
          />
          <FileList
            items={monitoringFiles}
            label={t("Monitioring")}
            onDownload={(f) => downloadFile(f.path)}
          />
          <FileList
            items={outputFiles}
            label={t("Output")}
            onDownload={(f) => downloadFile(f.path)}
          />
        </>
      ) : (
        <Loader />
      )}
    </Page>
  );
};

export default withRouter(CaseFileBrowser);
