import React from "react";
import Select from "react-select";
import {
  ExpertHelpLabel,
  ExpertInput,
} from "../../ExpertUtils/ExpertComponent";
import { FormSettingsSeparator } from "../../../../Components/Common/Form/FormSeparator/index";
import { selectSetupStyle } from "../../../../Components/Common/PaletteDropdown/index";
import { helpLink } from "../../expertConst";
import { changeExpertInput } from "../../../../redux/actions/expertActions";
import { useSelector, useDispatch } from "react-redux";

const [page, subpage] = ["grid", "nested_domain"];

const NestedDomain = () => {
  const nested_domain = useSelector(
    (state) => state.expert.expertJson.grid.nested_domain
  );

  const rasterParameters = useSelector(
    (state) => state.projects?.currentCase?.rasterParameters
  );

  const childRasterParameters = useSelector(
    (state) => state.projects?.currentCase?.childRasterParameters
  );

  const rasterParamsOnMount = useSelector(
    (state) => state.rasterArea?.currentCase?.rasterParameters
  );

  const childRasterParamsOnMount = useSelector(
    (state) => state.rasterArea?.currentCase?.childRasterParameters
  );

  const dispatch = useDispatch();

  return (
    <>
      <div style={{ marginBottom: -20 }} />
      <FormSettingsSeparator
        style={{ fontSize: "17px" }}
        label={"Grid: Parent"}
      />
      <ExpertHelpLabel
        label={"Grid Spacing in x,y,z direction [m]"}
        helpLink={`${helpLink}/initialization_parameters#dx`}
      />
      <input
        className="setup-input disabled"
        value={
          rasterParameters
            ? rasterParameters?.resolution
            : rasterParamsOnMount?.resolution
        }
        disabled
      />

      <ExpertHelpLabel
        label={"Number of grid points in x-direction [-]"}
        helpLink={`${helpLink}/initialization_parameters#nx`}
      />
      <input
        className="setup-input disabled"
        value={
          rasterParameters
            ? (rasterParameters.x_max - rasterParameters.x_min) /
              rasterParameters.resolution
            : (rasterParamsOnMount?.x_max - rasterParamsOnMount?.x_min) /
              rasterParamsOnMount?.resolution
        }
        disabled
      />

      <ExpertHelpLabel
        label={"Number of grid points in y-direction [-]"}
        helpLink={`${helpLink}/initialization_parameters#ny`}
      />
      <input
        className="setup-input disabled"
        value={
          rasterParameters
            ? (rasterParameters.y_max - rasterParameters.y_min) /
              rasterParameters.resolution
            : (rasterParamsOnMount?.y_max - rasterParamsOnMount?.y_min) /
              rasterParamsOnMount?.resolution
        }
        disabled
      />

      <ExpertHelpLabel
        label={"Method for nz-definition"}
        helpLink={`${helpLink}/initialization_parameters#nz`}
      />
      <Select
        isSearchable={false}
        styles={selectSetupStyle}
        options={[
          {
            value: "Autodetection",
            label: "Autodetection",
          },
          { value: "User-defined", label: "User-defined" },
        ]}
        value={{
          value:
            nested_domain.grid_parent.nz_type === 1
              ? "Autodetection"
              : "User-defined",
          label:
            nested_domain.grid_parent.nz_type === 1
              ? "Autodetection"
              : "User-defined",
        }}
        onChange={(e) =>
          dispatch(
            changeExpertInput({
              page: page,
              subpage: subpage,
              key: "grid_parent",
              subkey: "nz_type",
              value: e.value === "Autodetection" ? 1 : 2,
            })
          )
        }
      />
      {nested_domain.grid_parent.nz_type === 1 && (
        <>
          <div style={{ marginBottom: 12 }} />

          <ExpertHelpLabel
            label={"Minimum Height for nz-Autodetection [m]"}
            helpLink={`${helpLink}/initialization_parameters#nz`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="grid_parent"
            subkey="nz_minheight"
            min={100}
            max={999999}
            step={1}
            constValue={nested_domain.grid_parent.nz_minheight}
          />
        </>
      )}

      {nested_domain.grid_parent.nz_type === 2 && (
        <>
          <ExpertHelpLabel
            label={"Number of grid points in z-direction [-]"}
            helpLink={`${helpLink}/initialization_parameters#nz`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="grid_parent"
            subkey="nz_userdefined"
            min={50}
            max={999999}
            step={1}
            constValue={nested_domain.grid_parent.nz_userdefined}
          />
        </>
      )}

      <ExpertHelpLabel
        label={"Switch On Vertical Grid Stretchin"}
        helpLink={`${helpLink}/initialization_parameters#dz_stretch_level`}
      />
      <Select
        isSearchable={false}
        styles={selectSetupStyle}
        options={[
          {
            value: "True",
            label: "True",
          },
          { value: "False", label: "False" },
        ]}
        value={{
          label: !!nested_domain.grid_parent.dz_stretch_isActive
            ? "True"
            : "False",
          value: !!nested_domain.grid_parent.dz_stretch_isActive
            ? "True"
            : "False",
        }}
        onChange={(e) =>
          dispatch(
            changeExpertInput({
              page: page,
              subpage: subpage,
              key: "grid_parent",
              subkey: "dz_stretch_isActive",
              value: e.value === "True" ? true : false,
            })
          )
        }
      />

      {nested_domain.grid_parent.dz_stretch_isActive && (
        <>
          <div style={{ marginBottom: 12 }} />
          <ExpertHelpLabel
            label={"Height Level Start Vertical Grid Stretching [m]"}
            helpLink={`${helpLink}/initialization_parameters#dz_stretch_level`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="grid_parent"
            subkey="dz_stretch_level"
            min={1}
            max={999999}
            step={1}
            constValue={nested_domain.grid_parent.dz_stretch_level}
          />

          <ExpertHelpLabel
            label={"Allowed maximum vertical grid spacing [m]"}
            helpLink={`${helpLink}/initialization_parameters#dz_max`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="grid_parent"
            subkey="dz_max"
            min={1}
            max={999999}
            step={1}
            constValue={nested_domain.grid_parent.dz_max}
          />
          <ExpertHelpLabel
            label={"Stretch factor for a vertically stretched grid [-]"}
            helpLink={`${helpLink}/initialization_parameters#dz_stretch_factor`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="grid_parent"
            subkey="dz_stretch_factor"
            min={1.01}
            max={1.12}
            step={0.01}
            constValue={nested_domain.grid_parent.dz_stretch_factor}
          />
        </>
      )}
      <FormSettingsSeparator
        style={{ fontSize: "17px" }}
        label={"Grid: Child"}
      />

      <ExpertHelpLabel
        label={"Grid Spacing in x,y,z-direction [-]"}
        helpLink={`${helpLink}/initialization_parameters#dx`}
      />
      <input
        className="setup-input disabled"
        value={
          childRasterParameters
            ? childRasterParameters?.resolution
            : childRasterParamsOnMount?.resolution
        }
        disabled
      />

      <ExpertHelpLabel
        label={"Number of grid points in x-direction [-]"}
        helpLink={`${helpLink}/initialization_parameters#nx`}
      />
      <input
        className="setup-input disabled"
        value={
          childRasterParameters
            ? (childRasterParameters?.x_max - childRasterParameters?.x_min) /
              childRasterParameters?.resolution
            : (childRasterParamsOnMount?.x_max -
                childRasterParamsOnMount?.x_min) /
              childRasterParamsOnMount?.resolution
        }
        disabled
      />

      <ExpertHelpLabel
        label={"Number of grid points in y-direction [-]"}
        helpLink={`${helpLink}/initialization_parameters#ny`}
      />
      <input
        className="setup-input disabled"
        value={
          childRasterParameters
            ? (childRasterParameters?.y_max - childRasterParameters?.y_min) /
              childRasterParameters?.resolution
            : (childRasterParamsOnMount?.y_max -
                childRasterParamsOnMount?.y_min) /
              childRasterParamsOnMount?.resolution
        }
        disabled
      />

      <ExpertHelpLabel
        label={"Method for nz-Definition"}
        helpLink={`${helpLink}/initialization_parameters#nz`}
      />
      <Select
        isSearchable={false}
        styles={selectSetupStyle}
        options={[
          {
            value: "Autodetection",
            label: "Autodetection",
          },
          { value: "User-defined", label: "User-defined" },
        ]}
        value={{
          value:
            nested_domain.grid_child.nz_type === 1
              ? "Autodetection"
              : "User-defined",
          label:
            nested_domain.grid_child.nz_type === 1
              ? "Autodetection"
              : "User-defined",
        }}
        onChange={(e) =>
          dispatch(
            changeExpertInput({
              page: page,
              subpage: subpage,
              key: "grid_child",
              subkey: "nz_type",
              value: e.value === "Autodetection" ? 1 : 2,
            })
          )
        }
      />

      {nested_domain.grid_child.nz_type === 1 && (
        <>
          <div style={{ marginBottom: 12 }} />

          <ExpertHelpLabel
            label={"Minimum Height for nz-autodetection [m]"}
            helpLink={`${helpLink}/initialization_parameters#nz`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="grid_child"
            subkey="nz_minheight"
            min={100}
            max={999999}
            step={1}
            constValue={nested_domain.grid_child.nz_minheight}
          />
        </>
      )}
      {nested_domain.grid_child.nz_type === 2 && (
        <>
          <ExpertHelpLabel
            label={"Number of grid points in z-direction [-]"}
            helpLink={`${helpLink}/initialization_parameters#nz`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="grid_child"
            subkey="nz_userdefined"
            min={50}
            max={999999}
            step={1}
            constValue={nested_domain.grid_child.nz_userdefined}
          />
        </>
      )}
      <FormSettingsSeparator
        style={{ fontSize: "17px" }}
        label={"Topography"}
      />
      <ExpertHelpLabel
        label={"Topography Mode"}
        helpLink={`${helpLink}/initialization_parameters#topography`}
      />

      <Select
        isSearchable={false}
        styles={selectSetupStyle}
        options={[
          {
            value: "read_from_file",
            label: "read_from_file",
          },
        ]}
        value={{
          label: "read_from_file",
          value: "read_from_file",
        }}
      />

      <FormSettingsSeparator
        style={{ fontSize: "17px" }}
        label={"Runtime: Processor Settings - General"}
      />
      <ExpertHelpLabel label={"Mode for Processor Settings"} helpLink={``} />
      {/* tutaj dalej tutaj dalej */}
      <Select
        isSearchable={false}
        styles={selectSetupStyle}
        options={[
          {
            value: "Automatic",
            label: "Automatic",
          },
          { value: "UserDefined", label: "UserDefined" },
        ]}
        value={{
          value:
            nested_domain.runtime_processor_general.processor_mode === 1
              ? "Automatic"
              : "UserDefined",
          label:
            nested_domain.runtime_processor_general.processor_mode === 1
              ? "Automatic"
              : "UserDefined",
        }}
        onChange={(e) =>
          dispatch(
            changeExpertInput({
              page: page,
              subpage: subpage,
              key: "runtime_processor_general",
              subkey: "processor_mode",
              value: e.value === "Automatic" ? 1 : 2,
            })
          )
        }
      />

      {nested_domain.runtime_processor_general.processor_mode === 2 && (
        <>
          <div style={{ marginBottom: 12 }} />
          <ExpertHelpLabel
            label={"Number of available CPU-Cores [-]"}
            helpLink={``}
          />
          <input className="setup-input disabled" value={11111} disabled />
        </>
      )}
      <FormSettingsSeparator
        style={{ fontSize: "17px" }}
        label={"Runtime: Processor Settings - Parent"}
      />
      <ExpertHelpLabel
        label={"Parent: Number of used CPU-Cores [-]"}
        helpLink={`${helpLink}/runtime_parameters#pgrid`}
      />
      <input
        className="setup-input disabled"
        disabled
        value={
          nested_domain.runtime_processor_parent.par_npex *
          nested_domain.runtime_processor_parent.par_npey
        }
      />

      <ExpertHelpLabel
        label={"Parent: Number of processors along x-direction [-]"}
        helpLink={`${helpLink}/runtime_parameters#npex`}
      />
      <ExpertInput
        page={page}
        subpage={subpage}
        section="runtime_processor_parent"
        subkey="par_npex"
        min={2}
        max={99}
        step={1}
        constValue={nested_domain.runtime_processor_parent.par_npex}
      />

      <ExpertHelpLabel
        label={"Parent: Number of processors along y-direction [-]"}
        helpLink={`${helpLink}/runtime_parameters#npey`}
      />
      <ExpertInput
        page={page}
        subpage={subpage}
        section="runtime_processor_parent"
        subkey="par_npey"
        min={2}
        max={99}
        step={1}
        constValue={nested_domain.runtime_processor_parent.par_npey}
      />
      <FormSettingsSeparator
        style={{ fontSize: "17px" }}
        label={"Runtime: Processor Settings - Child"}
      />

      <ExpertHelpLabel
        label={"Child: Number of used CPU-Cores [-]"}
        helpLink={`${helpLink}/initialization_parametersx`}
      />
      <input
        className="setup-input"
        name="num"
        type="number"
        min="1"
        max="99"
        step="0.1"
        value={10}
        // onChange={(e) => this.props.setRelHumidity(e.target.value)}
      />

      <ExpertHelpLabel
        label={"Child: Number of processors along x-direction [-]"}
        helpLink={`${helpLink}/initialization_parametersx`}
      />
      <input
        className="setup-input"
        name="num"
        type="number"
        min="1"
        max="99"
        step="0.1"
        value={10}
        // onChange={(e) => this.props.setRelHumidity(e.target.value)}
      />

      <ExpertHelpLabel
        label={"Child: Number of processors along y-direction [-]"}
        helpLink={`${helpLink}/initialization_parametersx`}
      />
      <input
        className="setup-input"
        name="num"
        type="number"
        min="1"
        max="99"
        step="0.1"
        value={10}
        // onChange={(e) => this.props.setRelHumidity(e.target.value)}
      />

      <FormSettingsSeparator
        style={{ fontSize: "17px" }}
        label={"Initialization - Child"}
      />
      <ExpertHelpLabel
        label={"Initialization Actions"}
        helpLink={`${helpLink}/initialization_parameters#initializing_actions`}
      />
      <Select
        isSearchable={false}
        styles={selectSetupStyle}
        options={[
          {
            value: "interpolate from parent",
            label: "interpolate from parent",
          },
        ]}
        value={{
          label: "interpolate from parent",
          value: "interpolate from parent",
        }}
        disabled
      />

      <FormSettingsSeparator
        style={{ fontSize: "17px" }}
        label={"Module: Nesting"}
      />

      <ExpertHelpLabel
        label={"Activate Module"}
        helpLink={`${helpLink}/nesting_parameters`}
      />
      <Select
        isSearchable={false}
        styles={selectSetupStyle}
        options={[
          {
            value: "True",
            label: "True",
          },
        ]}
        value={{
          label: "True",
          value: "True",
        }}
        disabled
      />
      <div style={{ marginBottom: 12 }} />

      <ExpertHelpLabel
        label={"Nesting Mode"}
        helpLink={`${helpLink}/nesting_parameters#nesting_mode`}
      />
      <Select
        isSearchable={false}
        styles={selectSetupStyle}
        options={[
          {
            value: "one-way",
            label: "one-way",
          },
          {
            value: "two-way",
            label: "two-way",
          },
        ]}
        value={{
          label: nested_domain.nesting.nesting_mode,
          value: nested_domain.nesting.nesting_mode,
        }}
        onChange={(e) =>
          dispatch(
            changeExpertInput({
              page: page,
              subpage: subpage,
              key: "nesting",
              subkey: "nesting_mode",
              value: e.value,
            })
          )
        }
      />
      <div style={{ marginBottom: 12 }} />

      <ExpertHelpLabel
        label={"Type of Nesting Boundary Conditions"}
        helpLink={`${helpLink}/nesting_parameters#nesting_bounds`}
      />
      <Select
        isSearchable={false}
        styles={selectSetupStyle}
        options={[
          {
            value: "3d-nested",
            label: "3d-nested",
          },
        ]}
        value={{
          label: "3d-nested",
          value: "3d-nested",
        }}
        disabled
      />
      <div style={{ marginBottom: 12 }} />

      <ExpertHelpLabel
        label={"Nesting Datatransfer Mode"}
        helpLink={`${helpLink}/nesting_parameters#nesting_datatransfer_mode`}
      />
      <Select
        isSearchable={false}
        styles={selectSetupStyle}
        options={[
          {
            value: "mixed",
            label: "mixed",
          },
          {
            value: "cascade",
            label: "cascade",
          },
          {
            value: "overlap",
            label: "overlap",
          },
        ]}
        value={{
          label: nested_domain.nesting.nesting_datatransfer_mode,
          value: nested_domain.nesting.nesting_datatransfer_mode,
        }}
        disabled
      />
      <div style={{ marginBottom: 12 }} />

      <ExpertHelpLabel
        label={"Homogeneous Initialization Child"}
        helpLink={`${helpLink}/nesting_parameters#homogeneous_initialization_child`}
      />
      <Select
        isSearchable={false}
        styles={selectSetupStyle}
        options={[
          {
            value: "True",
            label: "True",
          },
          { value: "False", label: "False" },
        ]}
        value={{
          label: !!nested_domain.nesting.homogeneous_initialization_child
            ? "True"
            : "False",
          value: !!nested_domain.nesting.homogeneous_initialization_child
            ? "True"
            : "False",
        }}
        onChange={(e) =>
          dispatch(
            changeExpertInput({
              page: page,
              subpage: subpage,
              key: "nesting",
              subkey: "homogeneous_initialization_child",
              value: e.value === "True" ? true : false,
            })
          )
        }
      />
      <div style={{ marginBottom: 12 }} />

      <ExpertHelpLabel
        label={"Anterpolation Starting Height [-]"}
        helpLink={`${helpLink}/nesting_parameters#anterpolation_starting_height`}
      />
      <ExpertInput
        page={page}
        subpage={subpage}
        section="nesting"
        subkey="anterpolation_starting_height"
        min={0}
        max={999.9}
        step={0.1}
        constValue={nested_domain.nesting.anterpolation_starting_height}
      />
      <ExpertHelpLabel
        label={"Anterpolation Buffer Width (in Grid Points) [-]"}
        helpLink={`${helpLink}/nesting_parameters#anterpolation_buffer_width`}
      />
      <ExpertInput
        page={page}
        subpage={subpage}
        section="nesting"
        subkey="anterpolation_buffer_width"
        min={0}
        max={999}
        step={1}
        constValue={nested_domain.nesting.anterpolation_buffer_width}
      />
    </>
  );
};

export default NestedDomain;
