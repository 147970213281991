import React from "react";
import { ArrowRight, ArrowLeft } from "react-feather";
import "./WizardButton.scss";

function WizardButtonNext(props) {
  return (
    <WizardButton
      className="wizard-button-next"
      onClick={props.onClick}
      disabled={props.disabled}
      rightIcon={ArrowRight}
      text={props.text}
    ></WizardButton>
  );
}

function WizardButtonPrev(props) {
  return (
    <WizardButton
      className="wizard-button-prev"
      onClick={props.onClick}
      leftIcon={ArrowLeft}
      text={props.text}
    ></WizardButton>
  );
}

function WizardButtonSubmit(props) {
  return (
    <WizardButton
      className="wizard-button-submit"
      disabled={props.disabled}
      onClick={props.onClick}
      text={props.text}
    ></WizardButton>
  );
}
function WizardButton(props) {
  const Icon = (icon, className) => {
    const IconComponent = icon;
    return icon ? <IconComponent size={13} className={className} /> : null;
  };
  return (
    <button
      className={`wizard-button${props.className ? ` ${props.className}` : ""}`}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      <div className="btn-content-wrapper">
        {Icon(props.leftIcon, "left-icon")}
        <div className="btn-text-wrapper">{props.text}</div>
        {Icon(props.rightIcon, "right-icon")}
      </div>
    </button>
  );
}

export {
  WizardButtonNext,
  WizardButtonPrev,
  WizardButtonSubmit,
  WizardButton
};
