import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loader } from "../../Components/Loader";
import Domain from "./Domain";

class DomainProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (
      this.props.isLoading ||
      !this.props.loadedProject ||
      !this.props.currentCase
    ) {
      return <Loader />;
    }
    return <Domain key={this.props.currentCase.id} />;
  }
}

const mapStateToProps = (state) => {
  return {
    loadedProject: state.projects.loadedProject,
    isLoading: state.projects.isLoading,
    currentCase: state.projects.currentCase,
  };
};

export default withRouter(connect(mapStateToProps, null)(DomainProvider));
