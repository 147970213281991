import { types } from "../types/chemistry";
import {
  emissionsInitFactorTable,
  defaultPointEmissionTable,
} from "../../Components/Map/consts/consts";
import {
  generatePointEmissionsTable,
  getAssesmentTimeOptions,
  emissionsSubtypes,
} from "../../Components/Map/consts/consts";
import { cloneDeep } from "lodash";

const initialState = {
  season: { value: "Winter", label: "Winter" },
  startDay: "2022-01-17",
  duration: 30,
  airTemperatureSurface: -5,
  relHumidity: 60,
  windSpeed: 1.5,
  windDirection: { value: "North", label: "North" },
  meanTemperature: -4,
  amplitude: 4,
  soilMoisture: { value: "DrySoil", label: "DrySoil" },
  userDefinedSoilMoisture: 0.5,
  soilTemperature: {
    value: "PALMDefaultAutomaticAdjustmentToAirTemperature",
    label: "PALMDefaultAutomaticAdjustmentToAirTemperature",
  },
  userDefinedSoilTemperature: 297.05,
  inputPresets: {
    value: "Minimal",
    label: "Minimal",
  },
  outputPresets: { value: "Minimal", label: "Minimal" },
  surfaceCsFlux25: 85e-6,
  surfaceCsFlux10: 17e-5,
  emissionsFactorTable: emissionsInitFactorTable,
  outputParameters: [],
  mandatoryOutputParameters: [
    {
      value: [
        "theta_xy",
        "ta_xy",
        "theta_xy_av",
        "ta_xy_av",
        "theta_2m*_xy",
        "ta_2m*_xy",
      ],
      label: "Temperature",
      isDisabled: true,
    },
    {
      value: [
        "u_xy",
        "v_xy",
        "u_xy",
        "wspeed_xy",
        "wdir_xy",
        "u_xy_av",
        "v_xy_av",
        "u_xy_av",
        "wspeed_xy_av",
        "wdir_xy_av",
      ],
      label: "WindField",
      isDisabled: true,
    },
    // {
    //   value: ["tsurf*"],
    //   label: "SurfaceTemperature",
    //   isDisabled: true,
    // },
    {
      value: ["kc_PM10", "kc_PM25", "kc_PM10_av", "kc_PM25_av"],
      label: "ParticulateMatter",
      isDisabled: true,
    },
    {
      value: [
        "q",
        "rh",
        "q_av",
        "rh_av",
        "q_xy_av",
        "rh_xy_av",
        "q_xy",
        "rh_xy",
      ],
      label: "Humidity",
      isDisabled: true,
    },
  ],
  optionalOutputParameters: [
    {
      value: ["theta_av", "ta_av", "m_soil_av", "t_soil_av", "tsurf*_xy_av"],
      label: "AveragedTemperatureField(3D)",
    },
    {
      value: ["u_av", "v_av", "w_av", "wspeed_av", "wdir_av"],
      label: "AveragedWindField(3D)",
    },
    {
      value: ["kc_PM10_av", "kc_PM25_av"],
      label: "AveragedParticulateMatter(3D)",
      isDisabled: false,
    },
    {
      value: ["m_soil", "t_soil", "tsurf*_xy", "theta", "ta"],
      label: "InstantaneousTemperatureField(3D)",
      isDisabled: false,
    },
    {
      value: ["u", "v", "u", "wspeed", "wdir"],
      label: "InstantaneousWindField(3D)",
      isDisabled: false,
    },
    {
      value: ["kc_PM10", "kc_PM25"],
      label: "InstantaneousParticulateMatter(3D)",
      isDisabled: false,
    },
  ],
  mapOutputInterval: {
    value: 60,
    label: 60,
  },
  //outputTypeSelected: ["AverageOutput"],
  //outputTypeOptions: ["AverageOutput", "InstantaneousOutput"],
  verticalOutputInterval: {
    value: 60,
    label: 60,
  },
  timeSeriesOutputInterval: {
    value: 60,
    label: 60,
  },
  drawnMapPoiSelectedVerticalProfile: [],
  drawnMapPoiSelectedVerticalProfileOptions: [],
  drawnMapPoiSelectedTimeSeries: [],
  drawnMapPoiSelectedTimeSeriesOptions: [],
  assesmentTimeFrom: {
    value: "Day 1 - 09:00",
    label: "Day 1 - 09:00",
  },
  assesmentTimeTo: {
    value: "Day 1 - 09:00",
    label: "Day 1 - 09:00",
  },
  assesmentTimeOptions: getAssesmentTimeOptions({
    duration: 30,
    mapOutputInterval: 60,
  }),
  currentPointEmissionsTableIndex: 0,
  arrayOfPointEmissionTable: generatePointEmissionsTable(30),
};

const chemistryReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_CH_SEASON:
      return {
        ...state,
        season: action.payload,
      };
    case types.SET_CH_START_DAY:
      return {
        ...state,
        startDay: action.payload,
      };
    case types.SET_CH_DURATION: {
      const duration = action.payload;
      if (
        duration >>> 0 === parseFloat(duration) &&
        parseFloat(duration) < 55
      ) {
        const arrayOfPointEmissionTable = cloneDeep(
          state.arrayOfPointEmissionTable
        );
        if (duration > state.duration) {
          for (let index = 0; index < 5; index++) {
            let newRow = defaultPointEmissionTable[index][duration];
            arrayOfPointEmissionTable[index].push(newRow);
          }
        } else {
          for (let index = 0; index < 5; index++) {
            arrayOfPointEmissionTable[index].pop();
          }
        }

        return {
          ...state,
          duration: duration,
          arrayOfPointEmissionTable: arrayOfPointEmissionTable,
          assesmentTimeFrom: {
            value: "Day 1 - 09:00",
            label: "Day 1 - 09:00",
          },
          assesmentTimeTo: {
            value: "Day 1 - 09:00",
            label: "Day 1 - 09:00",
          },
          assesmentTimeOptions: getAssesmentTimeOptions({
            duration: duration,
            mapOutputInterval: state.mapOutputInterval.value,
          }),
        };
      }
      return {
        ...state,
      };
    }
    case types.SET_CH_AIR_TEMPERATURE_SURFACE:
      return {
        ...state,
        airTemperatureSurface: action.payload,
      };
    case types.SET_CH_REL_HUMIDITY:
      return {
        ...state,
        relHumidity: action.payload,
      };

    case types.SET_CH_WIND_SPEED:
      return {
        ...state,
        windSpeed: action.payload,
      };

    case types.SET_CH_WIND_DIRECTION:
      return {
        ...state,
        windDirection: action.payload,
      };

    case types.SET_CH_MEAN_TEMPERATURE:
      return {
        ...state,
        meanTemperature: action.payload,
      };

    case types.SET_CH_AMPLITUDE:
      return {
        ...state,
        amplitude: action.payload,
      };

    case types.SET_CH_SOIL_MOISTURE:
      return {
        ...state,
        soilMoisture: action.payload,
      };

    case types.SET_CH_SOIL_TEMPERATURE:
      return {
        ...state,
        soilTemperature: action.payload,
      };

    case types.SET_CH_USER_DEFINED_SOIL_MOISTURE: {
      return {
        ...state,
        userDefinedSoilMoisture: action.payload,
      };
    }

    case types.SET_CH_USER_DEFINED_SOIL_TEMPERATURE: {
      return {
        ...state,
        userDefinedSoilTemperature: action.payload,
      };
    }

    case types.SET_CH_INPUT_PRESETS:
      return {
        ...state,
        inputPresets: action.payload,
      };

    case types.SET_CH_OUTPUT_PRESETS:
      return {
        ...state,
        outputPresets: action.payload,
      };

    case types.SET_SURFACE_CS_FLUX_25:
      return {
        ...state,
        surfaceCsFlux25: action.payload,
      };

    case types.SET_SURFACE_CS_FLUX_10:
      return {
        ...state,
        surfaceCsFlux10: action.payload,
      };
    case types.SET_CH_OUTPUT_PARAMETERS:
      return {
        ...state,
        outputParameters: action.payload,
      };

    case types.SET_CH_MAP_OUTPUT_INTERVAL:
      return {
        ...state,
        mapOutputInterval: action.payload,
        assesmentTimeFrom: {
          value: "Day 1 - 09:00",
          label: "Day 1 - 09:00",
        },
        assesmentTimeTo: {
          value: "Day 1 - 09:00",
          label: "Day 1 - 09:00",
        },
        assesmentTimeOptions: getAssesmentTimeOptions({
          duration: state.duration,
          mapOutputInterval: action.payload.value,
        }),
      };

    // case types.SET_CH_OUTPUT_TYPE:
    //   const changedOutputType = action.payload.changedOutputType;
    //   const isChecked = action.payload.isChecked;
    //   let outputTypeSelected = state.outputTypeSelected;

    //   if (isChecked) {
    //     outputTypeSelected.push(changedOutputType);
    //   } else {
    //     outputTypeSelected = outputTypeSelected.filter(
    //       (x) => x !== changedOutputType
    //     );
    //   }
    //   return {
    //     ...state,
    //     outputTypeSelected: outputTypeSelected,
    //   };

    case types.SET_CH_VERTICAL_OUTPUT_INTERVAL:
      return {
        ...state,
        verticalOutputInterval: action.payload,
      };

    case types.SET_CH_TIME_SERIES_OUTPUT_INTERVAL:
      return {
        ...state,
        timeSeriesOutputInterval: action.payload,
      };

    case types.SET_CH_VERTICAL_POI:
      return {
        ...state,
        drawnMapPoiSelectedVerticalProfile: action.payload,
      };

    case types.SET_CH_TIME_SERIES_POI:
      return {
        ...state,
        drawnMapPoiSelectedTimeSeries: action.payload,
      };

    case types.SET_CH_INITIAL_POI_LIST:
      const initialPoiList = action.payload;
      return {
        ...state,
        drawnMapPoiSelectedVerticalProfile: initialPoiList,
        drawnMapPoiSelectedTimeSeries: initialPoiList,
        drawnMapPoiSelectedVerticalProfileOptions: initialPoiList,
        drawnMapPoiSelectedTimeSeriesOptions: initialPoiList,
      };

    case types.SET_CH_ASSESMENT_TIME_FROM:
      return {
        ...state,
        assesmentTimeFrom: action.payload,
      };

    case types.SET_CH_ASSESMENT_TIME_TO:
      return {
        ...state,
        assesmentTimeTo: action.payload,
      };

    case types.UPDATE_CH_EMISSIONS_FACTOR_TABLE:
      return {
        ...state,
        emissionsFactorTable: action.payload,
      };

    case types.UPDATE_CH_POINT_EMISSIONS_TABLE: {
      return {
        ...state,
        arrayOfPointEmissionTable: state.arrayOfPointEmissionTable.map(
          (p, i) => {
            if (i !== state.currentPointEmissionsTableIndex) {
              return p;
            }
            return state.arrayOfPointEmissionTable[
              state.currentPointEmissionsTableIndex
            ].map((z, j) => {
              if (j === action.payload.rowIndex) {
                return action.payload.rowData;
              }
              return z;
            });
          }
        ),
      };
    }

    case types.SET_POINT_EMISSION_TABLE_TYPE: {
      const currentIndex = emissionsSubtypes.findIndex((x) => {
        return x.value === action.payload;
      });
      return {
        ...state,
        currentPointEmissionsTableIndex: currentIndex,
      };
    }

    case types.LOAD_CHEMISTRY_SETTINGS: {
      const settings = action.payload;
      const runtimeParameters = settings.runtime_parameters;
      const initializationParameters = settings.initialization_parameters;
      const chemistryParameters = settings.chemistry_parameters;

      const season = {
        value: chemistryParameters.season,
        label: chemistryParameters.season,
      };
      const startDay = initializationParameters.origin_date_time.slice(0, -13);
      const duration = Number(runtimeParameters.end_time) / 3600;
      const airTemperatureSurface = (
        initializationParameters.pt_surface - 273.15
      ).toFixed(1);
      const relHumidity = initializationParameters.q_surface;
      const windSpeed = chemistryParameters.wind_speed;
      const windDirection = {
        value: chemistryParameters.wind_direction,
        label: chemistryParameters.wind_direction,
      };

      const meanTemperature = (
        initializationParameters.spinup_pt_mean - 273.15
      ).toFixed(1);
      const amplitude = initializationParameters.spinup_pt_amplitude;

      const soilMoisture = {
        value: chemistryParameters.soil_moisture,
        label: chemistryParameters.soil_moisture,
      };
      const soilTemperature = {
        value: chemistryParameters.soil_temperature,
        label: chemistryParameters.soil_temperature,
      };

      const userDefinedSoilMoisture = chemistryParameters.user_soil_moisture;
      const userDefinedSoilTemperature =
        chemistryParameters.user_soil_temperature;

      let inputPresets = state.inputPresets;
      let surfaceCsFlux25 = state.surfaceCsFlux25;
      let surfaceCsFlux10 = state.surfaceCsFlux10;
      let emissionsFactorTable = state.emissionsFactorTable;
      let arrayOfPointEmissionTable = state.arrayOfPointEmissionTable;

      if (chemistryParameters.surface_cs_flux_25) {
        inputPresets = {
          value: "UserDefinedEmissions",
          label: "UserDefinedEmissions",
        };
        surfaceCsFlux25 = chemistryParameters.surface_cs_flux_25;
        surfaceCsFlux10 = chemistryParameters.surface_cs_flux_10;
        emissionsFactorTable = chemistryParameters.emissions_factor_table;
        arrayOfPointEmissionTable = chemistryParameters.point_emissions_table;
      }

      let outputPresets = state.outputPresets;
      let outputParameters = state.outputParameters;
      let mapOutputInterval = state.mapOutputInterval;
      let verticalOutputInterval = state.verticalOutputInterval;
      let drawnMapPoiSelectedVerticalProfile =
        state.drawnMapPoiSelectedVerticalProfile;
      let timeSeriesOutputInterval = state.timeSeriesOutputInterval;
      let drawnMapPoiSelectedTimeSeries = state.drawnMapPoiSelectedTimeSeries;
      let assesmentTimeFrom = state.assesmentTimeFrom;
      let assesmentTimeTo = state.assesmentTimeTo;

      if (chemistryParameters.map_output_interval) {
        outputPresets = { value: "UserDefined", label: "UserDefined" };
        outputParameters = runtimeParameters.data_output.filter((x) => {
          return (
            x.label !== "Temperature" &&
            x.label !== "ParticulateMatter" &&
            x.label !== "WindField" &&
            x.label !== "ParticulateMatter" &&
            x.label !== "Humidity"
          );
        });
        mapOutputInterval = {
          value: chemistryParameters.map_output_interval,
          label: chemistryParameters.map_output_interval,
        };
        verticalOutputInterval = {
          label: chemistryParameters.vertical_output_interval,
          value: chemistryParameters.vertical_output_interval,
        };
        drawnMapPoiSelectedVerticalProfile =
          chemistryParameters.vertical_map_poi_selected.map((x) => {
            return {
              label: x,
              value: x,
            };
          });
        timeSeriesOutputInterval = {
          label: chemistryParameters.time_series_output_interval,
          value: chemistryParameters.time_series_output_interval,
        };
        drawnMapPoiSelectedTimeSeries =
          chemistryParameters.time_series_map_poi_selected.map((x) => {
            return {
              label: x,
              value: x,
            };
          });
        assesmentTimeFrom = {
          label: chemistryParameters.assesment_time_from,
          value: chemistryParameters.assesment_time_from,
        };
        assesmentTimeTo = {
          label: chemistryParameters.assesment_time_to,
          value: chemistryParameters.assesment_time_to,
        };
      }
      return {
        ...state,
        season: season,
        startDay: startDay,
        duration: duration,
        airTemperatureSurface: airTemperatureSurface,
        relHumidity: relHumidity,
        windSpeed: windSpeed,
        windDirection: windDirection,
        meanTemperature: meanTemperature,
        amplitude: amplitude,
        soilMoisture: soilMoisture,
        soilTemperature: soilTemperature,
        inputPresets: inputPresets,
        surfaceCsFlux25: surfaceCsFlux25,
        surfaceCsFlux10: surfaceCsFlux10,
        emissionsFactorTable: emissionsFactorTable,
        arrayOfPointEmissionTable: arrayOfPointEmissionTable,
        outputPresets: outputPresets,
        outputParameters: outputParameters,
        mapOutputInterval: mapOutputInterval,
        verticalOutputInterval: verticalOutputInterval,
        drawnMapPoiSelectedVerticalProfile: drawnMapPoiSelectedVerticalProfile,
        timeSeriesOutputInterval: timeSeriesOutputInterval,
        drawnMapPoiSelectedTimeSeries: drawnMapPoiSelectedTimeSeries,
        assesmentTimeFrom: assesmentTimeFrom,
        assesmentTimeTo: assesmentTimeTo,
        userDefinedSoilMoisture: userDefinedSoilMoisture,
        userDefinedSoilTemperature: userDefinedSoilTemperature,
        assesmentTimeOptions: getAssesmentTimeOptions({
          duration: duration,
          mapOutputInterval: mapOutputInterval.value,
        }),
      };
    }

    case types.SET_CH_POI_OPTIONS: {
      return {
        ...state,
        drawnMapPoiSelectedVerticalProfileOptions: action.payload,
        drawnMapPoiSelectedTimeSeriesOptions: action.payload,
      };
    }

    case types.RESET_CHEMISTRY_SETTINGS: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

export default chemistryReducer;
