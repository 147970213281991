import { apiRequest } from "./axiosApi";

const apiUrl = process.env.REACT_APP_FRONT_SERVICE_API;

export async function getUsers(searchPhrase) {
  if(!searchPhrase) {
    return [];
  }
  const response = await apiRequest({
    method: "get",
    url: `${apiUrl}/admin/users/search/${searchPhrase}`,
  });
  return response.data;
}

export async function updateUser(user) {
  if(!user) {
    return null;
  }
  const response = await apiRequest({
    method: "post",
    url: `${apiUrl}/admin/users`,
    data: user
  });
  return response.data;
}