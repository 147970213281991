import i18n from "../translations/i18n";

export function getFriendlyDateText(date) {
  const dateDiff = new Date() - new Date(date);
  if (dateDiff < 1000 * 60) {
    return `${i18n.t("MomentAgo")}`;
  }
  if (dateDiff < 1000 * 60 * 60) {
    const minutes = Math.round(((dateDiff % 86400000) % 3600000) / 60000);
    return i18n.t("MinutesAgo", { minutes });
  }
  if (dateDiff < 1000 * 60 * 60 * 24) {
    const hours = Math.floor((dateDiff % 86400000) / 3600000);
    return i18n.t("HoursAgo", { hours });
  }
  const days = Math.floor(dateDiff % 86400000);
  return i18n.t("DaysAgo", { days });
}

export const minutesToHoursMinutesSeconds = (mins) => {
  if (!mins) {
    return "00:00:00";
  }
  let hours = Math.floor(mins / 60);
  let minutes = mins % 60;
  var seconds = Math.floor((Math.abs(mins) * 60) % 60);
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes.toFixed(0) : minutes.toFixed(0);
  seconds = seconds < 10 ? "0" + seconds.toFixed(0) : seconds.toFixed(0);
  return `${hours}:${minutes}:${seconds}`;
};
