import React from "react";
import LayerSettingsForm from "./LayerSettingsForm/LayerSettingsForm";
import LayerAppearance from "./LayerAppearance/LayerAppearance";
import DeleteModal from "../../../../Components/Common/DeleteModal/index";
import { TextInput } from "../../../../Components/Common/Form/Form";
import { TextArea } from "../../../../Components/Map/Form/TextArea";
import Icon from "../../../../Components/Common/Icon/index";
import {
  Database,
  PenTool,
  Trash,
  ArrowUp,
  ArrowDown,
  PlusCircle,
} from "react-feather";
import { LayerSettingsInput } from "../../../../Components/Common/Form/Form";
import {
  toggleLayerSettingsForm,
  toggleReadOnlyLayerForm,
  toggleCreateLayerForm,
  toggleLayerAppearance,
  bringGeotiffForward,
  bringGeotiffBackward,
  deleteLayer,
  deletePage,
  bringGeotiffToFront,
} from "../../../../redux/actions/resultsActions";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import "./LayerSettings.scss";

class LayerSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDeleteCaseModalOpen: false,
      isDeletePageModalOpen: false,
      selectedLayerIndex: null,
    };

    this.handleLayerAppearanceToggle =
      this.handleLayerAppearanceToggle.bind(this);
    this.toggleDeleteCaseModal = this.toggleDeleteCaseModal.bind(this);
    this.toggleDeletePageModal = this.toggleDeletePageModal.bind(this);
    this.deleteLayer = this.deleteLayer.bind(this);
    this.deletePage = this.deletePage.bind(this);
  }

  handleLayerAppearanceToggle(jsonOriginPartSelected, jsonOriginLayerIndex) {
    this.props.toggleLayerAppearance(
      jsonOriginPartSelected,
      jsonOriginLayerIndex
    );
    //this.props.bringGeotiffToFront();
  }

  toggleDeleteCaseModal(i) {
    this.setState((prevState) => ({
      isDeleteCaseModalOpen: !prevState.isDeleteCaseModalOpen,
      selectedLayerIndex: i,
    }));
  }

  deleteLayer() {
    this.props.deleteLayer(this.state.selectedLayerIndex);
    this.setState({ isDeleteCaseModalOpen: false });
  }

  toggleDeletePageModal() {
    this.setState((prevState) => ({
      isDeletePageModalOpen: !prevState.isDeletePageModalOpen,
      selectedLayerIndex: null,
    }));
  }

  deletePage() {
    this.props.deletePage();
    this.setState({ isDeletePageModalOpen: false });
  }

  render() {
    const { t } = this.props;

    return (
      <>
        {!this.props.inReadOnlyLayerForm &&
        !this.props.inCreateLayerForm &&
        !this.props.inLayerAppearance ? (
          <div
            className="layer-settings-container"
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h3 className="raster-settings-header">{t("PageSettings")}</h3>
              <div>
                <TextInput
                  label={`${t("PageName")}*`}
                  name={"pageName"}
                  value={t(
                    this.props.resultsJsonOrigin.pages[
                      this.props.currentViewIndex
                    ].name
                  )}
                  onChange={() => console.log()}
                  disabled
                />
                <TextArea
                  label={t("Description")}
                  name={"description"}
                  value={
                    this.props.resultsJsonOrigin.pages[
                      this.props.currentViewIndex
                    ].desc
                  }
                  onChange={() => console.log()}
                  disabled
                />
              </div>
              <div style={{ marginTop: "20px" }} />
              <hr
                className="raster-settings-ruler"
                style={{ width: "300px" }}
              />
              <h3
                className="raster-settings-header"
                style={{ marginBottom: "25px" }}
              >{`${t("Layers")}`}</h3>
              {this.props.resultsJsonOrigin.pages[
                this.props.currentViewIndex
              ].lyrs.map((x, i) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      aignItems: "center",
                      //height: "100%",
                      width: "100%",
                      marginBottom: "12px",
                    }}
                  >
                    <LayerSettingsInput
                      id={x.id}
                      value={t(x.name)}
                      onChange={() => console.log()}
                      style={{
                        width: "240px",
                        height: "27px",
                      }}
                      disabled
                    />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "12px",
                        paddingBottom: "3px",
                      }}
                    >
                      <button
                        className={`layer-settings-icon-btn`}
                        title={t("LayerSettings")}
                        onClick={() => {
                          this.props.toggleReadOnlyLayerForm(x);
                        }}
                      >
                        <Icon
                          className={`pen-icon`}
                          icon={Database}
                          size="16px"
                          color="Gray"
                          strokeWidth={"1.7px"}
                        />
                      </button>
                      <button
                        className={`layer-settings-icon-btn`}
                        title={t("VizualizationPresets")}
                        onClick={() =>
                          this.handleLayerAppearanceToggle({
                            jsonOriginPartSelected: x,
                            jsonOriginLayerIndex: i,
                          })
                        }
                      >
                        <Icon
                          className={`pen-icon`}
                          icon={PenTool}
                          size="16px"
                          color="Gray"
                          strokeWidth={"1.7px"}
                        />
                      </button>
                      <button
                        className={`layer-settings-icon-btn`}
                        title={t("DeleteLayer")}
                        onClick={() => this.toggleDeleteCaseModal(i)}
                      >
                        <Icon className={`delete-icon`} icon={Trash} />
                      </button>
                      <button
                        className={`layer-settings-icon-btn`}
                        title={t("BringLayerBackward")}
                        onClick={() => this.props.bringGeotiffBackward(i)}
                        disabled={
                          i ===
                          this.props.resultsJsonOrigin.pages[
                            this.props.currentViewIndex
                          ].lyrs.length -
                            1
                        }
                      >
                        <Icon
                          className={`layer-back-icon
                        ${
                          i ===
                          this.props.resultsJsonOrigin.pages[
                            this.props.currentViewIndex
                          ].lyrs.length -
                            1
                            ? "layer-back-icon-disabled"
                            : ""
                        }
                        
                        `}
                          icon={ArrowDown}
                        />
                      </button>
                      <button
                        className={`layer-settings-icon-btn`}
                        title={t("BringLayerForward")}
                        onClick={() => this.props.bringGeotiffForward(i)}
                        disabled={i === 0}
                      >
                        <Icon
                          className={`layer-back-icon ${
                            i === 0 ? "layer-back-icon-disabled" : ""
                          }`}
                          icon={ArrowUp}
                        />
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
            <div>
              <button
                className="raster-settings-btn"
                onClick={() => this.props.toggleCreateLayerForm()}
              >
                <div className="btn-content-wrapper">
                  <PlusCircle
                    color={"#494949"}
                    size={"16px"}
                    strokeWidth={"1.8px"}
                  />
                  <div className="raster-btn-lbl">{t("NewLayer")}</div>
                </div>
              </button>
              {/* <button
                className="raster-settings-btn"
                //onClick={this.createRasterPreview}
              >
                <div className="btn-content-wrapper">
                  <Save color={"#494949"} size={"16px"} strokeWidth={"1.8px"} />
                  <div className="raster-btn-lbl">{t("Save")}</div>
                </div>
              </button> */}
              {this.props.resultsJsonOrigin.pages[this.props.currentViewIndex]
                .src === "custom" && (
                <button
                  className={`raster-settings-btn disabled-icon `}
                  onClick={this.toggleDeletePageModal}
                >
                  <div className="btn-content-wrapper">
                    <Trash
                      color={"#cc8585"}
                      size={"16px"}
                      strokeWidth={"2px"}
                    />
                    <div className="raster-btn-lbl">{t("DeletePage")}</div>
                  </div>
                </button>
              )}
            </div>
            <DeleteModal
              modalHdr={t("DeleteLayer")}
              modalInfo={t(`DeleteLayerModalInfo`)}
              isOpen={this.state.isDeleteCaseModalOpen}
              closeDeleteModal={this.toggleDeleteCaseModal}
              deleteModalData={this.deleteLayer}
            />
            <DeleteModal
              modalHdr={t("DeletePage")}
              modalInfo={t(`DeletePageModalInfo`)}
              isOpen={this.state.isDeletePageModalOpen}
              closeDeleteModal={this.toggleDeletePageModal}
              deleteModalData={this.deletePage}
            />
          </div>
        ) : !this.props.inLayerAppearance ? (
          <div
            className="layer-settings-container"
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <LayerSettingsForm />
          </div>
        ) : (
          <div className="layer-settings-container">
            <LayerAppearance />
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    inReadOnlyLayerForm: state.results.inReadOnlyLayerForm,
    inCreateLayerForm: state.results.inCreateLayerForm,
    inLayerAppearance: state.results.inLayerAppearance,
    resultsJsonOrigin: state.results.resultsJsonOrigin,
    currentViewIndex: state.results.currentViewIndex,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleLayerSettingsForm: () => dispatch(toggleLayerSettingsForm()),
    toggleReadOnlyLayerForm: (payload) =>
      dispatch(toggleReadOnlyLayerForm(payload)),
    toggleCreateLayerForm: () => dispatch(toggleCreateLayerForm()),
    toggleLayerAppearance: (payload) =>
      dispatch(toggleLayerAppearance(payload)),
    bringGeotiffForward: (payload) => dispatch(bringGeotiffForward(payload)),
    bringGeotiffBackward: (payload) => dispatch(bringGeotiffBackward(payload)),
    deleteLayer: (payload) => dispatch(deleteLayer(payload)),
    bringGeotiffToFront: () => dispatch(bringGeotiffToFront()),
    deletePage: () => dispatch(deletePage()),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(LayerSettings)
);
