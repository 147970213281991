import {
  PublicClientApplication,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import { authConfig, propolisApiScopes } from "./authConfig";

class AuthService {
  constructor() {
    this.msalClient = new PublicClientApplication(
      authConfig
    );
    this.logout = this.logout.bind(this);
    this.acquireTokenByScopes = this.acquireTokenByScopes.bind(this);
  }

  getCurrentUser() {
    const loggedUsers = this.msalClient.getAllAccounts();
    return loggedUsers.length > 0 ? loggedUsers[0] : null;
  }

  logout() {
    const accountObj = this.msalClient.getAllAccounts()[0];
    const logoutRequest = {
      account: accountObj,
      postLogoutRedirectUri: authConfig.postLogoutRedirectUri
    };
    return this.msalClient.logoutRedirect(logoutRequest);
  }

  async acquireToken() {
    return this.acquireTokenByScopes(propolisApiScopes);
  }
  async acquireTokenByScopes(scopes) {
    try {
      const account = this.msalClient.getAllAccounts()[0];
      const response = await this.msalClient.acquireTokenSilent({
        account,
        scopes,
      });
      return response.accessToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        const account = this.msalClient.getAllAccounts()[0];
        const response = await this.msalClient.acquireTokenRedirect({
          account,
          scopes,
        });
        return response.accessToken;
      } else {
        throw Error(error);
      }
    }
  }
}
const authService = new AuthService();
export default authService;
