import { types } from "../types/settings";

export function saveSettings(payload) {
  return {
    type: types.SETTINGS_SAVE,
    payload: payload,
  };
}
export function saveSettingsSuccess() {
  return {
    type: types.SETTINGS_SAVE_SUCCESS,
  };
}

export function saveSettingsError(error) {
  return {
    type: types.SETTINGS_SAVE_ERROR,
    payload: error,
  };
}

export function loadSettings() {
  return {
    type: types.SETTINGS_LOAD,
  };
}

export function loadSettingsSuccess(payload) {
  return {
    type: types.SETTINGS_LOAD_SUCCESS,
    payload: payload,
  };
}

export function loadSettingsError(error) {
  return {
    type: types.SETTINGS_LOAD_ERROR,
    payload: error,
  };
}
