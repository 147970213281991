import { call, put, takeEvery } from "redux-saga/effects";
import * as projectsApi from "../../api/projectsApi";
import * as storageApi from "../../api/storageApi";
import * as projectActions from "../actions/projectsActions";
import { types } from "../types/projects";

function* loadProject(action) {
  try {
    let project = yield call(projectsApi.getProjectByIdAsync, action.payload);
    if (project.pictureUrl) {
      const pictureDownloadUrl = yield call(
        storageApi.getDownloadLink,
        project.pictureUrl
      );
      project.pictureDownloadUrl = pictureDownloadUrl;
    }
    yield put(projectActions.loadProjectSuccess(project));
  } catch (error) {
    //dispatch load failure
  }
}

function* saveProject(action) {
  try {
    const updateResult = yield call(
      projectsApi.updateProjectAsync,
      action.payload
    );
    if (updateResult.data?.pictureUrl) {
      const pictureDownloadUrl = yield call(
        storageApi.getDownloadLink,
        updateResult.data.pictureUrl
      );
      updateResult.data.pictureDownloadUrl = pictureDownloadUrl;
    }
    yield put(projectActions.saveProjectSuccess(updateResult?.data));
  } catch (error) {
    console.log(error);
    yield put(projectActions.saveProjectFailure(error));
  }
}

function* loadProjects() {
  try {
    let projects = yield call(projectsApi.getProjectsAsync);
    yield put(projectActions.loadProjectsSuccess(projects));
  } catch (error) {
    yield put(projectActions.loadProjectsFailure());
  }
}

function* deleteProject(action) {
  try {
    let projectId = yield call(projectsApi.deleteProjectById, action.payload);
    yield put(projectActions.deleteProjectSuccess(projectId));
    yield loadProjects();
  } catch (error) {
    yield put(projectActions.deleteProjectFailure(error));
  }
}

export default function* projectsSaga() {
  yield takeEvery(types.LOAD_PROJECT, loadProject);
  yield takeEvery(types.LOAD_PROJECTS, loadProjects);
  yield takeEvery(types.DELETE_PROJECT, deleteProject);
  yield takeEvery(types.SAVE_PROJECT, saveProject);
}
