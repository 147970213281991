import React from "react";
import { XCircle, CheckCircle } from "react-feather";
import { PageHeader } from "../../../Components/Page/Page";
import Select from "../../../Components/Common/Select/Select";
import { TextInput } from "../../../Components/Common/Form/Form";
import { TextArea } from "../../../Components/Map/Form/TextArea";
import {
  WizardButtonNext,
  WizardButtonPrev,
} from "../../../Components/Common/WizardButton/WizardButton";
import { applicationFields } from "../../../common/applicationFields";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import "./ApplicationField.scss";

class ApplicationField extends React.Component {
  constructor() {
    super();
    this.state = {};
    this.onSelectChange = this.onSelectChange.bind(this);
  }

  onSelectChange(event) {
    this.props.onInputChange({
      target: {
        name: "applicationFieldId",
        value: parseInt(event.target.value),
      },
    });
  }

  render() {
    const { t } = this.props;
    return (
      <div className="wizard-section wizard-section__app-field">
        <PageHeader text={t("CaseInformation")} />
        <div className="text-input-container">
          <TextInput
            label={`${t("CaseName")}*`}
            name={"caseName"}
            value={this.props.caseName}
            onChange={this.props.onInputChange}
          />
          {this.props.caseName.length > 0 && (
            <div className="validation-container">
              {this.props.caseNameErrorMessage === "" ? (
                <>
                  <div className="validation-icon-container">
                    <CheckCircle width="17" stroke="green" strokeWidth="2" />
                  </div>
                  <div className="validation-wrapper"></div>
                </>
              ) : (
                <>
                  <div className="validation-icon-container">
                    <XCircle width="17" stroke="#fc3939e3" strokeWidth="2" />
                  </div>
                  <div className="validation-wrapper">
                    {t(this.props.caseNameErrorMessage)}
                  </div>
                </>
              )}
            </div>
          )}
        </div>

        <div className="text-input-container">
          <TextArea
            label={t("CaseInformation")}
            name={"caseInformation"}
            value={this.props.caseInformation}
            onChange={this.props.onInputChange}
          />
          {this.props.caseInformation.length > 0 && (
            <div className="validation-container">
              <div className="validation-icon-container">
                <CheckCircle width="17" stroke="green" strokeWidth="2" />
              </div>
              <div className="validation-wrapper"></div>
            </div>
          )}
        </div>
        <Select
          label={t("SelectApplicationField")}
          value={this.props.applicationFieldId}
          options={applicationFields.map((x, i) => {
            return {
              label: t(x.key),
              value: x.id,
              disabled:
                this.props.auth.roles.includes("IsSimulationUser") &&
                (i === 1 || i === 2 || i === 3)
                  ? false
                  : i === 0 || i === 4
                  ? false
                  : true,
            };
          })}
          onChange={(e) => this.onSelectChange(e)}
        />
        <div className="app-field-button-wrapper">
          {this.props.goPreviousStep && (
            <WizardButtonPrev
              text={t("Back")}
              onClick={this.props.goPreviousStep}
            />
          )}
          <WizardButtonNext
            text={t("Next")}
            onClick={this.props.goNextStep}
            disabled={!this.props.isCaseInfoValid}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isCaseInfoValid: state.checkName.isCaseInfoValid,
    isCaseNameValid: state.checkName.isCaseNameValid,
    caseNameErrorMessage: state.checkName.caseNameErrorMessage,
    auth: state.auth,
  };
};

export default withTranslation()(
  connect(mapStateToProps, null)(ApplicationField)
);
