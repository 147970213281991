import React from "react";
import Select from "react-select";
import { ExpertTimeAccordion } from "../../ExpertUtils/ExpertComponent";
import {
  ExpertHelpLabel,
  ExpertInput,
} from "../../ExpertUtils/ExpertComponent";
import { FormSettingsSeparator } from "../../../../Components/Common/Form/FormSeparator/index";
import { selectSetupStyle } from "../../../../Components/Common/PaletteDropdown/index";
import { hoursFromUtcOptions } from "../../../../Components/Map/consts/consts";
import { helpLink } from "../../expertConst";
import {
  changeExpertInput,
  changeExpertRuntimeTemplate,
  changeExpertDateTime,
} from "../../../../redux/actions/expertActions";
import { useSelector, useDispatch } from "react-redux";

const [page, subpage] = ["initialization", "runtime_template"];

const RuntimeTemplate = () => {
  const runtime_template = useSelector(
    (state) => state.expert.expertJson.initialization.runtime_template
  );
  const dispatch = useDispatch();
  return (
    <>
      <div style={{ marginBottom: -20 }} />
      <FormSettingsSeparator
        style={{ fontSize: "17px" }}
        label={"Run Steering"}
      />

      <ExpertHelpLabel
        label={"Start time and date of simulation"}
        helpLink={`${helpLink}/initialization_parameters#origin_date_time`}
      />
      <input
        className="setup-input"
        type="date"
        id="startDay"
        name="startDay"
        value={runtime_template?.run_steering.origin_data_time.substring(0, 10)}
        onChange={(e) =>
          dispatch(
            changeExpertDateTime({
              type: "date",
              subpage: subpage,
              key: "run_steering",
              value: e.target.value,
            })
          )
        }
        min="1900-05-01"
        max="2031-09-30"
        required
      />
      <input
        id={"time-hours"}
        className="setup-input"
        type="time"
        value={runtime_template?.run_steering.origin_data_time.substring(11)}
        onChange={(e) =>
          dispatch(
            changeExpertDateTime({
              type: "hours",
              subpage: subpage,
              key: "run_steering",
              value: e.target.value,
            })
          )
        }
        required
      />
      <ExpertHelpLabel
        label={"Hours From UTC"}
        helpLink={`${helpLink}/initialization_parameters#origin_date_time`}
      />
      <Select
        isSearchable={false}
        styles={selectSetupStyle}
        options={hoursFromUtcOptions}
        value={{
          label: runtime_template?.run_steering?.hours_from_utc,
          value: runtime_template?.run_steering?.hours_from_utc,
        }}
        onChange={(e) =>
          dispatch(
            changeExpertInput({
              page: page,
              subpage: subpage,
              key: "run_steering",
              subkey: "hours_from_utc",
              value: e.value,
            })
          )
        }
        maxMenuHeight="500px"
      />
      <div style={{ marginBottom: 12 }} />

      <ExpertHelpLabel
        label={"Simulation Endtime [s]"}
        helpLink={`${helpLink}/runtime_parameters#end_time`}
      />
      <ExpertTimeAccordion
        page={page}
        subpage={subpage}
        section="run_steering"
        subkey="end_time"
        min={1}
        max={999999}
        step={1}
        constValue={runtime_template?.run_steering?.end_time}
      />
      <div style={{ marginBottom: 12 }} />

      <ExpertHelpLabel
        label={"Maximum allowed value of the time step [s]"}
        helpLink={`${helpLink}/runtime_parameters#dt_max`}
      />
      <ExpertTimeAccordion
        page={page}
        subpage={subpage}
        section="run_steering"
        subkey="dt_max"
        min={1}
        max={999999}
        step={60}
        constValue={runtime_template?.run_steering?.dt_max}
      />
      <div style={{ marginBottom: 12 }} />

      <ExpertHelpLabel
        label={"Impose random perturbations"}
        helpLink={`${helpLink}/runtime_parameters#create_disturbances`}
      />
      <Select
        isSearchable={false}
        styles={selectSetupStyle}
        options={[
          {
            value: "True",
            label: "True",
          },
          { value: "False", label: "False" },
        ]}
        value={{
          label: !!runtime_template.run_steering.create_disturbances
            ? "True"
            : "False",
          value: !!runtime_template.run_steering.create_disturbances
            ? "True"
            : "False",
        }}
        onChange={(e) =>
          dispatch(
            changeExpertInput({
              page: page,
              subpage: subpage,
              key: "run_steering",
              subkey: "create_disturbances",
              value: e.value === "True" ? true : false,
            })
          )
        }
      />

      {runtime_template.run_steering.create_disturbances && (
        <>
          <div style={{ marginTop: 12 }} />
          <ExpertHelpLabel
            label={"Interval at which disturbances are to be imposed [s]"}
            helpLink={`${helpLink}/runtime_parameters#dt_disturb`}
          />
          <ExpertTimeAccordion
            page={page}
            subpage={subpage}
            section="run_steering"
            subkey="dt_disturb"
            min={1}
            max={9999999.9}
            step={0.1}
            constValue={runtime_template.run_steering.dt_disturb}
          />
          <div style={{ marginBottom: 12 }} />

          <ExpertHelpLabel
            label={"Maximum perturbation amplitude [m/s]"}
            helpLink={`${helpLink}/runtime_parameters#disturbance_amplitude`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="run_steering"
            subkey="disturbance_amplitude"
            min={0.01}
            max={99.9}
            step={0.01}
            constValue={runtime_template.run_steering.disturbance_amplitude}
          />
          <ExpertHelpLabel
            label={"Upper limit value of the perturbation energy [m²/s²]"}
            helpLink={`${helpLink}/runtime_parameters#disturbance_energy_limit`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="run_steering"
            subkey="disturbance_energy_limit"
            min={0.01}
            max={99.9}
            step={0.01}
            constValue={runtime_template.run_steering.disturbance_energy_limit}
          />
          <div style={{ marginBottom: -16 }} />
        </>
      )}
      <FormSettingsSeparator style={{ fontSize: "17px" }} label={"Templates"} />
      <ExpertHelpLabel label={"Select Template"} helpLink={``} />
      <Select
        isSearchable={false}
        styles={selectSetupStyle}
        options={[
          {
            value: "Constant Profiles",
            label: "Constant Profiles",
          },
          { value: "Dynamic Driver Upload", label: "Dynamic Driver Upload" },
          {
            value: "Dynamic Driver Windprofile",
            label: "Dynamic Driver Windprofile",
            disabled: true,
          },
        ]}
        value={{
          label:
            runtime_template.template === 1
              ? "Constant Profiles"
              : runtime_template.template === 2
              ? "Dynamic Driver Upload"
              : "Dynamic Driver Windprofile",
        }}
        isOptionDisabled={(option) => option.disabled}
        onChange={(e) =>
          dispatch(
            changeExpertRuntimeTemplate({
              page: page,
              subpage: subpage,
              key: "",
              subkey: "template",
              value:
                e.value === "Constant Profiles"
                  ? 1
                  : e.value === "Dynamic Driver Upload"
                  ? 2
                  : 3,
            })
          )
        }
      />
    </>
  );
};

export default RuntimeTemplate;
