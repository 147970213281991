import React from "react";
import Switch from "react-switch";
import BasicOsmDownloadSettings from "./BasicOsmDownloadSettings";
import ExpertOsmDownloadSettings from "./ExpertOsmDownloadSettings";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";

class OsmDownloadSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = { inBasicMode: true };
    this.onExportModeChange = this.onExportModeChange.bind(this);
  }

  onExportModeChange() {
    this.setState({ inBasicMode: !this.state.inBasicMode });
  }

  render() {
    const { t } = this.props;
    return (
      <div className="layer-settings-container">
        <div className="raster-switch-container" style={{ width: "100%" }}>
          <div className="raster-switch-lbl">{t("Basic")}</div>
          <Switch
            onChange={this.onExportModeChange}
            checked={!this.state.inBasicMode}
            checkedIcon={false}
            uncheckedIcon={false}
            height={11}
            width={25}
            handleDiameter={6}
            offHandleColor="#ffffff"
            onColor="#a1a1a1"
            offColor="#a1a1a1"
          />
          <div className="raster-switch-lbl">{t("Expert")}</div>
        </div>
        {this.state.inBasicMode ? (
          <BasicOsmDownloadSettings />
        ) : (
          <ExpertOsmDownloadSettings />
        )}
      </div>
    );
  }
}

export default withRouter(withTranslation()(OsmDownloadSettings));
