import proj4 from "proj4";

export const wgDef = "+proj=longlat +datum=WGS84 +no_defs";

export const utm31Def =
  "+proj=utm +zone=31 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs ";

export const utm32Def =
  "+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs";

export const utm33Def =
  "+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs ";

export const reprojectWgToUtmPoint = (coordinates, crsDef) => {
  const reproject = proj4(crsDef, [
    Number(coordinates.lng),
    Number(coordinates.lat),
  ]);

  let formatUtm = {
    lat: reproject[1].toFixed(2),
    lng: reproject[0].toFixed(2),
  };
  return formatUtm;
};

export const reprojectUtmToWgPoint = (coordinates, crsDef) => {
  let reproject = proj4(crsDef, wgDef, coordinates);
  let formatUtm = { lat: reproject[1], lng: reproject[0] };
  return formatUtm;
};
