import React from "react";
import Select from "react-select";
import {
  ExpertHelpLabel,
  ExpertInput,
} from "../../ExpertUtils/ExpertComponent";
import { FormSettingsSeparator } from "../../../../Components/Common/Form/FormSeparator/index";
import { selectSetupStyle } from "../../../../Components/Common/PaletteDropdown/index";
import { helpLink } from "../../expertConst";
import { changeExpertInput } from "../../../../redux/actions/expertActions";
import { useSelector, useDispatch } from "react-redux";

const [page, subpage] = ["grid", "single_domain"];

const SingleDomain = () => {
  const single_domain = useSelector(
    (state) => state.expert.expertJson.grid.single_domain
  );

  const rasterParameters = useSelector(
    (state) => state.projects.currentCase.rasterParameters
  );

  const rasterParamsOnMount = useSelector(
    (state) => state.rasterArea.currentCase.rasterParameters
  );

  const dispatch = useDispatch();

  return (
    <>
      <div style={{ marginBottom: -20 }} />
      <FormSettingsSeparator style={{ fontSize: "17px" }} label={"Grid"} />
      <ExpertHelpLabel
        label={"Grid Spacing in x,y,z-Direction [-]"}
        helpLink={`${helpLink}/initialization_parameters#dx`}
      />
      <input
        className="setup-input disabled"
        value={
          rasterParameters
            ? rasterParameters.resolution
            : rasterParamsOnMount?.resolution
            ? rasterParamsOnMount?.resolution
            : "NaN"
        }
        disabled
      />

      <ExpertHelpLabel
        label={"Number of grid points in x-direction [-]"}
        helpLink={`${helpLink}/initialization_parameters#nx`}
      />
      <input
        className="setup-input disabled"
        value={
          rasterParameters
            ? (rasterParameters.x_max - rasterParameters.x_min) /
              rasterParameters.resolution
            : (rasterParamsOnMount?.x_max - rasterParamsOnMount?.x_min) /
              rasterParamsOnMount?.resolution
        }
        disabled
      />

      <ExpertHelpLabel
        label={"Number of grid points in y-direction [-]"}
        helpLink={`${helpLink}/initialization_parameters#ny`}
      />
      <input
        className="setup-input disabled"
        value={
          rasterParameters
            ? (rasterParameters.y_max - rasterParameters.y_min) /
              rasterParameters.resolution
            : (rasterParamsOnMount?.y_max - rasterParamsOnMount?.y_min) /
              rasterParamsOnMount?.resolution
        }
        disabled
      />

      <ExpertHelpLabel
        label={"Method for nz-Definition"}
        helpLink={`${helpLink}/initialization_parameters#nz`}
      />
      <Select
        isSearchable={false}
        styles={selectSetupStyle}
        options={[
          {
            value: "Autodetection",
            label: "Autodetection",
          },
          { value: "User-defined", label: "User-defined" },
        ]}
        value={{
          value:
            single_domain.grid.nz_type === 1 ? "Autodetection" : "User-defined",
          label:
            single_domain.grid.nz_type === 1 ? "Autodetection" : "User-defined",
        }}
        onChange={(e) =>
          dispatch(
            changeExpertInput({
              page: page,
              subpage: subpage,
              key: "grid",
              subkey: "nz_type",
              value: e.value === "Autodetection" ? 1 : 2,
            })
          )
        }
      />
      <div style={{ marginBottom: 12 }} />

      {single_domain.grid.nz_type === 1 && (
        <>
          <ExpertHelpLabel
            label={"Minimum Height for nz-Autodetection [m]"}
            helpLink={`${helpLink}/initialization_parameters#nz`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="grid"
            subkey="nz_minheight"
            min={100}
            max={999999}
            step={1}
            constValue={single_domain.grid.nz_minheight}
          />
        </>
      )}
      {single_domain.grid.nz_type === 2 && (
        <>
          <ExpertHelpLabel
            label={"Number of grid points in z-direction [-]"}
            helpLink={`${helpLink}/initialization_parameters#nz`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="grid"
            subkey="nz_userdefined"
            min={50}
            max={999999}
            step={1}
            constValue={single_domain.grid.nz_userdefined}
          />
        </>
      )}
      <ExpertHelpLabel
        label={"Switch On Vertical Grid Stretchin"}
        helpLink={`${helpLink}/initialization_parameters#dz_stretch_level`}
      />
      <Select
        isSearchable={false}
        styles={selectSetupStyle}
        options={[
          {
            value: "True",
            label: "True",
          },
          { value: "False", label: "False" },
        ]}
        value={{
          label: !!single_domain.grid.dz_stretch_isActive ? "True" : "False",
          value: !!single_domain.grid.dz_stretch_isActive ? "True" : "False",
        }}
        onChange={(e) =>
          dispatch(
            changeExpertInput({
              page: page,
              subpage: subpage,
              key: "grid",
              subkey: "dz_stretch_isActive",
              value: e.value === "True" ? true : false,
            })
          )
        }
      />
      {single_domain.grid.dz_stretch_isActive && (
        <>
          <div style={{ marginBottom: 12 }} />

          <ExpertHelpLabel
            label={"Height Level Start Vertical Grid Stretching [m]"}
            helpLink={`${helpLink}/initialization_parameters#dz_stretch_level`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="grid"
            subkey="dz_stretch_level"
            min={1}
            max={999999}
            step={1}
            constValue={single_domain.grid.dz_stretch_level}
          />
          <ExpertHelpLabel
            label={"Allowed maximum vertical grid spacing [m]"}
            helpLink={`${helpLink}/initialization_parameters#dz_max`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="grid"
            subkey="dz_max"
            min={1}
            max={999999}
            step={1}
            constValue={single_domain.grid.dz_max}
          />
          <ExpertHelpLabel
            label={"Stretch factor for a vertically stretched grid [-]"}
            helpLink={`${helpLink}/initialization_parameters#dz_stretch_factor`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="grid"
            subkey="dz_stretch_factor"
            min={1.01}
            max={1.12}
            step={0.01}
            constValue={single_domain.grid.dz_stretch_factor}
          />
        </>
      )}
      <FormSettingsSeparator
        style={{ fontSize: "17px" }}
        label={"Topography"}
      />
      <ExpertHelpLabel
        label={"Topography Mode"}
        helpLink={`${helpLink}/initialization_parameters#topography`}
      />

      <Select
        isSearchable={false}
        styles={selectSetupStyle}
        options={[
          {
            value: "read_from_file",
            label: "read_from_file",
          },
        ]}
        value={{
          label: "read_from_file",
          value: "read_from_file",
        }}
      />

      <FormSettingsSeparator
        style={{ fontSize: "17px" }}
        label={"Runtime: Processor Settings"}
      />
      <ExpertHelpLabel label={"Mode for Processor Settings"} helpLink={``} />
      <Select
        isSearchable={false}
        styles={selectSetupStyle}
        options={[
          {
            value: "Automatic Distribution",
            label: "Automatic Distribution",
          },
          {
            value: "User-defined Distribution",
            label: "User-defined Distribution",
          },
        ]}
        value={{
          value:
            single_domain.runtime_processor.processor_mode === 1
              ? "Automatic Distribution"
              : "User-defined Distribution",
          label:
            single_domain.runtime_processor.processor_mode === 1
              ? "Automatic Distribution"
              : "User-defined Distribution",
        }}
        onChange={(e) =>
          dispatch(
            changeExpertInput({
              page: page,
              subpage: subpage,
              key: "runtime_processor",
              subkey: "processor_mode",
              value: e.value === "Automatic Distribution" ? 1 : 2,
            })
          )
        }
      />
      <div style={{ marginBottom: 12 }} />

      {single_domain.runtime_processor.processor_mode === 2 && (
        <>
          <ExpertHelpLabel
            label={"Number of available CPU-Cores [-]"}
            helpLink={`${helpLink}/runtime_parameters#pgrid`}
          />
          <input className="setup-input disabled" value={11111} disabled />

          <ExpertHelpLabel
            label={"Number of used CPU-Cores [-]"}
            helpLink={`${helpLink}/runtime_parameters#pgrid`}
          />
          <input
            className="setup-input disabled"
            disabled
            value={
              single_domain.runtime_processor.npex *
              single_domain.runtime_processor.npey
            }
          />
          <ExpertHelpLabel
            label={"Number of processors along x-direction [-]"}
            helpLink={`${helpLink}/runtime_parameters#npex`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="runtime_processor"
            subkey="npex"
            min={2}
            max={99}
            step={1}
            constValue={single_domain.runtime_processor.npex}
          />
          <ExpertHelpLabel
            label={"Number of processors along y-direction [-]"}
            helpLink={`${helpLink}/runtime_parameters#npey`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="runtime_processor"
            subkey="npey"
            min={2}
            max={99}
            step={1}
            constValue={single_domain.runtime_processor.npey}
          />
        </>
      )}
    </>
  );
};

export default SingleDomain;
