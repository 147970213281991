import React from "react";
import "./ActionButton.scss";

const ActionButton = (props) => {
  return (
    <button
      onMouseEnter={props.onMouseEnter}
      onClick={props.onClick}
      className={`map-btn ${props.icon} ${props.className} ${
        props.pushed ? "map-btn-pushed" : ""
      }`}
      title={props.title}
      disabled={props.disabled}
    />
  );
};

export default ActionButton;
