import React, { useEffect } from "react";
import L from "leaflet";
import {
  Map as LeafletMap,
  ScaleControl,
  LayersControl,
  TileLayer,
  Polygon,
} from "react-leaflet";
import OsmArea from "./OsmArea";
import { OpenStreetMapProvider, GeoSearchControl } from "leaflet-geosearch";
import { LayerOptions } from "../../../common/TileLayers";
import area from "@turf/area";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import {
  toggleTools,
  startArea,
  getOsmCursorCoords,
  finishArea,
} from "../../../redux/actions/toolsActions";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import "./searchControlStyle.scss";

const { BaseLayer } = LayersControl;

const SearchField = (props) => {
  const provider = new OpenStreetMapProvider();

  const searchControl = new GeoSearchControl({
    provider: provider,
    searchLabel: props.searchLabel,
    marker: {
      icon: poiMarker,
      draggable: false,
    },
  });

  useEffect(() => {
    props.osmMapRef.current.leafletElement.addControl(searchControl);
    // return () =>
    //   props.osmMapRef.current.leafletElement.removeControl(searchControl);
  }, []);

  return null;
};

const exludedAreaOptions = {
  fillColor: "#fff",
  color: "#fff",
  weight: 1,
  opacity: 0.1,
  dashArray: "3",
  fillOpacity: 0.1,
};

class OsmDownloadMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.osmMapRef = React.createRef();
    this.onMapClick = this.onMapClick.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
  }

  componentDidMount() {
    this.props.toggleTools();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.osmCrsBnd &&
      this.props.osmCrsBnd &&
      prevProps.osmCrsBnd !== this.props.osmCrsBnd
    ) {
      
      const southWest = L.latLng(
        this.props.osmCrsBnd[0][1],
        this.props.osmCrsBnd[1][0]
      );
      const northEast = L.latLng(
        this.props.osmCrsBnd[1][1],
        this.props.osmCrsBnd[0][0]
      );

      const bounds = L.latLngBounds(southWest, northEast);
      this.osmMapRef.current.leafletElement.fitBounds(bounds);
    }
  }

  componentWillUnmount() {
    this.props.toggleTools();
  }

  onMapClick(e) {
    if (
      this.props.inDrawExtentMode &&
      !this.props.isExtentDrawn &&
      !this.props.isDrawingExtent
    ) {
      this.props.startArea({
        lat: e.latlng.lat.toFixed(6),
        lng: e.latlng.lng.toFixed(6),
      });
    } else if (
      this.props.inDrawExtentMode &&
      !this.props.isExtentDrawn &&
      this.props.isDrawingExtent
    ) {
      const closingPointLat = Number(e.latlng.lat.toFixed(6));
      const closingPointLng = Number(e.latlng.lng.toFixed(6));

      const areaGeojson = {
        type: "Feature",
        properties: {},
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [this.props.extentCoords[0][1], this.props.extentCoords[0][0]],
              [closingPointLng, this.props.extentCoords[0][0]],
              [closingPointLng, closingPointLat],
              [this.props.extentCoords[0][1], closingPointLat],
            ],
          ],
        },
      };

      const osmBoundGeojson = {
        type: "Feature",
        properties: {},
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [this.props.osmCrsBnd[0][0], this.props.osmCrsBnd[0][1]],
              [this.props.osmCrsBnd[0][0], this.props.osmCrsBnd[1][1]],
              [this.props.osmCrsBnd[1][0], this.props.osmCrsBnd[1][1]],
              [this.props.osmCrsBnd[1][0], this.props.osmCrsBnd[0][1]],
            ],
          ],
        },
      };

      const areaCenterLat = (
        (Number(this.props.extentCoords[0][0]) + closingPointLat) /
        2
      ).toFixed(6);

      const areaCenterLng = (
        (Number(this.props.extentCoords[0][1]) + closingPointLng) /
        2
      ).toFixed(6);

      const centerPointGeoJson = {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [Number(areaCenterLng), Number(areaCenterLat)],
        },
        properties: {},
      };

      const sqrArea = area(areaGeojson);
      const isPointInside = booleanPointInPolygon(
        centerPointGeoJson,
        osmBoundGeojson
      );

      this.props.finishArea({
        lat: closingPointLat,
        lng: closingPointLng,
        area: sqrArea,
        isPointInside: isPointInside,
      });
    }
  }

  handleMouseMove = (e) => {
    this.props.getOsmCursorCoords([
      e.latlng.lat.toFixed(6),
      e.latlng.lng.toFixed(6),
    ]);
  };

  render() {
    const { t } = this.props;
    return (
      <LeafletMap
        ref={this.osmMapRef}
        className={"leaflet-map"}
        center={this.props.center}
        onClick={this.onMapClick}
        onMouseMove={this.props.isDrawingExtent ? this.handleMouseMove : null}
        zoom={this.props.zoom}
        maxZoom={19}
        preferCanvas
      >
        <LayersControl position="bottomleft">
          {LayerOptions.map((x) => {
            return (
              <BaseLayer key={x.name} name={t(x.name)} checked={x.checked}>
                <TileLayer attribution={x.attribution} url={x.url} />
              </BaseLayer>
            );
          })}
        </LayersControl>
        <SearchField
          osmMapRef={this.osmMapRef}
          searchLabel={t("EnterAdress")}
        />
        <ScaleControl position="bottomright" imperial={false} />

        <Polygon
          style={exludedAreaOptions}
          fillOpacity={0.4}
          color={"#fff"}
          positions={[
            [
              [-90, -180],
              [90, -180],
              [90, 180],
              [-90, 180],
            ],
            [
              [this.props.osmCrsBnd[0][1], this.props.osmCrsBnd[0][0]],
              [this.props.osmCrsBnd[1][1], this.props.osmCrsBnd[0][0]],
              [this.props.osmCrsBnd[1][1], this.props.osmCrsBnd[1][0]],
              [this.props.osmCrsBnd[0][1], this.props.osmCrsBnd[1][0]],
            ],
          ]}
        />

        <OsmArea />
      </LeafletMap>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    inDrawExtentMode: state.tools.inDrawExtentMode,
    isDrawingExtent: state.tools.isDrawingExtent,
    isExtentDrawn: state.tools.isExtentDrawn,
    extentCoords: state.tools.extentCoords,
    osmCrsBnd: state.tools?.osmCrs?.bnd,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleTools: () => dispatch(toggleTools()),
    startArea: (payload) => dispatch(startArea(payload)),
    getOsmCursorCoords: (payload) => dispatch(getOsmCursorCoords(payload)),
    finishArea: (payload) => dispatch(finishArea(payload)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(OsmDownloadMap)
);

const poiMarker = new L.Icon({
  iconUrl: require("./osm-pin.svg"),
  //iconRetinaUrl: require("../Results/StaticDriverMapPanel/StaticDriverMapPanel/pin-location-map.svg"),
  iconAnchor: [40, 60],
  popupAnchor: [0, 0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(80, 80),
  className: "pin-location-map",
});
