import React from "react";
import "leaflet-geotiff-2";
import "leaflet-geotiff-2/dist/leaflet-geotiff-plotty";
import { ExtraGeotiffLayer } from "./ExtraGeotiffLayer";
import geoblaze from "geoblaze";
import * as plotty from "leaflet-geotiff-2/node_modules/plotty";
import { palettes } from "../../../common/VisualizationPresets/colorPalettes";
import { getDownloadLink } from "../../../api/storageApi";
import { getExtraLegendDataProps } from "../../../redux/actions/legendActions";
import { connect } from "react-redux";

class ExtraTopography extends React.Component {
  async componentDidMount() {
    let extraTopographies = this.props.loadedProjectFiles.filter((x) => {
      return x.type === "image/tiff";
    });
    if (extraTopographies.length !== 0) {
      const extraTopo = [];

      for (let index = 0; index < extraTopographies.length; index++) {
        const extraTopoUrl = await getDownloadLink(
          extraTopographies[index].path
        );
        const extraTopographyRaster = await geoblaze.load(extraTopoUrl);

        extraTopo.push({
          name: extraTopographies[index].name,
          url: extraTopoUrl,
          max: ~~geoblaze.max(extraTopographyRaster)[0] + 1,
          min: ~~geoblaze.min(extraTopographyRaster)[0] - 1,
          checked: false,
        });
      }

      const elevationPallete = palettes.colors.elevation[`greysRev`];
      const nameOfElevationStyle = this.props.inputElevationPalette;
      plotty.addColorScale(
        `greysRev`,
        elevationPallete,
        [0, 0.12, 0.24, 0.36, 0.48, 0.6, 0.72, 0.84, 1]
      );

      this.props.getExtraLegendDataProps(extraTopo);
    } else if (extraTopographies.length === 0) {
      this.props.getExtraLegendDataProps([]);
    }
  }

  render() {
    return (
      <>
        {this.props.extraTopo.length !== 0 &&
          this.props.extraTopo.map((x) => {
            if (x.checked) {
              return (
                <ExtraGeotiffLayer
                  topographyUrl={x.url}
                  colorName={"greysRev"}
                  displayMin={x.min}
                  displayMax={x.max}
                />
              );
            }
            return <></>;
          })}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loadedProjectFiles: state.projects.loadedProject.files,
    inputElevationPalette:
      state.userSettings?.settings?.visualizationPresets
        ?.inputElevationPalette || palettes.defaults.elevation,
    extraTopo: state.legend.extraTopo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getExtraLegendDataProps: (payload) =>
      dispatch(getExtraLegendDataProps(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExtraTopography);
