import { types } from "../types/rasterArea";
import { types as projectTypes } from "../types/projects";
import { types as notificationTypes } from "../types/notifications";
import {
  fillValue,
  pavementFillValue,
  vegetationFillValue,
  waterFillValue,
} from "../../Components/Map/RasterizationSettings/RasterizationSettingsConst";
import { soilType as soilTypeOptions } from "../../Components/Map/RasterizationSettings/RasterizationSettingsConst";
import {
  calculateRasterAreaPolygonPoints,
  calculateBufferAreaPolygonPoints,
} from "../../containers/Domain/raster-area-helpers";
import { reproject } from "reproject";
import {
  reprojectUtmToWgPoint,
  wgDef,
} from "../../common/utmDef";
import { cloneDeep } from "lodash";

const rasterMethodOptions = ["DefaultCentreValue", "TouchAll"];

const bufferAreaInitialState = {
  bufferAreaPolygonPoints: null,
  isBufferAreaVisible: true,
  gridPointsNorth: 0,
  gridPointsSouth: 0,
  gridPointsWest: 0,
  gridPointsEast: 0,
};

const additionalDataInitialState = {
  verticalResolutionMode: "AsHorizontal",
  verticalResolutionMeter: null,

  buildingHeights: "FromCityModel",
  buildingHeightsFile: null,
  childBuildingHeights: "FromCityModel",
  childBuildingHeightsFile: null,
  treeHeights: "FromCityModel",
  treeHeightsFile: null,
  childTreeHeights: "FromCityModel",
  childTreeHeightsFile: null,

  bufferFillType: "pavement",
  bufferFillValue: {
    value: "PavementAsphaltConcreteMix",
    rasterValue: "Pavement1",
  },
  bufferFillValueOptions: pavementFillValue,
  bufferMethod: "default",
  bufferTopographyInterpMethod: "off",

  globalWaterTemperatureSource: "FromCityModel",
  globalWaterTemperature: 9.35,

  soilAlphaCoefficient: 3.14,
  lSoilCoefficient: -2.342,
  nSoilCoefficient: 1.28,
  soilHydraulicConductivity: 0.00000116,
  soilMoistureSaturation: 0.439,
  soilMoistureCapacity: 0.439,
  soilMoistureWilting: 0.439,
  soilMoistureContent: 0.01,
};

const initialState = {
  currentCase: null,
  rasterCenterLatUtm: null,
  rasterCenterLngUtm: null,
  rasterCenterLatWg: null,
  rasterCenterLngWg: null,
  rasterGridWidth: 5,
  rasterGridXNumber: 200,
  rasterGridYNumber: 200,
  isRasterAreaDraggable: false,
  rasterPreview: null,
  isRasterPreviewVisible: false,
  isGeoJsonVisible: true,
  isDrawnGeoJsonVisible: true,
  isRasterAreaVisible: true,
  rasterAreaPolygonPoints: null,

  season: "Summer",
  soilTypeUniform: "GloballyUniform",
  soilType: "Medium",
  rasterMethod: "DefaultCentreValue",
  fillValue: {
    value: "PavementAsphaltConcreteMix",
    rasterValue: "Pavement1",
  },
  fillValueBelowTreePatches: {
    value: "VegetationBareSoil",
    rasterValue: "Vegetation1",
  },
  rasterizationPriority: ["b", "w", "p", "v"],
  rasterizationPriorityOrderedList: [
    "building",
    "water",
    "pavement",
    "vegetation",
  ],
  topographyForChildDomain: "SameAsParentDomain",

  parentRasterPreview: null,
  isParentRasterPreviewVisible: false,
  childRasterPreview: null,
  isChildRasterPreviewVisible: false,
  isParentRasterAreaVisible: false,
  isChildRasterAreaVisible: false,
  domainType: "SingleDomain",
  gridSizes: "Parent 10m / Child 1m",
  parentRasterGridXNumber: 200,
  parentRasterGridYNumber: 200,
  childRasterGridXNumber: 200,
  childRasterGridYNumber: 200,
  parentAreaPolygonPoints: null,
  childAreaPolygonPoints: null,
  parentGridSize: 10,
  childGridSize: 1,

  parentOffsetX: 0,
  parentOffsetY: 0,

  isOffsetValid: true,
  offsetError: "",
  ...bufferAreaInitialState,
  ...additionalDataInitialState,
};

const rasterAreaReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_RASTER_CENTER_LAT_UTM: {
      const crsDef = action.payload.crsDef;
      const rasterCenterLatUtm = Number(action.payload.rasterCenterLatUtm);
      const rasterCenterLngUtm = cloneDeep(Number(state.rasterCenterLngUtm));
      const rasterGridXNumber = cloneDeep(state.rasterGridXNumber);
      const rasterGridYNumber = cloneDeep(state.rasterGridYNumber);
      const rasterGridWidth = cloneDeep(state.rasterGridWidth);
      const newRasterCenterPoint = reprojectUtmToWgPoint(
        [rasterCenterLngUtm, rasterCenterLatUtm],
        crsDef
      );
      let bufferAreaPolygonPoints = null;

      const rasterAreaPolygonPoints = calculateRasterAreaPolygonPoints(
        rasterCenterLngUtm,
        rasterCenterLatUtm,
        rasterGridWidth,
        rasterGridXNumber,
        rasterGridYNumber,
        crsDef
      );

      const parentAreaPolygonPoints = calculateRasterAreaPolygonPoints(
        rasterCenterLngUtm + Number(state.parentOffsetX),
        rasterCenterLatUtm + Number(state.parentOffsetY),
        state.parentGridSize,
        state.parentRasterGridXNumber,
        state.parentRasterGridYNumber,
        crsDef
      );

      const childAreaPolygonPoints = calculateRasterAreaPolygonPoints(
        rasterCenterLngUtm,
        rasterCenterLatUtm,
        state.childGridSize,
        state.childRasterGridXNumber,
        state.childRasterGridYNumber,
        crsDef
      );

      if (state.domainType === "SingleDomain") {
        bufferAreaPolygonPoints = calculateBufferAreaPolygonPoints(
          rasterCenterLngUtm,
          rasterCenterLatUtm,
          Number(state.rasterGridWidth),
          Number(state.rasterGridXNumber),
          Number(state.rasterGridYNumber),
          Number(state.gridPointsNorth),
          Number(state.gridPointsSouth),
          Number(state.gridPointsWest),
          Number(state.gridPointsEast),
          crsDef
        );
      } else {
        bufferAreaPolygonPoints = calculateBufferAreaPolygonPoints(
          Number(rasterCenterLngUtm) + Number(state.parentOffsetX),
          Number(rasterCenterLatUtm) + Number(state.parentOffsetY),
          Number(state.parentGridSize),
          Number(state.parentRasterGridXNumber),
          Number(state.parentRasterGridYNumber),
          Number(state.gridPointsNorth),
          Number(state.gridPointsSouth),
          Number(state.gridPointsWest),
          Number(state.gridPointsEast),
          crsDef
        );
      }

      return {
        ...state,
        rasterCenterLatUtm: rasterCenterLatUtm,
        rasterCenterLatWg: newRasterCenterPoint.lat,
        rasterCenterLngWg: newRasterCenterPoint.lng,
        rasterAreaPolygonPoints: rasterAreaPolygonPoints,
        parentAreaPolygonPoints: parentAreaPolygonPoints,
        childAreaPolygonPoints: childAreaPolygonPoints,
        bufferAreaPolygonPoints: bufferAreaPolygonPoints,
      };
    }

    case types.SET_RASTER_CENTER_LNG_UTM: {
      const crsDef = action.payload.crsDef;
      const rasterCenterLatUtm = cloneDeep(Number(state.rasterCenterLatUtm));
      const rasterCenterLngUtm = Number(action.payload.rasterCenterLngUtm);
      const rasterGridXNumber = cloneDeep(state.rasterGridXNumber);
      const rasterGridYNumber = cloneDeep(state.rasterGridYNumber);
      const rasterGridWidth = cloneDeep(state.rasterGridWidth);
      const newRasterCenterPoint = reprojectUtmToWgPoint(
        [rasterCenterLngUtm, rasterCenterLatUtm],
        crsDef
      );
      let bufferAreaPolygonPoints = null;

      const rasterAreaPolygonPoints = calculateRasterAreaPolygonPoints(
        rasterCenterLngUtm,
        rasterCenterLatUtm,
        rasterGridWidth,
        rasterGridXNumber,
        rasterGridYNumber,
        crsDef
      );

      const parentAreaPolygonPoints = calculateRasterAreaPolygonPoints(
        rasterCenterLngUtm + Number(state.parentOffsetX),
        rasterCenterLatUtm + Number(state.parentOffsetY),
        state.parentGridSize,
        state.parentRasterGridXNumber,
        state.parentRasterGridYNumber,
        crsDef
      );

      const childAreaPolygonPoints = calculateRasterAreaPolygonPoints(
        rasterCenterLngUtm,
        rasterCenterLatUtm,
        state.childGridSize,
        state.childRasterGridXNumber,
        state.childRasterGridYNumber,
        crsDef
      );

      if (state.domainType === "SingleDomain") {
        bufferAreaPolygonPoints = calculateBufferAreaPolygonPoints(
          rasterCenterLngUtm,
          rasterCenterLatUtm,
          Number(state.rasterGridWidth),
          Number(state.rasterGridXNumber),
          Number(state.rasterGridYNumber),
          Number(state.gridPointsNorth),
          Number(state.gridPointsSouth),
          Number(state.gridPointsWest),
          Number(state.gridPointsEast),
          crsDef
        );
      } else {
        bufferAreaPolygonPoints = calculateBufferAreaPolygonPoints(
          rasterCenterLngUtm + Number(state.parentOffsetX),
          rasterCenterLatUtm + Number(state.parentOffsetY),
          Number(state.parentGridSize),
          Number(state.parentRasterGridXNumber),
          Number(state.parentRasterGridYNumber),
          Number(state.gridPointsNorth),
          Number(state.gridPointsSouth),
          Number(state.gridPointsWest),
          Number(state.gridPointsEast),
          crsDef
        );
      }

      return {
        ...state,
        rasterCenterLngUtm: rasterCenterLngUtm,
        rasterCenterLatWg: newRasterCenterPoint.lat,
        rasterCenterLngWg: newRasterCenterPoint.lng,
        rasterAreaPolygonPoints: rasterAreaPolygonPoints,
        parentAreaPolygonPoints: parentAreaPolygonPoints,
        childAreaPolygonPoints: childAreaPolygonPoints,
        bufferAreaPolygonPoints: bufferAreaPolygonPoints,
      };
    }

    case types.SET_RASTER_CENTER_LAT_WG:
      return {
        ...state,
        rasterCenterLatWg: action.payload,
      };

    case types.SET_RASTER_CENTER_LNG_WG:
      return {
        ...state,
        rasterCenterLngWg: action.payload,
      };

    case types.SET_RASTER_GRID_WIDTH: {
      const rasterGridWidth = action.payload.rasterGridWidth;

      const crsDef = action.payload.crsDef;
      const rasterCenterLngUtm = cloneDeep(state.rasterCenterLngUtm);
      const rasterCenterLatUtm = cloneDeep(state.rasterCenterLatUtm);
      const rasterGridXNumber = cloneDeep(state.rasterGridXNumber);
      const rasterGridYNumber = cloneDeep(state.rasterGridYNumber);

      const rasterAreaPolygonPoints = calculateRasterAreaPolygonPoints(
        rasterCenterLngUtm,
        rasterCenterLatUtm,
        rasterGridWidth,
        rasterGridXNumber,
        rasterGridYNumber,
        crsDef
      );

      return {
        ...state,
        ...bufferAreaInitialState,
        rasterGridWidth: rasterGridWidth,
        rasterAreaPolygonPoints: rasterAreaPolygonPoints,
      };
    }

    case types.SET_RASTER_GRID_X_NUMBER: {
      const crsDef = action.payload.crsDef;
      const rasterCenterLngUtm = cloneDeep(state.rasterCenterLngUtm);
      const rasterCenterLatUtm = cloneDeep(state.rasterCenterLatUtm);
      const rasterGridXNumber = action.payload.rasterGridXNumber;
      const rasterGridYNumber = cloneDeep(state.rasterGridYNumber);
      const rasterGridWidth = cloneDeep(state.rasterGridWidth);

      const rasterAreaPolygonPoints = calculateRasterAreaPolygonPoints(
        rasterCenterLngUtm,
        rasterCenterLatUtm,
        rasterGridWidth,
        rasterGridXNumber,
        rasterGridYNumber,
        crsDef
      );

      return {
        ...state,
        ...bufferAreaInitialState,
        rasterGridXNumber: rasterGridXNumber,
        rasterAreaPolygonPoints: rasterAreaPolygonPoints,
      };
    }

    case types.SET_RASTER_GRID_Y_NUMBER: {
      const crsDef = action.payload.crsDef;
      const rasterCenterLngUtm = cloneDeep(state.rasterCenterLngUtm);
      const rasterCenterLatUtm = cloneDeep(state.rasterCenterLatUtm);
      const rasterGridXNumber = cloneDeep(state.rasterGridXNumber);
      const rasterGridYNumber = action.payload.rasterGridYNumber;
      const rasterGridWidth = cloneDeep(state.rasterGridWidth);

      const rasterAreaPolygonPoints = calculateRasterAreaPolygonPoints(
        rasterCenterLngUtm,
        rasterCenterLatUtm,
        rasterGridWidth,
        rasterGridXNumber,
        rasterGridYNumber,
        crsDef
      );

      return {
        ...state,
        ...bufferAreaInitialState,
        rasterGridYNumber: rasterGridYNumber,
        rasterAreaPolygonPoints: rasterAreaPolygonPoints,
      };
    }

    case types.TOGGLE_RASTER_AREA_DRAGGABLE:
      return {
        ...state,
        isRasterAreaDraggable: !state.isRasterAreaDraggable,
      };

    case types.UPDATE_RASTER_AREA_POSITION:
      const {
        rasterCenterLngWg,
        rasterCenterLatWg,
        rasterAreaPolygonPoints,
        parentAreaPolygonPoints,
        childAreaPolygonPoints,
        bufferAreaPolygonPoints,
      } = action.payload;

      return {
        ...state,
        rasterCenterLngWg: rasterCenterLngWg,
        rasterCenterLatWg: rasterCenterLatWg,
        rasterAreaPolygonPoints: rasterAreaPolygonPoints,
        parentAreaPolygonPoints: parentAreaPolygonPoints,
        childAreaPolygonPoints: childAreaPolygonPoints,
        bufferAreaPolygonPoints: bufferAreaPolygonPoints,
      };

    case types.GET_RASTER_PREVIEW: {
      const crsDef = action.payload.crsDef;

      const rasterPreview = reproject(
        action.payload.rasterPreview,
        crsDef,
        wgDef
      );

      return {
        ...state,
        rasterPreview: rasterPreview,
        isRasterPreviewVisible: true,
        isGeoJsonVisible: false,
        isDrawnGeoJsonVisible: false,
        isRasterAreaVisible: false,
      };
    }

    case types.GET_NESTED_RASTER_PREVIEW: {
      const crsDef = action.payload.crsDef;

      const parentRasterPreview = reproject(
        action.payload.parentRasterPreview,
        crsDef,
        wgDef
      );
      const childRasterPreview = reproject(
        action.payload.childRasterPreview,
        crsDef,
        wgDef
      );

      return {
        ...state,
        parentRasterPreview: parentRasterPreview,
        childRasterPreview: childRasterPreview,
        isGeoJsonVisible: false,
        isDrawnGeoJsonVisible: false,
        isParentRasterPreviewVisible: true,
        isChildRasterPreviewVisible: true,
        isRasterPreviewVisible: false,
        isChildRasterAreaVisible: false,
        isParentRasterAreaVisible: false,
      };
    }

    case types.TOGGLE_RASTER_PREVIEW_VISIBILITY: {
      return {
        ...state,
        isRasterPreviewVisible: !state.isRasterPreviewVisible,
      };
    }

    case types.TOGGLE_GEOJSON_VISIBILITY: {
      return {
        ...state,
        isGeoJsonVisible: !state.isGeoJsonVisible,
        isDrawnGeoJsonVisible: !state.isDrawnGeoJsonVisible,
      };
    }

    case types.TOGGLE_RASTER_AREA_VISIBILITY: {
      return {
        ...state,
        isRasterAreaVisible: !state.isRasterAreaVisible,
      };
    }

    case types.RESET_RASTER_AREA_PROPERTIES: {
      return {
        ...initialState,
        currentCase: { ...state.currentCase },
        domainType: state.domainType,
        isParentRasterAreaVisible: state.isParentRasterAreaVisible,
        isChildRasterAreaVisible: state.isChildRasterAreaVisible,
      };
    }

    case types.LOAD_RASTER_AREA_PREVIEW: {
      const crsDef = action.payload.crsDef;
      const rasterPreview = reproject(
        action.payload.rasterPreview,
        crsDef,
        wgDef
      );
      return {
        ...state,
        rasterPreview: rasterPreview,
      };
    }

    case types.LOAD_RASTER_AREA_PROPERTIES: {
      if (!state.currentCase) {
        return { ...initialState };
      }

      let addData = cloneDeep(additionalDataInitialState);
      let bufferFillValue = addData.bufferFillValue;
      let bufferFillValueOptions = addData.bufferFillValueOptions;

      if (
        state.currentCase?.rasterParameters?.additionalData?.globalParameters
      ) {
        const globalParameters =
          state.currentCase.rasterParameters.additionalData.globalParameters;
        const buildingSettings = globalParameters?.buildingSettings;
        const treeSettings = globalParameters?.treeSettings;
        const domainBufferSettings = globalParameters?.domainBufferSettings;
        const soilSettings = globalParameters?.soilParameters;

        if (globalParameters) {
          addData.globalWaterTemperatureSource =
            globalParameters.waterTemperatureParameteriation;
          addData.globalWaterTemperature =
            globalParameters.waterTemperatureGlobal;

          addData.verticalResolutionMode =
            globalParameters.verticalResolutionMode;
          addData.verticalResolutionMeter =
            globalParameters.verticalResolutionMeter;
          addData.parentOffsetX = globalParameters.parentOffsetX
            ? globalParameters.parentOffsetX
            : 0;
          addData.parentOffsetY = globalParameters.parentOffsetY
            ? globalParameters.parentOffsetY
            : 0;
          if (buildingSettings) {
            addData.buildingHeights = buildingSettings.bhParent;
            addData.buildingHeightsFile = buildingSettings.bhParentFile;
            addData.childBuildingHeights = buildingSettings.bhChild;
            addData.childBuildingHeightsFile = buildingSettings.bhChildFile;
          }

          if (treeSettings) {
            addData.treeHeights = treeSettings.thParent;
            addData.treeHeightsFile = treeSettings.thParentFile;
            addData.childTreeHeights = treeSettings.thChild;
            addData.childTreeHeightsFile = treeSettings.thChildFile;
          }

          if (domainBufferSettings) {
            let bufferFillType = domainBufferSettings.bufferFillType;
            addData.bufferFillType = bufferFillType;
            if (bufferFillType === "vegetation") {
              bufferFillValueOptions = vegetationFillValue;
              bufferFillValue = vegetationFillValue.find(
                (x) => x.rasterValue === domainBufferSettings.bufferFillValue
              );
            } else if (bufferFillType === "pavement") {
              bufferFillValueOptions = pavementFillValue;
              bufferFillValue = pavementFillValue.find(
                (x) => x.rasterValue === domainBufferSettings.bufferFillValue
              );
            } else {
              bufferFillValueOptions = waterFillValue;
              bufferFillValue = waterFillValue.find(
                (x) => x.rasterValue === domainBufferSettings.bufferFillValue
              );
            }
            addData.bufferFillValue = bufferFillValue;
            addData.bufferFillValueOptions = bufferFillValueOptions;

            addData.bufferFillType = domainBufferSettings.bufferFillType;
            addData.bufferMethod = domainBufferSettings.bufferMethod;
            addData.bufferTopographyInterpMethod =
              domainBufferSettings.bufferTopographyInterpMethod;

            addData.gridPointsNorth = domainBufferSettings.gridPointsNorth;
            addData.gridPointsSouth = domainBufferSettings.gridPointsSouth;
            addData.gridPointsWest = domainBufferSettings.gridPointsWest;
            addData.gridPointsEast = domainBufferSettings.gridPointsEast;
          }

          if (soilSettings) {
            addData.soilAlphaCoefficient = soilSettings.alpha_vg;
            addData.lSoilCoefficient = soilSettings.l_vg;
            addData.nSoilCoefficient = soilSettings.n_vg;
            addData.soilHydraulicConductivity = soilSettings.gamma_w_sat;
            addData.soilMoistureSaturation = soilSettings.m_sat;
            addData.soilMoistureCapacity = soilSettings.m_fc;
            addData.soilMoistureWilting = soilSettings.m_wilt;
            addData.soilMoistureContent = soilSettings.m_res;
          }
        }
      }

      if (
        state.currentCase?.rasterParameters &&
        !state.currentCase?.childRasterParameters
      ) {
        const crsDef = action.payload.crsDef;
        const xMin = Number(state.currentCase.rasterParameters.x_min);
        const xMax = Number(state.currentCase.rasterParameters.x_max);
        const yMin = Number(state.currentCase.rasterParameters.y_min);
        const yMax = Number(state.currentCase.rasterParameters.y_max);

        const rasterGridWidth = state.currentCase.rasterParameters.resolution;
        let soilTypeIndex = state.currentCase.rasterParameters.soil_Type;
        const rasterMethodIndex =
          state.currentCase.rasterParameters.rasterMethod;
        const rasterMethod = rasterMethodOptions[rasterMethodIndex - 1];

        if (soilTypeIndex === 99) {
          soilTypeIndex = 7;
        }

        let soilType = soilTypeOptions[soilTypeIndex - 1];
        const rasterCenterLatUtm = (yMin + yMax) / 2;
        const rasterCenterLngUtm = (xMin + xMax) / 2;
        const rasterGridXNumber = (xMax - xMin) / rasterGridWidth;
        const rasterGridYNumber = (yMax - yMin) / rasterGridWidth;

        const rasterAreaPolygonPoints = calculateRasterAreaPolygonPoints(
          rasterCenterLngUtm,
          rasterCenterLatUtm,
          rasterGridWidth,
          rasterGridXNumber,
          rasterGridYNumber,
          crsDef
        );

        const rasterCenterWg = reprojectUtmToWgPoint(
          [rasterCenterLngUtm, rasterCenterLatUtm],
          crsDef
        );

        const fill_Value = fillValue.find(
          (x) => x.rasterValue === state.currentCase.rasterParameters?.fillValue
        );

        const fillValueBelowTreePatches = fillValue.find(
          (x) =>
            x.rasterValue ===
            state.currentCase.rasterParameters?.surfaceBelowTree
        );

        const rasterizationPriority =
          state.currentCase?.rasterParameters?.rasterizationPriority;
        const rasterizationPriorityOrderedList = rasterizationPriority.map(
          (x) => {
            if (x === "b") {
              return "building";
            } else if (x === "w") {
              return "water";
            } else if (x === "v") {
              return "vegetation";
            } else return "pavement";
          }
        );
        const rasterCenterLatWg = rasterCenterWg.lat.toFixed(6);
        const rasterCenterLngWg = rasterCenterWg.lng.toFixed(6);

        const bufferAreaPolygonPoints = calculateBufferAreaPolygonPoints(
          Number(rasterCenterLngUtm),
          Number(rasterCenterLatUtm),
          Number(state.rasterGridWidth),
          Number(state.rasterGridXNumber),
          Number(state.rasterGridYNumber),
          Number(state.gridPointsNorth),
          Number(state.gridPointsSouth),
          Number(state.gridPointsWest),
          Number(state.gridPointsEast),
          crsDef
        );

        return {
          ...state,
          ...addData,
          rasterCenterLatUtm: rasterCenterLatUtm,
          rasterCenterLngUtm: rasterCenterLngUtm,
          rasterCenterLatWg: rasterCenterLatWg,
          rasterCenterLngWg: rasterCenterLngWg,
          rasterGridWidth: rasterGridWidth,
          rasterGridXNumber: rasterGridXNumber,
          rasterGridYNumber: rasterGridYNumber,
          rasterAreaPolygonPoints: rasterAreaPolygonPoints,
          isRasterPreviewVisible: false,
          isGeoJsonVisible: true,
          isRasterAreaVisible: false,
          season: state.currentCase.rasterParameters.season,
          soilType: soilType,
          fillValue: fill_Value,
          fillValueBelowTreePatches: fillValueBelowTreePatches,
          rasterizationPriority: rasterizationPriority,
          rasterizationPriorityOrderedList: rasterizationPriorityOrderedList,
          rasterMethod: rasterMethod,
          domainType: "SingleDomain",
          rasterPreview: null,
          parentRasterPreview: null,
          childRasterPreview: null,
          bufferAreaPolygonPoints: bufferAreaPolygonPoints,
        };
      }

      if (state.currentCase?.childRasterParameters) {
        const crsDef = action.payload.crsDef;
        const season = state.currentCase.rasterParameters.season;
        let soilTypeIndex = state.currentCase.rasterParameters.soil_Type;
        const rasterMethodIndex =
          state.currentCase.rasterParameters.rasterMethod;
        const rasterMethod = rasterMethodOptions[rasterMethodIndex - 1];

        if (soilTypeIndex === 99) {
          soilTypeIndex = 7;
        }

        let soilType = soilTypeOptions[soilTypeIndex - 1];

        const fill_Value = fillValue.find(
          (x) => x.rasterValue === state.currentCase.rasterParameters.fillValue
        );

        const fillValueBelowTreePatches = fillValue.find(
          (x) =>
            x.rasterValue ===
            state.currentCase.rasterParameters.surfaceBelowTree
        );

        const xParentMin = Number(state.currentCase.rasterParameters.x_min);
        const xParentMax = Number(state.currentCase.rasterParameters.x_max);
        const yParentMin = Number(state.currentCase.rasterParameters.y_min);
        const yParentMax = Number(state.currentCase.rasterParameters.y_max);

        const xChildMin = Number(state.currentCase.childRasterParameters.x_min);
        const xChildMax = Number(state.currentCase.childRasterParameters.x_max);
        const yChildMin = Number(state.currentCase.childRasterParameters.y_min);
        const yChildMax = Number(state.currentCase.childRasterParameters.y_max);

        const parentGridSize = state.currentCase.rasterParameters.resolution;
        const childGridSize =
          state.currentCase.childRasterParameters.resolution;

        const parentRasterGridXNumber =
          (xParentMax - xParentMin) / parentGridSize;
        const parentRasterGridYNumber =
          (yParentMax - yParentMin) / parentGridSize;

        const childRasterGridXNumber = (xChildMax - xChildMin) / childGridSize;
        const childRasterGridYNumber = (yChildMax - yChildMin) / childGridSize;

        const rasterCenterLatUtm = (yParentMin + yParentMax) / 2;
        const rasterCenterLngUtm = (xParentMin + xParentMax) / 2;

        const parentAreaPolygonPoints = calculateRasterAreaPolygonPoints(
          rasterCenterLngUtm,
          rasterCenterLatUtm,
          parentGridSize,
          parentRasterGridXNumber,
          parentRasterGridYNumber,
          crsDef
        );

        const childAreaPolygonPoints = calculateRasterAreaPolygonPoints(
          rasterCenterLngUtm,
          rasterCenterLatUtm,
          childGridSize,
          childRasterGridXNumber,
          childRasterGridYNumber,
          crsDef
        );

        const rasterCenterWg = reprojectUtmToWgPoint(
          [(yParentMin + yParentMax) / 2, (xParentMin + xParentMax) / 2],
          crsDef
        );

        const rasterizationPriority =
          state.currentCase?.rasterParameters?.rasterizationPriority;
        const rasterizationPriorityOrderedList = rasterizationPriority.map(
          (x) => {
            if (x === "b") {
              return "building";
            } else if (x === "w") {
              return "water";
            } else if (x === "v") {
              return "vegetation";
            } else return "pavement";
          }
        );

        const bufferAreaPolygonPoints = calculateBufferAreaPolygonPoints(
          rasterCenterLngUtm + Number(state.parentOffsetX),
          rasterCenterLatUtm + Number(state.parentOffsetY),
          Number(state.parentGridSize),
          Number(state.parentRasterGridXNumber),
          Number(state.parentRasterGridYNumber),
          Number(state.gridPointsNorth),
          Number(state.gridPointsSouth),
          Number(state.gridPointsWest),
          Number(state.gridPointsEast),
          crsDef
        );

        return {
          ...state,
          ...addData,
          rasterCenterLatUtm: rasterCenterLatUtm,
          rasterCenterLngUtm: rasterCenterLngUtm,
          rasterCenterLatWg: rasterCenterWg.lat.toFixed(6),
          rasterCenterLngWg: rasterCenterWg.lng.toFixed(6),
          parentGridSize: parentGridSize,
          parentRasterGridXNumber: parentRasterGridXNumber,
          parentRasterGridYNumber: parentRasterGridYNumber,
          parentAreaPolygonPoints: parentAreaPolygonPoints,

          childGridSize: childGridSize,
          childRasterGridXNumber: childRasterGridXNumber,
          childRasterGridYNumber: childRasterGridYNumber,
          childAreaPolygonPoints: childAreaPolygonPoints,
          gridSizes: `Parent ${parentGridSize}m / Child ${childGridSize}m`,

          isGeoJsonVisible: true,
          isRasterPreviewVisible: false,
          isRasterAreaVisible: false,
          isParentRasterPreviewVisible: false,
          isParentRasterAreaVisible: false,

          isChildRasterPreviewVisible: false,
          isChildRasterAreaVisible: false,

          domainType: "NestedDomain",
          season: season,
          soilType: soilType,
          rasterMethod: rasterMethod,
          fillValue: fill_Value,
          fillValueBelowTreePatches: fillValueBelowTreePatches,
          rasterizationPriority: rasterizationPriority,
          rasterizationPriorityOrderedList: rasterizationPriorityOrderedList,
          rasterPreview: null,

          bufferAreaPolygonPoints: bufferAreaPolygonPoints,
        };
      }

      return { ...state };
    }

    case types.LOAD_NESTED_AREA_PREVIEW: {
      const crsDef = action.payload.crsDef;
      const parentRasterPreview = reproject(
        action.payload.parentRaster.parentRasterPreview,
        crsDef,
        wgDef
      );

      const childRasterPreview = reproject(
        action.payload.childRaster.childRasterPreview,
        crsDef,
        wgDef
      );

      return {
        ...state,
        parentRasterPreview: parentRasterPreview,
        childRasterPreview: childRasterPreview,
      };
    }

    case types.ON_SINGLE_RASTER_LOAD_VISIBILITY: {
      return {
        ...state,
        isRasterPreviewVisible: true,
        isGeoJsonVisible: false,
        isDrawnGeoJsonVisible: false,
        isRasterAreaVisible: false,
      };
    }

    case types.SET_INITIAL_DEFAULT_VISIBILITES: {
      return {
        ...state,
        isRasterAreaVisible: true,
      };
    }

    case types.ON_NESTED_RASTER_LOAD_VISIBILITY: {
      return {
        ...state,
        isParentRasterPreviewVisible: true,
        isChildRasterPreviewVisible: true,
        isParentRasterAreaVisible: false,
        isChildRasterAreaVisible: false,
        isGeoJsonVisible: false,
        isDrawnGeoJsonVisible: false,
      };
    }

    case types.SET_VISIBILITIES_ON_EXIT_MODE: {
      return {
        ...state,
        isRasterPreviewVisible: false,
        isRasterAreaVisible: false,
        isGeoJsonVisible: true,
        isParentRasterAreaVisible: false,
        isChildRasterAreaVisible: false,
        isRasterAreaDraggable: false,
        isParentRasterPreviewVisible: false,
        isChildRasterPreviewVisible: false,
        isDrawnGeoJsonVisible: true,
      };
    }

    case types.SET_DOMAIN_TYPE: {
      if (action.payload === "SingleDomain") {
        return {
          ...initialState,
          currentCase: state.currentCase,
          domainType: action.payload,
          isRasterAreaVisible: true,
        };
      } else {
        return {
          ...initialState,
          currentCase: state.currentCase,
          domainType: action.payload,
          isRasterAreaVisible: false,
          isParentRasterAreaVisible: true,
          isChildRasterAreaVisible: true,
        };
      }
    }

    case types.SHOW_NESTED_RASTER_AREA: {
      return {
        ...state,
        isRasterAreaVisible: false,
        isParentRasterAreaVisible: true,
        isChildRasterAreaVisible: true,
      };
    }

    case types.SET_GRID_SIZES: {
      const { crsDef, gridSizes } = action.payload;
      const parentGridSize = parseInt(gridSizes.match(/\d+/g)[0]);
      const childGridSize = parseInt(gridSizes.match(/\d+/g)[1]);

      const rasterCenterLngUtm = cloneDeep(state.rasterCenterLngUtm);
      const rasterCenterLatUtm = cloneDeep(state.rasterCenterLatUtm);
      const parentRasterGridXNumber = cloneDeep(state.parentRasterGridXNumber);
      const parentRasterGridYNumber = cloneDeep(state.parentRasterGridYNumber);

      const childRasterGridXNumber = cloneDeep(state.childRasterGridXNumber);
      const childRasterGridYNumber = cloneDeep(state.childRasterGridYNumber);

      const parentAreaPolygonPoints = calculateRasterAreaPolygonPoints(
        rasterCenterLngUtm,
        rasterCenterLatUtm,
        parentGridSize,
        parentRasterGridXNumber,
        parentRasterGridYNumber,
        crsDef
      );

      const childAreaPolygonPoints = calculateRasterAreaPolygonPoints(
        rasterCenterLngUtm,
        rasterCenterLatUtm,
        childGridSize,
        childRasterGridXNumber,
        childRasterGridYNumber,
        crsDef
      );

      return {
        ...state,
        ...bufferAreaInitialState,
        gridSizes: gridSizes,
        parentGridSize: parentGridSize,
        childGridSize: childGridSize,
        parentAreaPolygonPoints: parentAreaPolygonPoints,
        childAreaPolygonPoints: childAreaPolygonPoints,
        parentOffsetX: 0,
        parentOffsetY: 0,
        verticalResolutionMeter: gridSizes,
      };
    }

    case types.SET_PARENT_RASTER_GRID_X_NUMBER: {
      const crsDef = action.payload.crsDef;
      const rasterCenterLngUtm = cloneDeep(state.rasterCenterLngUtm);
      const rasterCenterLatUtm = cloneDeep(state.rasterCenterLatUtm);
      const parentRasterGridXNumber = action.payload.parentRasterGridXNumber;
      const parentRasterGridYNumber = cloneDeep(state.parentRasterGridYNumber);
      const parentGridSize = cloneDeep(state.parentGridSize);

      const parentAreaPolygonPoints = calculateRasterAreaPolygonPoints(
        rasterCenterLngUtm + Number(state.parentOffsetX),
        rasterCenterLatUtm + Number(state.parentOffsetY),
        parentGridSize,
        parentRasterGridXNumber,
        parentRasterGridYNumber,
        crsDef
      );
      return {
        ...state,
        ...bufferAreaInitialState,
        parentRasterGridXNumber: parentRasterGridXNumber,
        parentAreaPolygonPoints: parentAreaPolygonPoints,
      };
    }

    case types.SET_PARENT_RASTER_GRID_Y_NUMBER: {
      const crsDef = action.payload.crsDef;
      const rasterCenterLngUtm = cloneDeep(state.rasterCenterLngUtm);
      const rasterCenterLatUtm = cloneDeep(state.rasterCenterLatUtm);
      const parentRasterGridXNumber = cloneDeep(state.parentRasterGridXNumber);
      const parentRasterGridYNumber = action.payload.parentRasterGridYNumber;
      const parentGridSize = cloneDeep(state.parentGridSize);

      const parentAreaPolygonPoints = calculateRasterAreaPolygonPoints(
        rasterCenterLngUtm + Number(state.parentOffsetX),
        rasterCenterLatUtm + Number(state.parentOffsetY),
        parentGridSize,
        parentRasterGridXNumber,
        parentRasterGridYNumber,
        crsDef
      );

      return {
        ...state,
        ...bufferAreaInitialState,
        parentRasterGridYNumber: parentRasterGridYNumber,
        parentAreaPolygonPoints: parentAreaPolygonPoints,
      };
    }

    case types.SET_CHILD_RASTER_GRID_X_NUMBER: {
      const crsDef = action.payload.crsDef;
      const rasterCenterLngUtm = cloneDeep(state.rasterCenterLngUtm);
      const rasterCenterLatUtm = cloneDeep(state.rasterCenterLatUtm);
      const childRasterGridXNumber = action.payload.childRasterGridXNumber;
      const childRasterGridYNumber = cloneDeep(state.childRasterGridYNumber);
      const childGridSize = cloneDeep(state.childGridSize);

      const childAreaPolygonPoints = calculateRasterAreaPolygonPoints(
        rasterCenterLngUtm,
        rasterCenterLatUtm,
        childGridSize,
        childRasterGridXNumber,
        childRasterGridYNumber,
        crsDef
      );
      return {
        ...state,
        ...bufferAreaInitialState,
        childRasterGridXNumber: childRasterGridXNumber,
        childAreaPolygonPoints: childAreaPolygonPoints,
      };
    }

    case types.SET_CHILD_RASTER_GRID_Y_NUMBER: {
      const crsDef = action.payload.crsDef;
      const rasterCenterLngUtm = cloneDeep(state.rasterCenterLngUtm);
      const rasterCenterLatUtm = cloneDeep(state.rasterCenterLatUtm);
      const childRasterGridXNumber = cloneDeep(state.childRasterGridXNumber);
      const childRasterGridYNumber = action.payload.childRasterGridYNumber;
      const childGridSize = cloneDeep(state.childGridSize);

      const childAreaPolygonPoints = calculateRasterAreaPolygonPoints(
        rasterCenterLngUtm,
        rasterCenterLatUtm,
        childGridSize,
        childRasterGridXNumber,
        childRasterGridYNumber,
        crsDef
      );
      return {
        ...state,
        ...bufferAreaInitialState,
        childRasterGridYNumber: childRasterGridYNumber,
        childAreaPolygonPoints: childAreaPolygonPoints,
      };
    }

    case types.SET_SOIL_TYPE: {
      return {
        ...state,
        soilType: action.payload,
      };
    }

    case types.SET_SEASON: {
      return {
        ...state,
        season: action.payload,
      };
    }

    case types.SET_RASTER_METHOD: {
      return {
        ...state,
        rasterMethod: action.payload,
      };
    }

    case types.SET_FILL_VALUE: {
      const fill_Value = fillValue.find(
        (x) => x.rasterValue === action.payload
      );
      return {
        ...state,
        fillValue: fill_Value,
      };
    }

    case types.SET_FILL_VALUE_BELOW_TREE_PATCHES: {
      const fillValueBelowTreePatches = fillValue.find(
        (x) => x.rasterValue === action.payload
      );
      return {
        ...state,
        fillValueBelowTreePatches: fillValueBelowTreePatches,
      };
    }

    case types.SET_RASTERIZATION_PRIORITY: {
      const rasterizationPriorityOrderedList = action.payload;
      const rasterizationPriority = rasterizationPriorityOrderedList.map(
        (x) => {
          return x[0];
        }
      );
      return {
        ...state,
        rasterizationPriorityOrderedList: action.payload,
        rasterizationPriority: rasterizationPriority,
      };
    }

    case types.SET_TOPOGRAPHY_FOR_CHILD_DOMAIN: {
      return {
        ...state,
        topographyForChildDomain: action.payload,
      };
    }

    case types.TOGGLE_PARENT_RASTER_PREVIEW_VISIBILITY: {
      return {
        ...state,
        isParentRasterPreviewVisible: !state.isParentRasterPreviewVisible,
      };
    }

    case types.TOGGLE_CHILD_RASTER_PREVIEW_VISIBILITY: {
      return {
        ...state,
        isChildRasterPreviewVisible: !state.isChildRasterPreviewVisible,
      };
    }

    case types.TOGGLE_PARENT_RASTER_AREA_VISIBILITY: {
      return {
        ...state,
        isParentRasterAreaVisible: !state.isParentRasterAreaVisible,
      };
    }

    case types.TOGGLE_CHILD_RASTER_AREA_VISIBILITY: {
      return {
        ...state,
        isChildRasterAreaVisible: !state.isChildRasterAreaVisible,
      };
    }

    case types.SET_SINGLE_RASTER_CASE_PROPS: {
      let currentCase = state.currentCase;
      if (!currentCase) {
        currentCase = {};
      }
      currentCase.rasterParameters = action.payload;
      return {
        ...state,
        currentCase: currentCase,
      };
    }

    case types.SET_NESTED_RASTER_CASE_PROPS: {
      const currentCase = state.currentCase;

      currentCase.rasterParameters = action.payload.rasterParameters;
      currentCase.childRasterParameters = action.payload.childRasterParameters;
      return {
        ...state,
        currentCase: currentCase,
      };
    }

    case types.SET_PARENT_OFFSET_X: {
      const parentOffsetX = action.payload.parentOffsetX;
      const crsDef = action.payload.crsDef;
      let updatedParentAreaPolygonPoints = [];
      let rasterCenterLngUtm = state.rasterCenterLngUtm;

      if (parentOffsetX !== "") {
        rasterCenterLngUtm = Number(rasterCenterLngUtm) + Number(parentOffsetX);

        updatedParentAreaPolygonPoints = calculateRasterAreaPolygonPoints(
          rasterCenterLngUtm,
          state.rasterCenterLatUtm + Number(state.parentOffsetY),
          state.parentGridSize,
          state.parentRasterGridXNumber,
          state.parentRasterGridYNumber,
          crsDef
        );
      } else {
        updatedParentAreaPolygonPoints = calculateRasterAreaPolygonPoints(
          rasterCenterLngUtm,
          state.rasterCenterLatUtm + Number(state.parentOffsetY),
          state.parentGridSize,
          state.parentRasterGridXNumber,
          state.parentRasterGridYNumber,
          crsDef
        );
      }

      const bufferAreaPolygonPoints = calculateBufferAreaPolygonPoints(
        Number(state.rasterCenterLngUtm) + Number(parentOffsetX),
        Number(state.rasterCenterLatUtm) + Number(state.parentOffsetY),
        Number(state.parentGridSize),
        Number(state.parentRasterGridXNumber),
        Number(state.parentRasterGridYNumber),
        Number(state.gridPointsNorth),
        Number(state.gridPointsSouth),
        Number(state.gridPointsWest),
        Number(state.gridPointsEast),
        crsDef
      );

      return {
        ...state,
        parentOffsetX: parentOffsetX,
        parentAreaPolygonPoints: updatedParentAreaPolygonPoints,
        bufferAreaPolygonPoints: bufferAreaPolygonPoints,
      };
    }

    case types.SET_PARENT_OFFSET_Y: {
      const parentOffsetY = action.payload.parentOffsetY;
      const crsDef = action.payload.crsDef;
      let updatedParentAreaPolygonPoints = [];
      let rasterCenterLatUtm = state.rasterCenterLatUtm;
      if (parentOffsetY !== "") {
        rasterCenterLatUtm = rasterCenterLatUtm + Number(parentOffsetY);
        updatedParentAreaPolygonPoints = calculateRasterAreaPolygonPoints(
          Number(state.rasterCenterLngUtm) + Number(state.parentOffsetX),
          rasterCenterLatUtm,
          state.parentGridSize,
          state.parentRasterGridXNumber,
          state.parentRasterGridYNumber,
          crsDef
        );
      } else {
        updatedParentAreaPolygonPoints = calculateRasterAreaPolygonPoints(
          Number(state.rasterCenterLngUtm) + Number(state.parentOffsetX),
          rasterCenterLatUtm,
          state.parentGridSize,
          state.parentRasterGridXNumber,
          state.parentRasterGridYNumber,
          crsDef
        );
      }

      const bufferAreaPolygonPoints = calculateBufferAreaPolygonPoints(
        Number(state.rasterCenterLngUtm) + Number(state.parentOffsetX),
        Number(state.rasterCenterLatUtm) + Number(parentOffsetY),
        Number(state.parentGridSize),
        Number(state.parentRasterGridXNumber),
        Number(state.parentRasterGridYNumber),
        Number(state.gridPointsNorth),
        Number(state.gridPointsSouth),
        Number(state.gridPointsWest),
        Number(state.gridPointsEast),
        crsDef
      );

      return {
        ...state,
        parentOffsetY: parentOffsetY,
        parentAreaPolygonPoints: updatedParentAreaPolygonPoints,
        bufferAreaPolygonPoints: bufferAreaPolygonPoints,
      };
    }

    case types.SET_GLOBAL_WATER_TEMPERATURE_SOURCE: {
      return {
        ...state,
        globalWaterTemperatureSource: action.payload,
      };
    }

    case types.SET_GLOBAL_WATER_TEMPERATURE: {
      return {
        ...state,
        globalWaterTemperature: action.payload,
      };
    }

    case types.TOGGLE_BUFFER_AREA_VISIBILITY: {
      return {
        ...state,
        isBufferAreaVisible: !state.isBufferAreaVisible,
      };
    }

    case types.SET_BUFFER_NORTH: {
      const { gridPointsNorth, crsDef } = action.payload;

      let bufferAreaPolygonPoints = null;
      if (state.domainType === "SingleDomain") {
        bufferAreaPolygonPoints = calculateBufferAreaPolygonPoints(
          Number(state.rasterCenterLngUtm),
          Number(state.rasterCenterLatUtm),
          Number(state.rasterGridWidth),
          Number(state.rasterGridXNumber),
          Number(state.rasterGridYNumber),
          gridPointsNorth,
          Number(state.gridPointsSouth),
          Number(state.gridPointsWest),
          Number(state.gridPointsEast),
          crsDef
        );
      } else {
        bufferAreaPolygonPoints = calculateBufferAreaPolygonPoints(
          Number(state.rasterCenterLngUtm) + Number(state.parentOffsetX),
          Number(state.rasterCenterLatUtm) + Number(state.parentOffsetY),
          Number(state.parentGridSize),
          Number(state.parentRasterGridXNumber),
          Number(state.parentRasterGridYNumber),
          gridPointsNorth,
          Number(state.gridPointsSouth),
          Number(state.gridPointsWest),
          Number(state.gridPointsEast),
          crsDef
        );
      }

      return {
        ...state,
        gridPointsNorth: gridPointsNorth,
        bufferAreaPolygonPoints: bufferAreaPolygonPoints,
      };
    }

    case types.SET_BUFFER_SOUTH: {
      const { gridPointsSouth, crsDef } = action.payload;

      let bufferAreaPolygonPoints = null;
      if (state.domainType === "SingleDomain") {
        bufferAreaPolygonPoints = calculateBufferAreaPolygonPoints(
          Number(state.rasterCenterLngUtm),
          Number(state.rasterCenterLatUtm),
          Number(state.rasterGridWidth),
          Number(state.rasterGridXNumber),
          Number(state.rasterGridYNumber),
          Number(state.gridPointsNorth),
          Number(gridPointsSouth),
          Number(state.gridPointsWest),
          Number(state.gridPointsEast),
          crsDef
        );
      } else {
        bufferAreaPolygonPoints = calculateBufferAreaPolygonPoints(
          Number(state.rasterCenterLngUtm) + Number(state.parentOffsetX),
          Number(state.rasterCenterLatUtm) + Number(state.parentOffsetY),
          Number(state.parentGridSize),
          Number(state.parentRasterGridXNumber),
          Number(state.parentRasterGridYNumber),
          Number(state.gridPointsNorth),
          Number(gridPointsSouth),
          Number(state.gridPointsWest),
          Number(state.gridPointsEast),
          crsDef
        );
      }

      return {
        ...state,
        gridPointsSouth: gridPointsSouth,
        bufferAreaPolygonPoints: bufferAreaPolygonPoints,
      };
    }

    case types.SET_BUFFER_WEST: {
      const { gridPointsWest, crsDef } = action.payload;

      let bufferAreaPolygonPoints = null;
      if (state.domainType === "SingleDomain") {
        bufferAreaPolygonPoints = calculateBufferAreaPolygonPoints(
          Number(state.rasterCenterLngUtm),
          Number(state.rasterCenterLatUtm),
          Number(state.rasterGridWidth),
          Number(state.rasterGridXNumber),
          Number(state.rasterGridYNumber),
          Number(state.gridPointsNorth),
          Number(state.gridPointsSouth),
          Number(gridPointsWest),
          Number(state.gridPointsEast),
          crsDef
        );
      } else {
        bufferAreaPolygonPoints = calculateBufferAreaPolygonPoints(
          Number(state.rasterCenterLngUtm) + Number(state.parentOffsetX),
          Number(state.rasterCenterLatUtm) + Number(state.parentOffsetY),
          Number(state.parentGridSize),
          Number(state.parentRasterGridXNumber),
          Number(state.parentRasterGridYNumber),
          Number(state.gridPointsNorth),
          Number(state.gridPointsSouth),
          Number(gridPointsWest),
          Number(state.gridPointsEast),
          crsDef
        );
      }

      return {
        ...state,
        gridPointsWest: gridPointsWest,
        bufferAreaPolygonPoints: bufferAreaPolygonPoints,
      };
    }

    case types.SET_BUFFER_EAST: {
      const { gridPointsEast, crsDef } = action.payload;

      let bufferAreaPolygonPoints = null;
      if (state.domainType === "SingleDomain") {
        bufferAreaPolygonPoints = calculateBufferAreaPolygonPoints(
          Number(state.rasterCenterLngUtm),
          Number(state.rasterCenterLatUtm),
          Number(state.rasterGridWidth),
          Number(state.rasterGridXNumber),
          Number(state.rasterGridYNumber),
          Number(state.gridPointsNorth),
          Number(state.gridPointsSouth),
          Number(state.gridPointsWest),
          Number(gridPointsEast),
          crsDef
        );
      } else {
        bufferAreaPolygonPoints = calculateBufferAreaPolygonPoints(
          state.rasterCenterLngUtm + Number(state.parentOffsetX),
          state.rasterCenterLatUtm + Number(state.parentOffsetY),
          state.parentGridSize,
          state.parentRasterGridXNumber,
          state.parentRasterGridYNumber,
          Number(state.gridPointsNorth),
          Number(state.gridPointsSouth),
          Number(state.gridPointsWest),
          Number(gridPointsEast),
          crsDef
        );
      }
      return {
        ...state,
        gridPointsEast: gridPointsEast,
        bufferAreaPolygonPoints: bufferAreaPolygonPoints,
      };
    }

    case types.SET_VERTICAL_RESOLUTION_MODE: {
      const verticalResolutionMode = action.payload;
      if (action.payload === "AsHorizontal") {
        return {
          ...state,
          verticalResolutionMode: verticalResolutionMode,
          verticalResolutionMeter: null,
        };
      }
      return {
        ...state,
        verticalResolutionMode: verticalResolutionMode,
      };
    }

    case types.SET_VERTICAL_RESOLUTION_METER: {
      return {
        ...state,
        verticalResolutionMeter: action.payload,
      };
    }

    case types.SET_BUILDING_HEIGHTS: {
      const buildingHeights = action.payload;

      if (buildingHeights === "fromCityModel") {
        return {
          ...state,
          buildingHeights: action.payload,
          buildingHeightsFile: null,
        };
      }

      return {
        ...state,
        buildingHeights: action.payload,
      };
    }

    case types.SET_BUILDING_HEIGHTS_FILE: {
      return {
        ...state,
        buildingHeightsFile: action.payload,
      };
    }

    case types.SET_CHILD_BUILDING_HEIGHTS: {
      const childBuildingHeights = action.payload;
      if (childBuildingHeights === "fromCityModel") {
        return {
          ...state,
          childBuildingHeights: action.payload,
          childBuildingHeightsFile: null,
        };
      }
      return {
        ...state,
        childBuildingHeights: action.payload,
      };
    }

    case types.SET_CHILD_BUILDING_HEIGHTS_FILE: {
      return {
        ...state,
        childBuildingHeightsFile: action.payload,
      };
    }

    case types.SET_TREE_HEIGHTS: {
      const treeHeights = action.payload;
      if (treeHeights === "fromCityModel") {
        return {
          ...state,
          treeHeights: action.payload,
          treeHeightsFile: null,
        };
      }

      return {
        ...state,
        treeHeights: action.payload,
      };
    }

    case types.SET_TREE_HEIGHTS_FILE: {
      return {
        ...state,
        treeHeightsFile: action.payload,
      };
    }

    case types.SET_CHILD_TREE_HEIGHTS: {
      const childTreeHeights = action.payload;
      if (childTreeHeights === "fromCityModel") {
        return {
          ...state,
          childTreeHeights: action.payload,
          childTreeHeightsFile: null,
        };
      }
      return {
        ...state,
        childTreeHeights: action.payload,
      };
    }

    case types.SET_CHILD_TREE_HEIGHTS_FILE: {
      return {
        ...state,
        childTreeHeightsFile: action.payload,
      };
    }

    case types.SET_BUFFER_FILL_TYPE: {
      const bufferFillType = action.payload;
      let bufferFillValue = pavementFillValue[0];
      let bufferFillValueOptions = pavementFillValue;

      if (bufferFillType === "vegetation") {
        bufferFillValue = vegetationFillValue[0];
        bufferFillValueOptions = vegetationFillValue;
      } else if (bufferFillType === "water") {
        bufferFillValue = waterFillValue[0];
        bufferFillValueOptions = waterFillValue;
      }
      return {
        ...state,
        bufferFillType: action.payload,
        bufferFillValue: bufferFillValue,
        bufferFillValueOptions: bufferFillValueOptions,
      };
    }

    case types.SET_BUFFER_FILL_VALUE: {
      let bufferFillValueProp = action.payload;
      const bufferFillType = cloneDeep(state.bufferFillType);
      let bufferFillValueOptions = null;

      if (bufferFillType === "vegetation") {
        bufferFillValueOptions = vegetationFillValue;
      } else if (bufferFillType === "water") {
        bufferFillValueOptions = waterFillValue;
      } else {
        bufferFillValueOptions = pavementFillValue;
      }

      const bufferFillValue = bufferFillValueOptions.find(
        (x) => x.rasterValue === bufferFillValueProp
      );

      return {
        ...state,
        bufferFillValue: bufferFillValue,
      };
    }

    case types.SET_BUFFER_METHOD: {
      return {
        ...state,
        bufferMethod: action.payload,
      };
    }

    case types.SET_BUFFER_TOPOGRAPHY_INTERP_METHOD: {
      return {
        ...state,
        bufferTopographyInterpMethod: action.payload,
      };
    }

    case types.SET_SOIL_ALPHA_COEFFICIENT: {
      return {
        ...state,
        soilAlphaCoefficient: action.payload,
      };
    }

    case types.SET_SOIL_L_COEFFICIENT: {
      return {
        ...state,
        lSoilCoefficient: action.payload,
      };
    }

    case types.SET_SOIL_N_COEFFICIENT: {
      return {
        ...state,
        nSoilCoefficient: action.payload,
      };
    }

    case types.SET_SOIL_HYDRAULIC_CONDUCTIVITY: {
      return {
        ...state,
        soilHydraulicConductivity: action.payload,
      };
    }

    case types.SET_SOIL_MOISTURE_SATURATION: {
      return {
        ...state,
        soilMoistureSaturation: action.payload,
      };
    }

    case types.SET_SOIL_MOISTURE_CAPACITY: {
      return {
        ...state,
        soilMoistureCapacity: action.payload,
      };
    }

    case types.SET_SOIL_MOISTURE_WILTING: {
      return {
        ...state,
        soilMoistureWilting: action.payload,
      };
    }

    case types.SET_SOIL_MOISTURE_CONTENT: {
      return {
        ...state,
        soilMoistureContent: action.payload,
      };
    }

    case projectTypes.SET_CURRENT_CASE: {
      return {
        ...initialState,
        currentCase: null,
      };
    }

    case projectTypes.SET_CURRENT_CASE_SUCCESS: {
      return {
        ...initialState,
        currentCase: action.payload,
      };
    }

    case notificationTypes.ADD_NOTIFICATION: {
      if (
        action.payload.type === "rasterPreview" &&
        action.payload.body.status === 2
      ) {
        const currentCase = state.currentCase;
        currentCase.rasterPreviewJob = {
          resultUrl: action.payload.body.resultUrl,
          childResultUrl: action.payload.body.childResultUrl,
        };
        return {
          ...state,
          currentCase: currentCase,
        };
      }
      return { ...state };
    }
    default:
      return state;
  }
};

export default rasterAreaReducer;
