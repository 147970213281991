import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import ProgressBar from "../../Components/ProgressBar/ProgressBar";
import { Loader } from "../../Components/Loader/index";
import DeleteModal from "../../Components/Common/DeleteModal/index";
import { XCircle } from "react-feather";
import { simStatus } from "../../redux/reducers/simulationReducer";
import { minutesToHoursMinutesSeconds } from "../../utils/date/dateHelpers";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const SimStatus = (props) => {
  const { t } = useTranslation();
  const projects = useSelector((state) => state.projects);

  const currentCase = useSelector((state) => state.projects.currentCase);
  const [isProcessingModalOpen, toggleProcessingModal] = useState(false);

  const startPostprocessing = async () => {
    toggleProcessingModal(!isProcessingModalOpen);
    await props.requestPostProcessing();
  };

  const toggleRedoProcessModal = () => {
    toggleProcessingModal(!isProcessingModalOpen);
  };

  const goToResults = (e) => {
    if (
      currentCase?.postProcessingJobs[currentCase.postProcessingJobs.length - 1]
        ?.status !== 2
    )
      e.preventDefault();
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <h2>{t("Status")}</h2>
      {currentCase?.simulationJobs?.some((x) => {
        return (
          (x.status === "canceled" || x.status === 9) &&
          x.subStatus === "budget_exceeded"
        );
      }) && (
        <div
          style={{
            color: "white",
            // backgroundColor: "#f1c40f",
            backgroundColor: "rgba(231, 76, 60, 0.82)",
            display: "flex",
            justifyContent: "center",
            padding: 5,
            marginBottom: 7,
            borderRadius: 2,
            alignItems: "center",
          }}
        >
          <XCircle width="17" strokeWidth="1.5" />
          <label style={{ marginLeft: 12, fontSize: 14, marginBottom: 1 }}>
            Budget has been exceeded
          </label>
        </div>
      )}
      {currentCase?.applicationField === 2 &&
        currentCase?.simulationJobs?.length > 0 &&
        currentCase?.simulationJobs?.map((x, i) => {
          return (
            <div className="wind-sim-status-wrapper">
              <label className="sim-number">Simulation {i + 1}</label>
              <label className="form-label user-settings-form-label">
                {t("InputData")}
              </label>
              <input
                className="setup-input"
                value={t("Locked")}
                style={{ caretColor: "white", cursor: "default" }}
              />
              <label className="form-label user-settings-form-label">
                {t("Status")}
              </label>
              <input
                className="setup-input"
                style={{ caretColor: "white", cursor: "default" }}
                value={
                  typeof x.status === "string"
                    ? t(x.status)
                    : t(simStatus[x.status])
                }
              />
              <label className="form-label user-settings-form-label">
                {t("StatusBar")}
              </label>
              <ProgressBar
                progress={
                  x.progress ? x.progress : x.simProgress ? x.simProgress : 0
                }
                status={
                  typeof x.status === "string"
                    ? x.status
                    : t(simStatus[x.status])
                }
              />
              <label
                className="form-label user-settings-form-label"
                style={{ marginTop: "12px", fontWeight: "500" }}
              >
                {t("LastUpdate")}: {new Date(x.updatedTs).toLocaleString()}
              </label>
              <label
                className="form-label user-settings-form-label"
                style={{ fontWeight: "500" }}
              >
                {t("ElapsedTime")}:{" "}
                {minutesToHoursMinutesSeconds(x.elapsedTimeInMinutes)}
              </label>
              <label
                className="form-label user-settings-form-label"
                style={{ fontWeight: "500" }}
              >
                {t("EstimatedRemainingTime")}:{" "}
                {minutesToHoursMinutesSeconds(x.simEta / 60)}
              </label>
            </div>
          );
        })}

      {currentCase &&
        currentCase?.applicationField !== 2 &&
        currentCase?.simulationJobs?.length !== 0 && (
          <>
            <label className="form-label user-settings-form-label">
              {t("InputData")}
            </label>
            <input
              className="setup-input"
              value={t("Locked")}
              style={{ caretColor: "white", cursor: "default" }}
            />
            <label className="form-label user-settings-form-label">
              {t("Status")}
            </label>
            <input
              className="setup-input"
              style={{ caretColor: "white", cursor: "default" }}
              value={
                typeof currentCase.simulationJobs[0].status === "string"
                  ? t(currentCase.simulationJobs[0].status)
                  : t(simStatus[currentCase.simulationJobs[0].status])
              }
            />
            <label className="form-label user-settings-form-label">
              {t("StatusBar")}
            </label>
            <ProgressBar
              progress={
                currentCase.simulationJobs[0].progress
                  ? currentCase.simulationJobs[0].progress
                  : currentCase.simulationJobs[0].simProgress
                  ? currentCase.simulationJobs[0].simProgress
                  : 0
              }
              status={
                typeof currentCase.simulationJobs[0].status === "string"
                  ? currentCase.simulationJobs[0].status
                  : t(simStatus[currentCase.simulationJobs[0].status])
              }
            />
            <label
              className="form-label user-settings-form-label"
              style={{ marginTop: "12px", fontWeight: "500" }}
            >
              {t("LastUpdate")}:{" "}
              {new Date(
                currentCase.simulationJobs[0].updatedTs
              ).toLocaleString()}
            </label>
            <label
              className="form-label user-settings-form-label"
              style={{ fontWeight: "500" }}
            >
              {t("ElapsedTime")}:{" "}
              {minutesToHoursMinutesSeconds(props.elapsedTimeInMinutes)}
            </label>
            <label
              className="form-label user-settings-form-label"
              style={{ fontWeight: "500" }}
            >
              {t("EstimatedRemainingTime")}:{" "}
              {minutesToHoursMinutesSeconds(props.eta / 60)}
            </label>
          </>
        )}

      {currentCase?.applicationField === 2 &&
        currentCase.simulationJobs?.length !== 0 &&
        currentCase.simulationJobs?.every((x) => {
          return x.status === "finished" || x.status === 8;
        }) && (
          <>
            <div className="sim-separator-line" style={{ marginTop: "20px" }} />
            <button
              className={`${
                currentCase?.postProcessingJobs[
                  currentCase.postProcessingJobs.length - 1
                ]?.status === 0 ||
                currentCase?.postProcessingJobs[
                  currentCase.postProcessingJobs.length - 1
                ]?.status === 1
                  ? `sim-btn-disabled`
                  : currentCase?.postProcessingJobs[
                      currentCase.postProcessingJobs.length - 1
                    ]?.status === 3
                  ? "sim-cancel-btn"
                  : `sim-start-btn sim-request-button`
              }`}
              style={{ marginBottom: "20px" }}
              onClick={
                currentCase?.postProcessingJobs[
                  currentCase.postProcessingJobs.length - 1
                ]?.status === 2 ||
                currentCase?.postProcessingJobs[
                  currentCase.postProcessingJobs.length - 1
                ]?.status === 3
                  ? toggleRedoProcessModal
                  : props.requestPostProcessing
              }
              disabled={
                currentCase?.postProcessingJobs[
                  currentCase.postProcessingJobs.length - 1
                ]?.status === 0 ||
                currentCase?.postProcessingJobs[
                  currentCase.postProcessingJobs.length - 1
                ]?.status === 1
                // ||
                // currentCase?.postProcessingJobs[
                //   currentCase.postProcessingJobs.length - 1
                // ]?.status === 2 ||
                // currentCase?.postProcessingJobs[
                //   currentCase.postProcessingJobs.length - 1
                // ]?.status === 3
              }
            >
              {currentCase?.postProcessingJobs[
                currentCase.postProcessingJobs.length - 1
              ]?.status === 0 ||
              currentCase?.postProcessingJobs[
                currentCase.postProcessingJobs.length - 1
              ]?.status === 1 ? (
                <div style={{ paddingRight: "12px" }}>
                  <Loader size="simulation" />
                </div>
              ) : (
                <></>
              )}
              <p className="sim-btn-inputs-text ">
                {currentCase?.postProcessingJobs[
                  currentCase.postProcessingJobs.length - 1
                ]?.status === 3
                  ? `${
                      t(`PostprocessingFailed`) + " " + t(`RedoPostprocessing`)
                    }`
                  : currentCase?.postProcessingJobs[
                      currentCase.postProcessingJobs.length - 1
                    ]?.status === 2
                  ? t(`RedoPostprocessing`)
                  : currentCase?.postProcessingJobs[
                      currentCase.postProcessingJobs.length - 1
                    ]?.status === 0 ||
                    currentCase?.postProcessingJobs[
                      currentCase.postProcessingJobs.length - 1
                    ]?.status === 1
                  ? t(`Postprocessing` + "...")
                  : t("StartPostprocessing")}
              </p>
            </button>
            <NavLink
              exact
              to={`/projects/${projects.loadedProject.id}/cases/${projects.currentCase.id}/results`}
              onClick={goToResults}
            >
              <button
                className={`${
                  currentCase?.postProcessingJobs[
                    currentCase.postProcessingJobs.length - 1
                  ]?.status === 2
                    ? `sim-start-btn sim-go-results-btn`
                    : `sim-btn-disabled sim-btn-go-results-disabled`
                }`}
                disabled={
                  currentCase?.postProcessingJobs[
                    currentCase.postProcessingJobs.length - 1
                  ]?.status === 0 ||
                  currentCase?.postProcessingJobs[
                    currentCase.postProcessingJobs.length - 1
                  ]?.status === 1 ||
                  currentCase?.postProcessingJobs[
                    currentCase.postProcessingJobs.length - 1
                  ]?.status === 3
                }
              >
                <p className="sim-btn-inputs-text">{t("GoToResults")}</p>
              </button>
            </NavLink>
          </>
        )}

      {currentCase?.applicationField !== 2 &&
        currentCase?.simulationJobs?.length !== 0 &&
        currentCase?.simulationJobs?.every((x) => {
          return x.status === "finished" || x.status === 8;
        }) && (
          <>
            <div className="sim-separator-line" style={{ marginTop: "20px" }} />
            <button
              className={`${
                currentCase?.postProcessingJobs[
                  currentCase.postProcessingJobs.length - 1
                ]?.status === 0 ||
                currentCase?.postProcessingJobs[
                  currentCase.postProcessingJobs.length - 1
                ]?.status === 1
                  ? `sim-btn-disabled`
                  : currentCase?.postProcessingJobs[
                      currentCase.postProcessingJobs.length - 1
                    ]?.status === 3
                  ? "sim-cancel-btn"
                  : `sim-start-btn sim-request-button`
              }`}
              style={{ marginBottom: "20px" }}
              onClick={
                currentCase?.postProcessingJobs[
                  currentCase.postProcessingJobs.length - 1
                ]?.status === 2 ||
                currentCase?.postProcessingJobs[
                  currentCase.postProcessingJobs.length - 1
                ]?.status === 3
                  ? toggleRedoProcessModal
                  : props.requestPostProcessing
              }
              disabled={
                currentCase?.postProcessingJobs[
                  currentCase.postProcessingJobs.length - 1
                ]?.status === 0 ||
                currentCase?.postProcessingJobs[
                  currentCase.postProcessingJobs.length - 1
                ]?.status === 1
                // ||
                // currentCase?.postProcessingJobs[
                //   currentCase.postProcessingJobs.length - 1
                // ]?.status === 2 ||
                // currentCase?.postProcessingJobs[
                //   currentCase.postProcessingJobs.length - 1
                // ]?.status === 3
              }
            >
              {currentCase?.postProcessingJobs[
                currentCase.postProcessingJobs.length - 1
              ]?.status === 0 ||
              currentCase?.postProcessingJobs[
                currentCase.postProcessingJobs.length - 1
              ]?.status === 1 ? (
                <div style={{ paddingRight: "12px" }}>
                  <Loader size="simulation" />
                </div>
              ) : (
                <></>
              )}
              <p className="sim-btn-inputs-text ">
                {currentCase?.postProcessingJobs[
                  currentCase.postProcessingJobs.length - 1
                ]?.status === 3
                  ? `${
                      t(`PostprocessingFailed`) + " " + t(`RedoPostprocessing`)
                    }`
                  : currentCase?.postProcessingJobs[
                      currentCase.postProcessingJobs.length - 1
                    ]?.status === 2
                  ? t(`RedoPostprocessing`)
                  : currentCase?.postProcessingJobs[
                      currentCase.postProcessingJobs.length - 1
                    ]?.status === 0 ||
                    currentCase?.postProcessingJobs[
                      currentCase.postProcessingJobs.length - 1
                    ]?.status === 1
                  ? t(`Postprocessing` + "...")
                  : t("StartPostprocessing")}
              </p>
            </button>
            <NavLink
              exact
              to={`/projects/${projects.loadedProject.id}/cases/${projects.currentCase.id}/results`}
              onClick={goToResults}
            >
              <button
                className={`${
                  currentCase?.postProcessingJobs[
                    currentCase.postProcessingJobs.length - 1
                  ]?.status === 2
                    ? `sim-start-btn sim-go-results-btn`
                    : `sim-btn-disabled sim-btn-go-results-disabled`
                }`}
                disabled={
                  currentCase?.postProcessingJobs[
                    currentCase.postProcessingJobs.length - 1
                  ]?.status === 0 ||
                  currentCase?.postProcessingJobs[
                    currentCase.postProcessingJobs.length - 1
                  ]?.status === 1 ||
                  currentCase?.postProcessingJobs[
                    currentCase.postProcessingJobs.length - 1
                  ]?.status === 3
                }
              >
                <p className="sim-btn-inputs-text">{t("GoToResults")}</p>
              </button>
            </NavLink>
          </>
        )}
      <DeleteModal
        modalHdr={t("RedoPostprocessing")}
        modalInfo={t("RedoPostprocessingModalInfo")}
        isOpen={isProcessingModalOpen}
        closeDeleteModal={toggleRedoProcessModal}
        deleteModalData={startPostprocessing}
      />
    </div>
  );
};

export default SimStatus;
