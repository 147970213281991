import { applicationFields } from "../../common/applicationFields";

export const fieldFormatter = (params, t) => {
  const appField = applicationFields.find((af) => af.id === params.value).key;
  return appField ? t(appField) : "";
};
export const statusFormatter = (params, t) => {
  const caseDto = params.data;
  const simulation = caseDto.simulationJob;
  if (!!simulation) {
    switch (simulation.status) {
      case 0: {
        return t("Waiting");
      }
      case 1: {
        return t("Simulating");
      }
      case 2: {
        return t("Error");
      }
      case 3: {
        return t("Finished");
      }
      default:
        break;
    }
  }
  const steering = caseDto.steering;
  if (!!steering && steering.status === 2) {
    return t("Checked");
  }

  return t("Draft");
};
