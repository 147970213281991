export const types = {
  ///GEOJSON
  SET_GEOJSON_WG: "SET_GEOJSON_WG",
  CHANGE_OBJECT_TYPE: "CHANGE_OBJECT_TYPE",
  CHANGE_OBJECT_SUBTYPE: "CHANGE_OBJECT_SUBTYPE",
  GET_SELECTED_MAP_ITEM_GEOPROPS: "GET_SELECTED_MAP_ITEM_GEOPROPS",
  CENTER_MAP: "CENTER_MAP",
  CENTER_MAP_SUCCESS: "CENTER_MAP_SUCCESS",
  RESET_GEODATA: "RESET_GEODATA",
  ///WMS
  SET_WMS_LAYER_NAMES: "SET_WMS_LAYER_NAMES",
  ///POLYGON DATA
  SET_POLYGON_DATA: "SET_POLYGON_DATA",
  UPDATE_BUILDING_HEIGHT: "UPDATE_BUILDING_HEIGHT",
  UPDATE_TREE_HEIGHT: "UPDATE_TREE_HEIGHT",
  UPDATE_POLYGON_DATA: "UPDATE_POLYGON_DATA",
  DELETE_POLYGON_DATA_BY_ID: "DELETE_POLYGON_DATA_BY_ID",
  ///POLYGON EDITOR
  SET_DRAWN_GEOJSON: "SET_DRAWN_GEOJSON",
  START_NEW_POLYGON: "START_NEW_POLYGON",
  DRAW_POLYGON: "DRAW_POLYGON",
  SELECT_POLYGON: "SELECT_POLYGON",
  SELECT_POLYGON_POINT: "SELECT_POLYGON_POINT",
  REMOVE_DRAWN_POLYGON: "REMOVE_DRAWN_POLYGON",
  SET_OBJECT_TYPE_INDEX: "SET_OBJECT_TYPE_INDEX",
  SET_OBJECT_SUBTYPE_INDEX: "SET_OBJECT_SUBTYPE_INDEX",
  ADD_COORDS_ROW: "ADD_COORDS_ROW",
  REMOVE_COORDS_ROW: "REMOVE_COORDS_ROW",
  HANDLE_COORD_INPUT_CHANGE: "HANDLE_COORD_INPUT_CHANGE",
  ADD_POLYGON_POINT_ONCLICK_WG: "ADD_POLYGON_POINT_ONCLICK_WG",
  DRAG_POINTWG: "DRAG_POINTWG",
  DRAGEND_POINTWG: "DRAGEND_POINTWG",
  GET_SELECTED_MAP_DRAWN_POLYGON_GEOPROPS:
    "GET_SELECTED_MAP_DRAWN_POLYGON_GEOPROPS",
  CHANGE_SELECTED_DRAWN_POLYGON_TYPE: "CHANGE_SELECTED_DRAWN_POLYGON_TYPE",
  CHANGE_SELECTED_DRAWN_POLYGON_SUBTYPE:
    "CHANGE_SELECTED_DRAWN_POLYGON_SUBTYPE",
  UNSELECT_SELECTED_DRAWN_POLYGON_GEO_PROPS:
    "UNSELECT_SELECTED_DRAWN_POLYGON_GEO_PROPS",
  INITIALIZE_POLYGON_EDITOR: "INITIALIZE_POLYGON_EDITOR",
  RESET_POLYGON_EDITOR_DATA: "RESET_POLYGON_EDITOR_DATA",
  SET_DRAWN_POLYGON_GEOJSON_CRS: "SET_DRAWN_POLYGON_GEOJSON_CRS",
  ///TREE DRAWING
  TOGGLE_TREE_DRAWING_MODE: "TOGGLE_TREE_DRAWING_MODE",
  ADD_TREE: "ADD_TREE",
  UPDATE_TREE_POSITION_ON_INPUT_CHANGE: "UPDATE_TREE_POSITION_ON_INPUT_CHANGE",
  SELECT_TREE: "SELECT_TREE",
  REMOVE_TREE: "REMOVE_TREE",
  GET_CURRENT_CURSOR_COORDS: "GET_CURRENT_CURSOR_COORDS",
  UPDATE_TREE_POSITION_ON_DRAGEND: "UPDATE_TREE_POSITION_ON_DRAGEND",
  CHANGE_SELECTED_TREE_SUBTYPE: "CHANGE_SELECTED_TREE_SUBTYPE",
  TOGGLE_POLYGON_DRAWING_MODE: "TOGGLE_POLYGON_DRAWING_MODE",
  TOGGLE_DRAWING_MODE: "TOGGLE_DRAWING_MODE",
  TOGGLE_RASTER_SETTINGS_MODE: "TOGGLE_RASTER_SETTINGS_MODE",
  GO_TO_TREE_PROPERTIES: "GO_TO_TREE_PROPERTIES",
  GO_TO_TREE_COORDINATES: "GO_TO_TREE_COORDINATES",
  //TOGGLE_SINGLE_TREE_VISIBILITY: "TOGGLE_SINGLE_TREE_VISIBILITY",
  UNSELECT_SELECTED_MAP_ITEM_GEO_PROPS: "UNSELECT_SELECTED_MAP_ITEM_GEO_PROPS",
  RESET_TREE_DRAWING_DATA: "RESET_TREE_DRAWING_DATA",
  CREATE_SINGLE_TREE_GEOJSON: "CREATE_SINGLE_TREE_GEOJSON",
  UPDATE_TEMP_INPUT: "UPDATE_TEMP_INPUT",
  TOGGLE_TREE_CURSOR_DISPLAY: "TOGGLE_TREE_CURSOR_DISPLAY",
  ENABLE_TREE_DRAGGABLE: "ENABLE_TREE_DRAGGABLE",
  DISABLE_TREE_DRAGGABLE: "DISABLE_TREE_DRAGGABLE",

  /////ASSIGNING_OBJECT_PROPS
  TOGGLE_COPY_PROPS_MODE: "TOGGLE_COPY_PROPS_MODE",
  ADD_ASSIGNED_OBJECT_COPY: "ADD_ASSIGNED_OBJECT_COPY",
  COPY_PROPS: "COPY_PROPS",
  /////ASSIGNING_SINGLE_TREE_PROPS
  TOGGLE_SINGLE_TREE_COPY_PROPS_MODE: "TOGGLE_SINGLE_TREE_COPY_PROPS_MODE",
  ADD_ASSIGNED_SINGLE_TREE_COPY: "ADD_ASSIGNED_SINGLE_TREE_COPY",
  COPY_SINGLE_TREE_PROPS: "COPY_SINGLE_TREE_PROPS",
  FILTER_GEOJSON: "FILTER_GEOJSON",

  RESET_ALL_MAP_DATA: "RESET_ALL_MAP_DATA",

  TOGGLE_EMISSION_DRAWING_MODE: "TOGGLE_EMISSION_DRAWING_MODE",
  ADD_EMISSION: "ADD_EMISSION",
  UPDATE_EMISSION_POSITION_ON_INPUT_CHANGE:
    "UPDATE_EMISSION_POSITION_ON_INPUT_CHANGE",
  SELECT_EMISSION: "SELECT_EMISSION",
  REMOVE_EMISSION: "REMOVE_EMISSION",
  UPDATE_EMISSION_POSITION_ON_DRAGEND: "UPDATE_EMISSION_POSITION_ON_DRAGEND",
  CHANGE_SELECTED_DRAWN_EMISSION_SUBTYPE:
    "CHANGE_SELECTED_DRAWN_EMISSION_SUBTYPE",
  GO_TO_EMISSION_PROPERTIES: "GO_TO_EMISSION_PROPERTIES",
  GO_TO_EMISSION_COORDINATES: "GO_TO_EMISSION_COORDINATES",
  RESET_EMISSION_DRAWING_DATA: "RESET_EMISSION_DRAWING_DATA",
  CREATE_SINGLE_EMISSION_DRAWN_GEOJSON: "CREATE_SINGLE_EMISSION_DRAWN_GEOJSON",
  TOGGLE_EMISSION_CURSOR_DISPLAY: "TOGGLE_EMISSION_CURSOR_DISPLAY",
  ENABLE_EMISSION_DRAGGABLE: "ENABLE_EMISSION_DRAGGABLE",
  DISABLE_EMISSION_DRAGGABLE: "DISABLE_EMISSION_DRAGGABLE",
  CHANGE_SELECTED_GEOJSON_EMISSION_SUBTYPE:
    "CHANGE_SELECTED_GEOJSON_EMISSION_SUBTYPE",
  TOGGLE_EMISSION_VISIBILITY: "TOGGLE_EMISSION_VISIBILITY",
  TOGGLE_EMISSION_DRAWN_VISIBILITY: "TOGGLE_EMISSION_DRAWN_VISIBILITY",
  UPDATE_EMISSION_GEOJSON_PROPERTIES: "UPDATE_EMISSION_GEOJSON_PROPERTIES",

  TOGGLE_MAP_POI_DRAWING_MODE: "TOGGLE_MAP_POI_DRAWING_MODE",
  ADD_MAP_POI: "ADD_MAP_POI",
  UPDATE_MAP_POI_POSITION_ON_INPUT_CHANGE:
    "UPDATE_MAP_POI_POSITION_ON_INPUT_CHANGE",
  SELECT_MAP_POI: "SELECT_MAP_POI",
  REMOVE_MAP_POI: "REMOVE_MAP_POI",
  UPDATE_MAP_POI_POSITION_ON_DRAGEND: "UPDATE_MAP_POI_POSITION_ON_DRAGEND",
  GO_TO_MAP_POI_PROPERTIES: "GO_TO_MAP_POI_PROPERTIES",
  GO_TO_MAP_POI_COORDINATES: "GO_TO_MAP_POI_COORDINATES",
  RESET_MAP_POI_DRAWING_DATA: "RESET_MAP_POI_DRAWING_DATA",
  CREATE_MAP_POI_GEOJSON: "CREATE_MAP_POI_GEOJSON",
  TOGGLE_MAP_POI_CURSOR_DISPLAY: "TOGGLE_MAP_POI_CURSOR_DISPLAY",
  ENABLE_MAP_POI_DRAGGABLE: "ENABLE_MAP_POI_DRAGGABLE",
  DISABLE_MAP_POI_DRAGGABLE: "DISABLE_MAP_POI_DRAGGABLE",
  CREATE_SINGLE_MAP_POI_DRAWN_GEOJSON: "CREATE_SINGLE_MAP_POI_DRAWN_GEOJSON",
  TOGGLE_MAP_POI_VISIBILITY: "TOGGLE_MAP_POI_VISIBILITY",
  TOGGLE_MAP_POI_DRAWN_VISIBILITY: "TOGGLE_MAP_POI_DRAWN_VISIBILITY",

  MERGE_CHEMISTRY_GEOJSON: "MERGE_CHEMISTRY_GEOJSON",
  TOGGLE_STREET_DRAWING_MODE: "TOGGLE_STREET_DRAWING_MODE",
  TOGGLE_EMISSION_COPY_PROPS_MODE: "TOGGLE_EMISSION_COPY_PROPS_MODE",
  ADD_ASSIGNED_SINGLE_EMISSION_COPY: "ADD_ASSIGNED_SINGLE_EMISSION_COPY",
  COPY_EMISSION_PROPS: "COPY_EMISSION_PROPS",

  REMOVE_OBJECT_FROM_UPLOADED_GEOJSON: "REMOVE_OBJECT_FROM_UPLOADED_GEOJSON",

  SET_MAP_DATA_SAVING_STATUS: "SET_MAP_DATA_SAVING_STATUS",

  SELECT_EDITOR_POINT: "SELECT_EDITOR_POINT",
};
