import React from "react";
import "./index.scss";

const FormSeparator = (props) => {
  return (
    <div className="form_separator">
      <h4 className="form-separator__label">{props.label}</h4>
      <div className="form-separator__line" />
    </div>
  );
};
export default FormSeparator;

export const FormSettingsSeparator = (props) => {
  return (
    <div className="form_section">
      <h4 className="form-section__txt" style={props.style}>
        {props.label}
      </h4>
      <div className="form-section__ruler" />
    </div>
  );
};
