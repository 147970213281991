import { apiRequest } from "./axiosApi";

const apiUrl = process.env.REACT_APP_FRONT_SERVICE_API;

export async function getCaseSetup(caseId) {
  const response = await apiRequest({
    method: "get",
    url: `${apiUrl}/Simulations/${caseId}`,
  });
  return response.data;
}

export async function postCaseSetup(payload) {
  await apiRequest({
    method: "post",
    url: `${apiUrl}/Simulations/${payload.caseId}/setup`,
    data: payload,
  });
}

export async function postSteering(caseId) {
  await apiRequest({
    method: "post",
    url: `${apiUrl}/Simulations/${caseId}/steering`,
  });
}

export async function requestSimulation(caseId, simulationJobId = null) {
  const body = {
    simulationJobId,
  };
  await apiRequest({
    method: "post",
    url: `${apiUrl}/Simulations/${caseId}/simulation`,
    data: body,
  });
}

export async function requestCustomSimulation(
  caseId,
  settings,
  simulationJobId = null
) {
  const body = {
    settings: settings,
    simulationJobId: simulationJobId,
  };

  await apiRequest({
    method: "post",
    url: `${apiUrl}/Simulations/${caseId}/simulation`,
    data: body,
  });
}

export async function cancelSimulation(caseId, simulationJobId) {
  await apiRequest({
    method: "post",
    url: `${apiUrl}/Simulations/${caseId}/simulation/${simulationJobId}/cancel`,
  });
}
export async function requestSimulationLog(caseId, simulationJobId) {
  await apiRequest({
    method: "post",
    url: `${apiUrl}/Simulations/${caseId}/simulation/${simulationJobId}/log`,
  });
}

export async function requestPostProcessingAsync(
  projectId,
  caseId,
  simulationJobId
) {
  const body = {
    projectId,
    caseId,
    simulationJobId,
  };
  await apiRequest({
    method: "post",
    url: `${apiUrl}/postprocessing`,
    data: body,
  });
}
export async function requestCustomPostProcessingAsync(
  projectId,
  caseId,
  simulationJobId,
  postProcessingType,
  settings,
  customSettings
) {
  const body = {
    projectId,
    caseId,
    simulationJobId,
    postProcessingType,
    settings,
    customSettings,
  };
  await apiRequest({
    method: "post",
    url: `${apiUrl}/postprocessing/custom`,
    data: body,
  });
}
