import { apiRequest } from "./axiosApi";

const apiUrl = process.env.REACT_APP_FRONT_SERVICE_API;

export async function requestNewLayer(payload) {
  await apiRequest({
    method: "post",
    url: `${apiUrl}/PostProcessing/horizontal-scalar`,
    data: payload,
  });
}

export async function requestNewCustomLayer(payload) {
  await apiRequest({
    method: "post",
    url: `${apiUrl}/PostProcessing/custom`,
    data: payload,
  });
}

// POST http://localhost:5001/api/PostProcessing/horizontal-scalar

// body:
// {

// 		"projectId": "170a2d9d-8bf2-45a7-880b-c2bbfe366c56",
// 		"caseId": "901ea4b3-9bb5-4281-84a8-20b37e2f6826",
//         "simulationJobId": "4d00ee13-5603-4f34-9dbe-173fa8868a62",
// 		"settings": {
// 			"layerId": "something",
// 			"layerName": "SomeName",
// 			"fileType": "mskRes",
// 			"resultVariable": "ta",
// 			"heightLevel": 1,
// 			"timestep": 1,
// 			"excludeBuildings": "True",
// 			"postProcessing": ""
// 		}
// }
