import React from "react";
import { Pane, Rectangle } from "react-leaflet";
import { connect } from "react-redux";

class OsmArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        {(this.props.isDrawingExtent || this.props.isExtentDrawn) && (
          <Pane autoPan style={{ zIndex: 100000 }}>
            <Rectangle
              bounds={this.props.extentCoords}
              color={
                this.props.isDrawingExtent ||
                (this.props.isAreaValid && this.props.isCrsValid)
                  ? "black"
                  : "red"
              }
              weight={2}
            />
          </Pane>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isDrawingExtent: state.tools.isDrawingExtent,
    isExtentDrawn: state.tools.isExtentDrawn,
    extentCoords: state.tools.extentCoords,
    isAreaValid: state.tools.isAreaValid,
    isCrsValid: state.tools.isCrsValid,
  };
};

export default connect(mapStateToProps, null)(OsmArea);
