export const types = {
  SET_THERMAL_MODE: "SET_THERMAL_MODE",
  SET_DYNAMIC_DRIVER: "SET_DYNAMIC_DRIVER",
  SET_START_DATE_SOURCE: "SET_START_DATE_SOURCE",

  SET_START_DAY: "SET_START_DAY",
  SET_HOURS_FROM_UTC: "SET_HOURS_FROM_UTC",
  SET_DURATION: "SET_DURATION",
  SET_AIR_TEMPERATURE_SURFACE: "SET_AIR_TEMPERATURE_SURFACE",
  SET_REL_HUMIDITY: "SET_REL_HUMIDITY",
  SET_WIND_SPEED: "SET_WIND_SPEED",
  SET_WIND_DIRECTION: "SET_WIND_DIRECTION",
  SET_MEAN_TEMPERATURE: "SET_MEAN_TEMPERATURE",
  SET_AMPLITUDE: "SET_AMPLITUDE",
  SET_SOIL_MOISTURE: "SET_SOIL_MOISTURE",
  SET_SOIL_TEMPERATURE: "SET_SOIL_TEMPERATURE",
  SET_OUTPUT_PRESETS: "SET_OUTPUT_PRESETS",
  SET_OUTPUT_PARAMETERS: "SET_OUTPUT_PARAMETERS",
  SET_OUTPUT_INTERVAL: "SET_OUTPUT_INTERVAL",
  SET_OUTPUT_TYPE: "SET_OUTPUT_TYPE",
  SET_NIGHT_TIME: "SET_NIGHT_TIME",
  SET_DAYTIME: "SET_DAYTIME",
  SET_TEST_RUN: "SET_TEST_RUN",
  SET_THERMAL_VARIABLES_AVERAGING: "SET_THERMAL_VARIABLES_AVERAGING",
  LOAD_THERMAL_SETTINGS: "LOAD_THERMAL_SETTINGS",
  RESET_THERMAL_SETTINGS: "RESET_THERMAL_SETTINGS",
  SET_USER_DEFINED_SOIL_MOISTURE: "SET_USER_DEFINED_SOIL_MOISTURE",
  SET_USER_DEFINED_SOIL_TEMPERATURE: "SET_USER_DEFINED_SOIL_TEMPERATURE",
};
