import React from "react";
import { Check, X } from "react-feather";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class StaticDriverIndicator extends React.Component {
  constructor(props) {
    super(props);
    this.state = { savedStaticDriver: false };
  }

  async componentDidUpdate(prevProps) {
    if (
      this.props.currentCase?.staticDriverJob?.status === "Completed" &&
      !this.state.savedStaticDriver
    ) {
      this.setState({ savedStaticDriver: true });
      return;
    } else if (
      this.props.staticDriverNotifiactions !==
      prevProps.staticDriverNotifiactions
    ) {
      if (!this.props.currentCase) {
        return;
      }

      for (
        let index = this.props.staticDriverNotifiactions.length - 1;
        index >= 0;
        index--
      ) {
        if (
          this.props.staticDriverNotifiactions[index].type === "staticDriver" &&
          this.props.staticDriverNotifiactions[index].body.caseId ===
            this.props.currentCase.id &&
          this.props.staticDriverNotifiactions[index].body.status === 2
        ) {
          this.setState({ savedStaticDriver: true });
          return;
        }
      }
    }
  }

  render() {
    const { t } = this.props;

    return (
      <div
        className="static-driver-indicator"
        title={
          this.props.currentCase?.staticDriverJob?.status === "Completed" ||
          this.state.savedStaticDriver
            ? t("StaticDriverCreated")
            : t("NoStaticDriverCreated")
        }
      >
        {this.props.currentCase?.staticDriverJob?.status === "Completed" ||
        this.state.savedStaticDriver ? (
          <Check strokeWidth={"3px"} size={"12px"} color={"#6e6e6e"} />
        ) : (
          <X strokeWidth={"3px"} size={"12px"} color={"#6e6e6e"} />
        )}
        <div className="static-driver-info">{t(`StaticDriver`)}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    staticDriverNotifiactions: state.notifications.notifications,
    currentCase: state.projects.currentCase,
  };
};

export default withTranslation()(
  connect(mapStateToProps, null)(StaticDriverIndicator)
);
