import React, { useState } from "react";
import { Search } from "react-feather";
import { TextInput } from "../Form/Form";
import { Loader } from "../../Loader";
import Icon from "../Icon";
import "./SearchBar.scss";

const SearchBar = (props) => {
  const [phrase, setPhrase] = useState("");
  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      if (phrase.length < 3) {
        return;
      }
      props.onSearch(phrase);
      return;
    }
    setPhrase(e.target.value);
  };
  return (
    <div className="search-bar search-bar__horizontal">
      <TextInput
        placeholder={`${props.placeholder}`}
        value={phrase}
        onKeyDown={(e) => onKeyDown(e)}
        onChange={(e) => setPhrase(e.target.value)}
      />
      <button
        className="wizard-button wizard-button-submit"
        disabled={phrase.length < 3}
        onClick={(e) => props.onSearch(phrase)}
      >
        {props.searching && <Loader size="x-small" mode="light" />}
        {!props.searching && <Icon icon={Search} />}
      </button>
    </div>
  );
};

export default SearchBar;
