import { types } from "../types/legend";
//import { types as mapTypes } from "../types/map";
import { cloneDeep } from "lodash";

const initialState = {
  legendVisibility: false,
  hiddenObjectTypesIndexes: [],
  allDrawnObjectsVisibility: true,
  allDrawnTreesVisibility: true,
  topographyVisibility: false,
  maxElevation: null,
  minElevation: null,
  topographyUrl: null,
  extraTopo: [],
  allDrawnEmissionVisibility: true,
  allDrawnMapPoiVisibility: true,
};

const legendReducer = (state = initialState, action) => {
  switch (action.type) {
    // case mapTypes.CHANGE_OBJECT_TYPE:
    //   return {
    //     ...state,
    //     hiddenObjectTypesIndexes: [],
    //   };
    // case mapTypes.CHANGE_OBJECT_SUBTYPE:
    //   return {
    //     ...state,
    //     hiddenObjectTypesIndexes: [],
    //   };
    // case mapTypes.GET_SELECTED_MAP_ITEM_GEOPROPS:
    //   return {
    //     ...state,
    //     hiddenObjectTypesIndexes: [],
    //   };
    case types.TOGGLE_ALL_DRAWN_OBJECTS_VISIBILITY:
      return {
        ...state,
        allDrawnObjectsVisibility: !state.allDrawnObjectsVisibility,
      };

    case types.TOGGLE_ALL_DRAWN_TREES_VISIBILITY:
      return {
        ...state,
        allDrawnTreesVisibility: !state.allDrawnTreesVisibility,
      };

    case types.TOGGLE_TOPOGRAPHY_VISIBILITY:
      return {
        ...state,
        topographyVisibility: !state.topographyVisibility,
      };
    case types.SET_HIDDEN_OBJECT_TYPES_INDEXES:
      return {
        ...state,
        hiddenObjectTypesIndexes: action.payload,
      };

    case types.RESET_LEGEND:
      return {
        ...initialState,
      };
    case types.SHOW_LEGEND:
      return {
        ...state,
        legendVisibility: true,
      };

    case types.HIDE_LEGEND:
      return {
        ...state,
        legendVisibility: false,
      };
    case types.HIDE_TOPOGRAPHY:
      return {
        ...state,
        topographyVisibility: false,
      };

    case types.GET_LEGEND_DATA:
      return {
        ...state,
        topographyUrl: action.payload.topographyUrl,
        maxElevation: action.payload.maxElevation,
        minElevation: action.payload.minElevation,
      };

    case types.SET_EXTRA_TOPOGRAPHY_VISIBILITY: {
      const extraTopoName = action.payload;
      const extraTopo = cloneDeep(state.extraTopo);
      const extraTopoIndex = extraTopo.findIndex((x) => {
        return x.name === extraTopoName;
      });

      const prevChecked = extraTopo[extraTopoIndex].checked;
      extraTopo[extraTopoIndex].checked = !prevChecked;

      return {
        ...state,
        extraTopo: extraTopo,
      };
    }
    case types.GET_EXTRA_LEGEND_DATA_PROPS:
      return {
        ...state,
        extraTopo: action.payload,
      };

    default:
      return state;
  }
};

export default legendReducer;
