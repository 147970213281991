import { call, fork, put, race, take, takeEvery } from "redux-saga/effects";
import { getUserSettingsAsync } from "../../api/userSettingsApi";
import { getUserRoles } from "../../api/roleApi";
import { getUserDetails } from "../../api/graphApi";
import authService from "../../utils/auth/authService";
import { initTranslations } from "../../utils/translations/i18n";
import * as authActions from "../actions/authActions";
import * as settingsActions from "../actions/settingsActions";
import { types } from "../types/auth";
import { loadSettings } from "./settingsSaga";

function* loginUser() {
  try {
    const identity = authService.getCurrentUser();
    const userSettings = yield call(getUserSettingsAsync);
    yield call(initTranslations, userSettings?.preferredLanguage);
    yield loadUserRoles();
    yield loadUserDetails();
    yield loadSettings();
    yield put(authActions.loginUserSuccess(identity));
    yield put(settingsActions.loadSettingsSuccess(userSettings));
  } catch (error) {
    console.log(error);
    yield put(authActions.loginUserFailure(error));
  }
}

function* logoutUser() {
  try {
    yield call(authService.logout);
    yield put(authActions.logoutUserSuccess());
  } catch (error) {
    console.log(error);
  }
}

function* loadUserRoles() {
  try {
    const roles = yield call(getUserRoles);
    yield put(authActions.getRolesSuccess(roles));
  } catch (error) {
    console.log(error);
    yield put(authActions.getRolesFailure(error));
  }
}

function* loadUserDetails() {
  try {
    const userDetails = yield call(getUserDetails);
    yield put(authActions.loadUserDetailsSuccess(userDetails));
  } catch (error) {
    yield put(authActions.loadUserDetailsFailure(error));
  }
}

export function* loginFlow() {
  while (true) {
    yield take(types.LOGIN_USER);
    const winner = yield race({
      auth: call(loginUser),
      logout: take(types.LOGOUT_USER),
    });
    if (winner.auth) {
      yield put(authActions.loginUserSuccess(winner.auth));
    }
  }
}

export function* logoutFlow() {
  while (true) {
    yield take(types.LOGOUT_USER);
    yield call(logoutUser);
  }
}

export default function* authSaga() {
  yield fork(loginFlow);
  yield fork(logoutFlow);
  yield takeEvery(types.LOAD_USER_DETAILS, loadUserDetails);
}
