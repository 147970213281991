import React, { Component } from "react";
import { Router, Redirect, Route, Switch } from "react-router-dom";
import Navbar from "./Navbar/Navbar";
import Sidebar from "./Sidebar/Sidebar";
import GeneralSettings from "../GeneralSettings/GeneralSettings";
import About from "../About/About";
import OsmDownloadContainer from "../Tools/OsmDownload/OsmDownloadContainer";
import Projects from "../Projects/Projects";
import Project from "../Project/Project";
import CaseProvider from "../Case/CaseProvider";
import CaseHome from "../Case/CaseHome";
import CaseResults from "../Case/CaseResults";
import CaseFileBrowser from "../Case/CaseFileBrowser";
import NewProjectWizard from "../Wizards/NewProjectWizard/NewProjectWizard";
import NewCaseWizard from "../Wizards/NewCaseWizard/index";
import DomainProvider from "../Domain/DomainProvider";
import Setup from "../Setup/Setup";
import ThermalComfort from "../ThermalComfort/ThermalComfort";
import WindComfort from "../WindComfort/WindComfort";
import Chemistry from "../Chemistry/Chemistry";
import Expert from "../Expert/Expert";
import Simulation from "../Simulation/Simulation";
import LegalNotice from "../LegalNotice/LegalNotice";
import Help from "../Help/Help";
import Admin from "../Admin/Admin";
import Files from "../ProjectFiles/Files";
import history from "../../redux/sagas/history";
import { withTranslation } from "react-i18next";
import { routes } from "../../routes/routes";
import "../../utils/translations/i18n";
import "./index.scss";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = { isSidebarVisible: false };
    this.toggleSidebarVisibility = this.toggleSidebarVisibility.bind(this);
    this.hideSidebar = this.hideSidebar.bind(this);
  }

  hideSidebar() {
    if (this.state.isSidebarVisible) {
      this.setState({ isSidebarVisible: false });
    }
  }

  toggleSidebarVisibility() {
    this.setState((prevState) => ({
      isSidebarVisible: !prevState.isSidebarVisible,
    }));
  }

  render() {
    return (
      <Router history={history}>
        <div className="all-layout">
          <CaseProvider />
          <Navbar toggleSidebarVisibility={this.toggleSidebarVisibility} />
          <div className="main-layout">
            <Switch>
              <Route exact path="/" component={Projects} />
              <Route
                exact
                path={routes.createProject}
                component={NewProjectWizard}
              />
              <Route exact path={routes.createCase} component={NewCaseWizard} />
              <Route exact path={routes.settings} component={GeneralSettings} />
              <Route
                exact
                path={routes.osmDownload}
                component={OsmDownloadContainer}
              />
              <Route exact path={routes.legalNotice} component={LegalNotice} />
              <Route exact path={routes.help} component={Help} />
              <Route exact path={routes.about} component={About} />
              <Route exact path={routes.projectHome} component={Project} />
              <Route exact path={routes.projectFiles} component={Files} />
              <Route exact path={routes.caseHome} component={CaseHome} />
              <Route
                exact
                path={routes.caseFileBrowser}
                component={CaseFileBrowser}
              />
              <Route exact path={routes.domain} component={DomainProvider} />
              <Route exact path={routes.caseSetup} component={Setup} />
              <Route
                exact
                path={routes.thermalComfort}
                component={ThermalComfort}
              />
              <Route exact path={routes.windComfort} component={WindComfort} />
              <Route exact path={routes.chemistry} component={Chemistry} />
              <Route exact path={routes.expert} component={Expert} />
              {this.props.roles &&
                Array.isArray(this.props.roles) &&
                this.props.roles?.includes("IsAdmin") && (
                  <Route exact path={routes.admin} component={Admin} />
                )}
              {/* {this.props.roles?.includes("IsSimulationUser") && ( */}
              <Route
                exact
                path={routes.caseSimulation}
                component={Simulation}
              />
              {/* )} */}
              {
                // this.props.roles?.includes("IsSimulationUser") && (
                <Route
                  exact
                  path={routes.caseResults}
                  component={CaseResults}
                />
              }
              <Route path="*">
                <Redirect to="/" />
              </Route>
            </Switch>
          </div>
          <Sidebar
            isSidebarVisible={this.state.isSidebarVisible}
            hideSidebar={this.hideSidebar}
          />
        </div>
      </Router>
    );
  }
}

export default withTranslation()(Layout);
