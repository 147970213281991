import React from "react";
import LoginWrapper from "./LoginWrapper/LoginWrapper";
import BurgerIcon from "./burger-rectangle.svg";
import "./Navbar.scss";

function Navbar(props) {
  return (
    <div className="navbar-wrapper">
      <div className="logo-wrapper">
        <button
          className="burger-nav-btn"
          onClick={props.toggleSidebarVisibility}
        >
          <img className={`burger-nav`} src={BurgerIcon} alt="logo"></img>
        </button>
      </div>
      <LoginWrapper />
    </div>
  );
}

export default Navbar;
