import React from "react";
import Icon from "../../../Icon";
import "./index.scss";

const IconCellRenderer = (props) => {
  props.reactContainer.classList.add("table-icon__container");
  return (
    <div
      className="table-icon__container"
      onClick={() => props.onClick(props.data)}
    >
      <Icon className="table-icon" icon={props.icon} />
    </div>
  );
};

export default IconCellRenderer;
