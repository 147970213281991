import React from "react";
import Map from "../../../Components/Map/Map";
import MapPanel from "../../../Components/Map/MapPanel/MapPanel";
import LegendWrapper from "../../../Components/Map/Legend/LegendWrapper";
import { connect } from "react-redux";

class MapContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lat: 48.15,
      lng: 11.58,
      zoom: 12,
    };
  }

  render() {
    return (
      <>
        <Map center={[this.state.lat, this.state.lng]} zoom={this.state.zoom} />
        {this.props.geoJsonWg && <MapPanel />}
        <LegendWrapper />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    geoJsonWg: state.map.geoJsonWg,
  };
};

export default connect(mapStateToProps, null)(MapContainer);
