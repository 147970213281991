import React from "react";
import "./Page.scss";

function Page(props) {
  return (
    <div className={`page${props.className ? ` ${props.className}` : ""}`}>
      {props.children}
    </div>
  );
}

export default Page;

function PageHeader(props) {
  return (
    <div className="page-hdr-wrapper">
      <h3 className="page-hdr">{props.text}</h3>
    </div>
  );
}

export { PageHeader };
