import React from "react";
import {
  ResultsTextInput,
  ResultsLayerInfo,
} from "../../../../../../Components/Map/Form/Select";
import { ChromePicker } from "react-color";
import {
  changeWindDirColor,
  changeWindDirSize,
  changeWindDirOpacity,
} from "../../../../../../redux/actions/resultsActions";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import "../../../../../../Components/Common/PaletteDropdown/index.scss";

class WindDirLayer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isColorPicker: false,
      color: "#32a852",
    };

    this.openColorPicker = this.openColorPicker.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChangeComplete = this.handleChangeComplete.bind(this);
  }

  openColorPicker() {
    this.setState({ isColorPicker: true });
  }

  handleClose() {
    this.setState({ isColorPicker: false });
  }

  handleChangeComplete(e) {
    this.setState({ color: e });
    this.props.changeWindDirColor(e.hex);
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <ResultsTextInput
          label={t("LayerName")}
          value={t(this.props.jsonOriginPartSelected.name)}
          visSettings
          disabled
        />
        <hr
          className="raster-settings-ruler"
          style={{ width: "300px", marginBottom: "0px" }}
        />
        <h5
          className="raster-settings-header"
          style={{ marginBottom: "16px", marginTop: "14px" }}
        >{`${t("Statistics")}`}</h5>
        <ResultsLayerInfo
          label={t("Minimum")}
          value={this.props.jsonOriginPartSelected.stat_min}
          style={{ borderRadius: "2px 2px 0px 0px" }}
          visSettings
        />
        <ResultsLayerInfo
          label={t("25%Quantile")}
          value={this.props.jsonOriginPartSelected.stat_q25}
          visSettings
        />
        <ResultsLayerInfo
          label={t("Median")}
          value={this.props.jsonOriginPartSelected.stat_median}
          visSettings
        />
        <ResultsLayerInfo
          label={t("Mean")}
          value={this.props.jsonOriginPartSelected.stat_mean}
          visSettings
        />
        <ResultsLayerInfo
          label={t("75%Quantile")}
          value={this.props.jsonOriginPartSelected.stat_q75}
          visSettings
        />
        <ResultsLayerInfo
          label={t("Maximum")}
          value={this.props.jsonOriginPartSelected.stat_max}
          style={{ borderRadius: "0px 0px 2px 2px" }}
          visSettings
        />
        <hr
          className="raster-settings-ruler"
          style={{ width: "300px", marginBottom: "8px" }}
        />
        <h5
          className="raster-settings-header"
          style={{ marginBottom: "10px", marginTop: "6px" }}
        >
          {t("WindDirection")}
        </h5>
        <ResultsTextInput
          label={t("WindDirSize")}
          value={
            this.props.jsonOriginPartSelected.plot_arr_size
              ? this.props.jsonOriginPartSelected.plot_arr_size
              : 20
          }
          //disabled={this.props.inReadOnlyLayerForm}
          onChange={(e) => this.props.changeWindDirSize(e.target.value)}
          visSettings
        />
        <ResultsTextInput
          label={t("WindDirOpacity")}
          value={this.props.jsonOriginPartSelected.plot_opacity}
          //disabled={this.props.inReadOnlyLayerForm}
          onChange={(e) => this.props.changeWindDirOpacity(e.target.value)}
          visSettings
        />

        <div
          className={`form-group form-results-group`}
          style={{
            justifyContent: "space-between",
          }}
        >
          <label
            key={"color_wind_dir"}
            style={{ width: "auto" }}
            className={`form-label form-results-label`}
          >
            {t("Color")}
          </label>
          <div
            onClick={() => this.openColorPicker()}
            style={{
              width: "250px",
              height: "60%",
              backgroundColor: `${this.props.jsonOriginPartSelected.plot_color}`,
              borderRadius: "2px",
              cursor: "pointer",
              border: "1px solid #999999",
            }}
          />
        </div>
        {this.state.isColorPicker && (
          <div
            style={{
              position: "fixed",
              zIndex: "2",
              // top: `${
              //   420 +
              //   this.state.colorPickerIndex * 42 -
              //   this.state.colorPickerIndex
              // }px`,
              right: "110px",
            }}
            onDoubleClick={this.handleClose}
          >
            <div style={cover} onClick={this.handleClose} />
            <ChromePicker
              color={{
                hex: this.props.jsonOriginPartSelected.plot_color,
              }}
              onChange={(e) => this.handleChangeComplete(e)}
            />
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    jsonOriginPartSelected: state.results.jsonOriginPartSelected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeWindDirColor: (payload) => dispatch(changeWindDirColor(payload)),
    changeWindDirSize: (payload) => dispatch(changeWindDirSize(payload)),
    changeWindDirOpacity: (payload) => dispatch(changeWindDirOpacity(payload)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(WindDirLayer)
);

const cover = {
  position: "fixed",
  top: "0px",
  right: "0px",
  bottom: "0px",
  left: "0px",
};
