import React from "react";
import CrsSelect from "./CrsSelect";
import { Loader } from "../../../Components/Loader/index";
import {
  Edit,
  XCircle,
  Globe,
  Download,
  AlertCircle,
  CheckCircle,
} from "react-feather";
import { postCustomTask } from "../../../api/caseApi";
import { getDownloadLink } from "../../../api/storageApi";
import { getProjectByIdAsync } from "../../../api/projectsApi";
import { loadProjects } from "../../../redux/actions/projectsActions";
import {
  toggleDrawExtent,
  toggleObjectToRequest,
  deleteArea,
} from "../../../redux/actions/toolsActions";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

class BasicOsmDownloadSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isProcessing: false,
      isReady: false,
      isError: false,
      status: null,
      noCaseError: false,
    };

    this.processOsmData = this.processOsmData.bind(this);
    this.downloadFile = this.downloadFile.bind(this);
  }

  componentDidMount() {
    if (this.props.projects?.length === 0) {
      this.props.loadProjects();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.osmNotification) {
      if (
        this.props.osmNotification?.status === 2 &&
        prevProps.osmNotification?.status !== 2
      ) {
        this.setState({
          isReady: true,
          isProcessing: false,
          isError: false,
        });
      } else if (
        prevProps.osmNotification?.status !== 3 &&
        this.props.osmNotification?.status === 3
      ) {
        this.setState({ isError: true, isProcessing: false });
      }
    }
  }
  async downloadFile() {
    const url = await getDownloadLink(
      this.props.osmNotification.response.resultUrl
    );
    const link = document.createElement("a");
    link.download = "download";
    link.href = url;
    link.click();
  }

  async processOsmData() {
    let caseId = null;

    if (this.props.projects.length === 0) {
      this.setState({ noCaseError: true });
      return;
    } else {
      for (let index = 0; index < this.props.projects.length; index++) {
        const projectId = this.props.projects[index].id;
        const projectDetails = await getProjectByIdAsync(projectId);
        if (
          projectDetails.caseDetailsDto &&
          projectDetails.caseDetailsDto.length > 0
        ) {
          caseId = projectDetails.caseDetailsDto[0].id;
          index = this.props.projects.length;
        }
      }
    }
    if (!caseId) {
      this.setState({ noCaseError: true });
      return;
    }
    this.setState({
      isProcessing: true,
      isReady: false,
      isError: false,
      noCaseError: false,
    });
    const request = {
      caseId: caseId,
      type: "custom-tasks",
      request: {
        extent: this.props.requestExtentCoords,
        crs: this.props.osmCrs,
        guiversion: "PALMGUIv20230112",
        objects: this.props.objectsToRequest,
        bldMethod: this.props.bldMethod + 1,
        bldStorey: this.props.bldStorey,
        bldFillVal: this.props.bldFillVal,
        trMethod: this.props.trMethod + 1,
        trFillValPatches: this.props.trFillValPatches,
        trFillValSingle: this.props.trFillValSingle,
        rwMethod: this.props.rwMethod + 1,
        rwLanes: this.props.rwLanes,
      },
    };
    console.log(JSON.stringify(request, null, 2));
    await postCustomTask(request);
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <h3
          className="raster-settings-header"
          style={{ marginBottom: "24px", marginTop: "12px", fontSize: 19 }}
        >{`${t("OsmDownload")}`}</h3>
        {this.props.isExtentDrawn && !this.props.isCrsValid && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <p
              style={{
                padding: "0px",
                color: "rgb(194, 78, 78)",
                fontSize: "12px",
                fontStyle: "italic",
                margin: "0px",
                paddingLeft: "8px",
                marginBottom: "10px",
              }}
              className="status-dscr"
            >
              {t("OsmCrsWarning")}
            </p>
          </div>
        )}
        {this.props.isExtentDrawn &&
          !this.props.isAreaValid &&
          this.props.isCrsValid && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "14px",
              }}
            >
              <p
                style={{
                  padding: "0px",
                  color: "rgb(194, 78, 78)",
                  fontSize: "12px",
                  fontStyle: "italic",
                  margin: "0px",
                  paddingLeft: "8px",
                }}
                className="status-dscr"
              >
                {t("OsmAreaTooBig")} {this.props.area} km².
              </p>
            </div>
          )}
        <div
          className={"form-group"}
          style={{
            height: "auto",
            marginTop: "3px",
            marginBottom: "3px",
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <label
            style={{
              width: "160px",
              fontSize: "11.7px",
              color: "rgb(78, 78, 78)",
              fontFamily: "Segoe UI",
              fontWeight: 600,
            }}
          >{`${t("DefineUtmCrs")}`}</label>
          <CrsSelect />
        </div>
        <div
          className={`raster-settings-btn-wrapper`}
          style={{ marginBottom: "6px", marginTop: "12px" }}
        >
          <button
            className={`raster-settings-btn ${
              this.props.inDrawExtentMode ? `raster-settings-btn-active` : ""
            }`}
            style={{
              marginTop: "8px",
              borderRadius: "2px",
              fontSize: "11px",
              padding: "5px 10px 5px 10px",
            }}
            onClick={this.props.toggleDrawExtent}
            disabled={this.state.isProcessing}
          >
            <div className="btn-content-wrapper">
              <Edit color={"#494949"} size={"12px"} strokeWidth={"1.8px"} />
              <div className="raster-btn-lbl">{t("DrawExtent")}</div>
            </div>
          </button>
          <button
            className="raster-settings-btn"
            style={{
              marginTop: "8px",
              borderRadius: "2px",
              fontSize: "11px",
              padding: "5px 10px 5px 10px",
            }}
            onClick={this.props.deleteArea}
            disabled={this.state.isProcessing}
          >
            <div className="btn-content-wrapper">
              <XCircle color={"#494949"} size={"13px"} strokeWidth={"1.8px"} />
              <div className="raster-btn-lbl">{t("DeleteExtent")}</div>
            </div>
          </button>
        </div>
        <hr
          className="raster-settings-ruler"
          style={{ width: "300px", marginBottom: "5px" }}
        />
        <label
          style={{
            width: "100%",
            fontSize: "11.7px",
            fontWeight: "600",
            marginTop: "18px",
            fontFamily: "Segoe UI",
            color: "rgb(104, 104, 104)",
            marginBottom: "6px",
          }}
        >
          {t("ObjectToBeRequested")}
        </label>
        {this.props.objectsToRequest.map((x, i) => {
          return (
            <label
              className="legend-row-container legend-wrapper"
              style={{ marginTop: "6px", marginBottom: "6px" }}
            >
              <input
                className="raster-checkbox"
                name={"invert-color"}
                type="checkbox"
                checked={x.value}
                onChange={() => {
                  this.props.toggleObjectToRequest(i);
                }}
              />
              <label
                style={{ width: "auto", marginLeft: "12px" }}
                className="raster-object-name layer-object-name"
              >
                {t(x.name)}
              </label>
            </label>
          );
        })}
        {this.props.objectsToRequest.every((x) => x.value === false) && (
          <p
            style={{
              padding: "0px",
              color: "#f14949",
              fontSize: "12px",
              fontStyle: "italic",
              margin: "0px",
              marginTop: "12px",
            }}
            className="status-dscr"
          >
            {t("SelectLeastOneObject")}
          </p>
        )}
        <hr className="raster-settings-ruler" style={{ marginBottom: "5px" }} />
        <p
          style={{
            padding: "0px",
            color: "gray",
            textAlign: "left",
            fontSize: "12px",
            fontStyle: "italic",
          }}
          className="status-dscr"
        >
          {t("OsmLimitsInfo")}
        </p>
        <hr
          className="raster-settings-ruler"
          style={{ marginBottom: "20px", marginTop: "10px" }}
        />
        {this.state.isProcessing && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "left",
                marginLeft: -63,
                marginTop: -25,
              }}
            >
              <Loader size="small-osm" />
            </div>
            <p style={{ fontSize: 11, cursor: "default", color: "gray" }}>
              {t("Processing")}...
            </p>
          </>
        )}

        {!this.state.isProcessing && this.state.isReady && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: -16,
            }}
          >
            <CheckCircle size="12px" color="rgb(6, 137, 108)" />
            <p className={`new-layer-name-error new-layer-success`}>
              GeoJSON has been created
            </p>
          </div>
        )}

        {!this.state.isProcessing && this.state.isError && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: -18,
            }}
          >
            <AlertCircle size="12px" color="#ac4141" />
            <p className={`new-layer-name-error`}>
              While processing data error has occured. Please try again
            </p>
          </div>
        )}
        {this.state.noCaseError && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: -18,
            }}
          >
            <AlertCircle size="12px" color="#ac4141" />
            <p className={`new-layer-name-error`}>
              In order to download OSM data please create project first
            </p>
          </div>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-around",
          }}
        >
          <button
            className="raster-settings-btn"
            onClick={this.processOsmData}
            style={{
              padding: "5px 10px 5px 10px",
              marginTop: "5px",
              fontSize: "11px",
            }}
            disabled={
              !this.props.isExtentDrawn ||
              !this.props.isAreaValid ||
              this.state.isProcessing
            }
          >
            <div className={`btn-content-wrapper `}>
              <Globe color={"#494949"} size={"12px"} strokeWidth={"2px"} />
              <div className="raster-btn-lbl">{t("ProcessOsmData")}</div>
            </div>
          </button>
          <button
            className="raster-settings-btn"
            onClick={this.downloadFile}
            disabled={!this.state.isReady || this.state.isProcessing}
            style={{
              padding: "5px 10px 5px 10px",
              marginTop: "5px",
              fontSize: "11px",
            }}
            title={this.props.isExtentDrawn ? "" : t("OsmDisabledInfo")}
          >
            <div className={`btn-content-wrapper `}>
              <Download color={"#494949"} size={"12px"} strokeWidth={"2px"} />
              <div className="raster-btn-lbl">{t("DownloadMap")}</div>
            </div>
          </button>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    inOsmDownloadMapView: state.tools.inOsmDownloadMapView,
    inDrawExtentMode: state.tools.inDrawExtentMode,
    objectsToRequest: state.tools.objectsToRequest,
    bldMethod: state.tools.bldMethod,
    bldStorey: state.tools.bldStorey,
    bldFillVal: state.tools.bldFillVal,
    trMethod: state.tools.trMethod,
    trFillValPatches: state.tools.trFillValPatches,
    trFillValSingle: state.tools.trFillValSingle,
    rwMethod: state.tools.rwMethod,
    rwLanes: state.tools.rwLanes,
    isExtentDrawn: state.tools.isExtentDrawn,
    isAreaValid: state.tools.isAreaValid,
    area: state.tools.area,
    osmCrs: state.tools.osmCrs,
    isCrsValid: state.tools.isCrsValid,
    requestExtentCoords: state.tools.requestExtentCoords,
    osmNotification: state.tools.osmNotification,
    projects: state.projects?.projects,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleDrawExtent: () => dispatch(toggleDrawExtent()),
    deleteArea: () => dispatch(deleteArea()),
    loadProjects: () => dispatch(loadProjects()),
    toggleObjectToRequest: (payload) =>
      dispatch(toggleObjectToRequest(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(BasicOsmDownloadSettings)));
