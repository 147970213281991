import { types } from "../types/notifications";

export function addNotification(payload) {
  return {
    type: types.ADD_NOTIFICATION,
    payload: payload,
  };
}
export function addCreationNotification(payload) {
  return {
    type: types.CREATE_NOTIFICATION,
    payload: payload,
  };
}
export function addSteeringNotification(payload) {
  return {
    type: types.ADD_STEERING_NOTIFICATION,
    payload: payload,
  };
}

export function dismissNotification(notificationId) {
  return {
    type: types.DISMISS_NOTIFICATION,
    payload: notificationId,
  };
}

export function ackNotifications(notificationIds) {
  return {
    type: types.ACK_NOTIFICATIONS,
    payload: notificationIds,
  };
}

export function addSimulationNotification(payload) {
  return {
    type: types.ADD_SIMULATION_NOTIFICATION,
    payload: payload,
  };
}

export function addPostprocessNotification(payload) {
  return {
    type: types.ADD_POSTPROCESS_NOTIFICATION,
    payload: payload,
  };
}

export function clearNotifications() {
  return {
    type: types.CLEAR_NOTIFICATIONS,
  };
}
