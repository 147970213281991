import { call, put, takeEvery } from "redux-saga/effects";
import * as projectsApi from "../../api/projectsApi";
import { uploadBlobToAzure } from "../../api/storageApi";
import * as filesActions from "../actions/projectFilesActions";
import { types } from "../types/projectFiles";

function* saveFile(action) {
  try {
    const path = `${action.payload.projectId}/${action.payload.file.name}`;
    yield call(uploadBlobToAzure, path, action.payload.file);

    const request = {
      name: action.payload.file.name,
      type: action.payload.file.type,
      path,
    };
    const response = yield call(
      projectsApi.addProjectFiles,
      action.payload.projectId,
      [request]
    );
    yield put(
      filesActions.updateFileSuccess(action.payload.projectId, response.data)
    );
  } catch (error) {
    yield put(
      filesActions.updateFileFailure(
        action.payload.projectId,
        action.payload.file,
        error
      )
    );
  }
}

function* deleteFile(action) {
  try {
    yield call(projectsApi.deleteProjectFiles, action.payload.projectId, [
      action.payload.fileName,
    ]);
    yield put(
      filesActions.deleteFileSuccess(action.payload.projectId, [
        action.payload.fileName,
      ])
    );
  } catch (error) {
    yield put(
      filesActions.deleteFileFailure(
        action.payload.projectId,
        [action.payload.fileName],
        error
      )
    );
  }
}

export default function* projectFilesSaga() {
  yield takeEvery(types.UPDATE_FILE, saveFile);
  yield takeEvery(types.DELETE_FILE, deleteFile);
}
