import React from "react";
import { Trash } from "react-feather";
import "./FileBrowser.scss";

class FileBrowser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTrashVisible: false,
    };
    this.showTrash = this.showTrash.bind(this);
    this.hideTrash = this.hideTrash.bind(this);
  }

  showTrash() {
    this.setState({ isTrashVisible: true });
  }

  hideTrash() {
    this.setState({ isTrashVisible: false });
  }

  componentDidUpdate(prevProps) {
    if (this.props.fileName !== prevProps.fileName) {
      this.setState({ isTrashVisible: false });
    }
  }

  render() {
    return (
      <div
        className={`file-browser ${
          this.props.setup ? "custom-setup-file-upload" : ""
        }`}
        style={
          this.props.expResultsStyle
            ? { width: "180px", height: "28px" }
            : this.props.resultsStyle
            ? { width: "250px", height: "28px" }
            : this.props.projectFiles
            ? { width: "450px", height: "30px" }
            : {}
        }
      >
        <label
          className={`custom-file-upload${
            this.props.disabled ? " custom-file-upload__disabled" : ""
          } `}
          style={
            this.props.expResultsStyle
              ? { width: "70px", fontSize: "10px" }
              : this.props.resultsStyle
              ? { width: "70px", height: "28px" }
              : {}
          }
        >
          <input
            type="file"
            onChange={this.props.onChange}
            disabled={this.props.disabled}
            accept={this.props.accept}
            onClick={(e) => (e.target.value = null)}
          />
          {this.props.label}
        </label>
        <div
          className={`file-preview ${
            this.props.setup ? "custom-setup-file-upload" : ""
          }`}
          style={
            this.props.resultsStyle
              ? { width: "180px" }
              : this.props.projectFiles
              ? { width: "350px", lineHeight: "4px" }
              : {}
          }
        >
          {this.props.fileName}
          {this.props.isClearable && this.props.fileName && (
            <span
              onClick={() => this.props.clearUploadedFile(this.props.name)}
              style={{ cursor: "pointer", height: "auto" }}
              onMouseEnter={this.showTrash}
              onMouseLeave={this.hideTrash}
            >
              <Trash
                width="16"
                stroke={
                  this.state.isTrashVisible
                    ? `rgba(191, 40, 40, 1)`
                    : `rgba(178, 186, 187)`
                }
                strokeWidth="2"
              />
            </span>
          )}
        </div>
      </div>
    );
  }
}

export default FileBrowser;
