import React from "react";
import { useTranslation } from "react-i18next";

export const SimulationNotification = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="notification__title">{t("Simulation")}</div>
      <div className="notification__body">
        {props.notification.projectName} ({props.notification.caseName}):{" "}
        {t(props.notification.body.response.status)}
      </div>
    </>
  );
};
