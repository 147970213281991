import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Edit2, X } from "react-feather";
import CircleButton from "../Button/Circle/CircleButton";
import "./index.scss";

export const EditableImage = (props) => {
  const fileBrowser = useRef(null);
  const { t } = useTranslation();
  const onIconClick = () => {
    fileBrowser.current.click();
  };
  const onImageChange = (e) => {
    if (!e.target.files || !e.target.files.length) {
      return;
    }
    props.onChange(e.target.files[0]);
  };
  const onImageClear = (e) => {
    props.onChange(null);
    fileBrowser.current.value = null;
  };
  return (
    <div className="editable-image__container">
      {props.imageUrl ? (
        <img className="editable-image" src={props.imageUrl} alt="avatar"></img>
      ) : (
        <Placeholder text={t("UploadPhoto")} />
      )}
      <div className="button-container">
        <CircleButton icon={Edit2} onClick={onIconClick}>
          <input
            ref={fileBrowser}
            hidden
            type="file"
            id="upload-image"
            accept="image/png, image/jpeg"
            onChange={onImageChange}
          />
        </CircleButton>
        <CircleButton
          disabled={!props.imageUrl}
          className="clear-image"
          icon={X}
          onClick={onImageClear}
        ></CircleButton>
      </div>
    </div>
  );
};

const Placeholder = ({ text }) => {
  return (
    <div className="placeholder">
      <span className="placeholder-text">{text}</span>
    </div>
  );
};

export default EditableImage;
