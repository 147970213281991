import React from "react";
import "./Form.scss";

const CheckBox = (props) => {
  return (
    <div className="form-group">
      <label className="form-checkbox-label">{props.label}</label>
      <input
        type="checkbox"
        className="form-input form-checkbox-input"
        checked={props.checked}
        onChange={props.onChange}
        name={props.name}
        disabled={props.disabled}
        id={props.id} />
    </div>
  );
};
export { CheckBox };
