import { types } from "../types/tools";

export function toggleTools() {
  return {
    type: types.TOGGLE_TOOLS,
  };
}
export function toggleDrawExtent() {
  return {
    type: types.TOGGLE_DRAW_EXTENT,
  };
}

export function deleteArea() {
  return {
    type: types.DELETE_AREA,
  };
}
export function setOsmCrs(payload) {
  return {
    type: types.SET_OSM_CRS,
    payload,
  };
}
export function toggleObjectToRequest(payload) {
  return {
    type: types.TOGGLE_OBJECT_TO_REQUEST,
    payload,
  };
}
export function setBldgMethod(payload) {
  return {
    type: types.SET_BLDG_METHOD,
    payload,
  };
}

export function setBldgStoreyHeight(payload) {
  return {
    type: types.SET_BLDG_STOREY_HEIGHT,
    payload,
  };
}

export function setBldgFillValue(payload) {
  return {
    type: types.SET_BLDG_FILL_VALUE,
    payload,
  };
}

export function setTreeMethod(payload) {
  return {
    type: types.SET_TREE_METHOD,
    payload,
  };
}
export function setTreePatchMissValue(payload) {
  return {
    type: types.SET_TREE_PATCH_MISS_VALUE,
    payload,
  };
}
export function setTreeMissValue(payload) {
  return {
    type: types.SET_TREE_MISS_VALUE,
    payload,
  };
}
export function setRoadWidthMethod(payload) {
  return {
    type: types.SET_ROAD_WIDTH_METHOD,
    payload,
  };
}
export function setRoadWidthLanes(payload) {
  return {
    type: types.SET_ROAD_WIDTH_LANES,
    payload,
  };
}
export function startArea(payload) {
  return {
    type: types.START_AREA,
    payload,
  };
}
export function getOsmCursorCoords(payload) {
  return {
    type: types.GET_OSM_CURSOR_COORDS,
    payload,
  };
}
export function finishArea(payload) {
  return {
    type: types.FINISH_AREA,
    payload,
  };
}
export function addOsmNotification(payload) {
  return {
    type: types.ADD_OSM_NOTIFICATION,
    payload,
  };
}
