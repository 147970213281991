import { createSelector } from "reselect";

export const selectNotifications = createSelector(
  (state) => state.notifications.notifications,
  (state) => state.projects.loadedProject,
  (notifications, loadedProject) => {
    return notifications
      .map((notification) => {
        if (notification.type !== "simulation") {
          return notification;
        }

        const simCase = loadedProject?.caseDetailsDto?.find(
          (c) => c.id === notification?.body?.response?.caseId
        );
        if (simCase) {
          notification.projectName = loadedProject?.name;
          notification.caseName = simCase.name;
          return notification;
        }
        return null;
      })
      .filter((n) => !!n);
  }
);