import React from "react";
import CopyPropsForm from "./CopyPropsForm.js/CopyPropsForm";
import { Box } from "../../../Components/Map/Form/Box";
import { Select } from "../../../Components/Map/Form/Select";
import { IdLine } from "../../../Components/Map/Form/IdLine";
import { NumericInput } from "../../../Components/Map/Form/NumericInput";
import AccordionSection from "../../../Components/Common/AccordionSection";
import { buildingGroups } from "../../../JSON/buildingGroups";
import { getCurrentProperties } from "../geo-selectors";
import { objectTypeIndex } from "../../../Components/Map/consts/consts";
import {
  allObjectTypes,
  singleTreeObjectType,
  streetObjectType,
  pointEmissionObjectType,
  pointOfInterestObjectType,
} from "../../../redux/reducers/mapReducer";
import {
  changeObjectType,
  changeObjectSubtype,
  changeSelectedTreeSubtype,
  goToTreeCoordinates,
  setObjectSubtypeIndex,
  changeSelectedDrawnPolygonSubtype,
  changeSelectedDrawnPolygonType,
  updateBuildingHeight,
  updateTreeHeight,
  updatePolygonData,
  updateTempInput,
  toggleCopyPropsMode,
  toggleSingleTreeCopyPropsMode,
  goToMapPoiCoordinates,
  goToEmissionCoordinates,
  changeSelectedDrawnEmissionSubtype,
  changeSelectedGeoJsonEmissionSubtype,
  toggleEmissionCopyPropsMode,
} from "../../../redux/actions/mapActions";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class FormContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      tempValue: "",
      tempKey: "",
    };
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleSubtypeChange = this.handleSubtypeChange.bind(this);
    this.handleSwitchChange = this.handleSwitchChange.bind(this);
    this.handleCopyPropsModeChange = this.handleCopyPropsModeChange.bind(this);
  }

  handleSwitchChange() {
    this.setState({ checked: !this.state.checked });
  }

  handleTypeChange(e) {
    const objectTypeIndex = e.target.selectedIndex + 1;

    if (this.props.selectedMapItemGeoProps.id <= 0) {
      this.props.changeSelectedDrawnPolygonType({
        id: this.props.selectedMapItemGeoProps.id,
        objectTypeIndex: objectTypeIndex,
      });
    } else {
      this.props.changeObjectType({
        objectId: this.props.selectedMapItemGeoProps.id,
        objectTypeIndex: objectTypeIndex,
      });
    }
  }

  handleSubtypeChange(e) {
    const subtypeIndex = e.target.selectedIndex + 1;

    if (
      this.props.selectedMapItemGeoProps.id > 0 &&
      this.props.selectedMapItemGeoProps.t !== 8
    ) {
      this.props.changeObjectSubtype({
        objectId: this.props.selectedMapItemGeoProps.id,
        subtypeIndex: subtypeIndex,
      });
    } else if (
      this.props.selectedMapItemGeoProps.id > 0 &&
      this.props.selectedMapItemGeoProps.t === 8
    ) {
      this.props.changeSelectedGeoJsonEmissionSubtype({
        objectId: this.props.selectedMapItemGeoProps.id,
        subtypeIndex: subtypeIndex,
      });
    } else if (
      this.props.selectedMapItemGeoProps.id > -1000 ||
      this.props.selectedMapItemGeoProps.id <= -4000
    ) {
      this.props.changeSelectedDrawnPolygonSubtype({
        id: this.props.selectedMapItemGeoProps.id,
        objectTypeIndex: this.props.selectedMapItemGeoProps.t,
        subtypeIndex: subtypeIndex,
      });
    } else if (this.props.selectedMapItemGeoProps.id > -2000) {
      this.props.changeSelectedTreeSubtype({
        id: this.props.selectedMapItemGeoProps.id,
        subtypeIndex: subtypeIndex,
      });
    } else if (this.props.selectedMapItemGeoProps.id > -3000) {
      this.props.changeSelectedDrawnEmissionSubtype({
        id: this.props.selectedMapItemGeoProps.id,
        subtypeIndex: subtypeIndex,
      });
    }
  }

  handleInputChange = (e) => {
    const reg = new RegExp(/^(?!0\d)\d*(\.\d+)?$/);
    const inputValue = e.target.value;

    if (reg.test(inputValue) && this.props.tempValue !== "") {
      if (e.target.id === "bh" && inputValue > 0) {
        this.props.updateBuildingHeight(inputValue);
      } else if (e.target.id === "th" && inputValue > 0) {
        this.props.updateTreeHeight(inputValue);
      } else if (e.target.id !== "th" && e.target.id !== "bh") {
        this.props.updatePolygonData({
          key: e.target.id,
          value: inputValue,
        });
      }
    } else if (
      e.target.id === "maxcool" &&
      !isNaN(inputValue) &&
      inputValue !== ""
    ) {
      this.props.updatePolygonData({
        key: e.target.id,
        value: inputValue,
      });
    } else if (e.target.id === "name") {
      this.props.updatePolygonData({
        key: e.target.id,
        value: inputValue,
      });
    }

    this.props.updateTempInput({ tempKey: "", tempValue: "" });
  };

  handleLiveInput = (e) => {
    const reg = new RegExp(/^[+-]?\d*(?:[.,]\d*)?$/);
    if (document.getElementById(e.target.id) !== document.activeElement) {
      document.getElementById(e.target.id).focus();
    }
    if (
      (reg.test(e.target.value) &&
        Number(e.target.max) >= Number(e.target.value)) ||
      e.target.name === "name"
    ) {
      this.props.updateTempInput({
        tempKey: e.target.name,
        tempValue: e.target.value,
      });
    }
  };

  handleCopyPropsModeChange(e) {
    e.preventDefault();
    this.props.toggleCopyPropsMode();
  }

  render() {
    const { t } = this.props;
    const currentProperties = getCurrentProperties(
      this.props.selectedMapItemGeoProps,
      this.props.polygonData,
      this.state.checked
    );
    return (
      <div className={"form"}>
        {!this.props.inCopyPropsMode &&
        !this.props.inSingleTreeCopyPropsMode &&
        !this.props.inEmissionCopyPropsMode ? (
          <>
            <IdLine
              labelId={`ID: ${this.props.selectedMapItemGeoProps.id}`}
              labelSwitch={t("PropertyDetails")}
              onChange={this.handleSwitchChange}
              checked={this.state.checked}
              switchActiveColor={this.props.selectedMapItemGeoProps.t}
              singleTreeActive={
                this.props.selectedMapItemGeoProps.id < 0 &&
                this.props.selectedMapItemGeoProps.t ===
                  objectTypeIndex.singleTree
              }
              emissionActive={
                this.props.selectedMapItemGeoProps.id < 0 &&
                this.props.selectedMapItemGeoProps.t ===
                  objectTypeIndex.emission
              }
              mapPoiActive={
                this.props.selectedMapItemGeoProps.id < 0 &&
                this.props.selectedMapItemGeoProps.t ===
                  objectTypeIndex.mapPointOfInterest
              }
              onTreeCoordIconClick={this.props.goToTreeCoordinates}
              onEmissionIconClick={this.props.goToEmissionCoordinates}
              onMapPoiIconClick={this.props.goToMapPoiCoordinates}
              toggleCopyPropsMode={
                this.props.currentObjectType === "single tree"
                  ? this.props.toggleSingleTreeCopyPropsMode
                  : this.props.currentObjectType === "point_emission"
                  ? this.props.toggleEmissionCopyPropsMode
                  : this.props.toggleCopyPropsMode
              }
            />
            <Box objectType={this.props.currentObjectType} />
            <Select
              label={t(allObjectTypes.fieldName)}
              value={this.props.currentObjectType}
              options={
                this.props.selectedMapItemGeoProps.t ===
                objectTypeIndex.singleTree
                  ? singleTreeObjectType.value.map((x) => {
                      return { label: t(x), value: x };
                    })
                  : this.props.selectedMapItemGeoProps.t ===
                    objectTypeIndex.street
                  ? streetObjectType.value.map((x) => {
                      return { label: t(x), value: x };
                    })
                  : this.props.selectedMapItemGeoProps.t ===
                    objectTypeIndex.emission
                  ? pointEmissionObjectType.value.map((x) => {
                      return { label: t(x), value: x };
                    })
                  : this.props.selectedMapItemGeoProps.t ===
                    objectTypeIndex.mapPointOfInterest
                  ? pointOfInterestObjectType.value.map((x) => {
                      return { label: t(x), value: x };
                    })
                  : allObjectTypes.value.map((x) => {
                      return { label: t(x), value: x };
                    })
              }
              onChange={this.handleTypeChange}
              help={
                this.props.currentObjectType === "building"
                  ? "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/urban_surface_parameters#building_type"
                  : this.props.currentObjectType === "vegetation"
                  ? "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/land_surface_parameters#vegetation_type"
                  : this.props.currentObjectType === "pavement"
                  ? "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/land_surface_parameters#pavement_type"
                  : this.props.currentObjectType === "water"
                  ? "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/land_surface_parameters#water_type"
                  : this.props.currentObjectType === "tree patches" ||
                    this.props.currentObjectType === "single tree"
                  ? "https://palm_gui.pages.fraunhofer.de/palmgui_handbuch/rasterization.html#d-b%C3%A4ume-tree-patches-und-single-trees"
                  : this.props.currentObjectType === "street"
                  ? "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/chememi"
                  : null
              }
            />
            <Select
              label={t(this.props.allObjectSubtypes.fieldName)}
              value={this.props.currentObjectSubtype}
              options={this.props.allObjectSubtypes.value.map((x) => {
                return { label: t(x), value: x };
              })}
              onChange={this.handleSubtypeChange}
              help={
                this.props.currentObjectType === "building"
                  ? "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/urban_surface_parameters#building_type"
                  : this.props.currentObjectType === "vegetation"
                  ? "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/land_surface_parameters#vegetation_type"
                  : this.props.currentObjectType === "pavement"
                  ? "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/land_surface_parameters#pavement_type"
                  : this.props.currentObjectType === "water"
                  ? "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/land_surface_parameters#water_type"
                  : this.props.currentObjectType === "tree patches" ||
                    this.props.currentObjectType === "single tree"
                  ? "https://palm_gui.pages.fraunhofer.de/palmgui_handbuch/rasterization.html#d-b%C3%A4ume-tree-patches-und-single-trees"
                  : this.props.currentObjectType === "street"
                  ? "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/chememi"
                  : null
              }
            />

            {this.props.selectedMapItemGeoProps.t === 1 &&
            this.state.checked ? (
              <div className="building-accordion-wrapper">
                {buildingGroups.map((y, i) => {
                  return (
                    <AccordionSection title={t(y)} buildingGroup>
                      {currentProperties
                        .filter((z) => {
                          return z.bg === i + 2;
                        })
                        .map((x) => {
                          return (
                            <NumericInput
                              key={x.key}
                              id={x.n}
                              name={x.n}
                              label={t(x.n)}
                              value={
                                x.key === this.props.tempKey
                                  ? this.props.tempValue
                                  : !!x.value || x.value === 0
                                  ? x.value
                                  : x.defaultValue
                              }
                              help={x.l}
                              min={x.cl}
                              max={x.cm}
                              step={x.s}
                              unit={x.unit}
                              maxLength={x.md}
                              onChange={(e) => this.handleLiveInput(e)}
                              onBlur={(e) => this.handleInputChange(e)}
                            />
                          );
                        })}
                    </AccordionSection>
                  );
                })}
              </div>
            ) : (
              currentProperties.map((element) => {
                return (
                  <NumericInput
                    key={element.key}
                    id={element.n}
                    name={element.n}
                    label={t(element.n)}
                    value={
                      element.key === this.props.tempKey
                        ? this.props.tempValue
                        : !!element.value || element.value === 0
                        ? element.value
                        : element.defaultValue
                    }
                    help={element.l}
                    min={element.cl}
                    max={element.cm}
                    step={element.s}
                    unit={element.unit}
                    type={element.t === 2 ? "text" : "number"}
                    disabled={element.n === "emis_type_street" ? true : false}
                    maxLength={element.md}
                    onChange={(e) => this.handleLiveInput(e)}
                    onBlur={(e) => this.handleInputChange(e)}
                  />
                );
              })
            )}
          </>
        ) : (
          <CopyPropsForm />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedMapItemGeoProps: state.map.selectedMapItemGeoProps,
    polygonData: state.map.polygonData,
    currentObjectType: state.map.currentObjectType,
    currentObjectSubtype: state.map.currentObjectSubtype,
    allObjectSubtypes: state.map.allObjectSubtypes,
    tempKey: state.map.tempKey,
    tempValue: state.map.tempValue,
    inCopyPropsMode: state.map.inCopyPropsMode,
    inSingleTreeCopyPropsMode: state.map.inSingleTreeCopyPropsMode,
    inEmissionCopyPropsMode: state.map.inEmissionCopyPropsMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeObjectType: (payload) => dispatch(changeObjectType(payload)),
    changeObjectSubtype: (payload) => dispatch(changeObjectSubtype(payload)),
    changeSelectedTreeSubtype: (payload) =>
      dispatch(changeSelectedTreeSubtype(payload)),
    goToTreeCoordinates: () => dispatch(goToTreeCoordinates()),
    setObjectSubtypeIndex: (payload) =>
      dispatch(setObjectSubtypeIndex(payload)),
    changeSelectedDrawnPolygonSubtype: (payload) =>
      dispatch(changeSelectedDrawnPolygonSubtype(payload)),
    changeSelectedDrawnPolygonType: (payload) =>
      dispatch(changeSelectedDrawnPolygonType(payload)),
    updateBuildingHeight: (payload) => dispatch(updateBuildingHeight(payload)),
    updateTreeHeight: (payload) => dispatch(updateTreeHeight(payload)),
    updatePolygonData: (payload) => dispatch(updatePolygonData(payload)),
    updateTempInput: (payload) => dispatch(updateTempInput(payload)),
    toggleCopyPropsMode: () => dispatch(toggleCopyPropsMode()),
    toggleSingleTreeCopyPropsMode: () =>
      dispatch(toggleSingleTreeCopyPropsMode()),
    goToEmissionCoordinates: () => dispatch(goToEmissionCoordinates()),
    goToMapPoiCoordinates: () => dispatch(goToMapPoiCoordinates()),
    changeSelectedDrawnEmissionSubtype: (payload) =>
      dispatch(changeSelectedDrawnEmissionSubtype(payload)),
    changeSelectedGeoJsonEmissionSubtype: (payload) =>
      dispatch(changeSelectedGeoJsonEmissionSubtype(payload)),
    toggleEmissionCopyPropsMode: () => dispatch(toggleEmissionCopyPropsMode()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  pure: false,
})(withTranslation()(FormContainer));
