import React from "react";
import Summary from "./Summary/Summary";
import SingleDomain from "./Grid/SingleDomain";
import NestedDomain from "./Grid/NestedDomain";
import RuntimeTemplate from "./RuntimeInitialization/RuntimeTemplate";
import ConstantProfiles from "./RuntimeInitialization/ConstantProfiles";
import DynamicUpload from "./RuntimeInitialization/DynamicUpload";
import DynamicWindProfile from "./RuntimeInitialization/DynamicWindProfile";
import Biometerology from "./Modules/Biometeorology";
import IndoorClimate from "./Modules/IndoorClimate";
import LandSurface from "./Modules/LandSurface";
import PlantCanopy from "./Modules/PlantCanopy";
import Radiation from "./Modules/Radiation";
import SyntheticTurbulence from "./Modules/SyntheticTurbulence";
import UrbanSurface from "./Modules/UrbanSurface";
import CrossSection from "./Output/CrossSection";
import TerrainFollowingMask from "./Output/TerrainFollowingMask";
import Volume from "./Output/Volume";
import PoiVerticalProfile from "./Output/PoiVerticalProfile";
import PoiTimeseries from "./Output/PoiTimeseries";
import GeneralSettings from "./Output/GeneralSettings";
import { useSelector } from "react-redux";

const ExpertPageContainer = (props) => {
  const currentExpertSection = useSelector(
    (state) => state.expert.currentExpertSection
  );
  const currentExpertPage = useSelector(
    (state) => state.expert.currentExpertPage
  );

  return (
    <>
      <h2>
        {currentExpertPage
          ? `${currentExpertSection} : ${currentExpertPage}`
          : "Summary"}
      </h2>
      <div className="wind-wrapper">
        {currentExpertSection === "Summary" ? (
          <Summary />
        ) : currentExpertPage === "Single Domain" ? (
          <SingleDomain />
        ) : currentExpertPage === "Nested Domain" ? (
          <NestedDomain />
        ) : currentExpertPage === "Runtime Template" ? (
          <RuntimeTemplate />
        ) : currentExpertPage === "Constant Profiles" ? (
          <ConstantProfiles />
        ) : currentExpertPage === "Dynamic Upload" ? (
          <DynamicUpload />
        ) : currentExpertPage === "Dynamic Wind Profile" ? (
          <DynamicWindProfile />
        ) : currentExpertPage === "Biometerology" ? (
          <Biometerology rowData={props.rowData.biometeorologyRowData} />
        ) : currentExpertPage === "Indoor Climate" ? (
          <IndoorClimate rowData={props.rowData.indoorClimateRowData} />
        ) : currentExpertPage === "Plant Canopy" ? (
          <PlantCanopy rowData={props.rowData.plantCanopyRowData} />
        ) : currentExpertPage === "Synthetic Turbulence" ? (
          <SyntheticTurbulence />
        ) : currentExpertPage === "Radiation" ? (
          <Radiation rowData={props.rowData.radiationRowData} />
        ) : currentExpertPage === "Urban Surface" ? (
          <UrbanSurface />
        ) : currentExpertPage === "Terrain Following Mask" ? (
          <TerrainFollowingMask />
        ) : currentExpertPage === "2D Cross Section (xy)" ? (
          <CrossSection />
        ) : currentExpertPage === "Land Surface" ? (
          <LandSurface rowData={props.rowData.landSurfaceRowData} />
        ) : currentExpertPage === "3D Volume" ? (
          <Volume />
        ) : currentExpertPage === "POI:Vertical Profile Chart" ? (
          <PoiVerticalProfile />
        ) : currentExpertPage === "POI:Timeseries" ? (
          <PoiTimeseries />
        ) : currentExpertPage === "General Settings" ? (
          <GeneralSettings rowData={props.rowData.generalRowData} />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default ExpertPageContainer;
