import React from "react";
import { Polygon } from "react-leaflet";
import { selectPolygon } from "../../../redux/actions/mapActions";
import { getPolygonColor } from "../../../utils/polygons/polygonColor";
import { connect } from "react-redux";

class DrawnPolygon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        {this.props.allDrawnPolygonsPointsWg.length !== 0 && (
          <>
            {this.props.allDrawnPolygonsPointsWg.map((x, idx) => (
              <Polygon
                zIndex={200000000}
                className="raster-area"
                positions={x}
                onclick={() => {
                  if (
                    this.props.inStreetDrawingMode &&
                    this.props.allDrawnPolygonGeoProps[idx]?.t === 7
                  ) {
                    this.props.selectPolygon(idx);
                  } else if (
                    !this.props.inStreetDrawingMode &&
                    this.props.allDrawnPolygonGeoProps[idx]?.t !== 7
                  ) {
                    this.props.selectPolygon(idx);
                  }
                }}
                fillColor={getPolygonColor(
                  this.props.allDrawnPolygonGeoProps[idx]?.t,
                  this.props.inputPalmTypesPalette
                )}
                fillOpacity={0.6}
                color={"#3388ff"}
              />
            ))}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allDrawnPolygonsPointsWg: state.map.allDrawnPolygonsPointsWg,
    inStreetDrawingMode: state.map.inStreetDrawingMode,
    allDrawnPolygonGeoProps: state.map.allDrawnPolygonGeoProps,
    inputPalmTypesPalette:
      state.userSettings?.settings?.visualizationPresets
        ?.inputPalmTypesPalette || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectPolygon: (idx) => dispatch(selectPolygon(idx)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DrawnPolygon);
