import { types } from "../types/cases";
import { types as notificationTypes } from "../types/notifications";

const initialState = {
  createdCaseId: null,
  isSaving: false,
  isCreating: false,
  isUpdating: false,
  updateError: null,
  topoConversionInProgress: false,
  topoConversionResultUrl: null,
  geojsonCheckInProgress: false,
  geojsonCheckResultUrl: null,
  geojsonUploadInProgress: false,
  topographyUploadInProgress: false,
  uploadedTopographyUrl: null,
  uploadedGeojsonUrl: null,
  topographyReady: false,
  geojsonReady: false,
  errors: [],
};

const createCaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE_CASE_RESET:
      return {
        ...initialState,
      };
    case types.INIT_CREATE_CASE:
      return {
        ...initialState,
        isCreating: true,
        topographyReady: action.payload.geoTiff ? false : true,
        geojsonReady: action.payload.geojson ? false : true,
      };
    case types.CREATE_CASE:
      return {
        ...state,
        isSaving: true,
      };
    case types.CLONE_CASE:
      return {
        ...state,
        isSaving: true,
      };
    case types.CREATE_CASE_SUCCESS:
      return {
        ...initialState,
        createdCaseId: action.payload || null,
      };
    case types.CREATE_CASE_FAILURE:
      return {
        ...state,
        isSaving: false,
        isCreating: false,
        errors: [...state.errors, action.payload],
      };
    case types.INIT_UPDATE_CASE:
      return {
        ...initialState,
        isUpdating: true,
        topographyReady: action.payload.geoTiff ? false : true,
        geojsonReady: action.payload.geojson ? false : true,
      };
    case types.UPDATE_CASE_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        topoConversionInProgress: false,
        topoConversionResultUrl: null,
        geojsonCheckInProgress: false,
        geojsonCheckResultUrl: null,
        geojsonUploadInProgress: false,
        topographyUploadInProgress: false,
        topographyReady: false,
        geojsonReady: false,
        errors: [],
      };
    case types.UPDATE_CASE_FAILURE:
      return {
        ...state,
        isUpdating: false,
        errors: [...state.errors, action.payload],
      };
    case types.CASE_UPLOAD_TOPOGRAPHY:
      return {
        ...state,
        topographyUploadInProgress: true,
        topographyReady: false,
      };
    case types.CASE_UPLOAD_TOPOGRAPHY_SUCCESS:
      return {
        ...state,
        topographyUploadInProgress: false,
        uploadedTopographyUrl: action.payload,
      };
    case types.CASE_UPLOAD_GEOJSON:
      return {
        ...state,
        geojsonUploadInProgress: true,
        geojsonReady: false,
      };
    case types.CASE_CHECK_GEOJSON:
      return {
        ...state,
        geojsonReady: false,
      };
    case types.CASE_CONVERT_TOPOGRAPHY:
      return {
        ...state,
        topographyReady: false,
      };
    case types.CASE_UPLOAD_GEOJSON_SUCCESS:
      return {
        ...state,
        geojsonUploadInProgress: false,
        uploadedGeojsonUrl: action.payload,
      };
    case types.SET_CASE_CHECK_GEOJSON_REQUEST:
      return {
        ...state,
        geojsonCheckRequestId: action.payload,
        geojsonCheckInProgress: true,
      };
    case types.SET_CASE_CONVERT_TOPOGRAPHY_REQUEST:
      return {
        ...state,
        topoConversionRequestId: action.payload,
        topoConversionInProgress: true,
      };
    case notificationTypes.CREATE_NOTIFICATION:
      const { resultUrl, type, status, error } = action.payload;
      if (type === "geojsonCheck") {
        if (status === 2) {
          return {
            ...state,
            geojsonCheckInProgress: false,
            geojsonCheckResultUrl: resultUrl,
            geojsonReady: true,
          };
        }
        if (status === 3) {
          return {
            ...state,
            isUpdating: false,
            geojsonCheckInProgress: false,
            uploadedGeojsonUrl: null,
            errors: [...state.errors, error],
            isCreating: false,
          };
        }
      }
      if (type === "TopographyConversionNotificationReceived") {
        if (status === 2) {
          return {
            ...state,
            topoConversionInProgress: false,
            topoConversionResultUrl: resultUrl,
            topographyReady: true,
          };
        }
        if (status === 3) {
          return {
            ...state,
            isUpdating: false,
            topoConversionInProgress: false,
            uploadedTopographyUrl: null,
            errors: [...state.errors, error],
            isCreating: false,
          };
        }
      }
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default createCaseReducer;
