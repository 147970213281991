import { apiRequest } from "./axiosApi";

const apiUrl = process.env.REACT_APP_FRONT_SERVICE_API;

export async function geojsonCheckAsync(geojsonUrl) {
  await apiRequest({
    method: "post",
    url: `${apiUrl}/check/geojson`,
    data: {
      geojsonUrl,
    },
  });
}

export async function topographyConversionAsync(topographyUrl) {
  await apiRequest({
    method: "post",
    url: `${apiUrl}/check/topography-conversion`,
    data: { topographyUrl },
  });
}
