import React from "react";
export const BadgeIndicator = (props) => {
  const count = props.count > 5 ? "5+" : props.count;
  if (count) {
    return (
      <span className={`badge-indicator badge-indicator__new`}>{count}</span>
    );
  }
  return <span className={`badge-indicator`}></span>;
};
