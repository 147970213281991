import React from "react";
import Sidebar from "../../../Components/Map/Sidebar/Sidebar";
import FormContainer from "../FormContainer/FormContainer";
import PolygonEditor from "../../../Components/Map/PolygonEditor/PolygonEditor";
import JsonStatusPanel, {
  ResultMapViewerStatus,
} from "../../../Components/Map/JsonStatusPanel/JsonStatusPanel";
import RasterizationSettings from "../../../Components/Map/RasterizationSettings/RasterizationSettings";
import TreeEditor from "../../../Components/Map/TreeEditor/TreeEditor";
import EmissionEditor from "../../../Components/Map/Emission/EmissionEditor/EmissionEditor";
import MapPoiEditor from "../../../Components/Map/MapPoi/MapPoiEditor/MapPoiEditor";
import LayerSettings from "../../Results/StaticDriver/LayerSettings/LayerSettings";
import GeodataDownload from "../../Results/StaticDriver/GeodataDownload/GeodataDownload";
import ExportSettings from "../../Results/ExportSettings/ExportSettings";
import PoiEditor from "../../Results/StaticDriver/PoiEditor/PoiEditor";
import AoiEditor from "../../Results/StaticDriver/AoiEditor/AoiEditor";
import TimeseriesChart from "../../Results/Charts/TimeseriesChart";
import VerticalChart from "../../Results/Charts/VerticalChart";
import OsmDownloadSettings from "../../Tools/OsmDownload/OsmDownloadSettings";
import { connect } from "react-redux";

class SidebarContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSidebarVisible: true,
    };
    this.handleSidebarButtonClick = this.handleSidebarButtonClick.bind(this);
  }

  handleSidebarButtonClick() {
    this.setState({
      isSidebarVisible: !this.state.isSidebarVisible,
    });
  }

  render() {
    return (
      <>
        {!this.props.inResultsMapView && !this.props.inOsmDownloadMapView ? (
          <Sidebar
            isSidebarVisible={this.state.isSidebarVisible}
            handleSidebarButtonClick={this.handleSidebarButtonClick}
            inPolygonDrawingMode={this.props.inPolygonDrawingMode}
            inTreeDrawingMode={this.props.inTreeDrawingMode}
          >
            {((!this.props.selectedMapItemGeoProps &&
              !this.props.selectedDrawnPolygonGeoProps &&
              !this.props.inDrawingMode &&
              !this.props.inRasterSettingsMode) ||
              (this.props.inDrawingMode &&
                !this.props.inPolygonDrawingMode &&
                !this.props.inTreeDrawingMode &&
                !this.props.inEmissionDrawingMode &&
                !this.props.inMapPoiDrawingMode)) && <JsonStatusPanel />}

            {this.props.inTreeDrawingMode && <TreeEditor />}

            {this.props.inPolygonDrawingMode && <PolygonEditor />}

            {this.props.inEmissionDrawingMode && <EmissionEditor />}

            {this.props.inMapPoiDrawingMode && <MapPoiEditor />}

            {this.props.inRasterSettingsMode && <RasterizationSettings />}
            {!this.props.inRasterSettingsMode &&
              !this.props.inDrawingMode &&
              this.props.selectedMapItemGeoProps && <FormContainer />}
          </Sidebar>
        ) : this.props.inResultsMapView ? (
          <Sidebar
            inResultsMap={this.props.inResultsMapView}
            isSidebarVisible={this.state.isSidebarVisible}
            handleSidebarButtonClick={this.handleSidebarButtonClick}
          >
            {this.props.inLayerSettings ? (
              <LayerSettings />
            ) : this.props.inGeodataDownload ? (
              <GeodataDownload />
            ) : this.props.inPoi ? (
              <PoiEditor />
            ) : this.props.inAoi ? (
              <AoiEditor />
            ) : this.props.inExportSettings ? (
              <ExportSettings />
            ) : this.props.inTimeChart ? (
              <TimeseriesChart />
            ) : this.props.inVerticalChart ? (
              <VerticalChart />
            ) : (
              <ResultMapViewerStatus />
            )}
          </Sidebar>
        ) : (
          <Sidebar
            isSidebarVisible={this.state.isSidebarVisible}
            handleSidebarButtonClick={this.handleSidebarButtonClick}
          >
            <OsmDownloadSettings />
          </Sidebar>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    inDrawingMode: state.map.inDrawingMode,
    inTreeDrawingMode: state.map.inTreeDrawingMode,
    inPolygonDrawingMode: state.map.inPolygonDrawingMode,
    inRasterSettingsMode: state.map.inRasterSettingsMode,
    selectedMapItemGeoProps: state.map.selectedMapItemGeoProps,
    selectedDrawnPolygonGeoProps: state.map.selectedDrawnPolygonGeoProps,
    inResultsMapView: state.results.inResultsMapView,
    inLayerSettings: state.results.inLayerSettings,
    inGeodataDownload: state.results.inGeodataDownload,
    inExportSettings: state.results.inExportSettings,
    inPoi: state.results.inPoi,
    inAoi: state.results.inAoi,
    inEmissionDrawingMode: state.map.inEmissionDrawingMode,
    inMapPoiDrawingMode: state.map.inMapPoiDrawingMode,
    inOsmDownloadMapView: state.tools.inOsmDownloadMapView,
    inTimeChart: state.results.inTimeChart,
    inVerticalChart: state.results.inVerticalChart,
  };
};

export default connect(mapStateToProps, null)(SidebarContainer);
