import React from "react";
import ReactDOM from "react-dom";
import ProjectSidebar from "../../../Components/Sidebar/Project/ProjectSidebar";
import SidebarItem from "../../../Components/Sidebar/SidebarItem";
import AccordionMenuSection from "../../../Components/Common/AccordionMenuSection/AccordionMenuSection";
import {
  HelpCircle,
  Settings,
  List,
  Clipboard,
  Info,
  Search,
} from "react-feather";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import "./Sidebar.scss";

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutsideSidebar, true);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutsideSidebar, true);
  }

  handleClickOutsideSidebar = (event) => {
    const domNode = ReactDOM.findDOMNode(this);
    if (
      (!domNode || !domNode.contains(event.target)) &&
      event.target.tagName !== "IMG" &&
      event.target.alt !== "logo"
    ) {
      this.props.hideSidebar();
    }
  };

  render() {
    const { t } = this.props;
    return (
      <div
        className={`sidebar-wrapper ${
          this.props.isSidebarVisible ? "sidebar-show" : ""
        }`}
      >
        <div className="sidebar-items">
          <SidebarItem route={"/"} label={t("Start")} icon={List} />
          <SidebarItem
            route={"/settings"}
            label={t("GeneralSettings")}
            icon={Settings}
          />
          {/* <SidebarLink
            route="https://www.hereon.de/innovation_transfer/communication_media/imprint/index.php.de"
            label={t("LegalNotice")}
            icon={Clipboard}
          /> */}
          <SidebarItem
            route={"/legalNotice"}
            label={t("LegalNotice")}
            icon={Clipboard}
          />
          {this.props.roles?.includes("IsAdmin") && (
            <SidebarItem route={"/admin"} label={t("Admin")} icon={Settings} />
          )}
          <AccordionMenuSection title={t("Toolbars")}>
            <SidebarItem
              class="sidebar-item__accordion"
              label={t("OsmDownload")}
              icon={Search}
              route="/osmDownload"
            />
          </AccordionMenuSection>
          <SidebarItem route="/help" label={t("Help")} icon={HelpCircle} />
          <SidebarItem route="/about" label={t("About")} icon={Info} />
          <div className="sidebar-section__separator" />
          {this.props.project && (
            <ProjectSidebar
              project={this.props.project}
              steering={this.props.steering}
              roles={this.props.roles}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    project: state.projects.loadedProject,
    steering: state.simulationSettings.steering,
    roles: state.auth.roles,
  };
};

export default connect(mapStateToProps, null)(withTranslation()(Sidebar));
