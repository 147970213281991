import { types } from "../types/projects";

export function unloadProject() {
  return {
    type: types.UNLOAD_PROJECT,
  };
}
export function loadProject(projectId) {
  return {
    type: types.LOAD_PROJECT,
    payload: projectId,
  };
}

export function loadProjectSuccess(project) {
  return {
    type: types.LOAD_PROJECT_SUCCESS,
    payload: project,
  };
}

export function createProject(project) {
  return {
    type: types.CREATE_PROJECT,
    payload: project,
  };
}

export function createProjectSuccess(projectId) {
  return {
    type: types.CREATE_PROJECT_SUCCESS,
    payload: projectId,
  };
}

export function deleteProject(projectId) {
  return {
    type: types.DELETE_PROJECT,
    payload: projectId,
  };
}

export function deleteProjectSuccess(projectId) {
  return {
    type: types.DELETE_PROJECT_SUCCESS,
    payload: projectId,
  };
}
export function deleteProjectFailure(error) {
  return {
    type: types.DELETE_PROJECT_FAILURE,
    payload: error,
  };
}

export function loadProjects() {
  return {
    type: types.LOAD_PROJECTS,
  };
}

export function loadProjectsSuccess(projects) {
  return {
    type: types.LOAD_PROJECTS_SUCCESS,
    payload: projects,
  };
}
export function loadProjectsFailure(error) {
  return {
    type: types.LOAD_PROJECTS_FAILURE,
    payload: error,
  };
}

export function saveProject(project) {
  return {
    type: types.SAVE_PROJECT,
    payload: project,
  };
}

export function saveProjectSuccess(updatedProject) {
  return {
    type: types.SAVE_PROJECT_SUCCESS,
    payload: updatedProject,
  };
}

export function saveProjectFailure(error) {
  return {
    type: types.SAVE_PROJECT_FAILURE,
    payload: error,
  };
}

export function setCurrentCase(payload) {
  return {
    type: types.SET_CURRENT_CASE,
    payload,
  };
}
export function setCurrentCaseSuccess(payload) {
  return {
    type: types.SET_CURRENT_CASE_SUCCESS,
    payload,
  };
}
