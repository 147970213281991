import React from "react";
import MapContainer from "./MapContainer/MapContainer";
import SidebarContainer from "./SidebarContainer/SidebarContainer";
import { Loader } from "../../Components/Loader";
import { allCrsOptions } from "../Tools/OsmDownload/osmConst";
import { downloadBlobAsync } from "../../api/storageApi";
import { utm31Def, utm32Def, utm33Def, wgDef } from "../../common/utmDef";
import {
  loadNestedAreaProperties,
  loadRasterAreaProperties,
  loadRasterAreaPreview,
  loadNestedAreaPreview,
  resetRasterAreaProperties,
} from "../../redux/actions/rasterAreaActions";
import {
  setGeoJsonWg,
  setPolygonData,
  setDrawnGeoJson,
  resetPolygonEditorData,
  resetTreeDrawingData,
  resetAllMapData,
} from "../../redux/actions/mapActions";
import gp from "geojson-precision";
import { reproject } from "reproject";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import "./Domain.scss";

class Domain extends React.Component {
  constructor(props) {
    super(props);

    this.initMap = this.initMap.bind(this);
  }

  async componentDidMount() {
    await this.initMap();
  }

  async componentDidUpdate(prevProps) {
    if (
      prevProps.currentCase !== this.props.currentCase &&
      prevProps.currentCase.rasterPreviewJob ===
        this.props.currentCase.rasterPreviewJob &&
      prevProps.currentCase.staticDriverJob ===
        this.props.currentCase.staticDriverJob
    ) {
      await this.initMap();
    }
  }

  async initMap() {
    if (!this.props.currentCase) {
      return;
    }

    if (this.props.currentCase.type === 1) {
      this.props.history.push(
        `/projects/${this.props.loadedProject.id}/cases/${this.props.currentCase.id}`
      );
      return;
    }

    const geoJsonUrl = this.props.currentCase.verifiedGeojsonUrl;
    const polygonDataUrl = this.props.currentCase.polygonDataUrl;
    const drawnPolygonGeoJsonUrl = this.props.currentCase.drawnGeojsonUrl;
    let crsDef = null;
    if (!!geoJsonUrl) {
      const geoJsonResponse = await downloadBlobAsync(geoJsonUrl);
      const geoJsonUtm = geoJsonResponse.data;
      const crs = geoJsonUtm.crs.properties.name.split(":").pop();
      
      if (crs === "25831") {
        crsDef = utm31Def;
      } else if (crs === "25832") {
        crsDef = utm32Def;
      } else if (crs === "25833") {
        crsDef = utm33Def;
      } else {
        crsDef = allCrsOptions.find((x) => x.value === `EPSG:${crs}`).def;
      }

      const geoJsonWgConv = reproject(geoJsonUtm, crsDef, wgDef);
      const geoJsonWg = gp.parse(geoJsonWgConv, 6);
      this.props.setGeoJsonWg({
        geoJson: geoJsonWg,
        crs: crs,
        applicationField: this.props.currentCase.applicationField,
      });
    } else if (!this.props.currentCase.verifiedGeojsonUrl) {
      const geoJsonUtmTemplate = getGeoJsonUtmTemplate(
        this.props.loadedProject.epsg
      );
      this.props.setGeoJsonWg({
        geoJson: geoJsonUtmTemplate,
        crs: this.props.loadedProject.epsg,
        applicationField: this.props.currentCase.applicationField,
      });
    } else {
      const verifiedGeojsonUrl = this.props.currentCase.verifiedGeojsonUrl;
      const verifiedGeojsonResponse = await downloadBlobAsync(
        verifiedGeojsonUrl
      );
      const verifiedGeoJson = verifiedGeojsonResponse.data;
      const crs = verifiedGeoJson.crs.properties.name.split(":").pop();

      if (crs === "25831") {
        crsDef = utm31Def;
      } else if (crs === "25832") {
        crsDef = utm32Def;
      } else if (crs === "25833") {
        crsDef = utm33Def;
      } else {
        crsDef = allCrsOptions.find((x) => x.value === `EPSG:${crs}`).def;
      }

      const verifiedGeoJsonWgConv = reproject(verifiedGeoJson, crsDef, wgDef);
      const verifiedGeoJsonWg = gp.parse(verifiedGeoJsonWgConv, 6);

      this.props.setGeoJsonWg({
        geoJson: verifiedGeoJsonWg,
        crs: crs,
        applicationField: this.props.currentCase.applicationField,
      });
    }

    if (!!polygonDataUrl) {
      const polygonDataResponse = await downloadBlobAsync(polygonDataUrl);
      const polygonData = polygonDataResponse.data;
      this.props.setPolygonData(polygonData);
    }

    if (!!drawnPolygonGeoJsonUrl) {
      const drawnPolygonGeoJsonResponse = await downloadBlobAsync(
        drawnPolygonGeoJsonUrl
      );
      const drawnPolygonGeoJson = drawnPolygonGeoJsonResponse.data;
      this.props.setDrawnGeoJson(drawnPolygonGeoJson);
    } else {
      this.props.resetPolygonEditorData();
      this.props.resetTreeDrawingData();
    }

    if (!!this.props.currentCase.rasterParameters) {
      const epsg = this.props.loadedProject.epsg;
      if (epsg === "25831") {
        crsDef = utm31Def;
      } else if (epsg === "25832") {
        crsDef = utm32Def;
      } else if (epsg === "25833") {
        crsDef = utm33Def;
      } else {
        crsDef = allCrsOptions.find((x) => x.value === `EPSG:${epsg}`).def;
      }

      this.props.loadRasterAreaProperties({
        crsDef: crsDef,
      });
    }

    if (!!this.props.rasterCase?.rasterPreviewJob) {
      const rasterPreviewAreaData = this.props.rasterCase?.rasterPreviewJob;
      if (
        rasterPreviewAreaData &&
        !rasterPreviewAreaData.childResultUrl &&
        rasterPreviewAreaData.resultUrl
      ) {
        const rasterPreview = await downloadBlobAsync(
          rasterPreviewAreaData.resultUrl
        );
        this.props.loadRasterAreaPreview({
          rasterPreview: rasterPreview.data,
          crsDef: crsDef,
        });
      } else if (
        rasterPreviewAreaData &&
        rasterPreviewAreaData.childResultUrl
      ) {
        const parentRasterPreview = await downloadBlobAsync(
          rasterPreviewAreaData.resultUrl
        );

        const childRasterPreview = await downloadBlobAsync(
          rasterPreviewAreaData.childResultUrl
        );
        this.props.loadNestedAreaPreview({
          crsDef: crsDef,
          parentRaster: {
            parentRasterPreview: parentRasterPreview.data,
          },
          childRaster: {
            childRasterPreview: childRasterPreview.data,
          },
        });
        return;
      }
    }
    if (
      !this.props.rasterCase?.rasterPreviewJob &&
      !this.props.rasterCase?.rasterParameters &&
      !this.props.rasterCase?.childRasterParameters
    ) {
      this.props.resetRasterAreaProperties();
    }
  }

  componentWillUnmount() {
    this.props.resetAllMapData();
  }

  render() {
    if (!this.props.loadedProject || !this.props.currentCase) {
      return <Loader />;
    }
    return (
      <div className="main">
        <MapContainer />
        {this.props.geoJsonWg && <SidebarContainer />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    geoJsonWg: state.map.geoJsonWg,
    loadedProject: state.projects.loadedProject,
    currentCase: state.projects.currentCase,
    rasterCase: state.rasterArea.currentCase,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetPolygonEditorData: () => dispatch(resetPolygonEditorData()),
    loadRasterAreaProperties: (payload) =>
      dispatch(loadRasterAreaProperties(payload)),
    loadNestedAreaProperties: (payload) =>
      dispatch(loadNestedAreaProperties(payload)),
    loadRasterAreaPreview: (payload) =>
      dispatch(loadRasterAreaPreview(payload)),
    loadNestedAreaPreview: (payload) =>
      dispatch(loadNestedAreaPreview(payload)),
    resetRasterAreaProperties: () => dispatch(resetRasterAreaProperties()),
    resetTreeDrawingData: () => dispatch(resetTreeDrawingData()),
    setGeoJsonWg: (payload) => dispatch(setGeoJsonWg(payload)),
    setPolygonData: (payload) => dispatch(setPolygonData(payload)),
    setDrawnGeoJson: (payload) => dispatch(setDrawnGeoJson(payload)),
    resetAllMapData: () => dispatch(resetAllMapData()),
  };
};

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Domain))
);

function getGeoJsonUtmTemplate(epsg) {
  return {
    type: "FeatureCollection",
    name: `urn:ogc:def:crs:EPSG::${epsg}`,
    crs: {
      type: "name",
      properties: {
        name: `urn:ogc:def:crs:EPSG::${epsg}`,
      },
    },
    features: [],
  };
}
