import React, { useState, useEffect } from "react";
import {
  ChevronRight,
  ChevronLeft,
  FileText,
  BarChart2,
  Moon,
  Sun,
  PlusSquare,
  Wind,
  Thermometer,
  User,
  Compass,
  Map,
} from "react-feather";
import { ReactComponent as City } from "./City.svg";
import { setResultsView } from "../../../redux/actions/resultsActions";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import "./Carousel.scss";

const itemWidth = 140;

const Carousel = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [xPos, setXpos] = useState(0);
  const [isRightArrowDisabled, disableRightArrow] = useState(false);
  let width = useCurrentWidth();

  const itemsAmount = useSelector(
    (state) => state.results.resultsJsonOrigin.pages.length
  );
  const currentViewIndex = useSelector(
    (state) => state.results.currentViewIndex
  );

  const resultsJsonOrigin = useSelector(
    (state) => state.results.resultsJsonOrigin
  );

  useEffect(() => {
    const carouselWidth = window.innerWidth - itemWidth;
    if (carouselWidth > itemsAmount * itemWidth) {
      disableRightArrow(true);
    }
  }, []);

  useEffect(() => {
    const carouselWidth = width - itemWidth;
    const swappedItems = Math.floor((-1 * xPos) / itemWidth);

    if ((itemsAmount - swappedItems) * itemWidth < carouselWidth) {
      disableRightArrow(true);
      return;
    }

    if (carouselWidth > itemsAmount * itemWidth) {
      disableRightArrow(true);
    } else {
      disableRightArrow(false);
    }
  }, [width]);

  const moveCarouselRight = () => {
    if (isRightArrowDisabled) {
      return;
    }

    const carouselWidth = window.innerWidth - itemWidth;
    const remainedWidthToSwap = carouselWidth + xPos;
    const visibleItems = Math.floor(carouselWidth / itemWidth);

    const hiddenItems =
      xPos !== 0 ? itemsAmount - 2 * visibleItems : itemsAmount - visibleItems;

    if (hiddenItems === 0) {
      return;
    }

    const hiddenItemPartWidth =
      1 -
      ((remainedWidthToSwap - visibleItems * itemWidth) / itemWidth).toFixed(3);
    let newXPos = null;
    const padding = 0;

    if (hiddenItems * itemWidth > remainedWidthToSwap) {
      newXPos = (
        remainedWidthToSwap -
        padding +
        hiddenItemPartWidth * itemWidth -
        itemWidth
      ).toFixed(2);
    } else {
      newXPos = hiddenItems * itemWidth;
    }

    let newXpos = -newXPos;
    setXpos(newXpos + xPos);

    const swappedItems = Math.floor((-1 * (newXpos + xPos)) / itemWidth) + 1;
    disableRightArrow((itemsAmount - swappedItems) * itemWidth < carouselWidth);
  };

  const moveCarouselLeft = () => {
    if (xPos === 0) {
      return;
    }

    const carouselWidth = window.innerWidth - itemWidth;
    const visibleItems = Math.floor(carouselWidth / itemWidth);

    if (carouselWidth > -1 * xPos) {
      setXpos(0);
    } else {
      setXpos(xPos + visibleItems * itemWidth);
    }

    const swappedItems = Math.floor((-1 * 0) / itemWidth) + 1;
    disableRightArrow((itemsAmount - swappedItems) * itemWidth < carouselWidth);
  };

  return (
    <div className="carousel-wrapper">
      <div className="carousel-container">
        <CarouselArrowLeft onClick={moveCarouselLeft} isDisabled={xPos === 0} />
        <div
          className="carousel-menu-container"
          style={{ transform: `translateX(${xPos}px)` }}
        >
          {!!resultsJsonOrigin &&
            resultsJsonOrigin.pages.map((x, i) => {
              if (currentViewIndex === i) {
                return (
                  <CarouselItem
                    label={t(x.name)}
                    icon={resultsIcon[x.icon]}
                    isActive
                  />
                );
              }
              return (
                <CarouselItem
                  label={t(x.name)}
                  icon={resultsIcon[x.icon]}
                  onClick={() => dispatch(setResultsView(i))}
                />
              );
            })}
        </div>
        <CarouselArrowRight
          onClick={moveCarouselRight}
          isDisabled={isRightArrowDisabled}
        />
      </div>
    </div>
  );
};

export default Carousel;

const CarouselItem = (props) => {
  return (
    <div
      className={`carousel-item-wrapper ${
        props.isActive ? "carousel-item-active" : ""
      }`}
      onClick={props.onClick}
    >
      <div
        className={`carousel-item-icon ${
          props.isActive ? "carousel-icon-active" : ""
        }`}
      >
        {props.icon}
      </div>
      {props.label !== "" && (
        <div className="carousel-item-lbl">{props.label}</div>
      )}
    </div>
  );
};

const CarouselArrowRight = (props) => {
  const [isHovered, toggleHover] = useState(false);
  return (
    <div
      className={`carousel-arrow-wrapper carousel-right-arrow ${
        props.isDisabled ? "arrow-wrapper-disabled" : ""
      }`}
      onClick={props.onClick}
      onMouseEnter={() => toggleHover(true)}
      onMouseLeave={() => toggleHover(false)}
    >
      <ChevronRight
        height={40}
        width={40}
        color={
          props.isDisabled
            ? "#d4d5d5"
            : isHovered
            ? "#06896c"
            : "rgba(0, 0, 0, 0.65)"
        }
      />
    </div>
  );
};

const CarouselArrowLeft = (props) => {
  const [isHovered, toggleHover] = useState(false);

  return (
    <div
      className="carousel-arrow-wrapper carousel-left-arrow"
      onClick={props.onClick}
      onMouseEnter={() => toggleHover(true)}
      onMouseLeave={() => toggleHover(false)}
    >
      <ChevronLeft
        height={40}
        width={40}
        color={
          props.isDisabled
            ? "#d4d5d5"
            : isHovered
            ? "#06896c"
            : "rgba(0, 0, 0, 0.65)"
        }
      />
    </div>
  );
};

export const resultsIcon = {
  UserMap: <City />,
  UserSplitView: <Compass />,
  UserTimeSeries: <City />,
  UserVerticalProfile: <City />,
  UserHistogram: <City />,
  UserBoxplot: <City />,
  StaticDriver: <City />,
  UserPage: <Map />,
  TCNightTemperature: (
    <>
      <Moon />
      <Thermometer />
    </>
  ),
  TCNightWind: (
    <>
      <Moon />
      <Wind />
    </>
  ),
  TCNightVolumeFlux: (
    <>
      <Moon />
      <BarChart2 />
    </>
  ),
  TCDayTemperature: (
    <>
      <Sun />
      <Thermometer />
    </>
  ),
  TCDayBiomet: (
    <>
      <Sun />
      <User />
    </>
  ),
  WCWindMap: <Wind />,
  WCWindComfortMap: (
    <>
      <Wind />
      <User />
    </>
  ),
  Report: <FileText />,
  CreatePage: <PlusSquare />,
};

const getWidth = () => window.innerWidth;

function useCurrentWidth() {
  // save current window width in the state object
  let [width, setWidth] = useState(getWidth());

  // in this case useEffect will execute only once because
  // it does not have any dependencies.
  useEffect(() => {
    // timeoutId for debounce mechanism
    let timeoutId = null;
    const resizeListener = () => {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId);
      // change width from the state object after 150 milliseconds
      timeoutId = setTimeout(() => setWidth(getWidth()), 150);
    };
    // set resize listener
    window.addEventListener("resize", resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  return width;
}
