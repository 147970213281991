import { types } from "../types/projectFiles";

const initialState = {
  filesByProject: {},
  filesBeingDeleted: [],
  deletedFiles: [],
  isUploading: false,
};

const projectFilesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_FILE: {
      return {
        ...state,
        isUploading: true,
      };
    }
    case types.UPDATE_FILE_SUCCESS:
      return {
        ...state,
        isUploading: false,
        filesByProject: {
          ...state.filesByProject,
          [action.payload.projectId]: state.filesByProject[
            action.payload.projectId
          ]
            ? [
                ...state.filesByProject[action.payload.projectId],
                ...action.payload.files,
              ]
            : [...action.payload.files],
        },
        deletedFiles: state.deletedFiles.filter(
          (n) => !action.payload.files.some((f) => f.name === n)
        ),
      };
    case types.UPDATE_FILE_FAILURE:
      return {
        ...state,
        isUploading: false,
        filesByProject: {
          ...state.filesByProject,
          [action.payload.projectId]: action.payload.files.map((f) => ({
            ...f,
            status: 1,
          })),
          error: action.payload.error,
        },
      };
    case types.DELETE_FILE:
      return {
        ...state,
        filesBeingDeleted: [
          ...state.filesBeingDeleted,
          action.payload.fileName,
        ],
      };
    case types.DELETE_FILE_SUCCESS:
      return {
        ...state,
        filesByProject: {
          ...state.filesByProject,
          [action.payload.projectId]: state.filesByProject[
            action.payload.projectId
          ]?.filter((f) => !action.payload.fileNames.includes(f.name)),
        },
        filesBeingDeleted: state.filesBeingDeleted.filter(
          (f) => !action.payload.fileNames.includes(f)
        ),
        deletedFiles: [...state.deletedFiles, ...action.payload.fileNames],
      };
    case types.DELETE_FILE_FAILURE:
      return {
        ...state,
        deleteError: action.payload.error,
        filesBeingDeleted: state.filesBeingDeleted.filter(
          (f) => !action.payload.fileNames.includes(f)
        ),
      };
    default:
      return state;
  }
};

export default projectFilesReducer;
