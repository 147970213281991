import React from "react";
import { Copy, PlusCircle } from "react-feather";
import { PageHeader } from "../../../Components/Page/Page";
import Select from "../../../Components/Common/Select/Select";
import {
  WizardButtonPrev,
  WizardButtonSubmit,
  WizardButton,
} from "../../../Components/Common/WizardButton/WizardButton";
import CrsSelect from "../../Tools/OsmDownload/CrsSelect";
import NewGeodataForm from "./NewGeodataForm";
import CloneGeodataForm from "./CloneGeodataForm";
import {
  applicationFields,
} from "../../../common/applicationFields";
import ErrorSection from "../../../Components/Common/ErrorSection/ErrorSection";
import { setProjectEpsg } from "../../../redux/actions/checkFileActions";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import "./Geodata.scss";

const geoDataTypeList = [
  { id: 0, key: "QGISUpload", enabled: () => true },
  // {
  //   id: 1,
  //   key: "StaticDriver",
  //   enabled: (applicationField) =>
  //     applicationFieldFunctionalities.staticDriverCase.includes(
  //       applicationField
  //     ),
  // },
];

class Geodata extends React.Component {
  constructor() {
    super();
    this.state = {
      geodataType: "initialGeodataType",
      geodataTypeIndex: 0,
      isGeodataValid: false,
      isGeoJsonValid: false,
      isGeoJsonSizeValid: false,
      isGeotTiffValid: false,
      geoJsonErrorMessage: "",
      geoTiffErrorMessage: "",
      cloneMode: false,
      cloneRequest: null,
    };
    this.onSelectChange = this.onSelectChange.bind(this);
    this.canSubmit = this.canSubmit.bind(this);
    this.cloneRequestChange = this.cloneRequestChange.bind(this);
    this.getCasesToClone = this.getCasesToClone.bind(this);
    this.onProjectEpsgChange = this.onProjectEpsgChange.bind(this);
  }

  async componentDidMount() {}

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.cloneMode && !this.state.cloneMode) {
      this.setState({ cloneRequest: null });
      this.cloneRequestChange(null);
    }

    if (!this.props.staticDriver && this.state.isStaticDriverValid) {
      this.setState({ isStaticDriverValid: false });
    }
    if (
      this.props.staticDriver &&
      this.props.staticDriver !== prevProps.staticDriver
    ) {
      this.setState({ isStaticDriverValid: true });
    }
  }

  onSelectChange(event) {
    const geodataType = event.target.value;
    const geodataTypeIndex = event.target.selectedIndex;

    this.setState({ geodataType, geodataTypeIndex });
    this.props.onGeodataTypeIndexChange(geodataTypeIndex);
  }

  cloneRequestChange(request) {
    this.setState({ cloneRequest: request });
    this.props.cloneRequestChange(request);
  }

  canSubmit() {
    if (this.state.cloneMode) {
      if (this.state.cloneRequest?.type === 1) {
        if (
          !this.state.cloneRequest?.copyStaticDriver &&
          !this.state.cloneRequest?.staticDriver
        ) {
          return false;
        }
      }
      return !!this.state.cloneRequest?.sourceCaseId;
    }
    if (this.state.geodataTypeIndex === 0) {
      return true;
    }
    if (this.state.geodataTypeIndex === 1) {
      return this.state.isStaticDriverValid;
    }
    return false;
  }

  getCasesToClone(t) {
    /// in order to copy case details dto has to be updated
    return (
      this.props.loadedProject?.caseDetailsDto
        ?.filter(
          (c) =>
            c.type === 0 || c.applicationField === this.props.applicationField
        )
        .map((c) => ({
          key: c,
          value: `${c.name} - ${this.props.t(
            applicationFields.find((af) => af.id === c.applicationField)?.key
          )}`,
        })) || []
    );
  }

  onProjectEpsgChange(e) {
    this.props.setProjectEpsg({ label: e.target.value, value: e.target.value });
  }

  render() {
    const { t } = this.props;
    const casesToClone = this.getCasesToClone(t);
    return (
      <div className="wizard-section wizard-section__geodata">
        <div className="geodata-header">
          <PageHeader text={t("Geodata")} />
          {this.props.loadedProject &&
          !!casesToClone.length &&
          this.state.cloneMode ? (
            <WizardButton
              className="btn-clone-toggle"
              text={t("New")}
              rightIcon={PlusCircle}
              onClick={() =>
                this.setState({ cloneMode: !this.state.cloneMode })
              }
            />
          ) : (
            this.props.loadedProject && (
              <WizardButton
                className="btn-clone-toggle"
                text={t("Clone")}
                rightIcon={Copy}
                onClick={() =>
                  this.setState({ cloneMode: !this.state.cloneMode })
                }
              />
            )
          )}
        </div>
        {this.state.cloneMode ? (
          <CloneGeodataForm
            applicationField={this.props.applicationField}
            cases={casesToClone}
            geoJson={this.props.geoJson}
            geoTiff={this.props.geoTiff}
            geojsonName={this.props.checkFile.geojsonName}
            geotiffName={this.props.checkFile.geotiffName}
            staticDriver={this.props.staticDriver}
            geojsonErrorMessage={t(this.props.checkFile.geojsonErrorMessage)}
            geotiffErrorMessage={t(this.props.checkFile.geotiffErrorMessage)}
            copyTopography={this.state.copyTopography}
            copyDomain={this.state.copyDomain}
            copyStaticDriver={this.state.copyStaticDriver}
            copySimulation={this.state.copySimulation}
            onGeoJsonChange={this.props.onGeoJsonChange}
            onGeoTiffChange={this.props.onGeoTiffChange}
            onStaticDriverChange={this.props.onStaticDriverChange}
            cloneRequestChange={this.cloneRequestChange}
            clearUploadedFile={this.props.clearUploadedFile}
          />
        ) : (
          <>
            <Select
              label={t("SelectType")}
              options={geoDataTypeList
                .filter((x) => x.enabled(this.props.applicationField))
                .map((x) => {
                  return { label: t(x.key), value: x.id };
                })}
              value={this.state.geodataType}
              onChange={this.onSelectChange}
            />
            <NewGeodataForm
              geodataType={this.state.geodataType}
              geojsonName={this.props.checkFile.geojsonName}
              geotiffName={this.props.checkFile.geotiffName}
              staticDriver={this.props.staticDriver}
              geojsonErrorMessage={t(this.props.checkFile.geojsonErrorMessage)}
              geotiffErrorMessage={t(this.props.checkFile.geotiffErrorMessage)}
              onGeoJsonChange={this.props.onGeoJsonChange}
              onGeoTiffChange={this.props.onGeoTiffChange}
              onStaticDriverChange={this.props.onStaticDriverChange}
              clearUploadedFile={this.props.clearUploadedFile}
            />
            {!this.props.loadedProject &&
              this.props.checkFile.geojsonName === "" && (
                <div
                  style={{
                    marginBottom: "36px",
                    marginTop: "16px",
                  }}
                >
                  <label className="ui-label-select">
                    {t("ChooseProjectEpsg")}
                  </label>
                  <CrsSelect menuPlacement={"top"} newProjectWizard />
                </div>
              )}
          </>
        )}
        {this.props.errors && (
          <ErrorSection label={`${t("Errors")}:`} errors={this.props.errors} />
        )}
        <div className="app-field-button-wrapper">
          <WizardButtonPrev
            text={t("Back")}
            onClick={this.props.goPreviousStep}
          />
          <WizardButtonSubmit
            text={
              this.props.loadedProject === null
                ? t(this.props.checkFile.submitButtonText)
                : t("CreateCase")
            }
            onClick={this.props.submit}
            disabled={!this.props.checkFile.canSubmit}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loadedProject: state.projects.loadedProject,
    checkFile: state.checkFile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setProjectEpsg: (payload) => dispatch(setProjectEpsg(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Geodata));
