import { all } from "redux-saga/effects";
import authSaga from "./authSaga";
import projectsSaga from "./projectsSaga";
import cases from "./caseSaga";
import settingsSaga from "./settingsSaga";
import createProjectSaga from "./createProjectSaga";
import projectFilesSaga from "./projectFilesSaga";

export default function* RootSaga() {
  yield all([authSaga(), createProjectSaga(), projectsSaga(), projectFilesSaga(), settingsSaga(), cases()]);
}
