import { types } from "../types/rasterArea";

export function setRasterCenterLatUtm(payload) {
  return {
    type: types.SET_RASTER_CENTER_LAT_UTM,
    payload,
  };
}

export function setRasterCenterLngUtm(payload) {
  return {
    type: types.SET_RASTER_CENTER_LNG_UTM,
    payload,
  };
}

export function setRasterCenterLatWg(payload) {
  return {
    type: types.SET_RASTER_CENTER_LAT_WG,
    payload,
  };
}

export function setRasterCenterLngWg(payload) {
  return {
    type: types.SET_RASTER_CENTER_LNG_WG,
    payload,
  };
}

export function setRasterGridWidth(payload) {
  return {
    type: types.SET_RASTER_GRID_WIDTH,
    payload,
  };
}

export function setRasterGridXNumber(payload) {
  return {
    type: types.SET_RASTER_GRID_X_NUMBER,
    payload,
  };
}

export function setRasterGridYNumber(payload) {
  return {
    type: types.SET_RASTER_GRID_Y_NUMBER,
    payload,
  };
}

export function toggleRasterAreaDraggable() {
  return {
    type: types.TOGGLE_RASTER_AREA_DRAGGABLE,
  };
}

export function resetRasterAreaProperties() {
  return {
    type: types.RESET_RASTER_AREA_PROPERTIES,
  };
}

export function updateRasterAreaPosition(payload) {
  return {
    type: types.UPDATE_RASTER_AREA_POSITION,
    payload,
  };
}

export function getRasterPreview(payload) {
  return {
    type: types.GET_RASTER_PREVIEW,
    payload,
  };
}

export function getNestedRasterPreview(payload) {
  return {
    type: types.GET_NESTED_RASTER_PREVIEW,
    payload,
  };
}

export function showNestedRasterArea() {
  return { type: types.SHOW_NESTED_RASTER_AREA };
}

export function toggleRasterPreviewVisibility() {
  return {
    type: types.TOGGLE_RASTER_PREVIEW_VISIBILITY,
  };
}

export function toggleGeoJsonVisibility() {
  return {
    type: types.TOGGLE_GEOJSON_VISIBILITY,
  };
}

export function toggleRasterAreaVisibility() {
  return {
    type: types.TOGGLE_RASTER_AREA_VISIBILITY,
  };
}

export function loadRasterAreaProperties(payload) {
  return {
    type: types.LOAD_RASTER_AREA_PROPERTIES,
    payload,
  };
}
export function loadRasterAreaPreview(payload) {
  return {
    type: types.LOAD_RASTER_AREA_PREVIEW,
    payload,
  };
}

export function loadNestedAreaProperties(payload) {
  return {
    type: types.LOAD_NESTED_AREA_PROPERTIES,
    payload,
  };
}

export function loadNestedAreaPreview(payload) {
  return {
    type: types.LOAD_NESTED_AREA_PREVIEW,
    payload,
  };
}

export function onSingleRasterLoadVisibility() {
  return {
    type: types.ON_SINGLE_RASTER_LOAD_VISIBILITY,
  };
}

export function onNestedRasterLoadVisibility() {
  return {
    type: types.ON_NESTED_RASTER_LOAD_VISIBILITY,
  };
}

export function setVisibilitiesOnExitMode() {
  return {
    type: types.SET_VISIBILITIES_ON_EXIT_MODE,
  };
}

export function setDomainType(payload) {
  return {
    type: types.SET_DOMAIN_TYPE,
    payload,
  };
}

export function setGridSizes(payload) {
  return {
    type: types.SET_GRID_SIZES,
    payload,
  };
}

export function setParentRasterGridXNumber(payload) {
  return {
    type: types.SET_PARENT_RASTER_GRID_X_NUMBER,
    payload,
  };
}

export function setParentRasterGridYNumber(payload) {
  return {
    type: types.SET_PARENT_RASTER_GRID_Y_NUMBER,
    payload,
  };
}

export function setChildRasterGridXNumber(payload) {
  return {
    type: types.SET_CHILD_RASTER_GRID_X_NUMBER,
    payload,
  };
}

export function setChildRasterGridYNumber(payload) {
  return {
    type: types.SET_CHILD_RASTER_GRID_Y_NUMBER,
    payload,
  };
}

export function setSoilType(payload) {
  return {
    type: types.SET_SOIL_TYPE,
    payload,
  };
}

export function setSeason(payload) {
  return {
    type: types.SET_SEASON,
    payload,
  };
}

export function setRasterMethod(payload) {
  return {
    type: types.SET_RASTER_METHOD,
    payload,
  };
}

export function setFillValue(payload) {
  return {
    type: types.SET_FILL_VALUE,
    payload,
  };
}

export function setRasterizationPriority(payload) {
  return {
    type: types.SET_RASTERIZATION_PRIORITY,
    payload,
  };
}

export function setTopographyForChildDomain(payload) {
  return {
    type: types.SET_TOPOGRAPHY_FOR_CHILD_DOMAIN,
    payload,
  };
}

export function toggleParentRasterPreviewVisibility() {
  return {
    type: types.TOGGLE_PARENT_RASTER_PREVIEW_VISIBILITY,
  };
}

export function toggleChildRasterPreviewVisibility() {
  return {
    type: types.TOGGLE_CHILD_RASTER_PREVIEW_VISIBILITY,
  };
}

export function toggleParentRasterAreaVisibility() {
  return {
    type: types.TOGGLE_PARENT_RASTER_AREA_VISIBILITY,
  };
}

export function toggleChildRasterAreaVisibility() {
  return {
    type: types.TOGGLE_CHILD_RASTER_AREA_VISIBILITY,
  };
}

export function setInitialDefaultVisibilites() {
  return {
    type: types.SET_INITIAL_DEFAULT_VISIBILITES,
  };
}

export function setFillValueBelowTreePatches(payload) {
  return {
    type: types.SET_FILL_VALUE_BELOW_TREE_PATCHES,
    payload,
  };
}

export function setSingleRasterCaseProps(payload) {
  return {
    type: types.SET_SINGLE_RASTER_CASE_PROPS,
    payload,
  };
}

export function setNestedRasterCaseProps(payload) {
  return {
    type: types.SET_NESTED_RASTER_CASE_PROPS,
    payload,
  };
}

export function setParentOffsetX(payload) {
  return {
    type: types.SET_PARENT_OFFSET_X,
    payload,
  };
}

export function setParentOffsetY(payload) {
  return {
    type: types.SET_PARENT_OFFSET_Y,
    payload,
  };
}

export function setGlobalWaterTemperatureSource(payload) {
  return {
    type: types.SET_GLOBAL_WATER_TEMPERATURE_SOURCE,
    payload,
  };
}

export function setGlobalWaterTemperature(payload) {
  return {
    type: types.SET_GLOBAL_WATER_TEMPERATURE,
    payload,
  };
}

export function toggleBufferAreaVisibility(payload) {
  return {
    type: types.TOGGLE_BUFFER_AREA_VISIBILITY,
    payload,
  };
}

export function setBufferNorth(payload) {
  return {
    type: types.SET_BUFFER_NORTH,
    payload,
  };
}

export function setBufferSouth(payload) {
  return {
    type: types.SET_BUFFER_SOUTH,
    payload,
  };
}

export function setBufferWest(payload) {
  return {
    type: types.SET_BUFFER_WEST,
    payload,
  };
}

export function setBufferEast(payload) {
  return {
    type: types.SET_BUFFER_EAST,
    payload,
  };
}

export function setVerticalResolutionMode(payload) {
  return {
    type: types.SET_VERTICAL_RESOLUTION_MODE,
    payload,
  };
}

export function setVerticalResolutionMeter(payload) {
  return {
    type: types.SET_VERTICAL_RESOLUTION_METER,
    payload,
  };
}

export function setBuildingHeights(payload) {
  return {
    type: types.SET_BUILDING_HEIGHTS,
    payload,
  };
}

export function setBuildingHeightsFile(payload) {
  return {
    type: types.SET_BUILDING_HEIGHTS_FILE,
    payload,
  };
}

export function setChildBuildingHeights(payload) {
  return {
    type: types.SET_CHILD_BUILDING_HEIGHTS,
    payload,
  };
}

export function setChildBuildingHeightsFile(payload) {
  return {
    type: types.SET_CHILD_BUILDING_HEIGHTS_FILE,
    payload,
  };
}

export function setTreeHeights(payload) {
  return {
    type: types.SET_TREE_HEIGHTS,
    payload,
  };
}

export function setTreeHeightsFile(payload) {
  return {
    type: types.SET_TREE_HEIGHTS_FILE,
    payload,
  };
}

export function setChildTreeHeights(payload) {
  return {
    type: types.SET_CHILD_TREE_HEIGHTS,
    payload,
  };
}

export function setChildTreeHeightsFile(payload) {
  return {
    type: types.SET_CHILD_TREE_HEIGHTS_FILE,
    payload,
  };
}

export function setBufferFillType(payload) {
  return {
    type: types.SET_BUFFER_FILL_TYPE,
    payload,
  };
}

export function setBufferFillValue(payload) {
  return {
    type: types.SET_BUFFER_FILL_VALUE,
    payload,
  };
}

export function setBufferMethod(payload) {
  return {
    type: types.SET_BUFFER_METHOD,
    payload,
  };
}

export function setBufferTopographyInterpMethod(payload) {
  return {
    type: types.SET_BUFFER_TOPOGRAPHY_INTERP_METHOD,
    payload,
  };
}

export function setSoilAlphaCoefficient(payload) {
  return {
    type: types.SET_SOIL_ALPHA_COEFFICIENT,
    payload,
  };
}

export function setSoilLCoefficient(payload) {
  return {
    type: types.SET_SOIL_L_COEFFICIENT,
    payload,
  };
}

export function setSoilNCoefficient(payload) {
  return {
    type: types.SET_SOIL_N_COEFFICIENT,
    payload,
  };
}

export function setSoilHydraulicConductivity(payload) {
  return {
    type: types.SET_SOIL_HYDRAULIC_CONDUCTIVITY,
    payload,
  };
}

export function setSoilMoistureSaturation(payload) {
  return {
    type: types.SET_SOIL_MOISTURE_SATURATION,
    payload,
  };
}

export function setSoilMoistureCapacity(payload) {
  return {
    type: types.SET_SOIL_MOISTURE_CAPACITY,
    payload,
  };
}
export function setSoilMoistureWilting(payload) {
  return {
    type: types.SET_SOIL_MOISTURE_WILTING,
    payload,
  };
}
export function setSoilMoistureContent(payload) {
  return {
    type: types.SET_SOIL_MOISTURE_CONTENT,
    payload,
  };
}
