import React from "react";
import OsmDownloadMap from "./OsmDownloadMap";
import SidebarContainer from "../../Domain/SidebarContainer/SidebarContainer";

class OsmDownloadContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lat: 48.14,
      lng: 11.65,
      zoom: 12,
    };
  }

  render() {
    return (
      <>
        <OsmDownloadMap
          center={[this.state.lat, this.state.lng]}
          zoom={this.state.zoom}
        />
        <SidebarContainer />
      </>
    );
  }
}

export default OsmDownloadContainer;
