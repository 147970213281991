import { types } from "../types/createProject";

export function initCreateProject(payload) {
  return {
    type: types.INIT_CREATE_PROJECT,
    payload,
  };
}

export function createProject() {
  return {
    type: types.CREATE_PROJECT,
  };
}

export function createProjectSuccess() {
  return {
    type: types.CREATE_PROJECT_SUCCESS,
  };
}

export function createProjectFailure(error) {
  return {
    type: types.CREATE_PROJECT_FAILURE,
    payload: error
  };
}

export function uploadGeojson(payload) {
  return {
    type: types.UPLOAD_GEOJSON,
    payload,
  };
}
export function uploadGeojsonSuccess(payload) {
  return {
    type: types.UPLOAD_GEOJSON_SUCCESS,
    payload
  };
}

export function checkGeojson(payload) {
  return {
    type: types.CHECK_GEOJSON,
    payload,
  };
}

export function uploadTopography(payload) {
  return {
    type: types.UPLOAD_TOPOGRAPHY,
    payload,
  };
}
export function uploadTopographySuccess(payload) {
  return {
    type: types.UPLOAD_TOPOGRAPHY_SUCCESS,
    payload
  };
}

export function convertTopography(payload) {
  return {
    type: types.CONVERT_TOPOGRAPHY,
    payload,
  };
}
export function setCheckGeojsonRequest(payload) {
  return {
    type: types.SET_CHECK_GEOJSON_REQUEST,
    payload,
  };
}

export function setConvertTopographyRequest(payload) {
  return {
    type: types.SET_CONVERT_TOPOGRAPHY_REQUEST,
    payload,
  };
}
