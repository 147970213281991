import React, { useRef, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  updateChEmissionsFactorTable,
  updateChPointEmissionsTable,
} from "../../redux/actions/chemistryActions";
import { useSelector, useDispatch } from "react-redux";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "../WindComfort/WindTable.scss";

export const EmissionsFactorTable = (props) => {
  const gridRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const emissionsFactorTable = useSelector(
    (state) => state.chemistry.emissionsFactorTable
  );

  const columnDefs = [
    {
      headerName: "Factor",
      field: "factor",
      pinned: "left",
      editable: false,
      cellClass: "chemistry-table-dir-col",
    },
    {
      headerName: "PM2.5",
      field: "pm25",
      pinned: "left",
    },
    {
      headerName: "PM10",
      field: "pm10",
      pinned: "left",
    },
  ];

  const numberFormatter = (params) => {
    return params.value?.toString();
  };

  const defaultColDef = useMemo(() => {
    return {
      editable: true,
      headerClass: "wind-hdr",
      cellClass: "wind-cell",
      suppressMovable: true,
      valueFormatter: numberFormatter,
      valueSetter: (params) => {
        if (!isNaN(params.newValue)) {
          const rowData = params.data;
          rowData[params.colDef.field] = params.newValue;
          emissionsFactorTable[params.node.rowIndex] = rowData;
          dispatch(updateChEmissionsFactorTable(emissionsFactorTable));
          return true;
        }
        return false;
      },
    };
  }, []);

  return (
    <div
      className="chemistry-table-container"
      style={{ marginTop: 30, minWidth: 805 }}
    >
      {/* <div className=" table-flex__wrapper"> */}
      <div className="ag-theme-alpine" style={gridStyle}>
        <AgGridReact
          {...props}
          ref={gridRef}
          rowData={emissionsFactorTable}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          suppressHorizontalScroll
          onGridReady={(params) => {
            params.api.sizeColumnsToFit();
            window.onresize = debounce(() => {
              params.api.sizeColumnsToFit();
            }, 200);
          }}
          overlayNoRowsTemplate={t("NoRowsToShow")}
          headerHeight={30}
          rowHeight={30}
          domLayout={"autoHeight"}
        ></AgGridReact>
      </div>
    </div>
  );
};

export const PointEmissionsTable = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const currentPointEmissionsTableIndex = useSelector(
    (state) => state.chemistry.currentPointEmissionsTableIndex
  );

  const arrayOfPointEmissionTable = useSelector(
    (state) => state.chemistry.arrayOfPointEmissionTable
  );

  const columnDefs = [
    {
      headerName: "Factor",
      field: "factor",
      pinned: "left",
      editable: false,
      cellClass: "chemistry-table-dir-col",
    },
    {
      headerName: "PM2.5",
      field: "pm25",
      pinned: "left",
    },
    {
      headerName: "PM10",
      field: "pm10",
      pinned: "left",
    },
  ];

  const numberFormatter = (params) => {
    return params.value?.toString();
  };

  const defaultColDef = useMemo(() => {
    return {
      editable: true,
      headerClass: "wind-hdr",
      cellClass: "wind-cell",
      suppressMovable: true,
      valueFormatter: numberFormatter,
      valueSetter: (params) => {
        if (!isNaN(params.newValue)) {
          const rowData = params.data;
          const rowIndex = params.node.rowIndex;
          if (rowIndex !== 0) {
            rowData[params.colDef.field] = Number(params.newValue).toFixed(2);
          } else {
            rowData[params.colDef.field] = params.newValue;
          }

          dispatch(
            updateChPointEmissionsTable({
              rowData: rowData,
              rowIndex: rowIndex,
            })
          );
          return true;
        }
        return false;
      },
    };
  }, []);
  return (
    <div className="chemistry-table-container" style={{ minWidth: 601 }}>
      <div className="ag-theme-alpine" style={gridStyle}>
        {arrayOfPointEmissionTable.map((x, i) => {
          if (i === currentPointEmissionsTableIndex)
            return (
              <AgGridReact
                {...props}
                rowData={arrayOfPointEmissionTable[
                  currentPointEmissionsTableIndex
                ].map((x) => {
                  return { ...x };
                })}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                suppressHorizontalScroll
                overlayNoRowsTemplate={t("NoRowsToShow")}
                headerHeight={30}
                rowHeight={30}
                domLayout={"autoHeight"}
              ></AgGridReact>
            );
          return;
        })}
      </div>
    </div>
  );
};
