import { ArrowRight, Trash2 } from "react-feather";
import * as moment from "moment";
import { fieldFormatter, statusFormatter } from "./formatters";

export const colDefs = (t, showCase, deleteCase) => [
  {
    headerName: t("Name"),
    field: "name",
    sortable: true,
  },
  {
    headerName: t("Field"),
    field: "applicationField",
    sortable: true,
    valueFormatter: (params) => fieldFormatter(params, t),
  },
  {
    headerName: t("Status"),
    sortable: true,
    valueFormatter: (params) => statusFormatter(params, t),
  },
  {
    headerName: t("LastModified"),
    field: "updatedTs",
    sortable: true,
    sort: "desc",
    valueGetter: (params) => params.data.updatedTs || params.data.createdTs,
    valueFormatter: (params) => {
      return moment(params.value).local("LLL").format("LLL");
    },
  },
  {
    headerName: "",
    cellRenderer: "iconCell",
    width: 60,
    cellRendererParams: {
      icon: ArrowRight,
      onClick: (data) => showCase(data),
    },
  },
  {
    headerName: "",
    cellRenderer: "iconCell",
    width: 60,
    cellRendererParams: {
      icon: Trash2,
      onClick: (data) => deleteCase(data),
    },
  },
];
