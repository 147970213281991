import React from "react";
import { useTranslation } from "react-i18next";

const SimWarning = (props) => {
  const { t } = useTranslation();

  return (
    <>
      {!props.savedSettings && (
        <p className="sim-warning">{t("SaveSettingsFirst")}</p>
      )}
      {!props.isSingleStaticDriverReady && !props.isNestedStaticDriverReady && (
        <p className="sim-warning">{t("CreateStaticDriverFirst")}</p>
      )}
    </>
  );
};

export default SimWarning;
