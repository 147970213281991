import React from "react";
import { Play, X } from "react-feather";
import { Loader } from "../../Components/Loader/index";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const SimStartBtn = (props) => {
  const { t } = useTranslation();

  const currentCase = useSelector((state) => state.projects.currentCase);

  const isCompleted =
    props.simulationNotifications?.status === "finished" ||
    props.simulationNotifications?.status === "cancelled" ||
    props.simulationNotifications?.status === "aborted";
  return (
    <>
      {!props.canStartSimulation ||
      props.isInfoLoading ||
      (currentCase?.simulationJobs.length > 0 &&
        currentCase?.simulationJobs.every((x) => {
          return x.status === "finished" || x.status === 8;
        })) ? (
        <button className="sim-btn-disabled">
          <Play color="#a0a5aa" size="15px" strokeWidth="2" />
          <p className="sim-btn-inputs-text sim-btn-text-icon">
            {t("StartSimulation")}
          </p>
        </button>
      ) : props.awaitingSimulationStart ? (
        <button className="sim-btn-disabled">
          <div>
            <Loader size="simulation" className="sim-btn-icon" />
          </div>
          <p className="sim-btn-inputs-text sim-btn-text-icon">
            {t("StartSimulation")}
          </p>
        </button>
      ) : props.canStartSimulation &&
        !props.isInfoLoading &&
        !props.simulationNotifications?.progress &&
        !!props.simulationNotifications === false &&
        props.simulationNotifications?.status !== "finished" ? (
        <button
          className="sim-start-btn sim-btn-text-icon"
          onClick={props.handleSimulationClick}
        >
          <Play color="white" size="15px" strokeWidth="2" />
          <p className="sim-btn-inputs-text sim-btn-text-icon">
            {t("StartSimulation")}
          </p>
        </button>
      ) : !!props.simulationNotifications?.progress && !isCompleted ? (
        //&&
        // props.simulationNotifications?.status !== "error"
        <button
          className="sim-start-btn sim-cancel-btn"
          onClick={props.cancelSimulation}
        >
          <X color="white" size="15px" />
          <p className="sim-btn-inputs-text sim-btn-text-icon">
            {t("CancelSimulation")}
          </p>
        </button>
      ) : !!props.simulationNotifications?.progress && !isCompleted ? (
        //&&
        //props.simulationNotifications?.status !== "error"
        <button
          className="sim-start-btn sim-cancel-btn"
          onClick={props.cancelSimulation}
        >
          <X color="white" size="15px" />
          <p className="sim-btn-inputs-text sim-btn-text-icon">
            {t("CancelSimulation")}
          </p>
        </button>
      ) : props.simulationNotifications?.status === "finished" ||
        props.simulationNotifications?.status === "aborted" ? (
        <button className="sim-btn-disabled">
          <Play color="#a0a5aa" size="15px" strokeWidth="2" />
          <p className="sim-btn-inputs-text sim-btn-text-icon">
            {t("StartSimulation")}
          </p>
        </button>
      ) : props.simulationNotifications?.status !== "cancelled" ? (
        //&&
        //props.simulationNotifications?.status !== "error"
        <button
          className="sim-start-btn sim-cancel-btn"
          onClick={props.cancelSimulation}
        >
          <X color="white" size="15px" />
          <p className="sim-btn-inputs-text sim-btn-text-icon">
            {t("CancelSimulation")}
          </p>
        </button>
      ) : (
        <></>
      )}
    </>
  );
};

export default SimStartBtn;
