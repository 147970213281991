import React from "react";
import { useTranslation } from "react-i18next";

const RasterizationNotification = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="notification__title">{t("Rasterization")}</div>
      <div className="notification__body">{t(getBody(props.rasterization.body.status))}</div>
    </>
  );
};

function getBody(status) {
  if (!status || status === 0) {
    return "Rasterization_Notification_Schedule";
  }
  if (status === 1) {
    return "Rasterization_Notification_Processing";
  }
  if (status === 2) {
    return "Rasterization_Notification_Completed";
  }
  if (status === 3) {
    return "Rasterization_Notification_Error";
  }
}

export default RasterizationNotification;
