import { types } from "../types/cases";

export function createCase(caseObj) {
  return {
    type: types.CREATE_CASE,
    payload: caseObj,
  };
}
export function initCreateCase(caseObj) {
  return {
    type: types.INIT_CREATE_CASE,
    payload: caseObj,
  };
}
export function initCloneCase(caseObj) {
  return {
    type: types.INIT_CLONE_CASE,
    payload: caseObj,
  };
}
export function initUpdateCase(caseObj) {
  return {
    type: types.INIT_UPDATE_CASE,
    payload: caseObj,
  };
}
export function cloneCase(cloneRequest) {
  return {
    type: types.CLONE_CASE,
    payload: cloneRequest,
  };
}

export function createCaseSuccess(caseId) {
  return {
    type: types.CREATE_CASE_SUCCESS,
    payload: caseId,
  };
}

export function createCaseFailure(error) {
  return {
    type: types.CREATE_CASE_FAILURE,
    payload: error,
  };
}

export function deleteCase(caseId) {
  return {
    type: types.DELETE_CASE,
    payload: caseId,
  };
}

export function deleteCaseSuccess(caseId) {
  return {
    type: types.DELETE_CASE_SUCCESS,
    payload: caseId,
  };
}

export function deleteCaseFailure() {
  return {
    type: types.DELETE_CASE_FAILURE,
  };
}

export function updateCase(caseObj) {
  return {
    type: types.UPDATE_CASE,
    payload: caseObj,
  };
}

export function updateCaseSuccess() {
  return {
    type: types.UPDATE_CASE_SUCCESS,
  };
}

export function updateCaseFailure() {
  return {
    type: types.UPDATE_CASE_FAILURE,
  };
}

export function checkGeojson(payload) {
  return {
    type: types.CASE_CHECK_GEOJSON,
    payload,
  };
}

export function convertTopography(payload) {
  return {
    type: types.CASE_CONVERT_TOPOGRAPHY,
    payload,
  };
}

export function setCheckGeojsonRequest(payload) {
  return {
    type: types.SET_CASE_CHECK_GEOJSON_REQUEST,
    payload,
  };
}

export function setConvertTopographyRequest(payload) {
  return {
    type: types.SET_CASE_CONVERT_TOPOGRAPHY_REQUEST,
    payload,
  };
}

export function uploadGeojson() {
  return {
    type: types.CASE_UPLOAD_GEOJSON,
  };
}
export function uploadGeojsonSuccess(payload) {
  return {
    type: types.CASE_UPLOAD_GEOJSON_SUCCESS,
    payload,
  };
}

export function uploadTopography() {
  return {
    type: types.CASE_UPLOAD_TOPOGRAPHY,
  };
}
export function uploadTopographySuccess(payload) {
  return {
    type: types.CASE_UPLOAD_TOPOGRAPHY_SUCCESS,
    payload,
  };
}
export function resetCreateCase() {
  return {
    type: types.CREATE_CASE_RESET,
  };
}

export function setCaseDrawnGeojsonUrl(payload) {
  return {
    type: types.SET_CASE_DRAWN_GEOJSON_URL,
    payload,
  };
}
