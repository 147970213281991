import React from "react";
import { useTranslation } from "react-i18next";
import "./Footer.scss";

function Footer() {
  const { t } = useTranslation();
  return (
    <div className="footer-wrapper">
      <div className="footer">
        <p className="footer-txt">{t("ProjFooterTxt")}</p>
      </div>
    </div>
  );
}

export default Footer;
