import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import CheckboxRenderer from "./CheckboxRenderer";
import { updateCheckboxExpertTable } from "../../../../redux/actions/expertActions";
import { connect } from "react-redux";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

class ExpertCheckboxTable extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div
        className="ag-theme-alpine"
        style={{
          height: "100%",
          width: "100%",
        }}
      >
        <AgGridReact
          columnDefs={this.props.tableData.columnDefs}
          defaultColDef={this.props.tableData.defaultColDef}
          frameworkComponents={{ checkboxRenderer: CheckboxRenderer }}
          onGridReady={(params) => {
            params.api.sizeColumnsToFit();
          }}
          rowData={this.props.rowData}
          domLayout={"autoHeight"}
          overlayNoRowsTemplate={"No Rows To Show"}
          suppressCellSelection={!this.props.suppressCellSelection}
          suppressHorizontalScroll
          rowClassRules={this.props.rowClassRules}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateCheckboxExpertTable: (payload) =>
      dispatch(updateCheckboxExpertTable(payload)),
  };
};

export default connect(null, mapDispatchToProps)(ExpertCheckboxTable);
