import React from "react";
import Select from "react-select";
import {
  ExpertHelpLabel,
  ExpertTimeAccordion,
} from "../../ExpertUtils/ExpertComponent";
import { FormSettingsSeparator } from "../../../../Components/Common/Form/FormSeparator/index";
import { helpLink } from "../../expertConst";
import { selectSetupStyle } from "../../../../Components/Common/PaletteDropdown/index";
import { changeExpertInput } from "../../../../redux/actions/expertActions";
import { getCrossSectionHeightLevelOptions } from "../../expertConst";
import { useSelector, useDispatch } from "react-redux";

const [page, subpage] = ["output", "dxy_cross_section"];

const CrossSection = () => {
  const dxy_cross_section = useSelector(
    (state) => state.expert.expertJson.output.dxy_cross_section
  );
  const childRasterParameters = useSelector(
    (state) => state.projects?.currentCase?.childRasterParameters
  );
  const childRasterParamsOnMount = useSelector(
    (state) => state.rasterArea?.currentCase?.childRasterParameters
  );
  const dispatch = useDispatch();
  return (
    <>
      <div style={{ marginBottom: -20 }} />
      <FormSettingsSeparator
        style={{ fontSize: "17px" }}
        label={"General Settings"}
      />

      <ExpertHelpLabel
        label={"Height Levels for 2D-xy-Output"}
        helpLink={`${helpLink}/runtime_parameters#section_xy`}
      />
      <Select
        isMulti
        isClearable
        classNamePrefix="select"
        closeMenuOnSelect={false}
        styles={selectSetupStyle}
        placeholder={"Select..."}
        options={getCrossSectionHeightLevelOptions()}
        value={dxy_cross_section.parameters.dxy_section_xy.map((x) => ({
          label: `Grid Height z = ${x}`,
          value: x,
        }))}
        onChange={(e) =>
          dispatch(
            changeExpertInput({
              page: page,
              subpage: subpage,
              key: "parameters",
              subkey: "dxy_section_xy",
              value: e.map((x) => x.value),
            })
          )
        }
      />
      {(childRasterParameters || childRasterParamsOnMount) && (
        <>
          <div style={{ marginBottom: 12 }} />
          <ExpertHelpLabel
            label={"Height Levels for 2D-xy-Output - Child"}
            helpLink={`${helpLink}/runtime_parameters#section_xy`}
          />
          <Select
            isMulti
            isClearable
            classNamePrefix="select"
            closeMenuOnSelect={false}
            styles={selectSetupStyle}
            placeholder={"Select..."}
            options={getCrossSectionHeightLevelOptions()}
            value={dxy_cross_section.parameters.dxy_section_xy_child.map(
              (x) => ({
                label: `Grid Height z = ${x}`,
                value: x,
              })
            )}
            onChange={(e) =>
              dispatch(
                changeExpertInput({
                  page: page,
                  subpage: subpage,
                  key: "parameters",
                  subkey: "dxy_section_xy_child",
                  value: e.map((x) => x.value),
                })
              )
            }
          />
        </>
      )}
      <FormSettingsSeparator
        style={{ fontSize: "17px" }}
        label={"Averaged Output"}
      />
      <ExpertHelpLabel
        label={"Activate averaged output"}
        helpLink={`${helpLink}/iofiles#DATA_2D_XY_AV_NETCDF_O`}
      />
      <Select
        isSearchable={false}
        styles={selectSetupStyle}
        options={[
          {
            value: "True",
            label: "True",
          },
          { value: "False", label: "False" },
        ]}
        value={{
          label: !!dxy_cross_section.averaged.dxy_av_isActive
            ? "True"
            : "False",
          value: !!dxy_cross_section.averaged.dxy_av_isActive
            ? "True"
            : "False",
        }}
        onChange={(e) =>
          dispatch(
            changeExpertInput({
              page: page,
              subpage: subpage,
              key: "averaged",
              subkey: "dxy_av_isActive",
              value: e.value === "True" ? true : false,
            })
          )
        }
      />
      <FormSettingsSeparator
        style={{ fontSize: "17px" }}
        label={"Instantaneous output"}
      />
      <ExpertHelpLabel
        label={"Activate instantaneous output"}
        helpLink={`${helpLink}/iofiles#DATA_2D_XY_AV_NETCDF`}
      />
      <Select
        isSearchable={false}
        styles={selectSetupStyle}
        options={[
          {
            value: "True",
            label: "True",
          },
          { value: "False", label: "False" },
        ]}
        value={{
          label: !!dxy_cross_section.instantaneous.dxy_inst_isActive
            ? "True"
            : "False",
          value: !!dxy_cross_section.instantaneous.dxy_inst_isActive
            ? "True"
            : "False",
        }}
        onChange={(e) =>
          dispatch(
            changeExpertInput({
              page: page,
              subpage: subpage,
              key: "instantaneous",
              subkey: "dxy_inst_isActive",
              value: e.value === "True" ? true : false,
            })
          )
        }
      />
      <div style={{ marginBottom: 12 }} />
      <ExpertHelpLabel
        label={"Instantaneous Output Intervall [s]"}
        helpLink={`${helpLink}/runtime_parameters#dt_do2d_xy`}
      />
      <ExpertTimeAccordion
        page={page}
        subpage={subpage}
        section="instantaneous"
        subkey="dxy_dt_do2d_xy"
        min={1}
        max={86400}
        constValue={dxy_cross_section.instantaneous.dxy_dt_do2d_xy}
      />
    </>
  );
};

export default CrossSection;
