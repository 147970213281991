import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Badge } from "../../../../Components/Common/Notification/Badge/index";
import { NotificationList } from "../../../../Components/Common/Notification/NotificationList";
import useClickOutside from "../../../../hooks/useClickOutside";
import {
  ackNotifications,
  dismissNotification,
} from "../../../../redux/actions/notificationActions";
import "./index.scss";
import { selectNotifications } from "../../../../redux/selectors/notificationsSelectors";
import { ReactComponent as NotificationIcon } from "./notification-bell.svg";

const Notifications = (props) => {
  const ref = useRef();
  const { t } = useTranslation();

  const [showNotifications, setShowNotifications] = useState(false);
  const notifications = useSelector(selectNotifications);

  const dispatch = useDispatch();
  useClickOutside(ref, () => {
    if (showNotifications) {
      hide();
    }
  });

  const toggle = () => {
    if (showNotifications) {
      hide();
    } else {
      setShowNotifications(true);
    }
  };

  const hide = () => {
    setShowNotifications(false);
    dispatch(ackNotifications(notifications.map((n) => n.id)));
  };

  const onItemDismiss = (item) => {
    dispatch(dismissNotification(item.id));
  };
  return (
    <div ref={ref} className="notifications-container">
      <Badge
        icon={NotificationIcon}
        show={showNotifications}
        count={notifications.filter((n) => !n.ack).length}
        onClick={toggle}
        title={t("Notifications")}
      />
      {showNotifications ? (
        <NotificationList
          items={notifications}
          onListClose={hide}
          onDismiss={onItemDismiss}
        />
      ) : null}
    </div>
  );
};

export default Notifications;
