import React from "react";
import "./index.scss";

const FormSectionSeparator = (props) => {
  return (
    <div className="form-section_separator">
      <h4 className="form-section__label">{props.label}</h4>
      <div className="form-section__line" />
    </div>
  );
};

export default FormSectionSeparator;
