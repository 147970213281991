import axios from "axios";
import authService from "./../utils/auth/authService";

export async function apiRequest(params) {
  try {
    const token = await getToken();
    return await makeRequest(token, params);
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data);
    } else {
      throw new Error(error.message);
    }
  }
}
export async function apiRequestWithToken(params, token) {
  return makeRequest(token, params);
}

async function makeRequest(token, params) {
  return axios({
    ...params,
    headers: { Authorization: `Bearer ${token}` },
    responseType: "application/json",
  });
}

export async function multiFormRequest(params) {
  const token = await getToken();
  return makeMultiFormRequest(token, params);
}

function makeMultiFormRequest(token, params) {
  return axios({
    ...params,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-type": "multipart/form-data",
    },
  });
}

export function getToken() {
  try {
    return authService.acquireToken();
  } catch (e) {
    console.error(e);
  }
}
