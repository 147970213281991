import React from "react";
import { useTranslation } from "react-i18next";

const StaticDriverNotification = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="notification__title">{t("StaticDriver")}</div>
      <div className="notification__body">
        {t(getBody(props.rasterization.body.status))}
      </div>
    </>
  );
};

function getBody(status) {
  if (!status || status === 0) {
    return "StaticDriver_Notification_Schedule";
  }
  if (status === 1) {
    return "StaticDriver_Notification_Processing";
  }
  if (status === 2) {
    return "StaticDriver_Notification_Completed";
  }
  if (status === 3) {
    return "StaticDriver_Notification_Error";
  }
}


export default StaticDriverNotification;
