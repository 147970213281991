import { types } from "../types/checkFile";
import { allCrsOptions } from "../../containers/Tools/OsmDownload/osmConst";
import { cloneDeep } from "lodash";

const acceptedFileFormat = {
  JSON: "application/json",
  GEOTIFF: "image/tiff",
};

const errorFileInfo = {
  WRONG_GEOJSON_FORMAT: "WrongGeojsonFormat",
  WRONG_GEOJSON_SIZE: "WrongGeojsonSize",
  WRONG_GEOJSON_EPSG: "WrongGeojsonEpsg",
  WRONG_NEW_CASE_GEOJSON_EPSG: "WrongNewCaseGeojsonEpsg",
  WRONG_GEOTIFF_FORMAT: "WrongGeotiffFormat",
  WRONG_GEOTIFF_SIZE: "WrongGeotiffSize",
};

const fileLimits = {
  MAX_FILE_SIZE: 500000000,
};

const btnTxt = {
  CREATE: "Create",
  CREATE_EMPTY_CASE: "CreateEmptyCase",
  CREATE_CASE_WITHOUT_GEOJSON: "CreateCaseWithoutGeojson",
  CREATE_CASE_WITHOUT_GEOTIFF: "CreateCaseWithoutGeotiff",
};

const geojsonCheckInitialState = {
  geojson: "",
  geojsonName: "",
  geojsonEpsg: { label: "25832", value: "25832" },
  isGeojsonAdded: false,
  isGeojsonValid: true,
  geojsonErrorMessage: "",
};

const geotiffCheckInitialState = {
  geotiff: "",
  geotiffName: "",
  isGeotiffAdded: false,
  isGeotiffValid: true,
  geotiffErrorMessage: "",
};

const initialState = {
  canSubmit: true,
  submitButtonText: btnTxt.CREATE_EMPTY_CASE,
  ...geojsonCheckInitialState,
  ...geotiffCheckInitialState,
};

const checkFileReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHECK_GEOJSON_FILE: {
      const geojson = action.payload.geojson;
      const geojsonName = geojson.name;
      const epsg = action.payload.epsg;
      let geojsonErrorMessage = "";
      if (
        geojson.type !== acceptedFileFormat.JSON &&
        geojsonName.substr(-7) !== "geojson"
      ) {
        geojsonErrorMessage = errorFileInfo.WRONG_GEOJSON_FORMAT;
      } else if (geojson.size > fileLimits.MAX_FILE_SIZE) {
        geojsonErrorMessage = errorFileInfo.WRONG_GEOJSON_SIZE;
      } else if (
        epsg === errorFileInfo.WRONG_NEW_CASE_GEOJSON_EPSG ||
        !allCrsOptions.some((x) => x.value === `EPSG:${epsg}`)
      ) {
        geojsonErrorMessage = errorFileInfo.WRONG_NEW_CASE_GEOJSON_EPSG;
    }

      if (geojsonErrorMessage !== "") {
        return {
          ...state,
          geojsonName: geojson.name,
          isGeojsonValid: false,
          isGeojsonAdded: true,
          canSubmit: false,
          geojsonErrorMessage: geojsonErrorMessage,
          submitButtonText: btnTxt.CREATE,
        };
      }

      let btnText = cloneDeep(state.submitButtonText);

      if (!state.isGeotiffAdded) {
        btnText = btnTxt.CREATE_CASE_WITHOUT_GEOTIFF;
      } else if (state.isGeotiffValid) {
        btnText = btnTxt.CREATE;
      }
      return {
        ...state,
        geojsonName: geojson.name,
        isGeojsonAdded: true,
        isGeojsonValid: true,
        geojsonEpsg: epsg,
        geojsonErrorMessage: null,
        canSubmit: state.isGeotiffValid ? true : false,
        submitButtonText: btnText,
        geojson: geojson,
      };
    }
    case types.CHECK_GEOTIFF_FILE: {
      const geotiff = action.payload;
      if (geotiff.type !== acceptedFileFormat.GEOTIFF) {
        return {
          ...state,
          geotiffName: geotiff.name,
          isGeotiffValid: false,
          isGeotiffAdded: true,
          canSubmit: false,
          geotiffErrorMessage: errorFileInfo.WRONG_GEOTIFF_FORMAT,
          submitButtonText: btnTxt.CREATE,
        };
      } else if (geotiff.size > fileLimits.MAX_FILE_SIZE) {
        return {
          ...state,
          geotiffName: geotiff.name,
          isGeotiffAdded: true,
          isGeotiffValid: false,
          canSubmit: false,
          geotiffErrorMessage: errorFileInfo.WRONG_GEOTIFF_SIZE,
          submitButtonText: btnTxt.CREATE,
        };
      }

      let submitButtonText = cloneDeep(state.submitButtonText);
      if (!state.isGeojsonAdded) {
        submitButtonText = btnTxt.CREATE_CASE_WITHOUT_GEOJSON;
      } else if (state.isGeojsonValid) {
        submitButtonText = btnTxt.CREATE;
      }

      return {
        ...state,
        geotiff: geotiff,
        geotiffName: geotiff.name,
        isGeotiffValid: true,
        isGeotiffAdded: true,
        geotiffErrorMessage: null,
        canSubmit: state.isGeojsonValid ? true : false,
        submitButtonText: submitButtonText,
      };
    }

    case types.RESET_CHECK_FILE_STATE:
      return {
        ...initialState,
      };
    case types.RESET_GEOJSON_CHECK_FILE: {
      let submitButtonText = cloneDeep(state.submitButtonText);
      let canSubmit = cloneDeep(state.canSubmit);

      if (state.isGeotiffAdded) {
        submitButtonText = btnTxt.CREATE_CASE_WITHOUT_GEOJSON;
        if (state.isGeotiffValid) {
          canSubmit = true;
        }
      } else {
        submitButtonText = btnTxt.CREATE_EMPTY_CASE;
        canSubmit = true;
      }
      return {
        ...state,
        ...geojsonCheckInitialState,
        submitButtonText: submitButtonText,
        canSubmit: canSubmit,
      };
    }
    case types.RESET_GEOTIFF_CHECK_FILE: {
      let submitButtonText = cloneDeep(state.submitButtonText);
      let canSubmit = cloneDeep(state.canSubmit);

      if (state.isGeojsonAdded) {
        submitButtonText = btnTxt.CREATE_CASE_WITHOUT_GEOTIFF;
        if (state.isGeojsonValid) {
          canSubmit = true;
        }
      } else {
        submitButtonText = btnTxt.CREATE_EMPTY_CASE;
        canSubmit = true;
      }
      return {
        ...state,
        ...geotiffCheckInitialState,
        submitButtonText: submitButtonText,
        canSubmit: canSubmit,
      };
    }

    case types.SET_PROJECT_EPSG: {
      return {
        ...state,
        geojsonEpsg: action.payload,
      };
    }

    default:
      return state;
  }
};

export default checkFileReducer;
