import { types } from "../types/projects";
import { types as ntypes } from "../types/notifications";
import { types as caseTypes } from "../types/cases";
import { types as projectFilesTypes } from "../types/projectFiles";
import { types as rasterAreaTypes } from "../types/rasterArea";
import { cloneDeep } from "lodash";

const initialState = {
  loadedProject: null,
  isLoading: false,
  projects: [],
  currentCase: null,
  currentCaseId: null,
  isUpdating: false,
  updateError: null,
};

const projectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_PROJECT:
      return {
        ...state,
        loadedProject:
          state.loadedProject?.id === action.payload
            ? state.loadedProject
            : null,
        isLoading: true,
        currentCase:
          state.loadedProject?.id === action.payload ? state.currentCase : null,
        currentCaseId:
          state.loadedProject?.id === action.payload
            ? state.currentCaseId
            : null,
      };
    case types.UNLOAD_PROJECT:
      return {
        ...state,
        loadedProject: null,
        currentCase: null,
      };
    case types.LOAD_PROJECT_SUCCESS:
      const currentCaseId = state.currentCase?.id;
      return {
        ...state,
        loadedProject: action.payload,
        isLoading: false,
        currentCase: currentCaseId
          ? action.payload.caseDetailsDto.find((c) => c.id === currentCaseId)
          : null,
      };
    case types.LOAD_PROJECTS:
      return {
        ...state,
        projects: null,
      };
    case types.LOAD_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: action.payload,
      };
    case types.SET_CURRENT_CASE_SUCCESS:
      return {
        ...state,
        currentCase: action.payload,
        currentCaseId: action.payload?.id,
        loadedProject:
          action.payload && state.loadedProject.caseDetailsDto
            ? {
                ...state.loadedProject,
                caseDetailsDto: state.loadedProject.caseDetailsDto.map((c) =>
                  c.id === action.payload?.id
                    ? {
                        ...action.payload,
                      }
                    : c
                ),
              }
            : state.loadedProject,
      };
    case types.SAVE_PROJECT:
      return {
        ...state,
        isUpdating: true,
        updateError: null,
      };
    case types.SAVE_PROJECT_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        updateError: null,
        loadedProject: action.payload
          ? {
              ...state.loadedProject,
              name: action.payload.name,
              description: action.payload.description,
              pictureUrl: action.payload.pictureUrl,
              pictureDownloadUrl: action.payload.pictureDownloadUrl,
            }
          : state.loadedProject,
      };
    case types.SAVE_PROJECT_FAILURE:
      return {
        ...state,
        isUpdating: false,
        updateError: action.payload,
      };
    case ntypes.ADD_NOTIFICATION: {
      if (!state.loadedProject) {
        return state;
      } else if (action.payload.type === "rasterPreview") {
        //&&
        //action.payload.body.status === 2
        return {
          ...state,
          loadedProject: {
            ...state.loadedProject,
            caseDetailsDto: state.loadedProject.caseDetailsDto.map((c) => {
              if (c.id === action.payload.body.caseId) {
                return {
                  ...c,
                  rasterPreviewJob: c.rasterPreviewJob
                    ? {
                        ...c.rasterPreviewJob,
                        resultUrl: action.payload.body.resultUrl,
                        childResultUrl: action.payload.body.childResultUrl,
                        updatedTs: action.payload.body.timestampUtc,
                      }
                    : {
                        id: action.payload.body.requestId,
                        resultUrl: action.payload.body.resultUrl,
                        childResultUrl: action.payload.body.childResultUrl,
                        updatedTs: action.payload.body.timestampUtc,
                      },
                };
              }
              return c;
            }),
          },
          currentCase: {
            ...state.currentCase,
            rasterPreviewJob:
              state.currentCaseId === action.payload.body.caseId
                ? action.payload.body
                : { ...state.currentCase.rasterPreviewJob },
          },
        };
      } else if (
        action.payload.type === "staticDriver"
        // && action.payload.body.status === 2
      ) {
        return {
          ...state,
          loadedProject: {
            ...state.loadedProject,
            caseDetailsDto: state.loadedProject.caseDetailsDto.map((c) => {
              if (c.id === action.payload.body.caseId) {
                return {
                  ...c,
                  staticDriverJob: c.staticDriverJob
                    ? {
                        ...c.staticDriverJob,
                        resultUrl: action.payload.body.resultUrl,
                        childResultUrl: action.payload.body.childResultUrl,
                        updatedTs: action.payload.body.timestampUtc,
                      }
                    : {
                        id: action.payload.body.requestId,
                        resultUrl: action.payload.body.resultUrl,
                        childResultUrl: action.payload.body.childResultUrl,
                        updatedTs: action.payload.body.timestampUtc,
                      },
                };
              }
              return c;
            }),
          },
          currentCase: {
            ...state.currentCase,
            staticDriverJob:
              state.currentCaseId === action.payload.body.caseId
                ? action.payload.body
                : { ...state.currentCase.staticDriverJob },
          },
        };
      }
      return state;
    }
    case projectFilesTypes.UPDATE_FILE_SUCCESS: {
      const uploadedFile = action.payload.files[0];
      const loadedProjectId = cloneDeep(state.loadedProject.id);
      const uploadedFileProjectId = action.payload.projectId;
      if (loadedProjectId !== uploadedFileProjectId) {
        return state;
      }

      const uploadedFileName = uploadedFile.name;
      const uploadedFilePath = uploadedFile.path;
      const uploadedFileType = uploadedFile.type;

      const loadedProject = cloneDeep(state.loadedProject);
      loadedProject.files = [
        ...loadedProject.files,
        {
          name: uploadedFileName,
          path: uploadedFilePath,
          type: uploadedFileType,
        },
      ];
      return {
        ...state,
        loadedProject: loadedProject,
      };
    }
    case projectFilesTypes.DELETE_FILE_SUCCESS:
      const deletedProjectName = action.payload.fileNames[0];
      const loadedProject = cloneDeep(state.loadedProject);

      if (
        loadedProject.files.some((x) => {
          return deletedProjectName === x.name;
        })
      ) {
        loadedProject.files = loadedProject.files.filter(function (x) {
          return x.name !== deletedProjectName;
        });

        return { ...state, loadedProject: loadedProject };
      }
      return {
        ...state,
      };
    case caseTypes.SET_CASE_DRAWN_GEOJSON_URL: {
      const currentCase = state.currentCase;
      const drawnGeojsonUrl = action.payload.drawnGeojsonUrl;
      const polygonDataUrl = action.payload.polygonDataUrl;
      currentCase.drawnGeojsonUrl = drawnGeojsonUrl;
      currentCase.polygonDataUrl = polygonDataUrl;
      return {
        ...state,
        currentCase: currentCase,
        loadedProject: {
          ...state.loadedProject,
          caseDetailsDto: state.loadedProject.caseDetailsDto.map((c) => {
            if (c.id === state.currentCaseId) {
              return {
                ...c,
                drawnGeojsonUrl: drawnGeojsonUrl,
                polygonDataUrl: polygonDataUrl,
              };
            }
            return c;
          }),
        },
      };
    }
    case ntypes.ADD_STEERING_NOTIFICATION: {
      const steering = action.payload;
      if (steering.resultUrl && state.currentCase.id === steering.id) {
        return {
          ...state,
          currentCase: {
            ...state.currentCase,
            steering: steering,
          },
        };
      }

      return {
        ...state,
      };
    }
    case ntypes.ADD_SIMULATION_NOTIFICATION: {
      const simNot = action.payload;

      const currentCase = cloneDeep(state.currentCase);
      const simNotRequestId = action.payload.body.requestId;

      const simRequestIndex = currentCase.simulationJobs.findIndex((x) => {
        return x.requestId === simNotRequestId;
      });
      const simIndex = currentCase.simulationJobs.findIndex((x) => {
        return x.id === simNotRequestId;
      });

      if (
        simNot.body.response.caseId === state.currentCase.id &&
        (simRequestIndex !== -1 || simIndex !== -1) &&
        simNot.body.response.caseId === state.currentCase.id
      ) {
        if (simRequestIndex !== -1) {
          currentCase.simulationJobs[simRequestIndex] = simNot.body.response;
          currentCase.simulationJobs[simRequestIndex].updatedTs = simNot.ts; // currentCase.simulationJobs[simRequestIndex].updatedTs = //   simNot.body.response.ts;
          return {
            ...state,
            currentCase: currentCase,
          };
        } else if (simIndex !== -1) {
          currentCase.simulationJobs[simIndex] = simNot.body.response;
          currentCase.simulationJobs[simIndex].updatedTs = simNot.ts;

          return {
            ...state,
            currentCase: currentCase,
          };
        }
      } //  else if ( //   simNot.body.response.caseId === state.currentCase.id && //   simId !== -1 && //   simNot.body.response.caseId === state.currentCase.id // ) { //   currentCase.simulationJobs[simRequestIndex] = simNot.body.response; // }
      else if (
        simNot.body.response.caseId === state.currentCase.id && //simRequestIndex === -1 &&
        simNot.body.response
      ) {
        currentCase.simulationJobs.push(simNot.body.response);
        currentCase.simulationJobs[
          currentCase.simulationJobs.length - 1
        ].updatedTs = simNot.ts;
        return {
          ...state,
          currentCase: currentCase,
        };
      } // else if ( //   (simNot.body.response.status === "finished" || //     simNot.body.response.status === 8) && //   simNot.body.response.caseId === state.currentCase.id // ) { //   simNot.body.response.id = simNot.id; //   currentCase.simulationJobs.push(simNot.body.response); // }
      return {
        ...state,
      };
    }

    case ntypes.ADD_POSTPROCESS_NOTIFICATION: {
      const simNot = action.payload;

      if (
        state.currentCase && // simNot.body.status === 2 &&
        simNot.body.caseId === state.currentCase.id
      ) {
        //const existingJob = state.currentCase.postProcessingJobs.find(j => j.id === simNot.id);
        // update job

        const jobs = state.currentCase.postProcessingJobs.filter(
          (j) => j.id !== simNot.id
        );

        return {
          ...state,
          currentCase: {
            ...state.currentCase,
            postProcessingJobs: [...jobs, simNot.body],
          },
        };
      }

      return state;
    }

    case rasterAreaTypes.SET_SINGLE_RASTER_CASE_PROPS: {
      const caseDetailsDto = cloneDeep(state.loadedProject.caseDetailsDto);
      const currentCaseIndex = caseDetailsDto.findIndex((x) => {
        return x.id === state.currentCase.id;
      });

      caseDetailsDto[currentCaseIndex].rasterParameters = action.payload;

      return {
        ...state,
        loadedProject: {
          ...state.loadedProject,
          caseDetailsDto: caseDetailsDto,
        },
      };
    }

    case rasterAreaTypes.SET_NESTED_RASTER_CASE_PROPS: {
      const caseDetailsDto = cloneDeep(state.loadedProject.caseDetailsDto);
      const currentCaseIndex = caseDetailsDto.findIndex((x) => {
        return x.id === state.currentCase.id;
      });

      caseDetailsDto[currentCaseIndex].rasterParameters =
        action.payload.rasterParameters;
      caseDetailsDto[currentCaseIndex].childRasterParameters =
        action.payload.childRasterParameters;
      return {
        ...state,
        loadedProject: {
          ...state.loadedProject,
          caseDetailsDto: caseDetailsDto,
        },
      };
    }

    default:
      return state;
  }
};

export default projectsReducer;
