import React from "react";
import { useTranslation } from "react-i18next";
import PaletteDropdown from "../../PaletteDropdown/index";
import FormSectionSeparator from "../FormSectionSeparator/index";
import { palettes } from "../../../../common/VisualizationPresets/colorPalettes";
import { PaletteTable } from "../../PaletteTable/PaletteTable";
import { palmTypes } from "../../../../common/VisualizationPresets/colorPalettes";
import "./index.scss";

const VisualizationPresets = (props) => {
  const { t } = useTranslation();
  const handleSelectedInputPalmPaletteChange = (row, newColor) => {
    const currentPalette = props?.inputPalmTypesPalette || {};
    if (currentPalette[row.key]) {
      currentPalette[row.key].selected = newColor;
    } else {
      currentPalette[row.key] = { selected: newColor };
    }
    props.handleChange({
      target: { name: "inputPalmTypesPalette", value: currentPalette },
    });
  };
  const handleUnselectedInputPalmPaletteChange = (row, newColor) => {
    const currentPalette = props?.inputPalmTypesPalette || {};
    if (currentPalette[row.key]) {
      currentPalette[row.key].unselected = newColor;
    } else {
      currentPalette[row.key] = { unselected: newColor };
    }
    props.handleChange({
      target: { name: "inputPalmTypesPalette", value: currentPalette },
    });
  };
  const resetInputPalmTypesPalette = () => {
    props.handleChange({
      target: { name: "inputPalmTypesPalette", value: {} },
    });
  };
  return (
    <>
      <div className="user-settings__palette">
        <div className="palettes-container">
          <FormSectionSeparator label={t("InputDataPalettes")} />
          <div className="form-line-input-wrapper sim-palette-container">
            <label className="form-label user-settings-form-label input-palm-types-label">
              {t("InputPalmTypesPalette")}
            </label>
            <PaletteTable
              rows={Object.keys(palmTypes).map((k) => {
                return {
                  key: k,
                  label: t(k),
                  selected:
                    (props.inputPalmTypesPalette &&
                      props.inputPalmTypesPalette[k]?.selected) ||
                    palmTypes[k].selected,
                  unselected:
                    (props.inputPalmTypesPalette &&
                      props.inputPalmTypesPalette[k]?.unselected) ||
                    palmTypes[k].unselected,
                };
              })}
              onUnselectedChange={handleUnselectedInputPalmPaletteChange}
              onSelectedChange={handleSelectedInputPalmPaletteChange}
            />
            <button
              className="wizard-button wizard-button-submit"
              onClick={resetInputPalmTypesPalette}
            >
              {t("Reset")}
            </button>
          </div>
          <PaletteDropdown
            label={t("InputElevationPalette")}
            name={"inputElevationPalette"}
            value={props.inputElevationPalette || palettes.defaults.elevation}
            options={Object.keys(palettes.colors.elevation).map((c) => ({
              value: c,
              label: t(c),
              colors: palettes.colors.elevation[c],
            }))}
            onChange={props.handleChange}
          />
        </div>
        <div className="sim-palette-section">
          <FormSectionSeparator label={t("SimulationPalettes")} />
          <div className="sim-palette-container">
            {/* <PaletteDropdown
              label={t("SimulationTemperaturePalette")}
              name={"simulationTemperaturePalette"}
              value={
                props.simulationTemperaturePalette ||
                palettes.defaults.temperatureResults
              }
              options={Object.keys(palettes.colors.temperatureResults).map(
                (c) => ({
                  value: c,
                  label: t(c),
                  colors: palettes.colors.temperatureResults[c],
                })
              )}
              onChange={props.handleChange}
            />
            <PaletteDropdown
              label={t("SimulationWindPalette")}
              name={"simulationWindPalette"}
              value={
                props.simulationWindPalette || palettes.defaults.windResults
              }
              options={Object.keys(palettes.colors.windResults).map((c) => ({
                value: c,
                label: t(c),
                colors: palettes.colors.windResults[c],
              }))}
              onChange={props.handleChange}
            />
            <PaletteDropdown
              label={t("SimulationColdAirDrainagePalette")}
              name={"simulationColdAirDrainagePalette"}
              value={
                props.simulationColdAirDrainagePalette ||
                palettes.defaults.coldAirDrainageResults
              }
              options={Object.keys(palettes.colors.coldAirDrainageResults).map(
                (c) => ({
                  value: c,
                  label: t(c),
                  colors: palettes.colors.coldAirDrainageResults[c],
                })
              )}
              onChange={props.handleChange}
            />
            <PaletteDropdown
              label={t("SimulationRadiationPalette")}
              name={"simulationRadiationPalette"}
              value={
                props.simulationRadiationPalette || palettes.defaults.radiation
              }
              options={Object.keys(palettes.colors.radiation).map((c) => ({
                value: c,
                label: t(c),
                colors: palettes.colors.radiation[c],
              }))}
              onChange={props.handleChange}
            />
            <PaletteDropdown
              label={t("SimulationHumidityPalette")}
              name={"simulationHumidityPalette"}
              value={
                props.simulationHumidityPalette ||
                palettes.defaults.humidityResuts
              }
              options={Object.keys(palettes.colors.humidityResuts).map((c) => ({
                value: c,
                label: t(c),
                colors: palettes.colors.humidityResuts[c],
              }))}
              onChange={props.handleChange}
            />
            <PaletteDropdown
              label={t("SimulationHeatFluxesPalette")}
              name={"simulationHeatFluxesPalette"}
              value={
                props.simulationHeatFluxesPalette ||
                palettes.defaults.heatFluxesResults
              }
              options={Object.keys(palettes.colors.heatFluxesResults).map(
                (c) => ({
                  value: c,
                  label: t(c),
                  colors: palettes.colors.heatFluxesResults[c],
                })
              )}
              onChange={props.handleChange}
              menuPlacement={"top"}
            />
            <PaletteDropdown
              label={t("SimulationDifferencesPalette")}
              name={"simulationDifferencesPalette"}
              value={
                props.simulationDifferencesPalette ||
                palettes.defaults.differencePlots
              }
              options={Object.keys(palettes.colors.differencePlots).map(
                (c) => ({
                  value: c,
                  label: t(c),
                  colors: palettes.colors.differencePlots[c],
                })
              )}
              onChange={props.handleChange}
              menuPlacement={"top"}
            /> */}
            <PaletteDropdown
              label={t("SimulationChartPalette")}
              name={"simulationChartPalette"}
              value={props.simulationChartPalette || palettes.defaults.charts}
              options={Object.keys(palettes.colors.charts).map((c) => ({
                value: c,
                label: t(c),
                colors: palettes.colors.charts[c],
              }))}
              onChange={props.handleChange}
              menuPlacement={"top"}
            />
          </div>
          <button
            className="palette-reset_btn wizard-button wizard-button-submit"
            onClick={props.reset}
          >
            {t("Reset")}
          </button>
        </div>
      </div>
    </>
  );
};

export default VisualizationPresets;
