import React from "react";
import { FormSettingsSeparator } from "../../../../Components/Common/Form/FormSeparator/index";
import { SummaryLabel } from "../../ExpertUtils/ExpertComponent";
import { changeExpertPage } from "../../../../redux/actions/expertActions";
import { useSelector, useDispatch } from "react-redux";

const Summary = () => {
  const projects = useSelector((state) => state.projects);
  const expertJson = useSelector((state) => state.expert?.expertJson);
  const rasterParameters = useSelector(
    (state) => state.projects?.currentCase?.rasterParameters
  );
  const rasterParamsOnMount = useSelector(
    (state) => state.rasterArea?.currentCase?.rasterParameters
  );

  const childRasterParameters = useSelector(
    (state) => state.projects?.currentCase?.childRasterParameters
  );

  const childRasterParamsOnMount = useSelector(
    (state) => state.rasterArea?.currentCase?.childRasterParameters
  );

  const dispatch = useDispatch();

  return (
    <>
      <FormSettingsSeparator style={{ fontSize: "17px" }} label={"Grid"} />
      <SummaryLabel
        label={"Domain Type"}
        value={
          projects.currentCase?.childRasterParameters ||
          childRasterParamsOnMount
            ? "Nested Domain"
            : "Single Domain"
        }
        refText={"Domain Map"}
        route={`/projects/${projects.loadedProject?.id}/cases/${projects.currentCase?.id}/domain`}
      />
      {!projects.currentCase?.childRasterParameters &&
      !childRasterParamsOnMount ? (
        <>
          <SummaryLabel
            label={"Grid Width"}
            value={
              rasterParameters
                ? rasterParameters?.resolution
                : rasterParamsOnMount?.resolution
                ? rasterParamsOnMount?.resolution
                : "NaN"
            }
            refText={"Domain Map"}
            route={`/projects/${projects.loadedProject?.id}/cases/${projects.currentCase?.id}/domain`}
          />
          <SummaryLabel
            label={"Grid Points in x-Direction (nx)"}
            value={
              rasterParameters
                ? (rasterParameters.x_max - rasterParameters.x_min) /
                  rasterParameters.resolution
                : (rasterParamsOnMount?.x_max - rasterParamsOnMount?.x_min) /
                  rasterParamsOnMount?.resolution
            }
            refText={"Domain Map"}
            route={`/projects/${projects.loadedProject?.id}/cases/${projects.currentCase?.id}/domain`}
          />
          <SummaryLabel
            label={"Grid Points in y-Direction (ny)"}
            value={
              rasterParameters
                ? (rasterParameters.y_max - rasterParameters.y_min) /
                  rasterParameters.resolution
                : (rasterParamsOnMount?.y_max - rasterParamsOnMount?.y_min) /
                  rasterParamsOnMount?.resolution
            }
            refText={"Domain Map"}
            route={`/projects/${projects.loadedProject?.id}/cases/${projects.currentCase?.id}/domain`}
          />
          <SummaryLabel
            label={"Grid Points in z-Direction Type:"}
            value={
              expertJson?.grid?.single_domain?.grid.nz_type === 1
                ? "Autodetection"
                : "User-defined"
            }
            refText={"Grid Settings"}
            onClick={() =>
              dispatch(
                changeExpertPage({
                  currentExpertSection: "Grid",
                  currentExpertPage: "Single Domain",
                })
              )
            }
          />
        </>
      ) : (
        <>
          <SummaryLabel
            label={"Grid Width (Parent)"}
            value={
              rasterParameters
                ? rasterParameters?.resolution
                : rasterParamsOnMount?.resolution
            }
            refText={"Domain Map"}
            route={`/projects/${projects.loadedProject?.id}/cases/${projects.currentCase?.id}/domain`}
          />
          <SummaryLabel
            label={"Grid Points in x-Direction (nx) (Parent)"}
            value={
              rasterParameters
                ? (rasterParameters.x_max - rasterParameters.x_min) /
                  rasterParameters.resolution
                : (rasterParamsOnMount?.x_max - rasterParamsOnMount?.x_min) /
                  rasterParamsOnMount?.resolution
            }
            refText={"Domain Map"}
            route={`/projects/${projects.loadedProject?.id}/cases/${projects.currentCase?.id}/domain`}
          />
          <SummaryLabel
            label={"Grid Points in y-Direction (ny) (Parent)"}
            value={
              rasterParameters
                ? (rasterParameters.y_max - rasterParameters.y_min) /
                  rasterParameters.resolution
                : (rasterParamsOnMount?.y_max - rasterParamsOnMount?.y_min) /
                  rasterParamsOnMount?.resolution
            }
            refText={"Domain Map"}
            route={`/projects/${projects.loadedProject?.id}/cases/${projects.currentCase?.id}/domain`}
          />
          <SummaryLabel
            label={"Grid Points in z-Direction Type (Parent)"}
            value={
              expertJson.grid.nested_domain.grid_parent.nz_type === 1
                ? "Autodetection"
                : "User-defined"
            }
            refText={"Grid Settings"}
            onClick={() =>
              dispatch(
                changeExpertPage({
                  currentExpertSection: "Grid",
                  currentExpertPage: "Nested Domain",
                })
              )
            }
          />

          <SummaryLabel
            label={"Grid Width (Child)"}
            value={
              childRasterParameters
                ? childRasterParameters?.resolution
                : childRasterParamsOnMount?.resolution
            }
            refText={"Domain Map"}
            route={`/projects/${projects.loadedProject?.id}/cases/${projects.currentCase?.id}/domain`}
          />
          <SummaryLabel
            label={"Grid Points in x-Direction (nx) (Child)"}
            value={
              childRasterParameters
                ? (childRasterParameters?.x_max -
                    childRasterParameters?.x_min) /
                  childRasterParameters?.resolution
                : (childRasterParamsOnMount?.x_max -
                    childRasterParamsOnMount?.x_min) /
                  childRasterParamsOnMount?.resolution
            }
            refText={"Domain Map"}
            route={`/projects/${projects.loadedProject?.id}/cases/${projects.currentCase?.id}/domain`}
          />
          <SummaryLabel
            label={"Grid Points in y-Direction (ny) (Child)"}
            value={
              childRasterParameters
                ? (childRasterParameters?.y_max -
                    childRasterParameters?.y_min) /
                  childRasterParameters?.resolution
                : (childRasterParamsOnMount?.y_max -
                    childRasterParamsOnMount?.y_min) /
                  childRasterParamsOnMount?.resolution
            }
            refText={"Domain Map"}
            route={`/projects/${projects.loadedProject?.id}/cases/${projects.currentCase?.id}/domain`}
          />
          <SummaryLabel
            label={"Grid Points in z-Direction Type (Child)"}
            value={
              expertJson?.grid.nested_domain?.grid_child?.nz_type === 1
                ? "Autodetection"
                : "User-defined"
            }
            refText={"Grid Settings"}
            onClick={() =>
              dispatch(
                changeExpertPage({
                  currentExpertSection: "Grid",
                  currentExpertPage: "Nested Domain",
                })
              )
            }
          />
        </>
      )}
      <FormSettingsSeparator
        style={{ fontSize: "17px" }}
        label={"Initialization & Runtime"}
      />
      <SummaryLabel
        label={"Endtime"}
        value={`${expertJson?.initialization?.runtime_template?.run_steering?.end_time} [s]`}
        refText={"Runtime Settings"}
        onClick={() =>
          dispatch(
            changeExpertPage({
              currentExpertSection: "Runtime Initialization",
              currentExpertPage: "Runtime Template",
            })
          )
        }
      />
      <SummaryLabel
        label={"Template"}
        value={
          expertJson?.initialization?.runtime_template?.template === 1
            ? "Constant Profiles"
            : expertJson?.initialization?.runtime_template?.template === 2
            ? "Dynamic Driver Upload"
            : "Dynamic Driver Windprofile"
        }
        refText={"Initialization Settings"}
        onClick={() =>
          dispatch(
            changeExpertPage({
              currentExpertSection: "Runtime Initialization",
              currentExpertPage:
                expertJson?.initialization?.runtime_template?.template === 1
                  ? "Constant Profiles"
                  : expertJson?.initialization?.runtime_template?.template === 2
                  ? "Dynamic Driver Upload"
                  : "Dynamic Driver Windprofile",
            })
          )
        }
      />
      <FormSettingsSeparator style={{ fontSize: "17px" }} label={"Modules"} />
      <SummaryLabel
        label={"Biometeorology"}
        value={
          expertJson.modules.biometeorology.isActive
            ? "switched on"
            : "switched off"
        }
        refText={"Module Settings"}
        onClick={() =>
          dispatch(
            changeExpertPage({
              currentExpertSection: "Modules",
              currentExpertPage: "Biometerology",
            })
          )
        }
      />
      <SummaryLabel
        label={"Indoor Climate"}
        value={
          expertJson.modules.indoor_climate.isActive
            ? "switched on"
            : "switched off"
        }
        refText={"Module Settings"}
        onClick={() =>
          dispatch(
            changeExpertPage({
              currentExpertSection: "Modules",
              currentExpertPage: "Indoor Climate",
            })
          )
        }
      />
      <SummaryLabel
        label={"Land Surface"}
        value={
          expertJson.modules.land_surface.isActive
            ? "switched on"
            : "switched off"
        }
        refText={"Module Settings"}
        onClick={() =>
          dispatch(
            changeExpertPage({
              currentExpertSection: "Modules",
              currentExpertPage: "Land Surface",
            })
          )
        }
      />
      <SummaryLabel
        label={"Plant Canopy"}
        value={
          expertJson.modules.plant_canopy.isActive
            ? "switched on"
            : "switched off"
        }
        refText={"Module Settings"}
        onClick={() =>
          dispatch(
            changeExpertPage({
              currentExpertSection: "Modules",
              currentExpertPage: "Plant Canopy",
            })
          )
        }
      />
      <SummaryLabel
        label={"Radiation"}
        value={
          expertJson.modules.radiation.isActive ? "switched on" : "switched off"
        }
        refText={"Module Settings"}
        onClick={() =>
          dispatch(
            changeExpertPage({
              currentExpertSection: "Modules",
              currentExpertPage: "Radiation",
            })
          )
        }
      />
      <SummaryLabel
        label={"Synthetic Turbulence"}
        value={
          expertJson.modules.synthetic_turbulence.isActive
            ? "switched on"
            : "switched off"
        }
        refText={"Module Settings"}
        onClick={() =>
          dispatch(
            changeExpertPage({
              currentExpertSection: "Modules",
              currentExpertPage: "Synthetic Turbulence",
            })
          )
        }
      />
      <SummaryLabel
        label={"Urban Surface"}
        value={
          expertJson.modules.urban_surface.isActive
            ? "switched on"
            : "switched off"
        }
        refText={"Module Settings"}
        onClick={() =>
          dispatch(
            changeExpertPage({
              currentExpertSection: "Modules",
              currentExpertPage: "Urban Surface",
            })
          )
        }
      />
      <FormSettingsSeparator style={{ fontSize: "17px" }} label={"Output"} />
      <SummaryLabel
        label={"2D Cross Section"}
        value={`${
          expertJson?.output?.dxy_cross_section?.averaged?.dxy_av_isActive &&
          expertJson?.output?.dxy_cross_section?.instantaneous
            ?.dxy_inst_isActive
            ? "averaged instantaneous"
            : expertJson?.output?.dxy_cross_section?.averaged?.dxy_av_isActive
            ? "averaged"
            : expertJson?.output?.dxy_cross_section?.instantaneous
                ?.dxy_inst_isActive
            ? "instantaneous"
            : "switched off"
        }`}
        refText={"Output Settings"}
        onClick={() =>
          dispatch(
            changeExpertPage({
              currentExpertSection: "Output",
              currentExpertPage: "2D Cross Section (xy)",
            })
          )
        }
      />
      <SummaryLabel
        label={"Mask: Terrain Following"}
        value={`${
          expertJson?.output?.mask_tf?.averaged?.masktf_av_isActive &&
          expertJson?.output?.mask_tf?.instantaneous?.masktf_inst_isActive
            ? "averaged instantaneous"
            : expertJson?.output?.mask_tf?.averaged?.masktf_av_isActive
            ? "averaged"
            : expertJson?.output?.mask_tf?.instantaneous?.masktf_inst_isActive
            ? "instantaneous"
            : "switched off"
        }`}
        refText={"Output Settings"}
        onClick={() =>
          dispatch(
            changeExpertPage({
              currentExpertSection: "Output",
              currentExpertPage: "Terrain Following Mask",
            })
          )
        }
      />
      <SummaryLabel
        label={"3D Volume"}
        value={`${
          expertJson?.output?.volume3d?.averaged?.vol3d_av_isActive &&
          expertJson?.output?.volume3d?.instantaneous?.vol3d_inst_isActive
            ? "averaged instantaneous"
            : expertJson?.output?.volume3d?.averaged?.vol3d_av_isActive
            ? "averaged"
            : expertJson?.output?.volume3d?.instantaneous?.vol3d_inst_isActive
            ? "instantaneous"
            : "switched off"
        }`}
        refText={"Output Settings"}
        onClick={() =>
          dispatch(
            changeExpertPage({
              currentExpertSection: "Output",
              currentExpertPage: "3D Volume",
            })
          )
        }
      />
      <SummaryLabel
        label={"POI: Timeseries"}
        value={"switched off"}
        refText={"switched off"}
      />
      <SummaryLabel
        label={"POI: Vertical Profile"}
        value={"switched off"}
        refText={"switched off"}
      />
    </>
  );
};

export default Summary;
