import { types } from "../types/expert";
import {
  biometeorologyTableData,
  indoorClimateTableData,
  landSurfaceSoilTableData,
  landSurfaceCheckboxTableData,
  plantCanopyTableData,
  radiationTableData,
  generalSettingsTableData,
  getCrossSectionHeightLevelOptions,
} from "../../containers/Expert/expertConst";
import { cloneDeep } from "lodash";

const initialState = {
  currentExpertSection: "Summary",
  currentExpertPage: "",
  expertTempKey: null,
  expertTempValue: null,
  rootFractionSum: 1,
  crossSectionHeightLevelOptions: getCrossSectionHeightLevelOptions(),
  expertJson: {
    grid: {
      single_domain: {
        isActive: false,
        grid: {
          dxdydz_const: "readonly",
          nx: "readonly",
          ny: "readonly",
          nz_type: 1,
          nz_minheight: 1500,
          nz_userdefined: 200,
          dz_stretch_isActive: false,
          dz_stretch_level: 500,
          dz_max: 999,
          dz_stretch_factor: 1.08,
        },
        topography: "read_from_file",
        runtime_processor: {
          processor_mode: 1,
          cpu_cores_available: "readonly",
          cpu_cores_used: "readonly",
          npex: 2,
          npey: 2,
        },
      },
      nested_domain: {
        isActive: false,
        grid_parent: {
          dxdydz_const: "readonly",
          nx: "readonly",
          ny: "readonly",
          nz_type: 1,
          nz_minheight: 1500,
          nz_userdefined: 200,
          dz_stretch_isActive: false,
          dz_stretch_level: 500,
          dz_max: 999,
          dz_stretch_factor: 1.08,
        },
        grid_child: {
          dxdydz_const: "readonly",
          nx: "readonly",
          ny: "readonly",
          nz_type: 1,
          nz_minheight: 1500,
          nz_userdefined: 200,
        },
        topography: "read_from_file",
        runtime_processor_general: {
          processor_mode: 1,
          cpu_cores_available: "readonly",
        },
        runtime_processor_parent: {
          par_cpu_cores_used: "readonly",
          par_npex: 2,
          par_npey: 2,
        },
        runtime_processor_child: {
          chi_cpu_cores_used: "readonly",
          chi_npex: 2,
          chi_npey: 2,
        },
        initialization_child: {
          topography: "interpolate from parent",
        },
        nesting: {
          isActive: true,
          switch_off_module: false,
          nesting_mode: "one way",
          nesting_bounds: "3d-nested",
          nesting_datatransfer_mode: "mixed",
          homogeneous_initialization_child: false,
          anterpolation_starting_height: 0,
          anterpolation_buffer_width: 2,
        },
      },
    },

    initialization: {
      runtime_template: {
        run_steering: {
          origin_data_time: "2019-06-21 12:00:00",
          hours_from_utc: "+02",
          end_time: 3600,
          dt_max: 3600,
          create_disturbances: true,
          dt_disturb: 60,
          disturbance_amplitude: 0.25,
          disturbance_energy_limit: 0.01,
          template_select: 0,
        },
        template: 1,
      },

      template_constant_profiles: {
        isActive: true,
        initialization: {
          neutral: false,
          pt_surface: 293.15,
          humidity: true,
          q_surface: 0.007709,
          check_realistic_q: true,
          calc_soil_moisture_during_spinup: false,
          use_geostrophic_wind: true,
          ug_surface: 0.0,
          vg_surface: 0.0,
          spinup_time: 0.0,
          dt_spinup: 5.0,
          spinup_pt_amplitude: 0.0,
          spinup_pt_mean: 293.15,
          pt_vertical_lvlsnbr: 2,
          pt_vertical: [
            {
              pt_vertical_gradiant: 0.0,
              pt_vertical_gradiant_level: 0.0,
            },
            {
              pt_vertical_gradiant: 0.0,
              pt_vertical_gradiant_level: 0.0,
            },
          ],
          q_vertical_lvlsnbr: 2,
          q_vertical: [
            {
              q_vertical_gradiant: 0,
              q_vertical_gradiant_level: 0,
            },
            {
              q_vertical_gradiant: 0,
              q_vertical_gradiant_level: 0,
            },
          ],
          ug_vertical_lvlsnbr: 2,
          ug_vertical: [
            {
              ug_vertical_gradiant: 0,
              ug_vertical_gradiant_level: 0,
            },
            {
              ug_vertical_gradiant: 0,
              ug_vertical_gradiant_level: 0,
            },
          ],
          vg_vertical_lvlsnbr: 2,
          vg_vertical: [
            {
              vg_vertical_gradiant: 0,
              vg_vertical_gradiant_level: 0,
            },
            {
              vg_vertical_gradiant: 0,
              vg_vertical_gradiant_level: 0,
            },
          ],
          uv_heights_lvlsnbr: 2,
          uv: [
            {
              u_profile: 0,
              v_profile: 0,
              uv_heights: 0,
            },

            {
              u_profile: 0,
              v_profile: 0,
              uv_heights: 0,
            },
          ],
        },
        boundary_conditions: {
          bc_lr: "cyclic",
          bc_ns: "cyclic",
          bc_e_b: "neumann",
          bc_p_b: "dirichlet",
          bc_pt_b: "dirichlet",
          bc_q_b: "dirichlet",
          bc_uv_b: "dirichlet",
          bc_p_t: "dirichlet",
          bc_pt_t: "initial_gradient",
          bc_q_t: "dirichlet",
          bc_uv_t: "dirichlet",
        },
        numerics: {
          rayleigh_dampening_factor: 0.0,
          rayleigh_dampening_height: 0,
          psolver: "poisfft",
          fft_method: "temperton-algorithm",
          cycle_mg: "w",
          mg_cycles: 4,
        },
        physics: {
          omega: 0.0000729212,
          latitude: 0,
          longitude: 0,
          use_fixed_date: false,
          use_fixed_time: false,
        },
      },

      template_dynamic_upload: {
        isActive: false,
        url_dynamic_driver: "",
        name_dynamic_driver: "no files selected",
        mode: {
          initializing_actions: "inifor",
        },
        initialization: {
          neutral: false,
          pt_surface: 293.15,
          humidity: true,
          q_surface: 0.007709,
          check_realistic_q: true,
          calc_soil_moisture_during_spinup: false,
          use_geostrophic_wind: true,
          ug_surface: 0.0,
          vg_surface: 0.0,
          spinup_time: 0.0,
          dt_spinup: 5.0,
          spinup_pt_amplitude: 0.0,
          spinup_pt_mean: 293.15,
          pt_vertical_lvlsnbr: 2,
          pt_vertical: [
            { pt_vertical_gradiant: 0, pt_vertical_gradiant_level: 0 },
            {
              pt_vertical_gradiant: 0,
              pt_vertical_gradiant_level: 0,
            },
          ],
          q_vertical_lvlsnbr: 2,
          q_vertical: [
            { q_vertical_gradiant: 0, q_vertical_gradiant_level: 0 },
            { q_vertical_gradiant: 0, q_vertical_gradiant_level: 0 },
          ],
          ug_vertical_lvlsnbr: 2,
          ug_vertical: [
            {
              ug_vertical_gradiant: 0,
              ug_vertical_gradiant_level: 0,
            },
            {
              ug_vertical_gradiant: 0,
              ug_vertical_gradiant_level: 0,
            },
          ],
          vg_vertical_lvlsnbr: 2,
          vg_vertical: [
            {
              vg_vertical_gradiant: 0,
              vg_vertical_gradiant_level: 0,
            },
            {
              vg_vertical_gradiant: 0,
              vg_vertical_gradiant_level: 0,
            },
          ],
        },

        boundary_conditions: {
          bc_e_b: "neumann",
          bc_p_b: "dirichlet",
          bc_pt_b: "dirichlet",
          bc_q_b: "dirichlet",
          bc_uv_b: "dirichlet",
          bc_p_t: "dirichlet",
          bc_pt_t: "initial_gradient",
          bc_q_t: "dirichlet",
          bc_uv_t: "dirichlet",
        },
        numerics: {
          rayleigh_dampening_factor: 0.0,
          rayleigh_dampening_height: 0,
          psolver: "poisfft",
          cycle_mg: "w",
          mg_cycles: 4,
        },
        physics: {
          omega: 0.0000729212,
          latitude: 0,
          longitude: 0,
        },
      },

      template_dynamic_windprofile: {
        isActive: false,
        mode: {
          initializing_actions: "inifor",
          origin_data_time: "2019-06-21 12:00:00",
          hours_from_utc: "+02",
        },
        initialization: {
          neutral: true,
          humidity: false,
        },
        boundary_conditions: {
          bc_e_b: "neumann",
          bc_p_b: "dirichlet",
          bc_pt_b: "dirichlet",
          bc_q_b: "dirichlet",
          bc_uv_b: "dirichlet",
          bc_p_t: "dirichlet",
          bc_pt_t: "initial_gradient",
          bc_q_t: "dirichlet",
          bc_uv_t: "dirichlet",
        },
        numerics: {
          rayleigh_dampening_factor: 0.0,
          rayleigh_dampening_height: 0,
          psolver: "poisfft",
          cycle_mg: "w",
          mg_cycles: 4,
        },
        physics: {
          omega: 0,
          latitude: 0,
          longitude: 0,
        },
      },
    },

    modules: {
      biometeorology: {
        isActive: false,
        parameters: {
          thermal_comfort: false,
        },
        output_quantities: biometeorologyTableData.rowData,
      },

      indoor_climate: {
        isActive: false,
        parameters: {
          switch_off_module: true,
          indoor_during_spinup: false,
          initial_indoor_temperature: 293.15,
        },
        output_quantities: indoorClimateTableData.rowData,
      },

      land_surface: {
        isActive: false,
        parameters: {
          switch_off_module: true,
          skip_time_do_lsm: 0,
          aero_resist_kray: true,
          constant_roughness: true,
          deep_soil_temperature: 9999999.9,
          conserve_water_content: true,
          min_soil_resistance: 50.0,
          use_default_root_fraction: false,
          soil_layers_nbr: 8,
          soil_params: landSurfaceSoilTableData.rowData,
        },
        output_quantities: landSurfaceCheckboxTableData.rowData,
      },

      plant_canopy: {
        isActive: false,
        parameters: {
          switch_off_module: true,
          canopy_mode: "read_from_file",
          canopy_drag_coeff: 0.15,
          cthf: 0,
          plant_canopy_transpiration: true,
        },
        output_quantities: plantCanopyTableData.rowData,
      },

      radiation: {
        isActive: false,
        parameters: {
          switch_off_module: true,
          radiation_scheme: "clear-sky",
          dt_radiation: 60,
          skip_time_do_radiation: 0,
          lw_radiation: true,
          sw_radiation: true,
          constant_albedo: false,
          radiation_interactions_on: true,
          nrefsteps: 3,
          plant_lw_interact: true,
          mrt_nlevels_mode: 1,
          mrt_nlevels: 0,
          rad_mrt_skip_roof: true,
          unscheduled_radiation_calls: false,
        },
        output_quantities: radiationTableData.rowData,
      },

      synthetic_turbulence: {
        isActive: false,
        parameters: {
          switch_off_module: true,
          compute_velocity_seeds_local: false,
          dt_stg_adjust: 1800,
          dt_stg_call: 0,
        },
      },

      urban_surface: {
        isActive: false,
        parameters: {
          switch_off_module: true,
          soil_inner_temperature: 288,
          wall_inner_temperature: 295,
          window_inner_temperature: 295,
          roof_inner_temperature: 295,
          usm_wall_mod: false,
        },
      },
    },

    output: {
      general: {
        parameters: {
          netcdf_data_format: 4,
          skip_time_data_output: 0,
          averaging_interval: 3600,
        },
        output_quantities: generalSettingsTableData.rowData,
      },

      dxy_cross_section: {
        parameters: {
          dxy_section_xy: [],
          dxy_section_xy_child: [],
        },
        averaged: {
          dxy_av_isActive: false,
        },
        instantaneous: {
          dxy_inst_isActive: false,
          dxy_dt_do2d_xy: 3600,
        },
      },

      mask_tf: {
        parameters: {
          masktf_vlimit: 20,
          masktf_vlimit_child: 20,
        },
        averaged: {
          masktf_av_isActive: false,
        },
        instantaneous: {
          masktf_inst_isActive: false,
          masktf_dt_domask: 3600,
        },
      },

      volume3d: {
        parameters: {
          vol3d_vlimit: 20,
          vol3d_vlimit_child: 20,
        },
        averaged: {
          vol3d_av_isActive: false,
        },
        instantaneous: {
          vol3d_inst_isActive: false,
          vol3d_dt_do3d: 3600,
        },
      },

      poi_vp: {
        parameters: { poivp_poiselect: [] },
        averaged: { poivp_av_isActive: false },
        instantaneous: { poivp_inst_isActive: false, poivp_dt_domask: 3600 },
      },

      poi_ts: {
        parameters: { poits_poiselect: [] },
        averaged: { poits_av_isActive: false },
        instantaneous: { poits_inst_isActive: false, poits_dt_domask: 3600 },
      },
    },
  },
};

const expertReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_EXPERT_PAGE: {
      return {
        ...state,
        currentExpertSection: action.payload.currentExpertSection,
        currentExpertPage: action.payload.currentExpertPage,
      };
    }
    case types.CHANGE_EXPERT_INPUT: {
      let { page, subpage, key, subkey, value, min, max, step } =
        action.payload;
      const expertJson = cloneDeep(state.expertJson);
      if (step) {
        const isDecimal = /^[0-9]*\.?[0-9]*$/;
        value = parseFloat(value);

        if (isDecimal.test(value) && value >= min && value <= max) {
          expertJson[page][subpage][key][subkey] = value;

          return {
            ...state,
            expertJson: expertJson,
            expertTempKey: null,
            expertTempValue: null,
          };
        } else {
          return {
            ...state,
            expertTempKey: null,
            expertTempValue: null,
          };
        }
      }

      if (value === "00") {
        value = 0;
      }

      if (!key) {
        expertJson[page][subpage][subkey] = value;

        return {
          ...state,
          expertJson: expertJson,
          expertTempKey: null,
          expertTempValue: null,
        };
      } else if (value === true || value === false) {
        expertJson[page][subpage][key][subkey] = value;

        return {
          ...state,
          expertJson: expertJson,
          expertTempKey: null,
          expertTempValue: null,
        };
      } else if (
        state.expertTempKey === subkey &&
        (state.expertTempValue < min || state.expertTempValue > max)
      ) {
        return {
          ...state,
          expertTempKey: null,
          expertTempValue: null,
        };
      } else {
        if (value < min || value > max) {
          return {
            ...state,
            expertTempKey: null,
            expertTempValue: null,
          };
        }

        if (value === "") {
          value = min;
        }
        expertJson[page][subpage][key][subkey] = value;

        return {
          ...state,
          expertJson: expertJson,
          expertTempKey: null,
          expertTempValue: null,
        };
      }
    }

    case types.CHANGE_EXPERT_TEMP_INPUT: {
      let { subkey, value } = action.payload;

      if (value === "00") {
        value = 0;
      }
      return {
        ...state,
        expertTempKey: subkey,
        expertTempValue: value,
      };
    }

    case types.CHANGE_EXPERT_TIME_INPUT: {
      let { page, subpage, key, subkey, value, max, time } = action.payload;
      const expertJson = cloneDeep(state.expertJson);

      let totalTime = Number(expertJson[page][subpage][key][subkey]);
      let totalHour = null;
      let totalMin = null;
      let totalSec = null;

      if (value.charAt(0) === "0") {
        value = value.slice(1);
      }

      if (value > max) {
        value = max;
      }

      if (time === "h") {
        totalHour = value;
        totalMin = Math.floor((totalTime % 3600) / 60);
        totalSec = Math.floor((totalTime % 3600) % 60);
      } else if (time === "min") {
        totalHour = Math.floor(totalTime / 3600);
        totalMin = value;
        totalSec = Math.floor((totalTime % 3600) % 60);
      } else {
        totalHour = Math.floor(totalTime / 3600);
        totalMin = Math.floor((totalTime % 3600) / 60);
        totalSec = Number(value);
      }

      totalTime = totalHour * 3600 + totalMin * 60 + totalSec;
      expertJson[page][subpage][key][subkey] = totalTime;
      return {
        ...state,
        expertTempKey: null,
        expertTempValue: null,
        expertJson: expertJson,
      };
    }

    case types.CHANGE_EXPERT_TEMPERATURE_INPUT: {
      let { page, subpage, key, subkey, value, max, temperature } =
        action.payload;
      value = parseFloat(value);
      const expertJson = cloneDeep(state.expertJson);
      let totalKelvin = null;
      // let totalCelsius = null;
      // let totalFarenheit = null;

      if (value > max) {
        value = max;
      }

      if (temperature === "Celsius") {
        totalKelvin = (value + 273.15).toFixed(2);
        // totalCelsius = value;
        // totalFarenheit = ((value * 9) / 5 + 32).toFixed(2);
      } else {
        totalKelvin = (((value - 32) * 5) / 9 + 273.15).toFixed(2);
        // totalCelsius = ((value - 32) * 5) / 9;
        // totalFarenheit = value;
      }
      totalKelvin = parseFloat(totalKelvin);
      expertJson[page][subpage][key][subkey] = totalKelvin;

      return {
        ...state,
        expertTempKey: null,
        expertTempValue: null,
        expertJson: expertJson,
      };
    }

    case types.CHANGE_EXPERT_DROPDOWN_INPUT: {
      let { page, subpage, key, subkey, value } = action.payload;
      const expertJson = cloneDeep(state.expertJson);

      if (value === "True") {
        value = true;
      } else {
        value = false;
      }

      if (!key) {
        expertJson[page][subpage][subkey] = value;
      } else {
        expertJson[page][subpage][key][subkey] = value;
      }

      return {
        ...state,
        expertJson: expertJson,
        expertTempKey: null,
        expertTempValue: null,
      };
    }

    case types.UPDATE_CHECKBOX_EXPERT_TABLE: {
      const { page, subpage, key, rowIndex, colId, checked } = action.payload;
      const expertJson = cloneDeep(state.expertJson);
      expertJson[page][subpage][key][rowIndex][colId] = checked;
      return {
        ...state,
        expertJson: expertJson,
      };
    }

    case types.UPDATE_LAND_SURFACE_TABLE: {
      const expertJson = cloneDeep(state.expertJson);

      const rowValue = action.payload.rowValue;
      const rowIndex = action.payload.rowIndex;
      const soil_params =
        expertJson.modules.land_surface.parameters.soil_params;

      soil_params[rowIndex] = rowValue;

      let rootFractionSum = 0;

      for (let index = 0; index < soil_params.length; index++) {
        rootFractionSum += parseFloat(soil_params[index].root_fraction);
      }

      expertJson.modules.land_surface.parameters.soil_params = soil_params;
      return {
        ...state,
        expertJson: expertJson,
        rootFractionSum: rootFractionSum,
      };
    }

    case types.UPDATE_LAND_SURFACE_TABLE_ROWS: {
      const expertJson = cloneDeep(state.expertJson);
      let soil_params = expertJson.modules.land_surface.parameters.soil_params;

      const newRowsCounter = action.payload;
      const oldRowsCounter = soil_params.length;

      if (newRowsCounter < 1 || newRowsCounter > 20 || newRowsCounter === "") {
        return {
          ...state,
          expertTempKey: null,
          expertTempValue: null,
        };
      }

      if (newRowsCounter > oldRowsCounter) {
        const rowsToAdd = newRowsCounter - oldRowsCounter;
        const soilParamsLength = soil_params.length + 1;

        for (let index = 0; index < rowsToAdd; index++) {
          soil_params.push({
            id_soil: soilParamsLength + index,
            dz_soil: 0,
            soil_temperature: 0,
            soil_moisture: 0,
            root_fraction: 0,
          });
        }
      } else {
        soil_params.length = newRowsCounter;
      }

      expertJson.modules.land_surface.parameters.soil_layers_nbr =
        newRowsCounter;
      expertJson.modules.land_surface.parameters.soil_params = soil_params;
      let rootFractionSum = null;

      for (let index = 0; index < soil_params.length; index++) {
        rootFractionSum += parseFloat(soil_params[index].root_fraction);
      }

      return {
        ...state,
        expertJson: expertJson,
        rootFractionSum: rootFractionSum,
        expertTempKey: null,
        expertTempValue: null,
      };
    }
    case types.CHANGE_EXPERT_DATE_TIME: {
      const { type, value, subpage, key } = action.payload;
      const expertJson = cloneDeep(state.expertJson);
      let dateTime = expertJson.initialization[subpage][key].origin_data_time;
      let date = dateTime.substring(0, 10);
      let hours = dateTime.substring(11);

      if (type === "date") {
        date = value;
      } else if (type === "hours") {
        hours = value;
      }

      expertJson.initialization[subpage][key].origin_data_time =
        date + " " + hours;

      return {
        ...state,
        expertJson: expertJson,
      };
    }

    case types.UPDATE_VECTOR_TABLE_ROWS: {
      const {
        template,
        subkey,
        firstRowKey,
        secondRowKey,
        thirdRowKey,
        newRowsCounter,
        maxRowsCounter,
        mainKey,
      } = action.payload;

      const expertJson = cloneDeep(state.expertJson);
      let vectorProps =
        expertJson.initialization[template].initialization[subkey];

      const oldRowsCounter = vectorProps.length;

      if (
        newRowsCounter < 1 ||
        newRowsCounter > maxRowsCounter ||
        newRowsCounter === ""
      ) {
        return {
          ...state,
          expertTempKey: null,
          expertTempValue: null,
          expertJson: expertJson,
        };
      }
      if (newRowsCounter > oldRowsCounter && !thirdRowKey) {
        const rowsToAdd = newRowsCounter - oldRowsCounter;
        //const soilParamsLength = oldRowsCounter.length + 1;

        for (let index = 0; index < rowsToAdd; index++) {
          vectorProps.push({
            //id_soil: soilParamsLength + index,
            [firstRowKey]: 0,
            [secondRowKey]: 0,
          });
        }
      } else if (thirdRowKey && newRowsCounter > oldRowsCounter) {
        const rowsToAdd = newRowsCounter - oldRowsCounter;
        //const soilParamsLength = oldRowsCounter.length + 1;

        for (let index = 0; index < rowsToAdd; index++) {
          vectorProps.push({
            //id_soil: soilParamsLength + index,
            [firstRowKey]: 0,
            [secondRowKey]: 0,
            [thirdRowKey]: 0,
          });
        }
      } else {
        vectorProps.length = newRowsCounter;
      }

      expertJson.initialization[template].initialization[subkey] = vectorProps;
      expertJson.initialization[template].initialization[mainKey] =
        newRowsCounter;

      return {
        ...state,
        expertJson: expertJson,
        expertTempKey: null,
        expertTempValue: null,
      };
    }

    case types.UPDATE_VECTOR_TABLE: {
      const expertJson = cloneDeep(state.expertJson);
      const { templateName, parentField, rowValue, rowIndex } = action.payload;

      expertJson.initialization[templateName].initialization[parentField][
        rowIndex
      ] = rowValue;
      return {
        ...state,
        expertJson: expertJson,
      };
    }

    case types.CHANGE_EXPERT_RUNTIME_TEMPLATE: {
      const expertJson = cloneDeep(state.expertJson);
      const { page, subpage, subkey, value } = action.payload;

      expertJson[page][subpage][subkey] = value;
      if (value === 1) {
        expertJson[page].template_constant_profiles.isActive = true;
        expertJson[page].template_dynamic_upload.isActive = false;
        expertJson[page].template_dynamic_windprofile.isActive = false;
      } else if (value === 2) {
        expertJson[page].template_constant_profiles.isActive = false;
        expertJson[page].template_dynamic_upload.isActive = true;
        expertJson[page].template_dynamic_windprofile.isActive = false;
      } else {
        expertJson[page].template_constant_profiles.isActive = false;
        expertJson[page].template_dynamic_upload.isActive = false;
        expertJson[page].template_dynamic_windprofile.isActive = true;
      }

      return {
        ...state,
        expertJson: expertJson,
        expertTempKey: null,
        expertTempValue: null,
      };
    }

    case types.CHANGE_EXPERT_RADIATION_SCHEME: {
      const expertJson = cloneDeep(state.expertJson);
      const { value } = action.payload;

      expertJson.modules.radiation.parameters.radiation_scheme = value;

      for (let index = 5; index < 13; index++) {
        expertJson.modules.radiation.output_quantities[index].d_xy = false;
        expertJson.modules.radiation.output_quantities[index].mask_tf = false;
        expertJson.modules.radiation.output_quantities[index].D_3 = false;
      }

      return {
        ...state,
        expertJson: expertJson,
        expertTempKey: null,
        expertTempValue: null,
      };
    }

    case types.LOAD_EXPERT_JSON: {
      const expertJson = action.payload;
      return {
        ...state,
        expertJson: expertJson,
      };
    }

    case types.RESET_EXPERT_JSON: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

export default expertReducer;
