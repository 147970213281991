import React from "react";
import PoiDrawing from "./PoiDrawing";
import AreaDrawing from "./AreaDrawing";
import { useSelector } from "react-redux";

const PoiAreaDrawing = () => {
  const isPoiVisible = useSelector((state) => state.results.isPoiVisible);

  return (
    <>
      {isPoiVisible && (
        <>
          <PoiDrawing />
          <AreaDrawing />
        </>
      )}
    </>
  );
};

export default PoiAreaDrawing;
