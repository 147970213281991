import React from "react";
import Select from "react-select";
import ExpertCheckboxTable from "../../ExpertUtils/ExpertCheckboxTable/ExpertCheckboxTable";
import { ExpertHelpLabel } from "../../ExpertUtils/ExpertComponent";
import { FormSettingsSeparator } from "../../../../Components/Common/Form/FormSeparator/index";
import { biometeorologyTableData } from "../../expertConst";
import { selectSetupStyle } from "../../../../Components/Common/PaletteDropdown/index";
import { changeExpertInput } from "../../../../redux/actions/expertActions";
import { useSelector, useDispatch } from "react-redux";

const [page, subpage] = ["modules", "biometeorology"];

const Biometerology = (props) => {
  const biometeorology = useSelector(
    (state) => state.expert?.expertJson?.modules?.biometeorology
  );

  const dispatch = useDispatch();

  return (
    <>
      <ExpertHelpLabel label={"Activate Module"} helpLink={""} />
      <Select
        isSearchable={false}
        styles={selectSetupStyle}
        options={[
          {
            value: "True",
            label: "True",
          },
          { value: "False", label: "False" },
        ]}
        value={{
          label: !!biometeorology?.isActive ? "True" : "False",
          value: !!biometeorology?.isActive ? "True" : "False",
        }}
        onChange={(e) =>
          dispatch(
            changeExpertInput({
              page: page,
              subpage: subpage,
              key: "",
              subkey: "isActive",
              value: e.value === "True" ? true : false,
            })
          )
        }
      />
      {biometeorology?.isActive && (
        <>
          <FormSettingsSeparator
            style={{ fontSize: "17px" }}
            label={"Output Quantities"}
          />
          <div
            className={"table-animation"}
            style={{ marginTop: "12px", minWidth: "805px" }}
          >
            <ExpertCheckboxTable
              tableData={biometeorologyTableData}
              rowData={props.rowData}
            />
          </div>
        </>
      )}
    </>
  );
};

export default Biometerology;
