import React from "react";
import { HelpCircle } from "react-feather";
import "./Form.scss";

const NumericInput = (props) => {
  return (
    <div className="form-group">
      <label className="form-label">{props.label}</label>
      <div className={`input-wrapper`}>
        <input
          id={props.name}
          name={props.name}
          className={`form-input`}
          value={props.value ? props.value : ""}
          type={props.type ? props.type : "number"}
          min={props.min}
          max={props.max}
          maxLength={props.maxLength.toString()}
          onChange={props.onChange}
          autoComplete="off"
          step={props.step ? props.step : ""}
          disabled={props.disabled ? props.disabled : false}
          {...props}
        />
        {props.unit && <span className="form-unit">{`[${props.unit}]`}</span>}
      </div>
      <a
        className="help-icon-link"
        href={props.help}
        target="_blank"
        rel="noopener noreferrer"
      >
        <HelpCircle
          color="rgba(0, 0, 0, 0.75)"
          strokeWidth="2"
          size="26px"
          style={{
            alignSelf: "center",
            paddingLeft: "4px",
          }}
        />
      </a>
    </div>
  );
};
export { NumericInput };
