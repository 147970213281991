import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { matchPath, useLocation } from "react-router";
import * as actions from "../../redux/actions/projectsActions";
import { routes } from "../../routes/routes";

const CaseProvider = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const project = useSelector((state) => state.projects?.loadedProject);
  const isProjectLoading = useSelector((state) => state.projects?.isLoading);
  const currentCaseId = useSelector((state) => state.projects.currentCaseId);
  useEffect(() => {
    let projectId = matchPath(location.pathname, {
      path: [routes.projectHome, routes.projectFiles],
      strict: true,
      exact: true,
    })?.params?.projectId;
    const caseViewsParams = matchPath(location.pathname, {
      path: [
        routes.caseHome,
        routes.domain,
        routes.caseSetup,
        routes.caseSimulation,
        routes.caseResults,
        routes.caseFileBrowser,
        routes.thermalComfort,
        routes.windComfort,
        routes.chemistry,
        routes.expert,
      ],
      strict: true,
      exact: true,
    })?.params;
    const caseId = caseViewsParams?.caseId;
    if (!caseId) {
      dispatch(actions.setCurrentCase(null));
    } else {
      projectId = caseViewsParams?.projectId;
    }
    if (!projectId && project) {
      dispatch(actions.unloadProject());
    }
    if (projectId && !project && !isProjectLoading) {
      dispatch(actions.loadProject(projectId));
      return;
    }
    if (caseId !== currentCaseId && project) {
      dispatch(actions.setCurrentCase(caseId));
    }
  }, [location, project, currentCaseId]);
  return null;
};

export default CaseProvider;
