import React from "react";
import AccordionSection from "../AccordionSection";
import { FileListItem } from "./FileListItem";
import "./FileList.scss";

export const FileList = (props) => {
  return (
    <div className={`file-list${props.className ? ` ${props.className}` : ""}`}>
      <AccordionSection
        expanded={props.expanded}
        title={`${props.label} (${props.items ? props.items.length : 0})`}
      >
        {props.items
          ? props.items.map((f) => {
              return (
                <FileListItem
                  key={f.name}
                  label={f.name}
                  icon={f.icon}
                  readonly={f.readonly}
                  onDownload={() => props.onDownload(f)}
                  isDeleting={f.isDeleting}
                  onDelete={(e) => props.onDelete(f)}
                  viewable={props.viewable}
                  onView={() => props.onView(f)}
                  disabled={f.disabled}
                />
              );
            })
          : null}
      </AccordionSection>
    </div>
  );
};
