import React from "react";
import ExpertPageContainer from "./ExpertPage/ExpertPageContainer";
import ExpertMenu from "./ExpertMenu/ExpertMenu";
import { Loader } from "../../Components/Loader/index";
import { Info } from "../../../node_modules/react-feather/dist/index";
import {
  biometeorologyTableData,
  indoorClimateTableData,
  plantCanopyTableData,
  landSurfaceCheckboxTableData,
  generalSettingsTableData,
  radiationTableData,
} from "./expertConst";
import {
  loadExpertJson,
  resetExpertJson,
} from "../../redux/actions/expertActions";
import { getCaseSetup } from "../../api/simulationApi";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import "./Expert.scss";

class Expert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expertCheckboxRowData: null,
    };
  }

  async componentDidMount() {
    if (!this.props.currentCaseId) {
      return;
    }
    const expertSettings = await getCaseSetup(this.props.currentCaseId);
    if (typeof expertSettings === "string") {
      this.setState({
        expertCheckboxRowData: {
          biometeorologyRowData: biometeorologyTableData.rowData,
          indoorClimateRowData: indoorClimateTableData.rowData,
          landSurfaceRowData: landSurfaceCheckboxTableData.rowData,
          plantCanopyRowData: plantCanopyTableData.rowData,
          radiationRowData: radiationTableData.rowData,
          generalRowData: generalSettingsTableData.rowData,
        },
      });
      return;
    } else {
      const expertJson = expertSettings.setup.settings.expert_parameters;
      this.props.loadExpertJson(expertJson);

      this.setState({
        expertCheckboxRowData: {
          biometeorologyRowData:
            expertJson.modules.biometeorology.output_quantities,
          indoorClimateRowData:
            expertJson.modules.indoor_climate.output_quantities,
          landSurfaceRowData: expertJson.modules.land_surface.output_quantities,
          plantCanopyRowData: expertJson.modules.plant_canopy.output_quantities,
          radiationRowData: expertJson.modules.radiation.output_quantities,
          generalRowData: expertJson.output.general.output_quantities,
        },
      });
    }
  }

  async componentDidUpdate(prevProps) {
    if (
      this.props.currentCaseId &&
      prevProps.currentCaseId !== this.props.currentCaseId
    ) {
      const expertSettings = await getCaseSetup(this.props.currentCaseId);
      if (typeof expertSettings === "string") {
        this.setState({
          expertCheckboxRowData: {
            biometeorologyRowData: biometeorologyTableData.rowData,
            indoorClimateRowData: indoorClimateTableData.rowData,
            landSurfaceRowData: landSurfaceCheckboxTableData.rowData,
            plantCanopyRowData: plantCanopyTableData.rowData,
            radiationRowData: radiationTableData.rowData,
            generalRowData: generalSettingsTableData.rowData,
          },
        });
      } else {
        const expertJson = expertSettings.setup.settings.expert_parameters;
        this.props.loadExpertJson(expertJson);
        this.setState({
          expertCheckboxRowData: {
            biometeorologyRowData:
              expertJson.modules.biometeorology.output_quantities,
            indoorClimateRowData:
              expertJson.modules.indoor_climate.output_quantities,
            landSurfaceRowData:
              expertJson.modules.land_surface.output_quantities,
            plantCanopyRowData:
              expertJson.modules.plant_canopy.output_quantities,
            radiationRowData: expertJson.modules.radiation.output_quantities,
            generalRowData: expertJson.output.general.output_quantities,
          },
        });
      }
    }
  }

  componentWillUnmount() {
    this.props.resetExpertJson();
  }

  render() {
    const { t } = this.props;
    return (
      <>
        {this.props.currentCaseId && this.state.expertCheckboxRowData ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div style={{ width: "300px" }} />
            <div className="page setup-page" style={{ minWidth: 900 }}>
              <div
                style={{
                  fontSize: "13px",
                  border: "1px solid #757575",
                  borderRadius: "6px",
                  padding: "12px",
                  display: "flex",
                  alignItems: "center",
                  color: "##717171",
                }}
              >
                <div style={{ minWidth: "16px" }}>
                  <Info size="16px" strokeWidth="1.8px" />
                </div>
                <div style={{ marginRight: "12px" }} />
                {t("ExpertMessage")}
              </div>
              <ExpertPageContainer rowData={this.state.expertCheckboxRowData} />
            </div>
            <ExpertMenu />
          </div>
        ) : (
          <Loader />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentCaseId: state.projects?.currentCaseId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadExpertJson: (payload) => dispatch(loadExpertJson(payload)),
    resetExpertJson: (payload) => dispatch(resetExpertJson(payload)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Expert)
);
