import { call, takeEvery, put } from "redux-saga/effects";
import history from "./history";
import { types } from "../types/cases";
import { types as projectTypes } from "../types/projects";
import * as caseActions from "../actions/caseActions";
import * as projectsActions from "../actions/projectsActions";
import {
  geojsonCheckAsync,
  topographyConversionAsync,
} from "../../api/checkApi";
import * as caseApi from "../../api/caseApi";
import { uploadBlobToAzure } from "../../api/storageApi";

function* createCase(action) {
  try {
    switch (action.payload.type) {
      case 0: {
        const payload = {
          name: action.payload.name,
          applicationField: action.payload.applicationField,
          description: action.payload.description,
          originalGeojsonUrl: action.payload.originalGeojsonUrl || null,
          verifiedGeojsonUrl: action.payload.verifiedGeojsonUrl || null,
          originalTopographyUrl: action.payload.originalTopographyUrl || null,
          topographyUrl: action.payload.topographyUrl || null,
        };

        const response = yield call(
          caseApi.postQgisCase,
          action.payload.projectId,
          payload
        );
        yield put(caseActions.createCaseSuccess(response.id));
        yield put(projectsActions.loadProject(action.payload.projectId));
        break;
      }
      case 1: {
        const folderName = new Date().getTime().toString();
        const StaticDriverUrl = `${action.payload.projectId}/${folderName}/${action.payload.staticDriver.name}`;
        yield call(
          uploadBlobToAzure,
          StaticDriverUrl,
          action.payload.staticDriver
        );
        const payload = {
          name: action.payload.name,
          applicationField: action.payload.applicationField,
          description: action.payload.description,
          StaticDriverUrl,
        };

        const response = yield call(
          caseApi.postSdCase,
          action.payload.projectId,
          payload
        );
        yield put(projectsActions.loadProject(action.payload.projectId));
        yield put(caseActions.createCaseSuccess());
        yield call(
          history.push,
          `/projects/${action.payload.projectId}/cases/${response.id}`
        );
        break;
      }
      default:
        yield put(caseActions.createCaseSuccess());
        break;
    }
  } catch (error) {
    yield put(caseActions.createCaseFailure(error?.message ?? error));
  }
}
function* cloneCase(action) {
  try {
    switch (action.payload.type) {
      case 1: {
        if (action.payload.staticDriver) {
          const folderName = new Date().getTime().toString();
          const staticDriverUrl = `${action.payload.projectId}/${folderName}/${action.payload.staticDriver.name}`;
          yield call(
            uploadBlobToAzure,
            staticDriverUrl,
            action.payload.staticDriver
          );
          action.payload.staticDriverUrl = staticDriverUrl;
        }
        break;
      }
      default:
        break;
    }

    const response = yield call(
      action.payload.type === 0 ? caseApi.cloneQgisCase : caseApi.cloneSdCase,
      action.payload
    );
    yield put(projectsActions.loadProject(action.payload.projectId));
    yield put(caseActions.createCaseSuccess());
    history.push(`/projects/${action.payload.projectId}/cases/${response.id}`);
  } catch (error) {
    yield put(caseActions.createCaseFailure(error));
  }
}

function* updateCase(action) {
  try {
    switch (action.payload.type) {
      case 0: {
        const payload = {
          id: action.payload.id,
          name: action.payload.name,
          description: action.payload.description || null,
          originalGeojsonUrl: action.payload.originalGeojsonUrl || null,
          verifiedGeojsonUrl: action.payload.verifiedGeojsonUrl || null,
          originalTopographyUrl: action.payload.originalTopographyUrl || null,
          topographyUrl: action.payload.topographyUrl || null,
          applicationField: action.payload.applicationField,
        };

        yield call(caseApi.putQgisCase, payload);
        yield put(caseActions.updateCaseSuccess());
        yield put(projectsActions.loadProject(action.payload.projectId));
        history.push(
          `/projects/${action.payload.projectId}/cases/${action.payload.id}`
        );
        break;
      }
      case 1: {
        let staticDriverUrl = action.payload.staticDriverUrl;
        if (action.payload.staticDriver instanceof File) {
          staticDriverUrl = `${action.payload.projectId}/${action.payload.id}/${action.payload.staticDriver.name}`;
          yield call(
            uploadBlobToAzure,
            staticDriverUrl,
            action.payload.staticDriver
          );
        }

        const payload = {
          id: action.payload.id,
          name: action.payload.name,
          description: action.payload.description,
          staticDriverUrl: staticDriverUrl,
          applicationField: action.payload.applicationField,
        };

        yield call(caseApi.putSdCase, payload);
        yield put(projectsActions.loadProject(action.payload.projectId));
        yield put(caseActions.updateCaseSuccess());
        history.push(
          `/projects/${action.payload.projectId}/cases/${action.payload.id}`
        );
        break;
      }
      default:
        yield put(caseActions.updateCaseSuccess());
        break;
    }
  } catch (error) {
    yield put(caseActions.updateCaseFailure(error));
  }
}

function* deleteCase(action) {
  try {
    let caseId = yield call(caseApi.deleteCaseById, action.payload);
    yield put(caseActions.deleteCaseSuccess(caseId));
    yield put(projectsActions.loadProject(action.payload.projectId));
  } catch (error) {
    yield put(caseActions.deleteCaseFailure(error));
  }
}

function* setCurrentCase(action) {
  try {
    if (!action.payload) {
      yield put(projectsActions.setCurrentCaseSuccess(null));
      return;
    }
    const caseEntity = yield call(caseApi.getCase, action.payload);
    yield put(projectsActions.setCurrentCaseSuccess(caseEntity));
  } catch (error) {
    console.log(error);
  }
}

function* checkGeojson(action) {
  yield put(caseActions.uploadGeojson());
  const { geojsonUrl, geojson } = action.payload;
  yield call(uploadBlobToAzure, geojsonUrl, geojson);
  yield put(caseActions.uploadGeojsonSuccess(geojsonUrl));
  const geojsonCheckRequestId = yield call(geojsonCheckAsync, geojsonUrl);
  yield put(caseActions.setCheckGeojsonRequest(geojsonCheckRequestId));
}

function* convertTopography(action) {
  yield put(caseActions.uploadTopography());
  const { topographyUrl, topography } = action.payload;
  yield call(uploadBlobToAzure, topographyUrl, topography);
  yield put(caseActions.uploadTopographySuccess(topographyUrl));
  const topographyConversionRequestId = yield call(
    topographyConversionAsync,
    topographyUrl
  );
  yield put(
    caseActions.setConvertTopographyRequest(topographyConversionRequestId)
  );
}

export default function* createCaseSaga() {
  yield takeEvery(types.CREATE_CASE, createCase);
  yield takeEvery(types.CLONE_CASE, cloneCase);
  yield takeEvery(types.UPDATE_CASE, updateCase);
  yield takeEvery(types.DELETE_CASE, deleteCase);
  yield takeEvery(types.CASE_CHECK_GEOJSON, checkGeojson);
  yield takeEvery(types.CASE_CONVERT_TOPOGRAPHY, convertTopography);
  yield takeEvery(projectTypes.SET_CURRENT_CASE, setCurrentCase);
}
