import React from "react";
import { XCircle } from "react-feather";
import { useTranslation } from "react-i18next";
import "./ErrorSection.scss";

const ErrorSection = (props) => {
  const { t } = useTranslation();

  return props.errors && props.errors.length > 0 ? (
    <div className="error-section">
      {props.label && (
        <div className="error-section__heading">
          <label>{props.label}</label>
        </div>
      )}
      <ul className="error-section__list">
        {props.errors?.map((e, i) => {
          return <ErrorItem key={i} error={t(e)} />;
        })}
      </ul>
    </div>
  ) : null;
};

const ErrorItem = (props) => {
  return (
    <div className="error-li">
      <XCircle width="13" height="13" stroke="#fc3939e3" strokeWidth="2" />
      <p className="error-li__msg">{props.error}</p>
    </div>
  );
};

export default ErrorSection;
