import React from "react";
import { useTranslation } from "react-i18next";
import { AgGridReact } from "ag-grid-react";
import { debounce } from "lodash";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./index.scss";

const Table = (props) => {
  const { t } = useTranslation();
  return (
    <div className="table-container">
      <div className="table-flex__wrapper">
        <div className="table ag-theme-alpine">
          <AgGridReact
            {...props}
            suppressCellSelection={true}
            gridOptions={{ rowSelection: "single", rowHeight: 80 }}
            columnDefs={props.colDefs}
            rowData={props.rowData}
            immutableData={true}
            getRowNodeId={(data) => data.id}
            onGridReady={(params) => {
              params.api.sizeColumnsToFit();
              window.onresize = debounce(() => {
                params.api.sizeColumnsToFit();
              }, 200);
            }}
            rowHeight={60}
            onFirstDataRendered={(params) => params.api.sizeColumnsToFit()}
            overlayNoRowsTemplate={t("NoRowsToShow")}
          ></AgGridReact>
        </div>
      </div>
    </div>
  );
};

export default Table;
