import { types } from "../types/settings";

const initialState = {
  settings: {
    pictureUrl: null,
    preferredLanguage: null,
    visualizationPresets: null,
  },
  budget: {},
};

const userSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SETTINGS_LOAD:
      return {
        ...state,
        loading: true,
      };
    case types.SETTINGS_LOAD_SUCCESS:
      return {
        ...initialState,
        ...action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default userSettingsReducer;
