import React from "react";
import AccordionSection from "../../../../Components/Common/AccordionSection/index";
import { LayerSettingsInput } from "../../../../Components/Common/Form/Form";
import Icon from "../../../../Components/Common/Icon/index";
import { Download } from "react-feather";
import { getDownloadLink } from "../../../../api/storageApi";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

class GeodataDownload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.downloadFile = this.downloadFile.bind(this);
  }

  async downloadFile(file) {
    const url = await getDownloadLink(file);
    const link = document.createElement("a");
    link.download = "download";
    link.href = url;
    link.click();
  }

  render() {
    const { t } = this.props;

    return (
      <div className="layer-settings-container">
        <h3
          className="raster-settings-header"
          style={{ marginBottom: "24px" }}
        >{`${t("DownloadGeodata")}`}</h3>
        {this.props.resultsJsonOrigin.pages.map((x) => {
          if (x.name === "Report" || x.name === "CreatePage") {
            return;
          }
          return (
            <AccordionSection
              title={t(x.name)}
              buildingGroup
              style={{ width: "100%" }}
            >
              {x.lyrs.map((y) => {
                if (y.type === "wind_dir" || y.type === "wind_stream") {
                  return (
                    <>
                      <button
                        className="layer-settings-input-wrapper"
                        onClick={() => this.downloadFile(y.geo_u_utm)}
                      >
                        <LayerSettingsInput
                          name={"geodata"}
                          value={t(y.name) + " u_utm"}
                          style={{
                            width: "280px",
                            height: "27px",
                            marginRight: "12px",
                            border: "0px solid black",
                            backgroundColor: "transparent",
                            cursor: "pointer",
                            letterSpacing: "0.2px",
                          }}
                          readOnly
                        />
                        <div
                          className="tree-form-btn"
                          style={{
                            marginRight: "3px",
                            backgroundColor: "transparent",
                            cursor: "pointer",
                          }}
                        >
                          <Icon className="download-icon" icon={Download} />
                        </div>
                      </button>
                      <button
                        className="layer-settings-input-wrapper"
                        onClick={() => this.downloadFile(y.geo_v_utm)}
                      >
                        <LayerSettingsInput
                          name={"geodata"}
                          value={t(y.name) + " v_utm"}
                          style={{
                            width: "280px",
                            height: "27px",
                            marginRight: "12px",
                            border: "0px solid black",
                            backgroundColor: "transparent",
                            cursor: "pointer",
                            letterSpacing: "0.2px",
                          }}
                          readOnly
                        />
                        <div
                          className="tree-form-btn"
                          style={{
                            marginRight: "3px",
                            backgroundColor: "transparent",
                            cursor: "pointer",
                          }}
                        >
                          <Icon className="download-icon" icon={Download} />
                        </div>
                      </button>
                    </>
                  );
                }
                return (
                  <button
                    className="layer-settings-input-wrapper"
                    onClick={() => this.downloadFile(y.geo_utm)}
                  >
                    <LayerSettingsInput
                      name={"geodata"}
                      value={t(y.name)}
                      style={{
                        width: "280px",
                        height: "27px",
                        marginRight: "12px",
                        border: "0px solid black",
                        backgroundColor: "transparent",
                        cursor: "pointer",
                        letterSpacing: "0.2px",
                      }}
                      readOnly
                    />
                    <div
                      className="tree-form-btn"
                      style={{
                        marginRight: "3px",
                        backgroundColor: "transparent",
                        cursor: "pointer",
                      }}
                    >
                      <Icon className="download-icon" icon={Download} />
                    </div>
                  </button>
                );
              })}
            </AccordionSection>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    resultsJsonOrigin: state.results.resultsJsonOrigin,
  };
};

export default withTranslation()(
  connect(mapStateToProps, null)(GeodataDownload)
);
