import React from "react";
import Page from "../../Components/Page/Page";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

const LegalNotice = () => {
  const { t } = useTranslation();

  return (
    <Page>
      <h1 className="settings-hdr">{t("Legal Notice")}</h1>
      <div className="about-info">
        PALM-4U GUI: PALM-4U GUI is free software: you can redistribute it
        and/or modify it under the terms of the GNU Affero General Public
        License (AGPL) as published by the Free Software Foundation, either
        version 3 of the License, or (at your option) any later version.
      </div>
      <div style={{ marginBottom: 24 }} />
      <div className="about-info">
        PALM-4U GUI is distributed in the hope that it will be useful, but
        WITHOUT ANY WARRANTY; without even the implied warranty of
        MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the GNU Affero
        General Public License for more details.
      </div>
      <div style={{ marginBottom: 18 }} />
      <div className="about-info">
        You should have received a copy of the GNU Affero General Public License
        along with PALM-4U GUI. If not, see {` `}
        <a
          href={"https://www.gnu.org/licenses"}
          target="_blank"
          title={"licencse link"}
          rel="noopener noreferrer"
        >
          here
        </a>
        .
      </div>
    </Page>
  );
};

export default withRouter(LegalNotice);
