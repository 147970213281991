import React from "react";
import { Save } from "react-feather";
import { getDownloadLink } from "../../../../api/storageApi";

const PalmMonitoring = (props) => {

  const downloadFile = async (x) => {
    const url = await getDownloadLink(x.filePath);
    const link = document.createElement("a");
    link.download = "download";
    link.href = url;
    link.click();
  };

  return (
    <div className="palm-monitoring">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "end",
          width: "110px",
        }}
      >
        {props.simulationNotifications?.results.map((x) => {
          if (
            x.fileType.includes("cpu") ||
            x.fileType.includes("header") ||
            x.fileType.includes("parin") ||
            x.fileType.includes("runControl") ||
            x.fileType.includes("stdout")
          ) {
            return (
              <button
                className="raster-settings-btn"
                onClick={() => downloadFile(x)}
                style={{ marginTop: "15px" }}
              >
                <div
                  className="btn-content-wrapper btn-palm-mon-content-wrapper"
                  style={{ justifyContent: "flexStart" }}
                >
                  <Save color={"#494949"} size={"16px"} strokeWidth={"2px"} />
                  <div className="raster-btn-lbl">{x.fileType}</div>
                </div>
              </button>
            );
          }
          return;
        })}
      </div>
    </div>
  );
};
export default PalmMonitoring;
