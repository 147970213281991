export const routes = {
  createProject: "/projects/create",
  createCase: "/projects/:projectId/cases/create",
  projectHome: "/projects/:projectId/view",
  projectFiles: "/projects/:projectId/files",
  caseHome: "/projects/:projectId/cases/:caseId",
  domain: "/projects/:projectId/cases/:caseId/domain",
  caseSetup: "/projects/:projectId/cases/:caseId/setup",
  thermalComfort: "/projects/:projectId/cases/:caseId/thermalComfort",
  windComfort: "/projects/:projectId/cases/:caseId/windComfort",
  chemistry: "/projects/:projectId/cases/:caseId/chemistry",
  expert: "/projects/:projectId/cases/:caseId/expert",
  caseFileBrowser: "/projects/:projectId/cases/:caseId/files",
  caseSimulation: "/projects/:projectId/cases/:caseId/simulation",
  caseResults: "/projects/:projectId/cases/:caseId/results",
  settings: "/settings",
  admin: "/admin",
  legalNotice: "/legalNotice",
  help: "/help",
  about: "/about",
  osmDownload: "/osmDownload",
};
