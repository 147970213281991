import { createSelector } from "reselect";

export const selectCurrentCaseId = (state) => state.projects.currentCaseId;
export const mapCurrentProject = (state) => state.projects.loadedProject;

export const selectCurrentCase = createSelector(
  selectCurrentCaseId,
  mapCurrentProject,
  (caseId, project) => project ? project.caseDetailsDto?.find((c) => c.id === caseId) : null
);
