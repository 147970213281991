import React from "react";
import L from "leaflet";
import { GeoJSON } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { emissionMarker } from "../Emission/EmissionDrawing/EmissionDrawing";
import { mapPoiMarker } from "../MapPoi/MapPoiDrawing/MapPoiDrawing";
import { connect } from "react-redux";

class ChemistryGeoJson extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.pointToEmissionLayer = this.pointToEmissionLayer.bind(this);
    this.pointToMapPoiLayer = this.pointToMapPoiLayer.bind(this);

    this.createEmissionClusterIcon = this.createEmissionClusterIcon.bind(this);
    this.createMapPoiClusterIcon = this.createMapPoiClusterIcon.bind(this);
  }

  //   componentDidMount() {
  //     if (
  //       this.props.drawnPolygonGeojsonWg.features.length !== 0 &&
  //       this.props.drawnPolygonGeoJsonRef.current !== null
  //     ) {
  //       this.props.drawnPolygonGeoJsonRef.current.leafletElement
  //         .clearLayers()
  //         .addData(this.props.drawnPolygonGeojsonWg);
  //     }
  //   }

  //   componentDidUpdate(prevProps) {
  //     if (
  //       this.props.inRasterSettingsMode &&
  //       this.props.drawnPolygonGeoJsonRef.current !== null
  //     ) {
  //       this.props.drawnPolygonGeoJsonRef.current.leafletElement
  //         .clearLayers()
  //         .addData(this.props.drawnPolygonGeojsonWg);
  //       return;
  //     }

  //     if (
  //       !prevProps.allDrawnObjectsVisibility &&
  //       this.props.allDrawnObjectsVisibility &&
  //       this.props.drawnPolygonGeoJsonRef.current !== null
  //     ) {
  //       this.props.drawnPolygonGeoJsonRef.current.leafletElement
  //         .clearLayers()
  //         .addData(this.props.drawnPolygonGeojsonWg);
  //       return;
  //     }
  //     if (
  //       prevProps.drawnPolygonGeojsonWg.features.length !==
  //         this.props.drawnPolygonGeojsonWg.features.length &&
  //       prevProps.drawnPolygonGeojsonWg.features.length === 0 &&
  //       this.props.drawnPolygonGeoJsonRef.current !== null
  //     ) {
  //       this.props.drawnPolygonGeoJsonRef.current.leafletElement
  //         .clearLayers()
  //         .addData(this.props.drawnPolygonGeojsonWg);
  //       return;
  //     }
  //     // if (
  //     //   this.props.drawnPolygonGeojsonWg !== prevProps.drawnPolygonGeojsonWg ||
  //     //   (prevProps.inDrawingMode !== this.props.inDrawingMode &&
  //     //     )
  //     // ) {
  //     //   console.log(this.props.drawnPolygonGeoJsonRef.current);

  //     //   this.props.drawnPolygonGeoJsonRef.current.leafletElement
  //     //     .clearLayers()
  //     //     .addData(this.props.drawnPolygonGeojsonWg);
  //     //   return;
  //     // }

  //     if (
  //       (this.props.drawnPolygonGeojsonWg.features.length !== 0 ||
  //         this.props.drawnPolygonGeojsonWg !== prevProps.drawnPolygonGeojsonWg) &&
  //       this.props.drawnPolygonGeoJsonRef.current !== null
  //     ) {
  //       this.props.drawnPolygonGeoJsonRef.current.leafletElement
  //         .clearLayers()
  //         .addData(this.props.drawnPolygonGeojsonWg);
  //       return;
  //     }
  //   }

  pointToEmissionLayer(feature, latlng) {
    return L.marker(latlng, { icon: emissionMarker });
  }

  pointToMapPoiLayer(feature, latlng) {
    return L.marker(latlng, { icon: mapPoiMarker });
  }

  createEmissionClusterIcon(cluster) {
    return L.divIcon({
      html: `<div><span>${cluster.getChildCount()}</span></div>`,
      className: "marker-cluster marker-cluster-emission span",
      iconSize: L.point(40, 40, true),
    });
  }

  createMapPoiClusterIcon(cluster) {
    return L.divIcon({
      html: `<div><span>${cluster.getChildCount()}</span></div>`,
      className: "marker-cluster marker-cluster-drawn-map-poi span",
      iconSize: L.point(40, 40, true),
    });
  }

  render() {
    return (
      <>
        {this.props.isGeoJsonVisible && (
          <>
            <MarkerClusterGroup
              iconCreateFunction={this.createEmissionClusterIcon}
              disableClusteringAtZoom={19}
              showCoverageOnHover
              zoomToBoundsOnClick
              spiderfyOnMaxZoom={false}
            >
              {this.props.emissionGeoJsonWg &&
                !this.props.hiddenObjectTypesIndexes.includes(8) && (
                  <GeoJSON
                    data={this.props.emissionGeoJsonWg}
                    onEachFeature={this.props.onEachFeature}
                    pointToLayer={this.pointToEmissionLayer}
                  />
                )}
            </MarkerClusterGroup>
            <MarkerClusterGroup
              iconCreateFunction={this.createMapPoiClusterIcon}
              disableClusteringAtZoom={19}
              showCoverageOnHover
              zoomToBoundsOnClick
              spiderfyOnMaxZoom={false}
            >
              {this.props.mapPoiGeoJsonWg &&
                !this.props.hiddenObjectTypesIndexes.includes(9) && (
                  <GeoJSON
                    data={this.props.mapPoiGeoJsonWg}
                    onEachFeature={this.props.onEachFeature}
                    pointToLayer={this.pointToMapPoiLayer}
                  />
                )}
            </MarkerClusterGroup>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    emissionGeoJsonWg: state.map.emissionGeoJsonWg,
    mapPoiGeoJsonWg: state.map.mapPoiGeoJsonWg,
    hiddenObjectTypesIndexes: state.legend.hiddenObjectTypesIndexes,
    isGeoJsonVisible: state.rasterArea.isGeoJsonVisible,
  };
};

export default connect(mapStateToProps, null)(ChemistryGeoJson);
