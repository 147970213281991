export const types = {
  INIT_CREATE_PROJECT: "INIT_CREATE_PROJECT",
  UPLOAD_GEOJSON: "UPLOAD_GEOJSON",
  UPLOAD_TOPOGRAPHY: "UPLOAD_TOPOGRAPHY",
  UPLOAD_GEOJSON_SUCCESS: "UPLOAD_GEOJSON_SUCCESS",
  UPLOAD_TOPOGRAPHY_SUCCESS: "UPLOAD_TOPOGRAPHY_SUCCESS",
  CREATE_PROJECT: "CREATE_PROJECT",
  CREATE_PROJECT_SUCCESS: "CREATE_PROJECT_SUCCESS",
  CREATE_PROJECT_FAILURE: "CREATE_PROJECT_FAILURE",
  SET_CHECK_GEOJSON_REQUEST: "SET_CHECK_GEOJSON_REQUEST",
  SET_CONVERT_TOPOGRAPHY_REQUEST: "SET_CONVERT_TOPOGRAPHY_REQUEST",
  CHECK_GEOJSON: "CHECK_GEOJSON",
  CONVERT_TOPOGRAPHY: "CONVERT_TOPOGRAPHY",
};
