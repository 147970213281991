import React from "react";
import {
  ResultsTextInput,
  ResultsLayerInfo,
} from "../../../../../../Components/Map/Form/Select";
import {
  ResultsSelect,
  ResultsStepInput,
} from "../../../../../../Components/Map/Form/Select";
import { ResultsPaletteDropdown } from "../../../../../../Components/Common/PaletteDropdown";
import {
  setLayerColorPalette,
  setLayerMin,
  setLayerMax,
  setLayerOpacity,
  invertLayerColor,
} from "../../../../../../redux/actions/resultsActions";
import { results as resultsColors } from "../../../../../../common/VisualizationPresets/colorPalettes";
import * as plotty from "leaflet-geotiff-2/node_modules/plotty";
import { cloneDeep } from "lodash";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import "../../../../../../Components/Common/PaletteDropdown/index.scss";

class LayerValue extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.onColorPaletteChange = this.onColorPaletteChange.bind(this);
    this.onInvertColorChange = this.onInvertColorChange.bind(this);
  }

  onColorPaletteChange(e) {
    const colorPaletteName = e.target.value;

    if (!Object.hasOwn(plotty.colorscales, colorPaletteName)) {
      plotty.addColorScale(
        colorPaletteName,
        resultsColors[colorPaletteName],
        [0, 0.125, 0.25, 0.375, 0.5, 0.625, 0.75, 0.875, 1]
      );
    }
    this.props.setLayerColorPalette(colorPaletteName);
  }

  onInvertColorChange() {
    if (
      !this.props.jsonOriginPartSelected.plot_colinv &&
      !plotty.colorscales.hasOwnProperty(
        "inv" + this.props.jsonOriginPartSelected.plot_color
      )
    ) {
      const colorPaletteName = cloneDeep(
        this.props.jsonOriginPartSelected.plot_color
      );
      const colorPalette = cloneDeep(resultsColors[colorPaletteName]);
      const invColorPalette = colorPalette.reverse();
      const invPaletteName = "inv" + colorPaletteName;
      plotty.addColorScale(
        invPaletteName,
        invColorPalette,
        [0, 0.125, 0.25, 0.375, 0.5, 0.625, 0.75, 0.875, 1]
      );
    }
    this.props.invertLayerColor();
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <ResultsTextInput
          label={t("LayerName")}
          value={t(this.props.jsonOriginPartSelected.name)}
          visSettings
          disabled
        />
        <hr
          className="raster-settings-ruler"
          style={{ width: "300px", marginBottom: "0px" }}
        />
        <h5
          className="raster-settings-header"
          style={{ marginBottom: "16px", marginTop: "14px" }}
        >{`${t("Statistics")}`}</h5>
        <ResultsLayerInfo
          label={t("Minimum")}
          value={this.props.jsonOriginPartSelected.stat_min}
          style={{ borderRadius: "2px 2px 0px 0px" }}
          visSettings
        />
        <ResultsLayerInfo
          label={t("25%Quantile")}
          value={this.props.jsonOriginPartSelected.stat_q25}
          visSettings
        />
        <ResultsLayerInfo
          label={t("Median")}
          value={this.props.jsonOriginPartSelected.stat_median}
          visSettings
        />
        <ResultsLayerInfo
          label={t("Mean")}
          value={this.props.jsonOriginPartSelected.stat_mean}
          visSettings
        />
        <ResultsLayerInfo
          label={t("75%Quantile")}
          value={this.props.jsonOriginPartSelected.stat_q75}
          visSettings
        />
        <ResultsLayerInfo
          label={t("Maximum")}
          value={this.props.jsonOriginPartSelected.stat_max}
          style={{ borderRadius: "0px 0px 2px 2px" }}
          visSettings
        />
        <hr
          className="raster-settings-ruler"
          style={{ width: "300px", marginBottom: "8px" }}
        />
        <h5
          className="raster-settings-header"
          style={{ marginBottom: "10px", marginTop: "6px" }}
        >
          {this.props.jsonOriginPartSelected.type === "scalar"
            ? t("ScalarField")
            : this.props.jsonOriginPartSelected.type === "wind_dir"
            ? t("VectorField")
            : t("WindStreamAnimation")}
        </h5>

        <ResultsSelect
          label={t("BreaksType")}
          value={"auto"}
          options={["auto", "steps", "custom"]}
          disabled
          //onChange={this.onNewLayerExclBldgsChange}
          visSettings
        />
        <ResultsTextInput
          label={t("Minimum")}
          value={this.props.jsonOriginPartSelected.plot_min}
          onChange={(e) => {
            this.props.setLayerMin(e.target.value);
          }}
          onBlur={(e) => {
            if (isNaN(e.target.value)) {
              this.props.setLayerMin(0);
            }
          }}
          visSettings
        />
        <ResultsTextInput
          label={t("Maximum")}
          value={this.props.jsonOriginPartSelected.plot_max}
          onChange={(e) => {
            this.props.setLayerMax(e.target.value);
          }}
          onBlur={(e) => {
            if (isNaN(e.target.value)) {
              this.props.setLayerMax(10);
            }
          }}
          visSettings
        />
        <ResultsStepInput
          label={t("Opacity")}
          value={this.props.jsonOriginPartSelected.plot_opacity}
          onChange={(e) => {
            this.props.setLayerOpacity(e.target.value);
          }}
          min={0}
          max={1}
          step={0.1}
          visSettings
          onBlur={(e) => {
            if (
              e.target.value === "" ||
              e.target.value < Number(0) ||
              e.target.value > Number(1)
            ) {
              this.props.setLayerOpacity(1);
            }
          }}
        />
        {/* {this.props.jsonOriginPartSelected.type === "scalar" && (
          <ResultsSelect
            label={t("OutputType")}
            style={{ height: "28px" }}
            //value={this.props.layerSettingsFormDataValue?.exclbldgs}
            options={["auto", "steps", "custom"]}
            //disabled={this.props.inReadOnlyLayerForm}
            //onChange={this.onNewLayerExclBldgsChange}
            visSettings
          />
        )} */}
        {this.props.jsonOriginPartSelected.type === "wind_dir" && (
          <ResultsTextInput
            label={t("ArrowSize")}
            value={5}
            //disabled={this.props.inReadOnlyLayerForm}
            //onChange={this.onNewLayerExclBldgsChange}
            visSettings
          />
        )}

        {this.props.jsonOriginPartSelected.type === "wind_stream" && (
          <>
            <ResultsTextInput
              label={t("Velocity")}
              value={5}
              //value={this.props.layerSettingsFormDataValue?.exclbldgs}
              //disabled={this.props.inReadOnlyLayerForm}
              //onChange={this.onNewLayerExclBldgsChange}
              visSettings
            />
            <ResultsTextInput
              label={t("Width")}
              value={5}
              //disabled={this.props.inReadOnlyLayerForm}
              //onChange={this.onNewLayerExclBldgsChange}
              visSettings
            />
          </>
        )}
        <ResultsPaletteDropdown
          label={t("ColorPalette")}
          name={"ColorPalette"}
          value={this.props.jsonOriginPartSelected.plot_color}
          options={Object.keys(resultsColors).map((c) => ({
            value: c,
            label: t(c),
            colors: resultsColors[c],
          }))}
          onChange={this.onColorPaletteChange}
        />

        <label
          className="legend-row-container legend-wrapper"
          style={{ marginTop: "16px" }}
        >
          <label className="raster-object-name layer-object-name">
            {t("InvertColorPalette")}
          </label>
          <input
            className="raster-checkbox"
            name={"invert-color"}
            type="checkbox"
            checked={this.props.jsonOriginPartSelected.plot_colinv}
            onClick={this.onInvertColorChange}
          />
        </label>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    jsonOriginPartSelected: state.results.jsonOriginPartSelected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLayerColorPalette: (payload) => dispatch(setLayerColorPalette(payload)),
    setLayerMin: (payload) => dispatch(setLayerMin(payload)),
    setLayerMax: (payload) => dispatch(setLayerMax(payload)),
    setLayerOpacity: (payload) => dispatch(setLayerOpacity(payload)),
    invertLayerColor: () => dispatch(invertLayerColor()),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(LayerValue)
);
