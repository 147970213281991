import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import L from "leaflet";
import { LayerGroup, GeoJSON } from "react-leaflet";
import { highlightedTreeMarker } from "../TreeDrawing/TreeDrawing";
import { highlightedEmissionMarker } from "../Emission/EmissionDrawing/EmissionDrawing";
import { highlightedMapPoiMarker } from "../MapPoi/MapPoiDrawing/MapPoiDrawing";
import { getSelectedPolygonColor } from "../../../utils/polygons/polygonColor";

export const SelectedMapItemPolygon = (props) => {
  const selectedMapItemGeoJson = useSelector(
    (state) => state.map.selectedMapItemGeoJson
  );
  const inRasterSettingsMode = useSelector(
    (state) => state.map.inRasterSettingsMode
  );
  const selectedTreeIndex = useSelector((state) => state.map.selectedTreeIndex);
  const inputPalmTypesPalette = useSelector(
    (state) =>
      state.userSettings?.settings?.visualizationPresets
        ?.inputPalmTypesPalette || {}
  );

  useEffect(() => {
    if (props?.layerRef?.current?.leafletElement)
      props.layerRef.current.leafletElement
        .clearLayers()
        .addData(selectedMapItemGeoJson);
  }, [
    selectedMapItemGeoJson?.properties?.id,
    selectedMapItemGeoJson?.properties?.t,
  ]);

  function pointToLayer(feature, latlng) {
    if (feature.properties.t === 6) {
      return L.marker(latlng, { icon: highlightedTreeMarker });
    } else if (feature.properties.t === 8) {
      return L.marker(latlng, { icon: highlightedEmissionMarker });
    } else {
      return L.marker(latlng, { icon: highlightedMapPoiMarker });
    }
  }

  return (
    <>
      {!inRasterSettingsMode &&
        selectedMapItemGeoJson !== null &&
        selectedMapItemGeoJson !== undefined &&
        selectedTreeIndex === null && (
          <LayerGroup zIndex={1000} interactive={false}>
            <GeoJSON
              ref={props.layerRef}
              style={(x) => {
                return {
                  fillColor: getSelectedPolygonColor(
                    x.properties.t,
                    inputPalmTypesPalette
                  ),
                  weight: 2,
                  opacity: 1,
                  color: "black",
                  fillOpacity: 1,
                };
              }}
              pointToLayer={pointToLayer}
            />
          </LayerGroup>
        )}
    </>
  );
};
