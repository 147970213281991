import { graphAdScopes } from "./../utils/auth/authConfig";
import { apiRequestWithToken } from "./axiosApi";
import authService from "./../utils/auth/authService";

const graphMeEndpoint = "https://graph.microsoft.com/v1.0/me";
const detailsQueryString = "?$select=surname,givenName,organization,mail";

export async function getUserDetails() {
  const token = await authService.acquireTokenByScopes(graphAdScopes);
  const response = await apiRequestWithToken(
    {
      method: "get",
      url: `${graphMeEndpoint}/${detailsQueryString}`
    },
    token
  );
  return response.data;
}
