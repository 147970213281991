import React from "react";
import { BadgeIndicator } from "./BadgeIndicator";
import "./index.scss";

export const Badge = (props) => {
  const Icon = props.icon;
  return (
    <div
      className={`badge${props.show ? " badge__active" : ""}`}
      onClick={props.onClick}
      title={props.title}
    >
      <Icon className="badge-icon" />
      <BadgeIndicator {...props} />
    </div>
  );
};
