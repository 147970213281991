import React from "react";
import ResultsGeotiffLegend from "../ResultsGeotiff/ResultsGeotiffLegend";
import ResultsGeotiff from "../ResultsGeotiff/ResultsGeotiff";
import WindDirection from "../WindDirection/WindDirection";
import WindAnimation from "../WindAnimation/WindAnimation";
import { calculateRasterCenterUtm } from "../../../Domain/raster-area-helpers/index";
import {
  centerMapSuccess,
  centerMapPlotSuccess,
  setInitMapPlotCenter,
} from "../../../../redux/actions/resultsActions";
import { connect } from "react-redux";

class StaticDriverGeotiff extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.staticDriverGeotiffRef = React.createRef();
  }

  componentDidMount() {
    const topoTiffLyr = this.props.resultsJsonOrigin.pages[0].lyrs[0];
    const topoBounds = [
      [topoTiffLyr.geo_ymin, topoTiffLyr.geo_xmin],
      [topoTiffLyr.geo_ymax, topoTiffLyr.geo_xmax],
    ];
    this.props.staticDriverMapRef.current.leafletElement.fitBounds(topoBounds);

    const latWg = ((topoTiffLyr.geo_ymin + topoTiffLyr.geo_ymax) / 2).toFixed(
      6
    );
    const lngWg = ((topoTiffLyr.geo_xmin + topoTiffLyr.geo_xmax) / 2).toFixed(
      6
    );

    const maplPlotCenterUtm = calculateRasterCenterUtm(
      Number(latWg),
      Number(lngWg),
      this.props.crsDef
    );

    this.props.setInitMapPlotCenter({
      latWg: latWg,
      lngWg: lngWg,
      latUtm: maplPlotCenterUtm.lat,
      lngUtm: maplPlotCenterUtm.lng,
    });
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isCentering && this.props.isCentering) {
      const topoTiffLyr = this.props.resultsJsonOrigin.pages[0].lyrs[0];
      const topoBounds = [
        [topoTiffLyr.geo_ymin, topoTiffLyr.geo_xmin],
        [topoTiffLyr.geo_ymax, topoTiffLyr.geo_xmax],
      ];
      this.props.staticDriverMapRef.current.leafletElement.fitBounds(
        topoBounds
      );
      this.props.centerMapSuccess();
    }

    if (!prevProps.isMapPlotCentering && this.props.isMapPlotCentering) {
      const topoTiffLyr = this.props.resultsJsonOrigin.pages[0].lyrs[0];
      const latWg = ((topoTiffLyr.geo_ymin + topoTiffLyr.geo_ymax) / 2).toFixed(
        6
      );
      const lngWg = ((topoTiffLyr.geo_xmin + topoTiffLyr.geo_xmax) / 2).toFixed(
        6
      );

      const maplPlotCenterUtm = calculateRasterCenterUtm(
        Number(latWg),
        Number(lngWg),
        this.props.crsDef
      );

      this.props.setInitMapPlotCenter({
        latWg: latWg,
        lngWg: lngWg,
        latUtm: maplPlotCenterUtm.lat,
        lngUtm: maplPlotCenterUtm.lng,
      });
      this.props.centerMapPlotSuccess();
    }

    if (
      this.props.currentViewIndex !== prevProps.currentViewIndex &&
      this.props.resultsJsonOrigin.pages[prevProps.currentViewIndex].lyrs
    ) {
      const isVector = this.props.resultsJsonOrigin.pages[
        prevProps.currentViewIndex
      ].lyrs.some((x) => {
        return x.type === "wind_dir" || x.type === "wind_stream";
      });

      if (isVector) {
        this.props.staticDriverMapRef.current.leafletElement.eachLayer(
          function (layer) {
            if (layer.options.arrowDirection || layer.options.paths) {
              layer.remove();
            }
          }
        );
      }
    } else if (
      this.props.resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs[
        this.props.currentLayerIndex
      ] &&
      (this.props.resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs[
        this.props.currentLayerIndex
      ].type === "wind_dir" ||
        this.props.resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs[
          this.props.currentLayerIndex
        ].type === "wind_stream")
    ) {
      const isWindDir =
        this.props.resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs[
          this.props.currentLayerIndex
        ].type === "wind_dir";

      const isWindStream =
        this.props.resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs[
          this.props.currentLayerIndex
        ].type === "wind_stream";

      if (
        isWindDir &&
        this.props.resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs[
          this.props.currentLayerIndex
        ].checked === false
      ) {
        this.props.staticDriverMapRef.current.leafletElement.eachLayer(
          function (layer) {
            if (layer.options.arrowDirection) {
              layer.remove();
            }
          }
        );
      } else if (
        isWindStream &&
        this.props.resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs[
          this.props.currentLayerIndex
        ].checked === false
      ) {
        this.props.staticDriverMapRef.current.leafletElement.eachLayer(
          function (layer) {
            if (layer.options.paths) {
              layer.remove();
            }
          }
        );
      }
    }
  }

  render() {
    return (
      <>
        <ResultsGeotiff onResultsReady={this.props.onResultsReady} />
        <ResultsGeotiffLegend
          staticDriverMapRef={this.props.staticDriverMapRef}
        />
        {this.props.resultsJsonOrigin !== null &&
          this.props.resultsJsonOrigin.pages[
            this.props.currentViewIndex
          ].lyrs.map((x, i) => {
            if (x.type === "wind_dir" && x.checked && x.geo_u_4326) {
              return (
                <WindDirection
                  mapRef={this.props.staticDriverMapRef}
                  key={
                    x.name +
                    x.geo_u_4326 +
                    i +
                    x.plot_color +
                    x.plot_arr_size +
                    x.plot_opacity
                  }
                  layerIndex={i}
                />
              );
            }
          })}

        {this.props.resultsJsonOrigin !== null &&
          this.props.resultsJsonOrigin.pages[
            this.props.currentViewIndex
          ].lyrs.map((x, i) => {
            if (x.type === "wind_stream" && x.checked) {
              return (
                <WindAnimation
                  mapRef={this.props.staticDriverMapRef}
                  key={
                    this.props.resultsJsonOrigin.pages[
                      this.props.currentViewIndex
                    ].name +
                    i +
                    this.props.resultsJsonOrigin.pages[
                      this.props.currentViewIndex
                    ].lyrs[i].plot_paths +
                    this.props.resultsJsonOrigin.pages[
                      this.props.currentViewIndex
                    ].lyrs[i].plot_fade +
                    this.props.resultsJsonOrigin.pages[
                      this.props.currentViewIndex
                    ].lyrs[i].plot_max_age +
                    this.props.resultsJsonOrigin.pages[
                      this.props.currentViewIndex
                    ].lyrs[i].plot_v_scale +
                    this.props.resultsJsonOrigin.pages[
                      this.props.currentViewIndex
                    ].lyrs[i].plot_color +
                    this.props.resultsJsonOrigin.pages[
                      this.props.currentViewIndex
                    ].lyrs[i].plot_stroke_width +
                    this.props.resultsJsonOrigin.pages[
                      this.props.currentViewIndex
                    ].lyrs[i].plot_opacity
                  }
                  layerIndex={i}
                />
              );
            }
          })}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    resultsJsonOrigin: state.results.resultsJsonOrigin,
    currentLayerIndex: state.results.currentLayerIndex,
    isCentering: state.results.isCentering,
    currentViewIndex: state.results.currentViewIndex,
    crsDef: state.results.crsDef,
    isMapPlotCentering: state.results.isMapPlotCentering,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    centerMapSuccess: () => dispatch(centerMapSuccess()),
    centerMapPlotSuccess: () => dispatch(centerMapPlotSuccess()),
    setInitMapPlotCenter: (payload) => dispatch(setInitMapPlotCenter(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StaticDriverGeotiff);
