import { types } from "../types/auth";

const initialState = {
  isAuthenticated: false,
  isAuthenticating: false,
  loginError: false,
  identity: null,
  roles: [],
  rolesLoaded: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_USER:
      return {
        ...state,
        isAuthenticating: true,
        loginError: false,
        rolesLoaded: false,
      };
    case types.LOGIN_USER_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        identity: action.payload,
        isAuthenticating: false,
      };
    case types.LOGIN_USER_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        identity: null,
        isAuthenticating: false,
        loginError: true,
      };
    case types.LOGOUT_USER_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        identity: null,
      };
      case types.LOAD_USER_DETAILS_SUCCESS:
        return {
          ...state,
          details: action.payload
        };
      case types.LOAD_USER_DETAILS_FAILURE:
        return {
          ...state,
        };
        case types.GET_ROLES_SUCCESS:
          return {
            ...state,
            roles: action.payload,
            rolesLoaded: true,
          };
          case types.GET_ROLES_FAILURE:
            return {
              ...state,
              roles: [],
              rolesLoaded: true,
            };
    default:
      return state;
  }
};

export default authReducer;
