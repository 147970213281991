import React from "react";
import { Box } from "../Form/Box";
import { Database } from "react-feather";
import { withTranslation } from "react-i18next";
import {
  selectTree,
  removeTree,
  updateTreePositionOnInputChange,
  goToTreeProperties,
  updateTempInput,
} from "../../../redux/actions/mapActions";
import { connect } from "react-redux";
import "./TreeEditor.scss";

class TreeEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(e) {
    const reg = new RegExp(/^[+-]?\d*(?:[.,]\d*)?$/);
    const inputValue = e.target.value;
    const name = e.target.name;
    if (reg.test(inputValue) || inputValue === "") {
      if (
        inputValue.slice(-1) === "" ||
        inputValue.slice(-1) === "." ||
        inputValue.slice(-1) === "0" ||
        (name === "lat" && (inputValue < 0 || inputValue > 10000000)) ||
        (name === "lng" && (inputValue < 0 || inputValue > 1000000))
      ) {
        this.props.updateTempInput({
          tempKey: name,
          tempValue: inputValue,
        });
      } else {
        this.props.updateTreePositionOnInputChange({
          treeIndex: this.props.selectedTreeIndex,
          e: e,
        });
      }
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div className="tree-editor-container">
        <h3 className="tree-editor-hdr">{t("AddTreeByClickingOnMap")}</h3>
        <Box objectType={"singleTree"} />
        {this.props.allDrawnTreesCoordinatesUtm.length > 0 && (
          <div className="tree-coord-label-container">
            <div className="long-icon minified-icon"></div>
            <label>{t("Easting")}</label>
            <div className="lat-icon minified-icon"></div>
            <label style={{ marginRight: "-20px" }}>{t("Northing")}</label>
          </div>
        )}

        {this.props.allDrawnTreesCoordinatesUtm.map((tree, i) => {
          return (
            <div className="tree-coords-line">
              <div
                className={`tree-icon ${
                  this.props.selectedTreeIndex === i
                    ? "tree-highlighted-icon"
                    : ""
                }`}
              />
              <label className="tree-index">{`${i}`}</label>
              <input
                className="tree-editor-coord-row"
                placeholder="Longitude"
                name="lng"
                value={
                  this.props.selectedTreeIndex === i &&
                  this.props.tempKey === "lng" &&
                  (this.props.tempValue || this.props.tempValue === "")
                    ? this.props.tempValue
                    : tree.lng
                }
                onChange={(e) => this.handleInputChange(e)}
                onFocus={() => this.props.selectTree(i)}
                onBlur={() =>
                  this.props.updateTempInput({ tempValue: "", tempKey: "" })
                }
              />

              <input
                className="tree-editor-coord-row"
                placeholder="Lattitude"
                name="lat"
                value={
                  this.props.selectedTreeIndex === i &&
                  this.props.tempKey === "lat" &&
                  (this.props.tempValue || this.props.tempValue === "")
                    ? this.props.tempValue
                    : tree.lat
                }
                onChange={(e) => this.handleInputChange(e)}
                onFocus={() => this.props.selectTree(i)}
                onBlur={() =>
                  this.props.updateTempInput({ tempValue: "", tempKey: "" })
                }
              />

              <button
                className="tree-form-btn"
                title={t("GoToTreeParametrization")}
                onClick={() => {
                  this.props.goToTreeProperties(i);
                }}
              >
                <Database size="16px" color="Gray" strokeWidth={"1.7px"} />
              </button>

              <button
                className="remove-tree-btn"
                onClick={() => this.props.removeTree(i)}
                title={t("DeleteTree")}
              >
                X
              </button>
            </div>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allDrawnTreesCoordinatesUtm: state.map.allDrawnTreesCoordinatesUtm,
    selectedTreeIndex: state.map.selectedTreeIndex,
    tempKey: state.map.tempKey,
    tempValue: state.map.tempValue,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectTree: (payload) => dispatch(selectTree(payload)),
    removeTree: (payload) => dispatch(removeTree(payload)),
    updateTreePositionOnInputChange: (payload) =>
      dispatch(updateTreePositionOnInputChange(payload)),
    goToTreeProperties: (payload) => dispatch(goToTreeProperties(payload)),
    updateTempInput: (payload) => dispatch(updateTempInput(payload)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(TreeEditor)
);
