import React from "react";
import { useTranslation } from "react-i18next";
import Notification from "../../Notification/Notification";
import RasterizationNotification from "../RasterizationNotification";
import EmptyNotificationList from "./EmptyNotificationList";
import { ReactComponent as CloseIcon } from "../close.svg";
import "./index.scss";
import StaticDriverNotification from "../StaticDriverNotification/index";
import { SimulationNotification } from "../SimulationNotification/index";

export const NotificationList = (props) => {
  const { t } = useTranslation();
  return (
    <div className="notification-list__container">
      <div className="notification-list__header">{t("Notifications")}</div>
      <ul className="notification-list">
        {!props.items.length ? (
          <EmptyNotificationList />
        ) : (
          props.items
            .sort((i, i2) => {
              return i.ts - i2.ts;
            })
            .map((i) => (
              <Notification
                key={i.id}
                timestamp={i.ts}
                onDismiss={() => props.onDismiss(i)}
              >
                {getNotification(i, props)}
              </Notification>
            ))
        )}
      </ul>
      <CloseIcon
        className="notifications__dismiss"
        onClick={props.onListClose}
      />
    </div>
  );
};

function getNotification(i, props) {
  if (i.type === "rasterPreview") {
    return <RasterizationNotification rasterization={i} />;
  }
  if (i.type === "staticDriver") {
    return <StaticDriverNotification rasterization={i} />;
  }
  if (i.type === "simulation") {
    return (
      <SimulationNotification
        notification={i}
      />
    );
  }
  return null;
}
