import { types } from "../types/thermal";

const initialState = {
  thermalMode: {
    value: "CyclicBoundaryConditions",
    label: "CyclicBoundaryConditions",
  },
  dynamicDriver: { label: null },
  startDateSource: {
    label: "UserDefined",
    value: "UserDefined",
  },
  startDay: "2022-06-21",
  hoursFromUtc: { value: "+2", label: "+2" },
  duration: 30,
  airTemperatureSurface: 21,
  relHumidity: 50,
  windSpeed: 1,
  windDirection: { value: "North", label: "North" },
  meanTemperature: 20,
  amplitude: 10,
  soilMoisture: { value: "DrySoil", label: "DrySoil" },
  userDefinedSoilMoisture: 0.5,
  soilTemperature: {
    value: "DerivedFromBoundaryConditions",
    label: "DerivedFromBoundaryConditions",
  },
  userDefinedSoilTemperature: 297.05,
  outputPresets: { value: "Minimal", label: "Minimal" },
  outputParameters: [],
  outputInterval: {
    value: 60,
    label: 60,
  },
  outputTypeSelected: ["AverageOutput"],
  outputTypeOptions: ["AverageOutput", "InstantaneousOutput"],
  nightTime: {
    value: "04:00",
    label: "04:00",
  },
  daytime: {
    value: "14:00",
    label: "14:00",
  },
  thermalVariablesAveraging: [],
  mandatoryVariablesAveraging: [
    {
      value: [
        "theta_xy",
        "ta_xy",
        "theta_xy_av",
        "ta_xy_av",
        "theta_2m*_xy",
        "ta_2m*_xy",
      ],
      label: "NearSurfaceTemperature",
      isDisabled: true,
    },
    {
      value: [
        "u_xy",
        "v_xy",
        "u_xy",
        "wspeed_xy",
        "wdir_xy",
        "u_xy_av",
        "v_xy_av",
        "u_xy_av",
        "wspeed_xy_av",
        "wdir_xy_av",
      ],
      label: "NearSurfaceWindfield",
      isDisabled: true,
    },
    {
      value: ["tsurf*"],
      label: "SurfaceTemperature",
      isDisabled: true,
    },
    {
      value: [
        "vf25m*_xy",
        "vf50m*_xy",
        "vf75m*_xy",
        "vf100m*_xy",
        "vfd25m*_xy",
        "vfd50m*_xy",
        "vfd75m*_xy",
        "vfd100m*_xy",
      ],
      label: "VolumeFluxes",
      isDisabled: true,
    },
    {
      value: [
        "bio_perct*_xy",
        "bio_pet*_xy",
        "bio_utci*_xy",
        "bio_perct*_xy_av",
        "bio_pet*_xy_av",
        "bio_utci*_xy_av",
      ],
      label: "Biometeorology",
      isDisabled: true,
    },
  ],
  optionalVariablesAveraging: [
    {
      value: ["q", "rh"],
      label: "NearSurfaceHumidity",
    },
    // {
    //   value: ["bio_mrt_xy"],
    //   label: "MeanRadiantTemperature",
    // },
    // {
    //   value: [
    //     "pcm_heatrate",
    //     "pcm_heatrate_av",
    //     "pcm_transpirationrate",
    //     "pcm_transpirationrate_av",
    //     "pcm_latentrate",
    //     "pcm_latentrate_av",
    //   ],
    //   label: "PlantCanopyIndices",
    // },
    {
      value: [
        "rad_net*_xy",
        "rad_lw_in*_xy",
        "rad_lw_out*_xy",
        "rad_sw_in*_xy",
        "rad_sw_out*_xy",
      ],
      label: "Radiation",
    },
    {
      value: [
        "qsws_liq*_xy",
        "qsws_soil*_xy",
        "qsws_veg*_xy",
        "ghf*_xy",
        "qsws*_xy",
        "shf*_xy",
      ],
      label: "LatentHeat",
      isDisabled: false,
    },
    {
      value: ["m_soil", "t_soil", "tsurf*_xy"],
      label: "SoilTemperatureMoisture",
      isDisabled: false,
    },
    {
      value: ["ta", "theta"],
      label: "TemperatureField(3D)",
      isDisabled: false,
    },
    {
      value: ["u", "v", "w", "wspeed", "wdir"],
      label: "Windfield(3D)",
      isDisabled: false,
    },
  ],
};

const thermalReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_THERMAL_MODE: {
      if (action.payload.value === "DynamicDriver") {
        return {
          ...state,
          thermalMode: action.payload,
          soilMoisture: {
            value: "FromDynamicDriver",
            label: "FromDynamicDriver",
          },
          soilTemperature: {
            value: "FromDynamicDriver",
            label: "FromDynamicDriver",
          },
        };
      }

      return {
        ...state,
        thermalMode: action.payload,
        soilMoisture: { value: "DrySoil", label: "DrySoil" },
        soilTemperature: {
          value: "DerivedFromBoundaryConditions",
          label: "DerivedFromBoundaryConditions",
        },
        dynamicDriver: { label: null },
      };
    }
    case types.SET_DYNAMIC_DRIVER:
      return {
        ...state,
        dynamicDriver: action.payload,
      };

    case types.SET_START_DATE_SOURCE:
      return {
        ...state,
        startDateSource: action.payload,
      };
    case types.SET_START_DAY:
      return {
        ...state,
        startDay: action.payload,
      };
    case types.SET_HOURS_FROM_UTC:
      return {
        ...state,
        hoursFromUtc: action.payload,
      };
    case types.SET_DURATION:
      return {
        ...state,
        duration: action.payload,
      };
    case types.SET_AIR_TEMPERATURE_SURFACE:
      return {
        ...state,
        airTemperatureSurface: action.payload,
      };
    case types.SET_REL_HUMIDITY:
      return {
        ...state,
        relHumidity: action.payload,
      };

    case types.SET_WIND_SPEED:
      return {
        ...state,
        windSpeed: action.payload,
      };

    case types.SET_WIND_DIRECTION:
      return {
        ...state,
        windDirection: action.payload,
      };

    case types.SET_MEAN_TEMPERATURE:
      return {
        ...state,
        meanTemperature: action.payload,
      };

    case types.SET_AMPLITUDE:
      return {
        ...state,
        amplitude: action.payload,
      };

    case types.SET_SOIL_MOISTURE:
      return {
        ...state,
        soilMoisture: action.payload,
      };

    case types.SET_SOIL_TEMPERATURE:
      return {
        ...state,
        soilTemperature: action.payload,
      };

    case types.SET_OUTPUT_PRESETS:
      return {
        ...state,
        outputPresets: action.payload,
      };
    case types.SET_OUTPUT_PARAMETERS:
      return {
        ...state,
        outputParameters: action.payload,
      };

    case types.SET_OUTPUT_INTERVAL:
      return {
        ...state,
        outputInterval: action.payload,
        daytime: {
          value: "14:00",
          label: "14:00",
        },
        nightTime: {
          value: "04:00",
          label: "04:00",
        },
      };

    case types.SET_OUTPUT_TYPE:
      const changedOutputType = action.payload.changedOutputType;
      const isChecked = action.payload.isChecked;
      let outputTypeSelected = state.outputTypeSelected;

      if (isChecked) {
        outputTypeSelected.push(changedOutputType);
      } else {
        outputTypeSelected = outputTypeSelected.filter(
          (x) => x !== changedOutputType
        );
      }
      return {
        ...state,
        outputTypeSelected: outputTypeSelected,
      };

    case types.SET_NIGHT_TIME:
      return {
        ...state,
        nightTime: action.payload,
      };

    case types.SET_DAYTIME:
      return {
        ...state,
        daytime: action.payload,
      };

    case types.SET_TEST_RUN:
      return {
        ...state,
        testRun: action.payload,
      };
    case types.SET_THERMAL_VARIABLES_AVERAGING:
      return {
        ...state,
        thermalVariablesAveraging: action.payload,
      };
    case types.LOAD_THERMAL_SETTINGS: {
      const settings = action.payload.setup.settings;
      const runtimeParameters = settings.runtime_parameters;
      const initializationParameters = settings.initialization_parameters;
      const thermalParameters = settings.thermal_comfort_parameters;

      const startDay = thermalParameters.start_day;
      const duration = runtimeParameters.end_time / 3600;

      const hoursFromUtc = {
        value: thermalParameters.hours_from_utc,
        label: thermalParameters.hours_from_utc,
      };
      const airTemperatureSurface = (
        initializationParameters.pt_surface - 273.15
      ).toFixed(1);
      const relHumidity = initializationParameters.q_surface;
      const windSpeed = thermalParameters.wind_speed;
      const windDirection = {
        value: thermalParameters.wind_direction,
        label: thermalParameters.wind_direction,
      };

      const meanTemperature = (
        initializationParameters.spinup_pt_mean - 273.15
      ).toFixed(1);
      const amplitude = initializationParameters.spinup_pt_amplitude;

      const soilMoisture = {
        value: thermalParameters.soil_moisture,
        label: thermalParameters.soil_moisture,
      };

      const userDefinedSoilMoisture = thermalParameters.user_soil_moisture
        ? thermalParameters.user_soil_moisture
        : 0.5;

      const soilTemperature = {
        value: thermalParameters.soil_temperature,
        label: thermalParameters.soil_temperature,
      };

      const userDefinedSoilTemperature = thermalParameters.user_soil_temperature
        ? thermalParameters.user_soil_temperature
        : 297.05;

      let outputPresets = {
        value: "Minimal",
        label: "Minimal",
      };
      const outputTypeSelected = runtimeParameters.output_type_options;

      let thermalMode = {
        value: "CyclicBoundaryConditions",
        label: "CyclicBoundaryConditions",
      };

      let startDateSource = {
        label: "UserDefined",
        value: "UserDefined",
      };

      let dynamicDriver = {
        label: null,
      };

      if (thermalParameters.mode !== undefined) {
        thermalMode = {
          value: thermalParameters.mode,
          label: thermalParameters.mode,
        };

        startDateSource = {
          value: thermalParameters.start_date,
          label: thermalParameters.start_date,
        };

        if (thermalParameters.path_user_dynamic_driver !== null) {
          dynamicDriver = {
            label: thermalParameters.path_user_dynamic_driver.name,
            value: thermalParameters.path_user_dynamic_driver.path,
          };
        }
      }

      if (!!outputTypeSelected) {
        const dataOutputMasks = runtimeParameters.data_output_masks;
        outputPresets = {
          value: "UserDefined",
          label: "UserDefined",
        };

        const nightTime = {
          value: thermalParameters.assessment_time_nighttime,
          label: thermalParameters.assessment_time_nighttime,
        };

        const daytime = {
          value: thermalParameters.assessment_daytime,
          label: thermalParameters.assessment_daytime,
        };

        const outputInterval = {
          label: thermalParameters.output_interval / 60,
          value: thermalParameters.output_interval / 60,
        };

        const thermalVariablesAveraging = dataOutputMasks.filter((x) => {
          return (
            x.label !== "NearSurfaceTemperature" &&
            x.label !== "NearSurfaceWindfield" &&
            x.label !== "SurfaceTemperature" &&
            x.label !== "VolumeFluxes" &&
            x.label !== "Biometeorology"
          );
        });

        return {
          ...state,
          thermalMode: thermalMode,
          startDateSource: startDateSource,
          dynamicDriver: dynamicDriver,
          outputPresets: outputPresets,
          startDay: startDay,
          duration: duration,
          hoursFromUtc: hoursFromUtc,
          airTemperatureSurface: airTemperatureSurface,
          relHumidity: relHumidity,
          meanTemperature: meanTemperature,
          amplitude: amplitude,
          outputTypeSelected: outputTypeSelected,
          windSpeed: windSpeed,
          windDirection: windDirection,
          soilTemperature: soilTemperature,
          soilMoisture: soilMoisture,
          nightTime: nightTime,
          daytime: daytime,
          thermalVariablesAveraging: thermalVariablesAveraging,
          outputInterval: outputInterval,
          userDefinedSoilMoisture: userDefinedSoilMoisture,
          userDefinedSoilTemperature: userDefinedSoilTemperature,
        };
      }

      return {
        ...state,
        thermalMode: thermalMode,
        startDateSource: startDateSource,
        dynamicDriver: dynamicDriver,
        outputPresets: outputPresets,
        startDay: startDay,
        duration: duration,
        hoursFromUtc: hoursFromUtc,
        airTemperatureSurface: airTemperatureSurface,
        relHumidity: relHumidity,
        meanTemperature: meanTemperature,
        amplitude: amplitude,
        windSpeed: windSpeed,
        windDirection: windDirection,
        soilTemperature: soilTemperature,
        soilMoisture: soilMoisture,
        outputTypeSelected: ["AverageOutput"],
        userDefinedSoilMoisture: userDefinedSoilMoisture,
        userDefinedSoilTemperature: userDefinedSoilTemperature,
      };
    }
    case types.RESET_THERMAL_SETTINGS: {
      return {
        ...initialState,
      };
    }
    case types.SET_USER_DEFINED_SOIL_MOISTURE: {
      return {
        ...state,
        userDefinedSoilMoisture: action.payload,
      };
    }
    case types.SET_USER_DEFINED_SOIL_TEMPERATURE: {
      return {
        ...state,
        userDefinedSoilTemperature: action.payload,
      };
    }
    default:
      return state;
  }
};

export default thermalReducer;
