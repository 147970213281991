import React from "react";
import { withTranslation } from "react-i18next";
import FileBrowser from "../../FileBrowser/FileBrowser";
import "./FormFileBrowser.scss";

class FormFileBrowser extends React.Component {
  constructor() {
    super();
    this.state = {};
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.props.onChange(e.target.files[0]);
  }

  render() {
    const { t } = this.props;
    return (
      <div
        className="file-browser-wrapper"
        style={
          this.props.resultsStyle
            ? {
                flexDirection: "row",
                height: "31px",
                marginTop: "5px",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
              }
            : {}
        }
      >
        {this.props.label && (
          <label
            className="file-browser-label"
            style={
              this.props.resultsStyle && !this.props.expResultsStyle
                ? { fontSize: 11.7, fontWeight: 500 }
                : this.props.expResultsStyle
                ? { fontSize: 11, fontColor: "#4e4e4e", fontWeight: 600 }
                : {}
            }
          >
            {this.props.label}
          </label>
        )}
        <FileBrowser
          disabled={this.props.disabled}
          onChange={this.onChange}
          fileName={this.props.fileName}
          label={`${t("Browse")}...`}
          accept={this.props.accept}
          clearUploadedFile={this.props.clearUploadedFile}
          name={this.props.name}
          isClearable={this.props.isClearable && !this.props.disabled}
          setup={this.props.setup}
          resultsStyle={this.props.resultsStyle}
          expResultsStyle={this.props.expResultsStyle}
          projectFiles={this.props.projectFiles}
        />
      </div>
    );
  }
}

export default withTranslation()(FormFileBrowser);
