import React from "react";
import { ResultsSelect } from "../../../../../Components/Map/Form/Select";
import { windfieldParamsOptions } from "../../../ResultsConst";
import {
  changeWindfieldCase,
  changeWindfieldFileType,
  changeWindfieldParams,
  changeWindfieldHeight,
  changeWindfieldTimestep,
} from "../../../../../redux/actions/resultsActions";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class Windfield extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <ResultsSelect
          label={t("Case")}
          value={this.props.currentCase.name}
          options={[this.props.currentCase.name]}
          onChange={(e) => this.props.changeWindfieldCase(e.target.value)}
        />
        <ResultsSelect
          label={t("FileType")}
          value={this.props.windfieldFileType}
          options={[
            {
              name: "InstantaneousMaskedOverSurface",
              value: "InstantaneousMaskedOverSurface",
            },
          ].map((x) => {
            return { label: t(x.name), value: x.value };
          })}
          onChange={(e) =>
            this.props.changeWindfieldFileType({
              fileType: e.target.value,
              selectedIndex: e.target.selectedIndex,
            })
          }
          optionsObject
        />
        <ResultsSelect
          label={t("Parameter")}
          value={this.props.windfieldParams}
          options={windfieldParamsOptions.map((x) => {
            return { label: t(x), value: x };
          })}
          onChange={(e) => this.props.changeWindfieldParams(e.target.value)}
          optionsObject
        />
        <ResultsSelect
          label={t("HeightLevel")}
          value={this.props.windfieldHeight.value}
          options={this.props.windfieldHeightOptions}
          onChange={(e) =>
            this.props.changeWindfieldHeight({
              value: e.target.value,
              index: e.target.selectedIndex,
            })
          }
        />
        <ResultsSelect
          label={t("Timestep")}
          value={this.props.windfieldTimestep.value}
          options={this.props.windfieldTimestepOptions}
          onChange={(e) =>
            this.props.changeWindfieldTimestep({
              value: e.target.value,
              index: e.target.selectedIndex,
            })
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentCase: state.projects.currentCase,
    windfieldFileType: state.results.windfieldFileType,
    windfieldTypeOptions: state.results.windfieldTypeOptions,
    windfieldParams: state.results.windfieldParams,
    windfieldTimestep: state.results.windfieldTimestep,
    windfieldTimestepOptions: state.results.windfieldTimestepOptions,
    windfieldHeight: state.results.windfieldHeight,
    windfieldHeightOptions: state.results.windfieldHeightOptions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeWindfieldCase: (payload) => dispatch(changeWindfieldCase(payload)),
    changeWindfieldFileType: (payload) =>
      dispatch(changeWindfieldFileType(payload)),
    changeWindfieldParams: (payload) =>
      dispatch(changeWindfieldParams(payload)),
    changeWindfieldHeight: (payload) =>
      dispatch(changeWindfieldHeight(payload)),
    changeWindfieldTimestep: (payload) =>
      dispatch(changeWindfieldTimestep(payload)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Windfield)
);
