export const types = {
  SET_RASTER_CENTER_LAT_UTM: "SET_RASTER_CENTER_LAT_UTM",
  SET_RASTER_CENTER_LNG_UTM: "SET_RASTER_CENTER_LNG_UTM",
  SET_RASTER_CENTER_LAT_WG: "SET_RASTER_CENTER_LAT_WG",
  SET_RASTER_CENTER_LNG_WG: "SET_RASTER_CENTER_LNG_WG",
  SET_RASTER_GRID_WIDTH: "SET_RASTER_GRID_WIDTH",
  SET_RASTER_GRID_X_NUMBER: "SET_RASTER_GRID_X_NUMBER",
  SET_RASTER_GRID_Y_NUMBER: "SET_RASTER_GRID_Y_NUMBER",
  TOGGLE_RASTER_AREA_DRAGGABLE: "TOGGLE_RASTER_AREA_DRAGGABLE",
  UPDATE_RASTER_AREA_POSITION: "UPDATE_RASTER_AREA_POSITION",
  GET_RASTER_PREVIEW: "GET_RASTER_PREVIEW",
  TOGGLE_RASTER_PREVIEW_VISIBILITY: "TOGGLE_RASTER_PREVIEW_VISIBILITY",
  TOGGLE_GEOJSON_VISIBILITY: "TOGGLE_GEOJSON_VISIBILITY",
  TOGGLE_RASTER_AREA_VISIBILITY: "TOGGLE_RASTER_AREA_VISIBILITY",
  RESET_RASTER_AREA_PROPERTIES: "RESET_RASTER_AREA_PROPERTIES",
  LOAD_RASTER_AREA_PROPERTIES: "LOAD_RASTER_AREA_PROPERTIES",
  LOAD_RASTER_AREA_PREVIEW: "LOAD_RASTER_AREA_PREVIEW",
  ON_SINGLE_RASTER_LOAD_VISIBILITY: "ON_SINGLE_RASTER_LOAD_VISIBILITY",
  ON_NESTED_RASTER_LOAD_VISIBILITY: "ON_NESTED_RASTER_LOAD_VISIBILITY",
  SET_VISIBILITIES_ON_EXIT_MODE: "SET_VISIBILITIES_ON_EXIT_MODE",
  SET_INITIAL_DEFAULT_VISIBILITES: "SET_INITIAL_DEFAULT_VISIBILITES",
  GET_NESTED_RASTER_PREVIEW: "GET_NESTED_RASTER_PREVIEW",
  TOGGLE_PARENT_RASTER_PREVIEW_VISIBILITY:
    "TOGGLE_PARENT_RASTER_PREVIEW_VISIBILITY",
  TOGGLE_CHILD_RASTER_PREVIEW_VISIBILITY:
    "TOGGLE_CHILD_RASTER_PREVIEW_VISIBILITY",
  SHOW_NESTED_RASTER_AREA: "SHOW_NESTED_RASTER_AREA",
  TOGGLE_PARENT_RASTER_AREA_VISIBILITY: "TOGGLE_PARENT_RASTER_AREA_VISIBILITY",
  TOGGLE_CHILD_RASTER_AREA_VISIBILITY: "TOGGLE_CHILD_RASTER_AREA_VISIBILITY",

  SET_DOMAIN_TYPE: "SET_DOMAIN_TYPE",
  SET_GRID_SIZES: "SET_GRID_SIZES",
  SET_PARENT_RASTER_GRID_X_NUMBER: "SET_PARENT_RASTER_GRID_X_NUMBER",
  SET_PARENT_RASTER_GRID_Y_NUMBER: "SET_PARENT_RASTER_GRID_Y_NUMBER",
  SET_CHILD_RASTER_GRID_X_NUMBER: "SET_CHILD_RASTER_GRID_X_NUMBER",
  SET_CHILD_RASTER_GRID_Y_NUMBER: "SET_CHILD_RASTER_GRID_Y_NUMBER",

  SET_SOIL_TYPE: "SET_SOIL_TYPE",
  SET_SEASON: "SET_SEASON",
  SET_RASTER_METHOD: "SET_RASTER_METHOD",
  SET_FILL_VALUE: "SET_FILL_VALUE",
  SET_FILL_VALUE_BELOW_TREE_PATCHES: "SET_FILL_VALUE_BELOW_TREE_PATCHES",
  SET_RASTERIZATION_PRIORITY: "SET_RASTERIZATION_PRIORITY",
  SET_TOPOGRAPHY_FOR_CHILD_DOMAIN: "SET_TOPOGRAPHY_FOR_CHILD_DOMAIN",

  LOAD_NESTED_AREA_PROPERTIES: "LOAD_NESTED_AREA_PROPERTIES",
  LOAD_NESTED_AREA_PREVIEW: "LOAD_NESTED_AREA_PREVIEW",

  SET_SINGLE_RASTER_CASE_PROPS: "SET_SINGLE_RASTER_CASE_PROPS",
  SET_NESTED_RASTER_CASE_PROPS: "SET_NESTED_RASTER_CASE_PROPS",

  SET_PARENT_OFFSET_X: "SET_PARENT_OFFSET_X",
  SET_PARENT_OFFSET_Y: "SET_PARENT_OFFSET_Y",

  SET_GLOBAL_WATER_TEMPERATURE_SOURCE: "SET_GLOBAL_WATER_TEMPERATURE_SOURCE",
  SET_GLOBAL_WATER_TEMPERATURE: "SET_GLOBAL_WATER_TEMPERATURE",

  TOGGLE_BUFFER_AREA_VISIBILITY: "TOGGLE_BUFFER_AREA_VISIBILITY",

  SET_BUFFER_NORTH: "SET_BUFFER_NORTH",
  SET_BUFFER_SOUTH: "SET_BUFFER_SOUTH",
  SET_BUFFER_WEST: "SET_BUFFER_WEST",
  SET_BUFFER_EAST: "SET_BUFFER_EAST",

  SET_VERTICAL_RESOLUTION_MODE: "SET_VERTICAL_RESOLUTION_MODE",
  SET_VERTICAL_RESOLUTION_METER: "SET_VERTICAL_RESOLUTION_METER",

  SET_BUILDING_HEIGHTS: "SET_BUILDING_HEIGHTS",
  SET_BUILDING_HEIGHTS_FILE: "SET_BUILDING_HEIGHTS_FILE",
  SET_CHILD_BUILDING_HEIGHTS: "SET_CHILD_BUILDING_HEIGHT",
  SET_CHILD_BUILDING_HEIGHTS_FILE: "SET_CHILD_BUILDING_HEIGHTS_FILE",
  SET_TREE_HEIGHTS: "SET_TREE_HEIGHTS",
  SET_TREE_HEIGHTS_FILE: "SET_TREE_HEIGHTS_FILE",
  SET_CHILD_TREE_HEIGHTS: "SET_CHILD_TREE_HEIGHTS",
  SET_CHILD_TREE_HEIGHTS_FILE: "SET_CHILD_TREE_HEIGHTS_FILE",

  SET_BUFFER_FILL_TYPE: "SET_BUFFER_FILL_TYPE",
  SET_BUFFER_FILL_VALUE: "SET_BUFFER_FILL_VALUE",
  SET_BUFFER_METHOD: "SET_BUFFER_METHOD",
  SET_BUFFER_TOPOGRAPHY_INTERP_METHOD: "SET_BUFFER_TOPOGRAPHY_INTERP_METHOD",

  SET_SOIL_ALPHA_COEFFICIENT: "SET_SOIL_ALPHA_COEFFICIENT",
  SET_SOIL_L_COEFFICIENT: "SET_SOIL_L_COEFFICIENT",
  SET_SOIL_N_COEFFICIENT: "SET_SOIL_N_COEFFICIENT",
  SET_SOIL_HYDRAULIC_CONDUCTIVITY: "SET_SOIL_HYDRAULIC_CONDUCTIVITY",
  SET_SOIL_MOISTURE_SATURATION: "SET_SOIL_MOISTURE_SATURATION",
  SET_SOIL_MOISTURE_CAPACITY: "SET_SOIL_MOISTURE_CAPACITY",
  SET_SOIL_MOISTURE_WILTING: "SET_SOIL_MOISTURE_WILTING",
  SET_SOIL_MOISTURE_CONTENT: "SET_SOIL_MOISTURE_CONTENT",
};
