import React from "react";
import { ResultsSelect } from "../../../../../Components/Map/Form/Select";
import { ResultsPaletteDropdown } from "../../../../../Components/Common/PaletteDropdown/index";
import { customCategoryColors } from "../../../../../common/VisualizationPresets/colorPalettes";
import { customCategoryNr } from "../../../ResultsConst";
import {
  changeCatCustomPage,
  changeCatCustomLayer,
  changeCatCustomNumber,
  changeCatCustomColorPalette,
  invertCatCustomColorPalette,
} from "../../../../../redux/actions/resultsActions";
import * as plotty from "leaflet-geotiff-2/node_modules/plotty";
import { cloneDeep } from "lodash";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class CustomCategory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isInverted: false,
    };

    this.onPageChange = this.onPageChange.bind(this);
    this.onLayerChange = this.onLayerChange.bind(this);
    this.onCatNrChange = this.onCatNrChange.bind(this);
    this.onColorPaletteChange = this.onColorPaletteChange.bind(this);
    this.onInvertColorChange = this.onInvertColorChange.bind(this);
  }

  componentDidMount() {}

  onPageChange(e) {
    this.props.changeCatCustomPage({
      catCustomPage: e.target.value,
      catCustomPageIndex: e.target.selectedIndex,
    });
  }

  onLayerChange(e) {
    this.props.changeCatCustomLayer({
      catCustomLayer: e.target.value,
      catCustomLayerIndex: e.target.selectedIndex,
    });
  }

  onCatNrChange(e) {
    this.props.changeCatCustomNumber(e.target.value);
  }

  onColorPaletteChange(e) {
    this.props.changeCatCustomColorPalette(e.target.value);
  }

  onInvertColorChange(e) {
    const colorPaletteName = cloneDeep(this.props.catCustomColorPalette);
    const colorPalette = customCategoryColors[colorPaletteName];
    let percBreak = null;
    if (colorPalette.length === 8) {
      percBreak = [0, 0.143, 0.286, 0.429, 0.572, 0.715, 0.858, 1];
    } else {
      percBreak = [0, 0.125, 0.25, 0.375, 0.5, 0.625, 0.75, 0.875, 1];
    }
    const invColorPalette = colorPalette.reverse();
    if (!this.props.isCatCustomColorPaletteInverted) {
      const invPaletteName = "inv" + colorPaletteName;
      plotty.addColorScale(invPaletteName, invColorPalette, percBreak);
    }

    this.props.invertCatCustomColorPalette();
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <h5 style={{ marginTop: "0px" }} className="raster-settings-header">
          {`${t("DataSelectionOrigin")}`}
        </h5>

        <ResultsSelect
          label={t("Case")}
          value={this.props.currentCase.name}
          options={[this.props.currentCase.name]}
        />
        <ResultsSelect
          label={t("Page")}
          value={this.props.catCustomPage}
          options={this.props.catCustomPageOptions.map((x) => {
            return { label: t(x.name), value: x.value };
          })}
          onChange={this.onPageChange}
          optionsObject
        />
        <ResultsSelect
          label={t("Layer")}
          value={this.props.catCustomLayer}
          options={this.props.catCustomLayerOptions.map((x) => {
            return { label: t(x.name), value: x.value };
          })}
          onChange={this.onLayerChange}
          optionsObject
        />
        <ResultsSelect
          label={t("NrOfCategories")}
          value={this.props.catCustomNr}
          options={customCategoryNr}
          onChange={this.onCatNrChange}
        />
        <ResultsPaletteDropdown
          label={t("ColorPalette")}
          name={"ColorPalette"}
          value={this.props.catCustomColorPalette}
          options={Object.keys(customCategoryColors).map((c) => ({
            value: c,
            label: t(c),
            colors: customCategoryColors[c],
          }))}
          onChange={this.onColorPaletteChange}
        />
        <label
          className="legend-row-container legend-wrapper"
          style={{ marginTop: "16px" }}
        >
          <label className="raster-object-name layer-object-name">
            {t("InvertColorPalette")}
          </label>
          <input
            className="raster-checkbox"
            name={"invert-color"}
            type="checkbox"
            checked={this.props.isCatCustomColorPaletteInverted}
            onClick={this.onInvertColorChange}
          />
        </label>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentCase: state.projects.currentCase,
    catCustomPage: state.results.catCustomPage,
    catCustomPageOptions: state.results.catCustomPageOptions,
    catCustomLayer: state.results.catCustomLayer,
    catCustomLayerOptions: state.results.catCustomLayerOptions,
    catCustomNr: state.results.catCustomNr,
    catCustomColorPalette: state.results.catCustomColorPalette,
    isCatCustomColorPaletteInverted:
      state.results.isCatCustomColorPaletteInverted,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeCatCustomPage: (payload) => dispatch(changeCatCustomPage(payload)),
    changeCatCustomLayer: (payload) => dispatch(changeCatCustomLayer(payload)),
    changeCatCustomNumber: (payload) =>
      dispatch(changeCatCustomNumber(payload)),
    changeCatCustomColorPalette: (payload) =>
      dispatch(changeCatCustomColorPalette(payload)),
    invertCatCustomColorPalette: () => dispatch(invertCatCustomColorPalette()),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(CustomCategory)
);
