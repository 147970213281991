import { getPolygonColor, getSelectedPolygonColor } from "./polygonColor.js";

export function stylePolygon(feature, selected = false, inputPalmTypesPalette) {
  return {
    fillColor: getPolygonColor(feature.properties.t, inputPalmTypesPalette),
    weight: selected ? 2 : 1,
    opacity: 1,
    color: selected ? "black" : "#fff",
    fillOpacity: selected ? 1 : 0.6,
  };
}

export function styleDrawnPolygonGeoJson(
  feature,
  selected = false,
  inputPalmTypesPalette
) {
  return {
    fillColor: !selected
      ? getPolygonColor(feature.properties.t, inputPalmTypesPalette)
      : getSelectedPolygonColor(feature.properties.t, inputPalmTypesPalette),
    weight: selected ? 2 : 1,
    opacity: 1,
    color: selected ? "black" : "#fff",
    fillOpacity: selected ? 1 : 0.6,
  };
}
