import React from "react";
import "./WizardForm.scss";

class WizardForm extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return <div className="wizard-wrapper stepper">{this.props.children}</div>;
  }
}
export default WizardForm;

const Step = (props) => (
  <div
    className={`stepper-step ${
      props.status === "succes" ? `step-completed` : ""
    }
    ${props.status === "failed" ? `step-failed` : ""}
      ${props.status === "in-progress" ? `step-in-progress` : ""}
    `}
  >
    <div className="stepper-indicator">
      <span
        className={`stepper-info ${
          props.status === "succes" ? `stepper-info-completed` : ""
        }
    ${props.status === "failed" ? `stepper-info-failed` : ""}
      ${props.status === "in-progress" ? `stepper-info-in-progress` : ""}
      ${props.isActive ? "step-active" : ""}
    `}
      ></span>
    </div>
    <div className="stepper-label">{props.text}</div>
    <div className="stepper-panel">{props.children}</div>
  </div>
);

export { Step };
