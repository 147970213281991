import React from "react";
import "./index.css";

export const Loader = (props) => {
  return (
    <div
      className={`loader${props.size ? ` ${props.size}-loader` : ""}${
        props.mode ? ` ${props.mode}-loader` : ""
      }`}
      style={props.style}
    />
  );
};
