export const types = {
  SET_CH_SEASON: "SET_CH_SEASON",
  SET_CH_START_DAY: "SET_CH_START_DAY",
  SET_CH_DURATION: "SET_CH_DURATION",
  SET_CH_AIR_TEMPERATURE_SURFACE: "SET_CH_AIR_TEMPERATURE_SURFACE",
  SET_CH_REL_HUMIDITY: "SET_CH_REL_HUMIDITY",
  SET_CH_WIND_SPEED: "SET_CH_WIND_SPEED",
  SET_CH_WIND_DIRECTION: "SET_CH_WIND_DIRECTION",
  SET_CH_MEAN_TEMPERATURE: "SET_CH_MEAN_TEMPERATURE",
  SET_CH_AMPLITUDE: "SET_CH_AMPLITUDE",
  SET_CH_SOIL_MOISTURE: "SET_CH_SOIL_MOISTURE",
  SET_CH_SOIL_TEMPERATURE: "SET_CH_SOIL_TEMPERATURE",
  SET_CH_USER_DEFINED_SOIL_MOISTURE: "SET_CH_USER_DEFINED_SOIL_MOISTURE",
  SET_CH_USER_DEFINED_SOIL_TEMPERATURE: "SET_CH_USER_DEFINED_SOIL_TEMPERATURE",
  SET_CH_INPUT_PRESETS: "SET_CH_INPUT_PRESETS",
  SET_CH_OUTPUT_PRESETS: "SET_CH_OUTPUT_PRESETS",
  SET_SURFACE_CS_FLUX_25: "SET_SURFACE_CS_FLUX_25",
  SET_SURFACE_CS_FLUX_10: "SET_SURFACE_CS_FLUX_10",
  SET_CH_OUTPUT_PARAMETERS: "SET_CH_OUTPUT_PARAMETERS",
  SET_CH_MAP_OUTPUT_INTERVAL: "SET_CH_MAP_OUTPUT_INTERVAL",
  SET_CH_VERTICAL_OUTPUT_INTERVAL: "SET_CH_VERTICAL_OUTPUT_INTERVAL",
  SET_CH_TIME_SERIES_OUTPUT_INTERVAL: "SET_CH_TIME_SERIES_OUTPUT_INTERVAL",
  SET_CH_VERTICAL_POI: "SET_CH_VERTICAL_POI",
  SET_CH_TIME_SERIES_POI: "SET_CH_TIME_SERIES_POI",
  SET_CH_INITIAL_POI_LIST: "SET_CH_INITIAL_POI_LIST",
  SET_CH_ASSESMENT_TIME_FROM: "SET_CH_ASSESMENT_TIME_FROM",
  SET_CH_ASSESMENT_TIME_TO: "SET_CH_ASSESMENT_TIME_TO",

  UPDATE_CH_EMISSIONS_FACTOR_TABLE: "UPDATE_CH_EMISSIONS_FACTOR_TABLE",
  UPDATE_CH_POINT_EMISSIONS_TABLE: "UPDATE_CH_POINT_EMISSIONS_TABLE",
  SET_POINT_EMISSION_TABLE_TYPE: "SET_POINT_EMISSION_TABLE_TYPE",

  LOAD_CHEMISTRY_SETTINGS: "LOAD_CHEMISTRY_SETTINGS",
  RESET_CHEMISTRY_SETTINGS: "RESET_CHEMISTRY_SETTINGS",
  SET_CH_POI_OPTIONS: "SET_CH_POI_OPTIONS",
};
