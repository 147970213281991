import React from "react";
import Select from "react-select";
import WindTable from "./WindTable";
import FormFileBrowser from "../../Components/Common/Form/FormFileBrowser/FormFileBrowser";
import { CheckCircle, XCircle } from "react-feather";
import { Loader } from "../../Components/Loader/index";
import { getCaseSetup } from "../../api/simulationApi";
import { postCaseSetup } from "../../api/simulationApi";
import { uploadBlobToAzure } from "../../api/storageApi";
import { selectSetupStyle } from "../../Components/Common/PaletteDropdown/index";
import { selectCaseSimulationNotifications } from "../../redux/selectors/simulationSelector";
import {
  setWindStatisticSource,
  setWindDirectionsNumber,
  setMeasurementHeight,
  setMeasurementLocation,
  setMeasurementAlpha,
  setSimDomainLocation,
  setSimDomainAlpha,
  updateWindTable,
  loadWindSettings,
  resetWindSettings,
  setWindTableFile,
} from "../../redux/actions/windActions";
import { calculateRasterCenterWgFromDb } from "../Domain/raster-area-helpers/index";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import "./WindComfort.scss";

class WindComfort extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: "",
      isSaving: false,
      saveCompleted: false,
      savedStaticDriver: false,
    };
    this.onWindTableFileChange = this.onWindTableFileChange.bind(this);
    this.onWindTableFileClear = this.onWindTableFileClear.bind(this);
    this.saveCaseSetup = this.saveCaseSetup.bind(this);
  }

  async componentDidMount() {
    if (!this.props.currentCaseId) {
      return;
    }
    const windCaseSettings = await getCaseSetup(this.props.currentCaseId);
    if (typeof windCaseSettings === "string") {
      this.props.resetWindSettings();
    } else {
      this.props.loadWindSettings(windCaseSettings);
    }

    if (this.props.staticDriverNotifications.length > 0) {
      for (
        let index = this.props.staticDriverNotifications.length - 1;
        index >= 0;
        index--
      ) {
        if (
          this.props.staticDriverNotifications[index].type === "staticDriver" &&
          this.props.staticDriverNotifications[index].body.caseId ===
            this.props.currentCase.id &&
          this.props.staticDriverNotifications[index].body.status === 2
        ) {
          this.setState({ savedStaticDriver: true });
        }
      }
    }
  }

  async componentDidUpdate(prevProps) {
    if (
      this.props.currentCaseId &&
      prevProps.currentCaseId !== this.props.currentCaseId
    ) {
      const windCaseSettings = await getCaseSetup(this.props.currentCaseId);
      if (typeof windCaseSettings === "string") {
        this.props.resetWindSettings();
      } else {
        this.props.loadWindSettings(windCaseSettings);
      }
    }

    if (
      this.props.currentCase?.staticDriverJob?.status === "Completed" &&
      !this.state.savedStaticDriver
    ) {
      this.setState({ savedStaticDriver: true });
      return;
    } else if (
      this.props.staticDriverNotifications !==
      prevProps.staticDriverNotifications
    ) {
      if (!this.props.currentCase) {
        return;
      }

      for (
        let index = this.props.staticDriverNotifications.length - 1;
        index >= 0;
        index--
      ) {
        if (
          this.props.staticDriverNotifications[index].type === "staticDriver" &&
          this.props.staticDriverNotifications[index].body.caseId ===
            this.props.currentCase.id &&
          this.props.staticDriverNotifications[index].body.status === 2
        ) {
          this.setState({ savedStaticDriver: true });
          return;
        }
      }
    }
  }

  onWindTableFileChange(e) {
    this.props.setWindTableFile({
      windTableFileName: e.name,
      windTableFile: e,
    });
  }

  onWindTableFileClear() {
    this.props.setWindTableFile({ windTableFileName: "", windTableFile: "" });
  }

  async saveCaseSetup() {
    this.setState({ isSaving: true });

    let longitude = null;
    let latitude = null;

    if (!this.props.rasterArea.rasterCenterLatWg) {
      const rasterCenterWg = calculateRasterCenterWgFromDb(
        this.props.currentCase.rasterParameters.y_min,
        this.props.currentCase.rasterParameters.y_max,
        this.props.currentCase.rasterParameters.x_min,
        this.props.currentCase.rasterParameters.x_max,
        this.props.epsg
      );
      latitude = rasterCenterWg.lat.toFixed(6);
      longitude = rasterCenterWg.lng.toFixed(6);
    } else {
      latitude = Number(this.props.rasterArea.rasterCenterLatWg).toFixed(6);
      longitude = Number(this.props.rasterArea.rasterCenterLngWg).toFixed(6);
    }

    let path = null;
    try {
      if (this.props.wind.windStatisticSource.value !== "UserDefinedTable") {
        path = `${this.props.projectId}/${this.props.currentCase.id}/${this.props.wind.windTableFileName}`;
        await uploadBlobToAzure(path, this.props.wind.windTableFile);
      }
      const windSetup = {
        caseId: this.props.currentCaseId,
        settings: {
          initialization_parameters: {
            nx: this.props.rasterArea.rasterGridXNumber - 1,
            ny: this.props.rasterArea.rasterGridYNumber - 1,
            resolution: this.props.rasterArea.rasterGridWidth,
            longitude: longitude,
            latitude: latitude,
          },
          wind_comfort_parameters: {
            windStatisticSource: this.props.wind.windStatisticSource,
            windDirectionsNumber: this.props.wind.windDirectionsNumber.value,
            directions:
              this.props.wind.windStatisticSource.value === "UserDefinedTable"
                ? this.props.wind.windDirectionsNumber
                : 8,
            measurementHeight: this.props.wind.measurementHeight,
            measurementLocation: this.props.wind.measurementLocation.value,
            measurementAlpha: this.props.wind.measurementAlpha,
            simDomainLocation: this.props.wind.simDomainLocation.value,
            simDomainAlpha:
              this.props.wind.simDomainLocation.value === "FromStaticDriver"
                ? null
                : this.props.wind.simDomainAlpha,
            optionalVariablesAveraging:
              this.props.wind.optionalVariablesAveraging.value,
            windTable:
              this.props.wind.windStatisticSource.value === "UserDefinedTable"
                ? this.props.wind.windTable
                : null,
            windHeader:
              this.props.wind.windStatisticSource.value === "UserDefinedTable"
                ? this.props.wind.windHeader
                : null,
            inputFile: path,
          },
        },
      };
      await postCaseSetup(windSetup);
      this.setState({ saveCompleted: true });
      setTimeout(() => this.setState({ saveCompleted: false }), 5000);
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ isSaving: false });
    }
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <div className="page setup-page" style={{ minWidth: 900 }}>
          <div className="settings-form">
            <div className="wind-wrapper">
              <h2 style={{ cursor: "pointer" }}>{t("WindComfort")}</h2>
              <label className="form-label user-settings-form-label">
                {t("Source")}
              </label>
              <Select
                value={{
                  label: t(this.props.wind.windStatisticSource.label),
                  value: this.props.wind.windStatisticSource.value,
                }}
                options={[
                  {
                    label: t("DWDClimateDataCenter"),
                    value: "DWDClimateDataCenter",
                  },
                  {
                    label: t("CustomTimeseries"),
                    value: "CustomTimeSeries",
                  },
                  {
                    label: t("UserDefinedTable"),
                    value: "UserDefinedTable",
                  },
                ]}
                styles={selectSetupStyle}
                onChange={(e) => this.props.setWindStatisticSource(e)}
                isSearchable={false}
                //classNamePrefix="select"
              />
              {this.props.wind.windStatisticSource.value !==
                "UserDefinedTable" && (
                <>
                  <div style={{ marginTop: "-8px" }} />
                  <FormFileBrowser
                    id="windData"
                    name="windData"
                    label={`${t("UploadWindData")}`}
                    fileName={
                      this.props.wind.windTableFileName
                        ? this.props.wind.windTableFileName
                        : ""
                    }
                    accept={".txt"}
                    isClearable
                    setup
                    //disabled={props.topographyDisabled}
                    error={""}
                    onChange={this.onWindTableFileChange}
                    clearUploadedFile={this.onWindTableFileClear}
                  />
                </>
              )}
              <div style={{ marginBottom: "2px" }} />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label
                  style={{ marginTop: "12px" }}
                  className="form-label user-settings-form-label"
                >
                  {t("MeasurementHeight")} [m]
                </label>
                <input
                  className="setup-input"
                  name="num"
                  type="number"
                  min="0"
                  max="100"
                  step="0.1"
                  value={this.props.wind.measurementHeight}
                  onChange={(e) =>
                    this.props.setMeasurementHeight(e.target.value)
                  }
                />
              </div>
              <div style={{ marginBottom: "2px" }} />

              <label className="form-label user-settings-form-label">
                {t("MeasurementLocation")}
              </label>
              <Select
                value={{
                  label: t(this.props.wind.measurementLocation.label),
                  value: this.props.wind.measurementLocation.value,
                }}
                options={[
                  {
                    label: t("CityCentre"),
                    value: "CityCentre",
                  },
                  {
                    label: t("City"),
                    value: "City",
                  },
                  {
                    label: t("Grassland"),
                    value: "Grassland",
                  },
                  {
                    label: t("Forest"),
                    value: "Forest",
                  },
                  {
                    label: t("Farmland"),
                    value: "Farmland",
                  },
                  {
                    label: t("UserDefined"),
                    value: "UserDefined",
                  },
                ]}
                styles={selectSetupStyle}
                isSearchable={false}
                onChange={(e) => this.props.setMeasurementLocation(e)}
              />
              <div style={{ marginBottom: "12px" }}></div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label className="form-label user-settings-form-label">
                  {t("ParameterAlpha")} [-]
                </label>
                <input
                  className="setup-input"
                  name="num"
                  type="number"
                  min="0"
                  max="1"
                  step="0.01"
                  value={this.props.wind.measurementAlpha}
                  disabled={
                    this.props.wind.measurementLocation.value === "UserDefined"
                      ? false
                      : true
                  }
                  onChange={(e) =>
                    this.props.setMeasurementAlpha(e.target.value)
                  }
                />
              </div>
              <div style={{ marginBottom: "2px" }} />
              <label className="form-label user-settings-form-label">
                {t("SimulationDomainLocation")}
              </label>
              <Select
                value={{
                  label: t(this.props.wind.simDomainLocation.label),
                  value: this.props.wind.simDomainLocation.value,
                }}
                options={[
                  {
                    label: t("FromStaticDriver"),
                    value: "FromStaticDriver",
                  },
                  {
                    label: t("CityCentre"),
                    value: "CityCentre",
                  },
                  {
                    label: t("City"),
                    value: "City",
                  },
                  {
                    label: t("Grassland"),
                    value: "Grassland",
                  },
                  {
                    label: t("Forest"),
                    value: "Forest",
                  },
                  {
                    label: t("Farmland"),
                    value: "Farmland",
                  },
                  {
                    label: t("UserDefined"),
                    value: "UserDefined",
                  },
                ]}
                styles={selectSetupStyle}
                isSearchable={false}
                onChange={(e) => this.props.setSimDomainLocation(e)}
              />
              {this.props.wind.simDomainLocation.value !==
                "FromStaticDriver" && (
                <>
                  <div style={{ marginBottom: "12px" }}></div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label className="form-label user-settings-form-label">
                      {t("SimulationDomainParameterAlpha")} [-]
                    </label>
                    <input
                      className="setup-input"
                      name="num"
                      type="number"
                      min="0"
                      max="1"
                      step="0.01"
                      value={this.props.wind.simDomainAlpha}
                      disabled={
                        this.props.wind.simDomainLocation.value ===
                        "UserDefined"
                          ? false
                          : true
                      }
                      onChange={(e) =>
                        this.props.setSimDomainAlpha(e.target.value)
                      }
                    />
                  </div>
                </>
              )}
              {this.props.wind.windStatisticSource.value !==
                "UserDefinedTable" && <div style={{ marginBottom: "-10px" }} />}
              {this.props.wind.windStatisticSource.value ===
                "UserDefinedTable" && (
                <>
                  {this.props.wind.simDomainLocation.value ===
                    "FromStaticDriver" && <div style={{ marginTop: 12 }} />}
                  <label className="form-label user-settings-form-label">
                    {t("NumberOfWindDirections")}
                  </label>
                  <Select
                    value={{
                      label: t(this.props.wind.windDirectionsNumber.label),
                      value: this.props.wind.windDirectionsNumber.value,
                    }}
                    options={[
                      {
                        label: t("8WindDirections"),
                        value: 8,
                      },
                      {
                        label: t("12WindDirections"),
                        value: 12,
                      },
                    ]}
                    styles={selectSetupStyle}
                    onChange={(e) => this.props.setWindDirectionsNumber(e)}
                    isSearchable={false}
                    //classNamePrefix="select"
                  />

                  <div className={"table-animation"}>
                    <div
                      className="wind-container"
                      style={{ marginTop: 30, minWidth: 805 }}
                    >
                      <WindTable
                        key={this.props.wind.windDirectionsNumber.value}
                        numberOfWindDirections={
                          this.props.wind.windDirectionsNumber.value
                        }
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            <div style={{ marginBottom: 36 }}></div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <button
                id="thermal-btn__save"
                className="wizard-button wizard-button-submit"
                onClick={this.saveCaseSetup}
                disabled={
                  (this.props.wind.windTableFileName === "" &&
                    (this.props.wind.windStatisticSource.value ===
                      "DWDClimateDataCenter" ||
                      this.props.wind.windStatisticSource.value ===
                        "CustomTimeSeries")) ||
                  (this.props.simulationJobs?.length ||
                  this.props.currentCase?.steering ||
                  this.props.simulationNotifications
                    ? true
                    : false ||
                      (this.props.currentCase?.staticDriverJob?.status !==
                        "Completed" &&
                        !this.state.savedStaticDriver))
                }
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "34px",
                }}
              >
                {!this.state.isSaving && <span>{t("Save")}</span>}
                {this.state.isSaving && <Loader size="x-small" mode="light" />}
              </button>
              {this.state.saveCompleted && (
                <div className="setup-save-status-wrapper">
                  <CheckCircle
                    width="17"
                    stroke="rgb(6,137,108)"
                    strokeWidth="2"
                  />
                  <div className="save-status-text">
                    {t("SetupHasBeenSaved")}
                  </div>
                </div>
              )}
              {this.props.simulationJobs?.length ||
              !!this.props.simulationNotifications ? (
                <div className="setup-save-status-wrapper">
                  <XCircle size="17" stroke="#a81d1dcf" strokeWidth="2" />
                  <div className="save-status-disabled">
                    {t("SavingSetupDisabledSimStarted")}
                  </div>
                </div>
              ) : this.props.currentCase?.staticDriverJob?.status !==
                  "Completed" && !this.state.savedStaticDriver ? (
                <div className="setup-save-status-wrapper">
                  <XCircle size="17" stroke="#a81d1dcf" strokeWidth="2" />
                  <div className="save-status-disabled">
                    {t("SavingSetupDisabledNoStaticDriver")}
                  </div>
                </div>
              ) : this.props.currentCase?.steering ? (
                <div className="setup-save-status-wrapper">
                  <XCircle size="17" stroke="#a81d1dcf" strokeWidth="2" />
                  <div className="save-status-disabled">
                    {t("SavingSetupDisabledInputChecked")}
                  </div>
                </div>
              ) : this.props.wind.windTableFileName === "" &&
                (this.props.wind.windStatisticSource.value ===
                  "DWDClimateDataCenter" ||
                  this.props.wind.windStatisticSource.value ===
                    "CustomTimeSeries") ? (
                <div className="setup-save-status-wrapper">
                  <XCircle size="17" stroke="#a81d1dcf" strokeWidth="2" />
                  <div className="save-status-disabled">
                    {t("SavingSetupDisabledNoWindFile")}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentCaseId: state.projects?.currentCaseId,
    projectId: state.projects?.loadedProject?.id,
    wind: state.wind,
    simulationSettings: state.simulationSettings,
    rasterArea: state.rasterArea,
    simulationJobs: state.projects?.currentCase?.simulationJobs,
    staticDriverNotifications: state.notifications.notifications,
    simulationNotifications: selectCaseSimulationNotifications(state),
    currentCase: state.projects?.currentCase,
    epsg: state.projects.loadedProject?.epsg,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setWindStatisticSource: (payload) =>
      dispatch(setWindStatisticSource(payload)),
    setWindDirectionsNumber: (payload) =>
      dispatch(setWindDirectionsNumber(payload)),
    setMeasurementHeight: (payload) => dispatch(setMeasurementHeight(payload)),
    setMeasurementLocation: (payload) =>
      dispatch(setMeasurementLocation(payload)),
    setMeasurementAlpha: (payload) => dispatch(setMeasurementAlpha(payload)),
    setSimDomainLocation: (payload) => dispatch(setSimDomainLocation(payload)),
    setSimDomainAlpha: (payload) => dispatch(setSimDomainAlpha(payload)),
    updateWindTable: (payload) => dispatch(updateWindTable(payload)),
    loadWindSettings: (payload) => dispatch(loadWindSettings(payload)),
    resetWindSettings: () => dispatch(resetWindSettings()),
    setWindTableFile: (payload) => dispatch(setWindTableFile(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(WindComfort)));
