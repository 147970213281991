import history from "./history";
import { types } from "../types/projects";
import * as actions from "../actions/createProjectActions";
import {
  geojsonCheckAsync,
  topographyConversionAsync,
} from "../../api/checkApi";
import { call, takeEvery, put } from "redux-saga/effects";
import * as projectsApi from "../../api/projectsApi";
import { uploadBlobToAzure } from "../../api/storageApi";

function* createProject(action) {
  try {
    const formData = new FormData();
    const newProject = action.payload;

    formData.append("Name", newProject.projectName);
    formData.append("DefaultCase.Name", newProject.caseName);
    formData.append("Description", newProject.description || "");
    formData.append(
      "DefaultCase.Description",
      newProject.caseInformation || ""
    );
    formData.append(
      "DefaultCase.ApplicationField",
      newProject.applicationField
    );
    formData.append("Picture", newProject.picture);
    formData.append("EPSG", newProject.epsg || "");

    const folderName = new Date().getTime().toString();
    switch (newProject.type) {
      case 0: {
        formData.append(
          "DefaultCase.OriginalGeojsonUrl",
          newProject.originalGeojsonUrl ?? ""
        );
        formData.append(
          "DefaultCase.VerifiedGeojsonUrl",
          newProject.geojsonUrl ?? ""
        );
        formData.append(
          "DefaultCase.TopographyUrl",
          newProject.topographyUrl ?? ""
        );
        formData.append(
          "DefaultCase.OriginalTopographyUrl",
          newProject.originalTopographyUrl ?? ""
        );
        const projectId = yield call(projectsApi.postQgisProject, formData);
        history.push(`/projects/${projectId}/view`);
        yield put(actions.createProjectSuccess(projectId));
        break;
      }
      case 1: {
        const staticDriverUrl = `${folderName}/${newProject.caseName}/${newProject.staticDriver.name}`;
        yield call(uploadBlobToAzure, staticDriverUrl, newProject.staticDriver);
        formData.append("DefaultCase.staticDriverUrl", staticDriverUrl);

        const projectId = yield call(
          projectsApi.postStaticDriverProject,
          formData
        );
        yield put(actions.createProjectSuccess(projectId));
        history.push(`/projects/${projectId}/view`);
        break;
      }
      default:
        break;
    }
  } catch (error) {
    yield put(actions.createProjectFailure(error));
  }
}

function* checkGeojson(action) {
  yield put(actions.uploadGeojson());
  const { geojsonUrl, geojson } = action.payload;
  yield call(uploadBlobToAzure, geojsonUrl, geojson);
  yield put(actions.uploadGeojsonSuccess(geojsonUrl));
  const geojsonCheckRequestId = yield call(geojsonCheckAsync, geojsonUrl);
  yield put(actions.setCheckGeojsonRequest(geojsonCheckRequestId));
}

function* convertTopography(action) {
  yield put(actions.uploadTopography());
  const { topographyUrl, topography } = action.payload;
  yield call(uploadBlobToAzure, topographyUrl, topography);
  yield put(actions.uploadTopographySuccess(topographyUrl));
  const topographyConversionRequestId = yield call(
    topographyConversionAsync,
    topographyUrl
  );
  yield put(actions.setConvertTopographyRequest(topographyConversionRequestId));
}

export default function* createProjectsSaga() {
  yield takeEvery(types.CREATE_PROJECT, createProject);
  yield takeEvery(types.CHECK_GEOJSON, checkGeojson);
  yield takeEvery(types.CONVERT_TOPOGRAPHY, convertTopography);
}
