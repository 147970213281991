import React from "react";
import Legend from "./Legend";
import { connect } from "react-redux";

const LegendWrapper = (props) => {
  return <>{props.legendVisibility && <Legend />}</>;
};

const mapStateToProps = (state) => {
  return {
    legendVisibility: state.legend.legendVisibility,
  };
};

export default connect(mapStateToProps, null)(LegendWrapper);
