import React from "react";
import "./IconButton.scss";

const IconButton = (props) => {
  const Icon = props.icon;
  return (
    <div
      className={`icon-button ${props.className || ""}`}
      onClick={props.onClick}
    >
      <Icon className="icon-button_icon" />
      {props.children}
    </div>
  );
};

export default IconButton;
