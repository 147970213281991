import React, { useState, useEffect } from "react";
import DeleteModal from "../../Components/Common/DeleteModal/index";
import FormSeparator from "../../Components/Common/Form/FormSeparator";
import { Loader } from "../../Components/Loader";
import Page from "../../Components/Page/Page";
import IconButton from "../../Components/Common/Button/Icon/IconButton";
import { FileList } from "../../Components/Common/FileList/FileList";
import FormFileBrowser from "../../Components/Common/Form/FormFileBrowser/FormFileBrowser";
import { ReactComponent as PdfLogo } from "../../static/icons/pdf.svg";
import { ReactComponent as TiffLogo } from "../../static/icons/tiff.svg";
import { ReactComponent as NcLogo } from "../../static/icons/nc.svg";
import { getDownloadLink } from "./../../api/storageApi";
import { getCaseSetup } from "../../api/simulationApi";
import { Save } from "react-feather";
import { cloneDeep } from "lodash";
import * as actions from "../../redux/actions/projectFilesActions";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Files.scss";

const acceptedFileTypes = ["application/pdf", "image/tiff"];

const Files = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const project = useSelector((state) => state.projects?.loadedProject);
  const uploadResults = useSelector(
    (state) => state.projectFiles.filesByProject[project?.id]
  );
  const isUploading = useSelector((state) => state.projectFiles.isUploading);
  const filesBeingDeleted = useSelector(
    (state) => state.projectFiles.filesBeingDeleted
  );
  const deletedFiles = useSelector((state) => state.projectFiles.deletedFiles);

  const [newFile, setNewFile] = useState(null);
  const [projectFiles, setProjectFiles] = useState([]);
  const [pdfExpanded, setPdfExpanded] = useState(true);
  const [tiffExpanded, setTiffExpanded] = useState(false);
  const [ncExpanded, setNcExpanded] = useState(false);
  const [deleteModal, setDeleteModal] = useState({
    show: false,
    data: null,
  });

  const showDeleteModal = (data) => setDeleteModal({ show: true, data: data });
  const closeDeleteModal = () =>
    setDeleteModal({
      show: false,
      data: null,
    });

  const newFileChange = (file) => {
    const fileName = file.name;
    const extensionName = fileName.split(".").pop();

    if (acceptedFileTypes.includes(file.type) || extensionName === "nc") {
      setNewFile(file);
      return;
    }
    setNewFile(null);
  };

  useEffect(() => {
    if (isUploading) {
      return;
    }
    const succeededUploads =
      uploadResults?.filter(
        (r) => !projectFiles.some((p) => p.path === r.path)
      ) || [];

    const newUploads = succeededUploads.filter(
      (f) => !projectFiles.some((pf) => pf.name === f.name)
    );
    setNewFile(null);

    setProjectFiles([...succeededUploads, ...projectFiles]);
    if (!!newUploads.filter((f) => f.type.includes("pdf").length) > 0) {
      setPdfExpanded(true);
    }
    if (!!newUploads.filter((f) => f.type.includes("tif")).length > 0) {
      setTiffExpanded(true);
    }
    if (!!newUploads.filter((f) => f.type.includes("")).length > 0) {
      setNcExpanded(true);
    }
  }, [uploadResults, isUploading]);

  useEffect(() => {
    if (!project) {
      setProjectFiles([]);
      return;
    }

    if (project) {
      const caseDetailsDto = cloneDeep(project.caseDetailsDto);
      if (caseDetailsDto.length !== 0) {
        const ncIndexToDisable = [];
        const tiffIndexToDisable = [];

        if (caseDetailsDto.some((x) => x.rasterParameters?.additionalData)) {
          caseDetailsDto.map((x) => {
            const buildingSettings =
              x.rasterParameters?.additionalData?.globalParameters
                ?.buildingSettings;
            const treeSettings =
              x.rasterParameters?.additionalData?.globalParameters
                ?.treeSettings;

            if (buildingSettings?.bhParentFile) {
              const tiffIndexInProjectFiles = project.files?.findIndex(
                (y) => y.path === buildingSettings.bhParentFile.path
              );
              if (tiffIndexInProjectFiles !== -1) {
                tiffIndexToDisable.push(tiffIndexInProjectFiles);
              }
            }

            if (treeSettings?.thParentFile) {
              const tiffIndexInProjectFiles = project.files?.findIndex(
                (y) => y.path === treeSettings.thParentFile.path
              );
              if (tiffIndexInProjectFiles !== -1) {
                tiffIndexToDisable.push(tiffIndexInProjectFiles);
              }
            }

            if (x?.rasterParameters?.childRasterParameters) {
              if (buildingSettings?.bhChildFile) {
                const tiffIndexInProjectFiles = project.files?.findIndex(
                  (y) => y.path === buildingSettings.bhChildFile.path
                );
                if (tiffIndexInProjectFiles !== -1) {
                  tiffIndexToDisable.push(tiffIndexInProjectFiles);
                }
              }

              if (treeSettings?.thChildFile) {
                const tiffIndexInProjectFiles = project.files?.findIndex(
                  (y) => y.path === treeSettings.thChildFile.path
                );
                if (tiffIndexInProjectFiles !== -1) {
                  tiffIndexToDisable.push(tiffIndexInProjectFiles);
                }
              }
            }
          });
        }

        if (caseDetailsDto.some((x) => x.applicationField === 1)) {
          const getFiles = async () => {
            const getCasePromise = caseDetailsDto.map(async (x) => {
              const caseSetup = await getCaseSetup(x.id);
              const caseDynamicDriverPath =
                caseSetup.setup?.settings?.thermal_comfort_parameters
                  ?.path_user_dynamic_driver?.path;
              if (caseDynamicDriverPath) {
                const ncIndexInProjectFiles = project.files?.findIndex(
                  (x) => x.path === caseDynamicDriverPath
                );
                if (ncIndexInProjectFiles !== -1) {
                  ncIndexToDisable.push(ncIndexInProjectFiles);
                }
              }
            });

            await Promise.all(getCasePromise);
            project.files.map((x, i) => {
              x.disabled = false;
              if (
                (ncIndexToDisable.length !== 0 &&
                  ncIndexToDisable.includes(i)) ||
                (tiffIndexToDisable.length !== 0 &&
                  tiffIndexToDisable.includes(i))
              ) {
                x.disabled = true;
              }
            });
            setProjectFiles(project.files);
          };
          getFiles();
        } else {
          project.files.map((x, i) => {
            x.disabled = false;
            if (
              (ncIndexToDisable.length !== 0 && ncIndexToDisable.includes(i)) ||
              (tiffIndexToDisable.length !== 0 &&
                tiffIndexToDisable.includes(i))
            ) {
              x.disabled = true;
            }
          });
          setProjectFiles(project.files);
        }
      }
    } else {
      setProjectFiles(project.files?.reverse());
    }
  }, [project]);

  const saveFile = () => {
    dispatch(actions.updateFile(project.id, newFile));
  };

  const deleteFile = (file) => {
    dispatch(actions.deleteFile(project.id, file.name));
    closeDeleteModal();
  };

  const openFile = async (filePath) => {
    const url = await getDownloadLink(filePath);
    window.open(url);
  };

  const downloadFile = async (filePath) => {
    const url = await getDownloadLink(filePath);
    var link = document.createElement("a");
    link.download = "download";
    link.href = url;
    link.click();
  };

  const groupedFiles = projectFiles
    .filter((f) => !deletedFiles.includes(f.name))
    .reduce((groups, file) => {
      (groups[file.type] = groups[file.type] || []).push({
        ...file,
        isDeleting: filesBeingDeleted.includes(file.name),
      });
      return groups;
    }, {});

  return project ? (
    <Page>
      <div className="files-page-wrapper" style={{ width: "500px" }}>
        <h2 style={{ fontSize: 28 }} className="settings-hdr">
          {t("Files")}
        </h2>
        <div style={{ width: "450px" }}>
          <FormSeparator label={t("UploadFile")} />
        </div>
        <div className="file-upload">
          <FormFileBrowser
            name="addFile"
            disabled={isUploading}
            fileName={newFile?.name || ""}
            onChange={newFileChange}
            clearUploadedFile={() => setNewFile(null)}
            accept={[".tif", ".tiff", ".pdf", ".nc"]}
            projectFiles
            isClearable
          />
          {!!newFile && !isUploading && (
            <IconButton
              className="file-submit-btn"
              icon={Save}
              onClick={saveFile}
            ></IconButton>
          )}
          {isUploading && <Loader size="x-small" />}
        </div>
        <div style={{ width: "450px" }}>
          <FormSeparator label={t("UploadedFilesList")} />
        </div>
        <FileList
          items={(groupedFiles["application/pdf"] || []).map((f) => ({
            ...f,
            icon: PdfLogo,
          }))}
          label={t("Pdf")}
          expanded={pdfExpanded}
          onDelete={(f) => showDeleteModal(f)}
          viewable
          onView={(f) => openFile(f.path)}
          onDownload={(f) => downloadFile(f.path)}
        />
        <FileList
          className="file-list__nc"
          items={(groupedFiles["image/tiff"] || []).map((f) => ({
            ...f,
            icon: TiffLogo,
          }))}
          label={t("Tiff")}
          expanded={tiffExpanded}
          isDeleting={true}
          onDelete={(f) => showDeleteModal(f)}
          onDownload={(f) => downloadFile(f.path)}
        />
        <FileList
          className="file-list__nc"
          items={(groupedFiles[""] || []).map((f) => ({
            ...f,
            icon: NcLogo,
          }))}
          label={t("NC")}
          expanded={ncExpanded}
          isDeleting={true}
          onDelete={(f) => showDeleteModal(f)}
          onDownload={(f) => downloadFile(f.path)}
        />
      </div>
      <DeleteModal
        modalHdr={t("DeleteFile")}
        modalInfo={t("DeleteFileModalInfo")}
        isOpen={deleteModal.show}
        closeDeleteModal={closeDeleteModal}
        deleteModalData={() =>
          deleteModal.data ? deleteFile(deleteModal.data) : null
        }
      />
    </Page>
  ) : (
    <Loader />
  );
};

export default withRouter(Files);
