export const templatesJson = [
  {
    t: 1,
    t1: 1,
    bh: 25,
    wallfrac: 0.82,
    windfrac: 0.18,
    greenfrac_wa: 0,
    greenfrac_ro: 0,
    wallfrac_ro: 1,
    windfrac_ro: 0,
    storey_height: 2.9,
    ceiling_height: 0.2,
    ground_h: 2.9,

    d_wall1: 0.02,
    d_wall2: 0.2,
    d_wall3: 0.38,
    d_wall4: 0.4,
    c_w1: 1520000,
    c_w2: 1512000,
    c_w3: 1512000,
    c_w4: 1526000,
    lamb_w1: 0.93,
    lamb_w2: 0.81,
    lamb_w3: 0.81,
    lamb_w4: 0.7,
    lamb_surf: 20000,
    c_surf_b: 23,
    e_wallfrac: 0.93,
    a_wallfrac: 36,
    z0_m_b: 0.001,
    z0h_h_b: 0.0001,

    d_std_w1: 0.02,
    d_std_w2: 0.04,
    d_std_w3: 0.06,
    d_std_w4: 0.08,
    c_std_1: 1736000,
    c_std_2: 1736000,
    c_std_3: 1736000,
    c_window4: 1736000,
    lamb_std_1: 0.45,
    lamb_std_2: 0.45,
    lamb_std_3: 0.45,
    lamb_window4: 0.45,
    e_windfrac: 0.91,
    t_windfrac: 0.7,
    a_windfrac: 37,

    d_roof_1: 0.02,
    d_roof_2: 0.06,
    d_roof_3: 0.08,
    d_roof_4: 0.1,
    c_roof_1: 1512000,
    c_roof_2: 709650,
    c_roof_3: 709650,
    c_w4_roof: 1526000,
    lamb_roof_1: 0.52,
    lamb_roof_2: 0.12,
    lamb_roof_3: 0.12,
    lambda_w4_roof: 0.7,
    e_roof: 0.9,
    a_roof: 42,

    d_roof_w1: 0.02,
    d_roof_w2: 0.04,
    d_roof_w3: 0.06,
    d_roof_w4: 0.08,
    c_roof_w1: 1736000,
    c_roof_w2: 1736000,
    c_roof_w3: 1736000,
    c_window4_roof: 1736000,
    lamb_roof_w1: 0.45,
    lamb_roof_w2: 0.45,
    lamb_roof_w3: 0.45,
    lambda_window4_roof: 0.45,
    e_roof_w: 0.91,
    t_roof_w: 0.7,
    a_roof_w: 37,

    t_roof_grsurf: 1,
    LAI_ro: 1.5,
    a_roof_grsurf: 5,
    e_roof_grsurf: 0.86,
    LAI_wa: 1.5,
    c_surf_green: 20000,
    lamb_surf_green: 10,
    e_greenfrac: 0.86,
    a_greenfrac: 5,

    shading_fac: 0.75,
    g_value: 0.8,
    u_value: 2.9,
    c_surf_wind: 20000,
    lamb_surf_wind: 23,

    targ_t_sum: 26,
    targ_t_win: 20,
    additi_gains: 0,
    basic_gains: 4.2,
    bairflow: 0.5,
    aairflow_occ: 2,

    heatrec_eff: 0,
    activesurf_fac: 3,
    factor_a: 260000,
    lambda_at: 4.5,
    maxheat: 100,
    maxcool: 0,
    antro_heat_fac: 0.1,
    antro_cool_fac: 1.333,

    groundfrac_gf: 0.82,
    windfrac_gf: 0.18,
    greenfrac_gf: 0,
    greenfrac_ro_gf: 0,
    LAI_wall_gf: 1.5,
    d_ff_1: 0.02,
    d_ff_2: 0.2,
    d_ff_3: 0.38,
    d_ff_4: 0.4,
    c_1_wall_gf: 1520000,
    c_2_wall_gf: 1512000,
    c_3_wall_gf: 1512000,
    c_w4_wall_gf: 1526000,
    lamb_1_wall_gf: 0.93,
    lamb_2_wall_gf: 0.81,
    lamb_3_wall_gf: 0.81,
    lamb_w4_wall_gf: 0.7,
    e_wallfrac_gf: 0.93,
    e_greenfrac_gf: 0.91,
    e_windowfrac_gf: 0.86,
    t_windowfrac_gf: 0.7,
    z0_m_b_gf: 0.001,
    z0_h_b_gf: 0.0001,
    a_gf_w: 36,
    d_ff_w1: 0.02,
    d_ff_w2: 0.04,
    d_ff_w3: 0.06,
    d_ff_w4: 0.08,
    c_ff_w1: 1736000,
    c_ff_w2: 1736000,
    c_ff_w3: 1736000,
    c_window4_gf: 1736000,
    lamb_ff_w1: 0.45,
    lamb_ff_w2: 0.45,
    lamb_ff_w3: 0.45,
    lamb_window4_gf: 0.45,
    a_ff_w: 37,
    a_ff_grsurf: 5,
  },
  {
    t: 1,
    t1: 2,
    bh: 25,
    wallfrac: 0.75,
    windfrac: 0.25,
    greenfrac_wa: 0,
    greenfrac_ro: 0,
    wallfrac_ro: 1,
    windfrac_ro: 0,
    storey_height: 2.5,
    ceiling_height: 0.2,
    ground_h: 2.5,

    d_wall1: 0.02,
    d_wall2: 0.08,
    d_wall3: 0.32,
    d_wall4: 0.34,
    c_w1: 1520000,
    c_w2: 79200,
    c_w3: 2112000,
    c_w4: 1526000,
    lamb_w1: 0.93,
    lamb_w2: 0.046,
    lamb_w3: 2.1,
    lamb_w4: 0.7,
    c_surf_b: 20000,
    lamb_surf: 23,
    e_wallfrac: 0.93,
    a_wallfrac: 36,
    z0_m_b: 0.001,
    z0h_h_b: 0.0001,

    d_std_w1: 0.02,
    d_std_w2: 0.04,
    d_std_w3: 0.06,
    d_std_w4: 0.08,
    c_std_1: 1736000,
    c_std_2: 1736000,
    c_std_3: 1736000,
    c_window4: 1736000,

    lamb_std_1: 0.19,
    lamb_std_2: 0.19,
    lamb_std_3: 0.19,
    lamb_window4: 0.19,
    e_windfrac: 0.87,
    t_windfrac: 0.65,
    a_windfrac: 37,

    d_roof_1: 0.02,
    d_roof_2: 0.17,
    d_roof_3: 0.37,
    d_roof_4: 0.39,
    c_roof_1: 1700000,
    c_roof_2: 79200,
    c_roof_3: 2112000,
    c_w4_roof: 1526000,
    lamb_roof_1: 0.16,
    lamb_roof_2: 0.046,
    lamb_roof_3: 2.1,
    lambda_w4_roof: 0.7,
    e_roof: 0.93,
    a_roof: 42,

    d_roof_w1: 0.02,
    d_roof_w2: 0.04,
    d_roof_w3: 0.06,
    d_roof_w4: 0.08,
    c_roof_w1: 1736000,
    c_roof_w2: 1736000,
    c_roof_w3: 1736000,
    c_window4_roof: 1736000,
    lamb_roof_w1: 0.19,
    lamb_roof_w2: 0.19,
    lamb_roof_w3: 0.19,
    lambda_window4_roof: 0.19,
    e_roof_w: 0.87,
    t_roof_w: 0.65,
    a_roof_w: 37,

    t_roof_grsurf: 1,
    LAI_ro: 1.5,
    a_roof_grsurf: 5,
    e_roof_grsurf: 0.86,
    LAI_wa: 1.5,
    c_surf_green: 20000,
    lamb_surf_green: 10,
    e_greenfrac: 0.86,
    a_greenfrac: 5,

    shading_fac: 0.75,
    g_value: 0.7,
    u_value: 1.7,
    c_surf_wind: 20000,
    lamb_surf_wind: 23,

    targ_t_sum: 26,
    targ_t_win: 20,
    additi_gains: 0,
    basic_gains: 4.2,
    bairflow: 0.5,
    aairflow_occ: 1.5,

    heatrec_eff: 0,
    activesurf_fac: 3.5,
    factor_a: 370000,
    lambda_at: 4.5,
    maxheat: 80,
    maxcool: 0,
    antro_heat_fac: 0,
    antro_cool_fac: 2.54,

    groundfrac_gf: 0.75,
    windfrac_gf: 0.25,
    greenfrac_gf: 0,
    greenfrac_ro_gf: 0,
    LAI_wall_gf: 1.5,
    d_ff_1: 0.02,
    d_ff_2: 0.08,
    d_ff_3: 0.32,
    d_ff_4: 0.34,
    c_1_wall_gf: 1520000,
    c_2_wall_gf: 79200,
    c_3_wall_gf: 2112000,
    c_w4_wall_gf: 1526000,
    lamb_1_wall_gf: 0.93,
    lamb_2_wall_gf: 0.046,
    lamb_3_wall_gf: 2.1,
    lamb_w4_wall_gf: 0.7,
    e_wallfrac_gf: 0.93,
    e_greenfrac_gf: 0.87,
    e_windowfrac_gf: 0.86,
    t_windowfrac_gf: 0.65,
    z0_m_b_gf: 0.001,
    z0_h_b_gf: 0.0001,
    a_gf_w: 36,
    d_ff_w1: 0.02,
    d_ff_w2: 0.04,
    d_ff_w3: 0.06,
    d_ff_w4: 0.08,
    c_ff_w1: 1736000,
    c_ff_w2: 1736000,
    c_ff_w3: 1736000,
    c_window4_gf: 1736000,
    lamb_ff_w1: 0.19,
    lamb_ff_w2: 0.19,
    lamb_ff_w3: 0.19,
    lamb_window4_gf: 0.19,
    a_ff_w: 37,
    a_ff_grsurf: 5,
  },
  {
    t: 1,
    t1: 3,
    bh: 25,
    wallfrac: 0.71,
    windfrac: 0.29,
    greenfrac_wa: 0,
    greenfrac_ro: 0,
    wallfrac_ro: 1,
    windfrac_ro: 0,
    storey_height: 2.7,
    ceiling_height: 0.2,
    ground_h: 2.7,

    d_wall1: 0.02,
    d_wall2: 0.22,
    d_wall3: 0.58,
    d_wall4: 0.6,
    c_w1: 1520000,
    c_w2: 79200,
    c_w3: 1344000,
    c_w4: 1526000,
    lamb_w1: 0.93,
    lamb_w2: 0.035,
    lamb_w3: 0.68,
    lamb_w4: 0.7,
    c_surf_b: 20000,
    lamb_surf: 23,
    e_wallfrac: 0.93,
    a_wallfrac: 36,
    z0_m_b: 0.001,
    z0h_h_b: 0.0001,

    d_std_w1: 0.03,
    d_std_w2: 0.06,
    d_std_w3: 0.09,
    d_std_w4: 0.12,
    c_std_1: 1736000,
    c_std_2: 1736000,
    c_std_3: 1736000,
    c_window4: 1736000,

    lamb_std_1: 0.11,
    lamb_std_2: 0.11,
    lamb_std_3: 0.11,
    lamb_window4: 0.11,
    e_windfrac: 0.8,
    t_windfrac: 0.57,
    a_windfrac: 38,

    d_roof_1: 0.02,
    d_roof_2: 0.06,
    d_roof_3: 0.36,
    d_roof_4: 0.38,
    c_roof_1: 3753600,
    c_roof_2: 709650,
    c_roof_3: 79200,
    c_w4_roof: 1526000,
    lamb_roof_1: 0.52,
    lamb_roof_2: 0.12,
    lamb_roof_3: 0.035,
    lambda_w4_roof: 0.7,
    e_roof: 0.93,
    a_roof: 42,

    d_roof_w1: 0.03,
    d_roof_w2: 0.06,
    d_roof_w3: 0.09,
    d_roof_w4: 0.12,
    c_roof_w1: 1736000,
    c_roof_w2: 1736000,
    c_roof_w3: 1736000,
    c_window4_roof: 1736000,
    lamb_roof_w1: 0.11,
    lamb_roof_w2: 0.11,
    lamb_roof_w3: 0.11,
    lambda_window4_roof: 0.11,
    e_roof_w: 0.8,
    t_roof_w: 0.57,
    a_roof_w: 38,

    t_roof_grsurf: 1,
    LAI_ro: 1.5,
    a_roof_grsurf: 5,
    e_roof_grsurf: 0.86,
    LAI_wa: 1.5,
    c_surf_green: 20000,
    lamb_surf_green: 10,
    e_greenfrac: 0.86,
    a_greenfrac: 5,

    shading_fac: 0.15,
    g_value: 0.6,
    u_value: 0.8,
    c_surf_wind: 20000,
    lamb_surf_wind: 23,

    targ_t_sum: 26,
    targ_t_win: 20,
    additi_gains: 0,
    basic_gains: 4.2,
    bairflow: 0.5,
    aairflow_occ: 1.5,

    heatrec_eff: 0.8,
    activesurf_fac: 2.5,
    factor_a: 165000,
    lambda_at: 4.5,
    maxheat: 40,
    maxcool: 0,
    antro_heat_fac: -2,
    antro_cool_fac: 1.25,

    groundfrac_gf: 0.71,
    windfrac_gf: 0.29,
    greenfrac_gf: 0,
    greenfrac_ro_gf: 0,
    LAI_wall_gf: 1.5,
    d_ff_1: 0.02,
    d_ff_2: 0.22,
    d_ff_3: 0.58,
    d_ff_4: 0.6,
    c_1_wall_gf: 1520000,
    c_2_wall_gf: 79200,
    c_3_wall_gf: 1344000,
    c_w4_wall_gf: 1526000,
    lamb_1_wall_gf: 0.93,
    lamb_2_wall_gf: 0.035,
    lamb_3_wall_gf: 0.68,
    lamb_w4_wall_gf: 0.7,
    e_wallfrac_gf: 0.93,
    e_greenfrac_gf: 0.8,
    e_windowfrac_gf: 0.86,
    t_windowfrac_gf: 0.57,
    z0_m_b_gf: 0.001,
    z0_h_b_gf: 0.0001,
    a_gf_w: 36,
    d_ff_w1: 0.03,
    d_ff_w2: 0.06,
    d_ff_w3: 0.09,
    d_ff_w4: 0.012,
    c_ff_w1: 1736000,
    c_ff_w2: 1736000,
    c_ff_w3: 1736000,
    c_window4_gf: 1736000,
    lamb_ff_w1: 0.11,
    lamb_ff_w2: 0.11,
    lamb_ff_w3: 0.11,
    lamb_window4_gf: 0.11,
    a_ff_w: 38,
    a_ff_grsurf: 5,
  },
  {
    t: 1,
    t1: 4,
    bh: 25,
    wallfrac: 0.82,
    windfrac: 0.18,
    greenfrac_wa: 0,
    greenfrac_ro: 0,
    wallfrac_ro: 1,
    windfrac_ro: 0,
    storey_height: 2.9,
    ceiling_height: 0.2,
    ground_h: 2.9,

    d_wall1: 0.02,
    d_wall2: 0.2,
    d_wall3: 0.38,
    d_wall4: 0.4,
    c_w1: 1520000,
    c_w2: 1512000,
    c_w3: 1512000,
    c_w4: 1526000,
    lamb_w1: 0.93,
    lamb_w2: 0.81,
    lamb_w3: 0.81,
    lamb_w4: 0.7,
    c_surf_b: 20000,
    lamb_surf: 23,
    e_wallfrac: 0.93,
    a_wallfrac: 36,
    z0_m_b: 0.001,
    z0h_h_b: 0.0001,

    d_std_w1: 0.02,
    d_std_w2: 0.04,
    d_std_w3: 0.06,
    d_std_w4: 0.08,
    c_std_1: 1736000,
    c_std_2: 1736000,
    c_std_3: 1736000,
    c_window4: 1736000,
    lamb_std_1: 0.45,
    lamb_std_2: 0.45,
    lamb_std_3: 0.45,
    lamb_window4: 0.45,
    e_windfrac: 0.91,
    t_windfrac: 0.7,
    a_windfrac: 37,
    d_roof_1: 0.02,
    d_roof_2: 0.06,
    d_roof_3: 0.08,
    d_roof_4: 0.1,
    c_roof_1: 1512000,
    c_roof_2: 709650,
    c_roof_3: 709650,
    c_w4_roof: 1526000,
    lamb_roof_1: 0.52,
    lamb_roof_2: 0.12,
    lamb_roof_3: 0.12,
    lambda_w4_roof: 0.7,
    e_roof: 0.9,
    a_roof: 42,

    d_roof_w1: 0.02,
    d_roof_w2: 0.04,
    d_roof_w3: 0.06,
    d_roof_w4: 0.08,
    c_roof_w1: 1736000,
    c_roof_w2: 1736000,
    c_roof_w3: 1736000,
    c_window4_roof: 1736000,
    lamb_roof_w1: 0.45,
    lamb_roof_w2: 0.45,
    lamb_roof_w3: 0.45,
    lambda_window4_roof: 0.45,
    e_roof_w: 0.91,
    t_roof_w: 0.7,
    a_roof_w: 37,

    t_roof_grsurf: 1,
    LAI_ro: 1.5,
    a_roof_grsurf: 5,
    e_roof_grsurf: 0.86,
    LAI_wa: 1.5,
    c_surf_green: 20000,
    lamb_surf_green: 10,
    e_greenfrac: 0.86,
    a_greenfrac: 5,

    shading_fac: 0.75,
    g_value: 0.8,
    u_value: 2.9,
    c_surf_wind: 20000,
    lamb_surf_wind: 23,

    targ_t_sum: 26,
    targ_t_win: 20,
    additi_gains: 7,
    basic_gains: 3,
    bairflow: 1,
    aairflow_occ: 1,

    heatrec_eff: 0,
    activesurf_fac: 3,
    factor_a: 260000,
    lambda_at: 4.5,
    maxheat: 100,
    maxcool: 0,
    antro_heat_fac: 0.1,
    antro_cool_fac: 1.333,

    groundfrac_gf: 0.82,
    windfrac_gf: 0.18,
    greenfrac_gf: 0,
    greenfrac_ro_gf: 0,
    LAI_wall_gf: 1.5,
    d_ff_1: 0.02,
    d_ff_2: 0.2,
    d_ff_3: 0.38,
    d_ff_4: 0.4,
    c_1_wall_gf: 1520000,
    c_2_wall_gf: 1512000,
    c_3_wall_gf: 1512000,
    c_w4_wall_gf: 1526000,
    lamb_1_wall_gf: 0.93,
    lamb_2_wall_gf: 0.81,
    lamb_3_wall_gf: 0.81,
    lamb_w4_wall_gf: 0.7,
    e_wallfrac_gf: 0.93,
    e_greenfrac_gf: 0.91,
    e_windowfrac_gf: 0.86,
    t_windowfrac_gf: 0.7,
    z0_m_b_gf: 0.001,
    z0_h_b_gf: 0.0001,
    a_gf_w: 36,
    d_ff_w1: 0.02,
    d_ff_w2: 0.04,
    d_ff_w3: 0.06,
    d_ff_w4: 0.08,
    c_ff_w1: 1736000,
    c_ff_w2: 1736000,
    c_ff_w3: 1736000,
    c_window4_gf: 1736000,
    lamb_ff_w1: 0.45,
    lamb_ff_w2: 0.45,
    lamb_ff_w3: 0.45,
    lamb_window4_gf: 0.45,
    a_ff_w: 37,
    a_ff_grsurf: 5,
  },
  {
    t: 1,
    t1: 5,
    bh: 25,
    wallfrac: 0.75,
    windfrac: 0.25,
    greenfrac_wa: 0,
    greenfrac_ro: 0,
    wallfrac_ro: 1,
    windfrac_ro: 0,
    storey_height: 2.5,
    ceiling_height: 0.2,
    ground_h: 2.5,

    d_wall1: 0.02,
    d_wall2: 0.08,
    d_wall3: 0.32,
    d_wall4: 0.34,
    c_w1: 1520000,
    c_w2: 79200,
    c_w3: 2112000,
    c_w4: 1526000,
    lamb_w1: 0.93,
    lamb_w2: 2.1,
    lamb_w3: 0.046,
    lamb_w4: 0.7,
    c_surf_b: 20000,
    lamb_surf: 23,
    e_wallfrac: 0.93,
    a_wallfrac: 36,
    z0_m_b: 0.001,
    z0h_h_b: 0.0001,

    d_std_w1: 0.02,
    d_std_w2: 0.04,
    d_std_w3: 0.06,
    d_std_w4: 0.08,
    c_std_1: 1736000,
    c_std_2: 1736000,
    c_std_3: 1736000,
    c_window4: 1736000,
    lamb_std_1: 0.19,
    lamb_std_2: 0.19,
    lamb_std_3: 0.19,
    lamb_window4: 0.19,
    e_windfrac: 0.87,
    t_windfrac: 0.65,
    a_windfrac: 37,

    d_roof_1: 0.02,
    d_roof_2: 0.17,
    d_roof_3: 0.37,
    d_roof_4: 0.39,
    c_roof_1: 1700000,
    c_roof_2: 79200,
    c_roof_3: 2112000,
    c_w4_roof: 1526000,
    lamb_roof_1: 0.16,
    lamb_roof_2: 0.046,
    lamb_roof_3: 2.1,
    lambda_w4_roof: 0.7,
    e_roof: 0.93,
    a_roof: 42,

    d_roof_w1: 0.02,
    d_roof_w2: 0.04,
    d_roof_w3: 0.06,
    d_roof_w4: 0.08,
    c_roof_w1: 1736000,
    c_roof_w2: 1736000,
    c_roof_w3: 1736000,
    c_window4_roof: 1736000,
    lamb_roof_w1: 0.19,
    lamb_roof_w2: 0.19,
    lamb_roof_w3: 0.19,
    lambda_window4_roof: 0.19,
    e_roof_w: 0.87,
    t_roof_w: 0.65,
    a_roof_w: 37,

    t_roof_grsurf: 1,
    LAI_ro: 1.5,
    a_roof_grsurf: 5,
    e_roof_grsurf: 0.86,
    LAI_wa: 1.5,
    c_surf_green: 20000,
    lamb_surf_green: 10,
    e_greenfrac: 0.86,
    a_greenfrac: 5,

    shading_fac: 0.75,
    g_value: 0.7,
    u_value: 1.7,
    c_surf_wind: 20000,
    lamb_surf_wind: 23,

    targ_t_sum: 26,
    targ_t_win: 20,
    additi_gains: 7,
    basic_gains: 3,
    bairflow: 1,
    aairflow_occ: 1,

    heatrec_eff: 0,
    activesurf_fac: 3.5,
    factor_a: 370000,
    lambda_at: 4.5,
    maxheat: 80,
    maxcool: 0,
    antro_heat_fac: 0,
    antro_cool_fac: 2.54,

    groundfrac_gf: 0.75,
    windfrac_gf: 0.25,
    greenfrac_gf: 0,
    greenfrac_ro_gf: 0,
    LAI_wall_gf: 1.5,
    d_ff_1: 0.02,
    d_ff_2: 0.08,
    d_ff_3: 0.32,
    d_ff_4: 0.34,
    c_1_wall_gf: 1520000,
    c_2_wall_gf: 79200,
    c_3_wall_gf: 2112000,
    c_w4_wall_gf: 1526000,
    lamb_1_wall_gf: 0.93,
    lamb_2_wall_gf: 0.046,
    lamb_3_wall_gf: 2.1,
    lamb_w4_wall_gf: 0.7,
    e_wallfrac_gf: 0.93,
    e_greenfrac_gf: 0.87,
    e_windowfrac_gf: 0.86,
    t_windowfrac_gf: 0.65,
    z0_m_b_gf: 0.001,
    z0_h_b_gf: 0.0001,
    a_gf_w: 36,
    d_ff_w1: 0.02,
    d_ff_w2: 0.04,
    d_ff_w3: 0.06,
    d_ff_w4: 0.08,
    c_ff_w1: 1736000,
    c_ff_w2: 1736000,
    c_ff_w3: 1736000,
    c_window4_gf: 1736000,
    lamb_ff_w1: 0.19,
    lamb_ff_w2: 0.19,
    lamb_ff_w3: 0.19,
    lamb_window4_gf: 0.19,
    a_ff_w: 37,
    a_ff_grsurf: 5,
  },
  {
    t: 1,
    t1: 6,
    bh: 25,
    wallfrac: 0.71,
    windfrac: 0.29,
    greenfrac_wa: 0,
    greenfrac_ro: 0,
    wallfrac_ro: 1,
    windfrac_ro: 0,
    storey_height: 2.7,
    ceiling_height: 0.2,
    ground_h: 2.7,

    d_wall1: 0.02,
    d_wall2: 0.22,
    d_wall3: 0.58,
    d_wall4: 0.6,
    c_w1: 1520000,
    c_w2: 79200,
    c_w3: 1344000,
    c_w4: 1526000,
    lamb_w1: 0.93,
    lamb_w2: 0.035,
    lamb_w3: 0.68,
    lamb_w4: 0.7,
    c_surf_b: 20000,
    lamb_surf: 23,
    e_wallfrac: 0.93,
    a_wallfrac: 36,
    z0_m_b: 0.001,
    z0h_h_b: 0.0001,

    d_std_w1: 0.03,
    d_std_w2: 0.06,
    d_std_w3: 0.09,
    d_std_w4: 0.12,
    c_std_1: 1736000,
    c_std_2: 1736000,
    c_std_3: 1736000,
    c_window4: 1736000,
    lamb_std_1: 0.11,
    lamb_std_2: 0.11,
    lamb_std_3: 0.11,
    lamb_window4: 0.11,
    e_windfrac: 0.8,
    t_windfrac: 0.57,
    a_windfrac: 38,

    d_roof_1: 0.02,
    d_roof_2: 0.06,
    d_roof_3: 0.36,
    d_roof_4: 0.38,
    c_roof_1: 3753600,
    c_roof_2: 709650,
    c_roof_3: 79200,
    c_w4_roof: 1526000,
    lamb_roof_1: 0.52,
    lamb_roof_2: 0.12,
    lamb_roof_3: 0.035,
    lambda_w4_roof: 0.7,
    e_roof: 0.93,
    a_roof: 42,

    d_roof_w1: 0.03,
    d_roof_w2: 0.06,
    d_roof_w3: 0.09,
    d_roof_w4: 0.12,
    c_roof_w1: 1736000,
    c_roof_w2: 1736000,
    c_roof_w3: 1736000,
    c_window4_roof: 1736000,
    lamb_roof_w1: 0.11,
    lamb_roof_w2: 0.11,
    lamb_roof_w3: 0.11,
    lambda_window4_roof: 0.11,
    e_roof_w: 0.8,
    t_roof_w: 0.57,
    a_roof_w: 38,

    t_roof_grsurf: 1,
    LAI_ro: 1.5,
    a_roof_grsurf: 5,
    e_roof_grsurf: 0.86,
    LAI_wa: 1.5,
    c_surf_green: 20000,
    lamb_surf_green: 10,
    e_greenfrac: 0.86,
    a_greenfrac: 5,

    shading_fac: 0.15,
    g_value: 0.6,
    u_value: 0.8,
    c_surf_wind: 20000,
    lamb_surf_wind: 23,

    targ_t_sum: 26,
    targ_t_win: 20,
    additi_gains: 7,
    basic_gains: 3,
    bairflow: 1,
    aairflow_occ: 1,

    heatrec_eff: 0.8,
    activesurf_fac: 2.5,
    factor_a: 165000,
    lambda_at: 4.5,
    maxheat: 40,
    maxcool: -80,
    antro_heat_fac: -2,
    antro_cool_fac: 1.25,
    groundfrac_gf: 0.71,
    windfrac_gf: 0.29,
    greenfrac_gf: 0,
    greenfrac_ro_gf: 0,
    LAI_wall_gf: 1.5,
    d_ff_1: 0.02,
    d_ff_2: 0.22,
    d_ff_3: 0.58,
    d_ff_4: 0.6,
    c_1_wall_gf: 1520000,
    c_2_wall_gf: 79200,
    c_3_wall_gf: 1344000,
    c_w4_wall_gf: 1526000,
    lamb_1_wall_gf: 0.93,
    lamb_2_wall_gf: 0.035,
    lamb_3_wall_gf: 0.68,
    lamb_w4_wall_gf: 0.7,
    e_wallfrac_gf: 0.93,
    e_greenfrac_gf: 0.8,
    e_windowfrac_gf: 0.86,
    t_windowfrac_gf: 0.57,
    z0_m_b_gf: 0.001,
    z0_h_b_gf: 0.0001,

    a_gf_w: 36,
    d_ff_w1: 0.03,
    d_ff_w2: 0.06,
    d_ff_w3: 0.09,
    d_ff_w4: 0.012,
    c_ff_w1: 1736000,
    c_ff_w2: 1736000,
    c_ff_w3: 1736000,
    c_window4_gf: 1736000,
    lamb_ff_w1: 0.11,
    lamb_ff_w2: 0.11,
    lamb_ff_w3: 0.11,
    lamb_window4_gf: 0.11,
    a_ff_w: 38,
    a_ff_grsurf: 5,
  },
  {
    t: 2,
    t2: 1,
    rcmin: 0,
    lai: 0,
    cveg: 0,
    canoprcoef: 0,
    z0v: 0.005,
    z0hv: 0.00005,
    lambs: 0,
    lambu: 0,
    fswin: 0,
    csurf: 0,
    emiss: 0.94,
    albedo: 17,
  },
  {
    t: 2,
    t2: 2,
    rcmin: 180,
    lai: 3,
    cveg: 1,
    canoprcoef: 0,
    z0v: 0.1,
    z0hv: 0.0001,
    lambs: 10,
    lambu: 10,
    fswin: 0.05,
    csurf: 0,
    emiss: 0.95,
    albedo: 2,
  },
  {
    t: 2,
    t2: 3,
    rcmin: 110,
    lai: 2,
    cveg: 1,
    canoprcoef: 0,
    z0v: 0.03,
    z0hv: 0.00003,
    lambs: 10,
    lambu: 10,
    fswin: 0.05,
    csurf: 0,
    emiss: 0.95,
    albedo: 5,
  },
  {
    t: 2,
    t2: 4,
    rcmin: 500,
    lai: 5,
    cveg: 1,
    canoprcoef: 0.03,
    z0v: 2,
    z0hv: 2,
    lambs: 20,
    lambu: 15,
    fswin: 0.03,
    csurf: 0,
    emiss: 0.97,
    albedo: 6,
  },
  {
    t: 2,
    t2: 5,
    rcmin: 500,
    lai: 5,
    cveg: 1,
    canoprcoef: 0.03,
    z0v: 2,
    z0hv: 2,
    lambs: 20,
    lambu: 15,
    fswin: 0.03,
    csurf: 0,
    emiss: 0.97,
    albedo: 8,
  },
  {
    t: 2,
    t2: 6,
    rcmin: 175,
    lai: 5,
    cveg: 1,
    canoprcoef: 0.03,
    z0v: 2,
    z0hv: 2,
    lambs: 20,
    lambu: 15,
    fswin: 0.03,
    csurf: 0,
    emiss: 0.97,
    albedo: 9,
  },
  {
    t: 2,
    t2: 7,
    rcmin: 240,
    lai: 6,
    cveg: 0.99,
    canoprcoef: 0.13,
    z0v: 2,
    z0hv: 2,
    lambs: 20,
    lambu: 15,
    fswin: 0.03,
    csurf: 0,
    emiss: 0.97,
    albedo: 7,
  },
  {
    t: 2,
    t2: 8,
    rcmin: 100,
    lai: 2,
    cveg: 0.7,
    canoprcoef: 0,
    z0v: 0.47,
    z0hv: 0.0047,
    lambs: 10,
    lambu: 10,
    fswin: 0.05,
    csurf: 0,
    emiss: 0.97,
    albedo: 10,
  },
  {
    t: 2,
    t2: 9,
    rcmin: 250,
    lai: 0.05,
    cveg: 0,
    canoprcoef: 0,
    z0v: 0.013,
    z0hv: 0.00013,
    lambs: 15,
    lambu: 15,
    fswin: 0,
    csurf: 0,
    emiss: 0.94,
    albedo: 11,
  },
  {
    t: 2,
    t2: 10,
    rcmin: 80,
    lai: 1,
    cveg: 0.5,
    canoprcoef: 0,
    z0v: 0.034,
    z0hv: 0.00034,
    lambs: 10,
    lambu: 10,
    fswin: 0.05,
    csurf: 0,
    emiss: 0.97,
    albedo: 13,
  },
  {
    t: 2,
    t2: 11,
    rcmin: 180,
    lai: 3,
    cveg: 1,
    canoprcoef: 0,
    z0v: 0.5,
    z0hv: 0.005,
    lambs: 10,
    lambu: 10,
    fswin: 0.05,
    csurf: 0,
    emiss: 0.97,
    albedo: 2,
  },
  {
    t: 2,
    t2: 12,
    rcmin: 150,
    lai: 0.5,
    cveg: 0.1,
    canoprcoef: 0,
    z0v: 0.17,
    z0hv: 0.0017,
    lambs: 10,
    lambu: 10,
    fswin: 0.05,
    csurf: 0,
    emiss: 0.97,
    albedo: 11,
  },
  {
    t: 2,
    t2: 13,
    rcmin: 0,
    lai: 0,
    cveg: 0,
    canoprcoef: 0,
    z0v: 0.0013,
    z0hv: 0.00013,
    lambs: 58,
    lambu: 58,
    fswin: 0,
    csurf: 0,
    emiss: 0.97,
    albedo: 14,
  },
  {
    t: 2,
    t2: 14,
    rcmin: 240,
    lai: 4,
    cveg: 0.6,
    canoprcoef: 0,
    z0v: 0.83,
    z0hv: 0.0083,
    lambs: 10,
    lambu: 10,
    fswin: 0.05,
    csurf: 0,
    emiss: 0.97,
    albedo: 3,
  },
  {
    t: 2,
    t2: 15,
    rcmin: 225,
    lai: 3,
    cveg: 0.5,
    canoprcoef: 0,
    z0v: 0.1,
    z0hv: 0.001,
    lambs: 10,
    lambu: 10,
    fswin: 0.05,
    csurf: 0,
    emiss: 0.97,
    albedo: 4,
  },
  {
    t: 2,
    t2: 16,
    rcmin: 225,
    lai: 1.5,
    cveg: 0.5,
    canoprcoef: 0,
    z0v: 0.25,
    z0hv: 0.0025,
    lambs: 10,
    lambu: 10,
    fswin: 0.05,
    csurf: 0,
    emiss: 0.97,
    albedo: 5,
  },
  {
    t: 2,
    t2: 17,
    rcmin: 250,
    lai: 5,
    cveg: 1,
    canoprcoef: 0.03,
    z0v: 2,
    z0hv: 2,
    lambs: 20,
    lambu: 15,
    fswin: 0.03,
    csurf: 0,
    emiss: 0.97,
    albedo: 10,
  },
  {
    t: 2,
    t2: 18,
    rcmin: 175,
    lai: 2.5,
    cveg: 1,
    canoprcoef: 0.03,
    z0v: 1.1,
    z0hv: 1.1,
    lambs: 20,
    lambu: 15,
    fswin: 0.03,
    csurf: 0,
    emiss: 0.97,
    albedo: 7,
  },
  {
    t: 3,
    t3: 1,
    z0p: 0.05,
    z0hp: 0.0005,
    emiss: 0.97,
    albedo: 18,
  },
  {
    t: 3,
    t3: 2,
    z0p: 0.05,
    z0hp: 0.0005,
    emiss: 0.94,
    albedo: 19,
  },
  {
    t: 3,
    t3: 3,
    z0p: 0.01,
    z0hp: 0.0001,
    emiss: 0.98,
    albedo: 20,
  },
  {
    t: 3,
    t3: 4,
    z0p: 0.01,
    z0hp: 0.0001,
    emiss: 0.93,
    albedo: 21,
  },
  {
    t: 3,
    t3: 5,
    z0p: 0.01,
    z0hp: 0.0001,
    emiss: 0.97,
    albedo: 22,
  },
  {
    t: 3,
    t3: 6,
    z0p: 0.01,
    z0hp: 0.0001,
    emiss: 0.97,
    albedo: 23,
  },
  {
    t: 3,
    t3: 7,
    z0p: 0.01,
    z0hp: 0.0001,
    emiss: 0.97,
    albedo: 24,
  },
  {
    t: 3,
    t3: 8,
    z0p: 0.01,
    z0hp: 0.0001,
    emiss: 0.94,
    albedo: 25,
  },
  {
    t: 3,
    t3: 9,
    z0p: 0.01,
    z0hp: 0.0001,
    emiss: 0.98,
    albedo: 26,
  },
  {
    t: 3,
    t3: 10,
    z0p: 0.01,
    z0hp: 0.0001,
    emiss: 0.93,
    albedo: 27,
  },
  {
    t: 3,
    t3: 11,
    z0p: 0.01,
    z0hp: 0.0001,
    emiss: 0.97,
    albedo: 28,
  },
  {
    t: 3,
    t3: 12,
    z0p: 0.01,
    z0hp: 0.0001,
    emiss: 0.97,
    albedo: 29,
  },
  {
    t: 3,
    t3: 13,
    z0p: 0.01,
    z0hp: 0.0001,
    emiss: 0.97,
    albedo: 30,
  },
  {
    t: 3,
    t3: 14,
    z0p: 0.01,
    z0hp: 0.0001,
    emiss: 0.94,
    albedo: 31,
  },
  {
    t: 3,
    t3: 15,
    z0p: 0.01,
    z0hp: 0.0001,
    emiss: 0.98,
    albedo: 32,
  },
  {
    t: 4,
    t4: 1,
    watert: 9.85,
    z0w: 0.01,
    z0hw: 0.001,
    emiss: 0.99,
  },
  {
    t: 4,
    t4: 2,
    watert: 9.85,
    z0w: 0.01,
    z0hw: 0.001,
    emiss: 0.99,
  },
  {
    t: 4,
    t4: 3,
    watert: 9.85,
    z0w: 0.01,
    z0hw: 0.001,
    emiss: 0.99,
  },
  {
    t: 4,
    t4: 4,
    watert: 9.85,
    z0w: 0.01,
    z0hw: 0.001,
    emiss: 0.99,
  },
  {
    t: 4,
    t4: 5,
    watert: 9.85,
    z0w: 0.01,
    z0hw: 0.001,
    emiss: 0.99,
  },
  {
    t: 5,
    t5: 1,
    th: 10,
    t_alpha: 5,
    t_beta: 3,
    t_lai_s: 5,
    t_lai_w: 0.8,
  },
  {
    t: 5,
    t5: 2,
    th: 10,
    t_alpha: 1.5,
    t_beta: 3,
    t_lai_s: 5,
    t_lai_w: 5,
  },
  {
    t: 5,
    t5: 3,
    th: 10,
    t_alpha: 7,
    t_beta: 3,
    t_lai_s: 6,
    t_lai_w: 0.8,
  },
  {
    t: 5,
    t5: 4,
    th: 10,
    t_alpha: 3,
    t_beta: 3,
    t_lai_s: 5,
    t_lai_w: 1.5,
  },
  {
    t: 6,
    t6: 1,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 4,
    th: 12,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.35,
    t_age: 15,
  },
  {
    t: 6,
    t6: 2,
    tree_shape: 3,
    crown_ratio: 1,
    crown_dia: 4,
    th: 12,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.8,
    t_age: 15,
  },
  {
    t: 6,
    t6: 3,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 7,
    th: 12,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.8,
    t_age: 15,
  },
  {
    t: 6,
    t6: 4,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 7,
    th: 12,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 1,
    t_age: 15,
  },
  {
    t: 6,
    t6: 5,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 8.5,
    th: 13.5,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 1.3,
    t_age: 15,
  },
  {
    t: 6,
    t6: 6,
    tree_shape: 3,
    crown_ratio: 1,
    crown_dia: 6,
    th: 16,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 1.2,
    t_age: 15,
  },
  {
    t: 6,
    t6: 7,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 3,
    th: 4,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 1.2,
    t_age: 15,
  },
  {
    t: 6,
    t6: 8,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 6,
    th: 14,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.3,
    t_age: 15,
  },
  {
    t: 6,
    t6: 9,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 4,
    th: 4,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.9,
    t_age: 15,
  },
  {
    t: 6,
    t6: 10,
    tree_shape: 3,
    crown_ratio: 1,
    crown_dia: 5,
    th: 10,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.5,
    t_age: 15,
  },
  {
    t: 6,
    t6: 11,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 3.5,
    th: 6,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.9,
    t_age: 15,
  },
  {
    t: 6,
    t6: 12,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 6,
    th: 10,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.7,
    t_age: 15,
  },
  {
    t: 6,
    t6: 13,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 5,
    th: 17,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.8,
    t_age: 15,
  },
  {
    t: 6,
    t6: 14,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 4.5,
    th: 7,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.8,
    t_age: 15,
  },
  {
    t: 6,
    t6: 15,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 5.5,
    th: 6.5,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.7,
    t_age: 15,
  },
  {
    t: 6,
    t6: 16,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 8,
    th: 13,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.8,
    t_age: 15,
  },
  {
    t: 6,
    t6: 17,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 6,
    th: 9,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.8,
    t_age: 15,
  },
  {
    t: 6,
    t6: 18,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 3,
    th: 6.5,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.8,
    t_age: 15,
  },
  {
    t: 6,
    t6: 19,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 2.5,
    th: 7.5,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.9,
    t_age: 15,
  },
  {
    t: 6,
    t6: 20,
    tree_shape: 5,
    crown_ratio: 1,
    crown_dia: 3.5,
    th: 9,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.7,
    t_age: 15,
  },
  {
    t: 6,
    t6: 21,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 5,
    th: 10,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.8,
    t_age: 15,
  },
  {
    t: 6,
    t6: 22,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 4.5,
    th: 6.5,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 1.2,
    t_age: 15,
  },
  {
    t: 6,
    t6: 23,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 5,
    th: 9,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.4,
    t_age: 15,
  },
  {
    t: 6,
    t6: 24,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 4,
    th: 4,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.7,
    t_age: 15,
  },
  {
    t: 6,
    t6: 25,
    tree_shape: 3,
    crown_ratio: 1,
    crown_dia: 3.5,
    th: 6,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 1.4,
    t_age: 15,
  },
  {
    t: 6,
    t6: 26,
    tree_shape: 3,
    crown_ratio: 1,
    crown_dia: 5,
    th: 10,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.5,
    t_age: 15,
  },
  {
    t: 6,
    t6: 27,
    tree_shape: 3,
    crown_ratio: 1,
    crown_dia: 3,
    th: 8,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.4,
    t_age: 15,
  },
  {
    t: 6,
    t6: 28,
    tree_shape: 3,
    crown_ratio: 1,
    crown_dia: 5,
    th: 7,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.4,
    t_age: 15,
  },
  {
    t: 6,
    t6: 29,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 2,
    th: 3,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.9,
    t_age: 15,
  },
  {
    t: 6,
    t6: 30,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 10,
    th: 14,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.4,
    t_age: 15,
  },
  {
    t: 6,
    t6: 31,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 6.5,
    th: 6,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 1.2,
    t_age: 15,
  },
  {
    t: 6,
    t6: 32,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 4.5,
    th: 6,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.9,
    t_age: 15,
  },
  {
    t: 6,
    t6: 33,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 4.5,
    th: 6,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.6,
    t_age: 15,
  },
  {
    t: 6,
    t6: 34,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 10,
    th: 12.5,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.5,
    t_age: 15,
  },
  {
    t: 6,
    t6: 35,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 5.5,
    th: 10.5,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 1.6,
    t_age: 15,
  },
  {
    t: 6,
    t6: 36,
    tree_shape: 3,
    crown_ratio: 1,
    crown_dia: 4,
    th: 8.5,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.8,
    t_age: 15,
  },
  {
    t: 6,
    t6: 37,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 6.5,
    th: 10.5,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.6,
    t_age: 15,
  },
  {
    t: 6,
    t6: 38,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 5.5,
    th: 10,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.8,
    t_age: 15,
  },
  {
    t: 6,
    t6: 39,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 9.5,
    th: 8.5,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.8,
    t_age: 15,
  },
  {
    t: 6,
    t6: 40,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 4,
    th: 7.5,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.8,
    t_age: 15,
  },
  {
    t: 6,
    t6: 41,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 7,
    th: 9,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.5,
    t_age: 15,
  },
  {
    t: 6,
    t6: 42,
    tree_shape: 5,
    crown_ratio: 1,
    crown_dia: 3,
    th: 7,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.9,
    t_age: 15,
  },
  {
    t: 6,
    t6: 43,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 3.5,
    th: 5.5,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.5,
    t_age: 15,
  },
  {
    t: 6,
    t6: 44,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 3,
    th: 6,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.6,
    t_age: 15,
  },
  {
    t: 6,
    t6: 45,
    tree_shape: 3,
    crown_ratio: 1,
    crown_dia: 7,
    th: 16.5,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.6,
    t_age: 15,
  },
  {
    t: 6,
    t6: 46,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 3,
    th: 6,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 1.1,
    t_age: 15,
  },
  {
    t: 6,
    t6: 47,
    tree_shape: 3,
    crown_ratio: 1,
    crown_dia: 3,
    th: 7,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.3,
    t_age: 15,
  },
  {
    t: 6,
    t6: 48,
    tree_shape: 3,
    crown_ratio: 1,
    crown_dia: 4.5,
    th: 9.5,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.5,
    t_age: 15,
  },
  {
    t: 6,
    t6: 49,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 7,
    th: 9,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.7,
    t_age: 15,
  },
  {
    t: 6,
    t6: 50,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 3,
    th: 5,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.6,
    t_age: 15,
  },
  {
    t: 6,
    t6: 51,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 4.5,
    th: 5,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.3,
    t_age: 15,
  },
  {
    t: 6,
    t6: 52,
    tree_shape: 5,
    crown_ratio: 1,
    crown_dia: 4.5,
    th: 12,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.5,
    t_age: 15,
  },
  {
    t: 6,
    t6: 53,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 7.5,
    th: 11.5,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 1,
    t_age: 15,
  },
  {
    t: 6,
    t6: 54,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 2,
    th: 6,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 1,
    t_age: 15,
  },
  {
    t: 6,
    t6: 55,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 7,
    th: 7,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.3,
    t_age: 15,
  },
  {
    t: 6,
    t6: 56,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 4,
    th: 8,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.4,
    t_age: 15,
  },
  {
    t: 6,
    t6: 57,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 13.5,
    th: 13.5,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.5,
    t_age: 15,
  },
  {
    t: 6,
    t6: 58,
    tree_shape: 3,
    crown_ratio: 1,
    crown_dia: 3,
    th: 13,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.9,
    t_age: 15,
  },
  {
    t: 6,
    t6: 59,
    tree_shape: 3,
    crown_ratio: 1,
    crown_dia: 6,
    th: 16,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.8,
    t_age: 15,
  },
  {
    t: 6,
    t6: 60,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 10,
    th: 14.5,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 1.1,
    t_age: 15,
  },
  {
    t: 6,
    t6: 61,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 9,
    th: 20,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 1.4,
    t_age: 15,
  },
  {
    t: 6,
    t6: 62,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 5,
    th: 7,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 1.6,
    t_age: 15,
  },
  {
    t: 6,
    t6: 63,
    tree_shape: 3,
    crown_ratio: 1,
    crown_dia: 6,
    th: 17.5,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.7,
    t_age: 15,
  },
  {
    t: 6,
    t6: 64,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 5,
    th: 4,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 1.1,
    t_age: 15,
  },
  {
    t: 6,
    t6: 65,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 10,
    th: 12,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.5,
    t_age: 15,
  },
  {
    t: 6,
    t6: 66,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 11.5,
    th: 14.5,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 1.6,
    t_age: 15,
  },
  {
    t: 6,
    t6: 67,
    tree_shape: 3,
    crown_ratio: 1,
    crown_dia: 3,
    th: 6,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 1.8,
    t_age: 15,
  },
  {
    t: 6,
    t6: 68,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 8,
    th: 14,
    t_lai_s: 3.1,
    t_lai_w: 0.1,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.4,
    t_age: 15,
  },
  {
    t: 6,
    t6: 69,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 4.5,
    th: 4.5,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 1.3,
    t_age: 15,
  },
  {
    t: 6,
    t6: 70,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 7,
    th: 5.5,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.5,
    t_age: 15,
  },
  {
    t: 6,
    t6: 71,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 4.5,
    th: 13.5,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.5,
    t_age: 15,
  },
  {
    t: 6,
    t6: 72,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 7,
    th: 14,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 1.1,
    t_age: 15,
  },
  {
    t: 6,
    t6: 73,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 8,
    th: 6,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 1.4,
    t_age: 15,
  },
  {
    t: 6,
    t6: 74,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 10,
    th: 25,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.6,
    t_age: 15,
  },
  {
    t: 6,
    t6: 75,
    tree_shape: 5,
    crown_ratio: 1,
    crown_dia: 5.5,
    th: 10.5,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 1.6,
    t_age: 15,
  },
  {
    t: 6,
    t6: 76,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 7.5,
    th: 10,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 1.4,
    t_age: 15,
  },
  {
    t: 6,
    t6: 77,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 4,
    th: 7,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 1.1,
    t_age: 15,
  },
  {
    t: 6,
    t6: 78,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 4.5,
    th: 5,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.6,
    t_age: 15,
  },
  {
    t: 6,
    t6: 79,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 6,
    th: 7,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.5,
    t_age: 15,
  },
  {
    t: 6,
    t6: 80,
    tree_shape: 5,
    crown_ratio: 1,
    crown_dia: 6,
    th: 16.5,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.6,
    t_age: 15,
  },
  {
    t: 6,
    t6: 81,
    tree_shape: 2,
    crown_ratio: 1,
    crown_dia: 5,
    th: 7.5,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 1.5,
    t_age: 15,
  },
  {
    t: 6,
    t6: 82,
    tree_shape: 3,
    crown_ratio: 1,
    crown_dia: 3.5,
    th: 9,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.7,
    t_age: 15,
  },
  {
    t: 6,
    t6: 83,
    tree_shape: 3,
    crown_ratio: 1,
    crown_dia: 7,
    th: 12.5,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.7,
    t_age: 15,
  },
  {
    t: 6,
    t6: 84,
    tree_shape: 3,
    crown_ratio: 1,
    crown_dia: 6,
    th: 10.5,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 1.1,
    t_age: 15,
  },
  {
    t: 6,
    t6: 85,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 7.5,
    th: 14,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.8,
    t_age: 15,
  },
  {
    t: 6,
    t6: 86,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 4,
    th: 5.5,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 1.2,
    t_age: 15,
  },
  {
    t: 6,
    t6: 87,
    tree_shape: 1,
    crown_ratio: 1,
    crown_dia: 5,
    th: 5,
    t_lai_s: 3,
    t_lai_w: 0.8,
    t_lad_max: 0.6,
    rat_lad_bad: 0.025,
    trunk_dia: 0.4,
    t_age: 15,
  },

  {
    t: 7,
    t7: 1,
    emis_type_street: "no emissions",
  },
  {
    t: 7,
    t7: 2,
    emis_type_street: "no emissions",
  },
  {
    t: 7,
    t7: 3,
    emis_type_street: "no emissions",
  },
  {
    t: 7,
    t7: 4,
    emis_type_street: "no emissions",
  },
  {
    t: 7,
    t7: 5,
    emis_type_street: "no emissions",
  },
  {
    t: 7,
    t7: 6,
    emis_type_street: "no emissions",
  },
  {
    t: 7,
    t7: 7,
    emis_type_street: "no emissions",
  },
  {
    t: 7,
    t7: 8,
    emis_type_street: "no emissions",
  },
  {
    t: 7,
    t7: 9,
    emis_type_street: "side street",
  },
  {
    t: 7,
    t7: 10,
    emis_type_street: "side street",
  },
  {
    t: 7,
    t7: 11,
    emis_type_street: "side street",
  },
  {
    t: 7,
    t7: 12,
    emis_type_street: "Main Street",
  },
  {
    t: 7,
    t7: 13,
    emis_type_street: "Main Street",
  },
  {
    t: 7,
    t7: 14,
    emis_type_street: "Main Street",
  },
  {
    t: 7,
    t7: 15,
    emis_type_street: "Main Street",
  },
  {
    t: 7,
    t7: 16,
    emis_type_street: "Main Street",
  },
  {
    t: 7,
    t7: 17,
    emis_type_street: "Main Street",
  },
  {
    t: 7,
    t7: 18,
    emis_type_street: "Main Street",
  },
  {
    t: 7,
    t7: 19,
    emis_type_street: "Main Street",
  },
  {
    t: 7,
    t7: 20,
    emis_type_street: "Main Street",
  },
  {
    t: 8,
    t8: 1,
    ea: 0.022,
    eb: 0.022,
    emis_source_low: 0,
    emis_source_high: 3,
    emis_vdis_bot: 0.3,
    emis_vdis_mid: 0.5,
    emis_vdis_top: 0.3,
  },
  {
    t: 8,
    t8: 2,
    ea: 0.0033,
    eb: 0.0033,
    emis_source_low: 0,
    emis_source_high: 3,
    emis_vdis_bot: 0.3,
    emis_vdis_mid: 0.5,
    emis_vdis_top: 0.3,
  },
  {
    t: 8,
    t8: 3,
    ea: 0.98,
    eb: 1.09,
    emis_source_low: 0,
    emis_source_high: 3,
    emis_vdis_bot: 0.3,
    emis_vdis_mid: 0.5,
    emis_vdis_top: 0.3,
  },
  {
    t: 8,
    t8: 4,
    ea: 1.68,
    eb: 1.76,
    emis_source_low: 0,
    emis_source_high: 3,
    emis_vdis_bot: 0.3,
    emis_vdis_mid: 0.5,
    emis_vdis_top: 0.3,
  },
  {
    t: 8,
    t8: 5,
    ea: 1,
    eb: 1,
    emis_source_low: 0,
    emis_source_high: 3,
    emis_vdis_bot: 0.3,
    emis_vdis_mid: 0.5,
    emis_vdis_top: 0.3,
  },
  {
    t: 9,
    t9: 1,
    name: "POI",
    ph: 1.5,
  },
];
