import React, { useState, useEffect } from "react";
import { ChevronRight } from "react-feather";
import "./index.scss";

const AccordionSection = (props) => {
  const [expanded, setExpanded] = useState(!!props.expanded || false);

  useEffect(() => {
    setExpanded(props.expanded);
  }, [props.expanded]);

  const Icon = (icon) => {
    const IconComponent = icon;
    return icon ? <IconComponent /> : null;
  };
  return (
    <div
      className={`${!props.buildingGroup ? "accordion-section" : ""}
    ${props.buildingGroup ? "building-accordion-section" : ""}
    `}
      style={props.style}
    >
      <button
        className={`
        ${
          !props.buildingGroup
            ? "accordion-section_btn accordion-section__header"
            : ""
        }
        ${
          props.buildingGroup
            ? "building-accordion-section_btn building-accordion-section__header"
            : ""
        }
      ${
        expanded && props.buildingGroup
          ? "building-accordion-section_btn--expanded"
          : ""
      }
      `}
        onClick={() => setExpanded(!expanded)}
      >
        {Icon(props.icon)}
        <span className={`${props.buildingGroup ? "building-span" : ""}`}>
          {props.title}
        </span>
        <ChevronRight
          className={`
          ${!props.buildingGroup ? "accordion-section__chevron" : ""}
          ${
            !props.buildingGroup && expanded
              ? " accordion-section__chevron--expanded"
              : ""
          }${
            props.buildingGroup && expanded
              ? "building-accordion-section__chevron--expanded"
              : ""
          }
          ${props.buildingGroup ? "building-accordion-section__chevron" : ""}
          `}
          size={"18px"}
        />
      </button>
      <div
        className={`
        ${!props.buildingGroup ? "accordion-section__content" : ""}
        ${
          !props.buildingGroup && expanded
            ? " accordion-section__content--expanded"
            : ""
        }
        ${
          props.buildingGroup && expanded
            ? "building-accordion-section__content--expanded"
            : ""
        }
        ${
          props.buildingGroup && !expanded
            ? "building-accordion-section__content"
            : ""
        }
        `}
      >
        {props.children}
      </div>
    </div>
  );
};

export default AccordionSection;
