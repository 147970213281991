import React from "react";
import { ResultsSelect } from "../../../../../Components/Map/Form/Select";
import { spatProcessOptions } from "../../../ResultsConst";
import {
  changeSpatCase,
  changeSpatFileType,
  changeSpatParams,
  changeSpatHeightFrom,
  changeSpatHeightTo,
  changeSpatTimeFrom,
  changeSpatTimeTo,
  changeSpatArea,
  changeSpatProcessing,
} from "../../../../../redux/actions/resultsActions";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class SpatiotemporalAnalysisForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <ResultsSelect
          label={t("Case")}
          value={this.props.currentCase.name}
          options={[this.props.currentCase.name]}
          onChange={(e) => this.props.changeSpatCase(e.target.value)}
        />
        <ResultsSelect
          label={t("FileType")}
          value={this.props.spatFileType}
          options={this.props.spatFileTypeOptions.map((x) => {
            return { label: t(x.name), value: x.value };
          })}
          onChange={(e) =>
            this.props.changeSpatFileType({
              fileType: e.target.value,
              selectedIndex: e.target.selectedIndex,
            })
          }
          optionsObject
        />
        <ResultsSelect
          label={t("Parameter")}
          value={this.props.spatParams}
          options={this.props.spatParamsOptions.map((x) => {
            return { label: t(x.name), value: x.value };
          })}
          onChange={(e) =>
            this.props.changeSpatParams({
              selectedParams: e.target.value,
              paramsIndex: e.target.selectedIndex,
            })
          }
          optionsObject
        />
        <ResultsSelect
          label={t("TimeFrom")}
          value={this.props.spatTimeFrom.value}
          options={this.props.spatTimeOptions}
          onChange={(e) =>
            this.props.changeSpatTimeFrom({
              value: e.target.value,
              index: e.target.selectedIndex,
            })
          }
        />
        <ResultsSelect
          label={t("TimeTo")}
          value={this.props.spatTimeTo.value}
          options={this.props.spatTimeOptions}
          onChange={(e) =>
            this.props.changeSpatTimeTo({
              value: e.target.value,
              index: e.target.selectedIndex,
            })
          }
        />

        <ResultsSelect
          label={t("HeightFrom")}
          value={this.props.spatHeightFrom.value}
          options={this.props.spatHeightOptions}
          onChange={(e) =>
            this.props.changeSpatHeightFrom({
              value: e.target.value,
              index: e.target.selectedIndex,
            })
          }
        />

        <ResultsSelect
          label={t("HeightTo")}
          value={this.props.spatHeightTo.value}
          options={this.props.spatHeightOptions}
          onChange={(e) =>
            this.props.changeSpatHeightTo({
              value: e.target.value,
              index: e.target.selectedIndex,
            })
          }
        />

        <ResultsSelect
          label={t("Area")}
          value={this.props.spatArea}
          options={this.props.spatAreaOptions}
          onChange={(e) => this.props.changeSpatArea(e.target.value)}
        />

        <ResultsSelect
          label={t("Processing")}
          value={this.props.spatProcessing}
          options={spatProcessOptions}
          onChange={(e) => this.props.changeSpatProcessing(e.target.value)}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentCase: state.projects.currentCase,
    spatFileType: state.results.spatFileType,
    spatFileTypeOptions: state.results.spatFileTypeOptions,
    spatParams: state.results.spatParams,
    spatParamsOptions: state.results.spatParamsOptions,
    spatTimeFrom: state.results.spatTimeFrom,
    spatTimeTo: state.results.spatTimeTo,
    spatTimeOptions: state.results.spatTimeOptions,
    spatHeightFrom: state.results.spatHeightFrom,
    spatHeightTo: state.results.spatHeightTo,
    spatHeightOptions: state.results.spatHeightOptions,
    spatArea: state.results.spatArea,
    spatAreaOptions: state.results.spatAreaOptions,
    spatProcessing: state.results.spatProcessing,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeSpatCase: (payload) => dispatch(changeSpatCase(payload)),
    changeSpatFileType: (payload) => dispatch(changeSpatFileType(payload)),
    changeSpatParams: (payload) => dispatch(changeSpatParams(payload)),
    changeSpatHeightFrom: (payload) => dispatch(changeSpatHeightFrom(payload)),
    changeSpatHeightTo: (payload) => dispatch(changeSpatHeightTo(payload)),
    changeSpatTimeFrom: (payload) => dispatch(changeSpatTimeFrom(payload)),
    changeSpatTimeTo: (payload) => dispatch(changeSpatTimeTo(payload)),
    changeSpatArea: (payload) => dispatch(changeSpatArea(payload)),
    changeSpatProcessing: (payload) => dispatch(changeSpatProcessing(payload)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(SpatiotemporalAnalysisForm)
);
