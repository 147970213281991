import React from "react";
import { Minus } from "react-feather";
import { components } from "react-select";
import CircleButton from "../../Button/Circle/CircleButton";

const RemovableOption = ({ children, ...props }) => {
  return (
    <components.Option {...props} className="map-option">
      {children}
      <CircleButton
        className="option-delete__btn"
        icon={Minus}
        onClick={(e) => {
          e.stopPropagation();
          props.selectProps.selectProps.onRemove(props.data);
        }}
      />
    </components.Option>
  );
};

const selectSetupStyle = {
  control: (provided, state, isFocused) => ({
    ...provided,
    background: "#fff",
    border: "1px solid rgba(64, 67, 69, 0.62)",
    minHeight: "30px",
    height: "auto",
    maxHeight: "auto",
    width: "100%",
    boxShadow: state.isFocused ? null : null,
    outline: "none",
    cursor: "pointer",
    "&:hover": {
      boxShadow: state.isFocused ? null : null,
    },
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "auto",
    padding: "0 6px",
    fontSize: "12px",
    overflow: "hidden",
    cursor: "pointer",
    color: "#495057",
  }),
  input: (provided, state) => ({
    ...provided,
    margin: "0px",
    cursor: "pointer",
    color: "white",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    //height: "30px",
    //height of select component
    height: "29px",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      fontSize: "12px",
      color: isSelected ? "white" : "black",
      borderColor: "rgba(100, 105, 110, 0.62)",
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? "#06896cd9"
        : isFocused
        ? "#06896c17"
        : null,
      cursor: "pointer",
      ":active": {
        backgroundColor: "#06896c99",
        color: "white",
      },
    };
  },
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#495057",
      cursor: "pointer",
    };
  },
  dropdownIndicator: (base) => ({
    ...base,
    color: "#495057",
  }),
  menu: (provided, state) => ({
    ...provided,
    border: "1px solid #727272",
    cursor: "pointer",
    fontSize: "12px",
    paddingLeft: "5px",
    paddingRight: "5px",
    boxShadow: "rgb(0 0 0 / 30%) 0px 0px 8px 1px",
    color: "#495057",
  }),
};

export const selectComponents = {
  removableOption: RemovableOption,
  styles: selectSetupStyle,
};
