import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { TextInput } from "../../Components/Common/Form/Form";
import FormSeparator from "../../Components/Common/Form/FormSeparator/index";
import { Loader } from "../../Components/Loader/index";
import Page from "../../Components/Page/Page";
import * as actions from "../../redux/actions/projectsActions";
import * as caseActions from "../../redux/actions/caseActions";
import Table from "../../Components/Common/Table";
import IconCellRenderer from "../../Components/Common/Table/CellRenderers/IconCellRenderer";
import DefaultCellRenderer from "../../Components/Common/Table/CellRenderers/DefaultCellRenderer";
import { EditableImage } from "../../Components/Common/EditableImage/index";
import { colDefs } from "./colDefs";
import { TextArea } from "../../Components/Map/Form/TextArea";
import DeleteModal from "../../Components/Common/DeleteModal/index";
import "./Project.scss";

const Project = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const project = useSelector((state) => state.projects?.loadedProject);
  const isUpdating = useSelector((state) => state.projects?.isUpdating);

  const [projectDetails, setProjectDetails] = useState({
    projectId: project?.projectId,
    imageUrl: project?.pictureDownloadUrl,
  });

  const [deleteModal, setDeleteModal] = useState({
    show: false,
    data: null,
  });

  const showDeleteModal = (data) => setDeleteModal({ show: true, data: data });
  const closeDeleteModal = () =>
    setDeleteModal({
      show: false,
      data: null,
    });

  useEffect(() => {
    if (project?.pictureDownloadUrl !== projectDetails.imageUrl) {
      setProjectDetails({ imageUrl: project?.pictureDownloadUrl });
    }
    setProjectDetails({
      ...projectDetails,
      projectId: project?.id,
      name: project?.name,
      description: project?.description,
      imageUrl: project?.pictureDownloadUrl,
      epsg: project?.epsg,
    });
  }, [project]);

  const onImageChange = (image) => {
    if (!image) {
      setProjectDetails({ ...projectDetails, imageUrl: null, image: null });
      return;
    }
    const url = URL.createObjectURL(image);
    setProjectDetails({ ...projectDetails, imageUrl: url, image });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProjectDetails({
      ...projectDetails,
      [name]: value,
    });
  };

  const submit = () => {
    if (isUpdating) {
      return;
    }
    dispatch(actions.saveProject(projectDetails));
  };

  const showCase = (caseDto) => {
    history.push(`/projects/${project.id}/cases/${caseDto.id}`);
  };
  const createCase = () => {
    history.push(`/projects/${project.id}/cases/create`);
  };

  const deleteCase = (caseDto) => {
    dispatch(caseActions.deleteCase(caseDto.id));
    closeDeleteModal();
  };

  return (
    <Page>
      <h1 style={{ fontSize: 30 }}>{t("ProjectInformation")}</h1>
      {project ? (
        <>
          <div className="project-heading">
            <EditableImage
              imageUrl={projectDetails.imageUrl}
              onChange={onImageChange}
            />
            <FormSeparator label={t("Info")} />
            <TextInput
              label={t("Name")}
              name={"name"}
              value={projectDetails.name}
              onChange={handleChange}
            />
            <TextInput
              label={t("CoordinateReferenceSystem")}
              name={"name"}
              value={`EPSG: ${project.epsg || ""}`}
              disabled
            />
            <TextArea
              label={t("Description")}
              name={"description"}
              value={projectDetails.description}
              onChange={handleChange}
            />
          </div>
          <button
            id="update-proj__btn"
            className="wizard-button wizard-button-submit case-button"
            onClick={submit}
          >
            <span>{t("Save")}</span>
            {isUpdating && <Loader size="x-small" mode="light" />}
          </button>

          <FormSeparator label={t("Cases")} />
          <button
            className="wizard-button wizard-button-submit case-button"
            onClick={createCase}
          >
            {t("NewCase")}
          </button>
          <Table
            colDefs={colDefs(t, showCase, showDeleteModal)}
            rowData={project.caseDetailsDto}
            frameworkComponents={{
              iconCell: IconCellRenderer,
              defaultCell: DefaultCellRenderer,
            }}
          />
          <DeleteModal
            isOpen={deleteModal.show}
            modalHdr={t("DeleteCase")}
            closeDeleteModal={closeDeleteModal}
            modalInfo={t(`DeleteCaseModalInfo`)}
            deleteModalData={() =>
              deleteModal.data ? deleteCase(deleteModal.data) : null
            }
          />
        </>
      ) : (
        <Loader />
      )}
    </Page>
  );
};

export default withRouter(Project);
