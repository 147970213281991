import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Avatar from "../../Components/Avatar";
import Page from "../../Components/Page/Page";
import { NumberInput } from "../../Components/Common/Form/Form";
import { Loader } from "../../Components/Loader";
import { getUsers, updateUser } from "../../api/adminApi";
import { CheckBox } from "../../Components/Map/Form/CheckBox";
import SearchBar from "../../Components/Common/SearchBar/SearchBar";
import UserBudget from "../../Components/User/UserBudget";
import "./Admin.scss";

const Admin = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [usersSaveInProgress, setUsersSaveInProgress] = useState([]);
  const [changedResults, setChangedResults] = useState({});
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    const updates = {};
    searchResults.forEach((user) => {
      const rolesChanged =
        user.roles.length !== user.originalRoles?.length ||
        user.roles.some((r) => !user.originalRoles?.includes(r));
      const simHoursChanged = user.simulationHours !== user.originalHours;
      updates[user.id] = !!rolesChanged || !!simHoursChanged;
    });
    setChangedResults(updates);
  }, [searchResults]);

  const refreshResults = async (searchPhrase) => {
    setIsLoading(true);
    const users = await getUsers(searchPhrase);
    setSearchResults(
      users.map((u) => {
        const simHours = Math.ceil(u.simulationMinutes / 60);
        return {
          ...u,
          originalRoles: u.roles,
          originalHours: simHours,
          simulationHours: simHours,
        };
      })
    );
    setIsLoading(false);
  };

  const saveUser = async (user) => {
    try {
      setUsersSaveInProgress([...usersSaveInProgress, user.id]);
      const request = {
        id: user.id,
        roles: user.roles,
        simulationHours: user.simulationHours,
      };
      var updatedUser = await updateUser(request);
      setSearchResults(
        searchResults.map((u) => {
          if (u.id === updatedUser.id) {
            const simHours = Math.ceil(updatedUser.simulationMinutes / 60);
            return {
              ...u,
              originalRoles: u.roles,
              originalHours: simHours,
              simulationHours: simHours,
              simulationMinutes: updatedUser.simulationMinutes,
            };
          }
          return u;
        })
      );

      setUsersSaveInProgress(
        usersSaveInProgress.filter((u) => u.id !== user.id)
      );
    } catch (err) {
      console.log(err);
    } finally {
      setUsersSaveInProgress(
        usersSaveInProgress.filter((u) => u.id !== user.id)
      );
    }
  };

  const toggleRole = (user, role) => {
    setSearchResults(
      searchResults.map((u) => {
        if (u.id === user.id) {
          if (user.roles.includes(role)) {
            u.roles = user.roles.filter((r) => r !== role);
            if (role === "IsSimulationUser") {
              u.simulationHours = null;
            }
          } else {
            user.roles.push(role);
          }
        }
        return u;
      })
    );
  };
  const setSimulationHours = (user, hours) => {
    setSearchResults(
      searchResults.map((u) => {
        if (u.id === user.id) {
          const numHours = parseInt(hours);
          if (numHours >= u.originalHours) {
            u.simulationHours = numHours;
          }
        }
        return u;
      })
    );
  };
  return (
    <Page>
      <h2 style={{ marginTop: 0 }}>Admin</h2>
      <SearchBar
        placeholder={t("SearchByEmailFirstNameLastName")}
        searching={isLoading}
        onSearch={(phrase) => refreshResults(phrase)}
      />
      <div className="users-list">
        {searchResults.map((r) => (
          <div key={r.id} className="user-li">
            <Avatar
              size="large"
              initials={`${r.givenName?.charAt(0)}${r.surname?.charAt(0)}`}
            />
            <div className="user-title">
              <div className="user-li__name">{r.displayName}</div>
              <div className="user-li__mail">{r.email}</div>
            </div>
            <div className="user-li__roles">
              <fieldset className="data-checkboxes">
                <legend>{t("Roles")}:</legend>
                <CheckBox
                  label={t("Admin")}
                  checked={r.roles.includes("IsAdmin")}
                  onChange={() => toggleRole(r, "IsAdmin")}
                />
                <CheckBox
                  label={t("SimulationUser")}
                  checked={r.roles.includes("IsSimulationUser")}
                  onChange={() => toggleRole(r, "IsSimulationUser")}
                />
                {r.roles.includes("IsSimulationUser") && (
                  <div className="hours-wrapper">
                    <NumberInput
                      name="simHours"
                      label={`${t("SimulationHours")}:`}
                      type="number"
                      max="1000"
                      min={r.originalHours ?? 0}
                      value={r.simulationHours ?? 0}
                      onChange={(e) => setSimulationHours(r, e.target.value)}
                    />
                    <UserBudget user={r} />
                  </div>
                )}
              </fieldset>
            </div>
            <button
              className="wizard-button wizard-button-submit"
              disabled={!changedResults[r.id]}
              onClick={() => saveUser(r)}
            >
              {t("Save")}
              {usersSaveInProgress.includes(r.id) && (
                <Loader size="x-small" mode="light" />
              )}
            </button>
          </div>
        ))}
      </div>
    </Page>
  );
};

export default withRouter(Admin);
