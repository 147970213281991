import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
  ExpertHelpLabel,
  ExpertInput,
} from "../../ExpertUtils/ExpertComponent";
import { FormSettingsSeparator } from "../../../../Components/Common/Form/FormSeparator/index";
import { hoursFromUtcOptions } from "../../../../Components/Map/consts/consts";
import { helpLink } from "../../expertConst";
import { allCrsOptions } from "../../../Tools/OsmDownload/osmConst";
import { reprojectUtmToWgPoint } from "../../../../common/utmDef";
import {
  changeExpertInput,
  changeExpertDropdownInput,
  changeExpertDateTime,
} from "../../../../redux/actions/expertActions";
import { selectSetupStyle } from "../../../../Components/Common/PaletteDropdown/index";
import { useSelector, useDispatch } from "react-redux";

const [page, subpage] = ["initialization", "template_dynamic_windprofile"];
const boundaryCounditionsOptions = [
  { label: "neumann", value: "neumann" },
  { label: "dirichlet", value: "dirichlet" },
];
const DynamicWindProfile = () => {
  const dynamic_wind_profile = useSelector(
    (state) =>
      state.expert.expertJson.initialization.template_dynamic_windprofile
  );
  const [centerWg, setCenterWg] = useState({ lat: 0, lng: 0 });

  const crs = useSelector((state) => state.projects?.loadedProject?.epsg);
  const rasterParameters = useSelector(
    (state) => state.projects?.currentCase?.rasterParameters
  );

  useEffect(() => {
    const crsDef = allCrsOptions.find((x) => x.value === `EPSG:${crs}`).def;
    const utmLng = (rasterParameters.x_max + rasterParameters.x_min) / 2;
    const utmLat = (rasterParameters.y_max + rasterParameters.y_min) / 2;
    let centerWg = reprojectUtmToWgPoint([utmLng, utmLat], crsDef);
    centerWg = { lng: centerWg.lng.toFixed(3), lat: centerWg.lat.toFixed(3) };
    setCenterWg(centerWg);
  }, []);
  const dispatch = useDispatch();

  return (
    <>
      <ExpertHelpLabel label={"Activate Module"} />
      <Select
        isSearchable={false}
        styles={selectSetupStyle}
        options={[
          {
            value: "True",
            label: "True",
          },
        ]}
        value={{
          label: "True",
          value: "True",
        }}
        isDisabled
      />

      {dynamic_wind_profile.isActive && (
        <>
          <FormSettingsSeparator style={{ fontSize: "17px" }} label={"Mode"} />
          <ExpertHelpLabel
            label={"Initializing actions"}
            helpLink={`${helpLink}/initialization_parameters#initializing_actions`}
          />
          <Select
            isSearchable={false}
            styles={selectSetupStyle}
            options={[
              {
                value: "inifor",
                label: "inifor",
              },
              {
                value: "set_constant_profiles",
                label: "set_constant_profiles",
              },
            ]}
            value={{
              label: dynamic_wind_profile.mode.initializing_actions,
              value: dynamic_wind_profile.mode.initializing_actions,
            }}
            onChange={(e) =>
              dispatch(
                changeExpertInput({
                  page: page,
                  subpage: subpage,
                  key: "mode",
                  subkey: "initializing_actions",
                  value: e.value,
                })
              )
            }
          />
          <div style={{ marginTop: 12 }} />
          <ExpertHelpLabel
            label={"Start time and date of simulation"}
            helpLink={`${helpLink}/initialization_parameters#origin_date_time`}
          />
          <input
            className="setup-input"
            type="date"
            id="startDay"
            name="startDay"
            value={dynamic_wind_profile.mode.origin_data_time.substring(0, 10)}
            onChange={(e) =>
              dispatch(
                changeExpertDateTime({
                  type: "date",
                  subpage: subpage,
                  value: e.target.value,
                })
              )
            }
            min="2023-05-01"
            max="2024-09-30"
            required
          />
          <input
            id={"time-hours"}
            className="setup-input"
            type="time"
            value={dynamic_wind_profile.mode.origin_data_time.substring(11)}
            onChange={(e) =>
              dispatch(
                changeExpertDateTime({
                  type: "hours",
                  subpage: subpage,
                  value: e.target.value,
                })
              )
            }
            required
          />
          <ExpertHelpLabel
            label={"Hours From UTC"}
            helpLink={`${helpLink}/initialization_parameters#origin_date_time`}
          />
          <Select
            isSearchable={false}
            styles={selectSetupStyle}
            options={hoursFromUtcOptions}
            value={{
              label: dynamic_wind_profile.mode.hours_from_utc,
              value: dynamic_wind_profile.mode.hours_from_utc,
            }}
            onChange={(e) =>
              dispatch(
                changeExpertInput({
                  page: page,
                  subpage: subpage,
                  key: "mode",
                  subkey: "hours_from_utc",
                  value: e.value,
                })
              )
            }
            maxMenuHeight="500px"
          />
          <div style={{ marginTop: 12 }} />
          <FormSettingsSeparator
            style={{ fontSize: "17px" }}
            label={"Initialization"}
          />
          <ExpertHelpLabel
            label={"No thermal effects"}
            helpLink={`${helpLink}/initialization_parameters#neutral`}
          />
          <Select
            isSearchable={false}
            styles={selectSetupStyle}
            options={[
              {
                value: "True",
                label: "True",
              },
              { value: "False", label: "False" },
            ]}
            value={{
              label: !!dynamic_wind_profile.boundary_conditions.neutral
                ? "True"
                : "False",
              value: !!dynamic_wind_profile.boundary_conditions.neutral
                ? "True"
                : "False",
            }}
            onChange={(e) =>
              dispatch(
                changeExpertDropdownInput({
                  page: page,
                  subpage: subpage,
                  key: "boundary_conditions",
                  subkey: "neutral",
                  value: e.value,
                })
              )
            }
          />
          <div style={{ marginBottom: 12 }} />
          <ExpertHelpLabel
            label={"Include moisture calculation"}
            helpLink={`${helpLink}/initialization_parameters#humidity`}
          />
          <Select
            isSearchable={false}
            styles={selectSetupStyle}
            options={[
              {
                value: "True",
                label: "True",
              },
              { value: "False", label: "False" },
            ]}
            value={{
              label: !!dynamic_wind_profile.initialization.humidity
                ? "True"
                : "False",
              value: !!dynamic_wind_profile.initialization.humidity
                ? "True"
                : "False",
            }}
            onChange={(e) =>
              dispatch(
                changeExpertDropdownInput({
                  page: page,
                  subpage: subpage,
                  key: "initialization",
                  subkey: "humidity",
                  value: e.value,
                })
              )
            }
          />
          <FormSettingsSeparator
            style={{ fontSize: "17px" }}
            label={"Boundary Conditions"}
          />
          <ExpertHelpLabel
            label={"Boundary condition bottom: TKE"}
            helpLink={`${helpLink}/initialization_parameters#bc_e_b`}
          />
          <Select
            isSearchable={false}
            styles={selectSetupStyle}
            options={boundaryCounditionsOptions}
            value={{
              value: dynamic_wind_profile.boundary_conditions.bc_e_b,
              label: dynamic_wind_profile.boundary_conditions.bc_e_b,
            }}
            onChange={(e) =>
              dispatch(
                changeExpertInput({
                  page: page,
                  subpage: subpage,
                  key: "boundary_conditions",
                  subkey: "bc_e_b",
                  value: e.value,
                })
              )
            }
          />
          <div style={{ marginTop: 12 }} />
          <ExpertHelpLabel
            label={"Boundary condition bottom: pertubation pressure"}
            helpLink={`${helpLink}/initialization_parameters#bc_p_b`}
          />
          <Select
            isSearchable={false}
            styles={selectSetupStyle}
            options={boundaryCounditionsOptions}
            value={{
              value: dynamic_wind_profile.boundary_conditions.bc_p_b,
              label: dynamic_wind_profile.boundary_conditions.bc_p_b,
            }}
            onChange={(e) =>
              dispatch(
                changeExpertInput({
                  page: page,
                  subpage: subpage,
                  key: "boundary_conditions",
                  subkey: "bc_p_b",
                  value: e.value,
                })
              )
            }
          />
          <div style={{ marginTop: 12 }} />
          {!dynamic_wind_profile.initialization.neutral && (
            <>
              <ExpertHelpLabel
                label={"Boundary condition bottom: potential temperature"}
                helpLink={`${helpLink}/initialization_parameters#bc_pt_b`}
              />
              <Select
                isSearchable={false}
                styles={selectSetupStyle}
                options={boundaryCounditionsOptions}
                value={{
                  value: dynamic_wind_profile.boundary_conditions.bc_pt_b,
                  label: dynamic_wind_profile.boundary_conditions.bc_pt_b,
                }}
                onChange={(e) =>
                  dispatch(
                    changeExpertInput({
                      page: page,
                      subpage: subpage,
                      key: "boundary_conditions",
                      subkey: "bc_pt_b",
                      value: e.value,
                    })
                  )
                }
              />
              <div style={{ marginTop: 12 }} />
            </>
          )}
          {dynamic_wind_profile.initialization.humidity && (
            <>
              <ExpertHelpLabel
                label={"Boundary condition bottom: water vapor / mixing ratio"}
                helpLink={`${helpLink}/initialization_parameters#bc_q_b`}
              />
              <Select
                isSearchable={false}
                styles={selectSetupStyle}
                options={boundaryCounditionsOptions}
                value={{
                  value: dynamic_wind_profile.boundary_conditions.bc_q_b,
                  label: dynamic_wind_profile.boundary_conditions.bc_q_b,
                }}
                onChange={(e) =>
                  dispatch(
                    changeExpertInput({
                      page: page,
                      subpage: subpage,
                      key: "boundary_conditions",
                      subkey: "bc_q_b",
                      value: e.value,
                    })
                  )
                }
              />
            </>
          )}
          <ExpertHelpLabel
            label={"Boundary condition bottom: horizontal wind"}
            helpLink={`${helpLink}/initialization_parameters#bc_uv_b`}
          />
          <Select
            isSearchable={false}
            styles={selectSetupStyle}
            options={boundaryCounditionsOptions}
            value={{
              value: dynamic_wind_profile.boundary_conditions.bc_uv_b,
              label: dynamic_wind_profile.boundary_conditions.bc_uv_b,
            }}
            onChange={(e) =>
              dispatch(
                changeExpertInput({
                  page: page,
                  subpage: subpage,
                  key: "boundary_conditions",
                  subkey: "bc_uv_b",
                  value: e.value,
                })
              )
            }
          />
          <div style={{ marginTop: 12 }} />
          <ExpertHelpLabel
            label={"Boundary condition top: pertubation pressure"}
            helpLink={`${helpLink}/initialization_parameters#bc_p_t`}
          />
          <Select
            isSearchable={false}
            styles={selectSetupStyle}
            options={boundaryCounditionsOptions}
            value={{
              value: dynamic_wind_profile.boundary_conditions.bc_p_t,
              label: dynamic_wind_profile.boundary_conditions.bc_p_t,
            }}
            onChange={(e) =>
              dispatch(
                changeExpertInput({
                  page: page,
                  subpage: subpage,
                  key: "boundary_conditions",
                  subkey: "bc_p_t",
                  value: e.value,
                })
              )
            }
          />
          <div style={{ marginTop: 12 }} />
          {!dynamic_wind_profile.initialization.neutral && (
            <>
              <ExpertHelpLabel
                label={"Boundary condition top: potential temperature"}
                helpLink={`${helpLink}/initialization_parameters#bc_pt_t`}
              />
              <Select
                isSearchable={false}
                styles={selectSetupStyle}
                options={[
                  { label: "initial_gradient", value: "initial_gradient" },
                  { label: "neumann", value: "neumann" },
                  { label: "dirichlet", value: "dirichlet" },
                ]}
                value={{
                  value: dynamic_wind_profile.boundary_conditions.bc_pt_t,
                  label: dynamic_wind_profile.boundary_conditions.bc_pt_t,
                }}
                onChange={(e) =>
                  dispatch(
                    changeExpertInput({
                      page: page,
                      subpage: subpage,
                      key: "boundary_conditions",
                      subkey: "bc_pt_t",
                      value: e.value,
                    })
                  )
                }
              />
              <div style={{ marginTop: 12 }} />
            </>
          )}
          {dynamic_wind_profile.initialization.humidity && (
            <>
              <ExpertHelpLabel
                label={"Boundary condition top: water vapor / mixing ratio"}
                helpLink={`${helpLink}/initialization_parameters#bc_q_t`}
              />
              <Select
                isSearchable={false}
                styles={selectSetupStyle}
                options={boundaryCounditionsOptions}
                value={{
                  value: dynamic_wind_profile.boundary_conditions.bc_q_t,
                  label: dynamic_wind_profile.boundary_conditions.bc_q_t,
                }}
                onChange={(e) =>
                  dispatch(
                    changeExpertInput({
                      page: page,
                      subpage: subpage,
                      key: "boundary_conditions",
                      subkey: "bc_q_t",
                      value: e.value,
                    })
                  )
                }
              />
              <div style={{ marginTop: 12 }} />
            </>
          )}
          <ExpertHelpLabel
            label={"Boundary condition top: horizontal wind"}
            helpLink={`${helpLink}/initialization_parameters#bc_uv_t`}
          />
          <Select
            isSearchable={false}
            styles={selectSetupStyle}
            options={boundaryCounditionsOptions}
            value={{
              value: dynamic_wind_profile.boundary_conditions.bc_uv_t,
              label: dynamic_wind_profile.boundary_conditions.bc_uv_t,
            }}
            onChange={(e) =>
              dispatch(
                changeExpertInput({
                  page: page,
                  subpage: subpage,
                  key: "boundary_conditions",
                  subkey: "bc_uv_t",
                  value: e.value,
                })
              )
            }
          />
          <FormSettingsSeparator
            style={{ fontSize: "17px" }}
            label={"Numerics"}
          />
          <ExpertHelpLabel
            label={"Rayleigh dampening factor [-]"}
            helpLink={`${helpLink}/initialization_parameters#rayleigh_damping_factor`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="numerics"
            subkey="rayleigh_dampening_factor"
            min={0}
            max={1}
            step={0.01}
            constValue={dynamic_wind_profile.numerics.rayleigh_dampening_factor}
          />
          <ExpertHelpLabel
            label={
              "Rayleigh dampening height (0 ist converted to PALM-Default) [m]"
            }
            helpLink={`${helpLink}/initialization_parameters#rayleigh_damping_height`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="numerics"
            subkey="rayleigh_dampening_height"
            min={0}
            max={5000}
            step={1}
            constValue={dynamic_wind_profile.numerics.rayleigh_dampening_height}
          />
          <ExpertHelpLabel
            label={"Poisson equation solver"}
            helpLink={`${helpLink}/initialization_parameters#psolver`}
          />
          <Select
            isSearchable={false}
            styles={selectSetupStyle}
            options={[
              {
                value: "poisfft",
                label: "poisfft",
              },
              {
                value: "multigrid",
                label: "multigrid",
              },
              {
                value: "multigrid_noopt",
                label: "multigrid_noopt",
              },
            ]}
            value={{
              label: dynamic_wind_profile.numerics.psolver,
              value: dynamic_wind_profile.numerics.psolver,
            }}
            onChange={(e) =>
              dispatch(
                changeExpertInput({
                  page: page,
                  subpage: subpage,
                  key: "numerics",
                  subkey: "psolver",
                  value: e.value,
                })
              )
            }
          />
          <div style={{ marginTop: 12 }} />
          <ExpertHelpLabel
            label={"Multigrid solver"}
            helpLink={`${helpLink}/initialization_parameters#cycle_mg`}
          />
          <Select
            isSearchable={false}
            styles={selectSetupStyle}
            options={[
              {
                value: "w",
                label: "w",
              },
              {
                value: "v",
                label: "v",
              },
            ]}
            value={{
              label: dynamic_wind_profile.numerics.cycle_mg,
              value: dynamic_wind_profile.numerics.cycle_mg,
            }}
            onChange={(e) =>
              dispatch(
                changeExpertInput({
                  page: page,
                  subpage: subpage,
                  key: "numerics",
                  subkey: "cycle_mg",
                  value: e.value,
                })
              )
            }
          />
          <div style={{ marginTop: 12 }} />
          <ExpertHelpLabel
            label={"Number of cycles of multigrid solver [-]"}
            helpLink={`${helpLink}/initialization_parameters#mg_cycles`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="numerics"
            subkey="mg_cycles"
            min={1}
            max={6}
            step={1}
            constValue={dynamic_wind_profile.numerics.mg_cycles}
          />
          <FormSettingsSeparator
            style={{ fontSize: "17px" }}
            label={"Physics"}
          />
          <ExpertHelpLabel
            label={
              "Angular velocity of rotating system (Coriolis-Parameter) [1/s]"
            }
            helpLink={`${helpLink}/initialization_parameters#omega`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="physics"
            subkey="omega"
            min={0}
            max={0.0001}
            step={0.00001}
            constValue={dynamic_wind_profile.physics.omega}
          />
          <ExpertHelpLabel
            label={"Latitude of simulation is given by static_driver"}
          />
          <input className="setup-input" value={centerWg.lat} disabled />
          <ExpertHelpLabel
            label={"Longitude of simulation is given by static_driver"}
          />
          <input className="setup-input" value={centerWg.lng} disabled />
        </>
      )}
    </>
  );
};

export default DynamicWindProfile;
