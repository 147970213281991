import { types } from "../types/chemistry";

export function setChSeason(payload) {
  return {
    type: types.SET_CH_SEASON,
    payload,
  };
}

export function setChStartDay(payload) {
  return {
    type: types.SET_CH_START_DAY,
    payload,
  };
}

export function setChDuration(payload) {
  return {
    type: types.SET_CH_DURATION,
    payload,
  };
}

export function setChAirTemperatureSurface(payload) {
  return {
    type: types.SET_CH_AIR_TEMPERATURE_SURFACE,
    payload,
  };
}

export function setChRelHumidity(payload) {
  return {
    type: types.SET_CH_REL_HUMIDITY,
    payload,
  };
}

export function setChWindSpeed(payload) {
  return {
    type: types.SET_CH_WIND_SPEED,
    payload,
  };
}

export function setChWindDirection(payload) {
  return {
    type: types.SET_CH_WIND_DIRECTION,
    payload,
  };
}

export function setChMeanTemperature(payload) {
  return {
    type: types.SET_CH_MEAN_TEMPERATURE,
    payload,
  };
}

export function setChAmplitude(payload) {
  return {
    type: types.SET_CH_AMPLITUDE,
    payload,
  };
}

export function setChSoilMoisture(payload) {
  return {
    type: types.SET_CH_SOIL_MOISTURE,
    payload,
  };
}
export function setChSoilTemperature(payload) {
  return {
    type: types.SET_CH_SOIL_TEMPERATURE,
    payload,
  };
}

export function setChUserDefinedSoilMoisture(payload) {
  return {
    type: types.SET_CH_USER_DEFINED_SOIL_MOISTURE,
    payload,
  };
}

export function setChUserDefinedSoilTemperatue(payload) {
  return {
    type: types.SET_CH_USER_DEFINED_SOIL_TEMPERATURE,
    payload,
  };
}

export function setChInputPresets(payload) {
  return {
    type: types.SET_CH_INPUT_PRESETS,
    payload,
  };
}

export function setChOutputPresets(payload) {
  return {
    type: types.SET_CH_OUTPUT_PRESETS,
    payload,
  };
}

export function setSurfaceCsFlux25(payload) {
  return {
    type: types.SET_SURFACE_CS_FLUX_25,
    payload,
  };
}

export function setSurfaceCsFlux10(payload) {
  return {
    type: types.SET_SURFACE_CS_FLUX_10,
    payload,
  };
}

export function setChOutputParameters(payload) {
  return {
    type: types.SET_CH_OUTPUT_PARAMETERS,
    payload,
  };
}

export function setChMapOutputInterval(payload) {
  return {
    type: types.SET_CH_MAP_OUTPUT_INTERVAL,
    payload,
  };
}

export function setChVerticalOutputInterval(payload) {
  return {
    type: types.SET_CH_VERTICAL_OUTPUT_INTERVAL,
    payload,
  };
}

export function setChTimeSeriesOutputInterval(payload) {
  return {
    type: types.SET_CH_TIME_SERIES_OUTPUT_INTERVAL,
    payload,
  };
}

export function setChVerticalPoi(payload) {
  return {
    type: types.SET_CH_VERTICAL_POI,
    payload,
  };
}

export function setChTimeSeriesPoi(payload) {
  return {
    type: types.SET_CH_TIME_SERIES_POI,
    payload,
  };
}

export function setChInitialPoiList(payload) {
  return {
    type: types.SET_CH_INITIAL_POI_LIST,
    payload,
  };
}

export function setChAssesmentTimeFrom(payload) {
  return {
    type: types.SET_CH_ASSESMENT_TIME_FROM,
    payload,
  };
}

export function setChAssesmentTimeTo(payload) {
  return {
    type: types.SET_CH_ASSESMENT_TIME_TO,
    payload,
  };
}

export function updateChEmissionsFactorTable(payload) {
  return {
    type: types.UPDATE_CH_EMISSIONS_FACTOR_TABLE,
    payload,
  };
}

export function updateChPointEmissionsTable(payload) {
  return {
    type: types.UPDATE_CH_POINT_EMISSIONS_TABLE,
    payload,
  };
}

export function setPointEmissionTableType(payload) {
  return {
    type: types.SET_POINT_EMISSION_TABLE_TYPE,
    payload,
  };
}

export function loadChemistrySettings(payload) {
  return {
    type: types.LOAD_CHEMISTRY_SETTINGS,
    payload,
  };
}

export function resetChemistrySettings() {
  return {
    type: types.RESET_CHEMISTRY_SETTINGS,
  };
}

export function setChPoiOptions(payload) {
  return {
    type: types.SET_CH_POI_OPTIONS,
    payload,
  };
}
