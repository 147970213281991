import { types } from "../types/expert";

export function changeExpertPage(payload) {
  return {
    type: types.CHANGE_EXPERT_PAGE,
    payload,
  };
}

export function changeExpertInput(payload) {
  return {
    type: types.CHANGE_EXPERT_INPUT,
    payload,
  };
}

export function changeExpertTempInput(payload) {
  return {
    type: types.CHANGE_EXPERT_TEMP_INPUT,
    payload,
  };
}

export function changeExpertTemperatureInput(payload) {
  return {
    type: types.CHANGE_EXPERT_TEMPERATURE_INPUT,
    payload,
  };
}

export function changeExpertTimeInput(payload) {
  return {
    type: types.CHANGE_EXPERT_TIME_INPUT,
    payload,
  };
}

export function changeExpertDropdownInput(payload) {
  return {
    type: types.CHANGE_EXPERT_DROPDOWN_INPUT,
    payload,
  };
}

export function updateCheckboxExpertTable(payload) {
  return {
    type: types.UPDATE_CHECKBOX_EXPERT_TABLE,
    payload,
  };
}

export function updateLandSurfaceTable(payload) {
  return {
    type: types.UPDATE_LAND_SURFACE_TABLE,
    payload,
  };
}

export function updateLandSurfaceTableRows(payload) {
  return {
    type: types.UPDATE_LAND_SURFACE_TABLE_ROWS,
    payload,
  };
}

export function changeExpertDateTime(payload) {
  return {
    type: types.CHANGE_EXPERT_DATE_TIME,
    payload,
  };
}

export function upddateVectorTableRows(payload) {
  return {
    type: types.UPDATE_VECTOR_TABLE_ROWS,
    payload,
  };
}

export function upddateVectorTable(payload) {
  return {
    type: types.UPDATE_VECTOR_TABLE,
    payload,
  };
}

export function changeExpertRuntimeTemplate(payload) {
  return {
    type: types.CHANGE_EXPERT_RUNTIME_TEMPLATE,
    payload,
  };
}

export function changeExpertRadiationScheme(payload) {
  return {
    type: types.CHANGE_EXPERT_RADIATION_SCHEME,
    payload,
  };
}

export function loadExpertJson(payload) {
  return {
    type: types.LOAD_EXPERT_JSON,
    payload,
  };
}

export function resetExpertJson(payload) {
  return {
    type: types.RESET_EXPERT_JSON,
    payload,
  };
}
