import { types } from "../types/wind";

export function setWindStatisticSource(payload) {
  return {
    type: types.SET_WIND_STATISTIC_SOURCE,
    payload,
  };
}

export function setWindDirectionsNumber(payload) {
  return {
    type: types.SET_WIND_DIRECTIONS_NUMBER,
    payload,
  };
}

export function setMeasurementHeight(payload) {
  return {
    type: types.SET_MEAUSEREMENT_HEIGHT,
    payload,
  };
}

export function setMeasurementLocation(payload) {
  return {
    type: types.SET_MEASUREMENT_LOCATION,
    payload,
  };
}

export function setMeasurementAlpha(payload) {
  return {
    type: types.SET_MEASUREMENT_ALPHA,
    payload,
  };
}

export function setSimDomainLocation(payload) {
  return {
    type: types.SET_SIM_DOMAIN_LOCATION,
    payload,
  };
}

export function setSimDomainAlpha(payload) {
  return {
    type: types.SET_SIM_DOMAIN_ALPHA,
    payload,
  };
}

export function updateWindTable(payload) {
  return {
    type: types.UPDATE_WIND_TABLE,
    payload,
  };
}

export function resetWindSettings() {
  return {
    type: types.RESET_WIND_SETTINGS,
  };
}

export function loadWindSettings(payload) {
  return {
    type: types.LOAD_WIND_SETTINGS,
    payload,
  };
}

export function setWindTableFile(payload) {
  return {
    type: types.SET_WIND_TABLE_FILE,
    payload,
  };
}
