import { MapControl, withLeaflet } from "react-leaflet";
import * as L from "leaflet";
import * as plotty from "leaflet-geotiff-2/node_modules/plotty";
import chroma from "chroma-js";
import { connect } from "react-redux";
import { results as resultsColors } from "../../../../common/VisualizationPresets/colorPalettes";
import { withTranslation } from "react-i18next";
import "leaflet.browser.print/dist/leaflet.browser.print";
import "./ResultsGeotiffLegend.scss";

class ResultsGeotiffLegend extends MapControl {
  createLeafletElement(props) {}

  componentDidMount() {
    if (
      this.props.resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs
        .length === 0
    ) {
      return;
    }

    const { t } = this.props;
    const legend = L.control({ position: "topright" });
    this.props.resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs.map(
      (x) => {
        const elements = document.getElementsByClassName(x.name);

        if (x.checked && elements[0] === undefined && x.type !== "wind_dir") {
          legend.onAdd = () => {
            const div = L.DomUtil.create(
              "div",
              `${this.props.currentActiveResultsView} ${x.name} res-geo-info res-geo-legend`
            );
            const plotColor = x.plot_color;

            let plottyColorscale =
              x.type === "wind_stream" ||
              plotty.colorscales[plotColor] === undefined
                ? chroma.brewer[plotColor]
                : plotty.colorscales[plotColor].colors;

            let breaks = plottyColorscale?.length;
            let plotMin = x.plot_min;
            const plotMax = x.plot_max;

            if (!breaks || breaks === 2) {
              breaks = 9;
            }

            if (!plottyColorscale || plottyColorscale.length === 2) {
              plottyColorscale = resultsColors[plotColor];
            }

            let labels = [];
            labels.push(
              `<label style="font-weight:600; font-family:"Segoe UI">${
                t(x.name) + ` ${x.set_unit}`
              }</label>`
            );

            let fixedDigits = 1;

            if (plotMin === 0 && plotMax <= 1) {
              fixedDigits = 2;
            }

            if (x.plot_preset_names) {
              breaks = breaks - 1;
            }

            let intervalValue = ((plotMax - plotMin) / breaks).toFixed(
              fixedDigits
            );
            intervalValue = Number(intervalValue);
            plotMin = Number(plotMin);

            for (let i = 0; i < breaks; i++) {
              let squareValue = parseFloat(plotMin + intervalValue * i).toFixed(
                fixedDigits
              );
              if (
                squareValue &&
                squareValue.toString()[squareValue.length - 1] === "0"
              ) {
                squareValue = parseFloat(squareValue).toFixed(1);
                if (squareValue.toString()[squareValue.length - 1] === "0") {
                  squareValue = parseFloat(squareValue).toFixed(0);
                }
              }

              if (x.plot_preset_names !== null) {
                squareValue = t(x.plot_preset_names[i]);
              }

              labels.push(
                '<i style="background:' +
                  plottyColorscale[i] +
                  '"></i> ' +
                  squareValue
              );
            }

            div.innerHTML = labels.join("<br>");
            return div;
          };
          const { map } = this.props.leaflet;
          legend.addTo(map);
        } else if (x.checked === false && elements[0] !== undefined) {
          elements[0].remove();
        }
      }
    );
  }
  componentDidUpdate(prevProps) {
    const { t } = this.props;

    if (
      (this.props.legendVisibilityChanged !==
        prevProps.legendVisibilityChanged &&
        this.props.resultsJsonOrigin?.pages[this.props.currentViewIndex] !==
          undefined) ||
      (!prevProps.inResultsMapView &&
        this.props.inResultsMapView &&
        this.props.jsonOriginPartSelected.name !==
          prevProps.jsonOriginPartSelected.name)
    ) {
      const legend = L.control({ position: "topright" });
      this.props.resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs.map(
        (x) => {
          const elements = document.getElementsByClassName(x.name);

          if (
            x.checked === true &&
            elements[0] === undefined &&
            x.type !== "wind_dir"
          ) {
            legend.onAdd = () => {
              const div = L.DomUtil.create(
                "div",
                `${x.type === "wind_stream" ? x.type : ""} ${
                  this.props.currentActiveResultsView
                } ${x.name} res-geo-info res-geo-legend`
              );
              const plotColor = x.plot_color;

              let plottyColorscale =
                x.type === "wind_stream" ||
                plotty.colorscales[plotColor] === undefined
                  ? chroma.brewer[plotColor]
                  : plotty.colorscales[plotColor].colors;

              let breaks = plottyColorscale?.length;
              let plotMin = x.plot_min;
              const plotMax = x.plot_max;

              if (!breaks || breaks === 2) {
                breaks = 9;
              }
              if (!plottyColorscale || plottyColorscale.length === 2) {
                plottyColorscale = resultsColors[plotColor];
              }
              let labels = [];
              labels.push(
                `<label style="font-weight:600; font-family:"Segoe UI">${
                  t(x.name) + ` ${x.set_unit}`
                }</label>`
              );
              let fixedDigits = 1;

              if (plotMin === 0 && plotMax <= 1) {
                fixedDigits = 2;
              }

              if (x.plot_preset_names) {
                breaks = breaks - 1;
              }

              let intervalValue = ((plotMax - plotMin) / (breaks - 1)).toFixed(
                fixedDigits
              );
              intervalValue = Number(intervalValue);
              plotMin = Number(plotMin);

              for (let i = 0; i < breaks; i++) {
                let squareValue = parseFloat(
                  plotMin + intervalValue * i
                ).toFixed(fixedDigits);
                if (
                  squareValue &&
                  squareValue.toString()[squareValue.length - 1] === "0"
                ) {
                  squareValue = parseFloat(squareValue).toFixed(1);
                  if (squareValue.toString()[squareValue.length - 1] === "0") {
                    squareValue = parseFloat(squareValue).toFixed(0);
                  }
                }

                if (!x.plot_preset_names && i === breaks - 1) {
                  squareValue = plotMax;
                }

                if (x.plot_preset_names !== null) {
                  squareValue = t(x.plot_preset_names[i]);
                }

                if (x.set_sl && i % 2 === 0) {
                  labels.push(
                    '<i style="background:' +
                      plottyColorscale[i] +
                      '"></i> ' +
                      squareValue
                  );
                } else if (!x.set_sl) {
                  labels.push(
                    '<i style="background:' +
                      plottyColorscale[i] +
                      '"></i> ' +
                      squareValue
                  );
                }
              }

              div.innerHTML = labels.join("<br>");
              return div;
            };
            const { map } = this.props.leaflet;
            legend.addTo(map);
          } else if (x.checked === false && elements[0] !== undefined) {
            elements[0].remove();
          }
        }
      );
    } else if (
      this.props.resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs[
        this.props.jsonOriginLayerIndex
      ] &&
      this.props.resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs
        .length !== 0 &&
      this.props.resultsJsonOrigin &&
      this.props.resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs[
        this.props.jsonOriginLayerIndex
      ].checked &&
      this.props.resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs[
        this.props.jsonOriginLayerIndex
      ].checked !==
        prevProps.resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs[
          this.props.jsonOriginLayerIndex
        ].checked
    ) {
      const legend = L.control({ position: "topright" });
      this.props.resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs.map(
        (x) => {
          const elements = document.getElementsByClassName(x.name);

          if (
            x.checked === true &&
            elements[0] === undefined &&
            x.type !== "wind_dir"
          ) {
            legend.onAdd = () => {
              const div = L.DomUtil.create(
                "div",
                `${x.type === "wind_stream" ? x.type : ""} ${
                  this.props.currentActiveResultsView
                } ${x.name} res-geo-info res-geo-legend`
              );
              const plotColor = x.plot_color;

              let plottyColorscale =
                x.type === "wind_stream" ||
                plotty.colorscales[plotColor] === undefined
                  ? chroma.brewer[plotColor]
                  : plotty.colorscales[plotColor].colors;

              let breaks = plottyColorscale?.length;
              let plotMin = x.plot_min;
              const plotMax = x.plot_max;

              if (!breaks || breaks === 2) {
                breaks = 9;
              }

              if (!plottyColorscale || plottyColorscale.length === 2) {
                plottyColorscale = resultsColors[plotColor];
              }

              if (x.type === "wind_stream") {
                plottyColorscale = chroma.brewer[plotColor];
                breaks = plottyColorscale?.length;
              }

              let labels = [];
              labels.push(
                `<label style="font-weight:600; font-family:"Segoe UI">${
                  t(x.name) + ` ${x.set_unit}`
                }</label>`
              );
              let fixedDigits = 1;
              if (plotMin === 0 && plotMax <= 1) {
                fixedDigits = 2;
              }

              if (x.plot_preset_names) {
                breaks = breaks - 1;
              }
              let intervalValue = ((plotMax - plotMin) / (breaks - 1)).toFixed(
                fixedDigits
              );

              intervalValue = Number(intervalValue);
              plotMin = Number(plotMin);

              for (let i = 0; i < breaks; i++) {
                let squareValue = parseFloat(
                  plotMin + intervalValue * i
                ).toFixed(fixedDigits);
                if (
                  squareValue &&
                  squareValue.toString()[squareValue.length - 1] === "0"
                ) {
                  squareValue = parseFloat(squareValue).toFixed(1);
                  if (squareValue.toString()[squareValue.length - 1] === "0") {
                    squareValue = parseFloat(squareValue).toFixed(0);
                  }
                }

                if (!x.plot_preset_names && i === breaks - 1) {
                  squareValue = plotMax;
                } else if (!x.plot_preset_names && i === breaks - 1) {
                  squareValue = plotMax;
                }
                if (x.plot_preset_names !== null) {
                  squareValue = t(x.plot_preset_names[i]);
                }

                if (x.set_sl && i % 2 === 0) {
                  labels.push(
                    '<i style="background:' +
                      plottyColorscale[i] +
                      '"></i> ' +
                      squareValue
                  );
                } else if (!x.set_sl) {
                  labels.push(
                    '<i style="background:' +
                      plottyColorscale[i] +
                      '"></i> ' +
                      squareValue
                  );
                }
              }

              div.innerHTML = labels.join("<br>");
              return div;
            };
            const { map } = this.props.leaflet;
            legend.addTo(map);

            L.LegendControl = L.Control.extend({
              onAdd: function (mymap) {
                var div = document.getElementsByClassName(
                  "leaflet-top leaflet-right"
                );
                var printedLegend = L.DomUtil.create("div", "res-geo-legend");
                printedLegend.innerHTML = div[0].innerHTML;
                return printedLegend;
              },
            });

            L.legendControl = function (options) {
              return new L.LegendControl(options);
            };

            this.props.staticDriverMapRef.current.leafletElement.on(
              "browser-print-start",
              function (e) {
                L.legendControl({ position: "bottomright" }).addTo(e.printMap);
              }
            );
          } else if (x.checked === false && elements[0] !== undefined) {
            elements[0].remove();
          }
        }
      );
    } else if (
      (prevProps.jsonOriginPartSelected &&
        this.props.jsonOriginPartSelected &&
        this.props.jsonOriginPartSelected.name ===
          prevProps.jsonOriginPartSelected.name &&
        this.props.jsonOriginPartSelected.plot_color !==
          prevProps.jsonOriginPartSelected.plot_color) ||
      this.props.legendVisibilityChanged !== prevProps.legendVisibilityChanged
    ) {
      const legend = L.control({ position: "topright" });
      this.props.resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs.map(
        (x) => {
          if (x.type === "wind_dir") {
            return;
          }

          const elements = document.getElementsByClassName(x.name);
          if (
            elements[0] !== undefined &&
            x.name === this.props.jsonOriginPartSelected.name
          ) {
            elements[0].remove();
          }
          if (x.checked === true) {
            legend.onAdd = () => {
              const div = L.DomUtil.create(
                "div",
                `${x.type === "wind_stream" ? x.type : ""}  ${
                  this.props.currentActiveResultsView
                } ${x.name} res-geo-info res-geo-legend`
              );
              const plotColor = x.plot_color;

              let plottyColorscale =
                x.type === "wind_stream"
                  ? chroma.brewer[plotColor]
                  : plotty.colorscales[plotColor].colors;

              let breaks = plottyColorscale?.length;
              let plotMin = x.plot_min;
              const plotMax = x.plot_max;

              if (!breaks || breaks === 2) {
                breaks = 9;
              }

              if (!plottyColorscale || plottyColorscale.length === 2) {
                plottyColorscale = resultsColors[plotColor];
              }

              let labels = [];
              labels.push(
                `<label style="font-weight:600; font-family:"Segoe UI">${
                  t(x.name) + ` ${x.set_unit}`
                }</label>`
              );

              let fixedDigits = 1;
              if (plotMin === 0 && plotMax <= 1) {
                fixedDigits = 2;
              }

              if (x.plot_preset_names) {
                breaks = breaks - 1;
              }
              let intervalValue = ((plotMax - plotMin) / (breaks - 1)).toFixed(
                fixedDigits
              );
              intervalValue = Number(intervalValue);
              plotMin = Number(plotMin);

              for (let i = 0; i < breaks; i++) {
                let squareValue = parseFloat(
                  plotMin + intervalValue * i
                ).toFixed(fixedDigits);
                if (
                  squareValue &&
                  squareValue.toString()[squareValue.length - 1] === "0"
                ) {
                  squareValue = parseFloat(squareValue).toFixed(1);
                  if (squareValue.toString()[squareValue.length - 1] === "0") {
                    squareValue = parseFloat(squareValue).toFixed(0);
                  }
                }

                if (x.plot_preset_names !== null) {
                  squareValue = t(x.plot_preset_names[i]);
                }
                if (x.set_sl && i % 2 === 0) {
                  labels.push(
                    '<i style="background:' +
                      plottyColorscale[i] +
                      '"></i> ' +
                      squareValue
                  );
                } else if (!x.set_sl) {
                  labels.push(
                    '<i style="background:' +
                      plottyColorscale[i] +
                      '"></i> ' +
                      squareValue
                  );
                }
              }

              div.innerHTML = labels.join("<br>");
              return div;
            };
            const { map } = this.props.leaflet;
            legend.addTo(map);
          }
        }
      );
    } else if (
      (this.props.currentActiveResultsView !==
        prevProps.currentActiveResultsView &&
        this.props.currentActiveResultsView !== "+" &&
        this.props.currentActiveResultsView !== "Report") ||
      prevProps.currentActiveResultsView === "+" ||
      prevProps.currentActiveResultsView === "Report"
    ) {
      if (
        this.props.resultsJsonOrigin.pages.length ===
        prevProps.resultsJsonOrigin.pages.length
      ) {
        this.props.resultsJsonOrigin.pages[prevProps.currentViewIndex].lyrs.map(
          (x) => {
            const elements = document.getElementsByClassName(x.name);
            if (elements[0] !== undefined) {
              elements[0].remove();
            }
          }
        );
      } else {
        prevProps.resultsJsonOrigin.pages[prevProps.currentViewIndex].lyrs.map(
          (x) => {
            const elements = document.getElementsByClassName(x.name);
            if (elements[0] !== undefined) {
              elements[0].remove();
            }
          }
        );
      }

      this.props.resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs.map(
        (x) => {
          const elements = document.getElementsByClassName(x.name);

          if (
            x.checked === true &&
            elements[0] === undefined &&
            x.type !== "wind_dir"
          ) {
            const legend = L.control({ position: "topright" });
            if (x.type === "wind_stream") {
              return;
            }
            legend.onAdd = () => {
              const div = L.DomUtil.create(
                "div",
                `${x.type === "wind_stream" ? x.type : ""}  ${
                  this.props.currentActiveResultsView
                } ${x.name} res-geo-info res-geo-legend`
              );
              const plotColor = x.plot_color;

              let plottyColorscale =
                x.type === "wind_stream"
                  ? chroma.brewer[plotColor]
                  : plotty.colorscales[plotColor].colors;
              let breaks = plottyColorscale?.length;

              if (!breaks || breaks === 2) {
                breaks = 9;
              }

              if (!plottyColorscale || plottyColorscale.length === 2) {
                plottyColorscale = resultsColors[plotColor];
              }

              if (x.type === "wind_stream") {
                plottyColorscale = chroma.brewer[plotColor];
              }
              let plotMin = Number(x.plot_min);
              const plotMax = Number(x.plot_max);
              let fixedDigits = 1;

              let labels = [];
              labels.push(
                `<label style="font-weight:600; font-family:"Segoe UI">${
                  t(x.name) + ` ${x.set_unit}`
                }</label>`
              );
              if (plotMin === 0 && plotMax <= 1) {
                fixedDigits = 2;
              }

              if (x.plot_preset_names) {
                breaks = breaks - 1;
              }

              let intervalValue = ((plotMax - plotMin) / (breaks - 1)).toFixed(
                fixedDigits
              );

              intervalValue = Number(intervalValue);
              plotMin = Number(plotMin);

              for (let i = 0; i < breaks; i++) {
                let squareValue = parseFloat(
                  plotMin + intervalValue * i
                ).toFixed(fixedDigits);

                if (
                  squareValue &&
                  squareValue.toString()[squareValue.length - 1] === "0"
                ) {
                  squareValue = parseFloat(squareValue).toFixed(1);
                  if (squareValue.toString()[squareValue.length - 1] === "0") {
                    squareValue = parseFloat(squareValue).toFixed(0);
                  }
                }

                if (!x.plot_preset_names && i === breaks - 1) {
                  squareValue = plotMax;
                } else if (x.plot_preset_names) {
                  squareValue = t(x.plot_preset_names[i]);
                }

                if (x.set_sl && i % 2 === 0) {
                  labels.push(
                    '<i style="background:' +
                      plottyColorscale[i] +
                      '"></i> ' +
                      squareValue
                  );
                } else if (!x.set_sl) {
                  labels.push(
                    '<i style="background:' +
                      plottyColorscale[i] +
                      '"></i> ' +
                      squareValue
                  );
                }
              }

              div.innerHTML = labels.join("<br>");
              return div;
            };
            const { map } = this.props.leaflet;
            legend.addTo(map);
          }
        }
      );
    }
    ///usuwanie layera z nowego page jak masz aktywny layer
    else if (
      this.props.resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs
        .length <
      prevProps.resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs.length
    ) {
      let difference = getDifference(
        prevProps.resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs,
        this.props.resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs
      );

      difference.map((x) => {
        const elements = document.getElementsByClassName(x.name);
        if (elements[0] !== undefined) {
          elements[0].remove();
        }
      });
      const elements = document.getElementsByClassName(difference[0].name);
      if (elements[0] !== undefined) {
        elements[0].remove();
      }
    } else if (
      this.props.inLayerAppearance === prevProps.inLayerAppearance &&
      prevProps.jsonOriginPartSelected &&
      (this.props.jsonOriginPartSelected.plot_min !==
        prevProps.jsonOriginPartSelected.plot_min ||
        this.props.jsonOriginPartSelected.plot_max !==
          prevProps.jsonOriginPartSelected.plot_max)
    ) {
      const legend = L.control({ position: "topright" });
      this.props.resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs.map(
        (x) => {
          const elements = document.getElementsByClassName(x.name);
          if (x.checked) {
            legend.onAdd = () => {
              const div = L.DomUtil.create(
                "div",
                `${x.type === "wind_stream" ? x.type : ""} ${
                  this.props.currentActiveResultsView
                } ${x.name} res-geo-info res-geo-legend`
              );
              const plotColor = x.plot_color;

              let plottyColorscale =
                x.type === "wind_stream"
                  ? chroma.brewer[plotColor]
                  : plotty.colorscales[plotColor].colors;
              let breaks = plottyColorscale?.length;

              let plotMin = Number(x.plot_min);
              const plotMax = Number(x.plot_max);

              if (!breaks || breaks === 2) {
                breaks = 9;
              }
              if (!plottyColorscale || plottyColorscale.length === 2) {
                plottyColorscale = resultsColors[plotColor];
              }

              let intervalValue = ((plotMax - plotMin) / (breaks - 1)).toFixed(
                2
              );
              let labels = [];
              labels.push(
                `<label style="font-weight:600; font-family:"Segoe UI">${
                  t(x.name) + ` ${x.set_unit}`
                }</label>`
              );
              if (plotMin === 0 && plotMax === 1) {
                intervalValue = Number((plotMax + plotMin) / breaks).toFixed(2);
              }

              if (x.plot_preset_names) {
                breaks = breaks - 1;
              }

              for (let i = 0; i < breaks; i++) {
                let squareValue = parseFloat(
                  plotMin + intervalValue * i
                ).toFixed(2);

                if (
                  squareValue &&
                  squareValue.toString()[squareValue.length - 1] === "0"
                ) {
                  squareValue = parseFloat(squareValue).toFixed(1);
                  if (squareValue.toString()[squareValue.length - 1] === "0") {
                    squareValue = parseFloat(squareValue).toFixed(0);
                  }
                }

                if (i === breaks - 1) {
                  squareValue = plotMax;
                }

                if (x.plot_preset_names !== null) {
                  squareValue = t(x.plot_preset_names[i]);
                }

                if (x.set_sl && i % 2 === 0) {
                  labels.push(
                    '<i style="background:' +
                      plottyColorscale[i] +
                      '"></i> ' +
                      squareValue
                  );
                } else if (!x.set_sl) {
                  labels.push(
                    '<i style="background:' +
                      plottyColorscale[i] +
                      '"></i> ' +
                      squareValue
                  );
                }
              }
              if (x.name === this.props.jsonOriginPartSelected.name) {
                elements[0].remove();
              }
              div.innerHTML = labels.join("<br>");
              return div;
            };

            const { map } = this.props.leaflet;
            legend.addTo(map);
          }
        }
      );
    } else if (
      this.props.jsonOriginPartSelected &&
      prevProps.jsonOriginPartSelected &&
      this.props.jsonOriginPartSelected?.plot_preset_cols !==
        prevProps.jsonOriginPartSelected?.plot_preset_cols
    ) {
      //console.log("CATEGORY CHANGE COLOR");

      const legend = L.control({ position: "topright" });
      this.props.resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs.map(
        (x) => {
          const elements = document.getElementsByClassName(x.name);
          if (
            elements[0] !== undefined &&
            x.name === this.props.jsonOriginPartSelected.name
          ) {
            elements[0].remove();
          }

          if (
            x.checked === true
            // && elements[0] === undefined
          ) {
            legend.onAdd = () => {
              const div = L.DomUtil.create(
                "div",
                `${x.type === "wind_stream" ? x.type : ""} ${
                  this.props.currentActiveResultsView
                } ${x.name} res-geo-info res-geo-legend`
              );
              const plotColor = x.plot_color;
              let plottyColorscale =
                x.type === "wind_stream"
                  ? chroma.brewer[plotColor]
                  : plotty.colorscales[plotColor].colors;
              let breaks = plottyColorscale?.length;
              let plotMin = x.plot_min;
              const plotMax = x.plot_max;

              if (!breaks || breaks === 2) {
                breaks = 9;
              }

              if (!plottyColorscale || plottyColorscale.length === 2) {
                plottyColorscale = resultsColors[plotColor];
              }
              if (x.type === "wind_stream") {
                plottyColorscale = chroma.brewer[plotColor];
              }
              let intervalValue = ((plotMax - plotMin) / breaks).toFixed(1);

              let labels = [];
              labels.push(
                `<label style="font-weight:600; font-family:"Segoe UI">${
                  t(x.name) + ` ${x.set_unit}`
                }</label>`
              );
              if (plotMin === 0 && plotMax === 1) {
                intervalValue = Number(
                  (plotMax + plotMin) / (breaks - 1)
                ).toFixed(2);
              } else if (plotMin === -10) {
                intervalValue = parseFloat(
                  (plotMax - plotMin) / breaks
                ).toFixed(2);
              }

              if (x.plot_preset_names) {
                breaks = breaks - 1;
              }

              for (let i = 0; i < breaks; i++) {
                let squareValue = parseFloat(
                  plotMin + intervalValue * i
                ).toFixed(1);

                if (
                  squareValue &&
                  squareValue.toString()[squareValue.length - 1] === "0"
                ) {
                  squareValue = parseFloat(squareValue).toFixed(1);
                  if (squareValue.toString()[squareValue.length - 1] === "0") {
                    squareValue = parseFloat(squareValue).toFixed(0);
                  }
                }

                if (x.plot_preset_names !== null) {
                  squareValue = t(x.plot_preset_names[i]);
                }
                if (x.set_sl && i % 2 === 0) {
                  labels.push(
                    '<i style="background:' +
                      plottyColorscale[i] +
                      '"></i> ' +
                      squareValue
                  );
                } else if (!x.set_sl) {
                  labels.push(
                    '<i style="background:' +
                      plottyColorscale[i] +
                      '"></i> ' +
                      squareValue
                  );
                }
              }

              div.innerHTML = labels.join("<br>");
              return div;
            };
            const { map } = this.props.leaflet;
            legend.addTo(map);
          }
        }
      );
    }
  }

  componentWillUnmount() {
    const { map } = this.props.leaflet;
  }
}

const mapStateToProps = (state) => {
  return {
    resultsJsonOrigin: state.results.resultsJsonOrigin,
    currentViewIndex: state.results.currentViewIndex,
    currentActiveResultsView: state.results.currentActiveResultsView,
    legendVisibilityChanged: state.results.legendVisibilityChanged,
    jsonOriginPartSelected: state.results.jsonOriginPartSelected,
    nrOfAutoPages: state.results.nrOfAutoPages,
    jsonOriginLayerIndex: state.results.jsonOriginLayerIndex,
    inLayerAppearance: state.results.inLayerAppearance,
  };
};

export default withLeaflet(
  connect(mapStateToProps, null)(withTranslation()(ResultsGeotiffLegend))
);

function getDifference(array1, array2) {
  return array1.filter((object1) => {
    return !array2.some((object2) => {
      return object1.id === object2.id;
    });
  });
}
