import React from "react";
import Select from "react-select";
import { ExpertTimeAccordion } from "../../ExpertUtils/ExpertComponent";
import { ExpertHelpLabel } from "../../ExpertUtils/ExpertComponent";
import { FormSettingsSeparator } from "../../../../Components/Common/Form/FormSeparator/index";
import { helpLink } from "../../expertConst";
import { selectSetupStyle } from "../../../../Components/Common/PaletteDropdown/index";
import { changeExpertInput } from "../../../../redux/actions/expertActions";
import { useSelector, useDispatch } from "react-redux";

const [page, subpage] = ["output", "poi_vp"];

const PoiVerticalProfile = () => {
  const poi_vp = useSelector((state) => state.expert.expertJson.output.poi_vp);
  const dispatch = useDispatch();

  return (
    <>
      <div style={{ marginBottom: -20 }} />
      <FormSettingsSeparator
        style={{ fontSize: "17px" }}
        label={"General Settings"}
      />
      <ExpertHelpLabel label={"Select Points of Interest"} helpLink={""} />
      <Select
        isMulti
        menuPlacement="top"
        isClearable
        closeMenuOnSelect={false}
        styles={selectSetupStyle}
        placeholder={"Select"}
        options={[
          {
            value: "Point0",
            label: "Point0",
          },
          {
            value: "Point1",
            label: "Point1",
          },
        ]}
        //onChange={(e) => this.props.setThermalVariablesAveraging(e)}
        value={{
          value: "Point0",
          label: "Point0",
        }}
        classNamePrefix="select"
      />
      <FormSettingsSeparator
        style={{ fontSize: "17px" }}
        label={"Averaged Output"}
      />
      <ExpertHelpLabel
        label={"Activate averaged output"}
        helpLink={`${helpLink}/iofiles#DATA_MASK_02_AV_NETCDF`}
      />
      <Select
        isSearchable={false}
        styles={selectSetupStyle}
        options={[
          {
            value: "True",
            label: "True",
          },
          { value: "False", label: "False" },
        ]}
        value={{
          label: !!poi_vp.averaged.poivp_av_isActive ? "True" : "False",
          value: !!poi_vp.averaged.poivp_av_isActive ? "True" : "False",
        }}
        onChange={(e) =>
          dispatch(
            changeExpertInput({
              page: page,
              subpage: subpage,
              key: "averaged",
              subkey: "poivp_av_isActive",
              value: e.value === "True" ? true : false,
            })
          )
        }
      />
      <FormSettingsSeparator
        style={{ fontSize: "17px" }}
        label={"Instantaneous output"}
      />
      <ExpertHelpLabel
        label={"Activate instantaneous output"}
        helpLink={`${helpLink}/iofiles#DATA_MASK_02_AV_NETCDF`}
      />
      <Select
        isSearchable={false}
        styles={selectSetupStyle}
        options={[
          {
            value: "True",
            label: "True",
          },
          { value: "False", label: "False" },
        ]}
        value={{
          label: !!poi_vp.instantaneous.poivp_inst_isActive ? "True" : "False",
          value: !!poi_vp.instantaneous.poivp_inst_isActive ? "True" : "False",
        }}
        onChange={(e) =>
          dispatch(
            changeExpertInput({
              page: page,
              subpage: subpage,
              key: "instantaneous",
              subkey: "poivp_inst_isActive",
              value: e.value === "True" ? true : false,
            })
          )
        }
      />
      <div style={{ marginBottom: 12 }} />
      <ExpertHelpLabel
        label={"Instantaneous Output Intervall [s]"}
        helpLink={`${helpLink}/runtime_parameters#dt_domask`}
      />
      <ExpertTimeAccordion
        page={page}
        subpage={subpage}
        section="instantaneous"
        subkey="poivp_dt_domask"
        min={1}
        max={86400}
        constValue={poi_vp.instantaneous.poivp_dt_domask}
      />
    </>
  );
};

export default PoiVerticalProfile;
