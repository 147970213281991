import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import NewGeodataForm from "./NewGeodataForm";
import Select from "../../../Components/Common/Select/Select";
import { applicationFieldFunctionalities } from "../../../common/applicationFields";
import {
  resetGeojsonCheckFile,
  resetGeotiffCheckFile,
} from "../../../redux/actions/checkFileActions";
import "./Geodata.scss";

const CloneGeodataForm = (props) => {
  const { t } = props;
  const dispatch = useDispatch();

  const [caseToClone, setCaseToClone] = useState(
    props.cases ? props.cases[0] : null
  );
  const [copyTopography, setCopyTopography] = useState(
    props.copyTopography || false
  );
  const [copyDomain, setCopyDomain] = useState(props.copyDomain || false);
  const [copyStaticDriver, setCopyStaticDriver] = useState(
    props.copyStaticDriver || false
  );
  const [copySimulation, setCopySimulation] = useState(
    props.copySimulation || false
  );
  const [copyRasterizationSettings, setCopyRasterizationSettings] = useState(
    props.copyRasterizationSettings || false
  );

  const checkFile = useSelector((state) => state.checkFile);

  useEffect(() => {
    setCopyTopography(false);
    setCopyDomain(false);
    setCopyStaticDriver(false);
    setCopySimulation(false);
    setCopyRasterizationSettings(false);
  }, [caseToClone]);

  const onClonedCaseChange = (e) => {
    const matchingCase = props.cases.find((c) => c.value === e.target.value);
    setCaseToClone(matchingCase);
  };

  const requestClone = () => {
    if (caseToClone?.key?.type === 0) {
      if (copyDomain && props.geoJson) {
        props.clearUploadedFile("geoJson");
        dispatch(resetGeojsonCheckFile());
      }
      if (!copyDomain) {
        setCopyRasterizationSettings(false);
        setCopyStaticDriver(false);
      }
      if (copyTopography && props.geoTiff) {
        props.clearUploadedFile("geoTiff");
        dispatch(resetGeotiffCheckFile());
      }
      props.cloneRequestChange({
        sourceCaseId: caseToClone.key?.id,
        copyTopography: copyTopography,
        copyDomain: copyDomain,
        copyRasterizationSettings: copyRasterizationSettings,
        verifiedGeojson: copyDomain ? null : props.geoJson,
        topography: copyTopography ? null : props.geoTiff,
        staticDriver: copyStaticDriver ? null : props.staticDriver,
        copyStaticDriver: copyStaticDriver,
        copySimulationSettings: copySimulation,
        applicationField: props.applicationField,
        type: caseToClone.key?.type,
      });
      return;
    }
    if (caseToClone?.key?.type === 1) {
      if (copyStaticDriver && props.staticDriver) {
        props.clearUploadedFile("staticDriver");
      }
      props.cloneRequestChange({
        sourceCaseId: caseToClone.key?.id,
        staticDriver: copyStaticDriver ? null : props.staticDriver,
        copyStaticDriver: copyStaticDriver,
        copySimulationSettings: copySimulation,
        applicationField: props.applicationField,
        type: caseToClone.key?.type,
      });
      return;
    }
  };
  
  useEffect(() => {
    requestClone();
  }, []);

  useEffect(() => {
    requestClone();
  }, [
    caseToClone,
    copyStaticDriver,
    copySimulation,
    copyRasterizationSettings,
    copyTopography,
    copyDomain,
    props.geoJson,
    props.staticDriver,
    props.geoTiff,
  ]);

  const isSameApplicationField =
    caseToClone?.key?.applicationField === props.applicationField;
  const isQgis = caseToClone?.key?.type === 0;
  const isStaticDriver = caseToClone?.key?.type === 1;

  return (
    <div className="geodata-clone">
      <Select
        label={t("Case")}
        options={
          props.cases?.map((c) => {
            return { label: c.value, value: c.value };
          }) || []
        }
        value={caseToClone?.value}
        onChange={onClonedCaseChange}
      />
      <fieldset className="data-checkboxes">
        <legend>{t("ToClone")}:</legend>
        <CheckBox
          label={t("Topography")}
          checked={
            copyTopography && !isStaticDriver && caseToClone?.key?.topographyUrl
          }
          disabled={isStaticDriver || !caseToClone?.key?.topographyUrl}
          onChange={() => setCopyTopography(!copyTopography)}
        />
        <CheckBox
          label={t("CityModel")}
          checked={copyDomain}
          disabled={isStaticDriver || !caseToClone?.key?.verifiedGeojsonUrl}
          onChange={() => setCopyDomain(!copyDomain)}
        />
        <CheckBox
          label={t("StaticDriver")}
          checked={copyStaticDriver && isSameApplicationField}
          disabled={
            !isSameApplicationField ||
            (isQgis &&
              (!caseToClone?.key?.staticDriverJob?.resultUrl ||
                !copyDomain ||
                !copyTopography ||
                !copyRasterizationSettings))
          }
          onChange={() => setCopyStaticDriver(!copyStaticDriver)}
        />
        <CheckBox
          label={t("SimulationSetup")}
          checked={copySimulation && isSameApplicationField}
          disabled={
            !isSameApplicationField ||
            !applicationFieldFunctionalities.simulation.includes(
              caseToClone?.key?.applicationField
            )
          }
          onChange={() => setCopySimulation(!copySimulation)}
        />
        <CheckBox
          label={t("RasterizationSettings")}
          checked={copyRasterizationSettings && isSameApplicationField}
          disabled={
            !isSameApplicationField ||
            !isQgis ||
            !copyDomain ||
            (isQgis && !caseToClone?.key?.rasterParameters)
          }
          onChange={() =>
            setCopyRasterizationSettings(!copyRasterizationSettings)
          }
        />
      </fieldset>
      <NewGeodataForm
        geodataType={caseToClone?.key?.type}
        geojsonName={checkFile.geojsonName}
        geotiffName={checkFile.geotiffName}
        geoJson={props.geoJson}
        geoTiff={props.geoTiff}
        staticDriver={props.staticDriver}
        geojsonErrorMessage={t(checkFile.geojsonErrorMessage)}
        geotiffErrorMessage={t(checkFile.geotiffErrorMessage)}
        domainDisabled={copyDomain}
        topographyDisabled={copyTopography}
        staticDriverDisabled={copyStaticDriver}
        onGeoJsonChange={props.onGeoJsonChange}
        onGeoTiffChange={props.onGeoTiffChange}
        onStaticDriverChange={props.onStaticDriverChange}
        clearUploadedFile={props.clearUploadedFile}
      />
    </div>
  );
};

const CheckBox = (props) => {
  return (
    <div className="cbx-wrapper">
      <input
        id={`clone-cbx__${props.label}`}
        type="checkbox"
        className="cbx"
        {...props}
      />
      <label htmlFor={`clone-cbx__${props.label}`} className="cbx-label">
        {props.label}
      </label>
    </div>
  );
};

export default withTranslation()(CloneGeodataForm);
