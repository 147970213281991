import React, { useEffect, useState } from "react";
import {
  Info,
  Box,
  Compass,
  Cpu,
  File,
  Sun,
  Wind,
  FileText,
  Cloud,
  Clipboard,
} from "react-feather";
import AccordionSection from "../../Common/AccordionSection";
import SidebarItem from "../../Sidebar/SidebarItem";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import "./ProjectSidebar.scss";

const ProjectSidebar = (props) => {
  const { t } = useTranslation();

  return (
    <div className="sidebar-project__container">
      <div className="sidebar-project__name">{props.project.name}</div>
      <SidebarItem
        label={t("ProjectInformation")}
        route={`/projects/${props.project.id}/view`}
        icon={Info}
      />
      <SidebarItem
        label={t("ExternalFiles")}
        route={`/projects/${props.project.id}/files`}
        icon={File}
      />
      <div className="sidebar-cases">
        {props.project &&
          props.project.caseDetailsDto.map((c) => (
            <CaseAccordion
              key={c.id}
              project={props.project}
              case={c}
              expanded={props.expanded}
              steering={props.steering}
              roles={props.roles}
              applicationField={c.applicationField}
            />
          ))}
      </div>
    </div>
  );
};

const CaseAccordion = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [expanded, setExpanded] = useState(
    props.expanded ||
      isCaseActive(location.pathname, props.project.id, props.case.id)
  );

  useEffect(() => {
    const caseIsActive = isCaseActive(
      location.pathname,
      props.project.id,
      props.case.id
    );
    setExpanded(caseIsActive);
  }, [location, props.project, props.case]);

  return (
    <AccordionSection expanded={expanded} title={props.case.name}>
      <SidebarItem
        label={t("General")}
        route={`/projects/${props.project.id}/cases/${props.case.id}`}
        icon={Info}
        class="sidebar-item__accordion"
      />
      <SidebarItem
        label={t("Domain")}
        route={`/projects/${props.project.id}/cases/${props.case.id}/domain`}
        icon={Compass}
        class="sidebar-item__accordion"
        disabled={props.case.type === 1}
      />
      {props.applicationField === 0 && (
        <SidebarItem
          label={t("Setup")}
          route={`/projects/${props.project.id}/cases/${props.case.id}/setup`}
          icon={Box}
          class="sidebar-item__accordion"
          disabled
        />
      )}
      {props.applicationField === 1 && (
        <SidebarItem
          label={t("Setup")}
          route={`/projects/${props.project.id}/cases/${props.case.id}/thermalComfort`}
          icon={Sun}
          class="sidebar-item__accordion"
          disabled={false}
        />
      )}
      {props.applicationField === 2 && (
        <SidebarItem
          label={t("WindComfort")}
          route={`/projects/${props.project.id}/cases/${props.case.id}/windComfort`}
          icon={Wind}
          class="sidebar-item__accordion"
          disabled={false}
        />
      )}
      {props.applicationField === 3 && (
        <SidebarItem
          label={t("Chemistry")}
          route={`/projects/${props.project.id}/cases/${props.case.id}/chemistry`}
          icon={Cloud}
          class="sidebar-item__accordion"
          disabled={false}
        />
      )}
      {props.applicationField === 4 && (
        <SidebarItem
          label={t("Expert")}
          route={`/projects/${props.project.id}/cases/${props.case.id}/expert`}
          icon={Clipboard}
          class="sidebar-item__accordion"
          disabled={false}
        />
      )}
      <SidebarItem
        label={t("Simulation")}
        route={`/projects/${props.project.id}/cases/${props.case.id}/simulation`}
        icon={Cpu}
        class="sidebar-item__accordion"
        disabled={props.applicationField === 0}
      />
      <SidebarItem
        label={t("Results")}
        route={`/projects/${props.project.id}/cases/${props.case.id}/results`}
        icon={FileText}
        class="sidebar-item__accordion"
        disabled={
          !props.case.postProcessingJobs?.some((x) => {
            return x.status === 2;
          })
        }
      />
      <SidebarItem
        label={t("FileBrowser")}
        route={`/projects/${props.project.id}/cases/${props.case.id}/files`}
        icon={File}
        class="sidebar-item__accordion"
      />
    </AccordionSection>
  );
};

const isCaseActive = (pathname, projectId, caseId) => {
  return pathname.startsWith(`/projects/${projectId}/cases/${caseId}`);
};

export default ProjectSidebar;
