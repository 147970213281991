export const types = {
  SET_WIND_STATISTIC_SOURCE: "SET_WIND_STATISTIC_SOURCE",
  SET_WIND_DIRECTIONS_NUMBER: "SET_WIND_DIRECTIONS_NUMBER",
  SET_MEAUSEREMENT_HEIGHT: "SET_MEAUSEREMENT_HEIGHT",
  SET_MEASUREMENT_LOCATION: "SET_MEASUREMENT_LOCATION",
  SET_MEASUREMENT_ALPHA: "SET_MEASUREMENT_ALPHA",
  SET_SIM_DOMAIN_LOCATION: "SET_SIM_DOMAIN_LOCATION",
  SET_SIM_DOMAIN_ALPHA: "SET_SIM_DOMAIN_ALPHA",
  UPDATE_WIND_TABLE: "UPDATE_WIND_TABLE",
  RESET_WIND_SETTINGS: "RESET_WIND_SETTINGS",
  LOAD_WIND_SETTINGS: "LOAD_WIND_SETTINGS",
  SET_WIND_TABLE_FILE: "SET_WIND_TABLE_FILE",
};
