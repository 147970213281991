import React from "react";
import { CircleMarker } from "react-leaflet";
import { useSelector } from "react-redux";

export const SingleTreePropCopies = () => {
  const assignedSingleTreeCopyCoords = useSelector(
    (state) => state.map.assignedSingleTreeCopyCoords
  );
  return (
    <>
      {assignedSingleTreeCopyCoords.length !== 0 &&
        assignedSingleTreeCopyCoords.map((x) => (
          <CircleMarker
            center={x}
            color="red"
            fillColor="white"
            fillOpacity={"1"}
            radius={12}
            dashArray={"12"}
          />
        ))}
    </>
  );
};
