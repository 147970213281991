import { types } from "../types/projectFiles";

export function updateFile(projectId, file) {
  return {
    type: types.UPDATE_FILE,
    payload: { projectId, file },
  };
}
export function updateFileSuccess(projectId, files) {
  return {
    type: types.UPDATE_FILE_SUCCESS,
    payload: { projectId, files },
  };
}
export function updateFileFailure(projectId, files, error) {
  return {
    type: types.UPDATE_FILE_FAILURE,
    payload: { projectId, files, error },
  };
}
export function deleteFile(projectId, fileName) {
  return {
    type: types.DELETE_FILE,
    payload: { projectId, fileName },
  };
}
export function deleteFileSuccess(projectId, fileNames) {
  return {
    type: types.DELETE_FILE_SUCCESS,
    payload: { projectId, fileNames },
  };
}
export function deleteFileFailure(projectId, fileNames, error) {
  return {
    type: types.DELETE_FILE_FAILURE,
    payload: { projectId, fileNames, error },
  };
}