import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import { isEqual } from "lodash";
import "./index.scss";

const Palette = ({ colors, inputId }) => {
  return (
    <div className="palette-wrapper">
      {colors.map((p) => (
        <div
          key={p}
          className="palette-section"
          style={{
            backgroundColor: p,
            height: `${inputId ? "10px" : "20px"}`,
          }}
        ></div>
      ))}
    </div>
  );
};

const ValueContainer = ({ children, ...props }) => {
  const value = props.getValue();
  return (
    <components.ValueContainer {...props}>
      <div className="control-palette">
        <Palette
          colors={value[0] ? value[0].colors : []}
          inputId={props.selectProps?.inputId === "layer-settings"}
        />
      </div>
      {children}
    </components.ValueContainer>
  );
};

const Option = (props) => {
  return (
    <components.Option {...props}>
      <div
        className="option-palette"
        style={{
          gridTemplateColumns:
            props.selectProps?.inputId === "layer-settings" ? "2fr 5fr" : "",
        }}
      >
        <label className="option-palette__label" title={props.label}>
          {props.label}
        </label>
        <Palette
          colors={props.data.colors}
          inputId={props.selectProps?.inputId === "layer-settings"}
        />
      </div>
    </components.Option>
  );
};

const PaletteDropdown = (props) => {
  const getOption = (value) => {
    return props.options.find((o) => isEqual(o.value, value));
  };

  const [value, setValue] = useState(getOption(props.value));

  useEffect(() => {
    setValue(getOption(props.value));
  }, [props.value]);

  return (
    <div className="form-line-input-wrapper form-line-input-wrapper-palette">
      <label className="form-label user-settings-form-label">
        {props.label}
      </label>
      <Select
        className="color-palette__select"
        classNamePrefix="select"
        components={{ Option, ValueContainer }}
        defaultValue={getOption(props.value)}
        options={props.options}
        styles={selectSetupStyle}
        onChange={(e) => {
          props.onChange({
            target: { name: props.name, value: e.value },
          });
          setValue(getOption(e.value));
        }}
        value={value}
        menuPlacement={props.menuPlacement}
      />
    </div>
  );
};

export default PaletteDropdown;

export const selectSetupStyle = {
  control: (provided, state) => ({
    ...provided,
    background: "#fff",
    border: "1px solid rgba(64, 67, 69, 0.62)",
    minHeight: "30px",
    height: "auto",
    maxHeight: "auto",
    width: "100%",
    fontFamily: "Segoe UI",
    boxShadow: null,
    //fontWeight: "500",
    cursor: "pointer",
    outline: "none",
    "&:hover": {
      border: "1px solid rgba(64, 67, 69, 0.62)",
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: "auto",
    padding: "0 6px",
    fontSize: "12px",
    overflow: "hidden",
    cursor: "pointer",
  }),
  singleValue: (defaultStyles, { isDisabled }) => ({
    ...defaultStyles,
    color: isDisabled ? "#787878" : "",
  }),
  input: (provided) => ({
    ...provided,
    margin: "0px",
    width: 0,
    cursor: "pointer",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    ///height Of select component
    height: "30px",
    cursor: "pointer",
    color: "transparent",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      fontSize: "12px",
      color: isSelected ? "white" : !isDisabled ? "black" : "gray",
      borderColor: "rgba(100, 105, 110, 0.62)",
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? "#06896cd9"
        : isFocused
        ? "#06896c17"
        : null,
      cursor: isDisabled ? "default" : "pointer",
      ":active": {
        backgroundColor: "#06896c99",
        color: "white",
      },
    };
  },
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#495057",
      cursor: "pointer",
    };
  },
  dropdownIndicator: (base, { isDisabled }) => ({
    ...base,
    color: isDisabled ? "rgba(103, 103, 103, 0.65)" : "#495057",
    cursor: "pointer",
  }),
  menu: (provided) => ({
    ...provided,
    border: "1px solid #727272",
    paddingLeft: "4px",
  }),
};

export const ResultsPaletteDropdown = (props) => {
  const getOption = (value) => {
    return props.options.find((o) => isEqual(o.value, value));
  };

  const [value, setValue] = useState(getOption(props.value));

  useEffect(() => {
    setValue(getOption(props.value));
  }, [props.value]);
  return (
    <div className="form-line-input-wrapper form-layer-wrapper-palette">
      <label className=" form-palette-label">{props.label}</label>
      <div style={{ width: "250px" }}>
        <Select
          inputId="layer-settings"
          classNamePrefix="select"
          components={{ Option, ValueContainer }}
          defaultValue={getOption(props.value)}
          options={props.options}
          styles={layerSetupStyle}
          onChange={(e) => {
            props.onChange({
              target: { name: props.name, value: e.value },
            });
            setValue(getOption(e.value));
          }}
          value={value}
          menuPlacement={"top"}
        />
      </div>
    </div>
  );
};

const layerSetupStyle = {
  control: (provided, state) => ({
    ...provided,
    background: "#fff",
    border: "1px solid rgba(64, 67, 69, 0.62)",
    minHeight: "28px",
    height: "24px",
    //maxHeight: "auto",
    width: "100%",
    fontFamily: "Segoe UI",
    boxShadow: null,
    //fontWeight: "500",
    outline: "none",
    borderRadius: "2px",
    "&:hover": {
      border: "1px solid rgba(64, 67, 69, 0.62)",
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: "auto",
    padding: "0 6px",
    fontSize: "12px",
    overflow: "hidden",
    cursor: "default",
  }),
  input: (provided) => ({
    ...provided,
    margin: "0px",
    width: 0,
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    ///height Of select component
    height: "100%",
    cursor: "default",
    color: "transparent",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      fontSize: "11px",
      color: isSelected ? "white" : "black",
      borderColor: "rgba(100, 105, 110, 0.62)",
      //height: "16px",
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? "#06896cd9"
        : isFocused
        ? "#06896c17"
        : null,
      ":active": {
        backgroundColor: "#06896c99",
        color: "white",
      },
    };
  },
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#495057",
    };
  },
  dropdownIndicator: (base) => ({
    ...base,
    color: "black",
    width: "23px",
    paddingRight: "1px",
  }),
  menu: (provided) => ({
    ...provided,
    border: "1px solid #727272",
    paddingLeft: "4px",
  }),
};
