import { definitionsJson } from "../../../JSON/definitions";
import { templatesJson } from "../../../JSON/templates";
import { unitsJson } from "../../../JSON/units";

export function getSelectedObjectId(geoJsonProperties) {
  return geoJsonProperties.id;
}

export function getCurrentObjectType(geoJsonProperties) {
  return definitionsJson.t.sel[geoJsonProperties.t - 1][1];
}

export function getAllObjectTypes() {
  return {
    fieldName: definitionsJson.t.n,
    value: definitionsJson.t.sel.map((el) => el[1]),
  };
}

export function getCurrentObjectSubtype(geoJsonProperties) {
  const objectSubtypeKey = `t${geoJsonProperties.t}`;

  return definitionsJson[objectSubtypeKey].sel[
    geoJsonProperties[objectSubtypeKey] - 1
  ][1];
}

export function getAllCorrespondingSubtypes(objectTypeIndex) {
  return {
    fieldName: definitionsJson[`t${objectTypeIndex}`].n,
    value: definitionsJson[`t${objectTypeIndex}`].sel.map((el) => el[1]),
  };
}

export function getCurrentProperties(
  selectedMapItemGeoProps,
  polygonData,
  isPrimary
) {
  const currentObjectTemplate = templatesJson.find(
    (x) =>
      x.t === selectedMapItemGeoProps.t &&
      x[`t${selectedMapItemGeoProps.t}`] ===
        selectedMapItemGeoProps[`t${selectedMapItemGeoProps.t}`]
  );

  const {
    t,
    t1,
    t2,
    t3,
    t4,
    t5,
    t6,
    ...newPrimaryProp
  } = currentObjectTemplate;
  const propertiesDefs = Object.keys(newPrimaryProp).filter((el) => {
    if (!isPrimary) {
      return definitionsJson[el].p === 1;
    }
    return definitionsJson[el].p === 2;
  });

  const currentProperties = propertiesDefs.map((x) => {
    let property = definitionsJson[x];
    property.key = x;
    const indexOfUnit = definitionsJson[x].u;
    property.unit = unitsJson.l[indexOfUnit][1];

    if (x === "bh") {
      property.defaultValue = selectedMapItemGeoProps.bh;
    } else if (x === "th") {
      property.defaultValue = selectedMapItemGeoProps.th;
    } else if (x === "ea") {
      property.defaultValue = selectedMapItemGeoProps.ea;
    } else if (x === "eb") {
      property.defaultValue = selectedMapItemGeoProps.eb;
    } else {
      property.defaultValue = currentObjectTemplate[x];
    }
    return property;
  });

  let specificObjectPolygonData = null;
  if (!!polygonData) {

    specificObjectPolygonData = polygonData.find(
      (x) => x.id === selectedMapItemGeoProps.id
    );
  }
  if (!!specificObjectPolygonData) {
    const { id, ...newObject } = specificObjectPolygonData;

    for (let index = 0; index < currentProperties.length; index++) {
      for (const [key, value] of Object.entries(newObject)) {
        if (currentProperties[index].n === key && key !== "name") {

          currentProperties[index].value = Number(value);
        } else if (key === "name") {
          currentProperties[index].value = value;
        }
      }
    }

    let newObjectKeys = Object.keys(newObject);
    for (let index = 0; index < currentProperties.length; index++) {
      if (
        polygonData.length === 0 ||
        !newObjectKeys.includes(currentProperties[index].n)
      ) {
        delete currentProperties[index].value;
      }
    }
  } else {
    for (let index = 0; index < currentProperties.length; index++) {
      delete currentProperties[index].value;
    }
  }
  return currentProperties;
}
