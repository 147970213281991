import { types } from "../types/simulationSettings";

export function setGridPointZDirection(payload) {
  return {
    type: types.SET_GRID_POINTS_Z_DIRECTION,
    payload,
  };
}

export function setScenario(payload) {
  return {
    type: types.SET_SCENARIO,
    payload,
  };
}

export function setSurfaceTemperature(payload) {
  return {
    type: types.SET_SURFACE_TEMPERATURE,
    payload,
  };
}

export function setSurfaceHumidity(payload) {
  return {
    type: types.SET_SURFACE_HUMIDITY,
    payload,
  };
}

export function setSpinupMeanTemperature(payload) {
  return {
    type: types.SET_SPINUP_MEAN_TEMPERATURE,
    payload,
  };
}

export function setSpinupTemperatureAmplitude(payload) {
  return {
    type: types.SET_SPINUP_TEMPERATURE_AMPLITUDE,
    payload,
  };
}
export function setVariablesAveraging(payload) {
  return {
    type: types.SET_VARIABLES_AVERAGING,
    payload,
  };
}
export function setTotalNumbersOfProcessors(payload) {
  return {
    type: types.SET_TOTAL_NUMBERS_OF_PROCESSORS,
    payload,
  };
}
export function setNrOfXProcessors(payload) {
  return {
    type: types.SET_NR_OF_X_PROCESSORS,
    payload,
  };
}
export function setNrOfYProcessors(payload) {
  return {
    type: types.SET_NR_OF_Y_PROCESSORS,
    payload,
  };
}

export function resetSimulationSettings() {
  return {
    type: types.RESET_SIMULATION_SETTINGS,
  };
}

export function loadSimulationSettings(payload) {
  return {
    type: types.LOAD_SIMULATION_SETTINGS,
    payload,
  };
}

export function setSimulationTime(payload) {
  return {
    type: types.SET_SIMULATION_TIME,
    payload,
  };
}
