import React from "react";
import { Box } from "../../../../Components/Map/Form/Box";
import { Edit, Trash } from "react-feather";
import { TextInput } from "../../../../Components/Common/Form/Form";
import {
  startNewAoi,
  deleteAoi,
  updateTempPoiInput,
  addAoiCoordsRow,
  removeAoiCoordsRow,
  handleAoiCoordInputChange,
  onAreaLayerNameChange,
} from "../../../../redux/actions/resultsActions";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class AoiEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleCoordsInputChange = this.handleCoordsInputChange.bind(this);
  }

  handleCoordsInputChange(e, drawnPolyAoiCoordsIndex) {
    const reg = new RegExp(/^[+-]?\d*(?:[.,]\d*)?$/);
    const inputValue = e.target.value.toString();
    const name = e.target.name;
    if (reg.test(inputValue) || inputValue === "") {
      if (
        inputValue === "" ||
        inputValue.slice(-1) === "." ||
        inputValue.slice(-1) === "-"
      ) {
        this.props.updateTempPoiInput({
          drawnPolyAoiCoordsIndex: drawnPolyAoiCoordsIndex,
          tempPoiKey: name,
          tempPoiValue: inputValue,
        });
      } else {
        this.props.handleAoiCoordInputChange({
          drawnPolyAoiCoordsIndex: drawnPolyAoiCoordsIndex,
          e: e.target,
        });
      }
    }
  }

  render() {
    const { t } = this.props;

    return (
      <div>
        <div className="polygon-editor-id-line">
          <span
            className="polygon-action-btn"
            onClick={() => this.props.startNewAoi()}
          >
            <Edit size="10px" strokeWidth="2.5px" />
            <span className="polygon-action-lbl">{t("NewPolygon")}</span>
          </span>
          <span className="polygon-action-btn" style={{ marginLeft: "12px" }}>
            <Trash size="10px" strokeWidth="2.5px" />
            <span
              className="polygon-action-lbl"
              onClick={() =>
                this.props.deleteAoi(this.props.selectedAoiPolygonIndex)
              }
            >
              {t("DeletePolygon")}
            </span>
          </span>
        </div>
        <Box objectType={"point_of_interest"} />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <TextInput
            label={`${t("AreaName")}*`}
            name={"aoiName"}
            value={
              this.props.selectedAoiPolygonIndex !== null
                ? this.props.arrayOfDrawnAoiLayerName[
                    this.props.selectedAoiPolygonIndex
                  ]
                : ""
            }
            onChange={(e) => this.props.onAreaLayerNameChange(e.target.value)}
            placeholder={""}
            style={{ height: "28px" }}
          />
        </div>
        <div className="polygon-hdr-container">
          <div className="polygon-main-coords-container">
            <div className="long-icon"></div>
            <label>{`${t("Easting")}`}</label>
            <div className="lat-icon"></div>
            <label style={{ marginRight: "-20px" }}>{`${t("Northing")}`}</label>
          </div>
        </div>

        <div className="polygon-coords-container">
          {!!this.props.aoiInputCoords &&
            this.props.aoiInputCoords.map((el, i) => (
              <>
                <div className="polygon-coords-input-row" key={i}>
                  <>
                    <label className="coords-counter">{i + 1}</label>
                    <div className="coords-point-icon"></div>
                  </>

                  <>
                    <input
                      className={"polygon-coord-input"}
                      placeholder={""}
                      name="lng"
                      value={
                        this.props.drawnPolyAoiCoordsIndex === i &&
                        this.props.tempPoiKey === "lng" &&
                        (this.props.tempPoiValue ||
                          this.props.tempPoiValue === "")
                          ? this.props.tempPoiValue
                          : el.lng
                      }
                      unit={""}
                      autoComplete={"off"}
                      onChange={(e) => this.handleCoordsInputChange(e, i)}
                      onBlur={() =>
                        this.props.updateTempPoiInput({
                          tempPoiValue: "",
                          tempPoiKey: "",
                          drawnPolyAoiCoordsIndex: null,
                        })
                      }
                    />

                    <span
                      className="deltacoord-right-unit"
                      style={{ marginRight: "-242px" }}
                    >{`[m]`}</span>

                    <input
                      className={"polygon-coord-input"}
                      placeholder={""}
                      name="lat"
                      value={
                        this.props.drawnPolyAoiCoordsIndex === i &&
                        this.props.tempPoiKey === "lat" &&
                        (this.props.tempPoiValue ||
                          this.props.tempPoiValue === "")
                          ? this.props.tempPoiValue
                          : el.lat
                      }
                      unit={""}
                      autoComplete={"off"}
                      onChange={(e) => this.handleCoordsInputChange(e, i)}
                      onBlur={() =>
                        this.props.updateTempPoiInput({
                          tempPoiValue: "",
                          tempPoiKey: "",
                          drawnPolyAoiCoordsIndex: null,
                        })
                      }
                    />

                    <span
                      className="deltacoord-unit"
                      style={{ paddingRight: "14px" }}
                    >{`[m]`}</span>

                    <div className="plus-minus-coords-container">
                      <input
                        className={
                          "changing-polygon-point-btn add-polygon-point-btn"
                        }
                        type="button"
                        value="+"
                        onClick={() => this.props.addAoiCoordsRow(i)}
                      />
                      <input
                        className="changing-polygon-point-btn"
                        type="button"
                        value="-"
                        onClick={() => this.props.removeAoiCoordsRow(i)}
                        disabled={
                          this.props.aoiInputCoords.length < 4 || i === 0
                        }
                      />
                    </div>
                  </>
                </div>
                {i === 0 ? (
                  <div className={"polygon-delta-container"}>
                    <label className="delta-lbl">Δ x</label>
                    <label className="delta-lbl">Δ y</label>
                  </div>
                ) : (
                  ""
                )}
              </>
            ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedAoiPolygonIndex: state.results.selectedAoiPolygonIndex,
    aoiInputCoords: state.results.aoiInputCoords,
    drawnPolyAoiCoordsIndex: state.results.drawnPolyAoiCoordsIndex,
    drawPolyAoiCoordsIndex: state.results.drawPolyAoiCoordsIndex,
    tempPoiKey: state.results.tempPoiKey,
    tempPoiValue: state.results.tempPoiValue,
    arrayOfDrawnAoiLayerName: state.results.arrayOfDrawnAoiLayerName,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    startNewAoi: () => dispatch(startNewAoi()),
    deleteAoi: (payload) => dispatch(deleteAoi(payload)),
    updateTempPoiInput: (payload) => dispatch(updateTempPoiInput(payload)),
    addAoiCoordsRow: (payload) => dispatch(addAoiCoordsRow(payload)),
    removeAoiCoordsRow: (payload) => dispatch(removeAoiCoordsRow(payload)),
    handleAoiCoordInputChange: (payload) =>
      dispatch(handleAoiCoordInputChange(payload)),
    onAreaLayerNameChange: (payload) =>
      dispatch(onAreaLayerNameChange(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AoiEditor));
