import React from "react";
import { useTranslation } from "react-i18next";

const EmptyNotificationList = (props) => {
  const { t } = useTranslation();
  return (
    <div className="notification-list__empty">{t("NotificationsEmpty")}</div>
  );
};

export default EmptyNotificationList;
