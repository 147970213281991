import axios from "axios";
import { BlobServiceClient } from "@azure/storage-blob";
import { apiRequest } from "./axiosApi";

const apiUrl = process.env.REACT_APP_FRONT_SERVICE_API;
const blobSasUrl = process.env.REACT_APP_AZURE_STORAGE_URL;
const containerName = "propolis-blob";

const uploadBlobToAzure = async (blobName, data) => {
  const sasToken = await getSasTokenAsync(blobName);
  const blobServiceClient = new BlobServiceClient(`${blobSasUrl}/?${sasToken}`);
  const containerClient = blobServiceClient.getContainerClient(containerName);
  const blockBlobClient = containerClient.getBlockBlobClient(blobName);
  if (!!data.length) {
    await blockBlobClient.upload(data, data.length);
  } else {
    await blockBlobClient.upload(data, data.size);
  }

  return blockBlobClient.url;
};

export const downloadBlobAsync = async (blobName) => {
  if (!blobName) {
    return null;
  }
  const link = await getDownloadLink(blobName);
  return axios.get(link);
};
export const getDownloadLink = async (blobName) => {
  if (!blobName) {
    return null;
  }
  const sasToken = await getSasTokenAsync(blobName);
  return `${blobSasUrl}/${containerName}/${blobName}?${sasToken}`;
};

async function getSasTokenAsync(blobName) {
  const response = await apiRequest({
    method: "POST",
    url: `${apiUrl}/Storage/sas`,
    data: { blobName },
  });
  return response.data;
}

export { uploadBlobToAzure };

export async function downloadAzureFile(url, fileName) {
  var xhr = new XMLHttpRequest();
  const sasToken = await getSasTokenAsync();
  xhr.open("GET", url + sasToken, true);
  xhr.responseType = "blob";

  // xhr.onprogress = function (event) {
  //   if (event.lengthComputable) {
  //     var percentComplete = (event.loaded / event.total) * 100;
  //     //yourShowProgressFunction(percentComplete);
  //   }
  // };

  xhr.onload = function (event) {
    if (this.status === 200) {
      _saveBlob(this.response, fileName);
    } else {
      //yourErrorFunction()
    }
  };

  xhr.onerror = function (event) {
    //yourErrorFunction()
  };

  xhr.send();
}

function _saveBlob(response, fileName) {
  if (navigator.msSaveBlob) {
    //OK for IE10+
    navigator.msSaveBlob(response, fileName);
  } else {
    _html5Saver(response, fileName);
  }
}

function _html5Saver(blob, fileName) {
  var a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  var url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = fileName;
  a.click();
  document.body.removeChild(a);
}
