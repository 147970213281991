import React from "react";
import chroma from "chroma-js";
import {
  ResultsTextInput,
  ResultsLayerInfo,
} from "../../../../../../Components/Map/Form/Select";
import { ResultsPaletteDropdown } from "../../../../../../Components/Common/PaletteDropdown";
import { defaultWindStreamColors } from "../../../../../../common/VisualizationPresets/colorPalettes";
import {
  changeWindStreamPaths,
  changeWindStreamMaxAge,
  changeWindStreamVScale,
  changeWindStreamFade,
  changeWindStreamColor,
  changeWindStreamStrokeWidth,
  changeWindStreamOpacity,
} from "../../../../../../redux/actions/resultsActions";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import "../../../../../../Components/Common/PaletteDropdown/index.scss";

class WindStreamLayer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <ResultsTextInput
          label={t("LayerName")}
          value={t(this.props.jsonOriginPartSelected.name)}
          visSettings
          disabled
        />
        <hr
          className="raster-settings-ruler"
          style={{ width: "300px", marginBottom: "0px" }}
        />
        <h5
          className="raster-settings-header"
          style={{ marginBottom: "16px", marginTop: "14px" }}
        >{`${t("Statistics")}`}</h5>
        <ResultsLayerInfo
          label={t("Minimum")}
          value={this.props.jsonOriginPartSelected.stat_min}
          style={{ borderRadius: "2px 2px 0px 0px" }}
          visSettings
        />
        <ResultsLayerInfo
          label={t("25%Quantile")}
          value={this.props.jsonOriginPartSelected.stat_q25}
          visSettings
        />
        <ResultsLayerInfo
          label={t("Median")}
          value={this.props.jsonOriginPartSelected.stat_median}
          visSettings
        />
        <ResultsLayerInfo
          label={t("Mean")}
          value={this.props.jsonOriginPartSelected.stat_mean}
          visSettings
        />
        <ResultsLayerInfo
          label={t("75%Quantile")}
          value={this.props.jsonOriginPartSelected.stat_q75}
          visSettings
        />
        <ResultsLayerInfo
          label={t("Maximum")}
          value={this.props.jsonOriginPartSelected.stat_max}
          style={{ borderRadius: "0px 0px 2px 2px" }}
          visSettings
        />
        <hr
          className="raster-settings-ruler"
          style={{ width: "300px", marginBottom: "8px" }}
        />
        <h5
          className="raster-settings-header"
          style={{ marginBottom: "10px", marginTop: "6px" }}
        >
          {t("WindStreamAnimation")}
        </h5>
        <ResultsTextInput
          label={t("Width")}
          value={
            this.props.jsonOriginPartSelected.plot_stroke_width
              ? this.props.jsonOriginPartSelected.plot_stroke_width
              : 1
          }
          //disabled={this.props.inReadOnlyLayerForm}
          onChange={(e) =>
            this.props.changeWindStreamStrokeWidth(e.target.value)
          }
          visSettings
        />
        <ResultsTextInput
          label={t("MaxPaths")}
          value={
            this.props.jsonOriginPartSelected.plot_paths
              ? this.props.jsonOriginPartSelected.plot_paths
              : 5000
          }
          //disabled={this.props.inReadOnlyLayerForm}
          onChange={(e) => this.props.changeWindStreamPaths(e.target.value)}
          visSettings
        />
        <ResultsTextInput
          label={t("MaxAge")}
          value={
            this.props.jsonOriginPartSelected.plot_max_age
              ? this.props.jsonOriginPartSelected.plot_max_age
              : 100
          }
          //disabled={this.props.inReadOnlyLayerForm}
          onChange={(e) => this.props.changeWindStreamMaxAge(e.target.value)}
          visSettings
        />
        <ResultsTextInput
          label={t("Velocity")}
          value={
            this.props.jsonOriginPartSelected.plot_v_scale
              ? this.props.jsonOriginPartSelected.plot_v_scale
              : 1 / 10000
          }
          //disabled={this.props.inReadOnlyLayerForm}
          onChange={(e) => this.props.changeWindStreamVScale(e.target.value)}
          visSettings
        />
        <ResultsTextInput
          label={t("Fade")}
          value={
            this.props.jsonOriginPartSelected.plot_fade
              ? this.props.jsonOriginPartSelected.plot_fade
              : 0.96
          }
          //disabled={this.props.inReadOnlyLayerForm}
          onChange={(e) => this.props.changeWindStreamFade(e.target.value)}
          visSettings
        />
        <ResultsTextInput
          label={t("Opacity")}
          value={this.props.jsonOriginPartSelected.plot_opacity}
          //disabled={this.props.inReadOnlyLayerForm}
          onChange={(e) => this.props.changeWindStreamOpacity(e.target.value)}
          visSettings
        />
        <ResultsPaletteDropdown
          label={t("ColorPalette")}
          name={"ColorPalette"}
          value={this.props.jsonOriginPartSelected.plot_color}
          options={defaultWindStreamColors.map((c) => ({
            value: c,
            label: t(c),
            colors: chroma.brewer[c],
          }))}
          onChange={(e) => this.props.changeWindStreamColor(e.target.value)}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    jsonOriginPartSelected: state.results.jsonOriginPartSelected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeWindStreamPaths: (payload) =>
      dispatch(changeWindStreamPaths(payload)),
    changeWindStreamMaxAge: (payload) =>
      dispatch(changeWindStreamMaxAge(payload)),
    changeWindStreamVScale: (payload) =>
      dispatch(changeWindStreamVScale(payload)),
    changeWindStreamFade: (payload) => dispatch(changeWindStreamFade(payload)),
    changeWindStreamColor: (payload) =>
      dispatch(changeWindStreamColor(payload)),
    changeWindStreamStrokeWidth: (payload) =>
      dispatch(changeWindStreamStrokeWidth(payload)),
    changeWindStreamOpacity: (payload) =>
      dispatch(changeWindStreamOpacity(payload)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(WindStreamLayer)
);
