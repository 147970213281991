import React, { useState } from "react";
import EditableAvatar from "../../../Avatar/EditableAvatar";
import FormSeparator from "../../../Common/Form/FormSeparator/index";
import { TextWithLabel } from "../../../Common/Form/TextWithLabel/index";
import LanguageDropdown from "../../../Common/LanguageDropdown";
import VisualizationPresets from "../../../Common/Form/VisualizationPresets/index";
import { PaletteTextInput } from "../../../Common/Form/Form";
import WMSCapabilities from "wms-capabilities";
import BackgroundMaps from "../../../Common/Form/BackgroundMaps";
import { setWmsLayerNames } from "../../../../redux/actions/mapActions";
import UserBudget from "../../UserBudget";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import "./index.scss";

const UserSettingsForm = (props) => {
  const [settings, setSettings] = useState(props.settings);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onImageChange = (picture) => {
    if (!picture) {
      setSettings({
        ...settings,
        pictureUrl: null,
        pictureDownloadUrl: null,
        picture: null,
      });
      return;
    }
    const url = URL.createObjectURL(picture);
    setSettings({ ...settings, pictureDownloadUrl: url, picture });
  };
  const handlePresetsChange = (e) => {
    const element = e.target;
    setSettings({
      ...settings,
      visualizationPresets: {
        ...settings.visualizationPresets,
        [element.name]: element.value,
      },
    });
  };
  const resetPresets = () => {
    setSettings({
      ...settings,
      visualizationPresets: { ...props.settings.visualizationPresets },
    });
  };
  const handleSimulationPresetsChange = (e) => {
    const element = e.target;
    setSettings({
      ...settings,
      simulationPresets: {
        ...settings.simulationPresets,
        [element.name]: element.value,
      },
    });
  };

  const handleLangChange = (value) => {
    setSettings({ ...settings, preferredLanguage: value });
  };

  const onWsMapChange = (wsMaps) => {
    if (wsMaps?.currentValue?.url) {
      fetch(`${wsMaps.currentValue.url}?service=WMS&request=GetCapabilities`)
        .then(function (response) {
          return response.text();
        })
        .then(function (text) {
          const result = new WMSCapabilities().parse(text);
          if (result.version !== null) {
            let wmsLayersNames = [];
            const multiLayer = result.Capability.Layer.Layer;
            if (multiLayer.length === 1 && multiLayer[0].Layer) {
              multiLayer[0].Layer.map((x) => {
                wmsLayersNames.push({ name: x.Name, title: x.Title });
              });
            } else {
              multiLayer.map((x) => {
                wmsLayersNames.push({ name: x.Name, title: x.Title });
              });
            }
            dispatch(setWmsLayerNames(wmsLayersNames));
            setSettings({ ...settings, wsMaps });
          }
        });
    } else {
      setSettings({ ...settings, wsMaps });
    }
  };

  const submit = () => {
    props.submit(settings);
  };

  return (
    <>
      <div className="settings-form">
        <h1 className="settings-hdr">{t("GeneralSettings")}</h1>
        <div className="user-details-container">
          <div className="user-details-wrapper">
            <TextWithLabel
              label={t("Email")}
              value={props.userDetails?.mail || ""}
            />
            <div style={{ marginTop: "-4px" }}></div>
            {props.userDetails?.organization && (
              <TextWithLabel
                label={t("Organization")}
                value={props.userDetails?.organization || ""}
              />
            )}
            <LanguageDropdown
              name={"preferredLanguage"}
              label={t("PreferredLanguage")}
              value={settings.preferredLanguage || "de-DE"}
              onChange={handleLangChange}
            />
          </div>
          <div className="avatar-container">
            <EditableAvatar
              size="xlarge"
              avatarUrl={settings.pictureDownloadUrl}
              onChange={onImageChange}
            />
          </div>
        </div>
        <div className="ws-sim-container">
          <div className="ws-maps">
            <FormSeparator label={t("Domain")} />
            <BackgroundMaps
              label={t("BackgroundMaps")}
              wsMaps={settings.wsMaps}
              onChange={onWsMapChange}
            />
          </div>
          <div className="simulation-presets">
            <FormSeparator label={t("Simulation")} />
            <div
              className="background-maps-container"
              style={{ marginBottom: "-24px" }}
            >
              <PaletteTextInput
                label={t("MaxCpuCoresCount")}
                name={"maxCpuCoresCount"}
                value={settings.simulationPresets?.maxCpuCoresCount || 100}
                max={120}
                readOnly
                onChange={handleSimulationPresetsChange}
              />
              <UserBudget user={props.budget} />
            </div>
          </div>
        </div>
        <VisualizationPresets
          {...settings.visualizationPresets}
          handleChange={handlePresetsChange}
          reset={resetPresets}
        />
      </div>
      <div className="save-palette-btn-container">
        <button className="wizard-button wizard-button-submit" onClick={submit}>
          {t("Save")}
        </button>
      </div>
    </>
  );
};

export default UserSettingsForm;
