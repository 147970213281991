import React, { useState, useEffect } from "react";
import { PhotoshopPicker } from "react-color";
import "./index.scss";

const ColorInput = (props) => {
  const [display, setDisplay] = useState(false);
  const [color, setColor] = useState(props.value || "#ffffff");

  useEffect(() => {
    if (props.value && color !== props.value) {
      setColor(props.value);
    }
  }, [props.value]);

  const handleChange = (e) => {
    return props.onChange({
      target: {
        name: props.name,
        value: e.hex,
      },
    });
  };

  const handleClose = () => {
    setDisplay(false);
  };

  const openColorPicker = () => {
    setDisplay(true);
  };

  const popover = {
    position: "absolute",
    zIndex: "2",
    top: "30px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  };
  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  };

  return (
    <div className="color-input__wrapper form-line-input-wrapper">
      {display ? (
        <div style={popover}>
          <div style={cover} onClick={handleClose} />
          <PhotoshopPicker color={{ hex: color }} onChange={handleChange} />
        </div>
      ) : null}
      <label>{props.label}</label>
      <div
        className="color-input"
        style={{ backgroundColor: color }}
        onClick={openColorPicker}
      />
    </div>
  );
};

export default ColorInput;
