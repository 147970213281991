import { types } from "../types/thermal";

export function setThermalMode(payload) {
  return {
    type: types.SET_THERMAL_MODE,
    payload,
  };
}

export function setDynamicDriver(payload) {
  return {
    type: types.SET_DYNAMIC_DRIVER,
    payload,
  };
}

export function setStartDateSource(payload) {
  return {
    type: types.SET_START_DATE_SOURCE,
    payload,
  };
}

export function setStartDay(payload) {
  return {
    type: types.SET_START_DAY,
    payload,
  };
}

export function setHoursFromUtc(payload) {
  return {
    type: types.SET_HOURS_FROM_UTC,
    payload,
  };
}

export function setDuration(payload) {
  return {
    type: types.SET_DURATION,
    payload,
  };
}

export function setAirTemperatureSurface(payload) {
  return {
    type: types.SET_AIR_TEMPERATURE_SURFACE,
    payload,
  };
}

export function setRelHumidity(payload) {
  return {
    type: types.SET_REL_HUMIDITY,
    payload,
  };
}

export function setWindSpeed(payload) {
  return {
    type: types.SET_WIND_SPEED,
    payload,
  };
}

export function setWindDirection(payload) {
  return {
    type: types.SET_WIND_DIRECTION,
    payload,
  };
}

export function setMeanTemperature(payload) {
  return {
    type: types.SET_MEAN_TEMPERATURE,
    payload,
  };
}

export function setAmplitude(payload) {
  return {
    type: types.SET_AMPLITUDE,
    payload,
  };
}
export function setSoilMoisture(payload) {
  return {
    type: types.SET_SOIL_MOISTURE,
    payload,
  };
}
export function setSoilTemperature(payload) {
  return {
    type: types.SET_SOIL_TEMPERATURE,
    payload,
  };
}
export function setOutputPresets(payload) {
  return {
    type: types.SET_OUTPUT_PRESETS,
    payload,
  };
}
export function setOutputParameters(payload) {
  return {
    type: types.SET_OUTPUT_PARAMETERS,
    payload,
  };
}
export function setOutputInterval(payload) {
  return {
    type: types.SET_OUTPUT_INTERVAL,
    payload,
  };
}

export function setOutputType(payload) {
  return {
    type: types.SET_OUTPUT_TYPE,
    payload,
  };
}

export function setNightTime(payload) {
  return {
    type: types.SET_NIGHT_TIME,
    payload,
  };
}

export function setDaytime(payload) {
  return {
    type: types.SET_DAYTIME,
    payload,
  };
}

export function setTestRun(payload) {
  return {
    type: types.SET_TEST_RUN,
    payload,
  };
}

export function setThermalVariablesAveraging(payload) {
  return {
    type: types.SET_THERMAL_VARIABLES_AVERAGING,
    payload,
  };
}

export function loadThermalSettings(payload) {
  return {
    type: types.LOAD_THERMAL_SETTINGS,
    payload,
  };
}

export function resetThermalSettings() {
  return {
    type: types.RESET_THERMAL_SETTINGS,
  };
}

export function setUserDefinedSoilMoisture(payload) {
  return {
    type: types.SET_USER_DEFINED_SOIL_MOISTURE,
    payload,
  };
}

export function setUserDefinedSoilTemperature(payload) {
  return {
    type: types.SET_USER_DEFINED_SOIL_TEMPERATURE,
    payload,
  };
}
