import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from "redux-saga";
import RootReducer from "./reducers/RootReducer";
import RootSaga from "./sagas/RootSagas";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();

const store = createStore(RootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(RootSaga);

export default store;