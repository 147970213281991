import React from "react";
import "./index.scss";

function TextWithLabel(props) {
  return (
    <div className="form-text-wrapper">
      <label className="text-input-label">{props.label}</label>
      <span
        className={`form-text`}
      >{props.value}</span>
    </div>
  );
}

export { TextWithLabel };
