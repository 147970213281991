export const applicationFields = [
  {
    id: 0,
    key: "StaticDriverCreation",
    disabled: false,
  },
  {
    id: 1,
    key: "ThermalComfort",
    disabled: false,
  },
  {
    id: 2,
    key: "WindComfort",
    disabled: false,
  },
  {
    id: 3,
    key: "Chemistry",
    disabled: false,
  },
  {
    id: 4,
    key: "Expert",
    disabled: false,
  },
];

export const applicationFieldFunctionalities = {
  staticDriverCase: [0, 1, 2, 3, 4],
  setup: [0, 1, 2, 3, 4],
  simulation: [0, 1, 2, 3, 4],
  results: [],
};
