import React from "react";
import Select from "react-select";
import { AlertCircle, CheckCircle, XCircle } from "react-feather";
import { FormSettingsSeparator } from "../../Components/Common/Form/FormSeparator";
import { Loader } from "../../Components/Loader";
import { hoursFromUtcOptions } from "../../Components/Map/consts/consts";
import {
  daytimeAssesment,
  nightTimeAssesment,
} from "../../Components/Map/consts/consts";
import { getCaseSetup, postCaseSetup } from "../../api/simulationApi";
import { selectCaseSimulationNotifications } from "../../redux/selectors/simulationSelector";
import { selectSetupStyle } from "../../Components/Common/PaletteDropdown/index";
import {
  setThermalMode,
  setDynamicDriver,
  setStartDateSource,
  setStartDay,
  setDuration,
  setAirTemperatureSurface,
  setRelHumidity,
  setWindSpeed,
  setWindDirection,
  setMeanTemperature,
  setAmplitude,
  setSoilMoisture,
  setSoilTemperature,
  setOutputPresets,
  setOutputInterval,
  setOutputType,
  setNightTime,
  setDaytime,
  setThermalVariablesAveraging,
  loadThermalSettings,
  resetThermalSettings,
  setHoursFromUtc,
  setUserDefinedSoilMoisture,
  setUserDefinedSoilTemperature,
} from "../../redux/actions/thermalActions";
import { calculateRasterCenterWgFromDb } from "../Domain/raster-area-helpers/index";
import { cloneDeep } from "lodash";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class ThermalComfort extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      saveCompleted: false,
      savedStaticDriver: false,
    };

    this.saveCaseSetup = this.saveCaseSetup.bind(this);
  }

  async componentDidMount() {
    if (!this.props.currentCaseId) {
      return;
    }
    const thermalCaseSettings = await getCaseSetup(this.props.currentCaseId);
    if (typeof thermalCaseSettings === "string") {
      this.props.resetThermalSettings();
    } else {
      this.props.loadThermalSettings(thermalCaseSettings);
    }

    if (this.props.staticDriverNotifications.length > 0) {
      for (
        let index = this.props.staticDriverNotifications.length - 1;
        index >= 0;
        index--
      ) {
        if (
          this.props.staticDriverNotifications[index].type === "staticDriver" &&
          this.props.staticDriverNotifications[index].body.caseId ===
            this.props.currentCase.id &&
          this.props.staticDriverNotifications[index].body.status === 2
        ) {
          this.setState({ savedStaticDriver: true });
        }
      }
    }
  }

  async componentDidUpdate(prevProps) {
    if (
      this.props.currentCaseId &&
      prevProps.currentCaseId !== this.props.currentCaseId
    ) {
      const thermalCaseSettings = await getCaseSetup(this.props.currentCaseId);
      if (typeof thermalCaseSettings === "string") {
        this.props.resetThermalSettings();
      } else {
        this.props.loadThermalSettings(thermalCaseSettings);
      }
    }

    if (
      this.props.currentCase?.staticDriverJob?.status === "Completed" &&
      !this.state.savedStaticDriver
    ) {
      this.setState({ savedStaticDriver: true });
      return;
    } else if (
      this.props.staticDriverNotifications !==
      prevProps.staticDriverNotifications
    ) {
      if (!this.props.currentCase) {
        return;
      }

      for (
        let index = this.props.staticDriverNotifications.length - 1;
        index >= 0;
        index--
      ) {
        if (
          this.props.staticDriverNotifications[index].type === "staticDriver" &&
          this.props.staticDriverNotifications[index].body.caseId ===
            this.props.currentCase.id &&
          this.props.staticDriverNotifications[index].body.status === 2
        ) {
          this.setState({ savedStaticDriver: true });
          return;
        }
      }
    }
  }

  async saveCaseSetup() {
    const mandatoryVariablesAveraging = cloneDeep(
      this.props.thermal.mandatoryVariablesAveraging
    );
    const thermalVariablesAveraging = cloneDeep(
      this.props.thermal.thermalVariablesAveraging
    );

    const data_output_masks = mandatoryVariablesAveraging.concat(
      thermalVariablesAveraging
    );

    const dataOutput = data_output_masks.filter((x) => {
      return (
        x.label !== "NearSurfaceTemperature" &&
        x.label !== "NearSurfaceWindfield"
      );
    });

    const airTemperatureSurfaceStr = (
      Number(cloneDeep(this.props.thermal.airTemperatureSurface)) + 273.15
    ).toFixed(2);
    const airTemperatureSurface = parseFloat(airTemperatureSurfaceStr);

    const meanTemperatureStr = (
      Number(cloneDeep(this.props.thermal.meanTemperature)) + 273.15
    ).toFixed(2);
    const meanTemperature = parseFloat(meanTemperatureStr);
    const startDay = cloneDeep(this.props.thermal.startDay);
    const hoursFromUtc = cloneDeep(this.props.thermal.hoursFromUtc.value);
    let hoursFromUtcAdjusted = hoursFromUtc;

    if (hoursFromUtc.length === 2) {
      hoursFromUtcAdjusted =
        hoursFromUtcAdjusted.slice(0, 1) + "0" + hoursFromUtcAdjusted.slice(1);
    } else if (hoursFromUtc.length === 1) {
      hoursFromUtcAdjusted =
        hoursFromUtcAdjusted.slice(0, 1) + "0" + hoursFromUtcAdjusted.slice(1);
    }

    const originDataTimeAdjusted = `${
      startDay + " 00:00:00 " + hoursFromUtcAdjusted
    }`;

    let longitude = null;
    let latitude = null;

    if (!this.props.rasterArea.rasterCenterLatWg) {
      const rasterCenterWg = calculateRasterCenterWgFromDb(
        this.props.currentCase.rasterParameters.y_min,
        this.props.currentCase.rasterParameters.y_max,
        this.props.currentCase.rasterParameters.x_min,
        this.props.currentCase.rasterParameters.x_max,
        this.props.epsg
      );
      latitude = rasterCenterWg.lat.toFixed(6);
      longitude = rasterCenterWg.lng.toFixed(6);
    } else {
      latitude = Number(this.props.rasterArea.rasterCenterLatWg).toFixed(6);
      longitude = Number(this.props.rasterArea.rasterCenterLngWg).toFixed(6);
    }

    let path_user_dynamic_driver = null;

    if (
      this.props.thermal.thermalMode.label !== "CyclicBoundaryConditions" &&
      this.props.thermal.dynamicDriver.label !== null
    ) {
      path_user_dynamic_driver = {
        name: this.props.thermal.dynamicDriver.label,
        path: this.props.thermal.dynamicDriver.value,
      };
    } else if (
      this.props.thermal.thermalMode.value === "DynamicDriver" &&
      this.props.thermal.dynamicDriver.label === null
    ) {
      const externalFiles = this.props.externalFiles
        ?.filter((x) => x.type === "")
        .map((x, i) => {
          return {
            name: x.name,
            path: x.path,
          };
        });
      console.log(externalFiles);
      path_user_dynamic_driver = externalFiles[0];
    }
    this.setState({ isSaving: true });
    try {
      if (this.props.thermal.outputPresets.value === "Minimal") {
        const minimalSetup = {
          caseId: this.props.currentCaseId,
          settings: {
            initialization_parameters: {
              origin_date_time: originDataTimeAdjusted,
              nx: this.props.rasterArea.rasterGridXNumber - 1,
              ny: this.props.rasterArea.rasterGridYNumber - 1,
              resolution: this.props.rasterArea.rasterGridWidth,
              longitude: longitude,
              latitude: latitude,
              pt_surface: airTemperatureSurface,
              q_surface: this.props.thermal.relHumidity,
              spinup_pt_mean: meanTemperature,
              spinup_pt_amplitude: this.props.thermal.amplitude,
            },
            runtime_parameters: {
              data_output: dataOutput,
              data_output_masks: this.props.thermal.mandatoryVariablesAveraging,
              end_time: Number(this.props.thermal.duration) * 3600,
            },
            thermal_comfort_parameters: {
              output_interval: 3600,
              wind_speed: this.props.thermal.windSpeed,
              wind_direction: this.props.thermal.windDirection.value,
              soil_moisture: this.props.thermal.soilMoisture.value,
              soil_temperature: this.props.thermal.soilTemperature.value,
              hours_from_utc: hoursFromUtc,
              start_day: startDay,
              assessment_daytime: "14:00",
              assessment_time_nighttime: "04:00",
              output_type_options: ["AveragedOutput"],
              user_soil_moisture:
                this.props.thermal.soilMoisture.value ===
                "UserDefinedConstantMoisture"
                  ? this.props.thermal.userDefinedSoilMoisture
                  : null,
              user_soil_temperature:
                this.props.thermal.soilTemperature.value ===
                "UserDefinedTemperature"
                  ? this.props.thermal.userDefinedSoilTemperature
                  : null,
              mode: this.props.thermal.thermalMode.value,
              path_user_dynamic_driver: path_user_dynamic_driver,
              start_date: "UserDefined",
              use_start_date_from_dynamic:
                this.props.thermal.thermalMode.label ===
                "CyclicBoundaryConditions"
                  ? false
                  : true,
            },
          },
        };
        console.log(JSON.stringify(minimalSetup, null, 2));

        await postCaseSetup(minimalSetup);
      } else {
        const userDefinedSetup = {
          caseId: this.props.currentCaseId,
          settings: {
            initialization_parameters: {
              origin_date_time: originDataTimeAdjusted,
              pt_surface: airTemperatureSurface,
              q_surface: this.props.thermal.relHumidity,
              spinup_pt_mean: meanTemperature,
              spinup_pt_amplitude: this.props.thermal.amplitude,
              nx: this.props.rasterArea.rasterGridXNumber - 1,
              ny: this.props.rasterArea.rasterGridYNumber - 1,
              resolution: this.props.rasterArea.rasterGridWidth,
              longitude: longitude,
              latitude: latitude,
            },
            runtime_parameters: {
              data_output: dataOutput,
              data_output_masks: data_output_masks,
              end_time: Number(this.props.thermal.duration) * 3600,
              output_type_options: this.props.thermal.outputTypeSelected,
            },
            thermal_comfort_parameters: {
              output_interval:
                Number(this.props.thermal.outputInterval.value) * 60,
              wind_speed: this.props.thermal.windSpeed,
              wind_direction: this.props.thermal.windDirection.value,
              soil_moisture: this.props.thermal.soilMoisture.value,
              soil_temperature: this.props.thermal.soilTemperature.value,
              hours_from_utc: hoursFromUtc,
              start_day: startDay,
              assessment_daytime: this.props.thermal.daytime.value,
              assessment_time_nighttime: this.props.thermal.nightTime.value,
              user_soil_moisture:
                this.props.thermal.soilMoisture.value ===
                "UserDefinedConstantMoisture"
                  ? this.props.thermal.userDefinedSoilMoisture
                  : null,
              user_soil_temperature:
                this.props.thermal.soilTemperature === "UserDefinedTemperature"
                  ? this.props.thermal.userDefinedSoilTemperature.value
                  : null,
              mode: this.props.thermal.thermalMode.value,
              path_user_dynamic_driver: path_user_dynamic_driver,
              start_date: "UserDefined",
              use_start_date_from_dynamic:
                this.props.thermal.thermalMode.label ===
                "CyclicBoundaryConditions"
                  ? false
                  : true,
            },
          },
        };
        await postCaseSetup(userDefinedSetup);
        console.log(JSON.stringify(userDefinedSetup, null, 2));
      }

      this.setState({ saveCompleted: true });
      setTimeout(() => this.setState({ saveCompleted: false }), 5000);
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ isSaving: false });
    }
  }

  render() {
    const { t } = this.props;

    return (
      <div className="page setup-page" style={{ minWidth: 900 }}>
        <div className="settings-form">
          <div className="wind-wrapper">
            <h2 style={{ fontSize: 28, marginBottom: 12 }}>
              {t("ThermalComfort")}
            </h2>
            <FormSettingsSeparator label={t("Mode")} />
            <label
              className="form-label user-settings-form-label"
              style={{ marginTop: "12px" }}
            >
              {t("SelectMode")}
            </label>
            <Select
              isSearchable={false}
              styles={selectSetupStyle}
              options={[
                {
                  label: t("CyclicBoundaryConditions"),
                  value: "CyclicBoundaryConditions",
                },
                {
                  label: t("DynamicDriver"),
                  value: "DynamicDriver",
                  isDisabled: !this.props.externalFiles?.some(
                    (x) => x.type === ""
                  ),
                },
              ]}
              value={{
                label: t(this.props.thermal.thermalMode.label),
                value: this.props.thermal.thermalMode.value,
              }}
              onChange={(e) => this.props.setThermalMode(e)}
              isDisabled={
                this.props.thermal.outputTypeSelected?.length === 0 ||
                this.props.simulationJobs?.length ||
                this.props.simulationNotifications
                  ? true
                  : false ||
                    (this.props.currentCase?.staticDriverJob?.status !==
                      "Completed" &&
                      !this.state.savedStaticDriver)
              }
            />

            {this.props.thermal.thermalMode.value === "DynamicDriver" && (
              <>
                <FormSettingsSeparator label={t("DynamicDriver")} />
                <label
                  className="form-label user-settings-form-label"
                  style={{ marginTop: "12px" }}
                >
                  {t("SelectUploadedDynamicDriver")}
                </label>
                <Select
                  isSearchable={false}
                  styles={selectSetupStyle}
                  options={this.props.externalFiles
                    ?.filter((x) => x.type === "")
                    .map((x, i) => {
                      return {
                        label: x.name,
                        value: x.path,
                        index: i,
                      };
                    })}
                  value={{
                    label:
                      this.props.thermal.dynamicDriver.label !== null
                        ? this.props.thermal.dynamicDriver.label
                        : this.props.externalFiles?.filter(
                            (x) => x.type === ""
                          )[0]?.name,
                    value:
                      this.props.thermal.dynamicDriver.label !== null
                        ? this.props.thermal.dynamicDriver.value
                        : this.props.externalFiles?.filter(
                            (x) => x.type === ""
                          )[0]?.value,
                  }}
                  isDisabled={
                    !this.props.externalFiles?.some((x) => x.type === "") ||
                    this.props.thermal.outputTypeSelected?.length === 0 ||
                    this.props.simulationJobs?.length ||
                    this.props.simulationNotifications
                      ? true
                      : false
                  }
                  onChange={(e) => this.props.setDynamicDriver(e)}
                />
              </>
            )}

            <FormSettingsSeparator label={t("Time")} />

            {this.props.thermal.thermalMode.value === "DynamicDriver" && (
              <>
                <label
                  className="form-label user-settings-form-label"
                  style={{ marginTop: "12px" }}
                >
                  {t("DefineStartDate")}
                </label>
                <Select
                  isSearchable={false}
                  styles={selectSetupStyle}
                  options={[
                    {
                      label: t("UserDefined"),
                      value: "UserDefined",
                    },
                  ]}
                  value={{
                    label: t("UserDefined"),
                    value: "UserDefined",
                  }}
                  onChange={(e) => this.props.setStartDateSource(e)}
                />
                <div style={{ marginBottom: 12 }} />
              </>
            )}

            <label className="form-label user-settings-form-label">
              {t("StartDay")}
            </label>
            <input
              className="setup-input"
              type="date"
              id="startDay"
              name="startDay"
              value={this.props.thermal.startDay}
              onChange={(e) => this.props.setStartDay(e.target.value)}
              min="2022-05-01"
              max="2022-09-30"
              required
            />
            <label className="form-label user-settings-form-label">
              {t("Duration")} [h]
            </label>
            <input
              className="setup-input"
              type="number"
              name="num"
              min="30"
              max="48"
              step="1"
              value={this.props.thermal.duration}
              onChange={(e) => this.props.setDuration(e.target.value)}
            />
            <label className="form-label user-settings-form-label">
              {t("HoursFromUtc")}
            </label>
            <Select
              isSearchable={false}
              styles={selectSetupStyle}
              options={hoursFromUtcOptions}
              onChange={(e) => this.props.setHoursFromUtc(e)}
              value={{
                label: this.props.thermal.hoursFromUtc.value,
                value: this.props.thermal.hoursFromUtc.value,
              }}
              maxMenuHeight="500px"
            />
            {this.props.thermal.thermalMode.value !== "DynamicDriver" && (
              <>
                <FormSettingsSeparator label={t("BoundaryConditions")} />
                <label className="form-label user-settings-form-label">
                  {t("AirTemperatureSurface")} [°C]
                </label>
                <input
                  className="setup-input"
                  name="num"
                  type="number"
                  min="0"
                  max="50"
                  step="0.1"
                  value={this.props.thermal.airTemperatureSurface}
                  onChange={(e) =>
                    this.props.setAirTemperatureSurface(e.target.value)
                  }
                />
                <label className="form-label user-settings-form-label">
                  {t("RelativeHumiditySurface")} [%]
                </label>
                <input
                  className="setup-input"
                  name="num"
                  type="number"
                  min="1"
                  max="99"
                  step="0.1"
                  value={this.props.thermal.relHumidity}
                  onChange={(e) => this.props.setRelHumidity(e.target.value)}
                />
                <label className="form-label user-settings-form-label">
                  {t("WindSpeed")} [m/s]
                </label>
                <input
                  className="setup-input"
                  name="num"
                  type="number"
                  min="0.1"
                  max="5"
                  step="0.1"
                  value={this.props.thermal.windSpeed}
                  onChange={(e) => this.props.setWindSpeed(e.target.value)}
                />
                <label className="form-label user-settings-form-label">
                  {t("WindDirection")}
                </label>
                <Select
                  isSearchable={false}
                  styles={selectSetupStyle}
                  options={[
                    { value: "North", label: t("North") },
                    { value: "Northeast", label: t("Northeast") },
                    { value: "East", label: t("East") },
                    { value: "Southeast", label: t("Southeast") },
                    { value: "South", label: t("South") },
                    { value: "Southwest", label: t("Southwest") },
                    { value: "West", label: t("West") },
                    { value: "Northwest", label: t("Northwest") },
                  ]}
                  onChange={(e) => this.props.setWindDirection(e)}
                  value={{
                    label: t(this.props.thermal.windDirection.label),
                    value: this.props.thermal.windDirection.value,
                  }}
                  maxMenuHeight="500px"
                />
              </>
            )}

            <FormSettingsSeparator label={t("Spinup")} />
            <label className="form-label user-settings-form-label">
              {t("MeanTemperature")} [°C]
            </label>
            <input
              className="setup-input"
              name="num"
              type="number"
              min="1"
              max="50"
              step="0.1"
              value={this.props.thermal.meanTemperature}
              onChange={(e) => this.props.setMeanTemperature(e.target.value)}
            />
            <label className="form-label user-settings-form-label">
              {t("Amplitude")} [K]
            </label>
            <input
              className="setup-input"
              name="num"
              type="number"
              min="1"
              max="50"
              step="0.1"
              value={this.props.thermal.amplitude}
              onChange={(e) => this.props.setAmplitude(e.target.value)}
            />
            <FormSettingsSeparator label={t("LandSurfaceModel")} />
            <label className="form-label user-settings-form-label">
              {t("SoilMoisture")}
            </label>
            <Select
              isSearchable={false}
              styles={selectSetupStyle}
              value={{
                label: t(this.props.thermal.soilMoisture.label),
                value: this.props.thermal.soilMoisture.value,
              }}
              options={
                this.props.thermal.thermalMode.value ===
                "CyclicBoundaryConditions"
                  ? [
                      { value: "DrySoil", label: t("DrySoil") },
                      { value: "MoistSoil", label: t("MoistSoil") },
                      { value: "PalmDefault", label: t("PalmDefault") },
                      { value: "Gehrkeetal", label: t("Gehrkeetal") },
                      {
                        value: "UserDefinedConstantMoisture",
                        label: t("UserDefinedConstantMoisture"),
                      },
                    ]
                  : [
                      {
                        value: "FromDynamicDriver",
                        label: t("FromDynamicDriver"),
                      },
                      { value: "DrySoil", label: t("DrySoil") },
                      { value: "MoistSoil", label: t("MoistSoil") },
                      { value: "PalmDefault", label: t("PalmDefault") },
                      { value: "Gehrkeetal", label: t("Gehrkeetal") },
                      {
                        value: "UserDefinedConstantMoisture",
                        label: t("UserDefinedConstantMoisture"),
                      },
                    ]
              }
              onChange={(e) => this.props.setSoilMoisture(e)}
              maxMenuHeight="500px"
            />
            <div style={{ marginBottom: 12 }} />
            {this.props.thermal.soilMoisture.value ===
              "UserDefinedConstantMoisture" && (
              <>
                <label className="form-label user-settings-form-label">
                  {t("UserDefinedSoilMoisture")} [m³/m³]
                </label>
                <input
                  className="setup-input"
                  name="num"
                  type="number"
                  min="0"
                  max="1"
                  step="0.01"
                  value={this.props.thermal.userDefinedSoilMoisture}
                  onChange={(e) =>
                    this.props.setUserDefinedSoilMoisture(e.target.value)
                  }
                />
              </>
            )}
            <label className="form-label user-settings-form-label">
              {t("SoilTemperature")}
            </label>
            <Select
              isSearchable={false}
              styles={selectSetupStyle}
              options={
                this.props.thermal.thermalMode.value ===
                "CyclicBoundaryConditions"
                  ? [
                      {
                        value: "DerivedFromBoundaryConditions",
                        label: t("DerivedFromBoundaryConditions"),
                      },
                      { value: "PalmDefault", label: t("PalmDefault") },
                      { value: "Gehrkeetal", label: t("Gehrkeetal") },
                      {
                        value: "UserDefinedTemperature",
                        label: t("UserDefinedTemperature"),
                      },
                    ]
                  : [
                      {
                        value: "FromDynamicDriver",
                        label: t("FromDynamicDriver"),
                      },
                      {
                        value: "DerivedFromBoundaryConditions",
                        label: t("DerivedFromBoundaryConditions"),
                      },
                      { value: "PalmDefault", label: t("PalmDefault") },
                      { value: "Gehrkeetal", label: t("Gehrkeetal") },
                      {
                        value: "UserDefinedTemperature",
                        label: t("UserDefinedTemperature"),
                      },
                    ]
              }
              onChange={(e) => this.props.setSoilTemperature(e)}
              value={{
                label: t(this.props.thermal.soilTemperature.label),
                value: this.props.thermal.soilTemperature.value,
              }}
              maxMenuHeight="500px"
            />
            <div style={{ marginBottom: 12 }} />
            {this.props.thermal.soilTemperature.value ===
              "UserDefinedTemperature" && (
              <>
                <label className="form-label user-settings-form-label">
                  {t("UserDefinedSoilTemperature")} [K]
                </label>
                <input
                  className="setup-input"
                  name="num"
                  type="number"
                  min="274"
                  max="372"
                  step="0.1"
                  value={this.props.thermal.userDefinedSoilTemperature}
                  onChange={(e) =>
                    this.props.setUserDefinedSoilTemperature(e.target.value)
                  }
                />
              </>
            )}

            <FormSettingsSeparator label={t("Results")} />
            <label className="form-label user-settings-form-label">
              {t("OutputPreset")}
            </label>
            <Select
              isSearchable={false}
              styles={selectSetupStyle}
              options={[
                {
                  value: "Minimal",
                  label: t("Minimal"),
                },
                { value: "UserDefined", label: t("UserDefined") },
              ]}
              onChange={(e) => this.props.setOutputPresets(e)}
              value={{
                label: t(this.props.thermal.outputPresets.label),
                value: this.props.thermal.outputPresets.value,
              }}
              maxMenuHeight="500px"
            />
            <div style={{ marginBottom: 12 }} />
            {this.props.thermal.outputPresets.value === "UserDefined" && (
              <>
                <label className="form-label user-settings-form-label">
                  {t("OutputParameters")}
                </label>
                <Select
                  isMulti
                  menuPlacement="top"
                  isClearable
                  closeMenuOnSelect={false}
                  styles={selectSetupStyle}
                  placeholder={`${t("Select")}...`}
                  options={[
                    {
                      label: `${t("Mandatory")}`,
                      options: [
                        {
                          value: [
                            "theta_xy",
                            "ta_xy",
                            "theta_xy_av",
                            "ta_xy_av",
                            "theta_2m*_xy",
                            "ta_2m*_xy",
                          ],
                          label: t("NearSurfaceTemperature"),
                          isDisabled: true,
                        },
                        {
                          value: [
                            "u_xy",
                            "v_xy",
                            "u_xy",
                            "wspeed_xy",
                            "wdir_xy",
                            "u_xy_av",
                            "v_xy_av",
                            "u_xy_av",
                            "wspeed_xy_av",
                            "wdir_xy_av",
                          ],
                          label: t("NearSurfaceWindfield"),
                          isDisabled: true,
                        },
                        {
                          value: ["tsurf*"],
                          label: t("SurfaceTemperature"),
                          isDisabled: true,
                        },
                        {
                          value: [
                            "vf25m*_xy",
                            "vf50m*_xy",
                            "vf75m*_xy",
                            "vf100m*_xy",
                            "vfd25m*_xy",
                            "vfd50m*_xy",
                            "vfd75m*_xy",
                            "vfd100m*_xy",
                          ],
                          label: t("VolumeFluxes"),
                          isDisabled: true,
                        },
                        {
                          value: [
                            "bio_perct*_xy",
                            "bio_pet*_xy",
                            "bio_utci*_xy",
                            "bio_perct*_xy_av",
                            "bio_pet*_xy_av",
                            "bio_utci*_xy_av",
                          ],
                          label: t("Biometeorology"),
                          isDisabled: true,
                        },
                      ],
                    },
                    {
                      label: `${t("Optional")}`,
                      options:
                        this.props.thermal.optionalVariablesAveraging.map(
                          (o) => ({ ...o, label: t(o.label) })
                        ),
                    },
                  ]}
                  onChange={(e) => this.props.setThermalVariablesAveraging(e)}
                  value={this.props.thermal.thermalVariablesAveraging}
                  classNamePrefix="select"
                />
                <div style={{ marginBottom: 12 }} />
                <label className="form-label user-settings-form-label">
                  {t("OutputInterval")}
                </label>
                <Select
                  isSearchable={false}
                  styles={selectSetupStyle}
                  options={[
                    {
                      value: 60,
                      label: 60,
                    },
                    { value: 30, label: 30 },
                  ]}
                  onChange={(e) => this.props.setOutputInterval(e)}
                  value={{
                    label: `${this.props.thermal.outputInterval.label} ${t(
                      "Minutes"
                    )}`,
                    value: `${this.props.thermal.outputInterval.value}
                    ${t("Minutes")}`,
                  }}
                  maxMenuHeight="500px"
                />
                <div style={{ marginBottom: 8, marginTop: 18 }}>
                  <div
                    className="form-label user-settings-form-label"
                    style={{ marginBottom: "7px" }}
                  >
                    {t("OutputType")}
                  </div>
                  {this.props.thermal.outputTypeSelected?.length === 0 && (
                    <div className="setup-warning">
                      <AlertCircle size={"15px"} />
                      <div className="setup-warning-info">
                        {`${t("ThermalOutputTypeNoSelection")}`}
                      </div>
                    </div>
                  )}
                  {this.props.thermal.outputTypeOptions.map((x) => {
                    return (
                      <div style={{ marginBottom: "5px" }}>
                        <input
                          type="checkbox"
                          id={x}
                          name={x}
                          defaultChecked={x === "AverageOuput" ? true : false}
                          checked={this.props.thermal.outputTypeSelected.some(
                            (y) => {
                              return x === y;
                            }
                          )}
                          onChange={(e) =>
                            this.props.setOutputType({
                              changedOutputType: x,
                              isChecked: e.target.checked,
                            })
                          }
                          style={{ cursor: "pointer" }}
                        />
                        <label
                          style={{
                            font: "12px Segoe UI",
                            fontWeight: "600",
                            fontSize: "12px",
                            fontColor: "#495057",
                            marginLeft: "5px",
                            cursor: "pointer",
                          }}
                          for={x}
                        >
                          {t(x)}
                        </label>
                      </div>
                    );
                  })}
                </div>
                {/* <FormSeparator /> */}
                <div style={{ marginBottom: 6 }}></div>
                <label className="form-label user-settings-form-label">
                  {t("AssessmentTimeNightime")}
                </label>
                <Select
                  isSearchable={false}
                  styles={selectSetupStyle}
                  options={
                    this.props.thermal.outputInterval.value === 60
                      ? nightTimeAssesment.hour
                      : nightTimeAssesment.halfHour
                  }
                  onChange={(e) => this.props.setNightTime(e)}
                  value={this.props.thermal.nightTime}
                  maxMenuHeight="500px"
                />
                <div style={{ marginBottom: 12 }} />
                <label className="form-label user-settings-form-label">
                  {t("AssessmentTimeDaytime")}
                </label>
                <Select
                  isSearchable={false}
                  styles={selectSetupStyle}
                  options={
                    this.props.thermal.outputInterval.value === 60
                      ? daytimeAssesment.hour
                      : daytimeAssesment.halfHour
                  }
                  onChange={(e) => this.props.setDaytime(e)}
                  value={this.props.thermal.daytime}
                  maxMenuHeight="500px"
                />
              </>
            )}
            <div style={{ marginBottom: 36 }}></div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <button
                id="thermal-btn__save"
                className="wizard-button wizard-button-submit"
                onClick={this.saveCaseSetup}
                disabled={
                  this.props.thermal.outputTypeSelected?.length === 0 ||
                  this.props.simulationJobs?.length ||
                  this.props.simulationNotifications
                    ? true
                    : false ||
                      (this.props.currentCase?.staticDriverJob?.status !==
                        "Completed" &&
                        !this.state.savedStaticDriver)
                }
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "34px",
                }}
              >
                {!this.state.isSaving && <span>{t("Save")}</span>}
                {this.state.isSaving && <Loader size="x-small" mode="light" />}
              </button>
              {this.state.saveCompleted && (
                <div className="setup-save-status-wrapper">
                  <CheckCircle
                    width="17"
                    stroke="rgb(6,137,108)"
                    strokeWidth="2"
                  />
                  <div className="save-status-text">
                    {t("SetupHasBeenSaved")}
                  </div>
                </div>
              )}
              {this.props.simulationJobs?.length ||
              !!this.props.simulationNotifications ? (
                <div className="setup-save-status-wrapper">
                  <XCircle size="17" stroke="#a81d1dcf" strokeWidth="2" />
                  <div className="save-status-disabled">
                    {t("SavingSetupDisabledSimStarted")}
                  </div>
                </div>
              ) : this.props.currentCase?.staticDriverJob?.status !==
                  "Completed" && !this.state.savedStaticDriver ? (
                <div className="setup-save-status-wrapper">
                  <XCircle size="17" stroke="#a81d1dcf" strokeWidth="2" />
                  <div className="save-status-disabled">
                    {t("SavingSetupDisabledNoStaticDriver")}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    thermal: state.thermal,
    currentCase: state.projects.currentCase,
    rasterArea: state.rasterArea,
    currentCaseId: state.projects?.currentCaseId,
    simulationJobs: state.projects?.currentCase?.simulationJobs,
    simulationNotifications: selectCaseSimulationNotifications(state),
    staticDriverNotifications: state.notifications.notifications,
    epsg: state.projects.loadedProject?.epsg,
    externalFiles: state.projects?.loadedProject?.files,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setThermalMode: (payload) => dispatch(setThermalMode(payload)),
    setDynamicDriver: (payload) => dispatch(setDynamicDriver(payload)),
    setStartDateSource: (payload) => dispatch(setStartDateSource(payload)),
    setStartDay: (payload) => dispatch(setStartDay(payload)),
    setHoursFromUtc: (payload) => dispatch(setHoursFromUtc(payload)),
    setDuration: (payload) => dispatch(setDuration(payload)),
    setAirTemperatureSurface: (payload) =>
      dispatch(setAirTemperatureSurface(payload)),
    setRelHumidity: (payload) => dispatch(setRelHumidity(payload)),
    setWindSpeed: (payload) => dispatch(setWindSpeed(payload)),
    setWindDirection: (payload) => dispatch(setWindDirection(payload)),
    setMeanTemperature: (payload) => dispatch(setMeanTemperature(payload)),
    setAmplitude: (payload) => dispatch(setAmplitude(payload)),
    setSoilMoisture: (payload) => dispatch(setSoilMoisture(payload)),
    setSoilTemperature: (payload) => dispatch(setSoilTemperature(payload)),
    setOutputPresets: (payload) => dispatch(setOutputPresets(payload)),
    setOutputInterval: (payload) => dispatch(setOutputInterval(payload)),
    setOutputType: (payload) => dispatch(setOutputType(payload)),
    setNightTime: (payload) => dispatch(setNightTime(payload)),
    setDaytime: (payload) => dispatch(setDaytime(payload)),
    setThermalVariablesAveraging: (payload) =>
      dispatch(setThermalVariablesAveraging(payload)),
    loadThermalSettings: (payload) => dispatch(loadThermalSettings(payload)),
    resetThermalSettings: () => dispatch(resetThermalSettings()),
    setUserDefinedSoilMoisture: (payload) =>
      dispatch(setUserDefinedSoilMoisture(payload)),
    setUserDefinedSoilTemperature: (payload) =>
      dispatch(setUserDefinedSoilTemperature(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(ThermalComfort)));
