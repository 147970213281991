import React, { useState, useEffect } from "react";
import Select from "react-select";
import { ExpertTimeAccordion } from "../../ExpertUtils/ExpertComponent";
import {
  ExpertHelpLabel,
  ExpertInput,
  ExpertLandSurfaceSoilInput,
} from "../../ExpertUtils/ExpertComponent";
import ExpertStandardTable from "../../ExpertUtils/ExpertStandardTable";
import ExpertCheckboxTable from "../../ExpertUtils/ExpertCheckboxTable/ExpertCheckboxTable";
import { FormSettingsSeparator } from "../../../../Components/Common/Form/FormSeparator/index";
import { landSurfaceCheckboxTableData } from "../../expertConst";
import { selectSetupStyle } from "../../../../Components/Common/PaletteDropdown/index";
import { helpLink } from "../../expertConst";
import {
  changeExpertInput,
  changeExpertTempInput,
  changeExpertDropdownInput,
} from "../../../../redux/actions/expertActions";
import { useSelector, useDispatch } from "react-redux";

const [page, subpage] = ["modules", "land_surface"];

const rowClassRules = {
  "expert-disabled": "rowIndex===6",
  "expert-disabled-prim": "rowIndex===12",
};

const LandSurface = (props) => {
  const land_surface = useSelector(
    (state) => state.expert.expertJson.modules.land_surface
  );
  const rootFractionSum = useSelector((state) => state.expert.rootFractionSum);

  const expertTempKey = useSelector((state) => state.expert.expertTempKey);
  const expertTempValue = useSelector((state) => state.expert.expertTempValue);

  const dispatch = useDispatch();

  const [checkboxTableData, setCheckboxTableData] = useState(
    landSurfaceCheckboxTableData
  );

  useEffect(() => {
    const rowDataFromRedux = landSurfaceCheckboxTableData;
    rowDataFromRedux.rowData = land_surface.output_quantities;
    setCheckboxTableData(checkboxTableData);
  }, []);

  return (
    <>
      <ExpertHelpLabel label={"Activate Module"} />
      <Select
        isSearchable={false}
        styles={selectSetupStyle}
        options={[
          {
            value: "True",
            label: "True",
          },
          { value: "False", label: "False" },
        ]}
        value={{
          label: !!land_surface.isActive ? "True" : "False",
          value: !!land_surface.isActive ? "True" : "False",
        }}
        onChange={(e) =>
          dispatch(
            changeExpertDropdownInput({
              page: page,
              subpage: subpage,
              key: "",
              subkey: "isActive",
              value: e.value,
            })
          )
        }
      />

      {land_surface.isActive && (
        <>
          <FormSettingsSeparator
            style={{ fontSize: "17px" }}
            label={"Parameters"}
          />

          <ExpertHelpLabel
            label={"Skip Time Do LSM [s]"}
            helpLink={`${helpLink}/land_surface_parameters#skip_time_do_lsm`}
          />
          <ExpertTimeAccordion
            page={page}
            subpage={subpage}
            section="parameters"
            subkey="skip_time_do_lsm"
            min={0}
            max={999999}
            constValue={land_surface.parameters.skip_time_do_lsm}
          />
          <div style={{ marginBottom: 12 }} />

          <ExpertHelpLabel
            label={"Aero Resistance Kray"}
            helpLink={`${helpLink}/land_surface_parameters#aero_resist_kray`}
          />
          <Select
            isSearchable={false}
            styles={selectSetupStyle}
            options={[
              {
                value: "True",
                label: "True",
              },
              { value: "False", label: "False" },
            ]}
            value={{
              label: !!land_surface.parameters.aero_resist_kray
                ? "True"
                : "False",
              value: !!land_surface.parameters.aero_resist_kray
                ? "True"
                : "False",
            }}
            onChange={(e) =>
              dispatch(
                changeExpertDropdownInput({
                  page: page,
                  subpage: subpage,
                  key: "parameters",
                  subkey: "aero_resist_kray",
                  value: e.value,
                })
              )
            }
          />

          <div style={{ marginBottom: 12 }} />

          <ExpertHelpLabel
            label={"Constant Roughness Water Surfaces"}
            helpLink={`${helpLink}/land_surface_parameters#constant_roughness`}
          />
          <Select
            isSearchable={false}
            styles={selectSetupStyle}
            options={[
              {
                value: "True",
                label: "True",
              },
              { value: "False", label: "False" },
            ]}
            value={{
              label: !!land_surface.parameters.constant_roughness
                ? "True"
                : "False",
              value: !!land_surface.parameters.constant_roughness
                ? "True"
                : "False",
            }}
            onChange={(e) =>
              dispatch(
                changeExpertDropdownInput({
                  page: page,
                  subpage: subpage,
                  key: "parameters",
                  subkey: "constant_roughness",
                  value: e.value,
                })
              )
            }
          />
          <div style={{ marginBottom: 12 }} />
          <ExpertHelpLabel
            label={"Deep Soil Temperature [K]"}
            helpLink={`${helpLink}/land_surface_parameters#deep_soil_temperature`}
          />
          <input
            className="setup-input"
            name="num"
            type="number"
            min={0}
            max={9999999.9}
            step={0.1}
            value={
              expertTempKey === "deep_soil_temperature"
                ? expertTempValue
                : land_surface.parameters.deep_soil_temperature
            }
            onChange={(e) =>
              dispatch(
                changeExpertTempInput({
                  subkey: "deep_soil_temperature",
                  value: e.target.value,
                })
              )
            }
            onBlur={(e) =>
              dispatch(
                changeExpertInput({
                  page: page,
                  subpage: subpage,
                  key: "parameters",
                  subkey: "deep_soil_temperature",
                  min: 0,
                  max: 9999999.9,
                  step: 0.1,
                  value: e.target.value,
                })
              )
            }
          />
          <ExpertHelpLabel
            label={"Conserve Water Content"}
            helpLink={`${helpLink}/land_surface_parameters#conserve_water_content`}
          />
          <Select
            isSearchable={false}
            styles={selectSetupStyle}
            options={[
              {
                value: "True",
                label: "True",
              },
              { value: "False", label: "False" },
            ]}
            value={{
              label: !!land_surface.parameters.conserve_water_content
                ? "True"
                : "False",
              value: !!land_surface.parameters.conserve_water_content
                ? "True"
                : "False",
            }}
            onChange={(e) =>
              dispatch(
                changeExpertDropdownInput({
                  page: page,
                  subpage: subpage,
                  key: "parameters",
                  subkey: "conserve_water_content",
                  value: e.value,
                })
              )
            }
          />
          <div style={{ marginBottom: 12 }} />
          <ExpertHelpLabel
            label={"Minimum Soil Resistance [s/m]"}
            helpLink={`${helpLink}/land_surface_parameters#min_soil_resistance`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="parameters"
            subkey="min_soil_resistance"
            min={0}
            max={9999999.9}
            step={0.1}
            constValue={land_surface.parameters.min_soil_resistance}
          />
          <ExpertHelpLabel
            label={"Root Fraction: Use Default Values from Vegetation Type"}
            helpLink={`${helpLink}/land_surface_parameters#root_fraction`}
          />
          <Select
            isSearchable={false}
            styles={selectSetupStyle}
            options={[
              {
                value: "True",
                label: "True",
              },
              { value: "False", label: "False" },
            ]}
            value={{
              label: !!land_surface.parameters.use_default_root_fraction
                ? "True"
                : "False",
              value: !!land_surface.parameters.use_default_root_fraction
                ? "True"
                : "False",
            }}
            onChange={(e) =>
              dispatch(
                changeExpertDropdownInput({
                  page: page,
                  subpage: subpage,
                  key: "parameters",
                  subkey: "use_default_root_fraction",
                  value: e.value,
                })
              )
            }
          />
          <div style={{ marginBottom: 12 }} />

          <ExpertHelpLabel
            label={"Number of Soil Layers [-]"}
            helpLink={`${helpLink}/land_surface_parameters#dz_soil`}
          />
          <ExpertLandSurfaceSoilInput
            page={page}
            subpage={subpage}
            section="parameters"
            subkey="soil_layers_nbr"
            min={1}
            max={20}
            step={1}
            constValue={land_surface.parameters.soil_layers_nbr}
          />
          <div style={{ marginBottom: 12 }} />
          <ExpertHelpLabel
            label={"Soil Parametrization"}
            helpLink={`${helpLink}/land_surface_parameters#dz_soil`}
          />
          <div style={{ borderTop: "1px solid rgba(100, 105, 110, 0.62)" }} />
          <div
            className={"table-animation"}
            style={{ marginTop: "12px", minWidth: "805px" }}
          >
            <ExpertStandardTable />
          </div>
          {rootFractionSum !== 1 && (
            <span
              className="save-status-disabled"
              style={{ width: 804, marginTop: 10, lineHeight: 1.5 }}
            >
              {`Sum of Root-Fraction not equal to 1.0. Current value is ${rootFractionSum}. Distribution will now be inferred based on the setting of vegetation_type and default look-up table. Refer to Documentation for further information.`}
            </span>
          )}
          <FormSettingsSeparator
            style={{ fontSize: "17px" }}
            label={"Output Quantities"}
          />
          <div
            className={"table-animation"}
            style={{ marginTop: "12px", minWidth: "805px" }}
          >
            <ExpertCheckboxTable
              tableData={landSurfaceCheckboxTableData}
              rowData={props.rowData}
              rowClassRules={rowClassRules}
            />
          </div>
        </>
      )}
    </>
  );
};

export default LandSurface;
