import { HubConnectionBuilder } from "@microsoft/signalr";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addNotification } from "../../redux/actions/notificationActions";
import { addSimulationNotification } from "../../redux/actions/notificationActions";
import authService from "../../utils/auth/authService";

const notificationHubUrl = `${process.env.REACT_APP_FRONT_SERVICE_API}/notifications/simulations`;

const simStatusesToTriggerNotification = [
  "batchInitialization",
  "running",
  "finished",
  "cancelled",
  "aborted",
  "error",
];

const SimulationProvider = () => {
  const [connection, setConnection] = useState(null);
  const user = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const start = () => {
    if (connection) {
      if (connection.connectionState === "Connected") {
        return;
      }
      connection
        .start()
        .then(() => {
          connection.on("SimulationNotificationReceived", (message) => {
            const simulationNotification = {
              id: message.requestId,
              type: "simulation",
              body: message,
              ts: message.timestampUtc,
            };
            console.log("sim notification received", simulationNotification);
            dispatch(addSimulationNotification(simulationNotification));
            if (
              simStatusesToTriggerNotification.includes(
                message?.response?.status
              )
            ) {
              dispatch(addNotification(simulationNotification));
            }
          });
          connection.onclose(() => {
            setTimeout(() => start(), 3000);
          });
        })
        .catch(async (e) => {
          console.log(
            "Connection failed: ",
            JSON.stringify(e),
            e.statusCode,
            new Date()
          );
          setTimeout(() => start(), 3000);
        });
    }
  };
  useEffect(() => {
    async function subscribeToHub() {
      const newConnection = new HubConnectionBuilder()
        .withUrl(`${notificationHubUrl}`, {
          accessTokenFactory: () => {
            return authService.acquireToken();
          },
        })
        .build();
      setConnection(newConnection);
    }
    if (!user || !user.identity) {
      return;
    }
    subscribeToHub();
  }, [user]);

  useEffect(() => {
    start();
  }, [connection]);

  return null;
};

export default SimulationProvider;
