import React from "react";
import { GeoJSON } from "react-leaflet";
import { styleDrawnPolygonGeoJson } from "../../../utils/polygons/polygonStyle";
import { connect } from "react-redux";

class DrawnPolygonGeoJson extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (
      this.props.drawnPolygonGeojsonWg.features.length !== 0 &&
      this.props.drawnPolygonGeoJsonRef.current !== null
    ) {
      this.props.drawnPolygonGeoJsonRef.current.leafletElement
        .clearLayers()
        .addData(this.props.drawnPolygonGeojsonWg);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.inRasterSettingsMode &&
      this.props.drawnPolygonGeoJsonRef.current !== null
    ) {
      this.props.drawnPolygonGeoJsonRef.current.leafletElement
        .clearLayers()
        .addData(this.props.drawnPolygonGeojsonWg);
      return;
    }

    if (
      !prevProps.allDrawnObjectsVisibility &&
      this.props.allDrawnObjectsVisibility &&
      this.props.drawnPolygonGeoJsonRef.current !== null
    ) {
      this.props.drawnPolygonGeoJsonRef.current.leafletElement
        .clearLayers()
        .addData(this.props.drawnPolygonGeojsonWg);
      return;
    }
    if (
      prevProps.drawnPolygonGeojsonWg.features.length !==
        this.props.drawnPolygonGeojsonWg.features.length &&
      prevProps.drawnPolygonGeojsonWg.features.length === 0 &&
      this.props.drawnPolygonGeoJsonRef.current !== null
    ) {
      this.props.drawnPolygonGeoJsonRef.current.leafletElement
        .clearLayers()
        .addData(this.props.drawnPolygonGeojsonWg);
      return;
    }
    // if (
    //   this.props.drawnPolygonGeojsonWg !== prevProps.drawnPolygonGeojsonWg ||
    //   (prevProps.inDrawingMode !== this.props.inDrawingMode &&
    //     )
    // ) {
    //   console.log(this.props.drawnPolygonGeoJsonRef.current);

    //   this.props.drawnPolygonGeoJsonRef.current.leafletElement
    //     .clearLayers()
    //     .addData(this.props.drawnPolygonGeojsonWg);
    //   return;
    // }

    if (
      (this.props.drawnPolygonGeojsonWg.features.length !== 0 ||
        this.props.drawnPolygonGeojsonWg !== prevProps.drawnPolygonGeojsonWg) &&
      this.props.drawnPolygonGeoJsonRef.current !== null
    ) {
      this.props.drawnPolygonGeoJsonRef.current.leafletElement
        .clearLayers()
        .addData(this.props.drawnPolygonGeojsonWg);
      return;
    }
  }
  render() {
    return (
      <>
        {this.props.allDrawnObjectsVisibility &&
          this.props.isDrawnGeoJsonVisible && (
            <GeoJSON
              ref={this.props.drawnPolygonGeoJsonRef}
              style={(x) => {
                return styleDrawnPolygonGeoJson(
                  x,
                  this.props.selectedMapItemGeoProps?.id === x?.properties?.id,
                  this.props.inputPalmTypesPalette
                );
              }}
              onEachFeature={this.props.onEachFeature}
              zIndex={1000000}
            />
          )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    drawnPolygonGeojsonWg: state.map.drawnPolygonGeojsonWg,
    inDrawingMode: state.map.inDrawingMode,
    inRasterSettingsMode: state.map.inRasterSettingsMode,
    allDrawnObjectsVisibility: state.legend.allDrawnObjectsVisibility,
    isDrawnGeoJsonVisible: state.rasterArea.isDrawnGeoJsonVisible,
    selectedMapItemGeoProps: state.map.selectedMapItemGeoProps,
    inputPalmTypesPalette:
      state.userSettings?.settings?.visualizationPresets?.inputPalmTypesPalette || {},
  };
};

export default connect(mapStateToProps, null)(DrawnPolygonGeoJson);
