import React from "react";
import { Marker, Pane, Polygon } from "react-leaflet";
import L from "leaflet";
import {
  calculateRasterCenterUtm,
  calculateRasterCenterWg,
  calculateSWWG,
  calculateNEWG,
  calculateRasterAreaPolygonPoints,
  calculateBufferAreaPolygonPoints,
} from "../../../containers/Domain/raster-area-helpers";
import { reprojectWgToUtmPoint } from "../../../common/utmDef";
import * as actions from "../../../redux/actions/rasterAreaActions";
import { connect } from "react-redux";
import { isEmpty } from "lodash";

class RasterArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.setDefaultRasterArea = this.setDefaultRasterArea.bind(this);
    this.dragRasterCenter = this.dragRasterCenter.bind(this);
    this.handleRasterCenterDragend = this.handleRasterCenterDragend.bind(this);
  }

  componentDidMount() {
    this.setDefaultRasterArea();
    if (this.props.rasterArea.domainType === "NestedDomain") {
      this.props.showNestedRasterArea();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.rasterArea.rasterCenterLatUtm !== null &&
      this.props.rasterArea.rasterCenterLatUtm === null
    ) {
      this.setDefaultRasterArea();
    }
  }

  setDefaultRasterArea() {
    let rasterCenterUtm;
    let rasterCenterWg;
    let group = null;

    if (isEmpty(this.props.referenceGeo.current)) {
      return;
    }

    group = this.props.referenceGeo.current.leafletElement;
    let groupBounds = group.getBounds();

    if (isEmpty(groupBounds)) {
      return;
    }

    rasterCenterWg = groupBounds.getCenter();

    if (this.props.rasterArea.rasterCenterLngUtm === null) {
      if (this.props.geoJsonWg.features.length !== 0) {
        //group = this.props.referenceGeo.leafletElement;
      } else if (this.props.drawnPolygonGeojsonWg.features.length !== 0) {
        group = this.props.referenceDrawnGeo.current.leafletElement;
        //group = this.props.referenceDrawnGeo.leafletElement;
      }

      if (Object.keys(group).length !== 0) {
        rasterCenterUtm = calculateRasterCenterUtm(
          rasterCenterWg.lat,
          rasterCenterWg.lng,
          this.props.crsDef
        );
      }
    } else {
      rasterCenterUtm = {
        lat: this.props.rasterArea.rasterCenterLatUtm,
        lng: this.props.rasterArea.rasterCenterLngUtm,
      };

      rasterCenterWg = calculateRasterCenterWg(
        rasterCenterUtm.lng,
        rasterCenterUtm.lat,
        this.props.crsDef
      );
    }

    const SWWG = calculateSWWG(
      rasterCenterUtm.lng,
      rasterCenterUtm.lat,
      this.props.rasterArea.rasterGridWidth,
      this.props.rasterArea.rasterGridXNumber,
      this.props.rasterArea.rasterGridYNumber,
      this.props.crsDef
    );
    const NEWG = calculateNEWG(
      rasterCenterUtm.lng,
      rasterCenterUtm.lat,
      this.props.rasterArea.rasterGridWidth,
      this.props.rasterArea.rasterGridXNumber,
      this.props.rasterArea.rasterGridYNumber,
      this.props.crsDef
    );

    const map = this.props.referenceMap.current.leafletElement;
    map.fitBounds([
      [NEWG.lat, NEWG.lng],
      [SWWG.lat, SWWG.lng],
    ]);
    this.props.setRasterCenterLatUtm({
      rasterCenterLatUtm: rasterCenterUtm.lat,
      crsDef: this.props.crsDef,
    });
    this.props.setRasterCenterLngUtm({
      rasterCenterLngUtm: rasterCenterUtm.lng,
      crsDef: this.props.crsDef,
    });
    this.props.setRasterCenterLatWg(rasterCenterWg.lat);
    this.props.setRasterCenterLngWg(rasterCenterWg.lng);
  }

  dragRasterCenter(e) {
    const centerLatLngWg = e.target._latlng;
    const rasterCenterUtm = calculateRasterCenterUtm(
      centerLatLngWg.lat,
      centerLatLngWg.lng,
      this.props.crsDef
    );

    const rasterAreaPolygonPoints = calculateRasterAreaPolygonPoints(
      rasterCenterUtm.lng,
      rasterCenterUtm.lat,
      this.props.rasterArea.rasterGridWidth,
      this.props.rasterArea.rasterGridXNumber,
      this.props.rasterArea.rasterGridYNumber,
      this.props.crsDef
    );

    let bufferAreaPolygonPoints = null;

    if (this.props.rasterArea.domainType === "SingleDomain") {
      bufferAreaPolygonPoints = calculateBufferAreaPolygonPoints(
        Number(rasterCenterUtm.lng),
        Number(rasterCenterUtm.lat),
        Number(this.props.rasterArea.rasterGridWidth),
        Number(this.props.rasterArea.rasterGridXNumber),
        Number(this.props.rasterArea.rasterGridYNumber),
        Number(this.props.rasterArea.gridPointsNorth),
        Number(this.props.rasterArea.gridPointsSouth),
        Number(this.props.rasterArea.gridPointsWest),
        Number(this.props.rasterArea.gridPointsEast),
        this.props.crsDef
      );
    } else {
      bufferAreaPolygonPoints = calculateBufferAreaPolygonPoints(
        Number(rasterCenterUtm.lng) +
          Number(this.props.rasterArea.parentOffsetX),
        Number(rasterCenterUtm.lat) +
          Number(this.props.rasterArea.parentOffsetY),
        this.props.rasterArea.parentGridSize,
        this.props.rasterArea.parentRasterGridXNumber,
        this.props.rasterArea.parentRasterGridYNumber,
        Number(this.props.rasterArea.gridPointsNorth),
        Number(this.props.rasterArea.gridPointsSouth),
        Number(this.props.rasterArea.gridPointsWest),
        Number(this.props.rasterArea.gridPointsEast),
        this.props.crsDef
      );
    }

    const parentAreaPolygonPoints = calculateRasterAreaPolygonPoints(
      Number(rasterCenterUtm.lng) + Number(this.props.rasterArea.parentOffsetX),
      Number(rasterCenterUtm.lat) + Number(this.props.rasterArea.parentOffsetY),
      this.props.rasterArea.parentGridSize,
      this.props.rasterArea.parentRasterGridXNumber,
      this.props.rasterArea.parentRasterGridYNumber,
      this.props.crsDef
    );

    const childAreaPolygonPoints = calculateRasterAreaPolygonPoints(
      rasterCenterUtm.lng,
      rasterCenterUtm.lat,
      this.props.rasterArea.childGridSize,
      this.props.rasterArea.childRasterGridXNumber,
      this.props.rasterArea.childRasterGridYNumber,
      this.props.crsDef
    );

    this.props.updateRasterAreaPosition({
      bufferAreaPolygonPoints: bufferAreaPolygonPoints,
      rasterCenterLngWg: centerLatLngWg.lng.toFixed(6),
      rasterCenterLatWg: centerLatLngWg.lat.toFixed(6),
      rasterAreaPolygonPoints: rasterAreaPolygonPoints,
      parentAreaPolygonPoints: parentAreaPolygonPoints,
      childAreaPolygonPoints: childAreaPolygonPoints,
    });
  }

  handleRasterCenterDragend(e) {
    const rasterCenterWg = {
      lat: e.target._latlng.lat,
      lng: e.target._latlng.lng,
    };
    const rasterCenterUtm = reprojectWgToUtmPoint(
      rasterCenterWg,
      this.props.crsDef
    );
    this.props.setRasterCenterLatUtm({
      rasterCenterLatUtm: rasterCenterUtm.lat,
      crsDef: this.props.crsDef,
    });
    this.props.setRasterCenterLngUtm({
      rasterCenterLngUtm: rasterCenterUtm.lng,
      crsDef: this.props.crsDef,
    });
  }

  render() {
    return (
      <>
        {this.props.crsDef &&
          this.props.rasterArea.rasterCenterLngWg !== null && (
            <Pane className="raster-area" style={{ zIndex: 100000 }}>
              {this.props.rasterArea.domainType === "SingleDomain" &&
                this.props.rasterArea.isRasterAreaVisible && (
                  <Polygon
                    autoPan
                    positions={this.props.rasterArea.rasterAreaPolygonPoints}
                    color="#ef6565ff"
                    fill={false}
                    lineJoin={"round"}
                    weight={6}
                  />
                )}
              {this.props.rasterArea.domainType === "NestedDomain" && (
                <>
                  {this.props.rasterArea.isParentRasterAreaVisible && (
                    <Polygon
                      autoPan
                      positions={this.props.rasterArea.parentAreaPolygonPoints}
                      color="#ef6565ff"
                      fill={false}
                      lineJoin={"round"}
                      weight={6}
                    />
                  )}
                  {this.props.rasterArea.isChildRasterAreaVisible && (
                    <Polygon
                      autoPan
                      positions={this.props.rasterArea.childAreaPolygonPoints}
                      color="#337dff"
                      fill={false}
                      lineJoin={"round"}
                      weight={6}
                    />
                  )}
                </>
              )}
              {(this.props.rasterArea.isParentRasterAreaVisible ||
                this.props.rasterArea.isChildRasterAreaVisible ||
                this.props.rasterArea.isRasterAreaVisible) && (
                <Marker
                  autoPan
                  position={{
                    lat: this.props.rasterArea.rasterCenterLatWg,
                    lng: this.props.rasterArea.rasterCenterLngWg,
                  }}
                  icon={rasterCenterPoint}
                  fillColor="red"
                  opacity={1}
                  draggable={this.props.rasterArea.isRasterAreaDraggable}
                  ondrag={this.dragRasterCenter}
                  onDragend={this.handleRasterCenterDragend}
                />
              )}
            </Pane>
          )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rasterArea: state.rasterArea,
    crsDef: state.map.crsDef,
    geoJsonWg: state.map.geoJsonWg,
    drawnPolygonGeojsonWg: state.map.drawnPolygonGeojsonWg,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setRasterCenterLatUtm: (payload) =>
      dispatch(actions.setRasterCenterLatUtm(payload)),
    setRasterCenterLngUtm: (payload) =>
      dispatch(actions.setRasterCenterLngUtm(payload)),
    setRasterCenterLatWg: (payload) =>
      dispatch(actions.setRasterCenterLatWg(payload)),
    setRasterCenterLngWg: (payload) =>
      dispatch(actions.setRasterCenterLngWg(payload)),
    updateRasterAreaPosition: (payload) =>
      dispatch(actions.updateRasterAreaPosition(payload)),
    showNestedRasterArea: () => dispatch(actions.showNestedRasterArea()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RasterArea);

export const rasterCenterPoint = new L.Icon({
  iconUrl: require("./../raster-center-point-icon.svg"),
  iconRetinaUrl: require("./../raster-center-point-icon.svg"),
  iconAnchor: [7.5, 7.5],
  popupAnchor: [0, -7.5],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(15, 15),
});
