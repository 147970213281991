import { types } from "../types/simulationSettings";
//import { types as thermalTypes } from "../types/thermal";
import { cloneDeep } from "lodash";

const initialState = {
  steering: null,
  gridPointsZDirection: { label: 100, value: 100 },
  scenario: { label: "TypicalSummerDay", value: "TypicalSummerDay" },
  surfaceTemperature: 21,
  surfaceHumidity: 60,
  spinupMeanTemperature: 26,
  spinupTemperatureAmplitude: 5,
  variablesAveraging: [],
  totalNumbersOfProcessors: 64,
  nrOfXProcessors: 6,
  nrOfYProcessors: 6,
  simulationTime: {
    hours: "24",
    minutes: "0",
  },
  mandatoryVariablesAveraging: [
    {
      value: ["theta_xy", "ta_xy", "theta_xy_av", "ta_xy_av"],
      label: "NearSurfaceTemperature",
      isDisabled: true,
    },
    {
      value: [
        "u_xy",
        "v_xy",
        "u_xy",
        "wspeed_xy",
        "wdir_xy",
        "u_xy_av",
        "v_xy_av",
        "u_xy_av",
        "wspeed_xy_av",
        "wdir_xy_av",
      ],
      label: "NearSurfaceWind",
      isDisabled: true,
    },
    // {
    //   label: "ColdAirDrainageFlow",
    //   isDisabled: true,
    // },
    {
      value: [
        "bio_perct*_xy",
        "bio_pet*_xy",
        "bio_utci*_xy",
        "bio_perct*_xy_av",
        "bio_pet*_xy_av",
        "bio_utci*_xy_av",
      ],
      label: "Biometerology",
      isDisabled: true,
    },
    {
      value: [
        "rad_net*_xy",
        "rad_lw_in*_xy",
        "rad_lw_out*_xy",
        "rad_sw_in*_xy",
        "rad_sw_out*_xy",
      ],
      label: "Radiation",
      isDisabled: true,
    },
  ],
  optionalVariablesAveraging: [
    // { value: ["NearSurfaceHumidity", "ds"], label: "NearSurfaceHumidity" },
    {
      value: ["t_soil_xy", "t_soil_xy_av", "m_soil_xy", "m_soil_xy_av"],
      label: "SurfaceAndSoilTemperature",
    },
    // { value: "SurfaceAndSoilHumidity", label: "SurfaceAndSoilHumidity" },
    {
      value: ["qsws_liq*_xy_av", "qsws_soil*_xy_av", "qsws_veg*_xy_av"],
      label: "HeatFluxes",
    },
    {
      value: [
        "pcm_heatrate",
        "pcm_heatrate_av",
        "pcm_transpirationrate",
        "pcm_transpirationrate_av",
        "pcm_latentrate",
        "pcm_latentrate_av",
      ],
      label: "PlantCanopyIndices",
    },
  ],
};

const simulationSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_GRID_POINTS_Z_DIRECTION:
      return {
        ...state,
        gridPointsZDirection: action.payload,
      };
    case types.SET_SCENARIO:
      return {
        ...state,
        scenario: action.payload,
      };
    case types.SET_SURFACE_TEMPERATURE:
      return {
        ...state,
        surfaceTemperature: action.payload,
      };
    case types.SET_SURFACE_HUMIDITY:
      return {
        ...state,
        surfaceHumidity: action.payload,
      };
    case types.SET_SPINUP_MEAN_TEMPERATURE:
      return {
        ...state,
        spinupMeanTemperature: action.payload,
      };
    case types.SET_SPINUP_TEMPERATURE_AMPLITUDE:
      return {
        ...state,
        spinupTemperatureAmplitude: action.payload,
      };
    case types.SET_VARIABLES_AVERAGING:
      return {
        ...state,
        variablesAveraging: action.payload,
      };
    case types.SET_TOTAL_NUMBERS_OF_PROCESSORS:
      return {
        ...state,
        totalNumbersOfProcessors: action.payload,
      };
    case types.SET_NR_OF_X_PROCESSORS:
      return {
        ...state,
        nrOfXProcessors: action.payload,
      };
    case types.SET_NR_OF_Y_PROCESSORS:
      return {
        ...state,
        nrOfYProcessors: action.payload,
      };
    // case types.SAVE_SIMULATION_SETTINGS: {
    //   let data_output = [];
    //   const mandatoryVariablesAveraging = cloneDeep(
    //     state.mandatoryVariablesAveraging
    //   );
    //   mandatoryVariablesAveraging.map((x) => {
    //     return data_output.push(x.value);
    //   });

    //   const steering = {
    //     caseId: action.payload.caseId,
    //     settings: {
    //       initialization_parameters: {
    //         nx: action.payload.nx - 1,
    //         ny: action.payload.ny - 1,
    //         nz: state.gridPointsZDirection.value,
    //         resolution: action.payload.resolution,
    //         longitude: action.payload.longitude,
    //         latitude: action.payload.latitude,
    //       },
    //       runtime_parameters: {
    //         npex: action.payload.npex,
    //         npey: action.payload.npey,
    //         data_output: data_output,
    //         data_output_masks: data_output,
    //       },
    //     },
    //   };

    //   return {
    //     ...state,
    //     steering: steering,
    //   };
    // }

    case types.LOAD_SIMULATION_SETTINGS: {
      const settings = action.payload.setup.settings;
      const runtimeParameters = settings.runtime_parameters;
      const initializationParameter = settings.initialization_parameters;

      const nrOfXProcessors = runtimeParameters.npex;
      const nrOfYProcessors = runtimeParameters.npey;

      const gridPointsZDirection = {
        label: initializationParameter.nz,
        value: initializationParameter.nz,
      };

      let surfaceTemperature = cloneDeep(state.surfaceTemperature);
      let surfaceHumidity = cloneDeep(state.surfaceHumidity);
      let spinupMeanTemperature = cloneDeep(state.spinupMeanTemperature);
      let spinupTemperatureAmplitude = cloneDeep(
        state.spinupTemperatureAmplitude
      );

      let scenario = cloneDeep(state.scenario);

      if (initializationParameter.pt_surface !== undefined) {
        surfaceTemperature =
          Number(initializationParameter.pt_surface) - 273.15;
        surfaceHumidity = initializationParameter.q_surface;

        spinupMeanTemperature =
          Number(initializationParameter.spinup_pt_mean) - 273.15;

        spinupTemperatureAmplitude =
          initializationParameter.spinup_pt_amplitude;

        scenario = { value: "UserDefined", label: "UserDefined" };
      }

      const data_output_loaded = settings.runtime_parameters.data_output;
      const data_output_no_mandatory = data_output_loaded.filter(
        (x) =>
          x.label !== "NearSurfaceTemperature" &&
          x.label !== "NearSurfaceWind" &&
          x.label !== "Biometerology" &&
          x.label !== "Radiation"
      );
      return {
        ...state,
        scenario: scenario,
        nrOfXProcessors: nrOfXProcessors,
        nrOfYProcessors: nrOfYProcessors,
        gridPointsZDirection: gridPointsZDirection,
        variablesAveraging: data_output_no_mandatory,
        surfaceTemperature: surfaceTemperature,
        surfaceHumidity: surfaceHumidity,
        spinupMeanTemperature: spinupMeanTemperature,
        spinupTemperatureAmplitude: spinupTemperatureAmplitude,
      };
    }
    case types.SET_SIMULATION_TIME:
      const hours = action.payload.hours;
      const minutes = action.payload.minutes;
      if (hours === 48 && minutes > 0) {
        const simulationTime = cloneDeep(state.simulationTime);
        simulationTime.hours = 48;
        simulationTime.minutes = 0;
        return {
          ...state,
          simulationTime: simulationTime,
        };
      } else {
        const simulationTime = cloneDeep(state.simulationTime);
        simulationTime.hours = hours;
        simulationTime.minutes = minutes;
        return {
          ...state,
          simulationTime: simulationTime,
        };
      }
    case types.RESET_SIMULATION_SETTINGS:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default simulationSettingsReducer;
