import { types } from "../types/checkName";

export function checkProjectName(payload) {
  return {
    type: types.CHECK_PROJECT_NAME,
    payload,
  };
}

export function checkProjectDescription(payload) {
  return {
    type: types.CHECK_PROJECT_DESCRIPTION,
    payload,
  };
}

export function checkProjectPicture(payload) {
  return {
    type: types.CHECK_PROJECT_PICTURE,
    payload,
  };
}

export function checkNewProjectCaseName(payload) {
  return {
    type: types.CHECK_NEW_PROJECT_CASE_NAME,
    payload,
  };
}

export function checkCaseDescription(payload) {
  return {
    type: types.CHECK_PROJECT_DESCRIPTION,
    payload,
  };
}

export function checkExistingProjectCaseName(payload) {
  return {
    type: types.CHECK_EXISTING_PROJECT_CASE_NAME,
    payload,
  };
}
