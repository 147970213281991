export const buildingGroups = [
  "Geometry",
  "FacadeWall",
  "FacadeWindows",
  "Roof",
  "RoofWindows",
  "Greening",
  "WindowsGeneral",
  "Usage",
  "HVAC",
  "GroundFloor",
];
