import React from "react";
import { ResultsTextInput } from "../../../../Components/Map/Form/Select";
import { ResultsSelect } from "../../../../Components/Map/Form/Select";
import { Airplay, Download } from "react-feather";
import { Loader } from "../../../../Components/Loader/index";
import { AlertCircle, CheckCircle } from "react-feather";
import { uploadBlobToAzure, getDownloadLink } from "../../../../api/storageApi";
import {
  changePageSize,
  changeScale,
  changeTitle,
  changeSubtitle,
  changeAdditionalText,
  changeAuthor,
  changeInstitution,
  changeAdress,
  changeContact,
  changeDate,
  setResultsGeotiffVisibility,
  getExportRequest,
} from "../../../../redux/actions/resultsActions.js";
import { requestNewCustomLayer } from "../../../../api/resultsApi";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class BasicExportSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mapPlotError: null,
      isProcessing: false,
      isMapPlotReady: false,
    };

    this.requestMapPlot = this.requestMapPlot.bind(this);
    this.saveMapPlot = this.saveMapPlot.bind(this);
  }

  async componentDidUpdate(prevProps) {
    if (
      prevProps.postprocessNotifications[
        prevProps.postprocessNotifications.length - 1
      ] !== undefined &&
      prevProps.postprocessNotifications[
        prevProps.postprocessNotifications.length - 1
      ].body.status === 1 &&
      this.props.postprocessNotifications[
        this.props.postprocessNotifications.length - 1
      ].body.status === 2
    ) {
      this.setState({
        isProcessing: false,
        isMapPlotReady: true,
      });
    } else if (
      prevProps.postprocessNotifications[
        prevProps.postprocessNotifications.length - 1
      ] !== undefined &&
      prevProps.postprocessNotifications[
        prevProps.postprocessNotifications.length - 1
      ].body.status === 1 &&
      this.props.postprocessNotifications[
        this.props.postprocessNotifications.length - 1
      ].body.status === 3
    ) {
      this.setState({
        isProcessing: false,
        isMapPlotReady: false,
        mapPlotError: "MapPlotError",
      });
    }

    if (prevProps.exportRequestJson !== this.props.exportRequestJson) {
      let mapPlotLogoUrl = "";

      if (this.props.mapPlotLogo.logo) {
        mapPlotLogoUrl = `${this.props.loadedProject.id}/${this.props.currentCase.id}/mapPlotLogo.png`;
        await uploadBlobToAzure(mapPlotLogoUrl, this.props.mapPlotLogo.logo);
      }
      let resultsDefUrl = this.props.postProcessingJobs
        .reverse()
        .find((x) => x.status === 2 && x.reportJsonUrl);

      if (
        resultsDefUrl !== undefined &&
        resultsDefUrl.reportJsonUrl !== undefined
      ) {
        resultsDefUrl = resultsDefUrl.reportJsonUrl;
      } else {
        resultsDefUrl = "";
      }

      const additionalInfo = {
        lng: this.props.preferredLanguage
          ? this.props.preferredLanguage
          : "de-DE",
        crs: this.props.epsg,
        resultsDefUrl: resultsDefUrl,
        styLogoPath: mapPlotLogoUrl,
      };

      const request = {
        projectId: this.props.loadedProject.id,
        caseId: this.props.currentCase.id,
        simulationJobId: this.props.simulationJobs[0].id
          ? this.props.simulationJobs[0].id
          : this.props.postProcessingJobs[0].requestId,
        settings: {
          task: "create_map_plot",
          ...additionalInfo,
          ...this.props.exportRequestJson,
        },
      };

      console.log(JSON.stringify(request, null, 2));
      await requestNewCustomLayer(request);
    }
  }

  async saveMapPlot() {
    const filePath = `${this.props.currentCase.id}/PostPro/PALMGUI_Mapplot.pdf`;
    const url = await getDownloadLink(filePath);
    document.createElement("a");
    window.open(url, "_blank");
    //link.setAttribute('download', 'pdf');
    //link.download = "download";
    //link.href = url;
    //link.click();
  }

  requestMapPlot() {
    this.setState({
      isProcessing: true,
      isMapPlotReady: false,
      mapPlotError: null,
    });
    this.props.getExportRequest();
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <ResultsSelect
          label={t("PageSize")}
          style={{ height: "32px" }}
          value={this.props.pageSize}
          options={["DINA4", "DINA3"]}
          onChange={(e) => {
            this.props.changePageSize(e.target.value);
          }}
          visSettings
        />
        <ResultsSelect
          label={t("Scale")}
          style={{ height: "32px" }}
          value={this.props.mapScale}
          options={[
            "1:500",
            "1:1000",
            "1:2000",
            "1:5000",
            "1:10000",
            "1:20000",
            "1:50000",
          ]}
          onChange={(e) => {
            this.props.changeScale(e.target.value);
          }}
          visSettings
        />
        <hr
          className="raster-settings-ruler"
          style={{ width: "300px", marginBottom: "20px" }}
        />
        <ResultsTextInput
          label={t("Title")}
          value={this.props.labTitle}
          onChange={(e) => {
            this.props.changeTitle(e.target.value);
          }}
          maxLength={40}
          visSettings
        />
        <ResultsTextInput
          label={t("Subtitle")}
          value={this.props.labSubtitle}
          onChange={(e) => {
            this.props.changeSubtitle(e.target.value);
          }}
          maxLength={40}
          visSettings
        />
        <ResultsTextInput
          label={t("AdditionalText")}
          value={this.props.labAdditionalText}
          onChange={(e) => {
            this.props.changeAdditionalText(e.target.value);
          }}
          maxLength={80}
          visSettings
        />
        <ResultsTextInput
          label={t("Author")}
          value={this.props.labAuthor}
          onChange={(e) => {
            this.props.changeAuthor(e.target.value);
          }}
          maxLength={40}
          visSettings
        />
        <ResultsTextInput
          label={t("Institution")}
          value={this.props.labInstitution}
          onChange={(e) => {
            this.props.changeInstitution(e.target.value);
          }}
          maxLength={40}
          visSettings
        />
        <ResultsTextInput
          label={t("Adress")}
          value={this.props.labAddress}
          onChange={(e) => {
            this.props.changeAdress(e.target.value);
          }}
          maxLength={60}
          visSettings
        />
        <ResultsTextInput
          label={t("Contact")}
          value={this.props.labContact}
          onChange={(e) => {
            this.props.changeContact(e.target.value);
          }}
          maxLength={40}
          visSettings
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
            marginTop: "4x",
          }}
        >
          <label
            style={{ width: "auto", fontSize: "11px", marginBottom: "2px" }}
            className="form-label user-settings-form-label"
          >
            {t("Date")}
          </label>
          <input
            style={{
              width: "250px",
              height: "28px",
              color: "black",
              fontSize: "11.7px",
              marginBottom: "0px",
              paddingLeft: "4px",
            }}
            className="setup-input"
            type="date"
            id="date"
            name="date"
            value={this.props.labDate}
            onChange={(e) => {
              this.props.changeDate(e.target.value);
            }}
            min="2000-01-01"
            max="2100-12-30"
            required
          ></input>
        </div>

        <div
          className="raster-settings-ruler"
          style={{ width: "300px", marginBottom: "0px" }}
        />
        <div
          style={{
            width: "100%",
            fontSize: "11.7px",
            fontWeight: "600",
            marginTop: "14px",
            fontFamily: "Segoe UI",
            color: "rgb(122, 122, 122)",
            marginBottom: "10px",
          }}
        >
          {t("SelectLayerForMapExport")}
        </div>
        {this.props.resultsJsonOrigin.pages[
          this.props.currentViewIndex
        ].lyrs.map((x, i) => {
          return (
              <label
                className="legend-row-container legend-wrapper"
                style={{ marginTop: "6px" }}
              >
                <input
                  className="raster-checkbox"
                  name={"invert-color"}
                  type="checkbox"
                  checked={x.checked}
                  onChange={() =>
                    this.props.setResultsGeotiffVisibility({
                      resultsGeotiffName: x.name,
                      currentLayerIndex: i,
                    })
                  }
                />
                <label
                  style={{ width: "auto", marginLeft: "12px" }}
                  className="raster-object-name layer-object-name"
                >
                  {t(x.name)}
                </label>
              </label>
          );
        })}
        {this.state.isProcessing && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "18px",
            }}
          >
            <div>
              <Loader size="export-results" />
            </div>
            <div
              style={{
                fontSize: "13px",
                cursor: "default",
                paddingLeft: "10px",
              }}
            >
              {t("Processing")}...
            </div>
          </div>
        )}

        {(this.state.isMapPlotReady || this.state.mapPlotError) && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "16px",
              marginBottom: "-6px",
            }}
          >
            {this.state.isMapPlotReady && !this.state.mapPlotError ? (
              <CheckCircle size="16px" color="rgb(6, 137, 108)" />
            ) : (
              <AlertCircle size="16px" color="#ac4141" />
            )}
            <div
              className={`new-layer-name-error ${
                this.state.isMapPlotReady ? "new-layer-success" : ""
              }`}
            >
              {this.state.isMapPlotReady
                ? t("MapPlotReady")
                : t("MapPlotError")}
            </div>
          </div>
        )}

        <hr
          className="raster-settings-ruler"
          style={{ width: "300px", marginBottom: "20px" }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-around",
          }}
        >
          <button
            className="raster-settings-btn"
            onClick={this.requestMapPlot}
            disabled={this.state.isProcessing}
            style={{
              padding: "4px 10px 4px 10px",
              marginTop: "5px",
              fontSize: "11px",
            }}
          >
            <div className={`btn-content-wrapper `}>
              <Airplay color={"#494949"} size={"12px"} strokeWidth={"2px"} />
              <div className="raster-btn-lbl">{t("ExportMap")}</div>
            </div>
          </button>
          <button
            className="raster-settings-btn"
            onClick={this.saveMapPlot}
            disabled={this.state.isProcessing || !this.state.isMapPlotReady}
            style={{
              padding: "4px 10px 4px 10px",
              marginTop: "5px",
              fontSize: "11px",
            }}
          >
            <div className={`btn-content-wrapper `}>
              <Download color={"#494949"} size={"12px"} strokeWidth={"2px"} />
              <div className="raster-btn-lbl">{t("DownloadMap")}</div>
            </div>
          </button>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    resultsJsonOrigin: state.results.resultsJsonOrigin,
    currentViewIndex: state.results.currentViewIndex,
    pageSize: state.results.pageSize,
    mapScale: state.results.mapScale,
    mapExtent: state.results.mapExtent,
    labTitle: state.results.labTitle,
    labSubtitle: state.results.labSubtitle,
    labAdditionalText: state.results.labAdditionalText,
    labAuthor: state.results.labAuthor,
    labInstitution: state.results.labInstitution,
    labAddress: state.results.labAddress,
    labContact: state.results.labContact,
    labDate: state.results.labDate,
    exportRequestJson: state.results.exportRequestJson,
    preferredLanguage: state.userSettings.settings.preferredLanguage,
    epsg: state.projects.loadedProject.epsg,
    postProcessingJobs: state.projects.currentCase.postProcessingJobs,
    mapPlotLogo: state.results.mapPlotLogo,
    loadedProject: state.projects.loadedProject,
    currentCase: state.projects.currentCase,
    simulationJobs: state.projects.currentCase.simulationJobs,
    postprocessNotifications: state.notifications?.postprocessNotifications,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changePageSize: (payload) => dispatch(changePageSize(payload)),
    changeScale: (payload) => dispatch(changeScale(payload)),
    changeTitle: (payload) => dispatch(changeTitle(payload)),
    changeSubtitle: (payload) => dispatch(changeSubtitle(payload)),
    changeAdditionalText: (payload) => dispatch(changeAdditionalText(payload)),
    changeAuthor: (payload) => dispatch(changeAuthor(payload)),
    changeInstitution: (payload) => dispatch(changeInstitution(payload)),
    changeAdress: (payload) => dispatch(changeAdress(payload)),
    changeContact: (payload) => dispatch(changeContact(payload)),
    changeDate: (payload) => dispatch(changeDate(payload)),
    setResultsGeotiffVisibility: (payload) =>
      dispatch(setResultsGeotiffVisibility(payload)),
    getExportRequest: () => dispatch(getExportRequest()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(BasicExportSettings)));
