import React from "react";
import Select from "react-select";
import ExpertCheckboxTable from "../../ExpertUtils/ExpertCheckboxTable/ExpertCheckboxTable";
import { ExpertTimeAccordion } from "../../ExpertUtils/ExpertComponent";
import { ExpertHelpLabel } from "../../ExpertUtils/ExpertComponent";
import { helpLink } from "../../expertConst";
import { FormSettingsSeparator } from "../../../../Components/Common/Form/FormSeparator/index";
import { generalSettingsTableData } from "../../expertConst";
import { selectSetupStyle } from "../../../../Components/Common/PaletteDropdown/index";
import { useSelector } from "react-redux";

const [page, subpage] = ["output", "general"];

const GeneralSettings = (props) => {
  const general = useSelector(
    (state) => state.expert.expertJson.output.general
  );

  return (
    <>
      <div style={{ marginBottom: -20 }} />
      <FormSettingsSeparator
        style={{ fontSize: "17px" }}
        label={"General Settings"}
      />
      <ExpertHelpLabel
        label={"Data format for netCDF files"}
        helpLink={`${helpLink}/runtime_parameters#netcdf_data_format`}
      />
      <Select
        isSearchable={false}
        styles={selectSetupStyle}
        options={[
          {
            value: "netCDF-4 with NF90_CLASSIC_MODEL",
            label: "netCDF-4 with NF90_CLASSIC_MODEL",
          },
        ]}
        //onChange={(e) => this.props.setOutputPresets(e)}
        value={{
          label: "netCDF-4 with NF90_CLASSIC_MODEL",
          value: "netCDF-4 with NF90_CLASSIC_MODEL",
        }}
      />
      <FormSettingsSeparator
        style={{ fontSize: "17px" }}
        label={"Averaged Output"}
      />
      <ExpertHelpLabel
        label={"Skip Time before Data Output [s]"}
        helpLink={`${helpLink}/runtime_parameters#skip_time_data_output`}
      />
      <ExpertTimeAccordion
        page={page}
        subpage={subpage}
        section="parameters"
        subkey="skip_time_data_output"
        min={0}
        max={86400}
        constValue={general.parameters.skip_time_data_output}
      />
      <div style={{ marginBottom: 12 }} />

      <ExpertHelpLabel
        label={"Averaging Intervall [s]"}
        helpLink={`${helpLink}/runtime_parameters#averaging_interval`}
      />
      <ExpertTimeAccordion
        page={page}
        subpage={subpage}
        section="parameters"
        subkey="averaging_interval"
        min={60}
        max={86400}
        constValue={general.parameters.averaging_interval}
      />

      <FormSettingsSeparator
        style={{ fontSize: "17px" }}
        label={"Output Quantities"}
      />
      <div
        className={"table-animation"}
        style={{ marginTop: "12px", minWidth: "805px" }}
      >
        <ExpertCheckboxTable
          tableData={generalSettingsTableData}
          rowData={props.rowData}
        />
      </div>
    </>
  );
};

export default GeneralSettings;
