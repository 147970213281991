import React, { useState } from "react";
import Select from "react-select";
import { ReactComponent as GermanFlag } from "./flags/de.svg";
import { ReactComponent as UsaFlag } from "./flags/en.svg";
import { selectSetupStyle } from "../PaletteDropdown/index";

const LanguageDropdown = (props) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const flagData = [
    {
      value: "de-DE",
      text: "Deutsch",
      icon: <GermanFlag />,
    },
    {
      value: "en-US",
      text: "English",
      icon: <UsaFlag />,
    },
  ];

  const initialFlag = flagData.find((obj) => {
    return obj.value === props.value;
  });

  const handleChange = (e) => {
    setSelectedOption(e);
    props.onChange(e.value);
  };

  return (
    <div className="form-line-input-wrapper">
      <label className="form-label user-settings-form-label">
        {props.label}
      </label>
      <div>
        <Select
          isSearchable={false}
          value={selectedOption === null ? initialFlag : selectedOption}
          options={flagData}
          onChange={handleChange}
          styles={selectSetupStyle}
          getOptionLabel={(e) => (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  paddingLeft: "0px",
                  marginRight: "8px",
                  marginLeft: "0px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {e.icon}
              </div>
              <div style={{ cursor: "pointer" }}>{e.text}</div>
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default LanguageDropdown;
