import { apiRequest } from "./axiosApi";
import { multiFormRequest } from "./axiosApi";

const apiUrl = process.env.REACT_APP_FRONT_SERVICE_API;

export async function getProjectsAsync() {
  const response = await apiRequest({
    method: "get",
    url: `${apiUrl}/projects`,
  });
  return response.data;
}
export async function getProjectByIdAsync(id) {
  const response = await apiRequest({
    method: "get",
    url: `${apiUrl}/projects/${id}`,
  });
  return response.data;
}

export async function updateProjectAsync(data) {
  const formData = new FormData();
  formData.append("projectId", data.projectId);
  formData.append("name", data.name);
  formData.append("description", data.description || "");
  formData.append("Picture", data.image);
  formData.append("PictureUrl", data.imageUrl || "");
  return await multiFormRequest({
    method: "put",
    url: `${apiUrl}/projects`,
    data: formData,
  });
}

export async function postQgisProject(data) {
  const response = await multiFormRequest({
    method: "post",
    url: `${apiUrl}/projects/qgis`,
    data: data,
  });
  return response.data;
}

export async function postStaticDriverProject(data) {
  const response = await multiFormRequest({
    method: "post",
    url: `${apiUrl}/projects/static-driver`,
    data: data,
  });
  return response.data;
}

export async function deleteProjectById(projectId) {
  const response = await apiRequest({
    method: "delete",
    url: `${apiUrl}/projects/${projectId}`,
  });
  return response.data;
}

export async function addProjectFiles(projectId, files) {
  return await apiRequest({
    method: "post",
    url: `${apiUrl}/projects/${projectId}/files`,
    data: files,
  });
}

export async function deleteProjectFiles(projectId, fileNames) {
  const response = await apiRequest({
    method: "delete",
    url: `${apiUrl}/projects/${projectId}/files`,
    data: fileNames,
  });
  return response.data;
}
