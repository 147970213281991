import React, { useRef, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import { upddateVectorTable } from "../../../redux/actions/expertActions";
import { useDispatch } from "react-redux";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

const ExpertVectorTable = (props) => {
  const gridRef = useRef();
  const dispatch = useDispatch();

  const numberFormatter = (params) => {
    return params.value?.toString();
  };

  const defaultColDef = useMemo(() => {
    return {
      suppressMovable: true,
      editable: true,
      headerClass: "expert-hdr",
      cellClass: "expert-cell expert-right",
      flex: 1,

      valueFormatter: numberFormatter,
      valueSetter: (params) => {
        const colDef = params.colDef;
        const value = params.newValue;
        const min = colDef.min;
        const max = colDef.max;

        if (!isNaN(value)) {
          if (value < min || value > max) {
            return false;
          }
          const rowData = params.data;
          const rowIndex = params.node.rowIndex;
          const templateName = colDef.templateName;
          rowData[colDef.field] = value;

          dispatch(
            upddateVectorTable({
              templateName: templateName,
              parentField: colDef.parentField,
              rowValue: rowData,
              rowIndex: rowIndex,
            })
          );
          return true;
        }
        return false;
      },
    };
  }, []);

  return (
    <div
      className="ag-theme-alpine"
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <AgGridReact
        {...props}
        ref={gridRef}
        rowData={props.rowData}
        columnDefs={props.columnDefs}
        defaultColDef={defaultColDef}
        domLayout={"autoHeight"}
        suppressHorizontalScroll
        suppressCellSelection={false}
        //rowHeight={40}
        // onGridReady={(params) => {
        //   params.api.sizeColumnsToFit();
        // }}
      />
    </div>
  );
};

export default ExpertVectorTable;
