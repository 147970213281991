import React, { useState, useEffect, useRef } from "react";

export const TextArea = (props) => {
  const textAreaRef = useRef(null);
  const [text, setText] = useState("auto");
  const [textAreaHeight, setTextAreaHeight] = useState("31px");
  const [parentHeight, setParentHeight] = useState("31px");

  useEffect(() => {
    if (!!props.value && !!props.value.match(/[^\n]*\n[^\n]*/gi)) {
      const numberOfLineBreaks = props.value.match(/[^\n]*\n[^\n]*/gi).length;

      setTextAreaHeight(`${numberOfLineBreaks * 25 + 12}px`);
      setParentHeight(`${numberOfLineBreaks * 25 + 12}px`);
    }
  }, [props.value]);

  useEffect(() => {
    setParentHeight(`${textAreaRef.current.scrollHeight}px`);
    setTextAreaHeight(`${textAreaRef.current.scrollHeight + 1}px`);
  }, [text]);

  const onChangeHandler = (event) => {
    setTextAreaHeight("auto");
    setParentHeight(`${textAreaRef.current.scrollHeight}px`);
    setText(event.target.value);

    if (props.onChange) {
      props.onChange(event);
    }
  };

  return (
    <div className="form-line-input-wrapper">
      <label className="text-input-form-label">{props.label}</label>
      <div
        style={{
          maxHeight: parentHeight,
        }}
      >
        <textarea
          className={`form-input-ui`}
          spellCheck="false"
          {...props}
          value={props.value || ""}
          ref={textAreaRef}
          rows={1}
          maxLength={500}
          style={{
            fontFamily: "Arial",
            color: "#495057",
            height: textAreaHeight,
            resize: "none",
            width: "300px",
            maxHeight: "200px",
            overflow: "hidden",
          }}
          disabled={props.disabled}
          onChange={onChangeHandler}
        />
      </div>
    </div>
  );
};
