import React from "react";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import { X } from "react-feather";
import "./index.scss";

const DeleteModal = (props) => {
  const { t } = useTranslation();

  return (
    <Modal style={customStyles} isOpen={props.isOpen}>
      <div className="modal-wrapper">
        <div className="modal-hdr-wrapper">
          <div className="modal-hdr">{props.modalHdr}</div>
          <X
            onClick={props.closeDeleteModal}
            size={"20px"}
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className="horizontal-line" />
        <div className="modal-info">{props.modalInfo}</div>
        <div className="modal-btn-wrapper">
          <button className="btn-modal-cancel" onClick={props.closeDeleteModal}>
            {t(`Cancel`)}
          </button>
          <button className="btn-modal-delete" onClick={props.deleteModalData}>
            {t(`Confirm`)}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    // boxShadow: "rgb(0 0 0 / 30%) 0px 0px 8px 1px",
    borderRadius: "5px",
    padding: "30px 38px 28px",
    width: "440px",
    border: "1px solid rgb(187 187 187)",
  },
  overlay: {
    backdropFilter: "blur(8px)",
    zIndex: 1000,
  },
};
