export const types = {
  LOGIN_USER: "LOGIN_USER",
  LOGIN_USER_SUCCESS: "LOGIN_USER_SUCCESS",
  LOGIN_USER_FAILURE: "LOGIN_USER_FAILURE",
  LOGOUT_USER: "LOGOUT_USER",
  LOGOUT_USER_SUCCESS: "LOGOUT_USER_SUCCESS",
  LOAD_USER_DETAILS: "LOAD_USER_DETAILS",
  LOAD_USER_DETAILS_SUCCESS: "LOAD_USER_DETAILS_SUCCESS",
  LOAD_USER_DETAILS_FAILURE: "LOAD_USER_DETAILS_FAILURE",
  GET_ROLES_SUCCESS: "GET_ROLES_SUCCESS",
  GET_ROLES_FAILURE: "GET_ROLES_FAILURE",
};
