import { types } from "../types/auth";

export function loginUser() {
  return {
    type: types.LOGIN_USER,
  };
}

export function loginUserSuccess(payload) {
  return {
    type: types.LOGIN_USER_SUCCESS,
    payload,
  };
}

export function loginUserFailure(payload) {
  return {
    type: types.LOGIN_USER_FAILURE,
    payload,
  };
}

export function logoutUser() {
  return {
    type: types.LOGOUT_USER,
  };
}
export function logoutUserSuccess() {
  return {
    type: types.LOGOUT_USER_SUCCESS,
  };
}

export function loadUserDetails() {
  return {
    type: types.LOAD_USER_DETAILS,
  };
}

export function loadUserDetailsSuccess(payload) {
  return {
    type: types.LOAD_USER_DETAILS_SUCCESS,
    payload,
  };
}

export function loadUserDetailsFailure(payload) {
  return {
    type: types.LOAD_USER_DETAILS_ERROR,
    payload,
  };
}

export function getRolesSuccess(payload) {
  return {
    type: types.GET_ROLES_SUCCESS,
    payload,
  };
}

export function getRolesFailure(payload) {
  return {
    type: types.GET_ROLES_FAILURE,
    payload,
  };
}