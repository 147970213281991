import { types } from "../types/simulation";
import { types as notificationTypes } from "../types/notifications";

const initialState = {
  simulationsByCase: {},
};

const simulationReducer = (state = initialState, action) => {
  switch (action.type) {
    case notificationTypes.ADD_SIMULATION_NOTIFICATION: {
      const notification = action.payload?.body?.response;
      const caseId = notification?.caseId;

      if (!caseId) {
        return state;
      }
      return {
        ...state,
        simulationsByCase: {
          ...state.simulationsByCase,
          [caseId]: {
            id: notification.requestId,
            status: notification.status,
            progress:
              notification.simProgress ??
              state.simulationsByCase[caseId]?.progress,
            eta: notification.simEta ?? state.simulationsByCase[caseId]?.eta,
            lastUpdate: new Date(),
            elapsedTimeInMinutes: notification.elapsedTimeInMinutes,
            results: state.simulationsByCase[caseId]?.results
              ? notification.results
                ? [
                    ...state.simulationsByCase[caseId].results,
                    ...notification.results,
                  ]
                : state.simulationsByCase[caseId].results
              : notification.results
              ? [...notification.results]
              : [],
          },
        },
      };
    }
    case types.LOAD_SIMULATION_JOB: {
      const simulationData = action.payload.simulationData;
      simulationData.status = simStatus[simulationData.status] || simulationData.status;
      return {
        ...state,
        simulationsByCase: {
          [action.payload.currentCaseId]: {
            id: simulationData.id,
            status: simulationData.status,
            progress: simulationData.progress,
            eta: simulationData.estimatedTimeInMinutes,
            lastUpdate: new Date(simulationData.updatedTs),
            elapsedTimeInMinutes: simulationData.elapsedTimeInMinutes,
            results: simulationData.results ? [...simulationData.results] : [],
          },
        },
      };
    }
    default:
      return state;
  }
};

export default simulationReducer;

export const simStatus = [
  "New",
  "waiting",
  "batchInitialization",
  "palmInitialization",
  "running",
  "resultWriting",
  "postprocessing",
  "resultUpload",
  "finished",
  "cancelled",
  "error",
  "aborted",
];
