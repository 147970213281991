import React from "react";
import Select from "react-select";
import {
  ExpertHelpLabel,
  ExpertInput,
  ExpertTimeAccordion,
} from "../../ExpertUtils/ExpertComponent";
import { FormSettingsSeparator } from "../../../../Components/Common/Form/FormSeparator/index";
import { helpLink } from "../../expertConst";
import { selectSetupStyle } from "../../../../Components/Common/PaletteDropdown/index";
import { changeExpertInput } from "../../../../redux/actions/expertActions";
import { useSelector, useDispatch } from "react-redux";

const [page, subpage] = ["output", "mask_tf"];

const TerrainFollowingMask = () => {
  const mask_tf = useSelector(
    (state) => state.expert.expertJson.output.mask_tf
  );
  const childRasterParameters = useSelector(
    (state) => state.projects?.currentCase?.childRasterParameters
  );
  const childRasterParamsOnMount = useSelector(
    (state) => state.rasterArea?.currentCase?.childRasterParameters
  );
  const dispatch = useDispatch();

  return (
    <>
      <div style={{ marginBottom: -20 }} />
      <FormSettingsSeparator
        style={{ fontSize: "17px" }}
        label={"Parameters"}
      />
      <ExpertHelpLabel
        label={"Vertical Output Limit above Terrain [-]"}
        helpLink={`${helpLink}/runtime_parameters#mask_k_over_surface`}
      />
      <ExpertInput
        page={page}
        subpage={subpage}
        section="parameters"
        subkey="masktf_vlimit"
        min={1}
        max={100}
        step={1}
        constValue={mask_tf.parameters.masktf_vlimit}
      />

      {(childRasterParameters || childRasterParamsOnMount) && (
        <>
          <ExpertHelpLabel
            label={"Vertical Output Limit above Terrain - Child [-]"}
            helpLink={`${helpLink}/runtime_parameters#mask_k_over_surface`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="parameters"
            subkey="masktf_vlimit_child"
            min={1}
            max={100}
            step={20}
            constValue={mask_tf.parameters.masktf_vlimit_child}
          />
        </>
      )}
      <div style={{ marginBottom: "-12px" }} />
      <FormSettingsSeparator
        style={{ fontSize: "17px" }}
        label={"Averaged Output"}
      />
      <ExpertHelpLabel
        label={"Activate averaged output"}
        helpLink={`${helpLink}/iofiles#DATA_MASK_01_AV_NETCDF`}
      />
      <Select
        isSearchable={false}
        styles={selectSetupStyle}
        options={[
          {
            value: "True",
            label: "True",
          },
          { value: "False", label: "False" },
        ]}
        value={{
          label: !!mask_tf.averaged.masktf_av_isActive ? "True" : "False",
          value: !!mask_tf.averaged.masktf_av_isActive ? "True" : "False",
        }}
        onChange={(e) =>
          dispatch(
            changeExpertInput({
              page: page,
              subpage: subpage,
              key: "averaged",
              subkey: "masktf_av_isActive",
              value: e.value === "True" ? true : false,
            })
          )
        }
      />
      <FormSettingsSeparator
        style={{ fontSize: "17px" }}
        label={"Instantaneous output"}
      />
      <ExpertHelpLabel
        label={"Activate instantaneous output"}
        helpLink={`${helpLink}/iofiles#DATA_MASK_01_NETCDF`}
      />
      <Select
        isSearchable={false}
        styles={selectSetupStyle}
        options={[
          {
            value: "True",
            label: "True",
          },
          { value: "False", label: "False" },
        ]}
        value={{
          label: !!mask_tf.instantaneous.masktf_inst_isActive
            ? "True"
            : "False",
          value: !!mask_tf.instantaneous.masktf_inst_isActive
            ? "True"
            : "False",
        }}
        onChange={(e) =>
          dispatch(
            changeExpertInput({
              page: page,
              subpage: subpage,
              key: "instantaneous",
              subkey: "masktf_inst_isActive",
              value: e.value === "True" ? true : false,
            })
          )
        }
      />
      <div style={{ marginBottom: 12 }} />
      <ExpertHelpLabel
        label={"Instantaneous Output Intervall [s]"}
        helpLink={`${helpLink}/runtime_parameters#dt_domask`}
      />
      <ExpertTimeAccordion
        page={page}
        subpage={subpage}
        section="instantaneous"
        subkey="masktf_dt_domask"
        min={1}
        max={86400}
        constValue={mask_tf.instantaneous.masktf_dt_domask}
      />
    </>
  );
};

export default TerrainFollowingMask;
