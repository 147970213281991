import { types } from "../types/checkName";

const CHARACTERS_LIMITS = {
  NAME_MIN_LENGTH: 3,
  NAME_MAX_LENGTH: 30,
  DESCRIPTION_MAX_LENGTH: 500,
};

const PICTURE_SIZE = {
  MAX_SIZE: 2000000,
};

const ACCEPTED_PICTURE_FILE_FORMAT = {
  PNG: "image/png",
  JPEG: "image/jpeg",
};

const ERROR_INFO = {
  PROJECT_NAME_WRONG_LENGTH: "ProjectNameLengthError",
  PROJECT_NAME_DUPLICATED: "ProjectNameAlreadyExists",
  PROJECT_DESCRIPTION_TOO_LONG: "ProjectNameLongError",
  CASE_NAME_WRONG_LENGTH: "CaseNameLengthError",
  CASE_NAME_DUPLICATED: "CaseNameAlreadyExists",
  PICTURE_WRONG_FORMAT: "PictureFormatError",
  PICTURE_TOO_BIG: "PictureSizeError",
};

const projectInfoInitialState = {
  isProjectInfoValid: false,
  isProjectNameValid: false,
  isProjectDescriptionValid: true,
  isProjectPictureValid: true,
  projectNameErrorMessage: "",
  projectDescriptionErrorMessage: "",
  projectPictureErrorMessage: "",
};

const caseInfoInitialState = {
  isCaseInfoValid: false,
  isCaseNameValid: false,
  isCaseDescriptionValid: true,
  caseNameErrorMessage: "",
  caseDescriptionErrorMessage: "",
};

const initialState = {
  ...projectInfoInitialState,
  ...caseInfoInitialState,
};

const checkNameReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHECK_PROJECT_NAME: {
      const projects = action.payload.projects;
      const projectName = action.payload.projectName;
      const isProjectNameDuplicated = hasDuplicates(projectName, projects);
      if (isProjectNameDuplicated) {
        return {
          ...state,
          isProjectInfoValid: false,
          isProjectNameValid: false,
          projectNameErrorMessage: ERROR_INFO.PROJECT_NAME_DUPLICATED,
        };
      }
      if (
        projectName.length > CHARACTERS_LIMITS.NAME_MIN_LENGTH &&
        projectName.length < CHARACTERS_LIMITS.NAME_MAX_LENGTH
      ) {
        return {
          ...state,
          isProjectInfoValid:
            state.isProjectDescriptionValid && state.isProjectPictureValid
              ? true
              : false,
          isProjectNameValid: true,
          projectNameErrorMessage: "",
        };
      }

      if (
        projectName.length <= CHARACTERS_LIMITS.NAME_MIN_LENGTH ||
        projectName.length >= CHARACTERS_LIMITS.NAME_MAX_LENGTH
      ) {
        return {
          ...state,
          isProjectInfoValid: false,
          isProjectNameValid: false,
          projectNameErrorMessage: ERROR_INFO.PROJECT_DESCRIPTION_TOO_LONG,
        };
      }
      return {
        ...state,
      };
    }

    case types.CHECK_PROJECT_DESCRIPTION: {
      return { ...state };
    }

    case types.CHECK_PROJECT_PICTURE: {
      const projectPicture = action.payload;

      if (projectPicture === null) {
        return {
          ...state,
          isProjectPictureValid: true,
          isProjectInfoValid:
            state.isProjectNameValid && state.isProjectDescriptionValid
              ? true
              : false,
          projectPictureErrorMessage: "",
        };
      }

      if (
        projectPicture.type !== ACCEPTED_PICTURE_FILE_FORMAT.JPEG &&
        projectPicture.type !== ACCEPTED_PICTURE_FILE_FORMAT.PNG
      ) {
        return {
          ...state,
          isProjectPictureValid: false,
          isProjectInfoValid: false,
          projectPictureErrorMessage: ERROR_INFO.PICTURE_WRONG_FORMAT,
        };
      }

      if (projectPicture.size < PICTURE_SIZE.MAX_SIZE) {
        return {
          ...state,
          isProjectPictureValid: true,
          projectPictureErrorMessage: "",
        };
      }

      if (projectPicture.size > PICTURE_SIZE.MAX_SIZE) {
        return {
          ...state,
          isProjectInfoValid: false,
          isProjectPictureValid: false,
          projectPictureErrorMessage: ERROR_INFO.PICTURE_TOO_BIG,
        };
      }

      return { ...state };
    }

    case types.CHECK_NEW_PROJECT_CASE_NAME: {
      const caseName = action.payload;
      if (
        caseName.length > CHARACTERS_LIMITS.NAME_MIN_LENGTH &&
        caseName.length < CHARACTERS_LIMITS.NAME_MAX_LENGTH
      ) {
        return {
          ...state,
          isCaseInfoValid: true,
          isCaseNameValid: true,
          caseNameErrorMessage: "",
        };
      }

      if (
        caseName.length <= CHARACTERS_LIMITS.NAME_MIN_LENGTH ||
        caseName.length >= CHARACTERS_LIMITS.NAME_MAX_LENGTH
      ) {
        return {
          ...state,
          isCaseInfoValid: false,
          isCaseNameValid: false,
          caseNameErrorMessage: ERROR_INFO.CASE_NAME_WRONG_LENGTH,
        };
      }
      return { ...state };
    }
    case types.CHECK_CASE_DESCRIPTION: {
      return { ...state };
    }

    case types.CHECK_EXISTING_PROJECT_CASE_NAME: {
      const caseName = action.payload.caseName;
      const caseList = action.payload.caseList;

      const isCaseNameDuplicated = hasDuplicates(caseName, caseList);
      if (isCaseNameDuplicated) {
        return {
          ...state,
          isCaseInfoValid: false,
          isCaseNameValid: false,
          caseNameErrorMessage: ERROR_INFO.PROJECT_NAME_DUPLICATED,
        };
      }
      if (
        caseName.length > CHARACTERS_LIMITS.NAME_MIN_LENGTH &&
        caseName.length < CHARACTERS_LIMITS.NAME_MAX_LENGTH
      ) {
        return {
          ...state,
          isCaseInfoValid: true,
          isCaseNameValid: true,
          caseNameErrorMessage: "",
        };
      }

      if (
        caseName.length <= CHARACTERS_LIMITS.NAME_MIN_LENGTH ||
        caseName.length >= CHARACTERS_LIMITS.NAME_MAX_LENGTH
      ) {
        return {
          ...state,
          isCaseInfoValid: false,
          isCaseNameValid: false,
          caseNameErrorMessage: ERROR_INFO.CASE_NAME_WRONG_LENGTH,
        };
      }

      return { ...state };
    }

    default:
      return state;
  }
};

export default checkNameReducer;

const hasDuplicates = (input, nameList) => {
  if (nameList?.length === 0) {
    return false;
  }
  const nameExists = nameList.some((x) => {
    return x.name === input;
  });
  return nameExists;
};
