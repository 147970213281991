import React from "react";
import { PageHeader } from "../../../Components/Page/Page";
import { WizardButtonNext } from "../../../Components/Common/WizardButton/WizardButton";
import FormFileBrowser from "../../../Components/Common/Form/FormFileBrowser/FormFileBrowser";
import { TextInput } from "../../../Components/Common/Form/Form";
import { TextArea } from "../../../Components/Map/Form/TextArea";
import { XCircle, CheckCircle } from "react-feather";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import "./ProjectInfo.scss";

class ProjectInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;
    return (
      <div className="wizard-section">
        <PageHeader text={t("ProjectInformation")} />
        <div className="text-input-container">
          <TextInput
            label={`${t("ProjectName")} *`}
            name={"projectName"}
            value={this.props.projectName}
            onChange={this.props.onInputChange}
          />
          {this.props.projectName.length > 0 && (
            <div className="validation-container">
              {this.props.isProjectNameValid ? (
                <>
                  <div className="validation-icon-container">
                    <CheckCircle width="17" stroke="green" strokeWidth="2" />
                  </div>
                  <div className="validation-wrapper"></div>
                </>
              ) : (
                <>
                  <div className="validation-icon-container">
                    <XCircle width="17" stroke="#fc3939e3" strokeWidth="2" />
                  </div>
                  <div className="validation-wrapper">
                    {t(this.props.projectNameErrorMessage)}
                  </div>
                </>
              )}
            </div>
          )}
        </div>

        <div className="text-input-container">
          <TextArea
            label={t("Information")}
            name={"information"}
            onChange={this.props.onInputChange}
            value={this.props.information}
          />
          {this.props.information.length > 0 && (
            <div className="validation-container">
              {this.props.isProjectDescriptionValid ? (
                <>
                  <div className="validation-icon-container">
                    <CheckCircle width="17" stroke="green" strokeWidth="2" />
                  </div>
                  <div className="validation-wrapper"></div>
                </>
              ) : (
                <>
                  <div className="validation-icon-container">
                    <XCircle width="17" stroke="#fc3939e3" strokeWidth="2" />
                  </div>
                  <div className="validation-wrapper">
                    {t(this.state.informationErrorMessage)}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
        <div className="text-input-container">
          <FormFileBrowser
            name="picture"
            fileName={this.props.picture.name}
            label={t("UploadPicture")}
            accept="image/x-png,image/gif,image/jpeg"
            onChange={this.props.onPictureChange}
            clearUploadedFile={this.props.clearUploadedFile}
            isClearable
          />
          {this.props.picture && (
            <div className="validation-container">
              {this.props.isProjectPictureValid ? (
                <>
                  <div className="validation-icon-container">
                    <CheckCircle width="17" stroke="green" strokeWidth="2" />
                  </div>
                  <div className="validation-wrapper"></div>
                </>
              ) : (
                <>
                  <div className="validation-icon-container">
                    <XCircle width="17" stroke="#fc3939e3" strokeWidth="2" />
                  </div>
                  <div className="validation-wrapper">
                    {t(this.props.pictureErrorMessage)}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
        <div className="app-field-button-wrapper end">
          <WizardButtonNext
            text={t("Next")}
            onClick={this.props.goNextStep}
            disabled={!this.props.isProjectInfoValid}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isProjectInfoValid: state.checkName.isProjectInfoValid,
    isProjectNameValid: state.checkName.isProjectNameValid,
    isProjectDescriptionValid: state.checkName.isProjectDescriptionValid,
    isProjectPictureValid: state.checkName.isProjectPictureValid,
    projectNameErrorMessage: state.checkName.projectNameErrorMessage,
    projectDescriptionErrorMessage:
      state.checkName.projectDescriptionErrorMessage,
    projectPictureErrorMessage: state.checkName.projectPictureErrorMessage,
  };
};

export default withTranslation()(connect(mapStateToProps, null)(ProjectInfo));
