import React, { useRef, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import { CompassIcon } from "../WindComfort/WindTable";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import { withTranslation } from "react-i18next";
import "./Simulation.scss";

const InputCheckSummary = (props) => {
  const additionalData = useSelector(
    (state) => state.projects?.currentCase?.steering?.additionalData
  );

  return (
    <>
      <div className="sim-summary-area">
        <div className="sim-summary-area-text">
          <p className="sim-info-header">Static_Driver</p>
          <div className="sim-input-section-area">{props.staticDriver}</div>
          <div className="form-separator__line" />
          <p className="sim-info-header">Setup</p>
          <div className="sim-input-section-area">{props.setup}</div>
          <div className="form-separator__line" />

          <p className="sim-info-header">Results</p>
          <div className="sim-input-section-area">{props.results}</div>
          <div className="form-separator__line" />

          <p className="sim-info-header">Storage</p>
          <div className="sim-input-section-area">{props.storage}</div>
        </div>
        {!props.isWindTableUserDefined && additionalData?.windTable && (
          <div className="table-animation">
            <WindSimTable additionalData={additionalData} />
          </div>
        )}
      </div>
    </>
  );
};

export default withTranslation()(InputCheckSummary);

export const WindSimTable = (props) => {
  const gridRef = useRef();

  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const { t } = useTranslation();

  const columnDefs = [
    {
      headerName: ``,
      field: "direction",
      editable: false,
      headerClass: "wind-table-first-hdr wind-table-first-header-sim",
      cellClass: "wind-table-dir-col",
      pinned: "left",
    },

    ...props.additionalData.windHeader,
  ];

  const defaultColDef = useMemo(() => {
    return {
      headerClass: "wind-hdr",
      cellClass: "wind-cell",
      editable: false,
      suppressMovable: true,

      minWidth: 54,
    };
  }, []);

  function lastRowBorder(params) {
    if (params.node.rowIndex === params.api.rowModel.rowsToDisplay.length - 1) {
      return { borderBottom: "0px solid white" };
    } else {
      return {};
    }
  }

  return (
    <div className="ag-theme-alpine" style={gridStyle}>
      <AgGridReact
        {...props}
        ref={gridRef}
        rowData={props.additionalData.windTable}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        frameworkComponents={{ iconComponent: CompassIcon }}
        suppressCellSelection={true}
        gridOptions={{ getRowStyle: lastRowBorder }}
        onGridReady={(params) => {
          params.api.sizeColumnsToFit();
          window.onresize = debounce(() => {
            params.api.sizeColumnsToFit();
          }, 200);
        }}
        overlayNoRowsTemplate={t("NoRowsToShow")}
        headerHeight={30}
        rowHeight={30}
        domLayout={"autoHeight"}
      ></AgGridReact>
    </div>
  );
};
