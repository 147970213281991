import { types } from "../types/results";
import { utm31Def, utm32Def, utm33Def } from "../../common/utmDef";
import { allCrsOptions } from "../../containers/Tools/OsmDownload/osmConst";
import {
  spatProcessOptions,
  windfieldParamsOptions,
} from "../../containers/Results/ResultsConst";
import {
  mapPlotSquareEdge,
  itemPositionAbbr,
} from "../../containers/Results/ResultsConst";
import { calculateRasterAreaPolygonPoints } from "../../containers/Domain/raster-area-helpers/index";
import {
  reprojectWgToUtmPoint,
  reprojectUtmToWgPoint,
} from "../../common/utmDef";
import { cloneDeep } from "lodash";

const viewInitialState = {
  inResultsMapPoiMode: false,
  inResultsMapExportMode: false,
  inLayerSettings: false,
  inGeodataDownload: false,
  inPoi: false,
  inAoi: false,
  tempPoiKey: "",
  tempPoiValue: "",
  isCentering: false,
  isMapPlotCentering: false,
  currentLayerIndex: null,
  inReadOnlyLayerForm: false,
  inCreateLayerForm: false,
  inLayerAppearance: false,
  inExportSettings: false,
  inTimeChart: false,
  inVerticalChart: false,
};

const mapPlotSettingsInitialState = {
  pageSize: "DINA4",
  mapScale: "1:10000",
  labTitle: "",
  labSubtitle: "",
  labAdditionalText: "",
  labAuthor: "",
  labInstitution: "",
  labAddress: "",
  labContact: "",
  labDate: "",
  annNorthArrow: true,
  annNorthArrowPos: "top",
  annScale: true,
  annScalePos: "topright",
  annAssessmentPoints: true,
  annAssessmentPointsArray: [],
  annAssessmentPointsCol: "#000",
  styBackMapCol: "#d3d3d3",
  styBackHeaderCol: " #fff",
  styLogoPath: "",
  styCropName: "Domain",
  styCropPolygon: "",
  vecArrowScale: true,
  vecArrowSkip: 4,
  vecArrowSize: 0.5,
  vecMinMag: 0,
  vecArrowScalePos: "bottom",
  exportRequestJson: "",
  mapPlotCenterLatWg: null,
  mapPlotCenterLngWg: null,
  mapPlotCenterLatUtm: null,
  mapPlotCenterLngUtm: null,
  mapPlotPolygonPoints: null,
  isMapPlotDraggable: false,
  mapPlotLogo: "",
};

const pointsOfInterestInitialState = {
  selectedPoiIndex: null,
  displayPoiOnMouseMove: false,
  arrayOfPoiId: [],
  allDrawnPoiWg: [],
  allDrawnPoiUtm: [],
};

const areaOfInterestInitialState = {
  isPoiVisible: true,
  arrayOfDrawnAoiId: [],
  allAoiInputCoords: [],
  inAoiSelectMode: true,
  canDrawAoiPolygon: false,
  drawnPolyAoiCoordsIndex: null,
  arrayOfDrawnAoiLayerName: [],
  selectedAoiPolygonIndex: null,
  allDrawnAoiPolygonsPointsUtm: [],
  allDrawnAoiPolygonsPointsWg: [],
  aoiPolygonPointsWg: [],
  aoiPolygonPointsUtm: [],
  aoiInputCoords: [
    { lng: "", lat: "" },
    { lng: "", lat: "" },
    { lng: "", lat: "" },
  ],
  aoiPolygonDrawn: false,
};

const layerSettingsInitialState = {
  resultsJsonOrigin: null,
  layerSettingsJson: null,
  layerSettingsTypes: null,
  layerSettingsFileTypes: null,
  layerSettingsFormDataValue: null,
  layerSettingsFormDataOptions: null,
  jsonOriginPartSelected: null,
  jsonOriginLayerIndex: 0,
  nrOfAutoPages: null,
  layerNames: [],
  pageNames: [],
  isPageNameValid: null,
  pageNameError: null,
};

const newLayerSettingsInitialState = {
  newLayerTypeField: "Scalar",
  diffCase: null,
  diffCaseId: null,
  diffCaseResultsJsonOrigin: null,
  diffPage: null,
  diffPageIndex: 0,
  diffPageOptions: null,
  diffLayer: null,
  diffLayerIndex: 0,
  diffLayerOptions: null,
  diffChildCase: null,
  diffChildCaseId: null,
  diffChildCaseResultsJsonOrigin: null,
  diffChildPage: null,
  diffChildPageIndex: 0,
  diffChildLayer: null,
  diffChildLayerIndex: 0,
  diffChildLayerOptions: null,
  diffType: "abs",
  diffUnit: "[-]",

  spatFileType: null,
  spatFileTypeOptions: null,
  spatParams: null,
  spatParamsOptions: null,
  spatHeightFrom: null,
  spatHeightTo: null,
  spatTimeFrom: null,
  spatTimeTo: null,
  spatTimeOptions: null,
  spatArea: null,
  spatAreaOptions: null,
  spatProcessing: null,
  spatProcessingOptions: null,

  catCustomPage: null,
  catCustomPageIndex: 0,
  catCustomPageOptions: null,
  catCustomLayer: null,
  catCustomLayerIndex: 0,
  catCustomLayerOptions: null,
  catCustomNr: 3,
  catCustomColorPalette: "greens",
  isCatCustomColorPaletteInverted: false,

  windfieldCase: null,
  windfieldFileType: null,
  windfieldTypeOptions: null,
  windfieldParams: windfieldParamsOptions[0],
  windfieldHeight: null,
  windfieldHeightOptions: null,
  windfieldTimestep: null,
  windfieldTimestepOptions: null,
};

const chartsInitialState = {
  chartFileType: null,
  chartFileTypeOptions: null,
  chartParams: null,
  chartParamsIndex: null,
  chartParamsOptions: null,
  chartPoint: [],
  chartHeightLevel: null,
  chartHeightLevelOptions: null,
  chartTimestep: null,
  chartTimestepOptions: null,
};

const initialState = {
  crsDef: null,
  inResultsMapView: false,
  currentViewIndex: 0,
  poiCursorCoords: null,
  isResultsGeotiffLegendVisible: false,
  lastResultsGeotiffLayerIndexChanged: "",
  lastResultsGeotiffPageIndexChanged: 0,
  lastResultsGeotiffNameChanged: "",
  resultsJsonCarouselOptions: [],
  currentActiveResultsView: "StaticDriver",
  reportUrl: null,
  legendVisibilityChanged: false,
  currentIncomingAoiId: 1,
  ...viewInitialState,
  ...pointsOfInterestInitialState,
  ...areaOfInterestInitialState,
  ...layerSettingsInitialState,
  ...newLayerSettingsInitialState,
  ...mapPlotSettingsInitialState,
  ...chartsInitialState,
};

const resultsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_RESULTS: {
      const resultsJsonOrigin = action.payload.data;
      const nrOfAutoPages = resultsJsonOrigin.pages.length;
      const resultsJsonCarouselOptions = [];
      resultsJsonOrigin.pages.map((x) => {
        resultsJsonCarouselOptions.push(x.name);
      });
      if (
        resultsJsonCarouselOptions[resultsJsonCarouselOptions.length - 1] !==
        "CreatePage"
      ) {
        resultsJsonCarouselOptions.push("CreatePage");
        resultsJsonOrigin.pages.push({
          id: "CreatePage",
          name: "CreatePage",
          icon: "CreatePage",
        });
      }

      const reportUrlList = resultsJsonOrigin.pages.filter((x) => {
        return x.name === "Report";
      })[0];
      const reportHtml = reportUrlList.report_html;
      const reportPdf = reportUrlList.report_pdf;
      const reportDocx = reportUrlList.report_docx;

      const reportUrl = {
        reportHtml: reportHtml,
        reportPdf: reportPdf,
        reportDocx: reportDocx,
      };

      const pageNames = resultsJsonOrigin.pages.map((x) => {
        return x.name;
      });

      return {
        ...state,
        inResultsMapView: true,
        resultsJsonCarouselOptions: resultsJsonCarouselOptions,
        resultsJsonOrigin: resultsJsonOrigin,
        reportUrl: reportUrl,
        currentActiveResultsView: "StaticDriver",
        currentViewIndex: 0,
        nrOfAutoPages: nrOfAutoPages,
        pageNames: pageNames,
      };
    }
    case types.SET_RESULTS_VIEW: {
      let currentActiveResultsView = cloneDeep(state.currentActiveResultsView);
      currentActiveResultsView =
        state.resultsJsonCarouselOptions[action.payload];

      return {
        ...state,
        ...viewInitialState,
        inLayerSettings: state.inLayerSettings,
        inPoi: state.inPoi,
        inAoi: state.inAoi,
        inResultsMapPoiMode: state.inResultsMapPoiMode,
        currentViewIndex: action.payload,
        currentActiveResultsView: currentActiveResultsView,
        jsonOriginLayerIndex: 0,
        currentLayerIndex: null,
      };
    }

    case types.SET_RESULTS_CRS_DEF:
      const epsg = action.payload;
      let crsDef = state.crsDef;

      if (epsg === "25831") {
        crsDef = utm31Def;
      } else if (epsg === "25832") {
        crsDef = utm32Def;
      } else if (epsg === "25833") {
        crsDef = utm33Def;
      } else {
        crsDef = allCrsOptions.find((x) => x.value === `EPSG:${epsg}`).def;
      }
      return {
        ...state,
        crsDef,
      };

    case types.TOGGLE_RESULTS_MAP_POI_MODE: {
      const allDrawnAoiPolygonsPointsWg = cloneDeep(
        state.allDrawnAoiPolygonsPointsWg
      );
      const allDrawnAoiPolygonsPointsUtm = cloneDeep(
        state.allDrawnAoiPolygonsPointsUtm
      );
      const allAoiInputCoords = cloneDeep(state.allAoiInputCoords);
      const arrayOfDrawnAoiId = cloneDeep(state.arrayOfDrawnAoiId);
      const arrayOfDrawnAoiLayerName = cloneDeep(
        state.arrayOfDrawnAoiLayerName
      );
      const isPoiVisible = cloneDeep(state.isPoiVisible);

      return {
        ...state,
        ...areaOfInterestInitialState,
        ...viewInitialState,
        inResultsMapPoiMode: !state.inResultsMapPoiMode,
        inAoi: false,
        inPoi: false,
        allDrawnAoiPolygonsPointsWg: allDrawnAoiPolygonsPointsWg,
        allDrawnAoiPolygonsPointsUtm: allDrawnAoiPolygonsPointsUtm,
        allAoiInputCoords: allAoiInputCoords,
        arrayOfDrawnAoiId: arrayOfDrawnAoiId,
        arrayOfDrawnAoiLayerName: arrayOfDrawnAoiLayerName,
        isPoiVisible: isPoiVisible,
      };
    }

    case types.TOGGLE_RESULTS_MAP_EXPORT_MODE:
      return {
        ...state,
        ...viewInitialState,
        inResultsMapExportMode: !state.inResultsMapExportMode,
      };

    case types.TOGGLE_LAYER_SETTINGS:
      return {
        ...state,
        ...viewInitialState,
        inLayerSettings: !state.inLayerSettings,
        inReadOnlyLayerForm: false,
        inCreateLayerForm: false,
      };

    case types.TOGGLE_LAYER_SETTINGS_FORM:
      return {
        ...state,
        inCreateLayerForm: false,
        inReadOnlyLayerForm: false,
      };

    case types.TOGGLE_GEODATA_DOWNLOAD:
      return {
        ...state,
        ...viewInitialState,
        inResultsMapExportMode: true,
        inGeodataDownload: !state.inGeodataDownload,
      };

    case types.TOGGLE_POI:
      const allDrawnAoiPolygonsPointsWg = cloneDeep(
        state.allDrawnAoiPolygonsPointsWg
      );
      const allDrawnAoiPolygonsPointsUtm = cloneDeep(
        state.allDrawnAoiPolygonsPointsUtm
      );
      const arrayOfDrawnAoiLayerName = cloneDeep(
        state.arrayOfDrawnAoiLayerName
      );
      const allAoiInputCoords = cloneDeep(state.allAoiInputCoords);
      const arrayOfDrawnAoiId = cloneDeep(state.arrayOfDrawnAoiId);
      return {
        ...state,
        ...areaOfInterestInitialState,
        selectedPoiIndex: null,
        inPoi: !state.inPoi,
        inAoi: false,
        inTimeChart: false,
        inVerticalChart: false,
        allDrawnAoiPolygonsPointsWg: allDrawnAoiPolygonsPointsWg,
        allDrawnAoiPolygonsPointsUtm: allDrawnAoiPolygonsPointsUtm,
        allAoiInputCoords: allAoiInputCoords,
        arrayOfDrawnAoiId: arrayOfDrawnAoiId,
        poiCursorCoords: null,
        arrayOfDrawnAoiLayerName: arrayOfDrawnAoiLayerName,
      };

    case types.TOGGLE_POI_CURSOR_DISPLAY:
      return {
        ...state,
        displayPoiOnMouseMove: !state.displayPoiOnMouseMove,
      };

    case types.GET_POI_CURSOR_COORDS:
      return {
        ...state,
        poiCursorCoords: action.payload,
      };

    case types.ADD_POI: {
      const allDrawnPoiWg = state.allDrawnPoiWg;
      const allDrawnPoiUtm = cloneDeep(state.allDrawnPoiUtm);
      const poiLatWg = action.payload.lat;
      const poiLngWg = action.payload.lng;
      const currentDrawnPoiUtm = reprojectWgToUtmPoint(
        {
          lat: poiLatWg,
          lng: poiLngWg,
        },
        state.crsDef
      );

      let arrayOfPoiId = state.arrayOfPoiId;
      let pointName = null;
      let addedPoiId = null;

      if (arrayOfPoiId.length === 0) {
        pointName = "Point 0";
        addedPoiId = 0;
      } else {
        const lastAddedPoiId = Number(arrayOfPoiId.slice(-1));
        addedPoiId = lastAddedPoiId + 1;
        pointName = `Point ${addedPoiId}`;
      }
      currentDrawnPoiUtm.pointName = pointName;
      currentDrawnPoiUtm.lat = Number(currentDrawnPoiUtm.lat);
      currentDrawnPoiUtm.lng = Number(currentDrawnPoiUtm.lng);

      allDrawnPoiUtm.push(currentDrawnPoiUtm);
      allDrawnPoiWg.push({
        lat: Number(action.payload.lat),
        lng: Number(action.payload.lng),
        pointName: pointName,
      });

      arrayOfPoiId.push(addedPoiId);
      let selectedPoiIndex = cloneDeep(state.selectedPoiIndex);

      if (allDrawnPoiWg.length === 0 && selectedPoiIndex === null) {
        selectedPoiIndex = 0;
      } else {
        selectedPoiIndex = allDrawnPoiWg.length - 1;
      }

      return {
        ...state,
        allDrawnPoiUtm: allDrawnPoiUtm,
        allDrawnPoiWg: allDrawnPoiWg,
        arrayOfPoiId: arrayOfPoiId,
        addedPoiId: addedPoiId,
        selectedPoiIndex: selectedPoiIndex,
      };
    }

    case types.SELECT_POI:
      return {
        ...state,
        selectedPoiIndex: action.payload,
      };

    case types.REMOVE_POI:
      const indexOfRemovedPoi = action.payload;

      const arrayOfPoiId = cloneDeep(state.arrayOfPoiId);
      const allDrawnPoiUtm = cloneDeep(state.allDrawnPoiUtm);
      const allDrawnPoiWg = cloneDeep(state.allDrawnPoiWg);

      allDrawnPoiWg.splice(indexOfRemovedPoi, 1);
      allDrawnPoiUtm.splice(indexOfRemovedPoi, 1);
      arrayOfPoiId.splice(indexOfRemovedPoi, 1);

      return {
        ...state,
        arrayOfPoiId: arrayOfPoiId,
        allDrawnPoiUtm: allDrawnPoiUtm,
        allDrawnPoiWg: allDrawnPoiWg,
        selectedPoiIndex: null,
      };

    case types.UPDATE_TEMP_POI_INPUT: {
      let tempPoiValue = cloneDeep(state.tempPoiValue);
      tempPoiValue = action.payload.tempPoiValue;
      return {
        ...state,
        tempPoiKey: action.payload.tempPoiKey,
        tempPoiValue: tempPoiValue,
        drawnPolyAoiCoordsIndex: action.payload.drawnPolyAoiCoordsIndex,
      };
    }

    case types.UPDATE_POI_POSITION_ON_INPUT_CHANGE: {
      let { name, value } = action.payload.e.target;

      const selectedPoiIndex = action.payload.selectedPoiIndex;
      const allDrawnPoiWg = cloneDeep(state.allDrawnPoiWg);
      const allDrawnPoiUtm = cloneDeep(state.allDrawnPoiUtm);

      if (name === "lat" && value < 0) {
        return { ...state };
      } else if (name === "lng" && value < 0) {
        return { ...state };
      } else if (value === "") {
        value = 0;
      }

      value = Number(value);
      if (name === "lat") {
        const valueInWg = reprojectUtmToWgPoint(
          [Number(allDrawnPoiUtm[selectedPoiIndex].lng), value],
          state.crsDef
        ).lat.toFixed(6);

        allDrawnPoiWg[selectedPoiIndex].lat = valueInWg;
        allDrawnPoiUtm[selectedPoiIndex].lat = value;
      } else {
        const valueInWg = reprojectUtmToWgPoint(
          [value, Number(allDrawnPoiUtm[selectedPoiIndex].lat)],
          state.crsDef
        ).lng.toFixed(6);
        allDrawnPoiWg[selectedPoiIndex].lng = valueInWg;
        allDrawnPoiUtm[selectedPoiIndex].lng = value;
      }

      return {
        ...state,
        allDrawnPoiUtm: allDrawnPoiUtm,
        allDrawnPoiWg: allDrawnPoiWg,
        tempPoiKey: "",
        tempPoiValue: "",
      };
    }

    case types.UPDATE_POI_POSITION_ON_DRAGEND: {
      const updatedCoordinates = action.payload.e;
      const indexOfSelectedPoi = action.payload.idx;
      const allDrawnPoiWg = cloneDeep(state.allDrawnPoiWg);
      const allDrawnPoiUtm = cloneDeep(state.allDrawnPoiUtm);

      const updatedPoiPositionCoordinatesWg = {
        lat: updatedCoordinates.lat.toFixed(7),
        lng: updatedCoordinates.lng.toFixed(7),
      };

      const updatedTreePositionCoordinatesUtm = reprojectWgToUtmPoint(
        {
          lat: updatedPoiPositionCoordinatesWg.lat,
          lng: updatedPoiPositionCoordinatesWg.lng,
        },
        state.crsDef
      );

      allDrawnPoiWg[indexOfSelectedPoi].lat =
        updatedPoiPositionCoordinatesWg.lat;
      allDrawnPoiWg[indexOfSelectedPoi].lng =
        updatedPoiPositionCoordinatesWg.lng;

      allDrawnPoiUtm[indexOfSelectedPoi].lat =
        updatedTreePositionCoordinatesUtm.lat;
      allDrawnPoiUtm[indexOfSelectedPoi].lng =
        updatedTreePositionCoordinatesUtm.lng;

      return {
        ...state,
        allDrawnPoiWg: allDrawnPoiWg,
        allDrawnPoiUtm: allDrawnPoiUtm,
      };
    }

    case types.ON_LAYER_NAME_CHANGE: {
      const inputValue = action.payload.inputValue;
      const indexOfPoi = action.payload.nameIndex;
      const inPoi = state.inPoi;
      const allDrawnPoiWg = state.allDrawnPoiWg;
      const allDrawnPoiUtm = cloneDeep(state.allDrawnPoiUtm);

      if (inPoi) {
        allDrawnPoiWg[indexOfPoi].pointName = inputValue;
        allDrawnPoiUtm[indexOfPoi].pointName = inputValue;

        return {
          ...state,
          allDrawnPoiUtm: allDrawnPoiUtm,
          allDrawnPoiWg: allDrawnPoiWg,
        };
      }
      return {
        ...state,
      };
    }

    case types.TOGGLE_AOI: {
      const allDrawnAoiPolygonsPointsWg = cloneDeep(
        state.allDrawnAoiPolygonsPointsWg
      );
      const allDrawnAoiPolygonsPointsUtm = cloneDeep(
        state.allDrawnAoiPolygonsPointsUtm
      );
      const allAoiInputCoords = cloneDeep(state.allAoiInputCoords);
      const arrayOfDrawnAoiId = cloneDeep(state.arrayOfDrawnAoiId);
      const arrayOfDrawnAoiLayerName = cloneDeep(
        state.arrayOfDrawnAoiLayerName
      );
      let inAoiSelectMode = true;
      if (state.inAoi) {
        inAoiSelectMode = false;
      }

      return {
        ...state,
        ...areaOfInterestInitialState,
        inAoi: !state.inAoi,
        inPoi: false,
        inTimeChart: false,
        inVerticalChart: false,
        allDrawnAoiPolygonsPointsWg: allDrawnAoiPolygonsPointsWg,
        allDrawnAoiPolygonsPointsUtm: allDrawnAoiPolygonsPointsUtm,
        allAoiInputCoords: allAoiInputCoords,
        arrayOfDrawnAoiId: arrayOfDrawnAoiId,
        inAoiSelectMode: inAoiSelectMode,
        arrayOfDrawnAoiLayerName: arrayOfDrawnAoiLayerName,
        poiCursorCoords: null,
      };
    }

    case types.START_NEW_AOI: {
      const allDrawnAoiPolygonsPointsWg = cloneDeep(
        state.allDrawnAoiPolygonsPointsWg
      );
      const allDrawnAoiPolygonsPointsUtm = cloneDeep(
        state.allDrawnAoiPolygonsPointsUtm
      );
      const allAoiInputCoords = cloneDeep(state.allAoiInputCoords);
      const arrayOfDrawnAoiId = cloneDeep(state.arrayOfDrawnAoiId);
      const arrayOfDrawnAoiLayerName = cloneDeep(
        state.arrayOfDrawnAoiLayerName
      );
      return {
        ...state,
        ...areaOfInterestInitialState,
        allDrawnAoiPolygonsPointsWg: allDrawnAoiPolygonsPointsWg,
        allDrawnAoiPolygonsPointsUtm: allDrawnAoiPolygonsPointsUtm,
        allAoiInputCoords: allAoiInputCoords,
        arrayOfDrawnAoiId: arrayOfDrawnAoiId,
        inAoiSelectMode: false,
        canDrawAoiPolygon: true,
        arrayOfDrawnAoiLayerName: arrayOfDrawnAoiLayerName,
      };
    }

    case types.DELETE_AOI: {
      const indexOfAoiPolygon = action.payload;
      const allDrawnAoiPolygonsPointsWg = cloneDeep(
        state.allDrawnAoiPolygonsPointsWg
      );
      const allDrawnAoiPolygonsPointsUtm = cloneDeep(
        state.allDrawnAoiPolygonsPointsUtm
      );
      const allAoiInputCoords = cloneDeep(state.allAoiInputCoords);
      const arrayOfDrawnAoiId = cloneDeep(state.arrayOfDrawnAoiId);
      const currentIncomingAoiId = cloneDeep(state.currentIncomingAoiId);
      const arrayOfDrawnAoiLayerName = cloneDeep(
        state.arrayOfDrawnAoiLayerName
      );
      allDrawnAoiPolygonsPointsWg.splice(indexOfAoiPolygon, 1);
      allDrawnAoiPolygonsPointsUtm.splice(indexOfAoiPolygon, 1);
      allAoiInputCoords.splice(indexOfAoiPolygon, 1);
      arrayOfDrawnAoiId.splice(indexOfAoiPolygon, 1);
      arrayOfDrawnAoiLayerName.splice(indexOfAoiPolygon, 1);

      return {
        ...state,
        ...areaOfInterestInitialState,
        inAoiSelectMode: true,
        allDrawnAoiPolygonsPointsWg: allDrawnAoiPolygonsPointsWg,
        allDrawnAoiPolygonsPointsUtm: allDrawnAoiPolygonsPointsUtm,
        allAoiInputCoords: allAoiInputCoords,
        selectedAoiPolygonIndex: null,
        arrayOfDrawnAoiId: arrayOfDrawnAoiId,
        currentIncomingAoiId: currentIncomingAoiId,
        arrayOfDrawnAoiLayerName: arrayOfDrawnAoiLayerName,
      };
    }

    case types.DRAW_AOI: {
      if (
        state.aoiPolygonPointsUtm.length < 3 &&
        state.aoiPolygonPointsUtm.length !== 0
      ) {
        return {
          ...state,
          inAoiSelectMode: true,
          aoiInputCoords: [
            { lng: "", lat: "" },
            { lng: "", lat: "" },
            { lng: "", lat: "" },
          ],
          aoiPolygonPointsWg: [],
          aoiPolygonPointsUtm: [],
        };
      } else if (state.inAoiSelectMode || state.aoiPolygonDrawn) {
        const allDrawnAoiPolygonsPointsWg = cloneDeep(
          state.allDrawnAoiPolygonsPointsWg
        );

        const allDrawnAoiPolygonsPointsUtm = cloneDeep(
          state.allDrawnAoiPolygonsPointsUtm
        );
        const allAoiInputCoords = cloneDeep(state.allAoiInputCoords);
        const arrayOfDrawnAoiId = cloneDeep(state.arrayOfDrawnAoiId);
        const currentIncomingAoiId = cloneDeep(state.currentIncomingAoiId);
        const arrayOfDrawnAoiLayerName = cloneDeep(
          state.arrayOfDrawnAoiLayerName
        );
        return {
          ...state,
          ...areaOfInterestInitialState,
          inAoiSelectMode: false,
          allAoiInputCoords: allAoiInputCoords,
          allDrawnAoiPolygonsPointsWg: allDrawnAoiPolygonsPointsWg,
          allDrawnAoiPolygonsPointsUtm: allDrawnAoiPolygonsPointsUtm,
          arrayOfDrawnAoiId: arrayOfDrawnAoiId,
          currentIncomingAoiId: currentIncomingAoiId,
          canDrawAoiPolygon: false,
          aoiPolygonDrawn: false,
          arrayOfDrawnAoiLayerName: arrayOfDrawnAoiLayerName,
        };
      } else if (
        !state.inAoiSelectMode &&
        state.aoiPolygonPointsUtm.length === 0
      ) {
        return {
          ...state,
          inAoiSelectMode: true,
        };
      }

      const arrayOfDrawnAoiId = cloneDeep(state.arrayOfDrawnAoiId);
      const aoiPolygonPointsWg = cloneDeep(state.aoiPolygonPointsWg);
      const aoiInputCoords = cloneDeep(state.aoiInputCoords);
      const allAoiInputCoords = cloneDeep(state.allAoiInputCoords);
      const allDrawnAoiPolygonsPointsWg = cloneDeep(
        state.allDrawnAoiPolygonsPointsWg
      );
      const allDrawnAoiPolygonsPointsUtm = cloneDeep(
        state.allDrawnAoiPolygonsPointsUtm
      );
      const arrayOfDrawnAoiLayerName = cloneDeep(
        state.arrayOfDrawnAoiLayerName
      );

      arrayOfDrawnAoiId.push(state.currentIncomingAoiId);
      allDrawnAoiPolygonsPointsWg.push(aoiPolygonPointsWg);
      aoiPolygonPointsWg.push(aoiPolygonPointsWg[0]);
      allAoiInputCoords.push(aoiInputCoords);
      allDrawnAoiPolygonsPointsUtm.push(state.aoiPolygonPointsUtm);
      arrayOfDrawnAoiLayerName.push(
        `${"Area Of Interest"} ` + state.currentIncomingAoiId
      );

      return {
        ...state,
        inAoiSelectMode: true,
        aoiPolygonDrawn: true,
        currentIncomingAoiId: state.currentIncomingAoiId + 1,
        allDrawnAoiPolygonsPointsWg: allDrawnAoiPolygonsPointsWg,
        selectedAoiPolygonIndex: allDrawnAoiPolygonsPointsWg.length - 1,
        allDrawnAoiPolygonsPointsUtm: allDrawnAoiPolygonsPointsUtm,
        allAoiInputCoords: allAoiInputCoords,
        arrayOfDrawnAoiId: arrayOfDrawnAoiId,
        arrayOfDrawnAoiLayerName: arrayOfDrawnAoiLayerName,
      };
    }

    case types.DRAG_AOI_POINT_WG: {
      const aoiPolygonPointsWg = cloneDeep(state.aoiPolygonPointsWg);
      aoiPolygonPointsWg[action.payload.indexOfPoint] = [
        action.payload.pointCoords[0],
        action.payload.pointCoords[1],
      ];

      const allDrawnAoiPolygonsPointsWg = cloneDeep(
        state.allDrawnAoiPolygonsPointsWg
      );

      allDrawnAoiPolygonsPointsWg[state.selectedAoiPolygonIndex][
        action.payload.indexOfPoint
      ] = [action.payload.pointCoords[0], action.payload.pointCoords[1]];
      return {
        ...state,
        aoiPolygonPointsWg: aoiPolygonPointsWg,
        allDrawnAoiPolygonsPointsWg: allDrawnAoiPolygonsPointsWg,
      };
    }

    case types.DRAGEND_AOI_POINT_WG: {
      const aoiInputCoords = cloneDeep(state.aoiInputCoords);
      const idx = action.payload.idx;
      const aoiPolygonPointsUtm = cloneDeep(state.aoiPolygonPointsUtm);

      if (aoiPolygonPointsUtm[idx] === undefined) {
        return { ...state };
      }

      const aoiPolygonPointsWg = cloneDeep(state.aoiPolygonPointsWg);
      const allDrawnAoiPolygonsPointsWg = cloneDeep(
        state.allDrawnAoiPolygonsPointsWg
      );
      const allDrawnAoiPolygonsPointsUtm = cloneDeep(
        state.allDrawnAoiPolygonsPointsUtm
      );
      const allAoiInputCoords = cloneDeep(state.allAoiInputCoords);

      aoiPolygonPointsWg[idx][0] = Number(
        action.payload.pointCoords.lat.toFixed(6)
      );
      aoiPolygonPointsWg[idx][1] = Number(
        action.payload.pointCoords.lng.toFixed(6)
      );
      const pointUtm = reprojectWgToUtmPoint(
        {
          lat: action.payload.pointCoords.lat.toFixed(6),
          lng: action.payload.pointCoords.lng.toFixed(6),
        },
        state.crsDef
      );

      aoiPolygonPointsUtm[action.payload.idx] = {
        lat: pointUtm.lat,
        lng: pointUtm.lng,
      };

      if (idx === 0) {
        aoiInputCoords[idx].lat = pointUtm.lat;
        aoiInputCoords[idx].lng = pointUtm.lng;

        aoiInputCoords[idx + 1].lat = (
          aoiPolygonPointsUtm[idx + 1].lat - aoiPolygonPointsUtm[idx].lat
        ).toFixed(2);
        aoiInputCoords[idx + 1].lng = (
          aoiPolygonPointsUtm[idx + 1].lng - aoiPolygonPointsUtm[idx].lng
        ).toFixed(2);

        aoiInputCoords[idx + 1].lat = (
          aoiPolygonPointsUtm[idx + 1].lat - aoiPolygonPointsUtm[idx].lat
        ).toFixed(2);
        aoiInputCoords[idx + 1].lng = (
          aoiPolygonPointsUtm[idx + 1].lng - aoiPolygonPointsUtm[idx].lng
        ).toFixed(2);
      } else if (idx === aoiPolygonPointsUtm.length - 1) {
        aoiInputCoords[idx].lat = (
          aoiPolygonPointsUtm[idx].lat - aoiPolygonPointsUtm[idx - 1].lat
        ).toFixed(2);
        aoiInputCoords[idx].lng = (
          aoiPolygonPointsUtm[idx].lng - aoiPolygonPointsUtm[idx - 1].lng
        ).toFixed(2);
      } else {
        aoiInputCoords[idx + 1].lat = (
          aoiPolygonPointsUtm[idx + 1].lat - aoiPolygonPointsUtm[idx].lat
        ).toFixed(2);
        aoiInputCoords[idx + 1].lng = (
          aoiPolygonPointsUtm[idx + 1].lng - aoiPolygonPointsUtm[idx].lng
        ).toFixed(2);
        aoiInputCoords[idx].lat = (
          aoiPolygonPointsUtm[idx].lat - aoiPolygonPointsUtm[idx - 1].lat
        ).toFixed(2);
        aoiInputCoords[idx].lng = (
          aoiPolygonPointsUtm[idx].lng - aoiPolygonPointsUtm[idx - 1].lng
        ).toFixed(2);
      }

      allDrawnAoiPolygonsPointsWg[state.selectedAoiPolygonIndex] =
        aoiPolygonPointsWg;
      allDrawnAoiPolygonsPointsUtm[state.selectedAoiPolygonIndex] =
        aoiPolygonPointsUtm;
      allAoiInputCoords[state.selectedAoiPolygonIndex] = aoiInputCoords;
      return {
        ...state,
        aoiInputCoords: aoiInputCoords,
        aoiPolygonPointsUtm: aoiPolygonPointsUtm,
        allDrawnAoiPolygonsPointsWg: allDrawnAoiPolygonsPointsWg,
        allDrawnAoiPolygonsPointsUtm: allDrawnAoiPolygonsPointsUtm,
        aoiPolygonPointsWg: aoiPolygonPointsWg,
        allAoiInputCoords: allAoiInputCoords,
      };
    }

    case types.ADD_AOI_POINT_WG: {
      if (state.aoiPolygonDrawn || state.inAoiSelectMode) {
        return { ...state };
      }
      const aoiPolygonPointsWg = cloneDeep(state.aoiPolygonPointsWg);
      const aoiPolygonPointsUtm = cloneDeep(state.aoiPolygonPointsUtm);
      const aoiInputCoords = cloneDeep(state.aoiInputCoords);
      aoiPolygonPointsWg.push([action.payload.lat, action.payload.lng]);

      if (aoiPolygonPointsWg.length === 1) {
        const startCoordinateUtm = reprojectWgToUtmPoint(
          {
            lat: Number(aoiPolygonPointsWg[0][0]),
            lng: Number(aoiPolygonPointsWg[0][1]),
          },
          state.crsDef
        );
        aoiPolygonPointsUtm.push(startCoordinateUtm);

        aoiInputCoords[0] = startCoordinateUtm;
      } else {
        const currentPointUtm = reprojectWgToUtmPoint(
          {
            lat: Number(aoiPolygonPointsWg[aoiPolygonPointsWg.length - 1][0]),
            lng: Number(aoiPolygonPointsWg[aoiPolygonPointsWg.length - 1][1]),
          },
          state.crsDef
        );

        aoiPolygonPointsUtm.push(currentPointUtm);

        const previousUtmPoint = {
          lat: aoiPolygonPointsUtm[aoiPolygonPointsUtm.length - 2].lat,
          lng: aoiPolygonPointsUtm[aoiPolygonPointsUtm.length - 2].lng,
        };

        const latDiffUtm = Number(
          currentPointUtm.lat - previousUtmPoint.lat
        ).toFixed(2);

        const lngDiffUtm = Number(
          currentPointUtm.lng - previousUtmPoint.lng
        ).toFixed(2);

        aoiInputCoords[aoiPolygonPointsWg.length - 1] = {
          lat: latDiffUtm,
          lng: lngDiffUtm,
        };
      }

      return {
        ...state,
        aoiPolygonPointsWg: aoiPolygonPointsWg,
        aoiPolygonPointsUtm: aoiPolygonPointsUtm,
        aoiInputCoords: aoiInputCoords,
        canDrawAoiPolygon: true,
      };
    }

    case types.ADD_AOI_COORDS_ROW: {
      if (state.aoiPolygonPointsUtm.length === 0) {
        return { ...state };
      }

      const coordsIndex = action.payload;
      const aoiInputCoords = cloneDeep(state.aoiInputCoords);
      const aoiPolygonPointsWg = cloneDeep(state.aoiPolygonPointsWg);
      const aoiPolygonPointsUtm = cloneDeep(state.aoiPolygonPointsUtm);
      const prevPointWg = aoiPolygonPointsWg[coordsIndex];
      const allDrawnAoiPolygonsPointsWg = cloneDeep(
        state.allDrawnAoiPolygonsPointsWg
      );
      const allDrawnAoiPolygonsPointsUtm = cloneDeep(
        state.allDrawnAoiPolygonsPointsUtm
      );
      const allAoiInputCoords = cloneDeep(state.allAoiInputCoords);
      let nextPointWg = null;
      if (aoiInputCoords.length === coordsIndex + 1) {
        nextPointWg = aoiPolygonPointsWg[0];
      } else {
        nextPointWg = aoiPolygonPointsWg[coordsIndex + 1];
      }

      const addedPointWg = [
        ((Number(prevPointWg[0]) + Number(nextPointWg[0])) / 2).toFixed(6),
        ((Number(prevPointWg[1]) + Number(nextPointWg[1])) / 2).toFixed(6),
      ];

      let aoiInputCoordsPrev = {
        lat: (aoiInputCoords[coordsIndex].lat / 2).toFixed(2),
        lng: (aoiInputCoords[coordsIndex].lng / 2).toFixed(2),
      };

      if (coordsIndex === 0) {
        aoiInputCoordsPrev = {
          lat: (
            (aoiPolygonPointsUtm[1].lat - aoiPolygonPointsUtm[0].lat) /
            2
          ).toFixed(2),
          lng: (
            (aoiPolygonPointsUtm[1].lng - aoiPolygonPointsUtm[0].lng) /
            2
          ).toFixed(2),
        };
      } else if (coordsIndex === aoiInputCoords.length) {
        aoiInputCoordsPrev = {
          lat: (
            (aoiPolygonPointsUtm[coordsIndex].lat -
              aoiPolygonPointsUtm[0].lat) /
            2
          ).toFixed(2),
          lng: (
            (aoiPolygonPointsUtm[coordsIndex].lng -
              aoiPolygonPointsUtm[0].lng) /
            2
          ).toFixed(2),
        };
      }

      aoiInputCoords.splice(coordsIndex + 1, 0, aoiInputCoordsPrev);
      if (coordsIndex !== 0 && coordsIndex !== coordsIndex.length - 1) {
        aoiInputCoords[coordsIndex] = aoiInputCoordsPrev;
      }
      if (coordsIndex === 0) {
        aoiInputCoords.splice(coordsIndex + 2, 0, aoiInputCoordsPrev);
      }

      aoiPolygonPointsWg.splice(coordsIndex + 1, 0, addedPointWg);

      const addedPointUtm = reprojectWgToUtmPoint(
        {
          lat: Number(addedPointWg[0]),
          lng: Number(addedPointWg[1]),
        },
        state.crsDef
      );
      aoiPolygonPointsUtm.splice(coordsIndex + 1, 0, addedPointUtm);

      allDrawnAoiPolygonsPointsUtm[state.selectedPolygonIndex] =
        aoiPolygonPointsUtm;
      allDrawnAoiPolygonsPointsWg[state.selectedPolygonIndex] =
        aoiPolygonPointsWg;
      allAoiInputCoords[state.selectedPolygonIndex] = aoiInputCoords;

      return {
        ...state,
        aoiInputCoords: aoiInputCoords,
        aoiPolygonPointsUtm: aoiPolygonPointsUtm,
        aoiPolygonPointsWg: aoiPolygonPointsWg,
        allDrawnAoiPolygonsPointsWg: allDrawnAoiPolygonsPointsWg,
        allDrawnAoiPolygonsPointsUtm: allDrawnAoiPolygonsPointsUtm,
        allAoiInputCoords: allAoiInputCoords,
        tempPoiKey: "",
        tempPoiValue: "",
        drawnPolyAoiCoordsIndex: null,
      };
    }

    case types.REMOVE_AOI_COORDS_ROW: {
      const coordsIndex = action.payload;
      const aoiInputCoords = cloneDeep(state.aoiInputCoords);
      const aoiPolygonPointsWg = cloneDeep(state.aoiPolygonPointsWg);
      const aoiPolygonPointsUtm = cloneDeep(state.aoiPolygonPointsUtm);
      const allDrawnAoiPolygonsPointsWg = cloneDeep(
        state.allDrawnAoiPolygonsPointsWg
      );
      const allDrawnAoiPolygonsPointsUtm = cloneDeep(
        state.allDrawnAoiPolygonsPointsUtm
      );
      const allAoiInputCoords = cloneDeep(state.allAoiInputCoords);

      aoiPolygonPointsWg.splice(coordsIndex, 1);
      aoiPolygonPointsUtm.splice(coordsIndex, 1);

      if (coordsIndex !== 1) {
        aoiInputCoords[coordsIndex - 1] = {
          lat: (aoiInputCoords[coordsIndex - 1].lat * 2).toFixed(2),
          lng: (aoiInputCoords[coordsIndex - 1].lng * 2).toFixed(2),
        };
      } else if (coordsIndex === 1) {
        aoiInputCoords[coordsIndex + 1] = {
          lat: (aoiInputCoords[coordsIndex + 1].lat * 2).toFixed(2),
          lng: (aoiInputCoords[coordsIndex + 1].lng * 2).toFixed(2),
        };
      }
      aoiInputCoords.splice(coordsIndex, 1);
      allDrawnAoiPolygonsPointsWg[state.selectedAoiPolygonIndex] =
        aoiPolygonPointsWg;
      allDrawnAoiPolygonsPointsUtm[state.selectedAoiPolygonIndex] =
        aoiPolygonPointsUtm;
      allAoiInputCoords[state.selectedAoiPolygonIndex] = aoiInputCoords;

      return {
        ...state,
        aoiInputCoords: aoiInputCoords,
        aoiPolygonPointsWg: aoiPolygonPointsWg,
        aoiPolygonPointsUtm: aoiPolygonPointsUtm,
        allDrawnAoiPolygonsPointsWg: allDrawnAoiPolygonsPointsWg,
        allDrawnAoiPolygonsPointsUtm: allDrawnAoiPolygonsPointsUtm,
        allAoiInputCoords: allAoiInputCoords,
      };
    }

    case types.HANDLE_AOI_COORD_INPUT_CHANGE: {
      if (state.aoiPolygonPointsUtm.length === 0) {
        return { ...state };
      }
      let { name, value } = action.payload.e;
      value = Number(value);
      const drawnPolyAoiCoordsIndex = action.payload.drawnPolyAoiCoordsIndex;
      const aoiInputCoords = JSON.parse(JSON.stringify(state.aoiInputCoords));
      const aoiPolygonPointsWg = cloneDeep(state.aoiPolygonPointsWg);
      const aoiPolygonPointsUtm = cloneDeep(state.aoiPolygonPointsUtm);
      const allAoiInputCoords = cloneDeep(state.allAoiInputCoords);
      const allDrawnAoiPolygonsPointsWg = cloneDeep(
        state.allDrawnAoiPolygonsPointsWg
      );
      const allDrawnAoiPolygonsPointsUtm = cloneDeep(
        state.allDrawnAoiPolygonsPointsUtm
      );

      aoiInputCoords[drawnPolyAoiCoordsIndex][name] = value;

      if (drawnPolyAoiCoordsIndex === 0) {
        aoiPolygonPointsUtm[drawnPolyAoiCoordsIndex][name] = value;
      } else {
        aoiPolygonPointsUtm[drawnPolyAoiCoordsIndex][name] =
          Number(aoiPolygonPointsUtm[drawnPolyAoiCoordsIndex - 1][name]) +
          value;
      }
      aoiPolygonPointsWg[drawnPolyAoiCoordsIndex][0] = reprojectUtmToWgPoint(
        [
          Number(aoiPolygonPointsUtm[drawnPolyAoiCoordsIndex].lng),
          Number(aoiPolygonPointsUtm[drawnPolyAoiCoordsIndex].lat),
        ],
        state.crsDef
      ).lat;

      aoiPolygonPointsWg[drawnPolyAoiCoordsIndex][1] = reprojectUtmToWgPoint(
        [
          Number(aoiPolygonPointsUtm[drawnPolyAoiCoordsIndex].lng),
          Number(aoiPolygonPointsUtm[drawnPolyAoiCoordsIndex].lat),
        ],
        state.crsDef
      ).lng;

      allAoiInputCoords[state.selectedAoiPolygonIndex] = aoiInputCoords;
      allDrawnAoiPolygonsPointsWg[state.selectedAoiPolygonIndex] =
        aoiPolygonPointsWg;
      allDrawnAoiPolygonsPointsUtm[state.selectedAoiPolygonIndex] =
        aoiPolygonPointsUtm;

      return {
        ...state,
        tempPoiValue: "",
        tempPoiKey: "",
        drawnPolyAoiCoordsIndex: drawnPolyAoiCoordsIndex,
        aoiInputCoords: aoiInputCoords,
        aoiPolygonPointsUtm: aoiPolygonPointsUtm,
        aoiPolygonPointsWg: aoiPolygonPointsWg,
        allAoiInputCoords: allAoiInputCoords,
        allDrawnAoiPolygonsPointsWg: allDrawnAoiPolygonsPointsWg,
        allDrawnAoiPolygonsPointsUtm: allDrawnAoiPolygonsPointsUtm,
      };
    }

    case types.SELECT_AOI: {
      if (!state.inAoi || state.selectedAoiPolygonIndex === action.payload) {
        return {
          ...state,
        };
      }
      const selectedAoiPolygonIndex = action.payload;

      return {
        ...state,
        aoiPolygonPointsWg:
          state.allDrawnAoiPolygonsPointsWg[selectedAoiPolygonIndex],
        aoiPolygonPointsUtm:
          state.allDrawnAoiPolygonsPointsUtm[selectedAoiPolygonIndex],
        selectedAoiPolygonIndex: selectedAoiPolygonIndex,
        aoiInputCoords: state.allAoiInputCoords[action.payload],
      };
    }

    case types.ON_AREA_LAYER_NAME_CHANGE: {
      const inputValue = action.payload;
      const selectedAoiPolygonIndex = state.selectedAoiPolygonIndex;

      const arrayOfDrawnAoiLayerName = cloneDeep(
        state.arrayOfDrawnAoiLayerName
      );

      arrayOfDrawnAoiLayerName[selectedAoiPolygonIndex] = inputValue;

      return {
        ...state,
        arrayOfDrawnAoiLayerName: arrayOfDrawnAoiLayerName,
      };
    }

    case types.SET_RESULTS_GEOTIFF_VISIBILITY: {
      const resultsGeotiffName = action.payload.resultsGeotiffName;
      const currentLayerIndex = action.payload.currentLayerIndex;
      const resultsJsonOrigin = cloneDeep(state.resultsJsonOrigin);

      const currentViewIndex = cloneDeep(state.currentViewIndex);
      let resultsGeotiffIndex = cloneDeep(
        state.lastResultsGeotiffLayerIndexChanged
      );

      resultsGeotiffIndex = resultsJsonOrigin.pages[
        currentViewIndex
      ].lyrs.findIndex((x) => {
        return x.name === resultsGeotiffName;
      });

      if (
        resultsJsonOrigin.pages[currentViewIndex].lyrs[
          currentLayerIndex.checked === undefined
        ]
      ) {
        resultsJsonOrigin.pages[currentViewIndex].lyrs[
          resultsGeotiffIndex
        ].checked = true;
      } else {
        resultsJsonOrigin.pages[currentViewIndex].lyrs[
          resultsGeotiffIndex
        ].checked =
          !resultsJsonOrigin.pages[currentViewIndex].lyrs[resultsGeotiffIndex]
            .checked;
      }

      const legendVisibilityChanged = !state.legendVisibilityChanged;
      return {
        ...state,
        resultsJsonOrigin: resultsJsonOrigin,
        lastResultsGeotiffNameChanged: resultsGeotiffName,
        legendVisibilityChanged: legendVisibilityChanged,
        lastResultsGeotiffPageIndexChanged: state.currentViewIndex,
        lastResultsGeotiffLayerIndexChanged: resultsGeotiffIndex,
        currentLayerIndex: currentLayerIndex,
      };
    }

    case types.UPDATE_RESULTS_JSON_ORIGIN:
      return {
        ...state,
        resultsJsonOrigin: action.payload,
        inResultsMapView: true,
      };
    case types.SHOW_RESULTS_GEOTIFF_LEGEND:
      return {
        ...state,
        isResultsGeotiffLegendVisible: true,
      };
    case types.HIDE_RESULTS_GEOTIFF_LEGEND:
      return {
        ...state,
        isResultsGeotiffLegendVisible: false,
      };
    case types.TOGGLE_POI_VISIBILITY:
      return {
        ...state,
        inAoi: false,
        inPoi: false,
        isPoiVisible: !state.isPoiVisible,
      };
    case types.EXIT_RESULTS:
      return {
        ...initialState,
        inResultsMapView: false,
      };
    case types.LOAD_LAYER_SETTINGS_JSON:
      const layerSettingsJson = action.payload.data;
      const layerSettingsTypes = layerSettingsJson.map((x) => {
        return x.name;
      });
      const layerSettingsFileTypes = layerSettingsJson.map((x) => {
        return x.id;
      });

      let chartFileTypeOptions = layerSettingsTypes.filter(
        (x) => x !== "StaticDriver"
      );

      chartFileTypeOptions = chartFileTypeOptions.map((x) => {
        return { label: x, value: x };
      });
      const chartFileType = chartFileTypeOptions[0].value;
      const chartParamsOptions = layerSettingsJson[1].data.params.map((x) => {
        return { value: x.palmid, label: x.lankey };
      });

      const chartHeightLevelOptions =
        layerSettingsJson[1].data.params[0].heights;
      const chartHeightLevel = chartHeightLevelOptions[0];

      const chartTimestepOptions = layerSettingsJson[1].data.times;
      const chartTimestep = chartTimestepOptions[0];

      return {
        ...state,
        layerSettingsJson: layerSettingsJson,
        layerSettingsTypes: layerSettingsTypes,
        layerSettingsFileTypes: layerSettingsFileTypes,
        chartFileType: chartFileType,
        chartFileTypeOptions: chartFileTypeOptions,
        chartParams: chartParamsOptions[0].value,
        chartParamsIndex: 0,
        chartParamsOptions: chartParamsOptions,
        chartPoint: state.allDrawnPoiWg[0],
        chartHeightLevel: chartHeightLevel,
        chartHeightLevelOptions: chartHeightLevelOptions,
        chartTimestep: chartTimestep,

        chartTimestepOptions: chartTimestepOptions,
      };

    case types.TOGGLE_READ_ONLY_LAYER_FORM: {
      if (!state.inReadOnlyLayerForm) {
        const layerPropsJson = action.payload;

        const layerSettingsJson = state.layerSettingsJson;
        let type = null;
        let layerSettingsFormDataOptions = null;

        let params = null;
        const heightLevel = layerPropsJson.set_hlvl;
        const exclbldgs = layerPropsJson.set_exclbld;
        const processing = layerPropsJson.set_proc;
        let timestep = null;

        const paramsId = layerPropsJson.set_param;
        params = layerPropsJson.name;

        let layerSettingsFormDataOptionsSection = layerSettingsJson.find(
          (x) => {
            return x.data.params.find((y) => {
              return y.palmid === paramsId;
            });
          }
        );

        if (
          layerSettingsFormDataOptionsSection?.name !== "StaticDriver" &&
          layerSettingsJson
        ) {
          timestep = layerSettingsJson.find((x) => {
            return x?.data?.params.find((y) => {
              return y?.palmid === paramsId;
            });
          }).data.times[layerPropsJson.set_ts];
        }
        type = layerSettingsFormDataOptionsSection.name;

        layerSettingsFormDataOptions =
          layerSettingsFormDataOptionsSection.data.params.find((x) => {
            return x.palmid === paramsId;
          });
        layerSettingsFormDataOptions.timestep =
          layerSettingsFormDataOptionsSection.data.times;
        //}

        layerSettingsFormDataOptions.processing =
          layerSettingsFormDataOptions.postproc;

        const layerSettingsFormDataValue = {
          type: type,
          params: params,
          heights: heightLevel,
          timestep: timestep,
          exclbldgs: exclbldgs,
          processing: processing,
          name: layerPropsJson.name,
        };

        return {
          ...state,
          layerSettingsFormDataValue: layerSettingsFormDataValue,
          layerSettingsFormDataOptions: layerSettingsFormDataOptions,
          inReadOnlyLayerForm: !state.inReadOnlyLayerForm,
          inCreateLayerForm: false,
        };
      }

      return {
        ...state,
        inReadOnlyLayerForm: !state.inReadOnlyLayerForm,
        inCreateLayerForm: false,
      };
    }
    case types.TOGGLE_CREATE_LAYER_FORM: {
      if (state.inCreateLayerForm) {
        return {
          ...state,
          ...newLayerSettingsInitialState,
          inCreateLayerForm: false,
        };
      }
      const resultsJsonOrigin = cloneDeep(state.resultsJsonOrigin);
      const layerSettingsJson = cloneDeep(state.layerSettingsJson);
      const pageNames = cloneDeep(state.pageNames);
      let layerSettingsFormDataValue = null;
      let layerSettingsFormDataOptions = null;

      if (!state.inCreateLayerForm) {
        const initLayerSettingsData = layerSettingsJson[0].data.params[0];

        layerSettingsFormDataValue = {
          fileType: state.layerSettingsFileTypes[0],
          type: layerSettingsJson[0].name,
          params: initLayerSettingsData.lankey,
          heights: initLayerSettingsData.heights,
          heightsIndex: null,
          timestep: layerSettingsJson[0].data.times,
          timestepIndex: null,
          exclbldgs: initLayerSettingsData.exclbldgs[0],
          processing: initLayerSettingsData.postproc[0],
          resultsVariable: layerSettingsJson[0].data.params[0].palmid,
        };

        const paramsOptions = layerSettingsJson[0].data.params.map((x) => {
          return x.lankey;
        });
        layerSettingsFormDataOptions = cloneDeep(layerSettingsFormDataValue);
        layerSettingsFormDataOptions.params = paramsOptions;
        layerSettingsFormDataOptions.exclbldgs =
          initLayerSettingsData.exclbldgs;
        layerSettingsFormDataOptions.processing =
          initLayerSettingsData.postproc;
      }
      const resultsJsonCarouselOptions = cloneDeep(
        state.resultsJsonCarouselOptions
      );

      const diffPage = pageNames[0];
      const diffPageOptions = resultsJsonCarouselOptions.map((x) => {
        return { value: x, name: x };
      });
      diffPageOptions.splice(diffPageOptions.length - 2, 2);
      const diffLayer = layerSettingsFormDataValue.params;
      const diffPageJson = resultsJsonOrigin.pages.find((x) => {
        return x.name === diffPage;
      });
      const diffLayerOptions = diffPageJson.lyrs.map((x) => {
        return { value: x.name, name: x.name };
      });

      let spatFileTypeOptions = state.layerSettingsTypes.filter(
        (x) => x !== "StaticDriver"
      );

      spatFileTypeOptions = spatFileTypeOptions.map((x) => {
        return { name: x, value: x };
      });
      const spatFileType = spatFileTypeOptions[0].value;
      const spatParamsOptions = layerSettingsJson[1].data.params.map((x) => {
        return { value: x.palmid, name: x.lankey };
      });
      const spatTimeOptions = layerSettingsJson[1].data.times;
      const spatTime = spatTimeOptions[0];

      const spatHeightOptions = layerSettingsJson[1].data.params[0].heights;
      const spatHeight = spatHeightOptions[0];

      let spatAreaOptions = ["Domain"];
      if (state.allDrawnAoiPolygonsPointsUtm.length > 0) {
        state.arrayOfDrawnAoiLayerName.map((x) => {
          return spatAreaOptions.push(x);
        });
      }
      const spatArea = spatAreaOptions[0];
      const spatProcessingOptions = spatProcessOptions;
      const spatProcessing = spatProcessingOptions[0];

      // const layerSettingsWindfield = layerSettingsJson.filter((x) => {
      //   return x.visibility.isvisible_mapwind === false;
      // });

      const layerSettingsWindfield = [
        //layerSettingsJson[1],
        layerSettingsJson[2],
      ];

      const windfieldTypeOptions = layerSettingsWindfield.map((x) => {
        return { name: x.name, value: x.name };
      });

      //const windfieldFileType = windfieldTypeOptions[1].value;
      const windfieldFileType = windfieldTypeOptions[0].value;
      // const windfieldHeightOptions =
      //   layerSettingsJson[1].data.params[0].heights;
      const windfieldHeightOptions =
        layerSettingsJson[2].data.params[0].heights;
      const windfieldHeight = windfieldHeightOptions[0];

      const windfieldTimestepOptions = layerSettingsWindfield.map((x) => {
        return x.data.times;
      })[0];
      const windfieldTimestep = windfieldTimestepOptions[0];

      return {
        ...state,
        inCreateLayerForm: !state.inCreateLayerForm,
        layerSettingsFormDataValue: layerSettingsFormDataValue,
        layerSettingsFormDataOptions: layerSettingsFormDataOptions,
        inReadOnlyLayerForm: false,
        diffPage: diffPage,
        diffPageOptions: diffPageOptions,
        diffLayer: diffLayer,
        diffLayerOptions: diffLayerOptions,
        diffChildPage: diffPage,
        diffChildLayer: diffLayer,
        diffChildLayerOptions: diffLayerOptions,

        spatFileType: spatFileType,
        spatFileTypeOptions: spatFileTypeOptions,
        spatParams: spatParamsOptions[0].value,
        spatParamsOptions: spatParamsOptions,
        spatTimeFrom: spatTime,
        spatTimeTo: spatTime,
        spatTimeOptions: spatTimeOptions,
        spatHeightFrom: spatHeight,
        spatHeightTo: spatHeight,
        spatHeightOptions: spatHeightOptions,
        spatArea: spatArea,
        spatAreaOptions: spatAreaOptions,
        spatProcessing: spatProcessing,
        spatProcessingOptions: spatProcessingOptions,

        catCustomPage: diffPage,
        catCustomPageOptions: diffPageOptions,
        catCustomLayer: diffLayer,
        catCustomLayerOptions: diffLayerOptions,

        windfieldTypeOptions: windfieldTypeOptions,
        windfieldFileType: windfieldFileType,
        windfieldHeightOptions: windfieldHeightOptions,
        windfieldHeight: windfieldHeight,
        windfieldTimestepOptions: windfieldTimestepOptions,
        windfieldTimestep: windfieldTimestep,
      };
    }

    case types.CHANGE_NEW_LAYER_CASE: {
      let layerSettingsFormDataValue = cloneDeep(
        state.layerSettingsFormDataValue
      );
      layerSettingsFormDataValue.caseName = action.payload;

      return {
        ...state,
        layerSettingsFormDataValue: layerSettingsFormDataValue,
      };
    }
    case types.CHANGE_NEW_LAYER_TYPE: {
      const type = action.payload.value;
      const typeIndex = action.payload.selectedIndex;
      let layerSettingsFormDataValue = state.layerSettingsFormDataValue;
      let layerSettingsFormDataOptions = state.layerSettingsFormDataOptions;

      let layerOptionsSection = state.layerSettingsJson.find(
        (x) => x.name === type
      );

      let layerOptionsSubsection = layerOptionsSection.data.params[0];

      let layerParamsOptions = layerOptionsSection.data.params.map((x) => {
        return x.lankey;
      });

      let timestepOptions = layerOptionsSection.data.times;
      let heightsOptions = layerOptionsSubsection.heights;
      let exclbldgsOptions = layerOptionsSubsection.exclbldgs;
      let processingOptions = layerOptionsSubsection.postproc;

      layerSettingsFormDataOptions = {
        params: layerParamsOptions,
        heights: heightsOptions,
        timestep: timestepOptions,
        exclbldgs: exclbldgsOptions,
        processing: processingOptions,
      };

      layerSettingsFormDataValue = {
        fileType: state.layerSettingsFileTypes[typeIndex],
        type: type,
        params: layerParamsOptions[0],
        heights: heightsOptions === null ? null : heightsOptions[0],
        heightsIndex: heightsOptions === null ? null : 0,
        timestep: timestepOptions === null ? null : timestepOptions[0],
        timestepIndex: timestepOptions === null ? null : 0,
        exclbldgs: exclbldgsOptions[0],
        processing: processingOptions[0],
        resultsVariable: layerOptionsSubsection.palmid,
      };

      return {
        ...state,
        layerSettingsFormDataValue: layerSettingsFormDataValue,
        layerSettingsFormDataOptions: layerSettingsFormDataOptions,
      };
    }
    case types.CHANGE_NEW_LAYER_PARAMS: {
      const params = action.payload;

      let layerSettingsFormDataValue = state.layerSettingsFormDataValue;
      let layerSettingsFormDataOptions = state.layerSettingsFormDataOptions;

      let layerOptionsSection = state.layerSettingsJson.find(
        (x) => x.name === layerSettingsFormDataValue.type
      );

      let layerOptionsSubsection = layerOptionsSection.data.params.find((x) => {
        return x.lankey === params;
      });

      let timestepOptions = layerOptionsSection.data.times;
      let heightsOptions = layerOptionsSubsection.heights;
      let exclbldgsOptions = layerOptionsSubsection.exclbldgs;
      let processingOptions = layerOptionsSubsection.postproc;

      layerSettingsFormDataOptions = {
        type: layerSettingsFormDataOptions.type,
        params: layerSettingsFormDataOptions.params,
        heights: heightsOptions,
        timestep: timestepOptions,
        exclbldgs: exclbldgsOptions,
        processing: processingOptions,
      };

      layerSettingsFormDataValue = {
        fileType: layerSettingsFormDataValue.fileType,
        type: layerSettingsFormDataValue.type,
        params: params,
        heights: heightsOptions === null ? null : heightsOptions[0],
        heightsIndex: heightsOptions === null ? null : 0,
        timestep: timestepOptions === null ? null : timestepOptions[0],
        timestepIndex: timestepOptions === null ? null : 0,
        exclbldgs: exclbldgsOptions[0],
        processing: processingOptions[0],
        resultsVariable: layerOptionsSubsection.palmid,
      };

      return {
        ...state,
        layerSettingsFormDataValue: layerSettingsFormDataValue,
        layerSettingsFormDataOptions: layerSettingsFormDataOptions,
      };
    }

    case types.CHANGE_NEW_LAYER_HEIGHT_LEVEL: {
      let layerSettingsFormDataValue = cloneDeep(
        state.layerSettingsFormDataValue
      );
      layerSettingsFormDataValue.heights = action.payload.heights;
      layerSettingsFormDataValue.heightsIndex = action.payload.heightsIndex;

      return {
        ...state,
        layerSettingsFormDataValue: layerSettingsFormDataValue,
      };
    }

    case types.CHANGE_NEW_LAYER_TIMESTEP: {
      let layerSettingsFormDataValue = cloneDeep(
        state.layerSettingsFormDataValue
      );
      layerSettingsFormDataValue.timestep = action.payload.timestep;
      layerSettingsFormDataValue.timestepIndex = action.payload.timestepIndex;

      return {
        ...state,
        layerSettingsFormDataValue: layerSettingsFormDataValue,
      };
    }

    case types.CHANGE_NEW_LAYER_EXCL_BLDGS: {
      let layerSettingsFormDataValue = cloneDeep(
        state.layerSettingsFormDataValue
      );
      layerSettingsFormDataValue.exclbldgs = action.payload;

      return {
        ...state,
        layerSettingsFormDataValue: layerSettingsFormDataValue,
      };
    }
    case types.CHANGE_NEW_LAYER_POSTPROC: {
      let layerSettingsFormDataValue = cloneDeep(
        state.layerSettingsFormDataValue
      );
      layerSettingsFormDataValue.processing = action.payload;

      return {
        ...state,
        layerSettingsFormDataValue: layerSettingsFormDataValue,
      };
    }

    case types.TOGGLE_LAYER_APPEARANCE: {
      if (!state.inLayerAppearance) {
        return {
          ...state,
          inLayerAppearance: !state.inLayerAppearance,
          jsonOriginLayerIndex: action.payload.jsonOriginLayerIndex,
          jsonOriginPartSelected: action.payload.jsonOriginPartSelected,
        };
      }

      return {
        ...state,
        inLayerAppearance: !state.inLayerAppearance,
      };
    }

    case types.BRING_GEOTIFF_TO_FRONT: {
      let resultsJsonOrigin = cloneDeep(state.resultsJsonOrigin);
      let jsonOriginLayerIndex = cloneDeep(state.jsonOriginLayerIndex);
      // const nrOfLayersInPage =
      //   resultsJsonOrigin.pages[state.currentViewIndex].lyrs.length;

      const layerToFront =
        resultsJsonOrigin.pages[state.currentViewIndex].lyrs[
          jsonOriginLayerIndex
        ];

      layerToFront.checked = true;

      resultsJsonOrigin.pages[state.currentViewIndex].lyrs.splice(
        jsonOriginLayerIndex,
        1
      );
      resultsJsonOrigin.pages[state.currentViewIndex].lyrs.unshift(
        layerToFront
      );

      return {
        ...state,
        resultsJsonOrigin: resultsJsonOrigin,
        jsonOriginLayerIndex: 0,
      };
    }

    case types.BRING_GEOTIFF_BACKWARD: {
      let selectedLayerIndex = action.payload;
      let resultsJsonOrigin = cloneDeep(state.resultsJsonOrigin);

      const layerForward =
        resultsJsonOrigin.pages[state.currentViewIndex].lyrs[
          selectedLayerIndex
        ];
      const layerBackward =
        resultsJsonOrigin.pages[state.currentViewIndex].lyrs[
          selectedLayerIndex + 1
        ];

      resultsJsonOrigin.pages[state.currentViewIndex].lyrs[selectedLayerIndex] =
        layerBackward;
      resultsJsonOrigin.pages[state.currentViewIndex].lyrs[
        selectedLayerIndex + 1
      ] = layerForward;

      return {
        ...state,
        resultsJsonOrigin: resultsJsonOrigin,
      };
    }

    case types.BRING_GEOTIFF_FORWARD: {
      let selectedLayerIndex = action.payload;
      let resultsJsonOrigin = cloneDeep(state.resultsJsonOrigin);

      const layerForward =
        resultsJsonOrigin.pages[state.currentViewIndex].lyrs[
          selectedLayerIndex
        ];
      const layerBackward =
        resultsJsonOrigin.pages[state.currentViewIndex].lyrs[
          selectedLayerIndex - 1
        ];

      resultsJsonOrigin.pages[state.currentViewIndex].lyrs[selectedLayerIndex] =
        layerBackward;
      resultsJsonOrigin.pages[state.currentViewIndex].lyrs[
        selectedLayerIndex - 1
      ] = layerForward;

      return {
        ...state,
        resultsJsonOrigin: resultsJsonOrigin,
      };
    }
    case types.DELETE_LAYER: {
      let selectedLayerIndex = action.payload;
      let resultsJsonOrigin = cloneDeep(state.resultsJsonOrigin);

      resultsJsonOrigin.pages[state.currentViewIndex].lyrs.splice(
        selectedLayerIndex,
        1
      );
      return {
        ...state,
        resultsJsonOrigin: resultsJsonOrigin,
      };
    }

    case types.CENTER_MAP: {
      return {
        ...state,
        isCentering: true,
      };
    }

    case types.CENTER_MAP_SUCCESS: {
      return {
        ...state,
        isCentering: false,
      };
    }

    case types.SET_LAYER_COLOR_PALETTE: {
      const colorPaletteName = action.payload;
      const resultsJsonOrigin = cloneDeep(state.resultsJsonOrigin);
      const jsonOriginPartSelected = cloneDeep(state.jsonOriginPartSelected);
      const currentPageIndex = state.currentViewIndex;
      const jsonOriginLayerIndex = state.jsonOriginLayerIndex;

      resultsJsonOrigin.pages[currentPageIndex].lyrs[
        jsonOriginLayerIndex
      ].plot_color = colorPaletteName;

      jsonOriginPartSelected.plot_color = colorPaletteName;

      return {
        ...state,
        resultsJsonOrigin: resultsJsonOrigin,
        jsonOriginPartSelected: jsonOriginPartSelected,
      };
    }

    case types.SET_LAYER_CATEGORY_COLOR: {
      const color = action.payload.color;
      const indexOfCategory = action.payload.indexOfCategory;

      const resultsJsonOrigin = cloneDeep(state.resultsJsonOrigin);
      const jsonOriginPartSelected = cloneDeep(state.jsonOriginPartSelected);
      const currentPageIndex = state.currentViewIndex;
      const jsonOriginLayerIndex = state.jsonOriginLayerIndex;

      if (!jsonOriginPartSelected.set_sl) {
        resultsJsonOrigin.pages[currentPageIndex].lyrs[
          jsonOriginLayerIndex
        ].plot_preset_cols[indexOfCategory] = color;

        jsonOriginPartSelected.plot_preset_cols[indexOfCategory] = color;
        return {
          ...state,
          resultsJsonOrigin: resultsJsonOrigin,
          jsonOriginPartSelected: jsonOriginPartSelected,
        };
      }

      resultsJsonOrigin.pages[currentPageIndex].lyrs[
        jsonOriginLayerIndex
      ].plot_preset_cols[indexOfCategory] = color;
      resultsJsonOrigin.pages[currentPageIndex].lyrs[
        jsonOriginLayerIndex
      ].plot_preset_cols[indexOfCategory + 1] = color;

      jsonOriginPartSelected.plot_preset_cols[indexOfCategory] = color;
      jsonOriginPartSelected.plot_preset_cols[indexOfCategory + 1] = color;

      return {
        ...state,
        resultsJsonOrigin: resultsJsonOrigin,
        jsonOriginPartSelected: jsonOriginPartSelected,
      };
    }

    case types.SET_LAYER_OPACITY: {
      const opacity = action.payload;
      const resultsJsonOrigin = cloneDeep(state.resultsJsonOrigin);
      const jsonOriginPartSelected = cloneDeep(state.jsonOriginPartSelected);
      const currentPageIndex = state.currentViewIndex;
      const jsonOriginLayerIndex = state.jsonOriginLayerIndex;

      resultsJsonOrigin.pages[currentPageIndex].lyrs[
        jsonOriginLayerIndex
      ].plot_opacity = opacity;

      jsonOriginPartSelected.plot_opacity = opacity;

      return {
        ...state,
        resultsJsonOrigin: resultsJsonOrigin,
        jsonOriginPartSelected: jsonOriginPartSelected,
      };
    }

    case types.SET_LAYER_MIN: {
      const min = action.payload;
      const resultsJsonOrigin = cloneDeep(state.resultsJsonOrigin);
      const jsonOriginPartSelected = cloneDeep(state.jsonOriginPartSelected);
      const currentPageIndex = state.currentViewIndex;
      const jsonOriginLayerIndex = state.jsonOriginLayerIndex;

      resultsJsonOrigin.pages[currentPageIndex].lyrs[
        jsonOriginLayerIndex
      ].plot_min = min;

      jsonOriginPartSelected.plot_min = min;

      return {
        ...state,
        resultsJsonOrigin: resultsJsonOrigin,
        jsonOriginPartSelected: jsonOriginPartSelected,
      };
    }

    case types.SET_LAYER_MAX: {
      const max = action.payload;
      const resultsJsonOrigin = cloneDeep(state.resultsJsonOrigin);
      const jsonOriginPartSelected = cloneDeep(state.jsonOriginPartSelected);
      const currentPageIndex = state.currentViewIndex;
      const jsonOriginLayerIndex = state.jsonOriginLayerIndex;

      resultsJsonOrigin.pages[currentPageIndex].lyrs[
        jsonOriginLayerIndex
      ].plot_max = max;

      jsonOriginPartSelected.plot_max = max;

      return {
        ...state,
        resultsJsonOrigin: resultsJsonOrigin,
        jsonOriginPartSelected: jsonOriginPartSelected,
      };
    }

    case types.DELETE_PAGE: {
      let resultsJsonOrigin = cloneDeep(state.resultsJsonOrigin);
      let resultsJsonCarouselOptions = cloneDeep(
        state.resultsJsonCarouselOptions
      );
      const pageNames = cloneDeep(state.pageNames);
      const currentViewIndex = cloneDeep(state.currentViewIndex);
      resultsJsonOrigin.pages.splice(currentViewIndex, 1);
      resultsJsonCarouselOptions.splice(currentViewIndex, 1);
      pageNames.splice(currentViewIndex, 1);
      let currentActiveResultsView =
        resultsJsonCarouselOptions[currentViewIndex - 1];
      return {
        ...state,
        resultsJsonOrigin: resultsJsonOrigin,
        jsonOriginPartSelected: null,
        currentViewIndex: state.currentViewIndex - 1,
        resultsJsonCarouselOptions: resultsJsonCarouselOptions,
        pageNames: pageNames,
        currentActiveResultsView: currentActiveResultsView,
        jsonOriginLayerIndex: 0,
      };
    }

    case types.CREATE_PAGE: {
      const { newPageName, newPageDescription } = action.payload;
      let resultsJsonOrigin = cloneDeep(state.resultsJsonOrigin);
      const pageNames = cloneDeep(state.pageNames);

      const lastId = resultsJsonOrigin.pages.length - 1;

      const newId = `${lastId + 1}`;

      const newPage = {
        id: newId,
        name: newPageName,
        desc: newPageDescription,
        type: "Map",
        icon: "UserPage",
        src: "custom",
        lyrs: [],
      };

      resultsJsonOrigin.pages.splice(
        resultsJsonOrigin.pages.length - 2,
        0,
        newPage
      );

      let resultsJsonCarouselOptions = cloneDeep(
        state.resultsJsonCarouselOptions
      );
      resultsJsonCarouselOptions.splice(
        resultsJsonCarouselOptions.length - 2,
        0,
        newPageName
      );

      const currentViewIndex = resultsJsonOrigin.pages.length - 3;
      pageNames.splice(pageNames.length - 2, 0, newPageName);
      return {
        ...state,
        resultsJsonOrigin: resultsJsonOrigin,
        currentViewIndex: currentViewIndex,
        currentActiveResultsView: newPageName,
        resultsJsonCarouselOptions: resultsJsonCarouselOptions,
        pageNames: pageNames,
        inLayerSettings: true,
      };
    }

    case types.INVERT_LAYER_COLOR: {
      const resultsJsonOrigin = cloneDeep(state.resultsJsonOrigin);
      const jsonOriginPartSelected = cloneDeep(state.jsonOriginPartSelected);
      const currentViewIndex = cloneDeep(state.currentViewIndex);
      const jsonOriginLayerIndex = cloneDeep(state.jsonOriginLayerIndex);

      resultsJsonOrigin.pages[currentViewIndex].lyrs[
        jsonOriginLayerIndex
      ].plot_colinv =
        !resultsJsonOrigin.pages[currentViewIndex].lyrs[jsonOriginLayerIndex]
          .plot_colinv;

      jsonOriginPartSelected.plot_colinv = !jsonOriginPartSelected.plot_colinv;
      if (jsonOriginPartSelected.plot_colinv) {
        resultsJsonOrigin.pages[currentViewIndex].lyrs[
          jsonOriginLayerIndex
        ].plot_color =
          "inv" +
          resultsJsonOrigin.pages[currentViewIndex].lyrs[jsonOriginLayerIndex]
            .plot_color;

        jsonOriginPartSelected.plot_color =
          "inv" + jsonOriginPartSelected.plot_color;
      } else {
        resultsJsonOrigin.pages[currentViewIndex].lyrs[
          jsonOriginLayerIndex
        ].plot_color = resultsJsonOrigin.pages[currentViewIndex].lyrs[
          jsonOriginLayerIndex
        ].plot_color.replace("inv", "");

        jsonOriginPartSelected.plot_color =
          jsonOriginPartSelected.plot_color.replace("inv", "");
      }

      return {
        ...state,
        resultsJsonOrigin: resultsJsonOrigin,
        jsonOriginPartSelected: jsonOriginPartSelected,
      };
    }

    case types.ADD_NEW_LAYER: {
      const resultsJsonOrigin = cloneDeep(state.resultsJsonOrigin);
      const currentViewIndex = cloneDeep(state.currentViewIndex);

      resultsJsonOrigin.pages[currentViewIndex].lyrs.unshift(action.payload);
      return {
        ...state,
        resultsJsonOrigin: resultsJsonOrigin,
      };
    }

    case types.VALIDATE_NEW_PAGE_NAME: {
      const pageName = action.payload;
      if (pageName.length < 4 || pageName.length > 30) {
        return {
          ...state,
          pageNameError: "PagaNameLengthError",
          isPageNameValid: false,
        };
      } else if (state.pageNames.includes(pageName)) {
        return {
          ...state,
          pageNameError: "PageNameDuplicateError",
          isPageNameValid: false,
        };
      }

      return {
        ...state,
        isPageNameValid: true,
        pageNameError: null,
      };
    }

    case types.CHANGE_NEW_LAYER_TYPE_FIELD: {
      if (action.payload === "Difference") {
        return {
          ...state,
          newLayerTypeField: action.payload,
          diffCaseResultsJsonOrigin: state.resultsJsonOrigin,
          diffChildCaseResultsJsonOrigin: state.resultsJsonOrigin,
        };
      }

      return {
        ...state,
        newLayerTypeField: action.payload,
      };
    }

    case types.CHANGE_NEW_LAYER_DIFF_CASE: {
      return {
        ...state,
        diffCase: action.payload.diffCaseName,
        diffCaseId: action.payload.diffCaseId,
      };
    }

    case types.LOAD_NEW_LAYER_DIFF_CASE_DATA: {
      const diffCaseResultsJsonOrigin = action.payload;

      const diffPage = diffCaseResultsJsonOrigin.pages[0].name;
      const diffPageOptions = diffCaseResultsJsonOrigin.pages.map((x) => {
        return { name: x.name, value: x.name };
      });

      const diffLayer = diffCaseResultsJsonOrigin.pages[0].lyrs[0].name;
      const diffLayerOptions = diffCaseResultsJsonOrigin.pages[0].lyrs.map(
        (x) => {
          return { name: x.name, value: x.name };
        }
      );

      return {
        ...state,
        diffCaseResultsJsonOrigin: diffCaseResultsJsonOrigin,
        diffPage: diffPage,
        diffPageOptions: diffPageOptions,
        diffPageIndex: 0,
        diffLayer: diffLayer,
        diffLayerOptions: diffLayerOptions,
        diffLayerIndex: 0,
      };
    }

    case types.CHANGE_NEW_LAYER_DIFF_PAGE: {
      const diffPage = action.payload.diffPage;
      const diffPageIndex = action.payload.diffPageIndex;
      const resultsJsonOrigin = cloneDeep(state.resultsJsonOrigin);
      const selectedPageIndex = resultsJsonOrigin.pages.findIndex((x) => {
        return x.name === diffPage;
      });

      let diffLayer = resultsJsonOrigin.pages[selectedPageIndex].lyrs[0].name;
      const diffLayerOptions = resultsJsonOrigin.pages[
        selectedPageIndex
      ].lyrs.map((x) => {
        return { name: x.name, value: x.name };
      });
      return {
        ...state,
        diffPage: diffPage,
        diffPageIndex: diffPageIndex,
        diffLayer: diffLayer,
        diffLayerIndex: 0,
        diffLayerOptions: diffLayerOptions,
      };
    }

    case types.CHANGE_NEW_DIFF_LAYER: {
      const diffLayer = action.payload.diffLayer;
      const diffLayerIndex = action.payload.diffLayerIndex;
      return {
        ...state,
        diffLayer: diffLayer,
        diffLayerIndex: diffLayerIndex,
      };
    }

    case types.LOAD_NEW_LAYER_DIFF_CHILD_CASE_DATA: {
      const diffChildCaseResultsJsonOrigin = action.payload;

      const diffChildPage = diffChildCaseResultsJsonOrigin.pages[0].name;
      const diffChildPageOptions = diffChildCaseResultsJsonOrigin.pages.map(
        (x) => {
          return { name: x.name, value: x.name };
        }
      );

      const diffChildLayer =
        diffChildCaseResultsJsonOrigin.pages[0].lyrs[0].name;
      const diffChildLayerOptions =
        diffChildCaseResultsJsonOrigin.pages[0].lyrs.map((x) => {
          return { name: x.name, value: x.name };
        });

      return {
        ...state,
        diffChildCaseResultsJsonOrigin: diffChildCaseResultsJsonOrigin,
        diffChildPage: diffChildPage,
        diffChildPageOptions: diffChildPageOptions,
        diffChildPageIndex: 0,
        diffChildLayer: diffChildLayer,
        diffChildLayerOptions: diffChildLayerOptions,
        diffChildLayerIndex: 0,
      };
    }

    case types.CHANGE_NEW_LAYER_DIFF_CHILD_CASE: {
      return {
        ...state,
        diffChildCase: action.payload.diffChildCaseName,
        diffChildCaseId: action.payload.diffChildCaseId,
      };
    }

    case types.CHANGE_NEW_LAYER_DIFF_CHILD_PAGE: {
      const diffChildPage = action.payload.diffChildPage;
      const diffChildPageIndex = action.payload.diffChildPageIndex;
      const resultsJsonOrigin = cloneDeep(state.resultsJsonOrigin);
      const selectedPageIndex = resultsJsonOrigin.pages.findIndex((x) => {
        return x.name === diffChildPage;
      });

      let diffChildLayer =
        resultsJsonOrigin.pages[selectedPageIndex].lyrs[0].name;

      const diffChildLayerOptions = resultsJsonOrigin.pages[
        selectedPageIndex
      ].lyrs.map((x) => {
        return { name: x.name, value: x.name };
      });

      return {
        ...state,
        diffChildPage: diffChildPage,
        diffChildPageIndex: diffChildPageIndex,
        diffChildLayer: diffChildLayer,
        diffChildLayerIndex: 0,
        diffChildLayerOptions: diffChildLayerOptions,
      };
    }

    case types.CHANGE_NEW_DIFF_CHILD_LAYER: {
      return {
        ...state,
        diffChildLayer: action.payload.diffChildLayer,
        diffChildLayerIndex: action.payload.diffChildLayerIndex,
      };
    }

    case types.CHANGE_SPAT_CASE: {
      return {
        ...state,
        diffChildLayer: action.payload,
      };
    }

    case types.CHANGE_SPAT_FILE_TYPE: {
      const fileType = action.payload.fileType;
      const selectedIndex = action.payload.selectedIndex + 1;

      const layerSettingsJson = cloneDeep(state.layerSettingsJson);
      const selectedSettingsJson = layerSettingsJson[selectedIndex];
      const spatParamsOptions = selectedSettingsJson.data.params.map((x) => {
        return { value: x.palmid, name: x.lankey };
      });
      const spatTimeOptions = selectedSettingsJson.data.times;
      const spatHeightOptions = selectedSettingsJson.data.params[0].heights;

      const spatTime = {
        value: spatTimeOptions[0],
        index: 0,
      };
      const spatHeight = {
        value: spatHeightOptions[0],
        index: 0,
      };

      return {
        ...state,
        spatFileType: fileType,
        spatParams: spatParamsOptions[0].value,
        spatParamsOptions: spatParamsOptions,
        spatTimeFrom: spatTime,
        spatTimeTo: spatTime,
        spatTimeOptions: spatTimeOptions,
        spatHeightFrom: spatHeight,
        spatHeightTo: spatHeight,
        spatHeightOptions: spatHeightOptions,
        spatArea: state.spatAreaOptions[0],
        spatProcessing: spatProcessOptions[0],
      };
    }
    case types.CHANGE_SPAT_PARAMS: {
      const spatParams = action.payload.selectedParams;
      const paramsIndex = action.payload.paramsIndex;
      const layerSettingsJson = cloneDeep(state.layerSettingsJson);
      const spatFileType = cloneDeep(state.spatFileType);

      const layerOptionsSection = layerSettingsJson.find(
        (x) => x.name === spatFileType
      );
      const spatTimeOptions = layerOptionsSection.data.times;
      const spatHeightOptions =
        layerOptionsSection.data.params[paramsIndex].heights;
      const spatTime = {
        value: spatTimeOptions[0],
        index: 0,
      };
      const spatHeight = {
        value: spatHeightOptions[0],
        index: 0,
      };

      return {
        ...state,
        spatParams: spatParams,
        spatTimeFrom: spatTime,
        spatTimeTo: spatTime,
        spatTimeOptions: spatTimeOptions,
        spatHeightFrom: spatHeight,
        spatHeightTo: spatHeight,
        spatHeightOptions: spatHeightOptions,
        spatArea: state.spatAreaOptions[0],
        spatProcessing: spatProcessOptions[0],
      };
    }

    case types.CHANGE_SPAT_HEIGHT_FROM: {
      const spatHeightFrom = {
        value: action.payload.value,
        index: action.payload.index,
      };
      return {
        ...state,
        spatHeightFrom: spatHeightFrom,
      };
    }
    case types.CHANGE_SPAT_HEIGHT_TO: {
      const spatHeightTo = {
        value: action.payload.value,
        index: action.payload.index,
      };
      return {
        ...state,
        spatHeightTo: spatHeightTo,
      };
    }
    case types.CHANGE_SPAT_TIME_FROM: {
      const spatTimeFrom = {
        value: action.payload.value,
        index: action.payload.index,
      };
      return {
        ...state,
        spatTimeFrom: spatTimeFrom,
      };
    }

    case types.CHANGE_SPAT_TIME_TO: {
      const spatTimeTo = {
        value: action.payload.value,
        index: action.payload.index,
      };
      return {
        ...state,
        spatTimeTo: spatTimeTo,
      };
    }

    case types.CHANGE_SPAT_AREA: {
      return {
        ...state,
        spatArea: action.payload,
      };
    }

    case types.CHANGE_SPAT_PROCESSING: {
      return {
        ...state,
        spatProcessing: action.payload,
      };
    }

    case types.CHANGE_CAT_CUSTOM_PAGE: {
      const catCustomPage = action.payload.catCustomPage;
      const catCustomPageIndex = action.payload.catCustomPageIndex;

      const resultsJsonOrigin = cloneDeep(state.resultsJsonOrigin);
      const selectedPageIndex = resultsJsonOrigin.pages.findIndex((x) => {
        return x.name === catCustomPage;
      });

      let catCustomLayer =
        resultsJsonOrigin.pages[selectedPageIndex].lyrs[0].name;
      const catCustomLayerOptions = resultsJsonOrigin.pages[
        selectedPageIndex
      ].lyrs.map((x) => {
        return { name: x.name, value: x.name };
      });
      return {
        ...state,
        catCustomPage: catCustomPage,
        catCustomPageIndex: catCustomPageIndex,
        catCustomLayer: catCustomLayer,
        catCustomLayerIndex: 0,
        catCustomLayerOptions: catCustomLayerOptions,
      };
    }

    case types.CHANGE_CAT_CUSTOM_LAYER: {
      return {
        ...state,
        catCustomLayer: action.payload.catCustomLayer,
        catCustomLayerIndex: action.payload.catCustomLayerIndex,
      };
    }

    case types.CHANGE_CAT_CUSTOM_NUMBER: {
      return {
        ...state,
        catCustomNr: action.payload,
      };
    }
    case types.CHANGE_CAT_CUSTOM_COLOR_PALETTE: {
      return {
        ...state,
        catCustomColorPalette: action.payload,
        isCatCustomColorPaletteInverted: false,
      };
    }

    case types.RESET_NEW_LAYER_SETTINGS: {
      return {
        ...state,
        ...newLayerSettingsInitialState,
      };
    }

    case types.CHANGE_CUSTOM_LAYER_BREAK_NAME: {
      const breakName = action.payload.breakName;
      const indexOfBreak = action.payload.indexOfBreak;

      const resultsJsonOrigin = cloneDeep(state.resultsJsonOrigin);
      const jsonOriginPartSelected = cloneDeep(state.jsonOriginPartSelected);
      const currentPageIndex = state.currentViewIndex;
      const jsonOriginLayerIndex = state.jsonOriginLayerIndex;

      resultsJsonOrigin.pages[currentPageIndex].lyrs[
        jsonOriginLayerIndex
      ].plot_preset_names[indexOfBreak] = breakName;
      jsonOriginPartSelected.plot_preset_names[indexOfBreak] = breakName;

      return {
        ...state,
        resultsJsonOrigin: resultsJsonOrigin,
        jsonOriginPartSelected: jsonOriginPartSelected,
      };
    }

    case types.CHANGE_CUSTOM_LAYER_BREAK_VALUE: {
      const breakValue = action.payload.breakValue;
      const indexOfBreak = action.payload.indexOfBreak;

      const resultsJsonOrigin = cloneDeep(state.resultsJsonOrigin);
      const jsonOriginPartSelected = cloneDeep(state.jsonOriginPartSelected);
      const currentPageIndex = state.currentViewIndex;
      const jsonOriginLayerIndex = state.jsonOriginLayerIndex;

      resultsJsonOrigin.pages[currentPageIndex].lyrs[
        jsonOriginLayerIndex
      ].plot_preset_breaks[indexOfBreak] = breakValue;
      jsonOriginPartSelected.plot_preset_breaks[indexOfBreak] = breakValue;

      const newValueBreaks = jsonOriginPartSelected.plot_preset_breaks;
      const diffValue =
        newValueBreaks[newValueBreaks.length - 1] - newValueBreaks[0];
      const newPercBreak = [];

      for (let index = 0; index < newValueBreaks.length; index++) {
        if (index === 0) {
          newPercBreak.push(0);
        } else if (index === newValueBreaks - 1) {
          newPercBreak.push(1);
        } else {
          newPercBreak.push(
            (
              (newValueBreaks[index] - newValueBreaks[index - 1]) /
              diffValue
            ).toFixed(2)
          );
        }
      }

      resultsJsonOrigin.pages[currentPageIndex].lyrs[
        jsonOriginLayerIndex
      ].plot_preset_breaks_perc = newPercBreak;
      jsonOriginPartSelected.plot_preset_breaks_perc = newPercBreak;

      return {
        ...state,
        resultsJsonOrigin: resultsJsonOrigin,
        jsonOriginPartSelected: jsonOriginPartSelected,
      };
    }

    case types.CHANGE_CUSTOM_LAYER_MIN: {
      const layerMin = Number(action.payload);
      const resultsJsonOrigin = cloneDeep(state.resultsJsonOrigin);
      const jsonOriginPartSelected = cloneDeep(state.jsonOriginPartSelected);
      const jsonOriginLayerIndex = state.jsonOriginLayerIndex;
      const currentPageIndex = state.currentViewIndex;

      resultsJsonOrigin.pages[currentPageIndex].lyrs[
        jsonOriginLayerIndex
      ].plot_min = layerMin;

      jsonOriginPartSelected.plot_min = layerMin;

      return {
        ...state,
        resultsJsonOrigin: resultsJsonOrigin,
        jsonOriginPartSelected: jsonOriginPartSelected,
      };
    }

    case types.CHANGE_DIFF_TYPE: {
      return {
        ...state,
        diffType: action.payload,
      };
    }
    case types.CHANGE_DIFF_UNIT: {
      return {
        ...state,
        diffUnit: action.payload,
      };
    }
    case types.CHANGE_WINDFIELD_CASE: {
      return {
        ...state,
        diffUnit: action.payload,
      };
    }
    case types.CHANGE_WINDFIELD_FILE_TYPE: {
      const layerSettingsJson =
        state.layerSettingsJson[action.payload.selectedIndex + 1];

      const windfieldHeightOptions = layerSettingsJson.data.params[0].heights;
      const windfieldHeight = { value: windfieldHeightOptions[0], index: 0 };
      const windfieldTimestepOptions = layerSettingsJson.data.times;
      const windfieldTimestep = {
        value: windfieldTimestepOptions[0],
        index: 0,
      };

      return {
        ...state,
        windfieldFileType: action.payload.fileType,
        windfieldHeightOptions: windfieldHeightOptions,
        windfieldHeight: windfieldHeight,
        windfieldTimestepOptions: windfieldTimestepOptions,
        windfieldTimestep: windfieldTimestep,
      };
    }
    case types.CHANGE_WINDFIELD_PARAMS: {
      return {
        ...state,
        windfieldParams: action.payload,
      };
    }
    case types.CHANGE_WINDFIELD_HEIGHT: {
      const windfieldHeight = {
        value: action.payload.value,
        index: action.payload.index,
      };
      return {
        ...state,
        windfieldHeight: windfieldHeight,
      };
    }
    case types.CHANGE_WINDFIELD_TIMESTEP: {
      const windfieldTimestep = {
        value: action.payload.value,
        index: action.payload.index,
      };
      return {
        ...state,
        windfieldTimestep: windfieldTimestep,
      };
    }
    case types.INVERT_CAT_CUSTOM_COLOR_PALETTE: {
      return {
        ...state,
        isCatCustomColorPaletteInverted: !state.isCatCustomColorPaletteInverted,
      };
    }
    case types.CHANGE_WIND_STREAM_COLOR: {
      const color = action.payload;
      const resultsJsonOrigin = cloneDeep(state.resultsJsonOrigin);
      const jsonOriginPartSelected = cloneDeep(state.jsonOriginPartSelected);
      const currentPageIndex = state.currentViewIndex;
      const jsonOriginLayerIndex = state.jsonOriginLayerIndex;

      resultsJsonOrigin.pages[currentPageIndex].lyrs[
        jsonOriginLayerIndex
      ].plot_color = color;

      jsonOriginPartSelected.plot_color = color;

      return {
        ...state,
        resultsJsonOrigin: resultsJsonOrigin,
        jsonOriginPartSelected: jsonOriginPartSelected,
      };
    }
    case types.CHANGE_WIND_STREAM_PATHS: {
      const paths = action.payload;
      const resultsJsonOrigin = cloneDeep(state.resultsJsonOrigin);
      const jsonOriginPartSelected = cloneDeep(state.jsonOriginPartSelected);
      const currentPageIndex = state.currentViewIndex;
      const jsonOriginLayerIndex = state.jsonOriginLayerIndex;

      resultsJsonOrigin.pages[currentPageIndex].lyrs[
        jsonOriginLayerIndex
      ].plot_paths = paths;

      jsonOriginPartSelected.plot_paths = paths;

      return {
        ...state,
        resultsJsonOrigin: resultsJsonOrigin,
        jsonOriginPartSelected: jsonOriginPartSelected,
      };
    }
    case types.CHANGE_WIND_STREAM_MAX_AGE: {
      const maxAge = action.payload;
      const resultsJsonOrigin = cloneDeep(state.resultsJsonOrigin);
      const jsonOriginPartSelected = cloneDeep(state.jsonOriginPartSelected);
      const currentPageIndex = state.currentViewIndex;
      const jsonOriginLayerIndex = state.jsonOriginLayerIndex;

      resultsJsonOrigin.pages[currentPageIndex].lyrs[
        jsonOriginLayerIndex
      ].plot_max_age = maxAge;

      jsonOriginPartSelected.plot_max_age = maxAge;
      return {
        ...state,
        resultsJsonOrigin: resultsJsonOrigin,
        jsonOriginPartSelected: jsonOriginPartSelected,
      };
    }
    case types.CHANGE_WIND_STREAM_V_SCALE: {
      const vScale = action.payload;
      const resultsJsonOrigin = cloneDeep(state.resultsJsonOrigin);
      const jsonOriginPartSelected = cloneDeep(state.jsonOriginPartSelected);
      const currentPageIndex = state.currentViewIndex;
      const jsonOriginLayerIndex = state.jsonOriginLayerIndex;

      resultsJsonOrigin.pages[currentPageIndex].lyrs[
        jsonOriginLayerIndex
      ].plot_v_scale = vScale;

      jsonOriginPartSelected.plot_v_scale = vScale;
      return {
        ...state,
        resultsJsonOrigin: resultsJsonOrigin,
        jsonOriginPartSelected: jsonOriginPartSelected,
      };
    }
    case types.CHANGE_WIND_STREAM_FADE: {
      const fade = action.payload;
      const resultsJsonOrigin = cloneDeep(state.resultsJsonOrigin);
      const jsonOriginPartSelected = cloneDeep(state.jsonOriginPartSelected);
      const currentPageIndex = state.currentViewIndex;
      const jsonOriginLayerIndex = state.jsonOriginLayerIndex;

      resultsJsonOrigin.pages[currentPageIndex].lyrs[
        jsonOriginLayerIndex
      ].plot_fade = fade;

      jsonOriginPartSelected.plot_fade = fade;
      return {
        ...state,
        resultsJsonOrigin: resultsJsonOrigin,
        jsonOriginPartSelected: jsonOriginPartSelected,
      };
    }
    case types.CHANGE_WIND_STREAM_STROKE_WIDTH: {
      const strokeWidth = action.payload;
      const resultsJsonOrigin = cloneDeep(state.resultsJsonOrigin);
      const jsonOriginPartSelected = cloneDeep(state.jsonOriginPartSelected);
      const currentPageIndex = state.currentViewIndex;
      const jsonOriginLayerIndex = state.jsonOriginLayerIndex;

      resultsJsonOrigin.pages[currentPageIndex].lyrs[
        jsonOriginLayerIndex
      ].plot_stroke_width = strokeWidth;

      jsonOriginPartSelected.plot_stroke_width = strokeWidth;
      return {
        ...state,
        resultsJsonOrigin: resultsJsonOrigin,
        jsonOriginPartSelected: jsonOriginPartSelected,
      };
    }
    case types.CHANGE_WIND_STREAM_OPACITY: {
      const opacity = action.payload;
      const resultsJsonOrigin = cloneDeep(state.resultsJsonOrigin);
      const jsonOriginPartSelected = cloneDeep(state.jsonOriginPartSelected);
      const currentPageIndex = state.currentViewIndex;
      const jsonOriginLayerIndex = state.jsonOriginLayerIndex;

      resultsJsonOrigin.pages[currentPageIndex].lyrs[
        jsonOriginLayerIndex
      ].plot_opacity = opacity;

      jsonOriginPartSelected.plot_opacity = opacity;
      return {
        ...state,
        resultsJsonOrigin: resultsJsonOrigin,
        jsonOriginPartSelected: jsonOriginPartSelected,
      };
    }
    case types.CHANGE_WIND_DIR_COLOR: {
      const color = action.payload;
      const resultsJsonOrigin = cloneDeep(state.resultsJsonOrigin);
      const jsonOriginPartSelected = cloneDeep(state.jsonOriginPartSelected);
      const currentPageIndex = state.currentViewIndex;
      const jsonOriginLayerIndex = state.jsonOriginLayerIndex;

      resultsJsonOrigin.pages[currentPageIndex].lyrs[
        jsonOriginLayerIndex
      ].plot_color = color;

      jsonOriginPartSelected.plot_color = color;
      return {
        ...state,
        resultsJsonOrigin: resultsJsonOrigin,
        jsonOriginPartSelected: jsonOriginPartSelected,
      };
    }
    case types.CHANGE_WIND_DIR_SIZE: {
      const size = action.payload;
      const resultsJsonOrigin = cloneDeep(state.resultsJsonOrigin);
      const jsonOriginPartSelected = cloneDeep(state.jsonOriginPartSelected);
      const currentPageIndex = state.currentViewIndex;
      const jsonOriginLayerIndex = state.jsonOriginLayerIndex;

      resultsJsonOrigin.pages[currentPageIndex].lyrs[
        jsonOriginLayerIndex
      ].plot_arr_size = size;

      jsonOriginPartSelected.plot_arr_size = size;
      return {
        ...state,
        resultsJsonOrigin: resultsJsonOrigin,
        jsonOriginPartSelected: jsonOriginPartSelected,
      };
    }
    case types.CHANGE_WIND_DIR_OPACITY: {
      const opacity = action.payload;
      const resultsJsonOrigin = cloneDeep(state.resultsJsonOrigin);
      const jsonOriginPartSelected = cloneDeep(state.jsonOriginPartSelected);
      const currentPageIndex = state.currentViewIndex;
      const jsonOriginLayerIndex = state.jsonOriginLayerIndex;

      resultsJsonOrigin.pages[currentPageIndex].lyrs[
        jsonOriginLayerIndex
      ].plot_opacity = opacity;

      jsonOriginPartSelected.plot_opacity = opacity;
      return {
        ...state,
        resultsJsonOrigin: resultsJsonOrigin,
        jsonOriginPartSelected: jsonOriginPartSelected,
      };
    }
    case types.TOGGLE_EXPORT_SETTINGS: {
      return {
        ...state,
        ...viewInitialState,
        inResultsMapExportMode: true,
        inExportSettings: !state.inExportSettings,
      };
    }

    case types.CHANGE_PAGE_SIZE: {
      const pageSize = action.payload;
      const areaSquareLength = mapPlotSquareEdge[state.mapScale][pageSize];
      const mapPlotPolygonPoints = calculateRasterAreaPolygonPoints(
        state.mapPlotCenterLngUtm,
        state.mapPlotCenterLatUtm,
        1,
        areaSquareLength,
        areaSquareLength,
        state.crsDef
      );
      return {
        ...state,
        pageSize: pageSize,
        mapPlotPolygonPoints: mapPlotPolygonPoints,
      };
    }
    case types.CHANGE_SCALE: {
      const mapScale = action.payload;
      const areaSquareLength = mapPlotSquareEdge[mapScale][state.pageSize];
      const mapPlotPolygonPoints = calculateRasterAreaPolygonPoints(
        state.mapPlotCenterLngUtm,
        state.mapPlotCenterLatUtm,
        1,
        areaSquareLength,
        areaSquareLength,
        state.crsDef
      );
      return {
        ...state,
        mapScale: mapScale,
        mapPlotPolygonPoints: mapPlotPolygonPoints,
      };
    }
    case types.CHANGE_TITLE: {
      return {
        ...state,
        labTitle: action.payload,
      };
    }
    case types.CHANGE_SUBTITLE: {
      return {
        ...state,
        labSubtitle: action.payload,
      };
    }
    case types.CHANGE_ADDITIONAL_TEXT: {
      return {
        ...state,
        labAdditionalText: action.payload,
      };
    }
    case types.CHANGE_AUTHOR: {
      return {
        ...state,
        labAuthor: action.payload,
      };
    }
    case types.CHANGE_INSTITUTION: {
      return {
        ...state,
        labInstitution: action.payload,
      };
    }
    case types.CHANGE_ADRESS: {
      return {
        ...state,
        labAddress: action.payload,
      };
    }
    case types.CHANGE_CONTACT: {
      return {
        ...state,
        labContact: action.payload,
      };
    }
    case types.CHANGE_DATE: {
      return {
        ...state,
        labDate: action.payload,
      };
    }
    case types.CHANGE_LAYERS_FOR_EXPORT: {
      return {
        ...state,
        lyrs: action.payload,
      };
    }
    case types.TOGGLE_INCLUDE_NORTH_ARROW: {
      return {
        ...state,
        annNorthArrow: !state.annNorthArrow,
      };
    }
    case types.TOGGLE_INCLUDE_SCALE: {
      return {
        ...state,
        annScale: !state.annScale,
      };
    }
    case types.TOGGLE_INCLUDE_ASSESMENT_POINTS: {
      return {
        ...state,
        annAssessmentPoints: !state.annAssessmentPoints,
      };
    }
    case types.CHANGE_NORTH_ARROW_POSITION: {
      return {
        ...state,
        annNorthArrowPos: action.payload,
      };
    }
    case types.CHANGE_SCALE_POSITION: {
      return {
        ...state,
        annScalePos: action.payload,
      };
    }
    case types.CHANGE_BACKGROUND_MAP_COLOR: {
      return {
        ...state,
        styBackMapCol: action.payload,
      };
    }
    case types.CHANGE_BACKGROUND_HEADER_COLOR: {
      return {
        ...state,
        styBackHeaderCol: action.payload,
      };
    }
    case types.CHANGE_AREA_TO_CROP: {
      return {
        ...state,
        styCropName: action.payload,
      };
    }
    case types.TOGGLE_ARROW_SCALE: {
      return {
        ...state,
        vecArrowScale: !state.vecArrowScale,
      };
    }
    case types.CHANGE_ARROW_GRIDPOINT_SKIP: {
      return {
        ...state,
        vecArrowSkip: action.payload,
      };
    }
    case types.CHANGE_ARROW_LENGTH: {
      return {
        ...state,
        vecArrowSize: action.payload,
      };
    }
    case types.CHANGE_ARROW_MIN_MAGNITUDE: {
      return {
        ...state,
        vecMinMag: action.payload,
      };
    }
    case types.CHANGE_ARROW_SCALE_POISITION: {
      return {
        ...state,
        vecArrowScalePos: action.payload,
      };
    }
    case types.SET_MAP_PLOT_CENTER_LAT_WG: {
      return {
        ...state,
        mapPlotCenterLatWg: action.payload,
      };
    }
    case types.SET_MAP_PLOT_CENTER_LNG_WG: {
      return {
        ...state,
        mapPlotCenterLngWg: action.payload,
      };
    }
    case types.SET_MAP_PLOT_CENTER_LAT_UTM: {
      const mapPlotCenterLatUtm = action.payload.mapPlotCenterLatUtm;
      if (action.payload.onDragend) {
        return {
          ...state,
          mapPlotCenterLatUtm: mapPlotCenterLatUtm,
        };
      }

      const mapPlotCenterWg = reprojectUtmToWgPoint(
        [Number(state.mapPlotCenterLngUtm), Number(mapPlotCenterLatUtm)],
        state.crsDef
      );

      const areaSquareLength =
        mapPlotSquareEdge[state.mapScale][state.pageSize];

      const mapPlotPolygonPoints = calculateRasterAreaPolygonPoints(
        state.mapPlotCenterLngUtm,
        mapPlotCenterLatUtm,
        1,
        areaSquareLength,
        areaSquareLength,
        state.crsDef
      );

      return {
        ...state,
        mapPlotCenterLatUtm: mapPlotCenterLatUtm,
        mapPlotCenterLatWg: mapPlotCenterWg.lat,
        mapPlotPolygonPoints: mapPlotPolygonPoints,
      };
    }
    case types.SET_MAP_PLOT_CENTER_LNG_UTM: {
      const mapPlotCenterLngUtm = action.payload.mapPlotCenterLngUtm;

      if (action.payload.onDragend) {
        return {
          ...state,
          mapPlotCenterLngUtm: mapPlotCenterLngUtm,
        };
      }

      const mapPlotCenterWg = reprojectUtmToWgPoint(
        [Number(mapPlotCenterLngUtm), Number(state.mapPlotCenterLatUtm)],
        state.crsDef
      );

      const areaSquareLength =
        mapPlotSquareEdge[state.mapScale][state.pageSize];

      const mapPlotPolygonPoints = calculateRasterAreaPolygonPoints(
        mapPlotCenterLngUtm,
        state.mapPlotCenterLatUtm,
        1,
        areaSquareLength,
        areaSquareLength,
        state.crsDef
      );

      return {
        ...state,
        mapPlotCenterLngUtm: mapPlotCenterLngUtm,
        mapPlotCenterLngWg: mapPlotCenterWg.lng,
        mapPlotPolygonPoints: mapPlotPolygonPoints,
      };
    }
    case types.TOGGLE_MAP_PLOT_DRAGGABLE: {
      return {
        ...state,
        isMapPlotDraggable: !state.isMapPlotDraggable,
      };
    }
    case types.SET_INIT_MAP_PLOT_CENTER: {
      const mapPlotCenterLatUtm = action.payload.latUtm;
      const mapPlotCenterLngUtm = action.payload.lngUtm;
      const areaSquareLength =
        mapPlotSquareEdge[state.mapScale][state.pageSize];

      const mapPlotPolygonPoints = calculateRasterAreaPolygonPoints(
        mapPlotCenterLngUtm,
        mapPlotCenterLatUtm,
        1,
        areaSquareLength,
        areaSquareLength,
        state.crsDef
      );
      return {
        ...state,
        mapPlotCenterLatWg: action.payload.latWg,
        mapPlotCenterLngWg: action.payload.lngWg,
        mapPlotCenterLatUtm: mapPlotCenterLatUtm,
        mapPlotCenterLngUtm: mapPlotCenterLngUtm,
        mapPlotPolygonPoints: mapPlotPolygonPoints,
      };
    }
    case types.UPDATE_MAP_PLOT_POSITION: {
      return {
        ...state,
        mapPlotCenterLatWg: action.payload.mapPlotCenterLatWg,
        mapPlotCenterLngWg: action.payload.mapPlotCenterLngWg,
        mapPlotCenterLatUtm: action.payload.mapPlotCenterLatUtm,
        mapPlotCenterLngUtm: action.payload.mapPlotCenterLngUtm,
        mapPlotPolygonPoints: action.payload.mapPlotPolygonPoints,
      };
    }

    case types.CENTER_MAP_PLOT: {
      return {
        ...state,
        isMapPlotCentering: true,
      };
    }

    case types.SET_MAP_PLOT_LOGO: {
      return {
        ...state,
        mapPlotLogo: action.payload,
      };
    }
    case types.CLEAR_MAP_PLOT_LOGO: {
      return {
        ...state,
        mapPlotLogo: "",
      };
    }
    case types.CENTER_MAP_PLOT_SUCCESS: {
      return {
        ...state,
        isMapPlotCentering: false,
      };
    }

    case types.GET_EXPORT_REQUEST: {
      const visibleLayersIndex = [];
      state.resultsJsonOrigin.pages[state.currentViewIndex].lyrs
        .map((x, i) => {
          if (x.checked) return i;
        })
        .filter((y) => {
          if (y !== undefined) {
            visibleLayersIndex.push(y + 1);
          }
        });
      const cropName = state.styCropName;
      let styCropPolygonCoords = "";
      let styCropPolygonCoordsUtm = "";

      if (cropName !== "Domain") {
        styCropPolygonCoordsUtm = [];
        const indexOfCropName = state.arrayOfDrawnAoiLayerName.findIndex(
          (x) => {
            return x === cropName;
          }
        );
        styCropPolygonCoords =
          state.allDrawnAoiPolygonsPointsUtm[indexOfCropName];

        for (let index = 0; index < styCropPolygonCoords.length; index++) {
          styCropPolygonCoordsUtm.push([
            Number(styCropPolygonCoords[index].lat),
            Number(styCropPolygonCoords[index].lng),
          ]);
        }
      }

      const mapMinExtent = reprojectWgToUtmPoint(
        {
          lat: state.mapPlotPolygonPoints[1][0],
          lng: state.mapPlotPolygonPoints[0][1],
        },
        state.crsDef
      );

      const mapMaxExtent = reprojectWgToUtmPoint(
        {
          lat: state.mapPlotPolygonPoints[3][0],
          lng: state.mapPlotPolygonPoints[2][1],
        },
        state.crsDef
      );
      const mapExtent = [
        [Number(mapMinExtent.lat), Number(mapMinExtent.lng)],
        [Number(mapMaxExtent.lat), Number(mapMaxExtent.lng)],
      ];

      const annAssessmentPoints = state.annAssessmentPoints;

      const scaleIndex =
        [
          "1:500",
          "1:1000",
          "1:2000",
          "1:5000",
          "1:10000",
          "1:20000",
          "1:50000",
        ].findIndex((x) => {
          return x === state.mapScale;
        }) + 1;

      const exportRequestJson = {
        page: state.currentViewIndex + 1,
        lyrs: visibleLayersIndex,
        guiversion: "Open-Beta 23.10 RC1",
        pageSize: state.pageSize,
        mapScale: scaleIndex,
        mapExtent: mapExtent,
        labTitle: state.labTitle,
        labSubtitle: state.labSubtitle,
        labAdditionalText: state.labAdditionalText,
        labAuthor: state.labAuthor,
        labInstitution: state.labInstitution,
        labAddress: state.labAddress,
        labContact: state.labContact,
        labDate: state.labDate,
        annNorthArrow: state.annNorthArrow,
        annNorthArrowPos: itemPositionAbbr[state.annNorthArrowPos],
        annScale: state.annScale,
        annScalePos: itemPositionAbbr[state.annScalePos],
        annAssessmentPoints: annAssessmentPoints,
        annAssessmentPointsArray: annAssessmentPoints
          ? state.allDrawnPoiUtm
          : "",
        annAssessmentPointsCol: state.annAssessmentPointsCol,
        styBackMapCol: state.styBackMapCol,
        styBackHeaderCol: state.styBackHeaderCol,
        styCropName: cropName,
        styCropPolygon: styCropPolygonCoordsUtm,
        vecArrowScale: state.vecArrowScale,
        vecArrowSkip: state.vecArrowSkip,
        vecArrowSize: state.vecArrowSize,
        vecMinMag: state.vecMinMag,
      };

      return {
        ...state,
        exportRequestJson: exportRequestJson,
      };
    }
    case types.TOGGLE_TIME_CHART: {
      return {
        ...state,
        inTimeChart: !state.inTimeChart,
        inAoi: false,
        inPoi: false,
        inVerticalChart: false,
        chartPoint: [],
      };
    }
    case types.TOGGLE_VERTICAL_CHART: {
      return {
        ...state,
        inVerticalChart: !state.inVerticalChart,
        inAoi: false,
        inPoi: false,
        inTimeChart: false,
        chartPoint: [],
      };
    }
    case types.CHANGE_CHART_FILE_TYPE: {
      const chartFileType = action.payload.fileType;
      const selectedIndex = action.payload.selectedIndex + 1;

      const layerSettingsJson = cloneDeep(state.layerSettingsJson);
      const selectedSettingsJson = layerSettingsJson[selectedIndex];
      const chartParamsOptions = selectedSettingsJson.data.params.map((x) => {
        return { value: x.palmid, label: x.lankey };
      });
      //const spatTimeOptions = selectedSettingsJson.data.times;
      const chartHeightLevelOptions =
        selectedSettingsJson.data.params[0].heights;

      const chartHeightLevel = {
        value: chartHeightLevelOptions[0],
        index: 0,
      };
      return {
        ...state,
        chartFileType: chartFileType,
        chartParams: chartParamsOptions[0].value,
        chartParamsIndex: 0,
        chartParamsOptions: chartParamsOptions,
        chartHeightLevel: chartHeightLevel,
        chartHeightLevelOptions: chartHeightLevelOptions,
      };
    }
    case types.CHANGE_CHART_PARAMS: {
      const chartParams = action.payload.selectedParams;
      const paramsIndex = action.payload.paramsIndex;
      const layerSettingsJson = cloneDeep(state.layerSettingsJson);
      const chartFileType = cloneDeep(state.chartFileType);

      const layerOptionsSection = layerSettingsJson.find(
        (x) => x.name === chartFileType
      );
      const chartHeightLevelOptions =
        layerOptionsSection.data.params[paramsIndex].heights;

      const chartHeight = {
        value: chartHeightLevelOptions[0],
        index: 0,
      };

      return {
        ...state,
        chartParams: chartParams,
        chartParamsIndex: paramsIndex,
        chartHeightLevel: chartHeight,
        chartHeightLevelOptions: chartHeightLevelOptions,
      };
    }
    case types.CHANGE_CHART_POINT: {
      if (state.chartPoint.length === 8 && action.payload.length !== 7) {
        return {
          ...state,
        };
      }

      return {
        ...state,
        chartPoint: action.payload,
      };
    }
    case types.CHANGE_CHART_HEIGHT_LEVEL: {
      const chartHeightLevel = {
        value: action.payload.value,
        index: action.payload.index,
      };
      return {
        ...state,
        chartHeightLevel: chartHeightLevel,
      };
    }
    case types.CHANGE_CHART_TIMESTEP: {
      const chartTimestep = {
        value: action.payload.value,
        index: action.payload.index,
      };
      return {
        ...state,
        chartTimestep: chartTimestep,
      };
    }
    default:
      return state;
  }
};

export default resultsReducer;
