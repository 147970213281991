export const types = {
  CREATE_PROJECT: "CREATE_PROJECT",
  CREATE_PROJECT_SUCCESS: "CREATE_PROJECT_SUCCESS",
  LOAD_PROJECT: "LOAD_PROJECT",
  UNLOAD_PROJECT: "UNLOAD_PROJECT",
  LOAD_PROJECT_SUCCESS: "LOAD_PROJECT_SUCCESS",
  DELETE_PROJECT: "DELETE_PROJECT",
  DELETE_PROJECT_SUCCESS: "DELETE_PROJECT_SUCCESS",
  DELETE_PROJECT_FAILURE: "DELETE_PROJECT_FAILURE",
  LOAD_PROJECTS: "LOAD_PROJECTS",
  LOAD_PROJECTS_SUCCESS: "LOAD_PROJECTS_SUCCESS",
  LOAD_PROJECTS_FAILURE: "LOAD_PROJECTS_FAILURE",
  SAVE_PROJECT: "SAVE_PROJECT",
  SAVE_PROJECT_SUCCESS: "SAVE_PROJECT_SUCCESS",
  SAVE_PROJECT_FAILURE: "SAVE_PROJECT_FAILURE",
  SET_CURRENT_CASE: "SET_CURRENT_CASE",
  SET_CURRENT_CASE_SUCCESS: "SET_CURRENT_CASE_SUCCESS",
  CHECK_GEOJSON: "CHECK_GEOJSON",
  CONVERT_TOPOGRAPHY: "CONVERT_TOPOGRAPHY",
};
