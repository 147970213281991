import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "../../../../Components/Map/Form/Box";
import { X, Copy } from "react-feather";
import {
  toggleCopyPropsMode,
  toggleSingleTreeCopyPropsMode,
  copyProps,
  copySingleTreeProps,
  toggleEmissionCopyPropsMode,
  copyEmissionProps,
} from "../../../../redux/actions/mapActions";
import { useTranslation } from "react-i18next";
import "./CopyPropsForm.scss";

export default function CopyPropsForm() {
  const [assignProperties, toggleAssignProperties] = useState(false);
  const [copySubtype, toggleCopySubtype] = useState(false);
  const [copyHeight, toggleCopyHeight] = useState(false);
  const [waterTemperature, toggleWaterTemperature] = useState(false);
  const [crownDiameter, toggleCrownDiameter] = useState(false);
  const [buildingSurfaceFractions, toggleBuildingSurfaceFractions] = useState(
    assignProperties ? true : false
  );
  const [emissionEa, toggleEmissionEa] = useState(false);
  const [emissionEb, toggleEmissionEb] = useState(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const assignedObjectCopyId = useSelector(
    (state) => state.map.assignedObjectCopyId
  );
  const assignedSingleTreeCopyId = useSelector(
    (state) => state.map.assignedSingleTreeCopyId
  );
  const assignedEmissionCopyId = useSelector(
    (state) => state.map.assignedEmissionCopyId
  );

  const currentObjectType = useSelector((state) => state.map.currentObjectType);

  const copyProperties = (e) => {
    e.preventDefault();
    if (currentObjectType === "single tree") {
      dispatch(
        copySingleTreeProps({
          copySubtype: copySubtype,
          copyHeight: copyHeight,
          crownDiameter: crownDiameter,
          assignProperties: assignProperties,
        })
      );
      return;
    } else if (currentObjectType === "point_emission") {
      dispatch(
        copyEmissionProps({
          copySubtype: copySubtype,
          emissionEa: emissionEa,
          emissionEb: emissionEb,
          assignProperties: assignProperties,
        })
      );
      return;
    }
    dispatch(
      copyProps({
        copySubtype: copySubtype,
        copyHeight: copyHeight,
        assignProperties: assignProperties,
        waterTemperature: waterTemperature,
        buildingSurfaceFractions: buildingSurfaceFractions,
      })
    );
  };

  const exitCopyPropsMode = () => {
    if (currentObjectType === "single tree") {
      dispatch(toggleSingleTreeCopyPropsMode());
      return;
    } else if (currentObjectType === "point_emission") {
      dispatch(toggleEmissionCopyPropsMode());
      return;
    }

    dispatch(toggleCopyPropsMode());
  };

  

  return (
    <>
      <CopyPropsIdLine onExitClick={exitCopyPropsMode} />
      <Box
        objectType={
          currentObjectType !== "single tree" ? currentObjectType : "singleTree"
        }
      />
      <CheckBoxInput
        copySubtype={copySubtype}
        copyHeight={copyHeight}
        waterTemperature={waterTemperature}
        crownDiameter={crownDiameter}
        assignProperties={assignProperties}
        buildingSurfaceFractions={buildingSurfaceFractions}
        toggleCopyHeight={() => toggleCopyHeight(!copyHeight)}
        toggleCopySubtype={() => toggleCopySubtype(!copySubtype)}
        toggleAssignProperties={() => toggleAssignProperties(!assignProperties)}
        toggleWaterTemperature={() => toggleWaterTemperature(!waterTemperature)}
        toggleCrownDiameter={() => toggleCrownDiameter(!crownDiameter)}
        toggleBuildingSurfaceFractions={() =>
          toggleBuildingSurfaceFractions(!buildingSurfaceFractions)
        }
        toggleEmissionEa={() => toggleEmissionEa(!emissionEa)}
        toggleEmissionEb={() => toggleEmissionEb(!emissionEb)}
      />
      <CopyPropsSelect
        label={t("SelectionType")}
        value={`SelectOnMap`}
        options={[{ value: "SelectOnMap", label: t(`SelectOnMap`) }]}
      />
      <div className="copy-props-btn-wrapper">
        <button
          className="raster-settings-btn"
          onClick={copyProperties}
          disabled={
            (assignedObjectCopyId.length === 0 &&
              assignedSingleTreeCopyId.length === 0 &&
              assignedEmissionCopyId.length === 0) ||
            (!copySubtype &&
              !copyHeight &&
              !assignProperties &&
              !waterTemperature &&
              !crownDiameter &&
              !buildingSurfaceFractions &&
              !emissionEa &&
              !emissionEb)
          }
        >
          <div className="btn-content-wrapper">
            <Copy color={"#494949"} size={"14px"} strokeWidth={"2px"} />
            <div className="raster-btn-lbl" style={{ fontSize: "11px" }}>
              {t("AssignToSelected")}
            </div>
          </div>
        </button>
      </div>
    </>
  );
}

const CopyPropsIdLine = (props) => {
  const { t } = useTranslation();
  const polygonId = useSelector(
    (state) => state.map.selectedMapItemGeoProps.id
  );
  return (
    <div className="id-copy-line">
      <label className="id-copy-label">ID: {polygonId}</label>
      <label className="assign-label">{t("AssignData")}</label>
      <X
        size="13px"
        style={{ cursor: "pointer" }}
        onClick={props.onExitClick}
      />
    </div>
  );
};

const CheckBoxInput = (props) => {
  const { t } = useTranslation();
  const objectTypeIndex = useSelector(
    (state) => state.map.selectedMapItemGeoProps.t
  );
  const currentObjectType = useSelector((state) => state.map.currentObjectType);
  return (
    <div className="form-chckbox-group">
      <label className="form-label">{t("SelectData")}</label>
      <div className="chckbox-container">
        <div className="chckbox-input-wrapper">
          <input
            type="checkbox"
            id="copySubtype"
            name="copySubtype"
            style={{ transform: "scale(0.9)", alignSelf: "center" }}
            checked={props.copySubtype}
            onChange={props.toggleCopySubtype}
          />
          <label className="chckbox-lbl" for="copySubtype">
            {t(`t${objectTypeIndex}`)}
          </label>
        </div>
        {(currentObjectType === "building" ||
          currentObjectType === "tree patches" ||
          currentObjectType === "single tree") && (
          <div className="chckbox-input-wrapper">
            <input
              className="copy-chckbox"
              type="checkbox"
              id="copyHeight"
              name="copyHeight"
              style={{ transform: "scale(0.9)" }}
              onChange={props.toggleCopyHeight}
              checked={props.copyHeight}
            />
            <label className="chckbox-lbl" for="copyHeight">
              {currentObjectType !== "building" ? t("th") : t("bh")}
            </label>
          </div>
        )}
        {currentObjectType === "water" && (
          <div className="chckbox-input-wrapper">
            <input
              className="copy-chckbox"
              type="checkbox"
              id="waterTemp"
              name="waterTemp"
              style={{ transform: "scale(0.9)" }}
              onChange={props.toggleWaterTemperature}
              checked={props.waterTemperature}
            />
            <label className="chckbox-lbl" for="waterTemp">
              {t("watert")}
            </label>
          </div>
        )}

        {currentObjectType === "single tree" && (
          <div className="chckbox-input-wrapper">
            <input
              className="copy-chckbox"
              type="checkbox"
              id="singleTree"
              name="singleTree"
              style={{ transform: "scale(0.9)" }}
              onChange={props.toggleCrownDiameter}
              checked={props.crownDiameter}
            />
            <label className="chckbox-lbl" for="singleTree">
              {t("crown_dia")}
            </label>
          </div>
        )}

        {currentObjectType === "point_emission" && (
          <>
            <div className="chckbox-input-wrapper">
              <input
                className="copy-chckbox"
                type="checkbox"
                id="ea"
                name="ea"
                style={{ transform: "scale(0.9)" }}
                onChange={props.toggleEmissionEa}
                checked={props.emissionEa}
              />
              <label className="chckbox-lbl" for="ea">
                {t("ea")}
              </label>
            </div>
            <div className="chckbox-input-wrapper">
              <input
                className="copy-chckbox"
                type="checkbox"
                id="eb"
                name="eb"
                style={{ transform: "scale(0.9)" }}
                onChange={props.toggleEmissionEb}
                checked={props.emissionEb}
              />
              <label className="chckbox-lbl" for="eb">
                {t("eb")}
              </label>
            </div>
          </>
        )}

        <div className="chckbox-input-wrapper">
          <input
            type="checkbox"
            id="copyProperties"
            name="copyProperties"
            style={{ transform: "scale(0.9)" }}
            checked={props.assignProperties}
            onChange={props.toggleAssignProperties}
          />
          <label className="chckbox-lbl" for="copyProperties">
            {t(`${currentObjectType}`)} {t("Parameters")}
          </label>
        </div>
        {currentObjectType === "building" && (
          <div className="chckbox-input-wrapper">
            <input
              className="copy-chckbox"
              type="checkbox"
              id="surfaceFractions"
              name="surfaceFractions"
              style={{ transform: "scale(0.9)" }}
              onChange={props.toggleBuildingSurfaceFractions}
              checked={
                props.assignProperties ? true : props.buildingSurfaceFractions
              }
              disabled={props.assignProperties}
            />
            <label className="chckbox-lbl" for="building">
              {t("SurfaceFractions")}
            </label>
          </div>
        )}
      </div>
    </div>
  );
};

const CopyPropsSelect = (props) => {
  return (
    <div className="form-group">
      <label className={`form-label`}>{props.label}</label>
      <select
        id={props.name}
        name={props.name}
        onChange={props.onChange}
        className={`form-select`}
        value={props.value}
        style={{ height: "25px" }}
      >
        {props.options.map((option) => {
          return (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
    </div>
  );
};
