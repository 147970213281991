import React from "react";
import { palettes } from "../../../common/VisualizationPresets/colorPalettes";
import { connect } from "react-redux";
import "./GeotiffLegend.scss";

class GeotiffLegend extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      elevationLegend: [],
    };
  }
  componentDidMount() {
    const diffHeight = this.props.maxElevation - this.props.minElevation;
    const stepHeight = diffHeight / 9;
    const elevationPallete =
      palettes.colors.elevation[`${this.props.inputElevationPalette}`];
    let elevationLegend = [];

    for (let index = 8; index > -1; index--) {
      elevationLegend.push({
        height: this.props.minElevation + stepHeight * index,
        color: elevationPallete[index],
      });
    }
    this.setState({ elevationLegend });
  }
  render() {
    return (
      <div className="geotiff-scale-wrapper">
        <h5 className="legend-geotiff-header">Topography</h5>
        <div className="scale-container">
          {this.state.elevationLegend.map((x) => {
            return (
              <div className="scale-row-container">
                <div
                  className="height-color-tile"
                  style={{ backgroundColor: x.color }}
                ></div>
                <div className="height-dscr-container">
                  <p className="height-dscr">{`${x.height} m`}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    maxElevation: state.legend.maxElevation,
    minElevation: state.legend.minElevation,
    inputElevationPalette:
      state.userSettings?.settings?.visualizationPresets?.inputElevationPalette ||
      palettes.defaults.elevation,
  };
};

export default connect(mapStateToProps, null)(GeotiffLegend);
