import React from "react";
import InputCheckSummary from "../../InputCheckSummary";

const InputCheck = (props) => {
  return (
    <div className="sim-summary-area">
      {props.inputChecked && !props.isInfoLoading && (
        <InputCheckSummary
          setup={props.setup}
          storage={props.storage}
          results={props.results}
          staticDriver={props.staticDriver}
          isWindTableUserDefined={props.isWindTableUserDefined}
        />
      )}
    </div>
  );
};

export default InputCheck;
