import React, { useEffect, useState } from "react";
import {
  Box,
  Compass,
  Cpu,
  File,
  Sun,
  Wind,
  FileText,
  Cloud,
  Clipboard,
} from "react-feather";
import Select from "react-select";
import FormSeparator from "../../Components/Common/Form/FormSeparator";
import Page from "../../Components/Page/Page";
import { TextInput } from "../../Components/Common/Form/Form";
import { Loader } from "../../Components/Loader";
import FormFileBrowser from "../../Components/Common/Form/FormFileBrowser/FormFileBrowser";
import { TextArea } from "../../Components/Map/Form/TextArea";
import ErrorSection from "../../Components/Common/ErrorSection/ErrorSection";
import CheckFilesLoader from "../Wizards/NewProjectWizard/CheckFilesLoader";
import Icon from "../../Components/Common/Icon/index";
import * as actions from "../../redux/actions/caseActions";
import { selectSetupStyle } from "../../Components/Common/PaletteDropdown/index";
import { selectCurrentCase } from "../../redux/selectors/caseSelector";
import { applicationFields } from "../../common/applicationFields";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import "./CaseHome.scss";

const CaseHome = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const projectId = useSelector((state) => state.projects?.loadedProject?.id);
  const caseDto = useSelector(selectCurrentCase);
  const isCaseUpdating = useSelector((state) => state.newCaseWizard.isUpdating);
  const errors = useSelector((state) => state.newCaseWizard.errors);
  const geojsonCheckResultUrl = useSelector(
    (state) => state.newCaseWizard.geojsonCheckResultUrl
  );
  const geojsonCheckInProgress = useSelector(
    (state) => state.newCaseWizard.geojsonCheckInProgress
  );
  const uploadedGeojsonUrl = useSelector(
    (state) => state.newCaseWizard.uploadedGeojsonUrl
  );
  const topoConversionInProgress = useSelector(
    (state) => state.newCaseWizard.topoConversionInProgress
  );
  const topoConversionResultUrl = useSelector(
    (state) => state.newCaseWizard.topoConversionResultUrl
  );
  const geojsonUploadInProgress = useSelector(
    (state) => state.newCaseWizard.geojsonUploadInProgress
  );
  const topographyUploadInProgress = useSelector(
    (state) => state.newCaseWizard.topographyUploadInProgress
  );
  const uploadedTopographyUrl = useSelector(
    (state) => state.newCaseWizard.uploadedTopographyUrl
  );
  const topographyReady = useSelector(
    (state) => state.newCaseWizard.topographyReady
  );
  const geojsonReady = useSelector((state) => state.newCaseWizard.geojsonReady);
  const applicationField = useSelector(
    (state) => state.projects?.currentCase?.applicationField
  );
  const postProcessingJobs = useSelector(
    (state) => state.projects?.currentCase?.postProcessingJobs
  );

  const [caseData, setCaseData] = useState({});
  const [topography, setTopography] = useState(null);
  const [geojson, setGeojson] = useState(null);
  const [staticDriver, setStaticDriver] = useState(null);

  useEffect(() => {
    if (caseDto) {
      setCaseData({
        id: caseDto.id,
        name: caseDto.name,
        description: caseDto.description,
        applicationField: caseDto.applicationField,
        topographyUrl: caseDto.topographyUrl,
        verifiedGeojsonUrl: caseDto.verifiedGeojsonUrl,
        staticDriverUrl: caseDto.staticDriverUrl,
        type: caseDto.type,
      });
      setGeojson(null);
      setTopography(null);
      setStaticDriver(null);
      dispatch(actions.resetCreateCase());
    }
  }, [caseDto]);

  useEffect(() => {
    if (isCaseUpdating) {
      if (
        !geojsonReady &&
        !uploadedGeojsonUrl &&
        !geojsonCheckInProgress &&
        !geojsonUploadInProgress &&
        geojson?.name
      ) {
        dispatch(
          actions.checkGeojson({
            geojson: geojson,
            geojsonUrl: `${projectId}/${caseDto.id}/${geojson.name}`,
          })
        );
      }
      if (
        !topographyReady &&
        !topoConversionInProgress &&
        !topographyUploadInProgress &&
        !uploadedTopographyUrl &&
        topography?.name &&
        geojsonReady
      ) {
        dispatch(
          actions.convertTopography({
            topography: topography,
            topographyUrl: `${projectId}/${caseDto.id}/${topography.name}`,
          })
        );
      }
      if (geojsonReady && topographyReady) {
        const payload = {
          ...caseData,
          projectId: projectId,
          applicationField: caseData.applicationField,
          originalTopographyUrl:
            uploadedTopographyUrl || caseDto.originalTopographyUrl,
          topographyUrl: topoConversionResultUrl || caseDto.topographyUrl,
          originalGeojsonUrl: uploadedGeojsonUrl || caseDto.originalGeojsonUrl,
          verifiedGeojsonUrl:
            geojsonCheckResultUrl || caseDto.verifiedGeojsonUrl,
        };
        dispatch(actions.updateCase(payload));
      }
    }
  }, [
    dispatch,
    caseData,
    isCaseUpdating,
    geojsonReady,
    geojsonCheckInProgress,
    geojsonUploadInProgress,
    topographyReady,
    topoConversionInProgress,
    topographyUploadInProgress,
    geojson,
    topography,
    projectId,
    caseDto,
    geojsonCheckResultUrl,
    topoConversionResultUrl,
    uploadedGeojsonUrl,
    uploadedTopographyUrl,
  ]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCaseData({
      ...caseData,
      [name]: value,
    });
  };

  const onTopographyChange = (topo) => {
    setTopography(topo);
  };

  const onGeojsonChange = (geojson) => {
    setGeojson(geojson);
  };

  const onStaticDriverChange = (staticDriver) => {
    setStaticDriver(staticDriver);
  };

  const canChangeApplicationField = () => {
    return (
      caseDto.type !== 1 &&
      (!caseDto.rasterPreviewJob || !caseDto.rasterPreviewJob.id)
    );
  };

  const getApplicationFieldWarning = (t) => {
    return caseDto.type === 1
      ? t("CannotChangeTypeStaticDriver")
      : t("CannotChangeTypeRasterSubmitted");
  };

  const submit = () => {
    if (isCaseUpdating) {
      return;
    }

    if (caseDto.type === 0) {
      const payload = {
        geoTiff: topography,
        geojson: geojson,
      };
      dispatch(actions.initUpdateCase(payload));
    } else {
      const payload = {
        ...caseData,
        projectId: projectId,
        applicationField: caseData.applicationField,
        topographyUrl: topography,
        geojsonUrl: geojson,
        staticDriver: staticDriver,
      };
      dispatch(actions.updateCase(payload));
    }
  };

  const goToDomain = () => {
    history.push(`/projects/${projectId}/cases/${caseDto.id}/domain`);
  };

  const goToSetup = () => {
    let setupPath = "";

    if (applicationField === 1) {
      setupPath = "thermalComfort";
    } else if (applicationField === 2) {
      setupPath = "windComfort";
    } else if (applicationField === 3) {
      setupPath = "chemistry";
    } else if (applicationField === 4) {
      setupPath = "expert";
    } else {
      setupPath = "setup";
    }

    history.push(`/projects/${projectId}/cases/${caseDto.id}/${setupPath}`);
  };

  const goToSimulation = () => {
    history.push(`/projects/${projectId}/cases/${caseDto.id}/simulation`);
  };

  const goToResults = () => {
    history.push(`/projects/${projectId}/cases/${caseDto.id}/results`);
  };

  const goToFileBrowser = () => {
    history.push(`/projects/${projectId}/cases/${caseDto.id}/files`);
  };

  const getFileNameFromPath = (path) => {
    return (path && path.replace(/^.*[\\\/]/, "")) || "";
  };

  const renderFileUploadScreen = () => {
    const steps = [];
    if (geojson?.name) {
      steps.push({
        text: t("UploadingGeojson"),
        active: geojsonUploadInProgress,
      });
      steps.push({
        text: t("GeojsonCheckInProgress"),
        active: geojsonCheckInProgress,
      });
    }
    if (topography?.name) {
      steps.push({
        text: t("UploadingTopography"),
        active: topographyUploadInProgress,
      });
      steps.push({
        text: t("TopoConvInProgress"),
        active: topoConversionInProgress,
      });
    }
    return steps.length > 0 ? <CheckFilesLoader steps={steps} /> : <Loader />;
  };

  const getApplicationFieldValue = () => {
    const applicationField = applicationFields.find(
      (af) => af.id === caseData.applicationField
    );
    return applicationField
      ? {
          value: applicationField.id,
          label: t(applicationField.key),
        }
      : null;
  };

  return isCaseUpdating ? (
    renderFileUploadScreen()
  ) : (
    <Page>
      {caseDto ? (
        <>
          <div className="case-wrapper" style={{ flexDirection: "row" }}>
            <div className="case-container">
              <div className="case-heading">
                <h1>{t("General")}</h1>
              </div>
              <FormSeparator label={t("Info")} />
              <TextInput
                label={t("Name")}
                name={"name"}
                value={caseData.name}
                onChange={handleChange}
              />
              <TextArea
                label={t("Description")}
                name={"description"}
                value={caseData.description || ""}
                onChange={handleChange}
              />
              <div className="form-line-input-wrapper">
                <label className="form-label">{t("ApplicationField")}</label>
                <Select
                  isDisabled={!canChangeApplicationField()}
                  value={getApplicationFieldValue()}
                  options={applicationFields.map((af) => ({
                    value: af.id,
                    label: t(af.key),
                    disabled: af.disabled,
                  }))}
                  styles={selectSetupStyle}
                  isOptionDisabled={(option) => option.disabled}
                  onChange={(o) =>
                    setCaseData({
                      ...caseData,
                      applicationField: o.value,
                    })
                  }
                  isSearchable={false}
                />
                {!canChangeApplicationField() && (
                  <span className="form-warning">
                    {getApplicationFieldWarning(t)}
                  </span>
                )}
              </div>
              <FormSeparator label={`${t("Geodata")}`} />
              {caseData.type !== 1 && (
                <div className="geodata-container">
                  <FormFileBrowser
                    label={t("UploadDigitalElevationModel")}
                    fileName={
                      topography?.name ||
                      getFileNameFromPath(caseData.topographyUrl)
                    }
                    onChange={onTopographyChange}
                    accept={".tif, .tiff"}
                    disabled={!canChangeApplicationField()}
                    isClearable={false}
                  />
                  {!canChangeApplicationField() && (
                    <span className="form-warning">
                      {getApplicationFieldWarning(t)}
                    </span>
                  )}
                  <FormFileBrowser
                    label={t("UploadQGISJSON")}
                    fileName={
                      geojson?.name ||
                      getFileNameFromPath(caseData.verifiedGeojsonUrl)
                    }
                    onChange={onGeojsonChange}
                    accept={"application/JSON, .GEOJSON"}
                    disabled={!canChangeApplicationField()}
                    isClearable={false}
                  />
                  {!canChangeApplicationField() && (
                    <span className="form-warning">
                      {getApplicationFieldWarning(t)}
                    </span>
                  )}
                </div>
              )}
              {caseData.staticDriverUrl && (
                <>
                  <FormFileBrowser
                    label={"staticDriver"}
                    fileName={
                      staticDriver?.name ||
                      getFileNameFromPath(caseData.staticDriverUrl)
                    }
                    onChange={onStaticDriverChange}
                  />
                  <span className="form-warning">
                    {t("StaticDriverNotice")}
                  </span>
                </>
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "90px",
                alignItems: "end",
              }}
            >
              <button
                className="raster-settings-btn case-sdb-btn"
                style={{ marginTop: "13px" }}
                onClick={goToDomain}
              >
                <div
                  className="btn-content-wrapper btn-palm-mon-content-wrapper"
                  style={{ justifyContent: "flexStart" }}
                >
                  <div
                    className="raster-btn-lbl case-btn-lbl"
                    style={{ marginLeft: "0px", marginRight: "5px" }}
                  >
                    {t("Domain")}
                  </div>
                  <Icon
                    className={`nav-case-btn`}
                    icon={Compass}
                    color={"#494949"}
                    strokeWidth={"2px"}
                  />
                </div>
              </button>
              <button
                className="raster-settings-btn case-sdb-btn"
                style={{ marginTop: "13px" }}
                onClick={goToSetup}
                disabled={applicationField === 0}
              >
                <div
                  className="btn-content-wrapper btn-palm-mon-content-wrapper"
                  style={{ justifyContent: "flexStart" }}
                >
                  <div
                    className="raster-btn-lbl case-btn-lbl"
                    style={{ marginLeft: "0px", marginRight: "5px" }}
                  >
                    {t("Setup")}
                  </div>
                  <Icon
                    className={`nav-case-btn`}
                    icon={
                      applicationField === 1
                        ? Sun
                        : applicationField === 2
                        ? Wind
                        : applicationField === 3
                        ? Cloud
                        : applicationField === 4
                        ? Clipboard
                        : Box
                    }
                    color={"#494949"}
                    strokeWidth={"2px"}
                  />
                </div>
              </button>
              <button
                className="raster-settings-btn case-sdb-btn"
                style={{ marginTop: "13px" }}
                onClick={goToSimulation}
                disabled={applicationField === 0}
              >
                <div
                  className="btn-content-wrapper btn-palm-mon-content-wrapper"
                  style={{ justifyContent: "flexStart" }}
                >
                  <div
                    className="raster-btn-lbl case-btn-lbl"
                    style={{ marginLeft: "0px", marginRight: "5px" }}
                  >
                    {t("Simulation")}
                  </div>
                  <Icon
                    className={`nav-case-btn`}
                    icon={Cpu}
                    color={"#494949"}
                    strokeWidth={"2px"}
                  />
                </div>
              </button>
              <button
                className="raster-settings-btn case-sdb-btn"
                style={{ marginTop: "13px" }}
                onClick={goToResults}
                disabled={
                  !postProcessingJobs?.some((x) => {
                    return x.status === 2;
                  })
                }
              >
                <div
                  className="btn-content-wrapper btn-palm-mon-content-wrapper"
                  style={{ justifyContent: "flexStart" }}
                >
                  <div
                    className="raster-btn-lbl case-btn-lbl"
                    style={{ marginLeft: "0px", marginRight: "5px" }}
                  >
                    {t("Results")}
                  </div>
                  <Icon
                    className={`nav-case-btn`}
                    icon={FileText}
                    color={"#494949"}
                    strokeWidth={"2px"}
                  />
                </div>
              </button>

              <button
                className="raster-settings-btn case-sdb-btn"
                style={{ marginTop: "13px" }}
                onClick={goToFileBrowser}
              >
                <div
                  className="btn-content-wrapper btn-palm-mon-content-wrapper"
                  style={{ justifyContent: "flexStart" }}
                >
                  <div
                    className="raster-btn-lbl case-btn-lbl"
                    style={{ marginLeft: "0px", marginRight: "5px" }}
                  >
                    {t("FileBrowser")}
                  </div>
                  <Icon
                    className={`nav-case-btn`}
                    icon={File}
                    color={"#494949"}
                    strokeWidth={"2px"}
                  />
                </div>
              </button>
            </div>
          </div>
          <ErrorSection label={`${t("Errors")}:`} errors={errors} />

          <div
            className="button-case-container"
            style={{ padding: "0px", alignItems: "center" }}
          >
            <button
              className="wizard-button case-home-btn wizard-button-submit"
              style={{
                marginTop: "0px",
              }}
              onClick={submit}
            >
              {isCaseUpdating && <Loader size="x-small" mode="light" />}

              {t("Save")}
            </button>
            <button
              className="wizard-button case-home-btn wizard-button-submit"
              style={{
                marginTop: "0px",
              }}
              onClick={goToDomain}
              disabled={caseData.type === 1}
            >
              {t("Next")}
            </button>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </Page>
  );
};

export default withRouter(CaseHome);
