import React from "react";
import LegendItem from "../../../../Components/Map/Legend/LegendItem/LegendItem";
import {
  setResultsGeotiffVisibility,
  hideResultsGeotiffLegend,
} from "../../../../redux/actions/resultsActions";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class StaticDriverLegend extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;
    return (
      <div
        className="legend-container"
        onMouseLeave={this.props.hideResultsGeotiffLegend}
        style={{ top: "324px", width: "auto" }}
      >
        <h5 className="legend-hdr ">{t("ResultsGeotiff")}</h5>
        {this.props.resultsJsonOrigin.pages[
          this.props.currentViewIndex
        ].lyrs.map((x, i) => {
          return (
            <LegendItem
              id={x.id}
              name={`${t(x.name) + ` ${x.set_unit}`}`}
              checked={
                this.props.resultsJsonOrigin.pages[this.props.currentViewIndex]
                  .lyrs[i].checked
                  ? true
                  : false
              }
              onChange={() =>
                this.props.setResultsGeotiffVisibility({
                  resultsGeotiffName: x.name,
                  currentLayerIndex: i,
                })
              }
            />
          );
        })}
        {this.props.resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs
          .length === 0 && (
          <p style={{ fontSize: "12px" }}>{t("NoLayersInCurrentPage")}</p>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    resultsJsonOrigin: state.results.resultsJsonOrigin,
    currentViewIndex: state.results.currentViewIndex,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setResultsGeotiffVisibility: (payload) =>
      dispatch(setResultsGeotiffVisibility(payload)),
    hideResultsGeotiffLegend: () => dispatch(hideResultsGeotiffLegend()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(StaticDriverLegend));
