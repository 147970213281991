import { HubConnectionBuilder } from "@microsoft/signalr";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addNotification,
  addCreationNotification,
} from "../../redux/actions/notificationActions";
import authService from "../../utils/auth/authService";

const notificationHubUrl = `${process.env.REACT_APP_FRONT_SERVICE_API}/notifications/rasterizations`;

const NotificationProvider = () => {
  const [connection, setConnection] = useState(null);
  const user = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const start = () => {
    if (connection) {
      if (connection.connectionState === "Connected") {
        return;
      }
      connection
        .start()
        .then((result) => {
          connection.on("RasterPreviewNotificationReceived", (message) => {
            const rasterizationNotification = {
              id: message.requestId,
              type: "rasterPreview",
              body: message,
              ts: message.timestampUtc,
            };
            dispatch(addNotification(rasterizationNotification));
          });
          connection.on("StaticDriverNotificationReceived", (message) => {
            const staticDriverNotification = {
              id: message.requestId,
              type: "staticDriver",
              body: message,
              ts: message.timestampUtc,
            };
            dispatch(addNotification(staticDriverNotification));
          });
          connection.on("GeojsonCheckNotificationReceived", (message) => {
            const geoJsonCheckNotification = {
              id: message.requestId,
              type: "geojsonCheck",
              status: message.status,
              error: message.error,
              ts: message.timestampUtc,
              resultUrl: message.resultUrl,
            };
            dispatch(addCreationNotification(geoJsonCheckNotification));
          });
          connection.on(
            "TopographyConversionNotificationReceived",
            (message) => {
              const toppographyNotification = {
                id: message.requestId,
                type: "TopographyConversionNotificationReceived",
                status: message.status,
                error: message.error,
                ts: message.timestampUtc,
                resultUrl: message.resultUrl,
              };
              dispatch(addCreationNotification(toppographyNotification));
            }
          );
          connection.onclose(() => {
            setTimeout(() => start(), 3000);
          });
        })
        .catch(async (e) => {
          console.log(
            "Connection failed: ",
            JSON.stringify(e),
            e.statusCode,
            new Date()
          );
          setTimeout(() => start(), 3000);
        });
    }
  };
  useEffect(() => {
    async function subscribeToHub() {
      const newConnection = new HubConnectionBuilder()
        .withUrl(`${notificationHubUrl}`, {
          accessTokenFactory: () => {
            return authService.acquireToken();
          },
        })
        .build();
      setConnection(newConnection);
    }
    if (!user || !user.identity) {
      return;
    }
    subscribeToHub();
  }, [user]);

  useEffect(() => {
    start();
  }, [connection]);

  return null;
};

export default NotificationProvider;
