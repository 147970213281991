import React from "react";
import {
  ResultsStepInput,
  ResultsTextInput,
} from "../../../../../../Components/Map/Form/Select";
import { ChromePicker } from "react-color";
import {
  setLayerCategoryColor,
  setLayerOpacity,
  changeCustomLayerBreakName,
  changeCustomLayerBreakValue,
  changeCustomLayerMin,
} from "../../../../../../redux/actions/resultsActions";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import "../../../../../../Components/Common/PaletteDropdown/index.scss";

class CategoryLayer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isColorPicker: false,
      color: "#32a852",
      colorPickerIndex: null,
    };

    this.openColorPicker = this.openColorPicker.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChangeComplete = this.handleChangeComplete.bind(this);
  }

  openColorPicker(i) {
    this.setState({ isColorPicker: true, colorPickerIndex: i });
  }

  handleClose() {
    this.setState({ isColorPicker: false });
  }

  handleChangeComplete(e, i) {
    this.setState({ color: e });
    this.props.setLayerCategoryColor({
      color: e.hex,
      indexOfCategory: this.state.colorPickerIndex,
    });
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <div
          style={{
            width: 320,
            //  padding: "0px 40px"
          }}
        >
          <ResultsTextInput
            label={t("LayerName")}
            value={t(this.props.jsonOriginPartSelected.name)}
            visSettings
          />
          <hr className="raster-settings-ruler" />
          <ResultsTextInput
            label={t("LayerMin")}
            value={t(this.props.jsonOriginPartSelected.plot_min)}
            onChange={(e) => {
              this.props.changeCustomLayerMin(e.target.value);
            }}
            visSettings
            disabled={this.props.jsonOriginPartSelected.set_sl ? true : false}
          />
          <hr
            className="raster-settings-ruler"
            style={{ marginTop: 11, marginBottom: 11 }}
          />
          {!this.props.jsonOriginPartSelected.set_sl
            ? this.props.jsonOriginPartSelected.plot_preset_names.map(
                (x, i) => (
                  <>
                    <ResultsTextInput
                      label={t("Break")}
                      value={
                        this.props.jsonOriginPartSelected.plot_preset_names[i]
                      }
                      onChange={(e) => {
                        this.props.changeCustomLayerBreakName({
                          breakName: e.target.value,
                          indexOfBreak: i,
                        });
                      }}
                      visSettings
                      disabled
                    />
                    <ResultsTextInput
                      label={t("Value")}
                      value={
                        this.props.jsonOriginPartSelected.plot_preset_breaks[i]
                      }
                      onChange={(e) => {
                        this.props.changeCustomLayerBreakValue({
                          breakValue: e.target.value,
                          indexOfBreak: i,
                        });
                      }}
                      visSettings
                      disabled
                    />
                    <div
                      className={`form-group form-results-group`}
                      style={{
                        justifyContent: "space-between",
                      }}
                    >
                      <label
                        key={x}
                        style={{ width: "auto" }}
                        className={`form-label form-results-label`}
                      >
                        {t("Color")}
                      </label>
                      <div
                        onClick={() => this.openColorPicker(i)}
                        style={{
                          width: "250px",
                          height: "22px",
                          backgroundColor: `${this.props.jsonOriginPartSelected.plot_preset_cols[i]}`,
                          borderRadius: "2px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                    <hr
                      className="raster-settings-ruler"
                      style={{ marginTop: 11, marginBottom: 11 }}
                    />
                  </>
                )
              )
            : this.props.jsonOriginPartSelected.plot_preset_cols.map((x, i) => {
                if (i % 2 !== 0) {
                  console.log(i);

                  return <></>;
                } else
                  return (
                    <>
                      <ResultsTextInput
                        label={t("Break")}
                        value={
                          this.props.jsonOriginPartSelected.plot_preset_names[i]
                        }
                        onChange={(e) => {
                          this.props.changeCustomLayerBreakName({
                            breakName: e.target.value,
                            indexOfBreak: i,
                          });
                        }}
                        visSettings
                        disabled
                      />
                      <ResultsTextInput
                        label={t("Value")}
                        value={
                          this.props.jsonOriginPartSelected.plot_preset_breaks[
                            i
                          ]
                        }
                        onChange={(e) => {
                          this.props.changeCustomLayerBreakValue({
                            breakValue: e.target.value,
                            indexOfBreak: i,
                          });
                        }}
                        visSettings
                        disabled
                      />
                      <div
                        className={`form-group form-results-group`}
                        style={{
                          justifyContent: "space-between",
                        }}
                      >
                        <label
                          key={x}
                          style={{ width: "auto" }}
                          className={`form-label form-results-label`}
                        >
                          {t("Color")}
                        </label>
                        <div
                          onClick={() => this.openColorPicker(i)}
                          style={{
                            width: "250px",
                            height: "22px",
                            backgroundColor: `${this.props.jsonOriginPartSelected.plot_preset_cols[i]}`,
                            borderRadius: "2px",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                      <hr
                        className="raster-settings-ruler"
                        style={{ marginTop: 11, marginBottom: 11 }}
                      />
                    </>
                  );
              })}
          <div style={{ marginTop: "28px" }} />

          <ResultsStepInput
            label={t("Opacity")}
            value={this.props.jsonOriginPartSelected.plot_opacity}
            style={{ width: "150px", borderRadius: "2px", height: "30px" }}
            onChange={(e) => {
              this.props.setLayerOpacity(e.target.value);
            }}
            min={0}
            max={1}
            step={0.1}
            visSettings
            onBlur={(e) => {
              if (
                e.target.value === "" ||
                Number(e.target.value) < 0 ||
                Number(e.target.value) > 1
              ) {
                this.props.setLayerOpacity(1);
              }
            }}
          />

          {this.state.isColorPicker && (
            <div
              style={
                !this.props.jsonOriginPartSelected.set_sl
                  ? {
                      position: "fixed",
                      zIndex: "2",
                      top: `${
                        420 +
                        this.state.colorPickerIndex * 42 -
                        this.state.colorPickerIndex
                      }px`,
                      right: "110px",
                    }
                  : {
                      position: "fixed",
                      zIndex: "2",
                      top: `${300 + this.state.colorPickerIndex * 20}px`,
                      right: "110px",
                    }
              }
              onDoubleClick={this.handleClose}
            >
              <div style={cover} onClick={this.handleClose} />
              <ChromePicker
                color={{
                  hex: this.props.jsonOriginPartSelected.plot_preset_cols[
                    this.state.colorPickerIndex
                  ],
                }}
                onChange={(e) =>
                  this.handleChangeComplete(e, this.state.colorPickerIndex)
                }
              />
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    jsonOriginPartSelected: state.results.jsonOriginPartSelected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLayerCategoryColor: (payload) =>
      dispatch(setLayerCategoryColor(payload)),
    setLayerOpacity: (payload) => dispatch(setLayerOpacity(payload)),
    changeCustomLayerBreakName: (payload) =>
      dispatch(changeCustomLayerBreakName(payload)),
    changeCustomLayerBreakValue: (payload) =>
      dispatch(changeCustomLayerBreakValue(payload)),
    changeCustomLayerMin: (payload) => dispatch(changeCustomLayerMin(payload)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(CategoryLayer)
);

const cover = {
  position: "fixed",
  top: "0px",
  right: "0px",
  bottom: "0px",
  left: "0px",
};
