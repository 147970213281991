import React from "react";
import L from "leaflet";
import "leaflet-geotiff-2";
import "leaflet-geotiff-2/dist/leaflet-geotiff-plotty";
import { withLeaflet, MapLayer } from "react-leaflet";

export const ExtraGeotiffLayer = withLeaflet((props) => {
  return <PlottyGeotiffLayer ref={props.onResultsReady} {...props} />;
});

export class PlottyGeotiffLayer extends MapLayer {
  createLeafletElement(props) {
    const {
      topographyUrl,
      displayMin,
      displayMax,
      colorName,
      zIndex,
      opacity,
    } = props

    let plottyRenderer = L.LeafletGeotiff.plotty({
      displayMin: displayMin,
      displayMax: displayMax,
      colorScale: colorName,
      opacity: opacity,
    });

    let leafletGeotiff = new L.leafletGeotiff(topographyUrl, {
      renderer: plottyRenderer,
      noDataValue: undefined,
      noDataKey: undefined,
      blockSize: 65536,
      opacity: opacity,
    });

    if (zIndex !== undefined) {
      leafletGeotiff.setZIndex(zIndex);
    }
    return leafletGeotiff;
  }

  componentDidMount() {
    const { map } = this.props.leaflet;
    this.leafletElement.addTo(map);
  }
}
