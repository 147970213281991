import {
  reprojectUtmToWgPoint,
  reprojectWgToUtmPoint,
  utm31Def,
  utm32Def,
  utm33Def,
} from "../../../common/utmDef";
import { allCrsOptions } from "../../Tools/OsmDownload/osmConst";

export const calculateSWWG = (
  centerLngUtm,
  centerLatUtm,
  gridWidth,
  gridXNumber,
  gridYNumber,
  crsDef
) => {
  const SWWG = [
    centerLngUtm - (gridWidth * gridYNumber) / 2,
    centerLatUtm - (gridWidth * gridXNumber) / 2,
  ];

  return reprojectUtmToWgPoint(SWWG, crsDef);
};

export const calculateNEWG = (
  centerLngUtm,
  centerLatUtm,
  gridWidth,
  gridXNumber,
  gridYNumber,
  crsDef
) => {
  const NEWG = [
    Number(centerLngUtm) + (gridWidth * gridYNumber) / 2,
    Number(centerLatUtm) + (gridWidth * gridXNumber) / 2,
  ];

  return reprojectUtmToWgPoint(NEWG, crsDef);
};

export const calculateRasterCenterWg = (centerLngUtm, centerLatUtm, crsDef) => {
  return reprojectUtmToWgPoint(
    [Number(centerLngUtm), Number(centerLatUtm)],
    crsDef
  );
};

export const calculateRasterCenterUtm = (centerLatWg, centerLngWg, crsDef) => {
  return reprojectWgToUtmPoint(
    {
      lat: centerLatWg.toFixed(6),
      lng: centerLngWg.toFixed(6),
    },
    crsDef
  );
};

export const calculateRasterAreaPolygonPoints = (
  centerLngUtm,
  centerLatUtm,
  gridWidth,
  gridXNumber,
  gridYNumber,
  crsDef
) => {
  const SWUTM = [
    Number(centerLngUtm) - (gridWidth * gridXNumber) / 2,
    Number(centerLatUtm) - (gridWidth * gridYNumber) / 2,
  ];

  const NWUTM = [
    Number(centerLngUtm) + (gridWidth * gridXNumber) / 2,
    Number(centerLatUtm) - (gridWidth * gridYNumber) / 2,
  ];

  const NEUTM = [
    Number(centerLngUtm) + (gridWidth * gridXNumber) / 2,
    Number(centerLatUtm) + (gridWidth * gridYNumber) / 2,
  ];

  const SEUTM = [
    Number(centerLngUtm) - (gridWidth * gridXNumber) / 2,
    Number(centerLatUtm) + (gridWidth * gridYNumber) / 2,
  ];

  const SWWG = reprojectUtmToWgPoint(SWUTM, crsDef);
  const NWWG = reprojectUtmToWgPoint(NWUTM, crsDef);
  const NEWG = reprojectUtmToWgPoint(NEUTM, crsDef);
  const SEWG = reprojectUtmToWgPoint(SEUTM, crsDef);

  return [
    [Number(SWWG.lat.toFixed(6)), Number(SWWG.lng.toFixed(6))],
    [Number(NWWG.lat.toFixed(6)), Number(NWWG.lng.toFixed(6))],
    [Number(NEWG.lat.toFixed(6)), Number(NEWG.lng.toFixed(6))],
    [Number(SEWG.lat.toFixed(6)), Number(SEWG.lng.toFixed(6))],
  ];
};

export const calculateRasterCenterWgFromDb = (
  yMinUtm,
  yMaxUtm,
  xMinUtm,
  xMaxUtm,
  epsg
) => {
  const latUtm = (yMaxUtm + yMinUtm) / 2;
  const lngUtm = (xMaxUtm + xMinUtm) / 2;

  let crsDef = null;
  if (epsg === "25831") {
    crsDef = utm31Def;
  } else if (epsg === "25832") {
    crsDef = utm32Def;
  } else if (epsg === "25833") {
    crsDef = utm33Def;
  } else {
    crsDef = allCrsOptions.find((x) => x.value === `EPSG:${epsg}`).def;
  }

  return reprojectUtmToWgPoint([lngUtm, latUtm], crsDef);
};

export const validateParentOffset = (
  xMinChild,
  xMaxChild,
  xMinParent,
  xMaxParent,
  yMinChild,
  yMaxChild,
  yMinParent,
  yMaxParent,
  gridSizeParent
) => {
  if (xMinChild > xMinParent + 30 * gridSizeParent) {
    return {
      isOffsetValid: false,
      offsetError: "ErrorOnEastBorder",
    };
  } else if (xMaxChild < xMaxParent - 30 * gridSizeParent) {
    return {
      isOffsetValid: false,
      offsetError: "ErrorOnWestBorder",
    };
  } else if (yMinChild > yMinParent + 30 * gridSizeParent) {
    return {
      isOffsetValid: false,
      offsetError: "ErrorOnSouthBorder",
    };
  } else if (yMaxChild < yMaxParent - 30 * gridSizeParent) {
    return {
      isOffsetValid: false,
      offsetError: "ErrorOnNorthBorder",
    };
  }

  return {
    isOffsetValid: true,
    offsetError: "",
  };
};

export const calculateBufferAreaPolygonPoints = (
  centerLngUtm,
  centerLatUtm,
  gridWidthRaster,
  gridXNumber,
  gridYNumber,
  gridPointsNorth,
  gridPointsSouth,
  gridPointsWest,
  gridPointsEast,
  crsDef
) => {
  const SWUTM = [
    centerLngUtm +
      gridPointsWest * gridWidthRaster -
      (gridWidthRaster * gridXNumber) / 2,
    centerLatUtm +
      gridPointsSouth * gridWidthRaster -
      (gridWidthRaster * gridYNumber) / 2,
  ];

  const SEUTM = [
    centerLngUtm -
      gridPointsEast * gridWidthRaster +
      (gridWidthRaster * gridXNumber) / 2,
    centerLatUtm +
      gridPointsSouth * gridWidthRaster -
      (gridWidthRaster * gridYNumber) / 2,
  ];

  const NEUTM = [
    centerLngUtm -
      gridPointsEast * gridWidthRaster +
      (gridWidthRaster * gridXNumber) / 2,
    centerLatUtm -
      gridPointsNorth * gridWidthRaster +
      (gridWidthRaster * gridYNumber) / 2,
  ];

  const NWUTM = [
    centerLngUtm +
      gridPointsWest * gridWidthRaster -
      (gridWidthRaster * gridXNumber) / 2,
    centerLatUtm -
      gridPointsNorth * gridWidthRaster +
      (gridWidthRaster * gridYNumber) / 2,
  ];

  const SWWG = reprojectUtmToWgPoint(SWUTM, crsDef);
  const NWWG = reprojectUtmToWgPoint(NWUTM, crsDef);
  const NEWG = reprojectUtmToWgPoint(NEUTM, crsDef);
  const SEWG = reprojectUtmToWgPoint(SEUTM, crsDef);

  return [
    [Number(SWWG.lat.toFixed(6)), Number(SWWG.lng.toFixed(6))],
    [Number(NWWG.lat.toFixed(6)), Number(NWWG.lng.toFixed(6))],
    [Number(NEWG.lat.toFixed(6)), Number(NEWG.lng.toFixed(6))],
    [Number(SEWG.lat.toFixed(6)), Number(SEWG.lng.toFixed(6))],
  ];
};
