export const types = {
  TOGGLE_TOOLS: "TOGGLE_TOOLS",
  TOGGLE_DRAW_EXTENT: "TOGGLE_DRAW_EXTENT",
  START_AREA: "START_AREA",
  FINISH_AREA: "FINISH_AREA",
  DELETE_AREA: "DELETE_AREA",
  GET_OSM_CURSOR_COORDS: "GET_OSM_CURSOR_COORDS",
  SET_OSM_CRS: "SET_OSM_CRS",
  TOGGLE_OBJECT_TO_REQUEST: "TOGGLE_OBJECT_TO_REQUEST",
  SET_BLDG_METHOD: "SET_BLDG_METHOD",
  SET_BLDG_STOREY_HEIGHT: "SET_BLDG_STOREY_HEIGHT",
  SET_BLDG_FILL_VALUE: "SET_BLDG_FILL_VALUE",
  SET_TREE_METHOD: "SET_TREE_METHOD",
  SET_TREE_PATCH_MISS_VALUE: "SET_TREE_PATCH_MISS_VALUE",
  SET_TREE_MISS_VALUE: "SET_TREE_MISS_VALUE",
  SET_ROAD_WIDTH_METHOD: "SET_ROAD_WIDTH_METHOD",
  SET_ROAD_WIDTH_LANES: "SET_ROAD_WIDTH_LANES",

  ADD_OSM_NOTIFICATION: "ADD_OSM_NOTIFICATION",
};
