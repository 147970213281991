import { apiRequest } from "./axiosApi";

const apiUrl = process.env.REACT_APP_FRONT_SERVICE_API;

export async function getUserRoles() {
  const response = await apiRequest({
    method: "get",
    url: `${apiUrl}/roles`,
  });
  return response.data;
}