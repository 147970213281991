import * as React from "react";
import { AuthenticatedApp } from "./AuthenticatedApp";
import { Provider } from "react-redux";
import { InteractionType } from "@azure/msal-browser";
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import store from "./redux/store";
import ErrorBoundary from "./Components/Error/ErrorBoundary";
import authService from "./utils/auth/authService";
import "./App.css";

function App() {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <MsalProvider instance={authService.msalClient}>
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={{prompt: 'select_account'}}
          >
            <AuthenticatedApp></AuthenticatedApp>
          </MsalAuthenticationTemplate>
        </MsalProvider>
      </Provider>
    </ErrorBoundary>
  );
}

export default App;
