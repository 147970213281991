import React from "react";
import L from "leaflet";
import { Marker, Pane } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import {
  selectMapPoi,
  updateMapPoiPositionOnDragend,
  enableMapPoiDraggable,
} from "../../../../redux/actions/mapActions";
import { connect } from "react-redux";

class MapPoiDrawing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.createClusterCustomIcon = this.createClusterCustomIcon.bind(this);
  }

  componentDidMount() {
    if (
      this.props.inMapPoiDrawingMode &&
      this.props.mapRef.current.leafletElement.getZoom() === 19
    ) {
      this.props.enableMapPoiDraggable();
    }
  }

  createClusterCustomIcon(cluster) {
    return L.divIcon({
      html: `<div><span>${cluster.getChildCount()}</span></div>`,
      className: "marker-cluster marker-cluster-drawn-map-poi span",
      iconSize: L.point(40, 40, true),
    });
  }

  render() {
    return (
      <>
        <Pane className="tree-area">
          {this.props.allDrawnMapPoiVisibility &&
            (this.props.allDrawnMapPoiCoordinatesWg.length > 0 &&
            this.props.isMapPoiDraggable ? (
              this.props.allDrawnMapPoiCoordinatesWg.map((x, idx) => (
                <Marker
                  position={[x[0], x[1]]}
                  icon={
                    this.props.selectedMapPoiIndex === idx
                      ? highlightedMapPoiMarker
                      : mapPoiMarker
                  }
                  zIndexOffset={
                    this.props.selectedMapPoiIndex === idx ? 10000 : 1000
                  }
                  objectTypeIndex={6}
                  markerIndex={idx}
                  onClick={
                    this.props.inRasterSettingsMode ||
                    this.props.displayMapPoiCursor
                      ? null
                      : !this.props.inMapPoiCopyPropsMode
                      ? () => this.props.selectMapPoi(idx)
                      : null
                  }
                  draggable={
                    this.props.inMapPoiDrawingMode &&
                    !this.props.displayMapPoiCursor
                  }
                  onDragend={(e) =>
                    this.props.updateMapPoiPositionOnDragend({
                      e: e.target._latlng,
                      idx: idx,
                    })
                  }
                />
              ))
            ) : (
              <MarkerClusterGroup
                iconCreateFunction={this.createClusterCustomIcon}
                disableClusteringAtZoom={19}
                showCoverageOnHover
                zoomToBoundsOnClick
                spiderfyOnMaxZoom={false}
              >
                {this.props.mapPoiDrawnVisibility &&
                  this.props.allDrawnMapPoiCoordinatesWg.map((x, idx) => (
                    <Marker
                      position={[x[0], x[1]]}
                      icon={
                        this.props.selectedMapPoiIndex === idx
                          ? highlightedMapPoiMarker
                          : mapPoiMarker
                      }
                      zIndexOffset={
                        this.props.selectedMapPoiIndex === idx ? 10000 : 1000
                      }
                      objectTypeIndex={6}
                      markerIndex={idx}
                      onClick={
                        this.props.inRasterSettingsMode ||
                        this.props.displayMapPoiCursor
                          ? null
                          : !this.props.inMapPoiCopyPropsMode
                          ? () => this.props.selectMapPoi(idx)
                          : () =>
                              this.props.addAssignedSingleMapPoiCopy({
                                properties: {
                                  id: this.props.arrayOfMapPoiId[idx],
                                },
                                geometry: {
                                  coordinates:
                                    this.props.allDrawnMapPoiCoordinatesWg[idx],
                                },
                              })
                      }
                      draggable={false}
                      onDragend={(e) =>
                        this.props.updateMapPoiPositionOnDragend({
                          e: e.target._latlng,
                          idx: idx,
                        })
                      }
                    />
                  ))}
              </MarkerClusterGroup>
            ))}
          {this.props.inMapPoiDrawingMode &&
            this.props.currentCursorCoords &&
            this.props.displayMapPoiCursor && (
              <Marker
                icon={mapPoiMarker}
                position={this.props.currentCursorCoords}
                zIndex={1}
                zIndexOffset={1}
              ></Marker>
            )}
        </Pane>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentCursorCoords: state.map.currentCursorCoords,
    allDrawnMapPoiVisibility: state.legend.allDrawnMapPoiVisibility,
    allDrawnMapPoiCoordinatesWg: state.map.allDrawnMapPoiCoordinatesWg,
    selectedMapPoiIndex: state.map.selectedMapPoiIndex,
    inRasterSettingsMode: state.map.inRasterSettingsMode,
    inMapPoiDrawingMode: state.map.inMapPoiDrawingMode,
    inMapPoiCopyPropsMode: state.map.inMapPoiCopyPropsMode,
    arrayOfMapPoiId: state.map.arrayOfMapPoiId,
    isGeoJsonVisible: state.rasterArea.isGeoJsonVisible,
    displayMapPoiCursor: state.map.displayMapPoiCursor,
    isMapPoiDraggable: state.map.isMapPoiDraggable,
    mapPoiDrawnVisibility: state.map.mapPoiDrawnVisibility,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectMapPoi: (payload) => dispatch(selectMapPoi(payload)),
    updateMapPoiPositionOnDragend: (payload) =>
      dispatch(updateMapPoiPositionOnDragend(payload)),
    enableMapPoiDraggable: () => dispatch(enableMapPoiDraggable()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MapPoiDrawing);

const mapPoiMarker = new L.Icon({
  iconUrl: require("./drawn-map-poi-icon.svg"),
  iconAnchor: [10, 10],
  popupAnchor: [0, 0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(20, 20),
  className: "tree-style",
});

const highlightedMapPoiMarker = new L.Icon({
  iconUrl: require("./drawn-highlighted-map-poi-icon.svg"),
  iconAnchor: [10, 10],
  popupAnchor: [0, -10],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(20, 20),
  className: "tree-style",
});

export { mapPoiMarker, highlightedMapPoiMarker };
