import React from "react";
import ColorInput from "../Form/Inputs/ColorInput/Index";
import { useTranslation } from "react-i18next";
import "./PaletteTable.scss";

export const PaletteTable = (props) => {
  const { t } = useTranslation();

  return (
    <div className="palette-grid">
      <div key="blank" className="cell cell-lt"></div>
      <ColumnHeader key="col1" label={t("Unselected")} />
      <ColumnHeader key="col2" className="cell-rt" label={t("Selected")} />
      {props.rows.map((r) => {
        return [
          <RowLabel key={`label_${r.label}`} label={r.label} />,
          <PalettePicker
            key={`col1_${r.label}`}
            color={r.unselected}
            onChange={(c) => props.onUnselectedChange(r, c)}
          />,
          <PalettePicker
            key={`col2_${r.label}`}
            color={r.selected}
            onChange={(c) => props.onSelectedChange(r, c)}
          />,
        ];
      })}
    </div>
  );
};
const ColumnHeader = ({ label, className }) => {
  return (
    <div className={`cell ${className ? ` ${className}` : ""}`}>{label}</div>
  );
};

const RowLabel = ({ label, className }) => {
  return (
    <div
      className={`cell pallete-row__label ${className ? ` ${className}` : ""}`}
    >
      {label}
    </div>
  );
};

const PalettePicker = (props) => {
  const handlePickerChange = (color) => {
    props.onChange(color.target.value);
  };

  return (
    <div className={`cell ${props.className ? ` ${props.className}` : ""}`}>
      <ColorInput value={props.color} onChange={handlePickerChange} />
    </div>
  );
};
