import React, { useRef, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { updateWindTable } from "../../redux/actions/windActions";
import { useSelector, useDispatch } from "react-redux";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./WindTable.scss";

const WindTable = (props) => {
  const gridRef = useRef();
  const dispatch = useDispatch();

  const windTable = useSelector((state) => state.wind.windTable);
  const windHeader = useSelector((state) => state.wind.windHeader);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const { t } = useTranslation();

  const columnDefs = [
    {
      headerName: `${t("WindVelocity")} [m / s]`,
      headerClass: "wind-parent-hdr",
      children: [
        {
          //headerName: `${t("Direction")}`,
          headerName: ``,
          field: "direction",
          editable: false,
          headerClass: "wind-table-first-hdr",
          cellClass: "wind-table-dir-col",
          //cellRenderer: "iconComponent",
        },
        ...windHeader,
      ],
    },
  ];

  const numberFormatter = (params) => {
    return params.value?.toString();
  };

  const defaultColDef = useMemo(() => {
    return {
      editable: true,
      headerClass: "wind-hdr",
      cellClass: "wind-cell",
      suppressMovable: true,
      valueFormatter: numberFormatter,
      valueSetter: (params) => {
        if (!isNaN(params.newValue)) {
          const rowData = params.data;
          rowData[params.colDef.field] = params.newValue;
          windTable[params.node.rowIndex] = rowData;
          dispatch(updateWindTable(windTable));
          return true;
        }
        return false;
      },
    };
  }, []);

  return (
    <div className="ag-theme-alpine" style={gridStyle}>
      <AgGridReact
        {...props}
        ref={gridRef}
        rowData={windTable}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        suppressHorizontalScroll
        frameworkComponents={{ iconComponent: CompassIcon }}
        onGridReady={(params) => {
          params.api.sizeColumnsToFit();
          window.onresize = debounce(() => {
            params.api.sizeColumnsToFit();
          }, 200);
        }}
        overlayNoRowsTemplate={t("NoRowsToShow")}
        headerHeight={30}
        rowHeight={30}
        domLayout={"autoHeight"}
      ></AgGridReact>
    </div>
  );
};

export default WindTable;

export const CompassIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="15"
        viewBox="0 0 3.969 3.969"
      >
        <circle
          r="1.553"
          cy="1.984"
          cx="1.984"
          stroke-linejoin="round"
          fill="#fff"
          stroke="#38f"
          stroke-width=".416"
        />
      </svg>
    </>
  );
};
