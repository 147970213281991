import React from "react";
import Select from "react-select";
import {
  ExpertHelpLabel,
  ExpertTempAccordion,
} from "../../ExpertUtils/ExpertComponent";
import { selectSetupStyle } from "../../../../Components/Common/PaletteDropdown/index";
import { helpLink } from "../../expertConst";
import { changeExpertInput } from "../../../../redux/actions/expertActions";
import { useSelector, useDispatch } from "react-redux";

const [page, subpage] = ["modules", "urban_surface"];

const UrbanSurface = () => {
  const urban_surface = useSelector(
    (state) => state.expert.expertJson.modules.urban_surface
  );
  const dispatch = useDispatch();

  return (
    <>
      <ExpertHelpLabel label={"Activate Module"} helpLink={""} />
      <Select
        isSearchable={false}
        styles={selectSetupStyle}
        options={[
          {
            value: "True",
            label: "True",
          },
          { value: "False", label: "False" },
        ]}
        value={{
          label: !!urban_surface.isActive ? "True" : "False",
          value: !!urban_surface.isActive ? "True" : "False",
        }}
        onChange={(e) =>
          dispatch(
            changeExpertInput({
              page: page,
              subpage: subpage,
              key: "",
              subkey: "isActive",
              value: e.value === "True" ? true : false,
            })
          )
        }
      />
      <div style={{ marginBottom: 12 }} />
      {urban_surface.isActive && (
        <>
          <ExpertHelpLabel
            label={"Deep soil temperature [K]"}
            helpLink={`${helpLink}/urban_surface_parameters#soil_inner_temperature`}
          />
          <ExpertTempAccordion
            page={page}
            subpage={subpage}
            section="parameters"
            subkey="soil_inner_temperature"
            min={273.14}
            max={373}
            step={0.01}
            constValue={urban_surface.parameters.soil_inner_temperature}
          />

          <div style={{ marginBottom: 12 }}></div>

          <ExpertHelpLabel
            label={"Inner temperature of walls [K]"}
            helpLink={`${helpLink}/urban_surface_parameters#wall_inner_temperature`}
          />
          <ExpertTempAccordion
            page={page}
            subpage={subpage}
            section="parameters"
            subkey="wall_inner_temperature"
            min={273.15}
            max={373}
            step={0.01}
            constValue={urban_surface.parameters.wall_inner_temperature}
          />
          <div style={{ marginBottom: 12 }}></div>

          <ExpertHelpLabel
            label={"Inner temperature of windows [K]"}
            helpLink={`${helpLink}/urban_surface_parameters#window_inner_temperature`}
          />
          <ExpertTempAccordion
            page={page}
            subpage={subpage}
            section="parameters"
            subkey="window_inner_temperature"
            min={273.15}
            max={373}
            step={0.01}
            constValue={urban_surface.parameters.window_inner_temperature}
          />
          <div style={{ marginBottom: 12 }}></div>
          <ExpertHelpLabel
            label={"Inner temperature of roofs [K]"}
            helpLink={`${helpLink}/urban_surface_parameters#roof_inner_temperature`}
          />
          <ExpertTempAccordion
            page={page}
            subpage={subpage}
            section="parameters"
            subkey="roof_inner_temperature"
            min={273.15}
            max={373}
            step={0.01}
            constValue={urban_surface.parameters.roof_inner_temperature}
          />
          <div style={{ marginBottom: 12 }} />
          <ExpertHelpLabel
            label={"Wall conductivity reduction during Spinup"}
            helpLink={`${helpLink}/urban_surface_parameters#usm_wall_mod`}
          />
          <Select
            isSearchable={false}
            styles={selectSetupStyle}
            options={[
              {
                value: "True",
                label: "True",
              },
              { value: "False", label: "False" },
            ]}
            value={{
              label: !!urban_surface.parameters.usm_wall_mod ? "True" : "False",
              value: !!urban_surface.parameters.usm_wall_mod ? "True" : "False",
            }}
            onChange={(e) =>
              dispatch(
                changeExpertInput({
                  page: page,
                  subpage: subpage,
                  key: "parameters",
                  subkey: "usm_wall_mod",
                  value: e.value === "True" ? true : false,
                })
              )
            }
          />
        </>
      )}
    </>
  );
};

export default UrbanSurface;
