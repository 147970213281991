export const types = {
  CREATE_CASE_RESET: "CREATE_CASE_RESET",
  INIT_CREATE_CASE: "INIT_CREATE_CASE",
  INIT_CREATE_CASE_SUCCESS: "INIT_CREATE_CASE_SUCCESS",
  INIT_CLONE_CASE: "INIT_CLONE_CASE",
  INIT_CLONE_CASE_SUCCESS: "INIT_CLONE_CASE_SUCCESS",
  INIT_UPDATE_CASE: "INIT_UPDATE_CASE",
  INIT_UPDATE_CASE_SUCCESS: "INIT_UPDATE_CASE_SUCCESS",
  CREATE_CASE: "CREATE_CASE",
  CLONE_CASE: "CLONE_CASE",
  CREATE_CASE_SUCCESS: "CREATE_CASE_SUCCESS",
  CREATE_CASE_FAILURE: "CREATE_CASE_FAILURE",
  DELETE_CASE: "DELETE_CASE",
  DELETE_CASE_SUCCESS: "DELETE_CASE_SUCCESS",
  DELETE_CASE_FAILURE: "DELETE_CASE_FAILURE",
  UPDATE_CASE: "UPDATE_CASE",
  UPDATE_CASE_SUCCESS: "UPDATE_CASE_SUCCESS",
  UPDATE_CASE_FAILURE: "UPDATE_CASE_FAILURE",
  CASE_CHECK_GEOJSON: "CASE_CHECK_GEOJSON",
  CASE_CONVERT_TOPOGRAPHY: "CASE_CONVERT_TOPOGRAPHY",
  SET_CASE_CHECK_GEOJSON_REQUEST: "SET_CASE_CHECK_GEOJSON_REQUEST",
  SET_CASE_CONVERT_TOPOGRAPHY_REQUEST: "SET_CASE_CONVERT_TOPOGRAPHY_REQUEST",
  CASE_UPLOAD_GEOJSON: "CASE_UPLOAD_GEOJSON",
  CASE_UPLOAD_GEOJSON_SUCCESS: "CASE_UPLOAD_GEOJSON_SUCCESS",
  CASE_UPLOAD_TOPOGRAPHY: "CASE_UPLOAD_TOPOGRAPHY",
  CASE_UPLOAD_TOPOGRAPHY_SUCCESS: "CASE_UPLOAD_TOPOGRAPHY_SUCCESS",
  SET_CASE_DRAWN_GEOJSON_URL: "SET_CASE_DRAWN_GEOJSON_URL"
};
