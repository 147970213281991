import React from "react";
import { ResultsSelect } from "../../../../../Components/Map/Form/Select";
import { downloadBlobAsync } from "../../../../../api/storageApi";
import { unitsJson } from "../../../../../JSON/units";
import {
  changeNewLayerDiffCase,
  changeNewLayerDiffPage,
  changeNewDiffLayer,
  changeNewLayerDiffChildCase,
  changeNewLayerDiffChildPage,
  changeNewDiffChildLayer,
  changeDiffType,
  changeDiffUnit,
  loadNewLayerDiffCaseData,
  loadNewLayerDiffChildCaseData,
} from "../../../../../redux/actions/resultsActions";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class DifferenceLayerSettingsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newLayerName: "layer default",
      layerNameError: null,
      isProcessing: false,
      newLayerResultInfo: null,
    };

    this.onCaseChange = this.onCaseChange.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onLayerChange = this.onLayerChange.bind(this);
    this.onChildCaseChange = this.onChildCaseChange.bind(this);
    this.onChildPageChange = this.onChildPageChange.bind(this);
    this.onChildLayerChange = this.onChildLayerChange.bind(this);
    this.onDiffTypeChange = this.onDiffTypeChange.bind(this);
    this.onDiffUnitChange = this.onDiffUnitChange.bind(this);
  }

  async componentDidUpdate(prevProps) {
    if (
      this.props.diffCaseId !== prevProps.diffCaseId &&
      this.props.diffCaseId
    ) {
      const diffCase = this.props.caseDetailsDto.find((x) => {
        return x.id === this.props.diffCaseId;
      });
      const resultsJsonUrl = diffCase.postProcessingJobs.find((x) => {
        return x.reportJsonUrl;
      }).reportJsonUrl;

      const resultsJson = await downloadBlobAsync(resultsJsonUrl);
      this.props.loadNewLayerDiffCaseData(resultsJson.data);
    }

    if (
      this.props.diffChildCaseId !== prevProps.diffChildCaseId &&
      this.props.diffChildCaseId
    ) {
      const diffChildCase = this.props.caseDetailsDto.find((x) => {
        return x.id === this.props.diffChildCaseId;
      });
      const resultsJsonUrl = diffChildCase.postProcessingJobs.find((x) => {
        return x.reportJsonUrl;
      }).reportJsonUrl;

      const resultsJson = await downloadBlobAsync(resultsJsonUrl);
      this.props.loadNewLayerDiffChildCaseData(resultsJson.data);
    }
  }

  onCaseChange(e) {
    this.props.changeNewLayerDiffCase({
      diffCaseName: e.target.value,
      diffCaseId: this.props.caseDetailsDto[e.target.selectedIndex].id,
    });
  }

  onPageChange(e) {
    this.props.changeNewLayerDiffPage({
      diffPage: e.target.value,
      diffPageIndex: e.target.selectedIndex,
    });
  }

  onLayerChange(e) {
    this.props.changeNewDiffLayer({
      diffLayer: e.target.value,
      diffLayerIndex: e.target.selectedIndex,
    });
  }

  onChildCaseChange(e) {
    this.props.changeNewLayerDiffChildCase({
      diffChildCaseName: e.target.value,
      diffChildCaseId: this.props.caseDetailsDto[e.target.selectedIndex].id,
    });
  }

  onChildPageChange(e) {
    this.props.changeNewLayerDiffChildPage({
      diffChildPage: e.target.value,
      diffChildPageIndex: e.target.selectedIndex,
    });
  }

  onChildLayerChange(e) {
    this.props.changeNewDiffChildLayer({
      diffChildLayer: e.target.value,
      diffChildLayerIndex: e.target.selectedIndex,
    });
  }

  onDiffTypeChange(e) {
    this.props.changeDiffType(e.target.value);
  }

  onDiffUnitChange(e) {
    this.props.changeDiffUnit(e.target.value);
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <p className="difference-header">{`${t("DifferenceLayer")} = ${t(
          "OriginLayer"
        )} - ${t("SubstractLayer")}`}</p>
        <h5
          className="raster-settings-header"
          style={{ marginBottom: "12px", marginTop: "15px" }}
        >{`${t("DataSelectionOrigin")}`}</h5>

        <ResultsSelect
          label={t("Case")}
          value={
            this.props.diffCase
              ? this.props.diffCase
              : {
                  label: this.props.currentCase.name,
                  value: this.props.currentCase.id,
                }
          }
          options={this.props.caseDetailsDto
            ?.filter((x) => {
              return x.postProcessingJobs && x.postProcessingJobs.length !== 0;
            })
            .map((y) => {
              return { value: y.name };
            })}
          onChange={this.onCaseChange}
          optionsObject
        />
        <ResultsSelect
          label={t("Page")}
          value={this.props.diffPage}
          options={this.props.diffPageOptions.map((x) => {
            return { label: t(x.name), value: x.value };
          })}
          onChange={this.onPageChange}
          optionsObject
        />
        <ResultsSelect
          label={t("Layer")}
          value={this.props.diffLayer}
          options={this.props.diffLayerOptions.map((x) => {
            return { label: t(x.name), value: x.value };
          })}
          onChange={this.onLayerChange}
          optionsObject
        />
        <h5
          className="raster-settings-header"
          style={{ marginBottom: "12px", marginTop: "20px" }}
        >{`${t("DataSelectionSubstract")}`}</h5>
        <ResultsSelect
          label={t("Case")}
          value={
            this.props.diffChildCase
              ? this.props.diffChildCase
              : {
                  label: this.props.currentCase.name,
                  value: this.props.currentCase.id,
                }
          }
          options={this.props.caseDetailsDto
            ?.filter((x) => {
              return x.postProcessingJobs && x.postProcessingJobs.length !== 0;
            })
            .map((y) => {
              return { value: y.name };
            })}
          onChange={this.onChildCaseChange}
          optionsObject
        />
        <ResultsSelect
          label={t("Page")}
          value={this.props.diffChildPage}
          options={this.props.diffPageOptions.map((x) => {
            return { label: t(x.name), value: x.value };
          })}
          onChange={this.onChildPageChange}
          optionsObject
        />
        <ResultsSelect
          label={t("Layer")}
          value={this.props.diffChildLayer}
          options={this.props.diffChildLayerOptions.map((x) => {
            return { label: t(x.name), value: x.value };
          })}
          onChange={this.onChildLayerChange}
          optionsObject
        />
        <hr className="raster-settings-ruler" style={{ width: "300px" }} />
        <ResultsSelect
          label={t("DiffType")}
          value={this.props.diffType}
          options={[
            { label: t("abs"), value: "abs" },
            { label: t("rel"), value: "rel" },
          ]}
          onChange={this.onDiffTypeChange}
          optionsObject
        />
        <ResultsSelect
          label={t("DiffUnit")}
          value={this.props.diffUnit}
          options={unitsJson.l.map((x) => {
            return { label: `[${x[1]}]`, value: `[${x[1]}]` };
          })}
          onChange={this.onDiffUnitChange}
          optionsObject
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentCase: state.projects.currentCase,
    caseDetailsDto: state.projects.loadedProject?.caseDetailsDto,
    layerSettingsTypes: state.results.layerSettingsTypes,
    postProcessingJobs: state.projects.currentCase?.postProcessingJobs,
    newLayerTypeField: state.results.newLayerTypeField,
    resultsJsonOrigin: state.results.resultsJsonOrigin,
    currentViewIndex: state.results.currentViewIndex,
    resultsJsonCarouselOptions: state.results.resultsJsonCarouselOptions,
    diffCase: state.results.diffCase,
    diffCaseId: state.results.diffCaseId,
    diffPage: state.results.diffPage,
    diffPageOptions: state.results.diffPageOptions,
    diffLayer: state.results.diffLayer,
    diffLayerOptions: state.results.diffLayerOptions,
    diffChildCase: state.results.diffChildCase,
    diffChildCaseId: state.results.diffChildCaseId,
    diffChildPage: state.results.diffChildPage,
    diffChildLayer: state.results.diffChildLayer,
    diffChildLayerOptions: state.results.diffChildLayerOptions,
    diffType: state.results.diffType,
    diffUnit: state.results.diffUnit,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeNewLayerDiffCase: (payload) =>
      dispatch(changeNewLayerDiffCase(payload)),
    changeNewLayerDiffPage: (payload) =>
      dispatch(changeNewLayerDiffPage(payload)),
    changeNewDiffLayer: (payload) => dispatch(changeNewDiffLayer(payload)),
    changeNewLayerDiffChildCase: (payload) =>
      dispatch(changeNewLayerDiffChildCase(payload)),
    changeNewLayerDiffChildPage: (payload) =>
      dispatch(changeNewLayerDiffChildPage(payload)),
    changeNewDiffChildLayer: (payload) =>
      dispatch(changeNewDiffChildLayer(payload)),
    changeDiffType: (payload) => dispatch(changeDiffType(payload)),
    changeDiffUnit: (payload) => dispatch(changeDiffUnit(payload)),
    loadNewLayerDiffCaseData: (payload) =>
      dispatch(loadNewLayerDiffCaseData(payload)),
    loadNewLayerDiffChildCaseData: (payload) =>
      dispatch(loadNewLayerDiffChildCaseData(payload)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(DifferenceLayerSettingsForm)
);
