export const types = {
  SET_GRID_POINTS_Z_DIRECTION: "SET_GRID_POINTS_Z_DIRECTION",
  SET_SCENARIO: "SET_SCENARIO",
  SET_SURFACE_TEMPERATURE: "SET_SURFACE_TEMPERATURE",
  SET_SURFACE_HUMIDITY: "SET_SURFACE_HUMIDITY",
  SET_SPINUP_MEAN_TEMPERATURE: "SET_MEAN_SPINUP_MEAN_TEMPERATURE",
  SET_SPINUP_TEMPERATURE_AMPLITUDE: "SET_SPINUP_TEMPERATURE_AMPLITUDE",
  SET_VARIABLES_AVERAGING: "SET_VARIABLES_AVERAGING",
  SET_TOTAL_NUMBERS_OF_PROCESSORS: "SET_TOTAL_NUMBERS_OF_PROCESSORS",
  SET_NR_OF_X_PROCESSORS: "SET_NR_OF_X_PROCESSORS",
  SET_NR_OF_Y_PROCESSORS: "SET_NR_OF_Y_PROCESSORS",
  RESET_SIMULATION_SETTINGS: "RESET_SIMULATION_SETTINGS",
  LOAD_SIMULATION_SETTINGS: "LOAD_SIMULATION_SETTINGS",
  SET_SIMULATION_TIME: "SET_SIMULATION_TIME",
};
