import React from "react";
import { getFriendlyDateText } from "../../../../utils/date/dateHelpers";
import { ReactComponent as CloseIcon } from "../close.svg";
import "./index.scss";

const Notification = (props) => {
  return (
    !props.children ? null :
    <div className="notification">
      {props.children}
      <span className="notification__date">{`- ${getFriendlyDateText(props.timestamp)}`}</span>
      <CloseIcon
        className="notification__dismiss"
        onClick={props.onDismiss}
      />
    </div>
    
  );
};

export default Notification;
