import React from "react";
import Select from "react-select";
import { CheckCircle } from "react-feather";
import ProcessorSettings from "../../Components/Setup/ProcessorsSettings";
import { FormSettingsSeparator } from "../../Components/Common/Form/FormSeparator";
import { Loader } from "../../Components/Loader";
import { getCaseSetup, postCaseSetup } from "../../api/simulationApi";
import { selectSetupStyle } from "../../Components/Common/PaletteDropdown/index";
import * as simulationSettingsActions from "../../redux/actions/simulationSettingsActions";
import { calculateRasterCenterWgFromDb } from "../Domain/raster-area-helpers/index";
import { cloneDeep } from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import "./Setup.scss";

class Setup extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isSaving: false, saveCompleted: false };

    this.saveCaseSetup = this.saveCaseSetup.bind(this);
  }

  async componentDidMount() {
    if (!this.props.currentCaseId) {
      return;
    }
    const simulationCaseSettings = await getCaseSetup(this.props.currentCaseId);

    if (typeof simulationCaseSettings === "string") {
      this.props.resetSimulationSettings();
    } else {
      this.props.loadSimulationSettings(simulationCaseSettings);
    }
  }

  async componentDidUpdate(prevProps) {
    if (
      this.props.currentCaseId &&
      prevProps.currentCaseId !== this.props.currentCaseId
    ) {
      const simulationCaseSettings = await getCaseSetup(
        this.props.currentCaseId
      );
      if (typeof simulationCaseSettings === "string") {
        this.props.resetSimulationSettings();
      } else {
        this.props.loadSimulationSettings(simulationCaseSettings);
      }
    }
  }

  async saveCaseSetup() {
    this.setState({ isSaving: true });
    try {
      const mandatoryVariablesAveraging = cloneDeep(
        this.props.simulationSettings.mandatoryVariablesAveraging
      );

      const variablesAveraging = cloneDeep(
        this.props.simulationSettings.variablesAveraging
      );

      const data_output_merged = mandatoryVariablesAveraging.concat(
        variablesAveraging
      );

      const endTime = cloneDeep(this.props.simulationSettings.simulationTime);
      const endTimeSeconds = endTime.hours * 60 * 60 + endTime.minutes * 60;

      let longitude = null;
      let latitude = null;

      if (!this.props.rasterArea.rasterCenterLatWg) {
        const rasterCenterWg = calculateRasterCenterWgFromDb(
          this.props.currentCase.rasterParameters.y_min,
          this.props.currentCase.rasterParameters.y_max,
          this.props.currentCase.rasterParameters.x_min,
          this.props.currentCase.rasterParameters.x_max,
          this.props.epsg
        );
        latitude = rasterCenterWg.lat.toFixed(6);
        longitude = rasterCenterWg.lng.toFixed(6);
      } else {
        latitude = Number(this.props.rasterArea.rasterCenterLatWg).toFixed(6);
        longitude = Number(this.props.rasterArea.rasterCenterLngWg).toFixed(6);
      }

      if (this.props.simulationSettings.scenario.value === "UserDefined") {
        await postCaseSetup({
          caseId: this.props.currentCaseId,
          settings: {
            initialization_parameters: {
              nx: this.props.rasterArea.rasterGridXNumber - 1,
              ny: this.props.rasterArea.rasterGridYNumber - 1,
              nz: this.props.simulationSettings.gridPointsZDirection.value,
              spinup_pt_mean:
                Number(this.props.simulationSettings.spinupMeanTemperature) +
                273.15,
              spinup_pt_amplitude: this.props.simulationSettings
                .spinupTemperatureAmplitude,
              pt_surface:
                Number(this.props.simulationSettings.surfaceTemperature) +
                273.15,
              q_surface: this.props.simulationSettings.surfaceHumidity,
              resolution: this.props.rasterArea.rasterGridWidth,
              longitude: longitude,
              latitude: latitude,
            },
            runtime_parameters: {
              npex: this.props.simulationSettings.nrOfXProcessors,
              npey: this.props.simulationSettings.nrOfYProcessors,
              data_output: data_output_merged,
              data_output_masks: data_output_merged,
              end_time: endTimeSeconds,
            },
          },
        });
      } else {
        await postCaseSetup({
          caseId: this.props.currentCaseId,
          settings: {
            initialization_parameters: {
              nx: this.props.rasterArea.rasterGridXNumber - 1,
              ny: this.props.rasterArea.rasterGridYNumber - 1,
              nz: this.props.simulationSettings.gridPointsZDirection.value,
              resolution: this.props.rasterArea.rasterGridWidth,
              longitude: longitude,
              latitude: latitude,
            },
            runtime_parameters: {
              npex: this.props.simulationSettings.nrOfXProcessors,
              npey: this.props.simulationSettings.nrOfYProcessors,
              data_output: data_output_merged,
              data_output_masks: data_output_merged,
              end_time: endTimeSeconds,
            },
          },
        });
      }
      this.setState({ saveCompleted: true });
      setTimeout(() => this.setState({ saveCompleted: false }), 5000);
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ isSaving: false });
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div className="page setup-page">
        <div className="settings-form">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h2>{t("Setup")}</h2>
            <div style={{ marginBottom: "-16px" }}></div>
            <FormSettingsSeparator label={t("Domain")} />
            <label className="form-label">{t("GridPointsZDirection")}</label>
            <Select
              styles={selectSetupStyle}
              value={{
                label: this.props.simulationSettings.gridPointsZDirection.label,
                value: this.props.simulationSettings.gridPointsZDirection.value,
              }}
              options={this.props.simulationSettings.zDirectionOptions}
              onChange={(value) => this.props.setGridPointZDirection(value)}
              isSearchable={false}
              //classNamePrefix="select"
            />
            <FormSettingsSeparator label={t("Time")} />
            <label className="form-label">{t("SimulationTime")}</label>
            <div className="setup-time-container">
              <div className="simulation-time-container">
                <input
                  className="simulation-time-input"
                  type="number"
                  min="0"
                  max="48"
                  value={
                    this.props.simulationSettings.simulationTime.hours < 10
                      ? "0" + this.props.simulationSettings.simulationTime.hours
                      : this.props.simulationSettings.simulationTime.hours
                  }
                  onChange={(e) =>
                    this.props.setSimulationTime({
                      hours: e.target.value,
                      minutes: this.props.simulationSettings.simulationTime
                        .minutes,
                    })
                  }
                  onKeyPress={(evt) => {
                    evt.preventDefault();
                  }}
                  dir="rtl"
                />
                <div className="simulation-time-separator"> : </div>
                <input
                  className="simulation-time-input"
                  type="number"
                  min="0"
                  max="59"
                  value={
                    this.props.simulationSettings.simulationTime.minutes < 10
                      ? "0" +
                        this.props.simulationSettings.simulationTime.minutes
                      : this.props.simulationSettings.simulationTime.minutes
                  }
                  onChange={(e) =>
                    this.props.setSimulationTime({
                      hours: this.props.simulationSettings.simulationTime.hours,
                      minutes: e.target.value,
                    })
                  }
                  onKeyPress={(evt) => {
                    evt.preventDefault();
                  }}
                />
              </div>
              <span className="time-unit">[h / min]</span>
            </div>
            <FormSettingsSeparator label={t("Date")} />
            <label className="form-label">{t("Scenario")}</label>
            <Select
              isSearchable={false}
              styles={selectSetupStyle}
              options={[
                { value: "TypicalSummerDay", label: t("TypicalSummerDay") },
                { value: "UserDefined", label: t("UserDefined") },
              ]}
              onChange={(value) => this.props.setScenario(value)}
              value={{
                label: t(`${this.props.simulationSettings.scenario.label}`),
                value: this.props.simulationSettings.scenario.value,
              }}
              maxMenuHeight="500px"
            />
            {this.props.simulationSettings.scenario.value === "UserDefined" && (
              <>
                <div className="horizontal-settings-ruler" />
                <label className="form-label">
                  {t("SurfaceTemperatureAt")} 21:00 [°C]
                </label>
                <input
                  className="setup-input"
                  type="number"
                  name="num"
                  min="-99"
                  max="99"
                  step="0.1"
                  value={this.props.simulationSettings.surfaceTemperature}
                  onChange={(e) =>
                    this.props.setSurfaceTemperature(e.target.value)
                  }
                />
                <label className="form-label">
                  {t("SurfaceHumidityAt")} 21:00 [%]
                </label>
                <input
                  className="setup-input"
                  type="number"
                  name="num"
                  min="1"
                  max="99"
                  step="0.1"
                  value={this.props.simulationSettings.surfaceHumidity}
                  onChange={(e) =>
                    this.props.setSurfaceHumidity(e.target.value)
                  }
                />
                <div className="horizontal-settings-ruler" />
                <label className="form-label">{t("SpinupPtMean")} [°C]</label>
                <input
                  className="setup-input"
                  type="number"
                  name="num"
                  min="-99"
                  max="99"
                  step="0.1"
                  value={this.props.simulationSettings.spinupMeanTemperature}
                  onChange={(e) =>
                    this.props.setSpinupMeanTemperature(e.target.value)
                  }
                />
                <label className="form-label">{t("SpinupPtAmplitude")}</label>
                <input
                  className="setup-input"
                  type="number"
                  name="num"
                  min="0"
                  max="50"
                  step="0.1"
                  value={
                    this.props.simulationSettings.spinupTemperatureAmplitude
                  }
                  onChange={(e) =>
                    this.props.setSpinupTemperatureAmplitude(e.target.value)
                  }
                />
              </>
            )}
            <FormSettingsSeparator label={t("Results")} />
            <label className="form-label">{t("VariablesAveraging")}:</label>
            <Select
              isMulti
              menuPlacement="top"
              isClearable
              closeMenuOnSelect={false}
              styles={selectSetupStyle}
              placeholder={`${t("Select")}...`}
              options={[
                {
                  label: `${t("Mandatory")}`,
                  options: this.props.simulationSettings
                    .mandatoryVariablesAveraging,
                },
                {
                  label: `${t("Optional")}`,
                  options: this.props.simulationSettings
                    .optionalVariablesAveraging,
                },
              ]}
              onChange={(e) => this.props.setVariablesAveraging(e)}
              value={this.props.simulationSettings.variablesAveraging}
              classNamePrefix="select"
            />
          </div>
          <ProcessorSettings />
          <div className="setup-save-wrapper">
            <button
              id="setup-btn__save"
              className="wizard-button wizard-button-submit"
              onClick={this.saveCaseSetup}
              disabled={
                this.props.simulationSettings.nrOfXProcessors *
                  this.props.simulationSettings.nrOfYProcessors >
                54
                  ? true
                  : false
              }
            >
              <span>{t("Save")}</span>
              {this.state.isSaving && <Loader size="x-small" mode="light" />}
            </button>
            {this.state.saveCompleted && (
              <div className="setup-save-status-wrapper">
                <CheckCircle
                  width="17"
                  stroke="rgb(6,137,108)"
                  strokeWidth="2"
                />
                <div className="save-status-text">{t("SetupSaved")}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    simulationSettings: state.simulationSettings,
    rasterArea: state.rasterArea,
    currentCaseId: state.projects.currentCaseId,
    epsg: state.projects.loadedProject?.epsg,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setGridPointZDirection: (payload) =>
      dispatch(simulationSettingsActions.setGridPointZDirection(payload)),
    setScenario: (payload) =>
      dispatch(simulationSettingsActions.setScenario(payload)),
    setSurfaceTemperature: (payload) =>
      dispatch(simulationSettingsActions.setSurfaceTemperature(payload)),
    setSurfaceHumidity: (payload) =>
      dispatch(simulationSettingsActions.setSurfaceHumidity(payload)),
    setSpinupMeanTemperature: (payload) =>
      dispatch(simulationSettingsActions.setSpinupMeanTemperature(payload)),
    setSpinupTemperatureAmplitude: (payload) =>
      dispatch(
        simulationSettingsActions.setSpinupTemperatureAmplitude(payload)
      ),
    setVariablesAveraging: (payload) =>
      dispatch(simulationSettingsActions.setVariablesAveraging(payload)),
    setTotalNumbersOfProcessors: (payload) =>
      dispatch(simulationSettingsActions.setTotalNumbersOfProcessors(payload)),
    setNrOfXProcessors: (payload) =>
      dispatch(simulationSettingsActions.setNrOfXProcessors(payload)),
    setNrOfYProcessors: (payload) =>
      dispatch(simulationSettingsActions.setNrOfYProcessors(payload)),
    loadSimulationSettings: (payload) =>
      dispatch(simulationSettingsActions.loadSimulationSettings(payload)),
    resetSimulationSettings: () =>
      dispatch(simulationSettingsActions.resetSimulationSettings()),
    setSimulationTime: (payload) =>
      dispatch(simulationSettingsActions.setSimulationTime(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(Setup)));
