import React from "react";
import { Pane, Polygon } from "react-leaflet";
import { connect } from "react-redux";

class BufferArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Pane className="raster-area" style={{ zIndex: 1000 }}>
        {this.props.rasterArea.isBufferAreaVisible && (
          <>
            {this.props.rasterArea.bufferAreaPolygonPoints && (
              <Polygon
                positions={this.props.rasterArea.bufferAreaPolygonPoints}
                color="#808080"
                fill={false}
                lineJoin={"round"}
                weight={3}
                dashArray="20"
              />
            )}
          </>
        )}
      </Pane>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rasterArea: state.rasterArea,
    crsDef: state.map.crsDef,
  };
};

export default connect(mapStateToProps, null)(BufferArea);
