import React from "react";
import StaticDriverIndicator from "./StaticDriverIndicator";
import SavingStatusIndicator from "./SavingStatusIndicator";

function MapPanelStatusBar() {
  return (
    <div className="map-panel-status-bar">
      <StaticDriverIndicator />
      <SavingStatusIndicator />
    </div>
  );
}

export default MapPanelStatusBar;
