const apiScope = process.env.REACT_APP_API_SCOPE;

export const authConfig = {
  auth: {
    clientId: process.env.REACT_APP_AD_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AD_TENANT_ID}`,
    redirectUri: process.env.REACT_APP_AD_REDIRECT_URI,
    postLogoutRedirectUri: process.env.REACT_APP_AD_LOGOUT_REDIRECT_URI,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" to save cache in cookies to address trusted zones limitations in IE (see: https://github.com/AzureAD/microsoft-authentication-library-for-js/wiki/Known-issues-on-IE-and-Edge-Browser)
  },
};

export const loginScopes = ["user.read", "email"];
export const graphAdScopes = ["user.read"];

export const propolisApiScopes = [apiScope];
