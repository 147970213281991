import React from "react";
import { RotateCw } from "react-feather";
import { requestSimulationLog } from "../../../../api/simulationApi";
import { downloadBlobAsync, getDownloadLink } from "../../../../api/storageApi";
import { withTranslation } from "react-i18next";

class SimulationStatus extends React.Component {
  constructor() {
    super();
    this.state = {
      simulationStatusDoc: null,
    };
  }
  async componentDidMount() {
    if (
      (this.props.simulationNotifications.results !== undefined &&
        this.props.simulationNotifications.results[
          this.props.simulationNotifications.results.length - 1
        ] &&
        this.props.simulationNotifications.results[
          this.props.simulationNotifications.results.length - 1
        ].fileType === "liveLog") ||
      this.props.simulationNotifications.status === "done"
    ) {
      //const url = await getDownloadLink("some_path/STDOUT.txt");
      const simulationStatusDoc = await downloadBlobAsync(
        "some_path/STDOUT.txt"
      );
      this.setState({ simulationStatusDoc: simulationStatusDoc.data });
    }
  }

  async componentDidUpdate(prevProps) {
    if (
      prevProps.simulationNotifications.results.length !==
      this.props.simulationNotifications.results.length
    ) {
      if (
        this.props.simulationNotifications.results[
          this.props.simulationNotifications.results.length - 1
        ] &&
        this.props.simulationNotifications.results[
          this.props.simulationNotifications.results.length - 1
        ].fileType === "liveLog"
      ) {
        //const url = await getDownloadLink("some_path/STDOUT.txt");
        const simulationStatusDoc = await downloadBlobAsync(
          "some_path/STDOUT.txt"
        );
        this.setState({ simulationStatusDoc: simulationStatusDoc.data });
      }
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div className="sim-status-tabs">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "24px",
          }}
        >
          <button
            className="raster-settings-btn"
            onClick={() =>
              requestSimulationLog(
                this.props.currentCase.id,
                this.props.simulationNotifications?.id
              )
            }
            style={{ marginTop: "0px" }}
            disabled
          >
            <div className="btn-content-wrapper">
              <RotateCw color={"#494949"} size={"16px"} strokeWidth={"2px"} />
              <div className="raster-btn-lbl">{t("Refresh")}</div>
            </div>
          </button>
          <div style={{ fontSize: "13px", fontStyle: "italic" }}>
            {this.props.simulationNotifications?.lastUpdate && (
              <>
                {t("LastUpdate")}:{" "}
                {this.props.simulationNotifications?.lastUpdate?.toLocaleString()}
              </>
            )}
          </div>
        </div>
        <div style={{ fontSize: "13px" }}>
          {/* Last Update:{" "}
          {this.props.simulationNotifications?.lastUpdate?.toLocaleString()} */}
          {this.state.simulationStatusDoc !== null &&
            this.state.simulationStatusDoc}
        </div>
      </div>
    );
  }
}

export default withTranslation()(SimulationStatus);
