import React from "react";
import Switch from "react-switch";
import BasicExportSettings from "./BasicExportSettings/BasicExportSettings";
import ExpertExportSettings from "./ExpertExportSettings";
import ExportAreaSettings from "./BasicExportSettings/ExportAreaSettings";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class ExportSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = { inBasicMode: true };
    this.onExportModeChange = this.onExportModeChange.bind(this);
  }

  onExportModeChange() {
    this.setState({ inBasicMode: !this.state.inBasicMode });
  }

  render() {
    const { t } = this.props;
    return (
      <div
        className="layer-settings-container"
        style={{ display: "block", padding: "0 25px 0 15px" }}
      >
        <div className="raster-switch-container" style={{ width: "100%" }}>
          <div className="raster-switch-lbl">{t("Basic")}</div>
          <Switch
            onChange={this.onExportModeChange}
            checked={!this.state.inBasicMode}
            checkedIcon={false}
            uncheckedIcon={false}
            height={11}
            width={25}
            handleDiameter={6}
            offHandleColor="#ffffff"
            onColor="#a1a1a1"
            offColor="#a1a1a1"
          />
          <div className="raster-switch-lbl">{t("Expert")}</div>
        </div>
        {this.state.inBasicMode ? (
          <>
            <ExportAreaSettings />
            <BasicExportSettings />
          </>
        ) : (
          <ExpertExportSettings />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(ExportSettings)));
