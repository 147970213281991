import { types } from "../types/results";

export function loadResults(payload) {
  return {
    type: types.LOAD_RESULTS,
    payload,
  };
}

export function exitResults() {
  return {
    type: types.EXIT_RESULTS,
  };
}

export function setResultsView(payload) {
  return {
    type: types.SET_RESULTS_VIEW,
    payload: payload,
  };
}

export function setResultsCrsDef(payload) {
  return {
    type: types.SET_RESULTS_CRS_DEF,
    payload: payload,
  };
}

export function toggleResultsMapPoiMode() {
  return {
    type: types.TOGGLE_RESULTS_MAP_POI_MODE,
  };
}

export function toggleResultsMapExportMode() {
  return {
    type: types.TOGGLE_RESULTS_MAP_EXPORT_MODE,
  };
}

export function toggleLayerSettings() {
  return {
    type: types.TOGGLE_LAYER_SETTINGS,
  };
}

export function toggleLayerSettingsForm() {
  return {
    type: types.TOGGLE_LAYER_SETTINGS_FORM,
  };
}

export function toggleGeodataDownload() {
  return {
    type: types.TOGGLE_GEODATA_DOWNLOAD,
  };
}

export function togglePoi() {
  return {
    type: types.TOGGLE_POI,
  };
}

export function togglePoiCursorDisplay() {
  return {
    type: types.TOGGLE_POI_CURSOR_DISPLAY,
  };
}

export function getPoiCursorCoords(payload) {
  return {
    type: types.GET_POI_CURSOR_COORDS,
    payload,
  };
}

export function addPoi(payload) {
  return {
    type: types.ADD_POI,
    payload,
  };
}

export function selectPoi(payload) {
  return {
    type: types.SELECT_POI,
    payload,
  };
}

export function removePoi(payload) {
  return {
    type: types.REMOVE_POI,
    payload,
  };
}

export function updateTempPoiInput(payload) {
  return {
    type: types.UPDATE_TEMP_POI_INPUT,
    payload,
  };
}

export function updatePoiPositionOnInputChange(payload) {
  return {
    type: types.UPDATE_POI_POSITION_ON_INPUT_CHANGE,
    payload,
  };
}

export function updatePoiPositionOnDragend(payload) {
  return {
    type: types.UPDATE_POI_POSITION_ON_DRAGEND,
    payload,
  };
}

export function onLayerNameChange(payload) {
  return {
    type: types.ON_LAYER_NAME_CHANGE,
    payload,
  };
}

export function toggleAoi(payload) {
  return {
    type: types.TOGGLE_AOI,
    payload,
  };
}

export function startNewAoi() {
  return {
    type: types.START_NEW_AOI,
  };
}

export function deleteAoi(payload) {
  return {
    type: types.DELETE_AOI,
    payload,
  };
}

export function drawAoi(payload) {
  return {
    type: types.DRAW_AOI,
    payload,
  };
}

export function dragAoiPointWg(payload) {
  return {
    type: types.DRAG_AOI_POINT_WG,
    payload,
  };
}

export function dragendAoiPointWg(payload) {
  return {
    type: types.DRAGEND_AOI_POINT_WG,
    payload,
  };
}

export function addAoiPointWg(payload) {
  return {
    type: types.ADD_AOI_POINT_WG,
    payload,
  };
}

export function addAoiCoordsRow(payload) {
  return {
    type: types.ADD_AOI_COORDS_ROW,
    payload,
  };
}

export function removeAoiCoordsRow(payload) {
  return {
    type: types.REMOVE_AOI_COORDS_ROW,
    payload,
  };
}

export function handleAoiCoordInputChange(payload) {
  return {
    type: types.HANDLE_AOI_COORD_INPUT_CHANGE,
    payload,
  };
}

export function selectAoi(payload) {
  return {
    type: types.SELECT_AOI,
    payload,
  };
}

export function onAreaLayerNameChange(payload) {
  return {
    type: types.ON_AREA_LAYER_NAME_CHANGE,
    payload,
  };
}

export function setResultsGeotiffVisibility(payload) {
  return {
    type: types.SET_RESULTS_GEOTIFF_VISIBILITY,
    payload,
  };
}

export function updateResultsJsonOrigin(payload) {
  return {
    type: types.UPDATE_RESULTS_JSON_ORIGIN,
    payload,
  };
}

export function showResultsGeotiffLegend() {
  return {
    type: types.SHOW_RESULTS_GEOTIFF_LEGEND,
  };
}

export function hideResultsGeotiffLegend() {
  return {
    type: types.HIDE_RESULTS_GEOTIFF_LEGEND,
  };
}

export function togglePoiVisibility() {
  return {
    type: types.TOGGLE_POI_VISIBILITY,
  };
}

export function loadLayerSettingsJson(payload) {
  return {
    type: types.LOAD_LAYER_SETTINGS_JSON,
    payload,
  };
}

export function toggleReadOnlyLayerForm(payload) {
  return {
    type: types.TOGGLE_READ_ONLY_LAYER_FORM,
    payload,
  };
}

export function toggleCreateLayerForm() {
  return {
    type: types.TOGGLE_CREATE_LAYER_FORM,
  };
}

export function changeNewLayerType(payload) {
  return {
    type: types.CHANGE_NEW_LAYER_TYPE,
    payload,
  };
}

export function changeNewLayerParams(payload) {
  return {
    type: types.CHANGE_NEW_LAYER_PARAMS,
    payload,
  };
}

export function changeNewLayerHeightLevel(payload) {
  return {
    type: types.CHANGE_NEW_LAYER_HEIGHT_LEVEL,
    payload,
  };
}

export function changeNewLayerTimestep(payload) {
  return {
    type: types.CHANGE_NEW_LAYER_TIMESTEP,
    payload,
  };
}
export function changeNewLayerExclBldgs(payload) {
  return {
    type: types.CHANGE_NEW_LAYER_EXCL_BLDGS,
    payload,
  };
}
export function changeNewLayerPostproc(payload) {
  return {
    type: types.CHANGE_NEW_LAYER_POSTPROC,
    payload,
  };
}

export function toggleLayerAppearance(payload) {
  return {
    type: types.TOGGLE_LAYER_APPEARANCE,
    payload,
  };
}

export function bringGeotiffToFront() {
  return {
    type: types.BRING_GEOTIFF_TO_FRONT,
  };
}

export function bringGeotiffForward(payload) {
  return {
    type: types.BRING_GEOTIFF_FORWARD,
    payload,
  };
}

export function bringGeotiffBackward(payload) {
  return {
    type: types.BRING_GEOTIFF_BACKWARD,
    payload,
  };
}

export function deleteLayer(payload) {
  return {
    type: types.DELETE_LAYER,
    payload,
  };
}

export function deletePage() {
  return {
    type: types.DELETE_PAGE,
  };
}

export function centerMap() {
  return {
    type: types.CENTER_MAP,
  };
}

export function centerMapSuccess() {
  return {
    type: types.CENTER_MAP_SUCCESS,
  };
}

export function setLayerCategoryColor(payload) {
  return {
    type: types.SET_LAYER_CATEGORY_COLOR,
    payload,
  };
}

export function setLayerColorPalette(payload) {
  return {
    type: types.SET_LAYER_COLOR_PALETTE,
    payload,
  };
}

export function setLayerOpacity(payload) {
  return {
    type: types.SET_LAYER_OPACITY,
    payload,
  };
}

export function setLayerMin(payload) {
  return {
    type: types.SET_LAYER_MIN,
    payload,
  };
}

export function setLayerMax(payload) {
  return {
    type: types.SET_LAYER_MAX,
    payload,
  };
}

export function createPage(payload) {
  return {
    type: types.CREATE_PAGE,
    payload,
  };
}

export function invertLayerColor() {
  return {
    type: types.INVERT_LAYER_COLOR,
  };
}

export function addNewLayer(payload) {
  return {
    type: types.ADD_NEW_LAYER,
    payload,
  };
}

export function validateNewPageName(payload) {
  return {
    type: types.VALIDATE_NEW_PAGE_NAME,
    payload,
  };
}

export function changeNewLayerTypeField(payload) {
  return {
    type: types.CHANGE_NEW_LAYER_TYPE_FIELD,
    payload,
  };
}

export function changeNewLayerCase(payload) {
  return {
    type: types.CHANGE_NEW_LAYER_CASE,
    payload,
  };
}

export function changeNewLayerDiffCase(payload) {
  return {
    type: types.CHANGE_NEW_LAYER_DIFF_CASE,
    payload,
  };
}

export function loadNewLayerDiffCaseData(payload) {
  return {
    type: types.LOAD_NEW_LAYER_DIFF_CASE_DATA,
    payload,
  };
}

export function changeNewLayerDiffPage(payload) {
  return {
    type: types.CHANGE_NEW_LAYER_DIFF_PAGE,
    payload,
  };
}

export function changeNewDiffLayer(payload) {
  return {
    type: types.CHANGE_NEW_DIFF_LAYER,
    payload,
  };
}

export function loadNewLayerDiffChildCaseData(payload) {
  return {
    type: types.LOAD_NEW_LAYER_DIFF_CHILD_CASE_DATA,
    payload,
  };
}

export function changeNewLayerDiffChildCase(payload) {
  return {
    type: types.CHANGE_NEW_LAYER_DIFF_CHILD_CASE,
    payload,
  };
}

export function changeNewLayerDiffChildPage(payload) {
  return {
    type: types.CHANGE_NEW_LAYER_DIFF_CHILD_PAGE,
    payload,
  };
}

export function changeNewDiffChildLayer(payload) {
  return {
    type: types.CHANGE_NEW_DIFF_CHILD_LAYER,
    payload,
  };
}

export function changeSpatCase(payload) {
  return {
    type: types.CHANGE_SPAT_CASE,
    payload,
  };
}
export function changeSpatFileType(payload) {
  return {
    type: types.CHANGE_SPAT_FILE_TYPE,
    payload,
  };
}
export function changeSpatParams(payload) {
  return {
    type: types.CHANGE_SPAT_PARAMS,
    payload,
  };
}
export function changeSpatHeightFrom(payload) {
  return {
    type: types.CHANGE_SPAT_HEIGHT_FROM,
    payload,
  };
}
export function changeSpatHeightTo(payload) {
  return {
    type: types.CHANGE_SPAT_HEIGHT_TO,
    payload,
  };
}
export function changeSpatTimeFrom(payload) {
  return {
    type: types.CHANGE_SPAT_TIME_FROM,
    payload,
  };
}
export function changeSpatTimeTo(payload) {
  return {
    type: types.CHANGE_SPAT_TIME_TO,
    payload,
  };
}
export function changeSpatArea(payload) {
  return {
    type: types.CHANGE_SPAT_AREA,
    payload,
  };
}
export function changeSpatProcessing(payload) {
  return {
    type: types.CHANGE_SPAT_PROCESSING,
    payload,
  };
}
export function changeCatCustomPage(payload) {
  return {
    type: types.CHANGE_CAT_CUSTOM_PAGE,
    payload,
  };
}
export function changeCatCustomLayer(payload) {
  return {
    type: types.CHANGE_CAT_CUSTOM_LAYER,
    payload,
  };
}
export function changeCatCustomNumber(payload) {
  return {
    type: types.CHANGE_CAT_CUSTOM_NUMBER,
    payload,
  };
}
export function changeCatCustomColorPalette(payload) {
  return {
    type: types.CHANGE_CAT_CUSTOM_COLOR_PALETTE,
    payload,
  };
}
export function invertCatCustomColorPalette() {
  return {
    type: types.INVERT_CAT_CUSTOM_COLOR_PALETTE,
  };
}
export function resetNewLayerSettings(payload) {
  return {
    type: types.RESET_NEW_LAYER_SETTINGS,
    payload,
  };
}
export function changeCustomLayerBreakValue(payload) {
  return {
    type: types.CHANGE_CUSTOM_LAYER_BREAK_VALUE,
    payload,
  };
}
export function changeCustomLayerBreakName(payload) {
  return {
    type: types.CHANGE_CUSTOM_LAYER_BREAK_NAME,
    payload,
  };
}
export function changeCustomLayerMin(payload) {
  return {
    type: types.CHANGE_CUSTOM_LAYER_MIN,
    payload,
  };
}
export function changeDiffType(payload) {
  return {
    type: types.CHANGE_DIFF_TYPE,
    payload,
  };
}
export function changeDiffUnit(payload) {
  return {
    type: types.CHANGE_DIFF_UNIT,
    payload,
  };
}
export function changeWindfieldCase(payload) {
  return {
    type: types.CHANGE_WINDFIELD_CASE,
    payload,
  };
}
export function changeWindfieldFileType(payload) {
  return {
    type: types.CHANGE_WINDFIELD_FILE_TYPE,
    payload,
  };
}
export function changeWindfieldParams(payload) {
  return {
    type: types.CHANGE_WINDFIELD_PARAMS,
    payload,
  };
}
export function changeWindfieldHeight(payload) {
  return {
    type: types.CHANGE_WINDFIELD_HEIGHT,
    payload,
  };
}
export function changeWindfieldTimestep(payload) {
  return {
    type: types.CHANGE_WINDFIELD_TIMESTEP,
    payload,
  };
}

export function changeWindStreamColor(payload) {
  return {
    type: types.CHANGE_WIND_STREAM_COLOR,
    payload,
  };
}
export function changeWindStreamPaths(payload) {
  return {
    type: types.CHANGE_WIND_STREAM_PATHS,
    payload,
  };
}
export function changeWindStreamMaxAge(payload) {
  return {
    type: types.CHANGE_WIND_STREAM_MAX_AGE,
    payload,
  };
}
export function changeWindStreamVScale(payload) {
  return {
    type: types.CHANGE_WIND_STREAM_V_SCALE,
    payload,
  };
}
export function changeWindStreamFade(payload) {
  return {
    type: types.CHANGE_WIND_STREAM_FADE,
    payload,
  };
}
export function changeWindStreamStrokeWidth(payload) {
  return {
    type: types.CHANGE_WIND_STREAM_STROKE_WIDTH,
    payload,
  };
}
export function changeWindStreamOpacity(payload) {
  return {
    type: types.CHANGE_WIND_STREAM_OPACITY,
    payload,
  };
}
export function changeWindDirColor(payload) {
  return {
    type: types.CHANGE_WIND_DIR_COLOR,
    payload,
  };
}
export function changeWindDirSize(payload) {
  return {
    type: types.CHANGE_WIND_DIR_SIZE,
    payload,
  };
}
export function changeWindDirOpacity(payload) {
  return {
    type: types.CHANGE_WIND_DIR_OPACITY,
    payload,
  };
}
export function toggleExportSettings() {
  return {
    type: types.TOGGLE_EXPORT_SETTINGS,
  };
}

export function changePageSize(payload) {
  return {
    type: types.CHANGE_PAGE_SIZE,
    payload,
  };
}
export function changeScale(payload) {
  return {
    type: types.CHANGE_SCALE,
    payload,
  };
}
export function changeTitle(payload) {
  return {
    type: types.CHANGE_TITLE,
    payload,
  };
}
export function changeSubtitle(payload) {
  return {
    type: types.CHANGE_SUBTITLE,
    payload,
  };
}
export function changeAdditionalText(payload) {
  return {
    type: types.CHANGE_ADDITIONAL_TEXT,
    payload,
  };
}
export function changeAuthor(payload) {
  return {
    type: types.CHANGE_AUTHOR,
    payload,
  };
}
export function changeInstitution(payload) {
  return {
    type: types.CHANGE_INSTITUTION,
    payload,
  };
}
export function changeAdress(payload) {
  return {
    type: types.CHANGE_ADRESS,
    payload,
  };
}
export function changeContact(payload) {
  return {
    type: types.CHANGE_CONTACT,
    payload,
  };
}
export function changeDate(payload) {
  return {
    type: types.CHANGE_DATE,
    payload,
  };
}
export function changeLayersForExport(payload) {
  return {
    type: types.CHANGE_LAYERS_FOR_EXPORT,
    payload,
  };
}

export function toggleIncludeNorthArrow() {
  return {
    type: types.TOGGLE_INCLUDE_NORTH_ARROW,
  };
}
export function toggleIncludeScale() {
  return {
    type: types.TOGGLE_INCLUDE_SCALE,
  };
}
export function toggleIncludeAssesmentPoints() {
  return {
    type: types.TOGGLE_INCLUDE_ASSESMENT_POINTS,
  };
}
export function changeNorthArrowPosition(payload) {
  return {
    type: types.CHANGE_NORTH_ARROW_POSITION,
    payload,
  };
}
export function changeScalePosition(payload) {
  return {
    type: types.CHANGE_SCALE_POSITION,
    payload,
  };
}
export function changeBackgroundMapColor(payload) {
  return {
    type: types.CHANGE_BACKGROUND_MAP_COLOR,
    payload,
  };
}
export function changeBackgroundHeaderColor(payload) {
  return {
    type: types.CHANGE_BACKGROUND_HEADER_COLOR,
    payload,
  };
}
export function changeAreaToCrop(payload) {
  return {
    type: types.CHANGE_AREA_TO_CROP,
    payload,
  };
}
export function toggleArrowScale() {
  return {
    type: types.TOGGLE_ARROW_SCALE,
  };
}
export function changeArrowGridpointSkip(payload) {
  return {
    type: types.CHANGE_ARROW_GRIDPOINT_SKIP,
    payload,
  };
}
export function changeArrowLength(payload) {
  return {
    type: types.CHANGE_ARROW_LENGTH,
    payload,
  };
}
export function changeArrowMinMagnitude(payload) {
  return {
    type: types.CHANGE_ARROW_MIN_MAGNITUDE,
    payload,
  };
}
export function changeArrowScalePosition(payload) {
  return {
    type: types.CHANGE_ARROW_SCALE_POISITION,
    payload,
  };
}
export function getExportRequest() {
  return {
    type: types.GET_EXPORT_REQUEST,
  };
}

export function setMapPlotCenterLatWg(payload) {
  return {
    type: types.SET_MAP_PLOT_CENTER_LAT_WG,
    payload,
  };
}
export function setMapPlotCenterLngWg(payload) {
  return {
    type: types.SET_MAP_PLOT_CENTER_LNG_WG,
    payload,
  };
}
export function setMapPlotCenterLatUtm(payload) {
  return {
    type: types.SET_MAP_PLOT_CENTER_LAT_UTM,
    payload,
  };
}
export function setMapPlotCenterLngUtm(payload) {
  return {
    type: types.SET_MAP_PLOT_CENTER_LNG_UTM,
    payload,
  };
}
export function toggleMapPlotDraggable() {
  return {
    type: types.TOGGLE_MAP_PLOT_DRAGGABLE,
  };
}
export function setInitMapPlotCenter(payload) {
  return {
    type: types.SET_INIT_MAP_PLOT_CENTER,
    payload,
  };
}
export function updateMapPlotPosition(payload) {
  return {
    type: types.UPDATE_MAP_PLOT_POSITION,
    payload,
  };
}
export function centerMapPlot() {
  return {
    type: types.CENTER_MAP_PLOT,
  };
}

export function centerMapPlotSuccess() {
  return {
    type: types.CENTER_MAP_PLOT_SUCCESS,
  };
}

export function setMapPlotLogo(payload) {
  return {
    type: types.SET_MAP_PLOT_LOGO,
    payload,
  };
}

export function clearMapPlotLogo() {
  return {
    type: types.CLEAR_MAP_PLOT_LOGO,
  };
}

export function toggleTimeChart() {
  return {
    type: types.TOGGLE_TIME_CHART,
  };
}

export function toggleVerticalChart() {
  return {
    type: types.TOGGLE_VERTICAL_CHART,
  };
}

export function changeChartFileType(payload) {
  return {
    type: types.CHANGE_CHART_FILE_TYPE,
    payload,
  };
}

export function changeChartParams(payload) {
  return {
    type: types.CHANGE_CHART_PARAMS,
    payload,
  };
}

export function changeChartPoint(payload) {
  return {
    type: types.CHANGE_CHART_POINT,
    payload,
  };
}

export function changeChartHeightLevel(payload) {
  return {
    type: types.CHANGE_CHART_HEIGHT_LEVEL,
    payload,
  };
}

export function changeChartTimestep(payload) {
  return {
    type: types.CHANGE_CHART_TIMESTEP,
    payload,
  };
}
