import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { getToken } from "../../api/axiosApi";

const apiUrl = `${process.env.REACT_APP_FRONT_SERVICE_API}/translations/{{lng}}`;

export async function initTranslations(preferredLanguage = "de-DE") {
  const bearerToken = await getToken();
  i18n
    .use(HttpApi)
    .use(initReactI18next)
    .init({
      debug: false,
      keySeparator: false,
      lng: preferredLanguage,
      fallbackLng: "de-DE",
      load: "currentOnly",
      backend: {
        loadPath: apiUrl,
        customHeaders: {
          authorization: `Bearer ${bearerToken}`,
        },
      },
    });
}

export default i18n;
