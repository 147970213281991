export const OBJECTS_TO_REQUEST = [
  "building",
  "vegetation",
  "pavement",
  "water",
  "treePatches",
  "singleTrees",
  "streetTypes",
];

export const BUILDING_WITH_MISSING_HEIGHT_OPTIONS = [
  "ignore",
  "onlyFromStoreyHeight",
  "allWithFillValue",
  "fromStoreyHeightsFillValue",
];

export const TREE_WITH_MISSING_HEIGHT_OPTIONS = [
  "ignore",
  "addFillValueTreePatches",
  "addFillValueSingleTrees",
  "addFillValueBoth",
];

export const ROAD_WIDTH_METHOD_OPTIONS = ["no", "FromLanesBufferWidth"];
