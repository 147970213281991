import React from "react";
import "./Select.scss";

function Select(props) {
  return (
    <div className="ui-select-wrapper">
      <label className="ui-label-select">{props.label}</label>
      <select
        id={props.id}
        name={props.name}
        className="ui-select-input"
        onChange={props.onChange}
        value={props.value}
      >
        {props.options.map((option) => {
          return (
            <option
              key={option.value}
              value={option.value}
              disabled={option.disabled}
            >
              {option.label}
            </option>
          );
        })}
      </select>
    </div>
  );
}

export default Select;
