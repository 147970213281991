import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  loadSettings,
  saveSettings,
} from "../../redux/actions/settingsActions";
import { loadUserDetails } from "../../redux/actions/authActions";
import { Loader } from "../../Components/Loader";
import UserSettingsForm from "../../Components/User/UserForm/UserSettingsForm";
import Page from "../../Components/Page/Page";

class GeneralSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      avatarUrl: null,
    };
    this.onImageChange = this.onImageChange.bind(this);
  }

  componentDidMount() {
    this.props.loadSettings();
    this.props.loadUserDetails();
  }

  componentDidUpdate(prevProps) {
    if (
      !this.state.pictureUrl &&
      prevProps.settings?.pictureDownloadUrl !==
        this.props.settings?.pictureDownloadUrl
    ) {
      this.setState({ avatarUrl: this.props.settings.pictureDownloadUrl });
    }
  }

  onImageChange(url) {
    this.setState({ avatarUrl: url });
  }
  handleInputChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  }

  render() {
    if (this.props.settings.loading) {
      return <Loader />;
    }
    return (
      <Page>
        <UserSettingsForm {...this.props} submit={this.props.submit} />
      </Page>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.auth.details,
    settings: {
      ...state.userSettings?.settings,
      visualizationPresets: {
        ...state.userSettings?.settings?.visualizationPresets,
        inputPalmTypesPalette: state.userSettings?.settings
          ?.visualizationPresets?.inputPalmTypesPalette
          ? JSON.parse(
              state.userSettings?.settings?.visualizationPresets
                .inputPalmTypesPalette
            )
          : {},
      },
    },
    budget: {
      ...state.userSettings?.budget,
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadSettings: () => {
      dispatch(loadSettings());
    },
    loadUserDetails: () => {
      dispatch(loadUserDetails());
    },
    submit: (payload) => {
      dispatch(saveSettings(payload));
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(GeneralSettings)
);
