import React, { useRef } from "react";
import { Edit2, X } from "react-feather";
import CircleButton from "../Common/Button/Circle/CircleButton";
import Avatar from "./";
import "./index.scss";

export const EditableAvatar = (props) => {
  const fileBrowser = useRef(null);
  const onIconClick = () => {
    fileBrowser.current.click();
  };
  const onImageChange = (e) => {
    if (!e.target.files[0]) {
      return;
    }
    props.onChange(e.target.files[0]);
  };
  const onImageClear = (e) => {
    props.onChange(null);
    fileBrowser.current.value = null;
  };
  return (
    <Avatar {...props}>
      <CircleButton className="avatar-edit" icon={Edit2} onClick={onIconClick}>
        <input
          ref={fileBrowser}
          hidden
          type="file"
          id="upload-avatar"
          accept="image/png, image/jpeg"
          onChange={onImageChange}
        />
      </CircleButton>
        <CircleButton
          disabled={!props.avatarUrl}
          className="avatar-clear"
          icon={X}
          onClick={onImageClear}
        ></CircleButton>
    </Avatar>
  );
};

export default EditableAvatar;
