import { types } from "../types/notifications";

const initialState = {
  notifications: [],
  steeringNotifications: [],
  postprocessNotifications: [],
};

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [
          ...state.notifications.filter((n) => {
            if (n.type === "simulation") {
              return (
                n.body?.response?.status !==
                  action.payload.body?.response?.status ||
                n.body?.response?.caseId !==
                  action.payload.body?.response?.caseId
              );
            }
            return n.id !== action.payload.id;
          }),
          action.payload,
        ],
      };
    case types.DISMISS_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          (n) => action.payload !== n.id
        ),
      };
    case types.ACK_NOTIFICATIONS:
      return {
        ...state,
        notifications: state.notifications.map((n) =>
          action.payload.includes(n.id) ? { ...n, ack: true } : n
        ),
      };
    case types.ADD_STEERING_NOTIFICATION:
      return {
        ...state,
        steeringNotifications: [
          ...state.steeringNotifications.filter(
            (n) => n.id !== action.payload.id
          ),
          action.payload,
        ],
      };
    case types.ADD_POSTPROCESS_NOTIFICATION: {
      return {
        ...state,
        postprocessNotifications: [
          ...state.postprocessNotifications.filter(
            (n) => n.id !== action.payload.id
          ),
          action.payload,
        ],
      };
    }
    case types.CLEAR_NOTIFICATIONS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default notificationsReducer;
