import { combineReducers } from "redux";
import auth from "./authReducer";
import notifications from "./notificationsReducer";
import userSettings from "./userSettingsReducer";
import projects from "./projectsReducer";
import projectFiles from "./projectFilesReducer";
import createProject from "./createProjectReducer";
import newCaseWizard from "./createCaseReducer";
import rasterArea from "./rasterAreaReducer";
import simulationSettings from "./simulationSettingsReducer";
import legend from "./legendReducer";
import map from "./mapReducer";
import checkFile from "./checkFileReducer";
import checkName from "./checkNameReducer";
import thermal from "./thermalReducer";
import wind from "./windReducer";
import chemistry from "./chemistryReducer";
import results from "./resultsReducer";
import simulation from "./simulationReducer";
import tools from "./toolsReducer";
import expert from "./expertReducer";

const RootReducer = combineReducers({
  auth,
  userSettings,
  notifications,
  projects,
  projectFiles,
  createProject,
  rasterArea,
  newCaseWizard,
  simulationSettings,
  legend,
  map,
  checkFile,
  checkName,
  thermal,
  wind,
  chemistry,
  results,
  simulation,
  tools,
  expert,
});

export default RootReducer;
