import React from "react";
import FormFileBrowser from "../../../Components/Common/Form/FormFileBrowser/FormFileBrowser";
import FormFileBrowserWithValidation from "../../../Components/Common/Form/FormFileBrowser/FormFileBrowserWithValidation";
import { withTranslation } from "react-i18next";

function NewGeodataForm(props) {
  const { t } = props;
  const geodataType = {
    QGIS: `QGIS - ${t("Upload")}`,
    staticDriver: t("StaticDriver"),
  };

  return (
    <>
      {(props.geodataType === geodataType.QGIS ||
        props.geodataType === "initialGeodataType" ||
        props.geodataType === "0") && (
        <>
          <FormFileBrowserWithValidation
            name="geoTiff"
            label={`${t("UploadDigitalElevationModel")}`}
            fileName={props.geotiffName}
            accept={[".tif", ".tiff"]}
            isClearable
            disabled={props.topographyDisabled}
            error={props.geotiffErrorMessage}
            onChange={props.onGeoTiffChange}
            clearUploadedFile={props.clearUploadedFile}
          />

          <FormFileBrowserWithValidation
            name="geoJson"
            label={t("Upload") + " QGIS-JSON"}
            fileName={props.geojsonName}
            accept={"application/JSON, .GEOJSON"}
            isClearable
            disabled={props.domainDisabled}
            error={props.geojsonErrorMessage}
            onChange={props.onGeoJsonChange}
            clearUploadedFile={props.clearUploadedFile}
          />
        </>
      )}
      {(props.geodataType === geodataType.staticDriver ||
        props.geodataType === "1") && (
        <div className="text-input-container">
          <FormFileBrowser
            name="staticDriver"
            label={`${t("StaticDriver")}*`}
            fileName={props.staticDriver?.name}
            accept={".nc"}
            disabled
            onChange={props.onStaticDriverChange}
            clearUploadedFile={props.clearUploadedFile}
            isClearable
          />
        </div>
      )}
    </>
  );
}

export default withTranslation()(NewGeodataForm);
