import { HubConnectionBuilder } from "@microsoft/signalr";
import { addOsmNotification } from "../../redux/actions/toolsActions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import authService from "../../utils/auth/authService";

const notificationHubUrl = `${process.env.REACT_APP_FRONT_SERVICE_API}/notifications/custom-tasks`;

const customTasksStatusesToTriggerNotification = [
  "batchInitialization",
  "running",
  "finished",
  "cancelled",
  "aborted",
  "error",
];

const CustomTaskProvider = () => {
  const [connection, setConnection] = useState(null);
  const user = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const start = () => {
    if (connection) {
      if (connection.connectionState === "Connected") {
        return;
      }
      connection
        .start()
        .then(() => {
          connection.on("CustomTaskNotificationReceived", (message) => {
            const taskNotification = {
              id: message.requestId,
              type: "task",
              body: message,
              ts: message.timestampUtc,
            };
            console.log(taskNotification.body);
            dispatch(addOsmNotification(taskNotification.body));
            if (
              customTasksStatusesToTriggerNotification.includes(
                message?.response?.status
              )
            ) {
              console.log(taskNotification);
            }
          });
          connection.onclose(() => {
            setTimeout(() => start(), 3000);
          });
        })
        .catch(async (e) => {
          console.log(
            "Connection failed: ",
            JSON.stringify(e),
            e.statusCode,
            new Date()
          );
          setTimeout(() => start(), 3000);
        });
    }
  };
  useEffect(() => {
    async function subscribeToHub() {
      const newConnection = new HubConnectionBuilder()
        .withUrl(`${notificationHubUrl}`, {
          accessTokenFactory: () => {
            return authService.acquireToken();
          },
        })
        .build();
      setConnection(newConnection);
    }
    if (!user || !user.identity) {
      return;
    }
    subscribeToHub();
  }, [user]);

  useEffect(() => {
    start();
  }, [connection]);

  return null;
};

export default CustomTaskProvider;
