import React, { useState, useEffect } from "react";
import Icon from "../../Common/Icon";
import { ChevronRight, Tool } from "react-feather";

const AccordionMenuSection = (props) => {
  const [expanded, setExpanded] = useState(!!props.expanded || false);

  useEffect(() => {
    setExpanded(props.expanded);
  }, [props.expanded]);

  return (
    <div className="accordion-section" style={{ justifyContent: "flex-start" }}>
      <span
        style={{
          paddingLeft: "20px",
          color: "rgba(66, 66, 66, 0.837)",
          justifyContent: "flex-start",
          paddingBottom: "7px",
          paddingTop: "7px",
        }}
        className={`accordion-section_btn accordion-section__header sidebar-item sidebar-link`}
        onClick={() => setExpanded(!expanded)}
      >
        <Icon className="sidebar-item__icon" icon={Tool} />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            paddingLeft: "2px",
          }}
        >
          <div>{props.title}</div>
          <ChevronRight
            className={`
         accordion-section__chevron
          ${expanded && " accordion-section__chevron--expanded"}
          `}
            size={"18px"}
          />
        </div>
      </span>
      {expanded && (
        <div
          className={
            "accordion-section__content accordion-section__content--expanded"
          }
        >
          {props.children}
        </div>
      )}
    </div>
  );
};

export default AccordionMenuSection;
