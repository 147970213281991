import React from "react";
import SimWarning from "./SimWarning";
import { Loader } from "../../Components/Loader";
import {
  getCaseSetup,
  requestSimulation,
  requestCustomSimulation,
  cancelSimulation,
  requestPostProcessingAsync,
  requestCustomPostProcessingAsync,
} from "../../api/simulationApi";
import { getUserSettingsAsync } from "../../api/userSettingsApi";
import SimStatus from "./SimStatus";
import SimStartBtn from "./SimStartBtn";
import SimTabs from "./SimTabs/SimTabs";
import DeleteModal from "../../Components/Common/DeleteModal/index";
import FormSeparator from "../../Components/Common/Form/FormSeparator/index";
import { Search, RotateCw } from "react-feather";
import { downloadBlobAsync } from "../../api/storageApi";
import { postSteering } from "../../api/simulationApi";
import { selectCaseSimulationNotifications } from "../../redux/selectors/simulationSelector";
import { loadSimulationJob } from "../../redux/actions/simulationActions";
import {
  loadResults,
  loadLayerSettingsJson,
} from "../../redux/actions/resultsActions";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { cloneDeep } from "lodash";
import { connect } from "react-redux";
import { minutesToHoursMinutesSeconds } from "../../utils/date/dateHelpers";
import "./Simulation.scss";

class Simulation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputChecked: false,
      staticDriver: null,
      setup: null,
      results: null,
      storage: null,
      isInfoLoading: false,
      savedSettings: false,
      isCancelModalOpen: false,
      simulationRequestError: null,
      awaitingSimulationStart: false,
      budgetAvailableHours: null,
      isWindTableUserDefined: true,
    };
    this.checkSteeringInput = this.checkSteeringInput.bind(this);
    this.toggleCancelModal = this.toggleCancelModal.bind(this);
    this.requestPostProcessing = this.requestPostProcessing.bind(this);
    this.handleBudgetRefresh = this.handleBudgetRefresh.bind(this);
  }

  async componentDidMount() {
    const savedSettings = await getCaseSetup(this.props.match.params.caseId);
    if (
      savedSettings &&
      !savedSettings.setup.settings.wind_comfort_parameters
    ) {
      this.setState({ savedSettings: true });
    } else if (
      savedSettings &&
      savedSettings.setup.settings.wind_comfort_parameters.windStatisticSource
        .value === "UserDefinedTable"
    ) {
      this.setState({ savedSettings: true });
    } else if (savedSettings) {
      this.setState({ savedSettings: true, isWindTableUserDefined: false });
    }

    if (
      this.props.currentCase?.simulationJobs?.length &&
      this.props.currentCase?.a
    ) {
      this.props.loadSimulationJob({
        currentCaseId: this.props.currentCase.id,
        simulationData: this.props.currentCase.simulationJobs[0],
      });
    }

    if (
      this.props.currentCase?.steering &&
      this.props.currentCase?.steering?.resultUrl !== null &&
      this.props.currentCase?.steering?.resultUrl !== "false"
    ) {
      const inputCheckUrl =
        this.props.currentCase.steering.resultUrl.substring(
          0,
          this.props.currentCase.steering.resultUrl.lastIndexOf("/")
        ) + "/json_summary.json";
      const inputCheck = await downloadBlobAsync(inputCheckUrl);
      const steeringInfo = inputCheck.data;
      const staticDriver = steeringInfo.static_driver;
      const setup = steeringInfo.setup;
      const results = steeringInfo.results;
      const storage = steeringInfo.storage;

      let staticDriverDiv = [];
      let setupDiv = [];
      let resultsDiv = [];
      let storageDiv = [];

      for (const [key, value] of Object.entries(staticDriver)) {
        if (value.length === 0) {
        } else if (key === "errors") {
          staticDriverDiv.push(
            <p className="sim-info sim-info-error">{`${key}: ${value}`}</p>
          );
        } else if (key === "warnings") {
          staticDriverDiv.push(
            <p className="sim-info sim-info-warning">{`${key}: ${value}`}</p>
          );
        } else {
          staticDriverDiv.push(
            <p className="sim-info">{`${key}: ${value}`}</p>
          );
        }
      }

      for (const [key, value] of Object.entries(setup)) {
        if (value === null || value === {} || value.length === 0) {
        } else if (key === "errors") {
          setupDiv.push(
            <p className="sim-info sim-info-error">{`${key}: ${value}`}</p>
          );
        } else if (key === "warnings") {
          setupDiv.push(
            <p className="sim-info sim-info-warning">{`${key}: ${value}`}</p>
          );
        } else {
          setupDiv.push(<p className="sim-info">{`${key}: ${value}`}</p>);
        }
      }

      for (const [key, value] of Object.entries(results)) {
        if (value === null || value === {} || value.length === 0) {
        } else if (key === "errors") {
          resultsDiv.push(
            <p className="sim-info sim-info-error">{`${key}: ${value}`}</p>
          );
        } else if (key === "warnings") {
          resultsDiv.push(
            <p className="sim-info sim-info-warning">{`${key}: ${value}`}</p>
          );
        } else {
          resultsDiv.push(<p className="sim-info">{`${key}: ${value}`}</p>);
        }
      }

      for (const [key, value] of Object.entries(storage)) {
        if (value === null || value === {} || value.length === 0) {
        } else if (key === "errors") {
          storageDiv.push(
            <p className="sim-info sim-errors">{`${key}: ${value}`}</p>
          );
        } else if (key === "warnings") {
          storageDiv.push(
            <p className="sim-info sim-warnings">{`${key}: ${value}`}</p>
          );
        } else {
          storageDiv.push(<p className="sim-info">{`${key}: ${value}`}</p>);
        }
      }

      this.setState({
        storage: storageDiv,
        staticDriver: staticDriverDiv,
        setup: setupDiv,
        results: resultsDiv,
        inputChecked: true,
      });
    }
    if (
      this.props.steeringNotifications !== undefined &&
      this.state.storage === null &&
      this.props.currentCase?.id
    ) {
      const steeringNotifications = cloneDeep(this.props.steeringNotifications);

      const allCurrentCaseSteerNot = steeringNotifications.filter(
        (item) => item.id === this.props.currentCase.id
      );

      const currentCaseNot = allCurrentCaseSteerNot.find(
        (n) =>
          n.id === this.props.currentCase.id &&
          n.body !== null &&
          n.resultUrl !== null
      );

      if (currentCaseNot?.body) {
        const steeringInfo = currentCaseNot.body;
        const staticDriver = steeringInfo.static_Driver;
        const setup = steeringInfo.setup;
        const results = steeringInfo.results;
        const storage = steeringInfo.storage;

        let staticDriverDiv = [];
        let setupDiv = [];
        let resultsDiv = [];
        let storageDiv = [];
        for (const [key, value] of Object.entries(staticDriver)) {
          if (value.length === 0) {
          } else if (key === "errors") {
            staticDriverDiv.push(
              <p className="sim-info sim-info-error">{`${key}: ${value}`}</p>
            );
          } else if (key === "warnings") {
            staticDriverDiv.push(
              <p className="sim-info sim-info-warning">{`${key}: ${value}`}</p>
            );
          } else {
            staticDriverDiv.push(
              <p className="sim-info">{`${key}: ${value}`}</p>
            );
          }
        }

        for (const [key, value] of Object.entries(setup)) {
          if (value === null || value === {} || value.length === 0) {
          } else if (key === "errors") {
            setupDiv.push(
              <p className="sim-info sim-info-error">{`${key}: ${value}`}</p>
            );
          } else if (key === "warnings") {
            setupDiv.push(
              <p className="sim-info sim-info-warning">{`${key}: ${value}`}</p>
            );
          } else {
            setupDiv.push(<p className="sim-info">{`${key}: ${value}`}</p>);
          }
        }

        for (const [key, value] of Object.entries(results)) {
          if (value === null || value === {} || value.length === 0) {
          } else if (key === "errors") {
            resultsDiv.push(
              <p className="sim-info sim-info-error">{`${key}: ${value}`}</p>
            );
          } else if (key === "warnings") {
            resultsDiv.push(
              <p className="sim-info sim-info-warning">{`${key}: ${value}`}</p>
            );
          } else {
            resultsDiv.push(<p className="sim-info">{`${key}: ${value}`}</p>);
          }
        }

        for (const [key, value] of Object.entries(storage)) {
          if (value === null || value === {} || value.length === 0) {
          } else if (key === "errors") {
            storageDiv.push(
              <p className="sim-info sim-errors">{`${key}: ${value}`}</p>
            );
          } else if (key === "warnings") {
            storageDiv.push(
              <p className="sim-info sim-warnings">{`${key}: ${value}`}</p>
            );
          } else {
            storageDiv.push(<p className="sim-info">{`${key}: ${value}`}</p>);
          }
        }

        this.setState({
          storage: storageDiv,
          staticDriver: staticDriverDiv,
          setup: setupDiv,
          results: resultsDiv,
          inputChecked: true,
          //isInfoLoading: false,
        });
      }
    }
  }

  async componentDidUpdate(prevProps) {
    if (
      !!this.props.currentCase?.id &&
      this.props.currentCase?.id !== prevProps.currentCase?.id
    ) {
      const savedSettings = await getCaseSetup(this.props.match.params.caseId);
      if (savedSettings) {
        this.setState({ savedSettings: true });
      }
      this.setState({
        simulationRequestError: null,
      });
    }
    if (
      this.props.currentCase?.simulationJobs?.length &&
      prevProps.currentCase !== this.props.currentCase
    ) {
      this.props.loadSimulationJob({
        currentCaseId: this.props.currentCase.id,
        simulationData: this.props.currentCase.simulationJobs[0],
      });
    }

    if (prevProps.currentCase !== this.props.currentCase) {
      if (
        !!this.props.currentCase?.steering &&
        this.props.currentCase?.steering?.resultUrl &&
        this.props.currentCase?.steering?.resultUrl !== "false"
      ) {
        const inputCheckUrl =
          this.props.currentCase.steering.resultUrl.substring(
            0,
            this.props.currentCase.steering.resultUrl.lastIndexOf("/")
          ) + "/json_summary.json";
        const inputCheck = await downloadBlobAsync(inputCheckUrl);
        const steeringInfo = inputCheck.data;
        const staticDriver = steeringInfo.static_driver;
        const setup = steeringInfo.setup;
        const results = steeringInfo.results;
        const storage = steeringInfo.storage;

        let staticDriverDiv = [];
        let setupDiv = [];
        let resultsDiv = [];
        let storageDiv = [];

        for (const [key, value] of Object.entries(staticDriver)) {
          if (value.length === 0) {
          } else if (key === "errors") {
            staticDriverDiv.push(
              <p className="sim-info sim-info-error">{`${key}: ${value}`}</p>
            );
          } else if (key === "warnings") {
            staticDriverDiv.push(
              <p className="sim-info sim-info-warning">{`${key}: ${value}`}</p>
            );
          } else {
            staticDriverDiv.push(
              <p className="sim-info">{`${key}: ${value}`}</p>
            );
          }
        }

        for (const [key, value] of Object.entries(setup)) {
          if (value === null || value === {} || value.length === 0) {
          } else if (key === "errors") {
            setupDiv.push(
              <p className="sim-info sim-info-error">{`${key}: ${value}`}</p>
            );
          } else if (key === "warnings") {
            setupDiv.push(
              <p className="sim-info sim-info-warning">{`${key}: ${value}`}</p>
            );
          } else {
            setupDiv.push(<p className="sim-info">{`${key}: ${value}`}</p>);
          }
        }

        for (const [key, value] of Object.entries(results)) {
          if (value === null || value === {} || value.length === 0) {
          } else if (key === "errors") {
            resultsDiv.push(
              <p className="sim-info sim-info-error">{`${key}: ${value}`}</p>
            );
          } else if (key === "warnings") {
            resultsDiv.push(
              <p className="sim-info sim-info-warning">{`${key}: ${value}`}</p>
            );
          } else {
            resultsDiv.push(<p className="sim-info">{`${key}: ${value}`}</p>);
          }
        }

        for (const [key, value] of Object.entries(storage)) {
          if (value === null || value === {} || value.length === 0) {
          } else if (key === "errors") {
            storageDiv.push(
              <p className="sim-info sim-errors">{`${key}: ${value}`}</p>
            );
          } else if (key === "warnings") {
            storageDiv.push(
              <p className="sim-info sim-warnings">{`${key}: ${value}`}</p>
            );
          } else {
            storageDiv.push(<p className="sim-info">{`${key}: ${value}`}</p>);
          }
        }

        this.setState({
          storage: storageDiv,
          staticDriver: staticDriverDiv,
          setup: setupDiv,
          results: resultsDiv,
          inputChecked: true,
        });
      } else {
        this.setState({
          inputChecked: false,
          staticDriver: null,
          setup: null,
          results: null,
          storage: null,
          isCancelModalOpen: false,
        });
      }
    }

    if (
      this.props.steeringNotifications !== undefined &&
      this.state.storage === null &&
      this.props.currentCase?.id
    ) {
      const steeringNotifications = cloneDeep(this.props.steeringNotifications);

      const allCurrentCaseSteerNot = steeringNotifications.filter(
        (item) => item.id === this.props.currentCase.id
      );

      const currentCaseNot = allCurrentCaseSteerNot.find(
        (n) =>
          n.id === this.props.currentCase.id &&
          n.body !== null &&
          n.resultUrl !== null
      );

      if (currentCaseNot?.body) {
        const steeringInfo = currentCaseNot.body;
        const staticDriver = steeringInfo.static_Driver;
        const setup = steeringInfo.setup;
        const results = steeringInfo.results;
        const storage = steeringInfo.storage;

        let staticDriverDiv = [];
        let setupDiv = [];
        let resultsDiv = [];
        let storageDiv = [];
        for (const [key, value] of Object.entries(staticDriver)) {
          if (value.length === 0) {
          } else if (key === "errors") {
            staticDriverDiv.push(
              <p className="sim-info sim-info-error">{`${key}: ${value}`}</p>
            );
          } else if (key === "warnings") {
            staticDriverDiv.push(
              <p className="sim-info sim-info-warning">{`${key}: ${value}`}</p>
            );
          } else {
            staticDriverDiv.push(
              <p className="sim-info">{`${key}: ${value}`}</p>
            );
          }
        }

        for (const [key, value] of Object.entries(setup)) {
          if (value === null || value === {} || value.length === 0) {
          } else if (key === "errors") {
            setupDiv.push(
              <p className="sim-info sim-info-error">{`${key}: ${value}`}</p>
            );
          } else if (key === "warnings") {
            setupDiv.push(
              <p className="sim-info sim-info-warning">{`${key}: ${value}`}</p>
            );
          } else {
            setupDiv.push(<p className="sim-info">{`${key}: ${value}`}</p>);
          }
        }

        for (const [key, value] of Object.entries(results)) {
          if (value === null || value === {} || value.length === 0) {
          } else if (key === "errors") {
            resultsDiv.push(
              <p className="sim-info sim-info-error">{`${key}: ${value}`}</p>
            );
          } else if (key === "warnings") {
            resultsDiv.push(
              <p className="sim-info sim-info-warning">{`${key}: ${value}`}</p>
            );
          } else {
            resultsDiv.push(<p className="sim-info">{`${key}: ${value}`}</p>);
          }
        }

        for (const [key, value] of Object.entries(storage)) {
          if (value === null || value === {} || value.length === 0) {
          } else if (key === "errors") {
            storageDiv.push(
              <p className="sim-info sim-errors">{`${key}: ${value}`}</p>
            );
          } else if (key === "warnings") {
            storageDiv.push(
              <p className="sim-info sim-warnings">{`${key}: ${value}`}</p>
            );
          } else {
            storageDiv.push(<p className="sim-info">{`${key}: ${value}`}</p>);
          }
        }

        this.setState({
          storage: storageDiv,
          staticDriver: staticDriverDiv,
          setup: setupDiv,
          results: resultsDiv,
          isInfoLoading: false,
          inputChecked: true,
        });
      }
    } else if (this.state.setup && this.state.isInfoLoading) {
      this.setState({ isInfoLoading: false });
    }

    if (
      this.props?.simulationNotifications !== prevProps.simulationNotifications
    ) {
      this.setState({
        awaitingSimulationStart:
          this.props?.simulationNotifications?.status === "New",
      });
    }

    if (
      this.props.postprocessNotifications !== prevProps.postprocessNotifications
    ) {
      if (
        this.props.postprocessNotifications[
          this.props.postprocessNotifications.length - 1
        ].body.status === 2
      ) {
        const postprocBody =
          this.props.postprocessNotifications[
            this.props.postprocessNotifications.length - 1
          ].body;

        const results = await downloadBlobAsync(postprocBody.reportJsonUrl);
        const layerSettingsJson = await downloadBlobAsync(
          postprocBody.palmOutputJsonUrl
        );
        if (results) {
          this.props.loadResults(results);
        }
        console.log(layerSettingsJson);
        this.props.loadLayerSettingsJson(layerSettingsJson);
      }
    }
  }

  async checkSteeringInput() {
    this.setState({
      inputChecked: true,
      isInfoLoading: true,
    });
    await postSteering(this.props.currentCase.id);
  }

  handleSimulationClick = async () => {
    if (this.props.currentCase.applicationField === 2) {
      const savedSetup = await getCaseSetup(this.props.match.params.caseId);
      const savedSettings = savedSetup.setup.settings.wind_comfort_parameters;
      if (savedSetup.steeringJob.additionalData.windHeader) {
        savedSettings.windHeader =
          savedSetup.steeringJob.additionalData.windHeader;
        savedSettings.windTable =
          savedSetup.steeringJob.additionalData.windTable;
      }

      for (let index = 0; index < savedSettings.windDirectionsNumber; index++) {
        try {
          this.setState({
            simRequest: null,
            awaitingSimulationStart: true,
          });

          await requestCustomSimulation(
            this.props.currentCase.id,
            savedSettings,
            null
          );
        } catch (e) {
          this.setState({
            simulationRequestError: e.message,
          });
        }
      }
    } else if (
      this.props.currentCase.applicationField === 3 ||
      this.props.currentCase.applicationField === 1
    ) {
      const savedSetup = await getCaseSetup(this.props.match.params.caseId);
      console.log(savedSetup);
      const savedSettings = savedSetup.setup.settings;
      if (savedSettings.chemistryFile) {
        savedSettings.chemistryFile =
          savedSetup.steeringJob.additionalData.chemistryFile;
        savedSettings.chemistryChildFile =
          savedSetup.steeringJob.additionalData.chemistryChildFile;
      }

      console.log(savedSettings);

      try {
        this.setState(
          {
            simRequest: null,
            awaitingSimulationStart: true,
          },
          async () => {
            await requestCustomSimulation(
              this.props.currentCase.id,
              savedSettings,
              null
            );
          }
        );
      } catch (e) {
        this.setState({
          simulationRequestError: e.message,
        });
      }
    } else {
      try {
        ///custom jak w wind?
        this.setState(
          {
            simRequest: null,
            awaitingSimulationStart: true,
          },
          async () => {
            await requestSimulation(
              this.props.currentCase.id,
              this.props.simulationNotifications?.id ?? null
            );
          }
        );
      } catch (e) {
        this.setState({
          simulationRequestError: e.message,
        });
      }
    }
  };

  requestPostProcessing = async () => {
    if (this.props.currentCase.applicationField === 2) {
      const currentCase = cloneDeep(this.props.currentCase);
      const savedSettings = await getCaseSetup(this.props.match.params.caseId);
      let simulationJobs = currentCase.simulationJobs;

      const results = simulationJobs.map((x) => {
        return x.results;
      });

      const settings = {
        appField: 2,
        ncStatic: currentCase.staticDriverJob.resultUrl,
        ncStaticChild: currentCase.staticDriverJob.childResultUrl,
        additionalSettings: {},
        steering: savedSettings.setup.settings,
        results: results,
      };

      if (savedSettings.steeringJob.additionalData.windHeader) {
        settings.steering.wind_comfort_parameters.windHeader =
          savedSettings.steeringJob.additionalData.windHeader;

        settings.steering.wind_comfort_parameters.windTable =
          savedSettings.steeringJob.additionalData.windTable;
      }

      console.log(JSON.stringify(settings, null, 2));

      let simulationJobId =
        this.props.currentCase.simulationJobs[
          this.props.currentCase.simulationJobs.length - 1
        ].id;

      if (!simulationJobId) {
        simulationJobId =
          this.props.currentCase.simulationJobs[
            this.props.currentCase.simulationJobs.length - 1
          ].requestId;
      }

      try {
        await requestCustomPostProcessingAsync(
          this.props.currentProject.id,
          this.props.currentCase.id,
          simulationJobId,
          "evaluation",
          settings,
          {}
        );

        ///trigger simulation job id with random value
      } catch (e) {
        console.log(e);
      }
    } else {
      try {
        await requestPostProcessingAsync(
          this.props.currentProject.id,
          this.props.currentCase.id,
          this.props.currentCase.simulationJobs[0].id
            ? this.props.currentCase.simulationJobs[0].id
            : this.props.currentCase.simulationJobs[0].requestId
        );
      } catch (e) {
        console.log(e);
      }
    }
  };

  toggleCancelModal() {
    this.setState((prevState) => ({
      isCancelModalOpen: !prevState.isCancelModalOpen,
    }));
  }

  async handleBudgetRefresh() {
    const userSettings = await getUserSettingsAsync();
    const budgetAvailableHours = minutesToHoursMinutesSeconds(
      userSettings.budget.simulationMinutes
    );
    this.setState({ budgetAvailableHours: budgetAvailableHours });
  }

  render() {
    const { t, currentCase } = this.props;
    let isSingleStaticDriverReady = false;
    let isNestedStaticDriverReady = false;
    let nestedMinPointCoords = null;
    let staticDriverNotifications = null;
    if (currentCase !== null && currentCase !== undefined) {
      staticDriverNotifications = this.props.staticDriverNotifications.find(
        (n) =>
          n.body?.caseId === this.props.currentCase?.id &&
          n.type === "staticDriver" &&
          n.body?.status === 2
      );
    }
    if (currentCase?.staticDriverJob?.childResultUrl) {
      isNestedStaticDriverReady = true;

      const parentRasterMinCoords = calculateRasterMinPoint(
        this.props.rasterCenterLatUtm,
        this.props.rasterCenterLngUtm,
        this.props.parentGridSize,
        this.props.parentRasterGridXNumber,
        this.props.parentRasterGridYNumber
      );

      const childRasterMinCoords = calculateRasterMinPoint(
        this.props.rasterCenterLatUtm,
        this.props.rasterCenterLngUtm,
        this.props.childGridSize,
        this.props.childRasterGridXNumber,
        this.props.childRasterGridYNumber
      );

      nestedMinPointCoords = {
        xMin: Math.abs(parentRasterMinCoords.xMin - childRasterMinCoords.xMin),
        yMin: Math.abs(parentRasterMinCoords.yMin - childRasterMinCoords.yMin),
      };
    } else if (currentCase?.staticDriverJob?.resultUrl) {
      isSingleStaticDriverReady = true;
    }

    if (
      staticDriverNotifications !== undefined &&
      staticDriverNotifications !== null
    ) {
      isNestedStaticDriverReady =
        staticDriverNotifications?.body.childResultUrl === null ? false : true;

      if (
        !isNestedStaticDriverReady &&
        staticDriverNotifications?.body.childResultUrl === null &&
        staticDriverNotifications?.body.resultUrl !== null
      ) {
        isSingleStaticDriverReady = true;
      } else if (isNestedStaticDriverReady) {
        const parentRasterMinCoords = calculateRasterMinPoint(
          this.props.rasterCenterLatUtm,
          this.props.rasterCenterLngUtm,
          this.props.parentGridSize,
          this.props.parentRasterGridXNumber,
          this.props.parentRasterGridYNumber
        );

        const childRasterMinCoords = calculateRasterMinPoint(
          this.props.rasterCenterLatUtm,
          this.props.rasterCenterLngUtm,
          this.props.childGridSize,
          this.props.childRasterGridXNumber,
          this.props.childRasterGridYNumber
        );

        nestedMinPointCoords = {
          xMin: Math.abs(
            parentRasterMinCoords.xMin - childRasterMinCoords.xMin
          ),
          yMin: Math.abs(
            parentRasterMinCoords.yMin - childRasterMinCoords.yMin
          ),
        };
      }
    }

    const canCheckInput =
      this.state.savedSettings &&
      (isSingleStaticDriverReady || isNestedStaticDriverReady);

    const canStartSimulation =
      this.state.inputChecked &&
      this.state.savedSettings &&
      (isSingleStaticDriverReady || isNestedStaticDriverReady);
    return (
      <div
        className="page setup-page"
        style={{
          flexDirection: "row",
          width: "auto",
          minWidth: "1200px",
          justifyContent: "space-between",
        }}
      >
        <div className="sim-left-box">
          <h1>{t("Simulation")}</h1>
          <FormSeparator label={t("Input")} />
          {!currentCase && <Loader />}
          <SimWarning
            savedSettings={this.state.savedSettings}
            isSingleStaticDriverReady={isSingleStaticDriverReady}
            isNestedStaticDriverReady={isNestedStaticDriverReady}
          />
          <div className="runtime-container settings-form sim-container">
            <div className="sim-input">
              {nestedMinPointCoords !== null && (
                <div
                  style={{
                    marginTop: "12px",
                    fontSize: "13px",
                    whiteSpace: "pre-wrap",
                  }}
                  className="form-label"
                >{`${t(`RasterPreview`)}: x-${t(`Difference`)}: ${
                  nestedMinPointCoords.xMin
                }  y-${t(`Difference`)}: ${nestedMinPointCoords.yMin}`}</div>
              )}
              <div
                style={{
                  borderTop: "1px solid rgba(100, 105, 110, 0.62)",
                  height: "1px",
                  marginBottom: "12px",
                }}
              />
            </div>
          </div>
          <button
            className={`${
              canCheckInput &&
              !this.state.setup &&
              !this.state.isInfoLoading &&
              this.props.simulationNotifications?.status !== "finished"
                ? `sim-check-inputs-btn `
                : ` sim-btn-disabled`
            }`}
            onClick={this.checkSteeringInput}
            disabled={
              !canCheckInput || this.state.setup || this.state.isInfoLoading
            }
          >
            {this.state.isInfoLoading ? (
              <div>
                <Loader size="simulation" />
              </div>
            ) : (
              <Search
                color={
                  !this.state.inputChecked ? "rgb(102, 102, 102)" : "#a0a5aa"
                }
                size="15px"
                className="sim-btn-icon"
              />
            )}
            <p className="sim-btn-inputs-text sim-btn-text-icon">
              {t("CheckInputs")}
            </p>
          </button>
          {this.props.currentCase?.applicationField !== 4 && (
            <>
              <SimStartBtn
                handleSimulationClick={this.handleSimulationClick}
                canStartSimulation={canStartSimulation}
                isInfoLoading={this.state.isInfoLoading}
                simulationNotifications={this.props.simulationNotifications}
                cancelSimulation={this.toggleCancelModal}
                awaitingSimulationStart={this.state.awaitingSimulationStart}
              />

              <div className="budget-hours-sim">
                <div>
                  <span
                    className="text-input-form-label"
                    style={{ marginBottom: 0, fontWeight: 500 }}
                  >
                    {t("AvailableHours")}:&nbsp;
                  </span>
                  <span
                    className="text-input-form-label"
                    style={{
                      fontSize: "12px",
                      marginBottom: 0,
                      fontWeight: 500,
                    }}
                  >
                    {this.state.budgetAvailableHours
                      ? this.state.budgetAvailableHours
                      : minutesToHoursMinutesSeconds(
                          this.props.simulationMinutes
                        )}
                  </span>
                </div>
                <button
                  className="raster-settings-btn  refresh-hours-sim"
                  onClick={this.handleBudgetRefresh}
                >
                  <div className="btn-content-wrapper">
                    <RotateCw
                      color={"#494949"}
                      size={"12px"}
                      strokeWidth={"2px"}
                    />
                    <div className="raster-btn-lbl">{t("Refresh")}</div>
                  </div>
                </button>
              </div>
            </>
          )}
          {this.state.simulationRequestError && (
            <p className="sim-info sim-errors">{`${this.state.simulationRequestError}`}</p>
          )}
          {(!!this.props.simulationNotifications ||
            this.props.currentCase?.simulationJobs.length > 0) && (
            <SimStatus
              {...this.props.simulationNotifications}
              requestPostProcessing={this.requestPostProcessing}
              //currentCase={this.props.currentCase}
            />
          )}
        </div>

        <div className="sim-right-box" style={{ width: "648px" }}>
          <SimTabs
            currentCase={this.props.currentCase}
            inputChecked={this.state.inputChecked}
            setup={this.state.setup}
            storage={this.state.storage}
            staticDriver={this.state.staticDriver}
            results={this.state.results}
            isInfoLoading={this.state.isInfoLoading}
            simulationNotifications={this.props.simulationNotifications}
            isWindTableUserDefined={this.state.isWindTableUserDefined}
          />
        </div>
        <DeleteModal
          modalHdr={t("CancelSimulation")}
          modalInfo={t("CancelSimulationModalInfo")}
          isOpen={this.state.isCancelModalOpen}
          closeDeleteModal={this.toggleCancelModal}
          deleteModalData={() => {
            this.toggleCancelModal();
            cancelSimulation(
              this.props.currentCase.id,
              this.props.simulationNotifications?.id
            );
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    steeringNotifications: state.notifications.steeringNotifications,
    staticDriverNotifications: state.notifications.notifications,
    //currentCase: selectCurrentCase(state),
    currentCase: state.projects.currentCase,
    simulationNotifications: selectCaseSimulationNotifications(state),
    rasterCenterLatUtm: state.rasterArea.rasterCenterLatUtm,
    rasterCenterLngUtm: state.rasterArea.rasterCenterLngUtm,
    parentRasterGridXNumber: state.rasterArea.parentRasterGridXNumber,
    parentRasterGridYNumber: state.rasterArea.parentRasterGridYNumber,
    childRasterGridXNumber: state.rasterArea.childRasterGridXNumber,
    childRasterGridYNumber: state.rasterArea.childRasterGridYNumber,
    parentGridSize: state.rasterArea.parentGridSize,
    childGridSize: state.rasterArea.childGridSize,
    rasterGridXNumber: state.rasterArea.rasterGridXNumber,
    rasterGridYNumber: state.rasterArea.rasterGridYNumber,
    rasterGridWidth: state.rasterArea.rasterGridWidth,
    currentProject: state.projects.loadedProject,
    postprocessNotifications: state.notifications.postprocessNotifications,
    simulationMinutes: state.userSettings.budget.simulationMinutes,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadSimulationJob: (payload) => dispatch(loadSimulationJob(payload)),
    loadResults: (payload) => dispatch(loadResults(payload)),
    loadLayerSettingsJson: (payload) =>
      dispatch(loadLayerSettingsJson(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(Simulation)));

function calculateRasterMinPoint(
  rasterCenterLatUtm,
  rasterCenterLngUtm,
  gridSize,
  rasterGridXNumber,
  rasterGridYNumber
) {
  const resolution = gridSize;
  const xLength = resolution * rasterGridXNumber;
  const yLength = resolution * rasterGridYNumber;
  const xMin = rasterCenterLngUtm - xLength / 2;
  const yMin = Number(rasterCenterLatUtm) - Number(yLength) / 2;

  return { xMin: xMin, yMin: yMin };
}
