export const types = {
  ADD_NOTIFICATION: "ADD_NOTIFICATION",
  CREATE_NOTIFICATION: "CREATE_NOTIFICATION",
  CLEAR_NOTIFICATIONS: "CLEAR_NOTIFICATIONS",
  DISMISS_NOTIFICATION: "DISMISS_NOTIFICATION",
  ACK_NOTIFICATIONS: "ACK_NOTIFICATIONS",
  ADD_STEERING_NOTIFICATION: "ADD_STEERING_NOTIFICATION",
  ADD_SIMULATION_NOTIFICATION: "ADD_SIMULATION_NOTIFICATION",
  ADD_POSTPROCESS_NOTIFICATION: "ADD_POSTPROCESS_NOTIFICATION",
};
