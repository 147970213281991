import React, { useState, useEffect } from "react";
import Select from "react-select";
import Modal from "react-modal";
import { Plus, Minus } from "react-feather";
import CircleButton from "../../Button/Circle/CircleButton";
import { NewWsMapInput } from "./NewWsMapInput";
import { selectComponents } from "./selectComponents";
import { useTranslation } from "react-i18next";
import "./index.scss";

Modal.setAppElement("body");

const BackgroundMaps = (props) => {
  useEffect(() => {
    if (
      currentValue !== null &&
      !options.some((x) => {
        return x.label === currentValue.label;
      })
    ) {
      setOptions([...options, currentValue]);
    }
  }, []);

  const [currentValue, setCurrentValue] = useState(
    props.wsMaps?.currentValue
      ? {
          label: `${props.wsMaps?.currentValue.name} (${props.wsMaps?.currentValue.url})`,
          value: props.wsMaps?.currentValue,
        }
      : null
  );

  const [options, setOptions] = useState(
    props.wsMaps?.options?.map((m) => {
      return { label: `${m.name} (${m.url})`, value: m };
    }) || []
  );
  const [newMapIsOpen, setNewMapIsOpen] = useState(false);

  const { t } = useTranslation();

  function onChange(option) {
    setCurrentValue(option);
    props.onChange({
      currentValue: option.value,
      options: toWsOptions(options),
    });
  }

  function onNewOption(option) {
    setOptions([
      ...options,
      { value: option, label: `${option.name} (${option.url})` },
    ]);
    props.onChange({
      currentValue: option,
      options: toWsOptions(options),
    });
  }

  function removeOption(option) {
    const newOptions = options.filter((o) => o.value !== option.value);
    setOptions(newOptions);
    let newCurrentValue = null;

    if (currentValue?.value.name !== option?.value.name) {
      newCurrentValue = currentValue;
    }
    setCurrentValue(newCurrentValue);
    props.onChange({
      currentValue: newCurrentValue,
      options: toWsOptions(newOptions),
    });
  }

  function toWsOptions(options) {
    return options.map((o) => ({ ...o.value }));
  }

  return (
    <div className="background-maps-container">
      <Modal
        isOpen={newMapIsOpen}
        className="modal"
        onRequestClose={() => setNewMapIsOpen(false)}
        overlayClassName="overlay"
        parentSelector={() =>
          document.querySelector(".background-maps-container")
        }
      >
        <NewWsMapInput
          options={options}
          onNew={onNewOption}
          onClose={() => setNewMapIsOpen(false)}
        />
      </Modal>
      <label className="text-input-form-label">{props.label}</label>
      <div className="ws-select__container">
        <Select
          {...props}
          className="maps-select"
          isSearchable={false}
          value={currentValue}
          options={options}
          placeholder={`${t(`Select`)}...`}
          styles={selectComponents.styles}
          selectProps={{ onRemove: removeOption }}
          components={{ Option: selectComponents.removableOption }}
          onChange={onChange}
          noOptionsMessage={() => t("NoOptions")}
        />
        <CircleButton
          icon={newMapIsOpen ? Minus : Plus}
          onClick={() => setNewMapIsOpen(!newMapIsOpen)}
        />
      </div>
    </div>
  );
};
export default BackgroundMaps;
