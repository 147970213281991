import React from "react";
import "./ProgressBar.scss";

const ProgressBar = (props) => {
  const { progress, status } = props;
  return (
    <div className="progress-bar-containter">
      <div
        className="progress-bar-filler"
        style={{
          width: `${status !== "finished" ? progress : 100}%`,
        }}
      ></div>
      <span
        className="progress-bar-indicator"
        style={{
          color: `${
            progress > 55 || status === "finished" ? "white" : "#424242"
          }`,
        }}
      >
        {status === "finished" ? "100%" : progress + "%"}
      </span>
    </div>
  );
};

export default ProgressBar;
