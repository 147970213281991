import React, { useState, useEffect } from "react";
import { ChevronRight } from "react-feather";

const ExpertMenuAccordion = (props) => {
  const [expanded, setExpanded] = useState(!!props.expanded || false);

  useEffect(() => {
    setExpanded(props.expanded);
  }, [props.expanded]);

  return (
    <div
      className="acc-exp-sec exp-acc-sec"
      style={{ justifyContent: "flex-start" }}
    >
      <span
        className={`acc-exp-sec_btn`}
        onClick={() => setExpanded(!expanded)}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          {props.title}
          <div style={{ minWidth: 12 }}>
            <ChevronRight
              className={`
         acc-exp-sec__chevron
          ${expanded && " acc-exp-sec__chevron--expanded"}
          `}
              size={"12px"}
              strokeWidth={"3px"}
              color={"rgb(68, 68, 68)"}
            />
          </div>
        </div>
      </span>
      {expanded && (
        <div className={"acc-exp-sec__content acc-exp-sec__content--expanded"}>
          {props.children}
        </div>
      )}
    </div>
  );
};

export default ExpertMenuAccordion;
