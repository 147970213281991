import React from "react";
import { Select } from "../Form/Select";
import { Box } from "../Form/Box";
import { Edit, Trash, HelpCircle } from "react-feather";
import { ReactComponent as PointIcon } from "../PolygonEditor/point-icon.svg";
import { getSelectedPolygonColor } from "../../../utils/polygons/polygonColor";
import {
  setObjectTypeIndex,
  setObjectSubtypeIndex,
  addCoordsRow,
  removeCoordsRow,
  handleCoordInputChange,
  startNewPolygon,
  removeDrawnPolygon,
  initializePolygonEditor,
  updateTempInput,
  selectPolygonPoint,
  selectEditorPoint,
} from "../../../redux/actions/mapActions";
import {
  allObjectTypes,
  streetObjectType,
} from "../../../redux/reducers/mapReducer";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import "./PolygonEditor.scss";

class PolygonEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleObjectTypeChange = this.handleObjectTypeChange.bind(this);
    this.handleObjectSubtypeChange = this.handleObjectSubtypeChange.bind(this);
    this.handleCoordsInputChange = this.handleCoordsInputChange.bind(this);
  }

  componentDidMount() {
    this.props.initializePolygonEditor();
  }

  componentWillUnmount() {
    this.props.initializePolygonEditor();
  }

  handleObjectTypeChange(e) {
    this.props.setObjectTypeIndex(e.target.selectedIndex);
  }

  handleObjectSubtypeChange(e) {
    this.props.setObjectSubtypeIndex(e.target.selectedIndex);
  }

  handleCoordsInputChange(e, drawnPolyCoordsIndex) {
    const reg = new RegExp(/^[+-]?\d*(?:[.,]\d*)?$/);
    const inputValue = e.target.value.toString();
    const typeOfCoords = e.target.name;
    if (reg.test(inputValue) || inputValue === "") {
      if (
        inputValue === "" ||
        inputValue.slice(-1) === "." ||
        inputValue.slice(-1) === "-" ||
        (typeOfCoords === "lat" && inputValue > 10000000) ||
        (typeOfCoords === "lng" && inputValue > 1000000)
      ) {
        this.props.updateTempInput({
          drawnPolyCoordsIndex: drawnPolyCoordsIndex,
          tempKey: typeOfCoords,
          tempValue: inputValue,
        });
      } else {
        this.props.handleCoordInputChange({
          drawnPolyCoordsIndex: drawnPolyCoordsIndex,
          typeOfCoords: typeOfCoords,
          value: inputValue,
        });
      }
    }
  }

  render() {
    const { t } = this.props;

    return (
      <div>
        <div className="polygon-editor-id-line">
          <span
            className="polygon-action-btn"
            onClick={() => this.props.startNewPolygon()}
          >
            <Edit size="10px" strokeWidth="2.5px" />
            <span className="polygon-action-lbl">{t("NewPolygon")}</span>
          </span>
          <span className="polygon-action-btn" style={{ marginLeft: "12px" }}>
            <Trash size="10px" strokeWidth="2.5px" />
            <span
              className="polygon-action-lbl"
              onClick={() =>
                this.props.removeDrawnPolygon(this.props.selectedPolygonIndex)
              }
            >
              {t("DeletePolygon")}
            </span>
          </span>
        </div>
        <Select
          label={
            this.props.inStreetDrawingMode
              ? t(`${streetObjectType.fieldName}`)
              : t(`${allObjectTypes.fieldName}`)
          }
          value={
            this.props.inStreetDrawingMode
              ? streetObjectType
              : this.props.objectType
          }
          options={
            this.props.inStreetDrawingMode
              ? streetObjectType.value.map((x) => {
                  return {
                    label: t(`${x}`),
                    value: x,
                  };
                })
              : allObjectTypes.value.map((x) => {
                  return {
                    label: t(`${x}`),
                    value: x,
                  };
                })
          }
          onChange={this.handleObjectTypeChange}
          help={
            this.props.objectType === "building"
              ? "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/urban_surface_parameters#building_type"
              : this.props.objectType === "vegetation"
              ? "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/land_surface_parameters#vegetation_type"
              : this.props.objectType === "pavement"
              ? "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/land_surface_parameters#pavement_type"
              : this.props.objectType === "water"
              ? "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/land_surface_parameters#water_type"
              : this.props.objectType === "tree patches" ||
                this.props.objectType === "single tree"
              ? "https://palm_gui.pages.fraunhofer.de/palmgui_handbuch/rasterization.html#d-b%C3%A4ume-tree-patches-und-single-trees"
              : this.props.objectType === "street"
              ? "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/chememi"
              : null
          }
        />
        <Select
          label={t(`${this.props.allObjectSubtypes.fieldName}`)}
          value={this.props.objectSubtype}
          options={this.props.allObjectSubtypes.value.map((x) => {
            return {
              label: t(`${x}`),
              value: x,
            };
          })}
          onChange={this.handleObjectSubtypeChange}
          help={
            this.props.objectType === "building"
              ? "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/urban_surface_parameters#building_type"
              : this.props.objectType === "vegetation"
              ? "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/land_surface_parameters#vegetation_type"
              : this.props.objectType === "pavement"
              ? "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/land_surface_parameters#pavement_type"
              : this.props.objectType === "water"
              ? "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/land_surface_parameters#water_type"
              : this.props.objectType === "tree patches" ||
                this.props.objectType === "single tree"
              ? "https://palm_gui.pages.fraunhofer.de/palmgui_handbuch/rasterization.html#d-b%C3%A4ume-tree-patches-und-single-trees"
              : this.props.objectType === "street"
              ? "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/chememi"
              : null
          }
        />
        <Box
          objectType={
            this.props.inStreetDrawingMode ? "street" : this.props.objectType
          }
        />
        <div
          style={{
            alignItems: "center",
            color: "#4f4f4f",
            fontSize: "12px",
            display: "flex",
            justifyContent: "center",
            paddingTop: "12px",
          }}
        >
          <HelpCircle
            size="14px"
            color="#4f4f4f"
            strokeWidth={"2px"}
            style={{ minWidth: "13px" }}
          />
          <div style={{ paddingLeft: "12px" }}>
            {t("AddPolygonByClickingOnMap")}
          </div>
        </div>

        {this.props.inStreetDrawingMode && (
          <div
            style={{
              alignItems: "center",
              color: "#4f4f4f",
              fontSize: "12px",
              display: "flex",
              justifyContent: "center",
              paddingTop: "12px",
            }}
          >
            <HelpCircle
              size="14px"
              color="#4f4f4f"
              strokeWidth={"2px"}
              style={{ minWidth: "13px" }}
            />
            <div style={{ paddingLeft: "12px" }}>
              {t("StreetInactiveWarning")}
            </div>
          </div>
        )}

        <div className="polygon-hdr-container">
          <div className="polygon-main-coords-container">
            <div className="long-icon"></div>
            <label>{`${t("Easting")}`}</label>
            <div className="lat-icon"></div>
            <label style={{ marginRight: "-20px" }}>{`${t("Northing")}`}</label>
          </div>
        </div>

        <div className="polygon-coords-container">
          {this.props.inputCoords.map((el, i) => (
            <>
              <div className="polygon-coords-input-row" key={i}>
                <>
                  <label className="coords-counter">{i + 1}</label>
                  <div className="coords-point-icon">
                    <PointIcon
                      stroke={
                        i === this.props.selectedPolygonPoint
                          ? getSelectedPolygonColor(
                              this.props.objectTypeIndex + 1,
                              this.props.inputPalmTypesPalette
                            )
                          : "#636363"
                      }
                      height={16}
                      width={16}
                    />
                  </div>
                </>

                <>
                  <input
                    className={"polygon-coord-input"}
                    placeholder={""}
                    name="lng"
                    value={
                      this.props.drawnPolyCoordsIndex === i &&
                      this.props.tempKey === "lng" &&
                      (this.props.tempValue || this.props.tempValue === "")
                        ? this.props.tempValue
                        : el.lng
                    }
                    unit={""}
                    autoComplete={"off"}
                    onChange={(e) => this.handleCoordsInputChange(e, i)}
                    onBlur={() =>
                      this.props.updateTempInput({ tempValue: "", tempKey: "" })
                    }
                    onFocus={() => this.props.selectEditorPoint(i)}
                  />

                  <span className="deltacoord-right-unit">{`[m]`}</span>

                  <input
                    className={"polygon-coord-input"}
                    placeholder={""}
                    name="lat"
                    value={
                      this.props.drawnPolyCoordsIndex === i &&
                      this.props.tempKey === "lat" &&
                      (this.props.tempValue || this.props.tempValue === "")
                        ? this.props.tempValue
                        : el.lat
                    }
                    unit={""}
                    autoComplete={"off"}
                    onChange={(e) => this.handleCoordsInputChange(e, i)}
                    onBlur={() =>
                      this.props.updateTempInput({ tempValue: "", tempKey: "" })
                    }
                    onFocus={() => this.props.selectEditorPoint(i)}
                  />

                  <span className="deltacoord-unit">{`[m]`}</span>

                  <div className="plus-minus-coords-container">
                    <input
                      className={
                        "changing-polygon-point-btn add-polygon-point-btn"
                      }
                      type="button"
                      value="+"
                      onClick={() => this.props.addCoordsRow(i)}
                      disabled={i === 0}
                    />
                    <input
                      className="changing-polygon-point-btn"
                      type="button"
                      value="-"
                      onClick={() => this.props.removeCoordsRow(i)}
                      disabled={this.props.inputCoords.length < 4 || i === 0}
                    />
                  </div>
                </>
              </div>
              {i === 0 ? (
                <div className={"polygon-delta-container"}>
                  <label className="delta-lbl">Δ x</label>
                  <label className="delta-lbl">Δ y</label>
                </div>
              ) : (
                ""
              )}
            </>
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedPolygonIndex: state.map.selectedPolygonIndex,
    objectType: state.map.objectType,
    objectSubtype: state.map.objectSubtype,
    allObjectSubtypes: state.map.allObjectSubtypes,
    inputCoords: state.map.inputCoords,
    drawnPolyCoordsIndex: state.map.drawnPolyCoordsIndex,
    tempValue: state.map.tempValue,
    tempKey: state.map.tempKey,
    inStreetDrawingMode: state.map.inStreetDrawingMode,
    selectedPolygonPoint: state.map.selectedPolygonPoint,
    inputPalmTypesPalette:
      state.userSettings?.settings?.visualizationPresets
        ?.inputPalmTypesPalette || {},
    objectTypeIndex: state.map.objectTypeIndex,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setObjectTypeIndex: (payload) => dispatch(setObjectTypeIndex(payload)),
    setObjectSubtypeIndex: (payload) =>
      dispatch(setObjectSubtypeIndex(payload)),
    addCoordsRow: (payload) => dispatch(addCoordsRow(payload)),
    removeCoordsRow: (payload) => dispatch(removeCoordsRow(payload)),
    handleCoordInputChange: (payload) =>
      dispatch(handleCoordInputChange(payload)),
    startNewPolygon: () => dispatch(startNewPolygon()),
    removeDrawnPolygon: (payload) => dispatch(removeDrawnPolygon(payload)),
    initializePolygonEditor: () => dispatch(initializePolygonEditor()),
    updateTempInput: (payload) => dispatch(updateTempInput(payload)),
    selectPolygonPoint: (payload) => dispatch(selectPolygonPoint(payload)),
    selectEditorPoint: (payload) => dispatch(selectEditorPoint(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(PolygonEditor));
