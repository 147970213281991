import React from "react";
import "./CircleButton.scss";

const CircleButton = (props) => {
  const Icon = props.icon;
  return (
    <div
      className={`circle-button ${props.disabled ? " button-disabled" : ""} ${
        props.className || ""
      }`}
      onClick={props.onClick}
    >
      <Icon className="circle-button_icon" />
      {props.children}
    </div>
  );
};

export default CircleButton;
