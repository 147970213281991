import { call, put, takeEvery } from "redux-saga/effects";
import i18n from "./../../utils/translations/i18n";
import * as settingsApi from "../../api/userSettingsApi";
import * as settingsActions from "../actions/settingsActions";
import * as storageApi from "../../api/storageApi";
import { types } from "../types/settings";

export function* loadSettings() {
  try {
    const user = yield call(settingsApi.getUserSettingsAsync);
    if (user?.settings?.pictureUrl) {
      const pictureDownloadUrl = yield call(
        storageApi.getDownloadLink,
        user?.settings?.pictureUrl
      );
      user.settings.pictureDownloadUrl = pictureDownloadUrl;
    }
    i18n.changeLanguage(user?.settings?.preferredLanguage);
    yield put(settingsActions.loadSettingsSuccess(user));
  } catch (error) {
    yield put(settingsActions.loadSettingsError(error));
  }
}

function* saveSettings(action) {
  try {
    const settings = yield call(
      settingsApi.saveUserSettingsAsync,
      action.payload
    );
    yield put(settingsActions.saveSettingsSuccess(settings));
    yield put(settingsActions.loadSettings());
  } catch (error) {
    yield put(settingsActions.saveSettingsError(error));
  }
}

export default function* projectsSaga() {
  yield takeEvery(types.SETTINGS_LOAD, loadSettings);
  yield takeEvery(types.SETTINGS_SAVE, saveSettings);
}
