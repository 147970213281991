import { types } from "../types/checkFile";

export function checkGeojsonFile(payload) {
  return {
    type: types.CHECK_GEOJSON_FILE,
    payload,
  };
}

export function checkGeotiffFile(payload) {
  return {
    type: types.CHECK_GEOTIFF_FILE,
    payload,
  };
}

export function resetCheckFileState() {
  return {
    type: types.RESET_CHECK_FILE_STATE,
  };
}

export function resetGeojsonCheckFile() {
  return {
    type: types.RESET_GEOJSON_CHECK_FILE,
  };
}

export function resetGeotiffCheckFile() {
  return {
    type: types.RESET_GEOTIFF_CHECK_FILE,
  };
}

export function setProjectEpsg(payload) {
  return {
    type: types.SET_PROJECT_EPSG,
    payload,
  };
}
