const asiaOptions = [
  {
    label: "EPSG:3414 | SVY21 / Singapore TM",
    crs: "SVY21 / Singapore TM",
    value: "EPSG:3414",
    bnd: [
      [103.59, 1.13],
      [104.07, 1.47],
    ],
    def: "+proj=tmerc +lat_0=1.36666666666667 +lon_0=103.833333333333 +k=1 +x_0=28001.642 +y_0=38744.572 +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/3414",
    cmt: "",
  },
  {
    label: "EPSG:5179 | Korea 2000/ Unified CS",
    crs: "Korea 2000/ Unified CS",
    value: "EPSG:5179",
    bnd: [
      [122.71, 28.6],
      [134.28, 40.27],
    ],
    def: "+proj=tmerc +lat_0=38 +lon_0=127.5 +k=0.9996 +x_0=1000000 +y_0=2000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/5179",
    cmt: "",
  },
  {
    label: "EPSG:5185 | Korea 2000/ West Belt 2010",
    crs: "Korea 2000/ West Belt 2010",
    value: "EPSG:5185",
    bnd: [
      [124, 28.6],
      [126, 40.27],
    ],
    def: "+proj=tmerc +lat_0=38 +lon_0=125 +k=1 +x_0=200000 +y_0=600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/5185",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:5186 | Korea 2000/ Central Belt 2010",
    crs: "Korea 2000/ Central Belt 2010",
    value: "EPSG:5186",
    bnd: [
      [126, 28.6],
      [128, 40.27],
    ],
    def: "+proj=tmerc +lat_0=38 +lon_0=127 +k=1 +x_0=200000 +y_0=600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/5186",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:5187 | Korea 2000/ East Belt 2010",
    crs: "Korea 2000/ East Belt 2010",
    value: "EPSG:5187",
    bnd: [
      [128, 28.6],
      [130, 40.27],
    ],
    def: "+proj=tmerc +lat_0=38 +lon_0=129 +k=1 +x_0=200000 +y_0=600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/5187",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:5188 | Korea 2000/ East Sea Belt 2010",
    crs: "Korea 2000/ East Sea Belt 2010",
    value: "EPSG:5188",
    bnd: [
      [130, 28.6],
      [132, 40.27],
    ],
    def: "+proj=tmerc +lat_0=38 +lon_0=131 +k=1 +x_0=200000 +y_0=600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/5188",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:6688 | JDG2011/UTM zone 51N",
    crs: "JDG2011/UTM zone 51N",
    value: "EPSG:6688",
    bnd: [
      [122.38, 17.09],
      [126, 46.05],
    ],
    def: "+proj=utm +zone=51 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/6688",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:6689 | JDG2011/UTM zone 52N",
    crs: "JDG2011/UTM zone 52N",
    value: "EPSG:6689",
    bnd: [
      [126, 17.09],
      [132, 46.05],
    ],
    def: "+proj=utm +zone=52 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/6689",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:6690 | JDG2011/UTM zone 53N",
    crs: "JDG2011/UTM zone 53N",
    value: "EPSG:6690",
    bnd: [
      [132, 17.09],
      [138, 46.05],
    ],
    def: "+proj=utm +zone=53 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/6690",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:6691 | JDG2011/UTM zone 54N",
    crs: "JDG2011/UTM zone 54N",
    value: "EPSG:6691",
    bnd: [
      [138, 17.09],
      [144, 46.05],
    ],
    def: "+proj=utm +zone=54 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/6691",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:6692 | JDG2011/UTM zone 55N",
    crs: "JDG2011/UTM zone 55N",
    value: "EPSG:6692",
    bnd: [
      [144, 17.09],
      [157.65, 46.05],
    ],
    def: "+proj=utm +zone=54 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/6692",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:7756 | WGS 84/Andhra Pradesh",
    crs: "WGS 84/Andhra Pradesh",
    value: "EPSG:7756",
    bnd: [
      [76.75, 12.61],
      [84.81, 19.92],
    ],
    def: "+proj=lcc +lat_0=16.25543298 +lon_0=80.875 +lat_1=13.75 +lat_2=18.75 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/7756",
    cmt: "",
  },
  {
    label: "EPSG:7757 | WGS 84/Arunachal Pradesh",
    crs: "WGS 84/Arunachal Pradesh",
    value: "EPSG:7757",
    bnd: [
      [91.55, 26.65],
      [97.42, 29.47],
    ],
    def: "+proj=lcc +lat_0=28.00157897 +lon_0=94.5 +lat_1=27 +lat_2=29 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/7757",
    cmt: "",
  },
  {
    label: "EPSG:7758 | WGS 84/Assam",
    crs: "WGS 84/Assam",
    value: "EPSG:7758",
    bnd: [
      [89.69, 24.13],
      [96.03, 27.98],
    ],
    def: "+proj=lcc +lat_0=26.00257703 +lon_0=92.75 +lat_1=24.6666666666667 +lat_2=27.3333333333333 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/7758",
    cmt: "",
  },
  {
    label: "EPSG:7759 | WGS 84/Bihar",
    crs: "WGS 84/Bihar",
    value: "EPSG:7759",
    bnd: [
      [83.31, 24.28],
      [88.3, 27.86],
    ],
    def: "+proj=lcc +lat_0=25.87725247 +lon_0=85.875 +lat_1=24.625 +lat_2=27.125 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/7759",
    cmt: "",
  },
  {
    label: "EPSG:7760 | WGS 84/Delhi",
    crs: "WGS 84/Delhi",
    value: "EPSG:7760",
    bnd: [
      [76.83, 28.4],
      [77.34, 28.89],
    ],
    def: "+proj=lcc +lat_0=28.62510126 +lon_0=77 +lat_1=28.375 +lat_2=28.875 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/7760",
    cmt: "",
  },
  {
    label: "EPSG:7761 | WGS 84/Gujarat",
    crs: "WGS 84/Gujarat",
    value: "EPSG:7761",
    bnd: [
      [68.13, 20.05],
      [74.48, 24.71],
    ],
    def: "+proj=lcc +lat_0=22.37807121 +lon_0=71.375 +lat_1=20.7916666666667 +lat_2=23.9583333333333 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/7761",
    cmt: "",
  },
  {
    label: "EPSG:7762 | WGS 84/Haryana",
    crs: "WGS 84/Haryana",
    value: "EPSG:7762",
    bnd: [
      [74.46, 27.65],
      [77.6, 30.94],
    ],
    def: "+proj=lcc +lat_0=29.25226266 +lon_0=76 +lat_1=28.0833333333333 +lat_2=30.4166666666667 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/7762",
    cmt: "",
  },
  {
    label: "EPSG:7763 | WGS 84/Himachal Pradesh",
    crs: "WGS 84/Himachal Pradesh",
    value: "EPSG:7763",
    bnd: [
      [75.57, 30.38],
      [79, 33.26],
    ],
    def: "+proj=lcc +lat_0=31.75183497 +lon_0=77.375 +lat_1=30.75 +lat_2=32.75 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/7763",
    cmt: "",
  },
  {
    label: "EPSG:7764 | WGS 84/Jammu and Kashmir",
    crs: "WGS 84/Jammu and Kashmir",
    value: "EPSG:7764",
    bnd: [
      [73.76, 32.27],
      [79.57, 35.51],
    ],
    def: "+proj=lcc +lat_0=34.75570874 +lon_0=76.5 +lat_1=33.0833333333333 +lat_2=36.4166666666667 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/7764 ",
    cmt: "",
  },
  {
    label: "EPSG:7765 | WGS 84/Jharkhand",
    crs: "WGS 84/Jharkhand",
    value: "EPSG:7765",
    bnd: [
      [83.32, 21.96],
      [87.98, 25.35],
    ],
    def: "+proj=lcc +lat_0=23.62652682 +lon_0=85.625 +lat_1=22.5416666666667 +lat_2=24.7083333333333 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/7765 ",
    cmt: "",
  },
  {
    label: "EPSG:7766 | WGS 84/Madhya Pradesh",
    crs: "WGS 84/Madhya Pradesh",
    value: "EPSG:7766",
    bnd: [
      [74.03, 21.07],
      [82.81, 26.88],
    ],
    def: "+proj=lcc +lat_0=24.00529821 +lon_0=78.375 +lat_1=22 +lat_2=26 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/7766 ",
    cmt: "",
  },
  {
    label: "EPSG:7767 | WGS 84/Maharashtra",
    crs: "WGS 84/Maharashtra",
    value: "EPSG:7767",
    bnd: [
      [72.6, 15.6],
      [80.9, 22.04],
    ],
    def: "+proj=lcc +lat_0=18.88015774 +lon_0=76.75 +lat_1=16.625 +lat_2=21.125 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/7767 ",
    cmt: "",
  },
  {
    label: "EPSG:7768 | WGS 84/Manipur",
    crs: "WGS 84/Manipur",
    value: "EPSG:7768",
    bnd: [
      [92.97, 23.84],
      [94.76, 25.7],
    ],
    def: "+proj=lcc +lat_0=24.75060911 +lon_0=94 +lat_1=24.0833333333333 +lat_2=25.4166666666667 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/7768 ",
    cmt: "",
  },
  {
    label: "EPSG:7769 | WGS 84/Meghalaya",
    crs: "WGS 84/Meghalaya",
    value: "EPSG:7769",
    bnd: [
      [89.82, 25.03],
      [92.81, 26.12],
    ],
    def: "+proj=lcc +lat_0=25.62524747 +lon_0=91.375 +lat_1=25.2083333333333 +lat_2=26.0416666666667 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/7769 ",
    cmt: "",
  },
  {
    label: "EPSG:7770 | WGS 84/Nagaland",
    crs: "WGS 84/Nagaland",
    value: "EPSG:7770",
    bnd: [
      [93.33, 25.2],
      [95.25, 27.05],
    ],
    def: "+proj=lcc +lat_0=26.12581974 +lon_0=94.375 +lat_1=25.375 +lat_2=26.875 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/7770 ",
    cmt: "",
  },
  {
    label: "EPSG:7771 | WGS 84/India Northeast",
    crs: "WGS 84/India Northeast",
    value: "EPSG:7771",
    bnd: [
      [89.69, 21.94],
      [97.42, 29.47],
    ],
    def: "+proj=lcc +lat_0=25.63452135 +lon_0=93.5 +lat_1=23.0416666666667 +lat_2=28.2083333333333 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/7771 ",
    cmt: "",
  },
  {
    label: "EPSG:7772 | WGS 84/Orissa",
    crs: "WGS 84/Orissa",
    value: "EPSG:7772",
    bnd: [
      [81.38, 17.8],
      [87.5, 22.57],
    ],
    def: "+proj=lcc +lat_0=20.25305174 +lon_0=84.375 +lat_1=18.5833333333333 +lat_2=21.9166666666667 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/7772 ",
    cmt: "",
  },
  {
    label: "EPSG:7773 | WGS 84/Punjab",
    crs: "WGS 84/Punjab",
    value: "EPSG:7773",
    bnd: [
      [73.87, 29.54],
      [76.94, 32.58],
    ],
    def: "+proj=lcc +lat_0=31.00178226 +lon_0=75.375 +lat_1=30 +lat_2=32 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/7773 ",
    cmt: "",
  },
  {
    label: "EPSG:7774 | WGS 84/Rajasthan",
    crs: "WGS 84/Rajasthan",
    value: "EPSG:7774",
    bnd: [
      [69.48, 23.06],
      [78.27, 30.2],
    ],
    def: "+proj=lcc +lat_0=26.88505546 +lon_0=73.875 +lat_1=24.2916666666667 +lat_2=29.4583333333333 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/7774 ",
    cmt: "",
  },
  {
    label: "EPSG:7775 | WGS 84/Uttar Pradesh",
    crs: "WGS 84/Uttar Pradesh",
    value: "EPSG:7775",
    bnd: [
      [77.08, 23.87],
      [84.64, 30.42],
    ],
    def: "+proj=lcc +lat_0=27.13270823 +lon_0=80.875 +lat_1=24.875 +lat_2=29.375 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/7775 ",
    cmt: "",
  },
  {
    label: "EPSG:7776 | WGS 84/Uttaranchal",
    crs: "WGS 84/Uttaranchal",
    value: "EPSG:7776",
    bnd: [
      [77.56, 28.71],
      [81.02, 31.48],
    ],
    def: "+proj=lcc +lat_0=30.0017132 +lon_0=79.375 +lat_1=29 +lat_2=31 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/7776 ",
    cmt: "",
  },
  {
    label: "EPSG:7777 | WGS 84/Andaman and Nicobar",
    crs: "WGS 84/Andaman and Nicobar",
    value: "EPSG:7777",
    bnd: [
      [92.15, 6.7],
      [94.33, 13.73],
    ],
    def: "+proj=tmerc +lat_0=10.25 +lon_0=93.25 +k=0.9999428 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/7777 ",
    cmt: "",
  },
  {
    label: "EPSG:7778 | WGS 84/Chhattisgarh",
    crs: "WGS 84/Chhattisgarh",
    value: "EPSG:7778",
    bnd: [
      [80.23, 17.78],
      [84.39, 24.11],
    ],
    def: "+proj=tmerc +lat_0=21 +lon_0=82.25 +k=0.9998332 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/7778 ",
    cmt: "",
  },
  {
    label: "EPSG:7779 | WGS 84/Goa",
    crs: "WGS 84/Goa",
    value: "EPSG:7779",
    bnd: [
      [73.61, 14.86],
      [74.35, 15.8],
    ],
    def: "+proj=tmerc +lat_0=15.375 +lon_0=74 +k=0.9999913 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/7779 ",
    cmt: "",
  },
  {
    label: "EPSG:7780 | WGS 84/Karnataka",
    crs: "WGS 84/Karnataka",
    value: "EPSG:7780",
    bnd: [
      [74, 11.57],
      [78.58, 18.46],
    ],
    def: "+proj=tmerc +lat_0=15.125 +lon_0=76.375 +k=0.9998012 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/7780 ",
    cmt: "",
  },
  {
    label: "EPSG:7781 | WGS 84/Kerala",
    crs: "WGS 84/Kerala",
    value: "EPSG:7781",
    bnd: [
      [74.81, 8.25],
      [77.4, 12.8],
    ],
    def: "+proj=tmerc +lat_0=10.5 +lon_0=76 +k=0.9999177 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/7781 ",
    cmt: "",
  },
  {
    label: "EPSG:7782 | WGS 84/Lakshadweep",
    crs: "WGS 84/Lakshadweep",
    value: "EPSG:7782",
    bnd: [
      [72.04, 8.21],
      [73.76, 11.76],
    ],
    def: "+proj=tmerc +lat_0=10 +lon_0=73.125 +k=0.9999536 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/7782 ",
    cmt: "",
  },
  {
    label: "EPSG:7783 | WGS 84/Mizoram",
    crs: "WGS 84/Mizoram",
    value: "EPSG:7783",
    bnd: [
      [92.25, 21.94],
      [93.45, 24.53],
    ],
    def: "+proj=tmerc +lat_0=23.125 +lon_0=92.75 +k=0.9999821 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/7783 ",
    cmt: "",
  },
  {
    label: "EPSG:7784 | WGS 84/Sikkim",
    crs: "WGS 84/Sikkim",
    value: "EPSG:7784",
    bnd: [
      [88.01, 27.08],
      [88.92, 28.14],
    ],
    def: "+proj=tmerc +lat_0=27.625 +lon_0=88.5 +k=0.9999926 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/7784 ",
    cmt: "",
  },
  {
    label: "EPSG:7785 | WGS 84/Tamil Nadu",
    crs: "WGS 84/Tamil Nadu",
    value: "EPSG:7785",
    bnd: [
      [76.22, 8.02],
      [80.4, 13.59],
    ],
    def: "+proj=tmerc +lat_0=10.875 +lon_0=78.375 +k=0.9997942 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/7785 ",
    cmt: "",
  },
  {
    label: "EPSG:7786 | WGS 84/Tripura",
    crs: "WGS 84/Tripura",
    value: "EPSG:7786",
    bnd: [
      [91.15, 22.94],
      [92.34, 24.54],
    ],
    def: "+proj=tmerc +lat_0=23.75 +lon_0=91.75 +k=0.9999822 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/7786 ",
    cmt: "",
  },
  {
    label: "EPSG:7787 | WGS 84/West Bengal",
    crs: "WGS 84/West Bengal",
    value: "EPSG:7787",
    bnd: [
      [85.82, 21.49],
      [89.88, 27.23],
    ],
    def: "+proj=tmerc +lat_0=24.375 +lon_0=87.875 +k=0.9998584 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/7787 ",
    cmt: "",
  },
];

const australiaOceania = [
  {
    label: "EPSG:2133 | NZGD2000/UTM zone 58S",
    crs: "NZGD2000/UTM zone 58S",
    value: "EPSG:2133",
    bnd: [
      [162, -55.95],
      [168, -25.88],
    ],
    def: "+proj=utm +zone=58 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/2133",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:2134 | NZGD2000/UTM zone 59S",
    crs: "NZGD2000/UTM zone 59S",
    value: "EPSG:2134",
    bnd: [
      [168, -55.95],
      [174, -25.88],
    ],
    def: "+proj=utm +zone=59 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/2134",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:2135 | NZGD2000/UTM zone 60S",
    crs: "NZGD2000/UTM zone 60S",
    value: "EPSG:2135",
    bnd: [
      [174, -55.95],
      [180, -25.88],
    ],
    def: "+proj=utm +zone=60 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/2135",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:5700 | NZGD2000/UTM zone 1S",
    crs: "NZGD2000/UTM zone 1S",
    value: "EPSG:5700",
    bnd: [
      [-180, -55.95],
      [-174, -25.88],
    ],
    def: "+proj=utm +zone=1 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/5700",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:7846 | GDA2020 / MAG 46",
    crs: "GDA2020 / MAG 46",
    value: "EPSG:7846",
    bnd: [
      [93.41, -60.55],
      [96, -8.47],
    ],
    def: "+proj=utm +zone=46 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/7846",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:7847 | GDA2020 / MAG 47",
    crs: "GDA2020 / MAG 47",
    value: "EPSG:7847",
    bnd: [
      [96, -60.55],
      [102, -8.47],
    ],
    def: "+proj=utm +zone=47 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/7847",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:7848 | GDA2020 / MAG 48",
    crs: "GDA2020 / MAG 48",
    value: "EPSG:7848",
    bnd: [
      [102, -60.55],
      [108, -8.47],
    ],
    def: "+proj=utm +zone=48 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/7848",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:7849 | GDA2020 / MAG 49",
    crs: "GDA2020 / MAG 49",
    value: "EPSG:7849",
    bnd: [
      [108, -60.55],
      [114, -8.47],
    ],
    def: "+proj=utm +zone=49 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/7849",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:7850 | GDA2020 / MAG 50",
    crs: "GDA2020 / MAG 50",
    value: "EPSG:7850",
    bnd: [
      [114, -60.55],
      [120, -8.47],
    ],
    def: "+proj=utm +zone=50 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/7850",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:7851 | GDA2020 / MAG 51",
    crs: "GDA2020 / MAG 51",
    value: "EPSG:7851",
    bnd: [
      [120, -60.55],
      [126, -8.47],
    ],
    def: "+proj=utm +zone=51 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/7851",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:7852 | GDA2020 / MAG 52",
    crs: "GDA2020 / MAG 52",
    value: "EPSG:7852",
    bnd: [
      [126, -60.55],
      [132, -8.47],
    ],
    def: "+proj=utm +zone=52 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/7852",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:7853 | GDA2020 / MAG 53",
    crs: "GDA2020 / MAG 53",
    value: "EPSG:7853",
    bnd: [
      [132, -60.55],
      [138, -8.47],
    ],
    def: "+proj=utm +zone=53 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/7853",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:7854 | GDA2020 / MAG 54",
    crs: "GDA2020 / MAG 54",
    value: "EPSG:7854",
    bnd: [
      [138, -60.55],
      [144, -8.47],
    ],
    def: "+proj=utm +zone=54 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/7854",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:7855 | GDA2020 / MAG 55",
    crs: "GDA2020 / MAG 55",
    value: "EPSG:7855",
    bnd: [
      [144, -60.55],
      [150, -8.47],
    ],
    def: "+proj=utm +zone=55 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/7855",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:7856 | GDA2020 / MAG 56",
    crs: "GDA2020 / MAG 56",
    value: "EPSG:7856",
    bnd: [
      [150, -60.55],
      [156, -8.47],
    ],
    def: "+proj=utm +zone=56 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/7856",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:7857 | GDA2020 / MAG 57",
    crs: "GDA2020 / MAG 57",
    value: "EPSG:7857",
    bnd: [
      [156, -60.55],
      [162, -8.47],
    ],
    def: "+proj=utm +zone=57 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/7857",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:7858 | GDA2020 / MAG 58",
    crs: "GDA2020 / MAG 58",
    value: "EPSG:7858",
    bnd: [
      [162, -60.55],
      [168, -8.47],
    ],
    def: "+proj=utm +zone=58 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/7858",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:7859 | GDA2020 / MAG 59",
    crs: "GDA2020 / MAG 59",
    value: "EPSG:7859",
    bnd: [
      [168, -60.55],
      [173.34, -8.47],
    ],
    def: "+proj=utm +zone=59 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/7859",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
];

const europeOptions = [
  {
    label: "EPSG:2056 | CH1903+ / LV95",
    crs: "CH1903+ / LV95",
    value: "EPSG:2056",
    bnd: [
      [5.96, 45.82],
      [10.49, 47.81],
    ],
    def: "+proj=somerc +lat_0=46.9524055555556 +lon_0=7.43958333333333 +k_0=1 +x_0=2600000 +y_0=1200000 +ellps=bessel +towgs84=674.374,15.056,405.346,0,0,0,0 +units=m +no_defs",
    src: "https://epsg.io/2056",
    cmt: "",
  },
  {
    label: "EPSG:2169_LUREF Luxembourg TM",
    crs: "LUREF / Luxembourg TM",
    value: "EPSG:2169",
    bnd: [
      [5.73, 49.44],
      [6.53, 50.19],
    ],
    def: "+proj=tmerc +lat_0=49.8333333333333 +lon_0=6.16666666666667 +k=1 +x_0=80000 +y_0=100000 +ellps=intl +towgs84=-265.8867,76.9851,20.2667,0.33746,3.09264,-2.53861,0.4598 +units=m +no_defs +type=crs",
    src: "https://epsg.io/2169",
    cmt: "",
  },
  {
    label: "EPSG:21781 | CH1903 / LV03",
    crs: "CH1903 / LV03",
    value: "EPSG:21781",
    bnd: [
      [5.96, 45.82],
      [10.49, 47.81],
    ],
    def: "+proj=somerc +lat_0=46.9524055555556 +lon_0=7.43958333333333 +k_0=1 +x_0=600000 +y_0=200000 +ellps=bessel +towgs84=674.374,15.056,405.346,0,0,0,0 +units=m +no_defs",
    src: "https://epsg.io/21781",
    cmt: "replaced by 2056 ",
  },
  {
    label: "EPSG:25828 | ETRS89 / UTM zone 28N",
    crs: "ETRS89 / UTM zone 28N",
    value: "EPSG:25828",
    bnd: [
      [-16.1, 32.88],
      [-12, 84.73],
    ],
    def: "+proj=utm +zone=28 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/25828",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:25829 | ETRS89 / UTM zone 29N",
    crs: "ETRS89 / UTM zone 29N",
    value: "EPSG:25829",
    bnd: [
      [-12, 32.88],
      [-6, 84.73],
    ],
    def: "+proj=utm +zone=29 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/25829",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:25830 | ETRS89 / UTM zone 30N",
    crs: "ETRS89 / UTM zone 30N",
    value: "EPSG:25830",
    bnd: [
      [-6, 32.88],
      [0, 84.73],
    ],
    def: "+proj=utm +zone=30 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/25830",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:25831 | ETRS89 / UTM zone 31N",
    crs: "ETRS89 / UTM zone 31N",
    value: "EPSG:25831",
    bnd: [
      [0, 32.88],
      [6, 84.73],
    ],
    def: "+proj=utm +zone=31 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/25831",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:25832 | ETRS89 / UTM zone 32N",
    crs: "ETRS89 / UTM zone 32N",
    value: "EPSG:25832",
    bnd: [
      [6, 32.88],
      [12, 84.73],
    ],
    def: "+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/25832",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:25833 | ETRS89 / UTM zone 33N",
    crs: "ETRS89 / UTM zone 33N",
    value: "EPSG:25833",
    bnd: [
      [12, 32.88],
      [18, 84.73],
    ],
    def: "+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/25833",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:25834 | ETRS89 / UTM zone 34N",
    crs: "ETRS89 / UTM zone 34N",
    value: "EPSG:25834",
    bnd: [
      [18, 32.88],
      [24, 84.73],
    ],
    def: "+proj=utm +zone=34 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/25834",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:25835 | ETRS89 / UTM zone 35N",
    crs: "ETRS89 / UTM zone 35N",
    value: "EPSG:25835",
    bnd: [
      [24, 32.88],
      [30, 84.73],
    ],
    def: "+proj=utm +zone=35 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/25835",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:25836 | ETRS89 / UTM zone 36N",
    crs: "ETRS89 / UTM zone 36N",
    value: "EPSG:25836",
    bnd: [
      [30, 32.88],
      [36, 84.73],
    ],
    def: "+proj=utm +zone=36 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/25836",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:25837 | ETRS89 / UTM zone 37N",
    crs: "ETRS89 / UTM zone 37N",
    value: "EPSG:25837",
    bnd: [
      [36, 32.88],
      [42, 84.73],
    ],
    def: "+proj=utm +zone=37 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/25837",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:27700 | OSGB36 / British National Grid",
    crs: "OSGB36 / British National Grid",
    value: "EPSG:27700",
    bnd: [
      [-9, 49.75],
      [2.01, 61.01],
    ],
    def: "+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +nadgrids=OSTN15_NTv2_OSGBtoETRS.gsb +units=m +no_defs +type=crs",
    src: "https://epsg.io/27700",
    cmt: "",
  },
];

const northAmericaOptions = [
  {
    label: "EPSG:3706 | NAD83(NSRS2007) / UTM zone 59N",
    crs: "NAD83(NSRS2007) / UTM zone 59N",
    value: "EPSG:3706",
    bnd: [
      [167.65, 14.92],
      [174, 74.71],
    ],
    def: "+proj=utm +zone=59 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/3706",
    cmt: "accuracy better than 1m replaces NAD83 ",
  },
  {
    label: "EPSG:3707 | NAD83(NSRS2007) / UTM zone 60N",
    crs: "NAD83(NSRS2007) / UTM zone 60N",
    value: "EPSG:3707",
    bnd: [
      [174, 14.92],
      [180, 74.71],
    ],
    def: "+proj=utm +zone=60 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/3707",
    cmt: "accuracy better than 1m replaces NAD83 ",
  },
  {
    label: "EPSG:3708 | NAD83(NSRS2007) / UTM zone 1N",
    crs: "NAD83(NSRS2007) / UTM zone 1N",
    value: "EPSG:3708",
    bnd: [
      [-180, 14.92],
      [-174, 74.71],
    ],
    def: "+proj=utm +zone=1 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/3708",
    cmt: "accuracy better than 1m replaces NAD84 ",
  },
  {
    label: "EPSG:3709 | NAD83(NSRS2007) / UTM zone 2N",
    crs: "NAD83(NSRS2007) / UTM zone 2N",
    value: "EPSG:3709",
    bnd: [
      [-174, 14.92],
      [-168, 74.71],
    ],
    def: "+proj=utm +zone=2 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/3709",
    cmt: "accuracy better than 1m replaces NAD85 ",
  },
  {
    label: "EPSG:3710 | NAD83(NSRS2007) / UTM zone 3N",
    crs: "NAD83(NSRS2007) / UTM zone 3N",
    value: "EPSG:3710",
    bnd: [
      [-168, 14.92],
      [-162, 74.71],
    ],
    def: "+proj=utm +zone=3 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/3710",
    cmt: "accuracy better than 1m replaces NAD86 ",
  },
  {
    label: "EPSG:3711 | NAD83(NSRS2007) / UTM zone 4N",
    crs: "NAD83(NSRS2007) / UTM zone 4N",
    value: "EPSG:3711",
    bnd: [
      [-162, 14.92],
      [-156, 74.71],
    ],
    def: "+proj=utm +zone=4 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/3711",
    cmt: "accuracy better than 1m replaces NAD87 ",
  },
  {
    label: "EPSG:3712 | NAD83(NSRS2007) / UTM zone 5N",
    crs: "NAD83(NSRS2007) / UTM zone 5N",
    value: "EPSG:3712",
    bnd: [
      [-156, 14.92],
      [-150, 74.71],
    ],
    def: "+proj=utm +zone=5 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/3712",
    cmt: "accuracy better than 1m replaces NAD88 ",
  },
  {
    label: "EPSG:3713 | NAD83(NSRS2007) / UTM zone 6N",
    crs: "NAD83(NSRS2007) / UTM zone 6N",
    value: "EPSG:3713",
    bnd: [
      [-150, 14.92],
      [-144, 74.71],
    ],
    def: "+proj=utm +zone=6 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/3713",
    cmt: "accuracy better than 1m replaces NAD89 ",
  },
  {
    label: "EPSG:3714 | NAD83(NSRS2007) / UTM zone 7N",
    crs: "NAD83(NSRS2007) / UTM zone 7N",
    value: "EPSG:3714",
    bnd: [
      [-144, 14.92],
      [-138, 74.71],
    ],
    def: "+proj=utm +zone=7 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/3714",
    cmt: "accuracy better than 1m replaces NAD90 ",
  },
  {
    label: "EPSG:3715 | NAD83(NSRS2007) / UTM zone 8N",
    crs: "NAD83(NSRS2007) / UTM zone 8N",
    value: "EPSG:3715",
    bnd: [
      [-138, 14.92],
      [-132, 74.71],
    ],
    def: "+proj=utm +zone=8 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/3715",
    cmt: "accuracy better than 1m replaces NAD91 ",
  },
  {
    label: "EPSG:3716 | NAD83(NSRS2007) / UTM zone 9N",
    crs: "NAD83(NSRS2007) / UTM zone 9N",
    value: "EPSG:3716",
    bnd: [
      [-132, 14.92],
      [-126, 74.71],
    ],
    def: "+proj=utm +zone=9 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/3716",
    cmt: "accuracy better than 1m replaces NAD92 ",
  },
  {
    label: "EPSG:3717 | NAD83(NSRS2007) / UTM zone 10N",
    crs: "NAD83(NSRS2007) / UTM zone 10N",
    value: "EPSG:3717",
    bnd: [
      [-126, 14.92],
      [-120, 74.71],
    ],
    def: "+proj=utm +zone=10 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/3717",
    cmt: "accuracy better than 1m replaces NAD93 ",
  },
  {
    label: "EPSG:3718 | NAD83(NSRS2007) / UTM zone 11N",
    crs: "NAD83(NSRS2007) / UTM zone 11N",
    value: "EPSG:3718",
    bnd: [
      [-120, 14.92],
      [-114, 74.71],
    ],
    def: "+proj=utm +zone=11 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/3718",
    cmt: "accuracy better than 1m replaces NAD94 ",
  },
  {
    label: "EPSG:3719 | NAD83(NSRS2007) / UTM zone 12N",
    crs: "NAD83(NSRS2007) / UTM zone 12N",
    value: "EPSG:3719",
    bnd: [
      [-114, 14.92],
      [-108, 74.71],
    ],
    def: "+proj=utm +zone=12 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/3719",
    cmt: "accuracy better than 1m replaces NAD95 ",
  },
  {
    label: "EPSG:3720 | NAD83(NSRS2007) / UTM zone 13N",
    crs: "NAD83(NSRS2007) / UTM zone 13N",
    value: "EPSG:3720",
    bnd: [
      [-108, 14.92],
      [-102, 74.71],
    ],
    def: "+proj=utm +zone=13 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/3720",
    cmt: "accuracy better than 1m replaces NAD96 ",
  },
  {
    label: "EPSG:3721 | NAD83(NSRS2007) / UTM zone 14N",
    crs: "NAD83(NSRS2007) / UTM zone 14N",
    value: "EPSG:3721",
    bnd: [
      [-102, 14.92],
      [-96, 74.71],
    ],
    def: "+proj=utm +zone=14 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/3721",
    cmt: "accuracy better than 1m replaces NAD97 ",
  },
  {
    label: "EPSG:3722 | NAD83(NSRS2007) / UTM zone 15N",
    crs: "NAD83(NSRS2007) / UTM zone 15N",
    value: "EPSG:3722",
    bnd: [
      [-96, 14.92],
      [-90, 74.71],
    ],
    def: "+proj=utm +zone=15 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/3722",
    cmt: "accuracy better than 1m replaces NAD98 ",
  },
  {
    label: "EPSG:3723 | NAD83(NSRS2007) / UTM zone 16N",
    crs: "NAD83(NSRS2007) / UTM zone 16N",
    value: "EPSG:3723",
    bnd: [
      [-90, 14.92],
      [-84, 74.71],
    ],
    def: "+proj=utm +zone=16 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/3723",
    cmt: "accuracy better than 1m replaces NAD99 ",
  },
  {
    label: "EPSG:3724 | NAD83(NSRS2007) / UTM zone 17N",
    crs: "NAD83(NSRS2007) / UTM zone 17N",
    value: "EPSG:3724",
    bnd: [
      [-84, 14.92],
      [-78, 74.71],
    ],
    def: "+proj=utm +zone=17 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/3724",
    cmt: "accuracy better than 1m replaces NAD100 ",
  },
  {
    label: "EPSG:3725 | NAD83(NSRS2007) / UTM zone 18N",
    crs: "NAD83(NSRS2007) / UTM zone 18N",
    value: "EPSG:3725",
    bnd: [
      [-78, 14.92],
      [-72, 74.71],
    ],
    def: "+proj=utm +zone=18 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/3725",
    cmt: "accuracy better than 1m replaces NAD101 ",
  },
  {
    label: "EPSG:3726 | NAD83(NSRS2007) / UTM zone 19N",
    crs: "NAD83(NSRS2007) / UTM zone 19N",
    value: "EPSG:3726",
    bnd: [
      [-72, 14.92],
      [-66, 74.71],
    ],
    def: "+proj=utm +zone=19 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/3726",
    cmt: "accuracy better than 1m replaces NAD102 ",
  },
  {
    label: "EPSG:6330 | NAD83(2011) / UTM zone 1N",
    crs: "NAD83(2011) / UTM zone 1N",
    value: "EPSG:6330",
    bnd: [
      [-180, 14.92],
      [-174, 74.71],
    ],
    def: "+proj=utm +zone=1 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/6330",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:6331 | NAD83(2011) / UTM zone 2N",
    crs: "NAD83(2011) / UTM zone 2N",
    value: "EPSG:6331",
    bnd: [
      [-174, 14.92],
      [-168, 74.71],
    ],
    def: "+proj=utm +zone=2 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/6331",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:6332 | NAD83(2011) / UTM zone 3N",
    crs: "NAD83(2011) / UTM zone 3N",
    value: "EPSG:6332",
    bnd: [
      [-168, 14.92],
      [-162, 74.71],
    ],
    def: "+proj=utm +zone=3 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/6332",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:6333 | NAD83(2011) / UTM zone 4N",
    crs: "NAD83(2011) / UTM zone 4N",
    value: "EPSG:6333",
    bnd: [
      [-162, 14.92],
      [-156, 74.71],
    ],
    def: "+proj=utm +zone=4 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/6333",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:6334 | NAD83(2011) / UTM zone 5N",
    crs: "NAD83(2011) / UTM zone 5N",
    value: "EPSG:6334",
    bnd: [
      [-156, 14.92],
      [-150, 74.71],
    ],
    def: "+proj=utm +zone=5 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/6334",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:6335 | NAD83(2011) / UTM zone 6N",
    crs: "NAD83(2011) / UTM zone 6N",
    value: "EPSG:6335",
    bnd: [
      [-150, 14.92],
      [-144, 74.71],
    ],
    def: "+proj=utm +zone=6 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/6335",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:6336 | NAD83(2011) / UTM zone 7N",
    crs: "NAD83(2011) / UTM zone 7N",
    value: "EPSG:6336",
    bnd: [
      [-144, 14.92],
      [-138, 74.71],
    ],
    def: "+proj=utm +zone=7 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/6336",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:6337 | NAD83(2011) / UTM zone 8N",
    crs: "NAD83(2011) / UTM zone 8N",
    value: "EPSG:6337",
    bnd: [
      [-138, 14.92],
      [-132, 74.71],
    ],
    def: "+proj=utm +zone=8 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/6337",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:6338 | NAD83(2011) / UTM zone 9N",
    crs: "NAD83(2011) / UTM zone 9N",
    value: "EPSG:6338",
    bnd: [
      [-132, 14.92],
      [-126, 74.71],
    ],
    def: "+proj=utm +zone=9 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/6338",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:6339 | NAD83(2011) / UTM zone 10N",
    crs: "NAD83(2011) / UTM zone 10N",
    value: "EPSG:6339",
    bnd: [
      [-126, 14.92],
      [-120, 74.71],
    ],
    def: "+proj=utm +zone=10 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/6339",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:6340 | NAD83(2011) / UTM zone 11N",
    crs: "NAD83(2011) / UTM zone 11N",
    value: "EPSG:6340",
    bnd: [
      [-120, 14.92],
      [-114, 74.71],
    ],
    def: "+proj=utm +zone=11 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/6340",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:6341 | NAD83(2011) / UTM zone 12N",
    crs: "NAD83(2011) / UTM zone 12N",
    value: "EPSG:6341",
    bnd: [
      [-114, 14.92],
      [-108, 74.71],
    ],
    def: "+proj=utm +zone=12 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/6341",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:6342 | NAD83(2011) / UTM zone 13N",
    crs: "NAD83(2011) / UTM zone 13N",
    value: "EPSG:6342",
    bnd: [
      [-108, 14.92],
      [-102, 74.71],
    ],
    def: "+proj=utm +zone=13 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/6342",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:6343 | NAD83(2011) / UTM zone 14N",
    crs: "NAD83(2011) / UTM zone 14N",
    value: "EPSG:6343",
    bnd: [
      [-102, 14.92],
      [-96, 74.71],
    ],
    def: "+proj=utm +zone=14 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/6343",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:6344 | NAD83(2011) / UTM zone 15N",
    crs: "NAD83(2011) / UTM zone 15N",
    value: "EPSG:6344",
    bnd: [
      [-96, 14.92],
      [-90, 74.71],
    ],
    def: "+proj=utm +zone=15 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/6344",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:6345 | NAD83(2011) / UTM zone 16N",
    crs: "NAD83(2011) / UTM zone 16N",
    value: "EPSG:6345",
    bnd: [
      [-90, 14.92],
      [-84, 74.71],
    ],
    def: "+proj=utm +zone=16 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/6345",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:6346 | NAD83(2011) / UTM zone 17N",
    crs: "NAD83(2011) / UTM zone 17N",
    value: "EPSG:6346",
    bnd: [
      [-84, 14.92],
      [-78, 74.71],
    ],
    def: "+proj=utm +zone=17 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/6346",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:6347 | NAD83(2011) / UTM zone 18N",
    crs: "NAD83(2011) / UTM zone 18N",
    value: "EPSG:6347",
    bnd: [
      [-78, 14.92],
      [-72, 74.71],
    ],
    def: "+proj=utm +zone=18 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/6347",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:6348 | NAD83(2011) / UTM zone 19N",
    crs: "NAD83(2011) / UTM zone 19N",
    value: "EPSG:6348",
    bnd: [
      [-72, 14.92],
      [-66, 74.71],
    ],
    def: "+proj=utm +zone=19 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/6348",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:6328 | NAD83(2011) / UTM zone 59N",
    crs: "NAD83(2011) / UTM zone 59N",
    value: "EPSG:6328",
    bnd: [
      [167.65, 14.92],
      [174, 74.71],
    ],
    def: "+proj=utm +zone=59 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/6328",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:6329 | NAD83(2011) / UTM zone 60N",
    crs: "NAD83(2011) / UTM zone 60N",
    value: "EPSG:6329",
    bnd: [
      [174, 14.92],
      [180, 74.71],
    ],
    def: "+proj=utm +zone=60 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/6329",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:22707 | NAD83(CSRS)v7 / UTM zone 7N",
    crs: "NAD83(CSRS)v7 / UTM zone 7N",
    value: "EPSG:22707",
    bnd: [
      [-141.01, 52.05],
      [-138, 72.53],
    ],
    def: "+proj=utm +zone=7 +ellps=GRS80 +units=m +no_defs +type=crs",
    src: "https://epsg.io/22707",
    cmt: "",
  },
  {
    label: "EPSG:22708 | NAD83(CSRS)v7 / UTM zone 8N",
    crs: "NAD83(CSRS)v7 / UTM zone 8N",
    value: "EPSG:22708",
    bnd: [
      [-138, 48.06],
      [-132, 79.42],
    ],
    def: "+proj=utm +zone=8 +ellps=GRS80 +units=m +no_defs +type=crs",
    src: "https://epsg.io/22708",
    cmt: "",
  },
  {
    label: "EPSG:22709 | NAD83(CSRS)v7 / UTM zone 9N",
    crs: "NAD83(CSRS)v7 / UTM zone 9N",
    value: "EPSG:22709",
    bnd: [
      [-132, 46.52],
      [-126, 80.93],
    ],
    def: "+proj=utm +zone=9 +ellps=GRS80 +units=m +no_defs +type=crs",
    src: "https://epsg.io/22709",
    cmt: "",
  },
  {
    label: "EPSG:22710 | NAD83(CSRS)v7 / UTM zone 10N",
    crs: "NAD83(CSRS)v7 / UTM zone 10N",
    value: "EPSG:22710",
    bnd: [
      [-126, 48.13],
      [-120, 81.8],
    ],
    def: "+proj=utm +zone=10 +ellps=GRS80 +units=m +no_defs +type=crs",
    src: "https://epsg.io/22710",
    cmt: "",
  },
  {
    label: "EPSG:22711 | NAD83(CSRS)v7 / UTM zone 11N",
    crs: "NAD83(CSRS)v7 / UTM zone 11N",
    value: "EPSG:22711",
    bnd: [
      [-120, 48.99],
      [-114, 83.5],
    ],
    def: "+proj=utm +zone=11 +ellps=GRS80 +units=m +no_defs +type=crs",
    src: "https://epsg.io/22711",
    cmt: "",
  },
  {
    label: "EPSG:22712 | NAD83(CSRS)v7 / UTM zone 12N",
    crs: "NAD83(CSRS)v7 / UTM zone 12N",
    value: "EPSG:22712",
    bnd: [
      [-114, 48.99],
      [-108, 84],
    ],
    def: "+proj=utm +zone=12 +ellps=GRS80 +units=m +no_defs +type=crs",
    src: "https://epsg.io/22712",
    cmt: "",
  },
  {
    label: "EPSG:22713 | NAD83(CSRS)v7 / UTM zone 13N",
    crs: "NAD83(CSRS)v7 / UTM zone 13N",
    value: "EPSG:22713",
    bnd: [
      [-108, 48.99],
      [-102, 84],
    ],
    def: "+proj=utm +zone=13 +ellps=GRS80 +units=m +no_defs +type=crs",
    src: "https://epsg.io/22713",
    cmt: "",
  },
  {
    label: "EPSG:22714 | NAD83(CSRS)v7 / UTM zone 14N",
    crs: "NAD83(CSRS)v7 / UTM zone 14N",
    value: "EPSG:22714",
    bnd: [
      [-102, 48.99],
      [-96, 84],
    ],
    def: "+proj=utm +zone=14 +ellps=GRS80 +units=m +no_defs +type=crs",
    src: "https://epsg.io/22714",
    cmt: "",
  },
  {
    label: "EPSG:22715 | NAD83(CSRS)v7 / UTM zone 15N",
    crs: "NAD83(CSRS)v7 / UTM zone 15N",
    value: "EPSG:22715",
    bnd: [
      [-96, 48.03],
      [-90, 84],
    ],
    def: "+proj=utm +zone=15 +ellps=GRS80 +units=m +no_defs +type=crs",
    src: "https://epsg.io/22715",
    cmt: "",
  },
  {
    label: "EPSG:22716 | NAD83(CSRS)v7 / UTM zone 16N",
    crs: "NAD83(CSRS)v7 / UTM zone 16N",
    value: "EPSG:22716",
    bnd: [
      [-90, 46.11],
      [-84, 84],
    ],
    def: "+proj=utm +zone=16 +ellps=GRS80 +units=m +no_defs +type=crs",
    src: "https://epsg.io/22716",
    cmt: "",
  },
  {
    label: "EPSG:22717 | NAD83(CSRS)v7 / UTM zone 17N",
    crs: "NAD83(CSRS)v7 / UTM zone 17N",
    value: "EPSG:22717",
    bnd: [
      [-84, 41.67],
      [-78, 84],
    ],
    def: "+proj=utm +zone=17 +ellps=GRS80 +units=m +no_defs +type=crs",
    src: "https://epsg.io/22717",
    cmt: "",
  },
  {
    label: "EPSG:22718 | NAD83(CSRS)v7 / UTM zone 18N",
    crs: "NAD83(CSRS)v7 / UTM zone 18N",
    value: "EPSG:22718",
    bnd: [
      [-78, 43.63],
      [-72, 84],
    ],
    def: "+proj=utm +zone=18 +ellps=GRS80 +units=m +no_defs +type=crs",
    src: "https://epsg.io/22718",
    cmt: "",
  },
  {
    label: "EPSG:22719 | NAD83(CSRS)v7 / UTM zone 19N",
    crs: "NAD83(CSRS)v7 / UTM zone 19N",
    value: "EPSG:22719",
    bnd: [
      [-72, 40.8],
      [-66, 84],
    ],
    def: "+proj=utm +zone=19 +ellps=GRS80 +units=m +no_defs +type=crs",
    src: "https://epsg.io/22719",
    cmt: "",
  },
  {
    label: "EPSG:22720 | NAD83(CSRS)v7 / UTM zone 20N",
    crs: "NAD83(CSRS)v7 / UTM zone 20N",
    value: "EPSG:22720",
    bnd: [
      [-66, 40.04],
      [-60, 84],
    ],
    def: "+proj=utm +zone=20 +ellps=GRS80 +units=m +no_defs +type=crs",
    src: "https://epsg.io/22720",
    cmt: "",
  },
  {
    label: "EPSG:22721 | NAD83(CSRS)v7 / UTM zone 21N",
    crs: "NAD83(CSRS)v7 / UTM zone 21N",
    value: "EPSG:22721",
    bnd: [
      [-60, 38.56],
      [-54, 84],
    ],
    def: "+proj=utm +zone=21 +ellps=GRS80 +units=m +no_defs +type=crs",
    src: "https://epsg.io/22721",
    cmt: "",
  },
  {
    label: "EPSG:22722 | NAD83(CSRS)v7 / UTM zone 22N",
    crs: "NAD83(CSRS)v7 / UTM zone 22N",
    value: "EPSG:22722",
    bnd: [
      [-54, 39.5],
      [-47.99, 57.65],
    ],
    def: "+proj=utm +zone=22 +ellps=GRS80 +units=m +no_defs +type=crs",
    src: "https://epsg.io/22722",
    cmt: "",
  },
  {
    label: "EPSG:26901 | NAD83 / UTM zone 1N",
    crs: "NAD83 / UTM zone 1N",
    value: "EPSG:26901",
    bnd: [
      [-180, 51.3],
      [-174, 54.34],
    ],
    def: "+proj=utm +zone=1 +ellps=GRS80 +towgs84=-2,0,4,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/26901",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:26902 | NAD83 / UTM zone 2N",
    crs: "NAD83 / UTM zone 2N",
    value: "EPSG:26902",
    bnd: [
      [-174, 23.81],
      [-168, 86.46],
    ],
    def: "+proj=utm +zone=2 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/26902",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:26903 | NAD83 / UTM zone 3N",
    crs: "NAD83 / UTM zone 3N",
    value: "EPSG:26903",
    bnd: [
      [-168, 23.81],
      [-162, 86.46],
    ],
    def: "+proj=utm +zone=3 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/26903",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:26904 | NAD83 / UTM zone 4N",
    crs: "NAD83 / UTM zone 4N",
    value: "EPSG:26904",
    bnd: [
      [-162, 23.81],
      [-156, 86.46],
    ],
    def: "+proj=utm +zone=4 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/26904",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:26905 | NAD83 / UTM zone 5N",
    crs: "NAD83 / UTM zone 5N",
    value: "EPSG:26905",
    bnd: [
      [-156, 23.81],
      [-150, 86.46],
    ],
    def: "+proj=utm +zone=5 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/26905",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:26906 | NAD83 / UTM zone 6N",
    crs: "NAD83 / UTM zone 6N",
    value: "EPSG:26906",
    bnd: [
      [-150, 23.81],
      [-144, 86.46],
    ],
    def: "+proj=utm +zone=6 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/26906",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:26907 | NAD83 / UTM zone 7N",
    crs: "NAD83 / UTM zone 7N",
    value: "EPSG:26907",
    bnd: [
      [-144, 23.81],
      [-138, 86.46],
    ],
    def: "+proj=utm +zone=7 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/26907",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:26908 | NAD83 / UTM zone 8N",
    crs: "NAD83 / UTM zone 8N",
    value: "EPSG:26908",
    bnd: [
      [-138, 23.81],
      [-132, 86.46],
    ],
    def: "+proj=utm +zone=8 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/26908",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:26909 | NAD83 / UTM zone 9N",
    crs: "NAD83 / UTM zone 9N",
    value: "EPSG:26909",
    bnd: [
      [-132, 23.81],
      [-126, 86.46],
    ],
    def: "+proj=utm +zone=9 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/26909",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:26910 | NAD83 / UTM zone 10N",
    crs: "NAD83 / UTM zone 10N",
    value: "EPSG:26910",
    bnd: [
      [-126, 23.81],
      [-120, 86.46],
    ],
    def: "+proj=utm +zone=10 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/26910",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:26911 | NAD83 / UTM zone 11N",
    crs: "NAD83 / UTM zone 11N",
    value: "EPSG:26911",
    bnd: [
      [-120, 23.81],
      [-114, 86.46],
    ],
    def: "+proj=utm +zone=11 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/26911",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:26912 | NAD83 / UTM zone 12N",
    crs: "NAD83 / UTM zone 12N",
    value: "EPSG:26912",
    bnd: [
      [-114, 23.81],
      [-108, 86.46],
    ],
    def: "+proj=utm +zone=12 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/26912",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:26913 | NAD83 / UTM zone 13N",
    crs: "NAD83 / UTM zone 13N",
    value: "EPSG:26913",
    bnd: [
      [-108, 23.81],
      [-102, 86.46],
    ],
    def: "+proj=utm +zone=13 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/26913",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:26914 | NAD83 / UTM zone 14N",
    crs: "NAD83 / UTM zone 14N",
    value: "EPSG:26914",
    bnd: [
      [-102, 23.81],
      [-96, 86.46],
    ],
    def: "+proj=utm +zone=14 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/26914",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:26915 | NAD83 / UTM zone 15N",
    crs: "NAD83 / UTM zone 15N",
    value: "EPSG:26915",
    bnd: [
      [-96, 23.81],
      [-90, 86.46],
    ],
    def: "+proj=utm +zone=15 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/26915",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:26916 | NAD83 / UTM zone 16N",
    crs: "NAD83 / UTM zone 16N",
    value: "EPSG:26916",
    bnd: [
      [-90, 23.81],
      [-84, 86.46],
    ],
    def: "+proj=utm +zone=16 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/26916",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:26917 | NAD83 / UTM zone 17N",
    crs: "NAD83 / UTM zone 17N",
    value: "EPSG:26917",
    bnd: [
      [-84, 23.81],
      [-78, 86.46],
    ],
    def: "+proj=utm +zone=17 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/26917",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:26918 | NAD83 / UTM zone 18N",
    crs: "NAD83 / UTM zone 18N",
    value: "EPSG:26918",
    bnd: [
      [-78, 23.81],
      [-72, 86.46],
    ],
    def: "+proj=utm +zone=18 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/26918",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:26919 | NAD83 / UTM zone 19N",
    crs: "NAD83 / UTM zone 19N",
    value: "EPSG:26919",
    bnd: [
      [-72, 23.81],
      [-66, 86.46],
    ],
    def: "+proj=utm +zone=19 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/26919",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:26920 | NAD83 / UTM zone 20N",
    crs: "NAD83 / UTM zone 20N",
    value: "EPSG:26920",
    bnd: [
      [-66, 23.81],
      [-60, 86.46],
    ],
    def: "+proj=utm +zone=20 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/26920",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:26921 | NAD83 / UTM zone 21N",
    crs: "NAD83 / UTM zone 21N",
    value: "EPSG:26921",
    bnd: [
      [-60, 23.81],
      [-54, 86.46],
    ],
    def: "+proj=utm +zone=21 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/26921",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:26922 | NAD83 / UTM zone 22N",
    crs: "NAD83 / UTM zone 22N",
    value: "EPSG:26922",
    bnd: [
      [-54, 23.81],
      [-48, 86.46],
    ],
    def: "+proj=utm +zone=22 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/26922",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:26923 | NAD83 / UTM zone 23N",
    crs: "NAD83 / UTM zone 23N",
    value: "EPSG:26923",
    bnd: [
      [-48, 23.81],
      [-42, 86.46],
    ],
    def: "+proj=utm +zone=23 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/26923",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:9712 | NAD83 / UTM zone 24N",
    crs: "NAD83 / UTM zone 24N",
    value: "EPSG:9712",
    bnd: [
      [-42, 23.81],
      [-36, 86.46],
    ],
    def: "+proj=utm +zone=24 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/9712",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:3372 | NAD83 / UTM zone 59N",
    crs: "NAD83 / UTM zone 59N",
    value: "EPSG:3372",
    bnd: [
      [168, 51.3],
      [174, 54.34],
    ],
    def: "+proj=utm +zone=59 +ellps=GRS80 +towgs84=-2,0,4,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/3372",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:3373 | NAD83 / UTM zone 60N",
    crs: "NAD83 / UTM zone 60N",
    value: "EPSG:3373",
    bnd: [
      [174, 51.3],
      [180, 54.34],
    ],
    def: "+proj=utm +zone=60 +ellps=GRS80 +towgs84=-2,0,4,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/3373",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
];

const southMiddleAmericaOptions = [
  {
    label: "EPSG:5880 | SIRGAS 2000/Brazil Polyconic",
    crs: "SIRGAS 2000/Brazil Polyconic",
    value: "EPSG:5880",
    bnd: [
      [-122.19, -59.87],
      [-25.28, 32.72],
    ],
    def: "+proj=poly +lat_0=0 +lon_0=-54 +x_0=5000000 +y_0=10000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/5880",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io GUI-Check Bounds also checked with QGIS 3.22.10",
  },
  {
    label: "EPSG:5641 | SIRGAS 2000/Brazil Mercator",
    crs: "SIRGAS 2000/Brazil Mercator",
    value: "EPSG:5641",
    bnd: [
      [-51.64, -5.74],
      [-32.43, 7.04],
    ],
    def: "+proj=merc +lat_ts=-2 +lon_0=-43 +x_0=5000000 +y_0=10000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/5641",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io GUI-Check Bounds also checked with QGIS 3.22.10",
  },
  {
    label: "EPSG:31965 | SIRGAS 2000/UTM zone 11N",
    crs: "SIRGAS 2000/UTM zone 11N",
    value: "EPSG:31965",
    bnd: [
      [-120, 0],
      [-114, 32.72],
    ],
    def: "+proj=utm +zone=11 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/31965",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:31966 | SIRGAS 2000/UTM zone 12N",
    crs: "SIRGAS 2000/UTM zone 12N",
    value: "EPSG:31966",
    bnd: [
      [-114, 0],
      [-108, 32.72],
    ],
    def: "+proj=utm +zone=12 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/31966",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:31967 | SIRGAS 2000/UTM zone 13N",
    crs: "SIRGAS 2000/UTM zone 13N",
    value: "EPSG:31967",
    bnd: [
      [-108, 0],
      [-102, 32.72],
    ],
    def: "+proj=utm +zone=13 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/31967",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:31968 | SIRGAS 2000/UTM zone 14N",
    crs: "SIRGAS 2000/UTM zone 14N",
    value: "EPSG:31968",
    bnd: [
      [-102, 0],
      [-96, 32.72],
    ],
    def: "+proj=utm +zone=14 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/31968",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:31969 | SIRGAS 2000/UTM zone 15N",
    crs: "SIRGAS 2000/UTM zone 15N",
    value: "EPSG:31969",
    bnd: [
      [-96, 0],
      [-90, 32.72],
    ],
    def: "+proj=utm +zone=15 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/31969",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:31970 | SIRGAS 2000/UTM zone 16N",
    crs: "SIRGAS 2000/UTM zone 16N",
    value: "EPSG:31970",
    bnd: [
      [-90, 0],
      [-84, 32.72],
    ],
    def: "+proj=utm +zone=16 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/31970",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:31971 | SIRGAS 2000/UTM zone 17N",
    crs: "SIRGAS 2000/UTM zone 17N",
    value: "EPSG:31971",
    bnd: [
      [-84, 0],
      [-78, 32.72],
    ],
    def: "+proj=utm +zone=17 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/31971",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:31972 | SIRGAS 2000/UTM zone 18N",
    crs: "SIRGAS 2000/UTM zone 18N",
    value: "EPSG:31972",
    bnd: [
      [-78, 0],
      [-72, 32.72],
    ],
    def: "+proj=utm +zone=18 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/31972",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:31973 | SIRGAS 2000/UTM zone 19N",
    crs: "SIRGAS 2000/UTM zone 19N",
    value: "EPSG:31973",
    bnd: [
      [-72, 0],
      [-66, 32.72],
    ],
    def: "+proj=utm +zone=19 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/31973",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:31974 | SIRGAS 2000/UTM zone 20N",
    crs: "SIRGAS 2000/UTM zone 20N",
    value: "EPSG:31974",
    bnd: [
      [-66, 0],
      [-60, 32.72],
    ],
    def: "+proj=utm +zone=20 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/31974",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:31975 | SIRGAS 2000/UTM zone 21N",
    crs: "SIRGAS 2000/UTM zone 21N",
    value: "EPSG:31975",
    bnd: [
      [-60, 0],
      [-54, 32.72],
    ],
    def: "+proj=utm +zone=21 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/31975",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:31976 | SIRGAS 2000/UTM zone 22N",
    crs: "SIRGAS 2000/UTM zone 22N",
    value: "EPSG:31976",
    bnd: [
      [-54, 0],
      [-48, 32.72],
    ],
    def: "+proj=utm +zone=22 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/31976",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:6210 | SIRGAS 2000/UTM zone 23N",
    crs: "SIRGAS 2000/UTM zone 23N",
    value: "EPSG:6210",
    bnd: [
      [-48, 0],
      [-42, 32.72],
    ],
    def: "+proj=utm +zone=23 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/6210",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:6211 | SIRGAS 2000/UTM zone 24N",
    crs: "SIRGAS 2000/UTM zone 24N",
    value: "EPSG:6211",
    bnd: [
      [-42, 0],
      [-36, 32.72],
    ],
    def: "+proj=utm +zone=24 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/6211",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:31977 | SIRGAS 2000/UTM zone 17S",
    crs: "SIRGAS 2000/UTM zone 17S",
    value: "EPSG:31977",
    bnd: [
      [-84, -59.87],
      [-78, 0],
    ],
    def: "+proj=utm +zone=17 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/31977",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:31978 | SIRGAS 2000/UTM zone 18S",
    crs: "SIRGAS 2000/UTM zone 18S",
    value: "EPSG:31978",
    bnd: [
      [-78, -59.87],
      [-72, 0],
    ],
    def: "+proj=utm +zone=18 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/31978",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:31979 | SIRGAS 2000/UTM zone 19S",
    crs: "SIRGAS 2000/UTM zone 19S",
    value: "EPSG:31979",
    bnd: [
      [-72, -59.87],
      [-66, 0],
    ],
    def: "+proj=utm +zone=19 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/31979",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:31980 | SIRGAS 2000/UTM zone 20S",
    crs: "SIRGAS 2000/UTM zone 20S",
    value: "EPSG:31980",
    bnd: [
      [-66, -59.87],
      [-60, 0],
    ],
    def: "+proj=utm +zone=20 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/31980",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:31981 | SIRGAS 2000/UTM zone 21S",
    crs: "SIRGAS 2000/UTM zone 21S",
    value: "EPSG:31981",
    bnd: [
      [-60, -59.87],
      [-54, 0],
    ],
    def: "+proj=utm +zone=21 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/31981",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:31982 | SIRGAS 2000/UTM zone 22S",
    crs: "SIRGAS 2000/UTM zone 22S",
    value: "EPSG:31982",
    bnd: [
      [-54, -59.87],
      [-48, 0],
    ],
    def: "+proj=utm +zone=22 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/31982",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:31983 | SIRGAS 2000/UTM zone 23S",
    crs: "SIRGAS 2000/UTM zone 23S",
    value: "EPSG:31983",
    bnd: [
      [-48, -59.87],
      [-42, 0],
    ],
    def: "+proj=utm +zone=23 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/31983",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:31984 | SIRGAS 2000/UTM zone 24S",
    crs: "SIRGAS 2000/UTM zone 24S",
    value: "EPSG:31984",
    bnd: [
      [-42, -59.87],
      [-36, 0],
    ],
    def: "+proj=utm +zone=24 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/31984",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:31985 | SIRGAS 2000/UTM zone 25S",
    crs: "SIRGAS 2000/UTM zone 25S",
    value: "EPSG:31985",
    bnd: [
      [-36, -59.87],
      [-30, 0],
    ],
    def: "+proj=utm +zone=25 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/31985",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  {
    label: "EPSG:5396 | SIRGAS 2000/UTM zone 26S",
    crs: "SIRGAS 2000/UTM zone 26S",
    value: "EPSG:5396",
    bnd: [
      [-30, -59.87],
      [-25.28, 0],
    ],
    def: "+proj=utm +zone=26 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/5396",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
];
const worldwideOptions = [
  {
    label: "EPSG:32601 | WGS84 / UTM Zone 1N",
    crs: "WGS84 / UTM Zone 1N",
    value: "EPSG:32601",
    bnd: [
      [-180, 0],
      [-174, 84],
    ],
    def: "+proj=utm +zone=1 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32601",
    cmt: "",
  },
  {
    label: "EPSG:32602 | WGS84 / UTM Zone 2N",
    crs: "WGS84 / UTM Zone 2N",
    value: "EPSG:32602",
    bnd: [
      [-174, 0],
      [-168, 84],
    ],
    def: "+proj=utm +zone=2 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32602",
    cmt: "",
  },
  {
    label: "EPSG:32603 | WGS84 / UTM Zone 3N",
    crs: "WGS84 / UTM Zone 3N",
    value: "EPSG:32603",
    bnd: [
      [-168, 0],
      [-162, 84],
    ],
    def: "+proj=utm +zone=3 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32603",
    cmt: "",
  },
  {
    label: "EPSG:32604 | WGS84 / UTM Zone 4N",
    crs: "WGS84 / UTM Zone 4N",
    value: "EPSG:32604",
    bnd: [
      [-162, 0],
      [-156, 84],
    ],
    def: "+proj=utm +zone=4 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32604",
    cmt: "",
  },
  {
    label: "EPSG:32605 | WGS84 / UTM Zone 5N",
    crs: "WGS84 / UTM Zone 5N",
    value: "EPSG:32605",
    bnd: [
      [-156, 0],
      [-150, 84],
    ],
    def: "+proj=utm +zone=5 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32605",
    cmt: "",
  },
  {
    label: "EPSG:32606 | WGS84 / UTM Zone 6N",
    crs: "WGS84 / UTM Zone 6N",
    value: "EPSG:32606",
    bnd: [
      [-150, 0],
      [-144, 84],
    ],
    def: "+proj=utm +zone=6 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32606",
    cmt: "",
  },
  {
    label: "EPSG:32607 | WGS84 / UTM Zone 7N",
    crs: "WGS84 / UTM Zone 7N",
    value: "EPSG:32607",
    bnd: [
      [-144, 0],
      [-138, 84],
    ],
    def: "+proj=utm +zone=7 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32607",
    cmt: "",
  },
  {
    label: "EPSG:32608 | WGS84 / UTM Zone 8N",
    crs: "WGS84 / UTM Zone 8N",
    value: "EPSG:32608",
    bnd: [
      [-138, 0],
      [-132, 84],
    ],
    def: "+proj=utm +zone=8 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32608",
    cmt: "",
  },
  {
    label: "EPSG:32609 | WGS84 / UTM Zone 9N",
    crs: "WGS84 / UTM Zone 9N",
    value: "EPSG:32609",
    bnd: [
      [-132, 0],
      [-126, 84],
    ],
    def: "+proj=utm +zone=9 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32609",
    cmt: "",
  },
  {
    label: "EPSG:32610 | WGS84 / UTM Zone 10N",
    crs: "WGS84 / UTM Zone 10N",
    value: "EPSG:32610",
    bnd: [
      [-126, 0],
      [-120, 84],
    ],
    def: "+proj=utm +zone=10 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32610",
    cmt: "",
  },
  {
    label: "EPSG:32611 | WGS84 / UTM Zone 11N",
    crs: "WGS84 / UTM Zone 11N",
    value: "EPSG:32611",
    bnd: [
      [-120, 0],
      [-114, 84],
    ],
    def: "+proj=utm +zone=11 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32611",
    cmt: "",
  },
  {
    label: "EPSG:32612 | WGS84 / UTM Zone 12N",
    crs: "WGS84 / UTM Zone 12N",
    value: "EPSG:32612",
    bnd: [
      [-114, 0],
      [-108, 84],
    ],
    def: "+proj=utm +zone=12 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32612",
    cmt: "",
  },
  {
    label: "EPSG:32613 | WGS84 / UTM Zone 13N",
    crs: "WGS84 / UTM Zone 13N",
    value: "EPSG:32613",
    bnd: [
      [-108, 0],
      [-102, 84],
    ],
    def: "+proj=utm +zone=13 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32613",
    cmt: "",
  },
  {
    label: "EPSG:32614 | WGS84 / UTM Zone 14N",
    crs: "WGS84 / UTM Zone 14N",
    value: "EPSG:32614",
    bnd: [
      [-102, 0],
      [-96, 84],
    ],
    def: "+proj=utm +zone=14 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32614",
    cmt: "",
  },
  {
    label: "EPSG:32615 | WGS84 / UTM Zone 15N",
    crs: "WGS84 / UTM Zone 15N",
    value: "EPSG:32615",
    bnd: [
      [-96, 0],
      [-90, 84],
    ],
    def: "+proj=utm +zone=15 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32615",
    cmt: "",
  },
  {
    label: "EPSG:32616 | WGS84 / UTM Zone 16N",
    crs: "WGS84 / UTM Zone 16N",
    value: "EPSG:32616",
    bnd: [
      [-90, 0],
      [-84, 84],
    ],
    def: "+proj=utm +zone=16 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32616",
    cmt: "",
  },
  {
    label: "EPSG:32617 | WGS84 / UTM Zone 17N",
    crs: "WGS84 / UTM Zone 17N",
    value: "EPSG:32617",
    bnd: [
      [-84, 0],
      [-78, 84],
    ],
    def: "+proj=utm +zone=17 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32617",
    cmt: "",
  },
  {
    label: "EPSG:32618 | WGS84 / UTM Zone 18N",
    crs: "WGS84 / UTM Zone 18N",
    value: "EPSG:32618",
    bnd: [
      [-78, 0],
      [-72, 84],
    ],
    def: "+proj=utm +zone=18 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32618",
    cmt: "",
  },
  {
    label: "EPSG:32619 | WGS84 / UTM Zone 19N",
    crs: "WGS84 / UTM Zone 19N",
    value: "EPSG:32619",
    bnd: [
      [-72, 0],
      [-66, 84],
    ],
    def: "+proj=utm +zone=19 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32619",
    cmt: "",
  },
  {
    label: "EPSG:32620 | WGS84 / UTM Zone 20N",
    crs: "WGS84 / UTM Zone 20N",
    value: "EPSG:32620",
    bnd: [
      [-66, 0],
      [-60, 84],
    ],
    def: "+proj=utm +zone=20 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32620",
    cmt: "",
  },
  {
    label: "EPSG:32621 | WGS84 / UTM Zone 21N",
    crs: "WGS84 / UTM Zone 21N",
    value: "EPSG:32621",
    bnd: [
      [-60, 0],
      [-54, 84],
    ],
    def: "+proj=utm +zone=21 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32621",
    cmt: "",
  },
  {
    label: "EPSG:32622 | WGS84 / UTM Zone 22N",
    crs: "WGS84 / UTM Zone 22N",
    value: "EPSG:32622",
    bnd: [
      [-54, 0],
      [-48, 84],
    ],
    def: "+proj=utm +zone=22 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32622",
    cmt: "",
  },
  {
    label: "EPSG:32623 | WGS84 / UTM Zone 23N",
    crs: "WGS84 / UTM Zone 23N",
    value: "EPSG:32623",
    bnd: [
      [-48, 0],
      [-42, 84],
    ],
    def: "+proj=utm +zone=23 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32623",
    cmt: "",
  },
  {
    label: "EPSG:32624 | WGS84 / UTM Zone 24N",
    crs: "WGS84 / UTM Zone 24N",
    value: "EPSG:32624",
    bnd: [
      [-42, 0],
      [-36, 84],
    ],
    def: "+proj=utm +zone=24 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32624",
    cmt: "",
  },
  {
    label: "EPSG:32625 | WGS84 / UTM Zone 25N",
    crs: "WGS84 / UTM Zone 25N",
    value: "EPSG:32625",
    bnd: [
      [-36, 0],
      [-30, 84],
    ],
    def: "+proj=utm +zone=25 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32625",
    cmt: "",
  },
  {
    label: "EPSG:32626 | WGS84 / UTM Zone 26N",
    crs: "WGS84 / UTM Zone 26N",
    value: "EPSG:32626",
    bnd: [
      [-30, 0],
      [-24, 84],
    ],
    def: "+proj=utm +zone=26 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32626",
    cmt: "",
  },
  {
    label: "EPSG:32627 | WGS84 / UTM Zone 27N",
    crs: "WGS84 / UTM Zone 27N",
    value: "EPSG:32627",
    bnd: [
      [-24, 0],
      [-18, 84],
    ],
    def: "+proj=utm +zone=27 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32627",
    cmt: "",
  },
  {
    label: "EPSG:32628 | WGS84 / UTM Zone 28N",
    crs: "WGS84 / UTM Zone 28N",
    value: "EPSG:32628",
    bnd: [
      [-18, 0],
      [-12, 84],
    ],
    def: "+proj=utm +zone=28 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32628",
    cmt: "",
  },
  {
    label: "EPSG:32629 | WGS84 / UTM Zone 29N",
    crs: "WGS84 / UTM Zone 29N",
    value: "EPSG:32629",
    bnd: [
      [-12, 0],
      [-6, 84],
    ],
    def: "+proj=utm +zone=29 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32629",
    cmt: "",
  },
  {
    label: "EPSG:32630 | WGS84 / UTM Zone 30N",
    crs: "WGS84 / UTM Zone 30N",
    value: "EPSG:32630",
    bnd: [
      [-6, 0],
      [0, 84],
    ],
    def: "+proj=utm +zone=30 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32630",
    cmt: "",
  },
  {
    label: "EPSG:32631 | WGS84 / UTM Zone 31N",
    crs: "WGS84 / UTM Zone 31N",
    value: "EPSG:32631",
    bnd: [
      [0, 0],
      [6, 84],
    ],
    def: "+proj=utm +zone=31 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32631",
    cmt: "",
  },
  {
    label: "EPSG:32632 | WGS84 / UTM Zone 32N",
    crs: "WGS84 / UTM Zone 32N",
    value: "EPSG:32632",
    bnd: [
      [6, 0],
      [12, 84],
    ],
    def: "+proj=utm +zone=32 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32632",
    cmt: "",
  },
  {
    label: "EPSG:32633 | WGS84 / UTM Zone 33N",
    crs: "WGS84 / UTM Zone 33N",
    value: "EPSG:32633",
    bnd: [
      [12, 0],
      [18, 84],
    ],
    def: "+proj=utm +zone=33 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32633",
    cmt: "",
  },
  {
    label: "EPSG:32634 | WGS84 / UTM Zone 34N",
    crs: "WGS84 / UTM Zone 34N",
    value: "EPSG:32634",
    bnd: [
      [18, 0],
      [24, 84],
    ],
    def: "+proj=utm +zone=34 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32634",
    cmt: "",
  },
  {
    label: "EPSG:32635 | WGS84 / UTM Zone 35N",
    crs: "WGS84 / UTM Zone 35N",
    value: "EPSG:32635",
    bnd: [
      [24, 0],
      [30, 84],
    ],
    def: "+proj=utm +zone=35 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32635",
    cmt: "",
  },
  {
    label: "EPSG:32636 | WGS84 / UTM Zone 36N",
    crs: "WGS84 / UTM Zone 36N",
    value: "EPSG:32636",
    bnd: [
      [30, 0],
      [36, 84],
    ],
    def: "+proj=utm +zone=36 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32636",
    cmt: "",
  },
  {
    label: "EPSG:32637 | WGS84 / UTM Zone 37N",
    crs: "WGS84 / UTM Zone 37N",
    value: "EPSG:32637",
    bnd: [
      [36, 0],
      [42, 84],
    ],
    def: "+proj=utm +zone=37 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32637",
    cmt: "",
  },
  {
    label: "EPSG:32638 | WGS84 / UTM Zone 38N",
    crs: "WGS84 / UTM Zone 38N",
    value: "EPSG:32638",
    bnd: [
      [42, 0],
      [48, 84],
    ],
    def: "+proj=utm +zone=38 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32638",
    cmt: "",
  },
  {
    label: "EPSG:32639 | WGS84 / UTM Zone 39N",
    crs: "WGS84 / UTM Zone 39N",
    value: "EPSG:32639",
    bnd: [
      [48, 0],
      [54, 84],
    ],
    def: "+proj=utm +zone=39 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32639",
    cmt: "",
  },
  {
    label: "EPSG:32640 | WGS84 / UTM Zone 40N",
    crs: "WGS84 / UTM Zone 40N",
    value: "EPSG:32640",
    bnd: [
      [54, 0],
      [60, 84],
    ],
    def: "+proj=utm +zone=40 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32640",
    cmt: "",
  },
  {
    label: "EPSG:32641 | WGS84 / UTM Zone 41N",
    crs: "WGS84 / UTM Zone 41N",
    value: "EPSG:32641",
    bnd: [
      [60, 0],
      [66, 84],
    ],
    def: "+proj=utm +zone=41 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32641",
    cmt: "",
  },
  {
    label: "EPSG:32642 | WGS84 / UTM Zone 42N",
    crs: "WGS84 / UTM Zone 42N",
    value: "EPSG:32642",
    bnd: [
      [66, 0],
      [72, 84],
    ],
    def: "+proj=utm +zone=42 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32642",
    cmt: "",
  },
  {
    label: "EPSG:32643 | WGS84 / UTM Zone 43N",
    crs: "WGS84 / UTM Zone 43N",
    value: "EPSG:32643",
    bnd: [
      [72, 0],
      [78, 84],
    ],
    def: "+proj=utm +zone=43 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32643",
    cmt: "",
  },
  {
    label: "EPSG:32644 | WGS84 / UTM Zone 44N",
    crs: "WGS84 / UTM Zone 44N",
    value: "EPSG:32644",
    bnd: [
      [78, 0],
      [84, 84],
    ],
    def: "+proj=utm +zone=44 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32644",
    cmt: "",
  },
  {
    label: "EPSG:32645 | WGS84 / UTM Zone 45N",
    crs: "WGS84 / UTM Zone 45N",
    value: "EPSG:32645",
    bnd: [
      [84, 0],
      [90, 84],
    ],
    def: "+proj=utm +zone=45 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32645",
    cmt: "",
  },
  {
    label: "EPSG:32646 | WGS84 / UTM Zone 46N",
    crs: "WGS84 / UTM Zone 46N",
    value: "EPSG:32646",
    bnd: [
      [90, 0],
      [96, 84],
    ],
    def: "+proj=utm +zone=46 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32646",
    cmt: "",
  },
  {
    label: "EPSG:32647 | WGS84 / UTM Zone 47N",
    crs: "WGS84 / UTM Zone 47N",
    value: "EPSG:32647",
    bnd: [
      [96, 0],
      [102, 84],
    ],
    def: "+proj=utm +zone=47 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32647",
    cmt: "",
  },
  {
    label: "EPSG:32648 | WGS84 / UTM Zone 48N",
    crs: "WGS84 / UTM Zone 48N",
    value: "EPSG:32648",
    bnd: [
      [102, 0],
      [108, 84],
    ],
    def: "+proj=utm +zone=48 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32648",
    cmt: "",
  },
  {
    label: "EPSG:32649 | WGS84 / UTM Zone 49N",
    crs: "WGS84 / UTM Zone 49N",
    value: "EPSG:32649",
    bnd: [
      [108, 0],
      [114, 84],
    ],
    def: "+proj=utm +zone=49 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32649",
    cmt: "",
  },
  {
    label: "EPSG:32650 | WGS84 / UTM Zone 50N",
    crs: "WGS84 / UTM Zone 50N",
    value: "EPSG:32650",
    bnd: [
      [114, 0],
      [120, 84],
    ],
    def: "+proj=utm +zone=50 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32650",
    cmt: "",
  },
  {
    label: "EPSG:32651 | WGS84 / UTM Zone 51N",
    crs: "WGS84 / UTM Zone 51N",
    value: "EPSG:32651",
    bnd: [
      [120, 0],
      [126, 84],
    ],
    def: "+proj=utm +zone=51 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32651",
    cmt: "",
  },
  {
    label: "EPSG:32652 | WGS84 / UTM Zone 52N",
    crs: "WGS84 / UTM Zone 52N",
    value: "EPSG:32652",
    bnd: [
      [126, 0],
      [132, 84],
    ],
    def: "+proj=utm +zone=52 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32652",
    cmt: "",
  },
  {
    label: "EPSG:32653 | WGS84 / UTM Zone 53N",
    crs: "WGS84 / UTM Zone 53N",
    value: "EPSG:32653",
    bnd: [
      [132, 0],
      [138, 84],
    ],
    def: "+proj=utm +zone=53 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32653",
    cmt: "",
  },
  {
    label: "EPSG:32654 | WGS84 / UTM Zone 54N",
    crs: "WGS84 / UTM Zone 54N",
    value: "EPSG:32654",
    bnd: [
      [138, 0],
      [144, 84],
    ],
    def: "+proj=utm +zone=54 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32654",
    cmt: "",
  },
  {
    label: "EPSG:32655 | WGS84 / UTM Zone 55N",
    crs: "WGS84 / UTM Zone 55N",
    value: "EPSG:32655",
    bnd: [
      [144, 0],
      [150, 84],
    ],
    def: "+proj=utm +zone=55 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32655",
    cmt: "",
  },
  {
    label: "EPSG:32656 | WGS84 / UTM Zone 56N",
    crs: "WGS84 / UTM Zone 56N",
    value: "EPSG:32656",
    bnd: [
      [150, 0],
      [156, 84],
    ],
    def: "+proj=utm +zone=56 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32656",
    cmt: "",
  },
  {
    label: "EPSG:32657 | WGS84 / UTM Zone 57N",
    crs: "WGS84 / UTM Zone 57N",
    value: "EPSG:32657",
    bnd: [
      [156, 0],
      [162, 84],
    ],
    def: "+proj=utm +zone=57 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32657",
    cmt: "",
  },
  {
    label: "EPSG:32658 | WGS84 / UTM Zone 58N",
    crs: "WGS84 / UTM Zone 58N",
    value: "EPSG:32658",
    bnd: [
      [162, 0],
      [168, 84],
    ],
    def: "+proj=utm +zone=58 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32658",
    cmt: "",
  },
  {
    label: "EPSG:32659 | WGS84 / UTM Zone 59N",
    crs: "WGS84 / UTM Zone 59N",
    value: "EPSG:32659",
    bnd: [
      [168, 0],
      [174, 84],
    ],
    def: "+proj=utm +zone=59 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32659",
    cmt: "",
  },
  {
    label: "EPSG:32660 | WGS84 / UTM Zone 60N",
    crs: "WGS84 / UTM Zone 60N",
    value: "EPSG:32660",
    bnd: [
      [174, 0],
      [180, 84],
    ],
    def: "+proj=utm +zone=60 +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32660",
    cmt: "",
  },
  {
    label: "EPSG:32701 | WGS84 / UTM Zone 1S",
    crs: "WGS84 / UTM Zone 1S",
    value: "EPSG:32701",
    bnd: [
      [-180, -80],
      [-174, 0],
    ],
    def: "+proj=utm +zone=1 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32701",
    cmt: "",
  },
  {
    label: "EPSG:32702 | WGS84 / UTM Zone 2S",
    crs: "WGS84 / UTM Zone 2S",
    value: "EPSG:32702",
    bnd: [
      [-174, -80],
      [-168, 0],
    ],
    def: "+proj=utm +zone=2 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32702",
    cmt: "",
  },
  {
    label: "EPSG:32703 | WGS84 / UTM Zone 3S",
    crs: "WGS84 / UTM Zone 3S",
    value: "EPSG:32703",
    bnd: [
      [-168, -80],
      [-162, 0],
    ],
    def: "+proj=utm +zone=3 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32703",
    cmt: "",
  },
  {
    label: "EPSG:32704 | WGS84 / UTM Zone 4S",
    crs: "WGS84 / UTM Zone 4S",
    value: "EPSG:32704",
    bnd: [
      [-162, -80],
      [-156, 0],
    ],
    def: "+proj=utm +zone=4 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32704",
    cmt: "",
  },
  {
    label: "EPSG:32705 | WGS84 / UTM Zone 5S",
    crs: "WGS84 / UTM Zone 5S",
    value: "EPSG:32705",
    bnd: [
      [-156, -80],
      [-150, 0],
    ],
    def: "+proj=utm +zone=5 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32705",
    cmt: "",
  },
  {
    label: "EPSG:32706 | WGS84 / UTM Zone 6S",
    crs: "WGS84 / UTM Zone 6S",
    value: "EPSG:32706",
    bnd: [
      [-150, -80],
      [-144, 0],
    ],
    def: "+proj=utm +zone=6 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32706",
    cmt: "",
  },
  {
    label: "EPSG:32707 | WGS84 / UTM Zone 7S",
    crs: "WGS84 / UTM Zone 7S",
    value: "EPSG:32707",
    bnd: [
      [-144, -80],
      [-138, 0],
    ],
    def: "+proj=utm +zone=7 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32707",
    cmt: "",
  },
  {
    label: "EPSG:32708 | WGS84 / UTM Zone 8S",
    crs: "WGS84 / UTM Zone 8S",
    value: "EPSG:32708",
    bnd: [
      [-138, -80],
      [-132, 0],
    ],
    def: "+proj=utm +zone=8 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32708",
    cmt: "",
  },
  {
    label: "EPSG:32709 | WGS84 / UTM Zone 9S",
    crs: "WGS84 / UTM Zone 9S",
    value: "EPSG:32709",
    bnd: [
      [-132, -80],
      [-126, 0],
    ],
    def: "+proj=utm +zone=9 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32709",
    cmt: "",
  },
  {
    label: "EPSG:32710 | WGS84 / UTM Zone 10S",
    crs: "WGS84 / UTM Zone 10S",
    value: "EPSG:32710",
    bnd: [
      [-126, -80],
      [-120, 0],
    ],
    def: "+proj=utm +zone=10 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32710",
    cmt: "",
  },
  {
    label: "EPSG:32711 | WGS84 / UTM Zone 11S",
    crs: "WGS84 / UTM Zone 11S",
    value: "EPSG:32711",
    bnd: [
      [-120, -80],
      [-114, 0],
    ],
    def: "+proj=utm +zone=11 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32711",
    cmt: "",
  },
  {
    label: "EPSG:32712 | WGS84 / UTM Zone 12S",
    crs: "WGS84 / UTM Zone 12S",
    value: "EPSG:32712",
    bnd: [
      [-114, -80],
      [-108, 0],
    ],
    def: "+proj=utm +zone=12 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32712",
    cmt: "",
  },
  {
    label: "EPSG:32713 | WGS84 / UTM Zone 13S",
    crs: "WGS84 / UTM Zone 13S",
    value: "EPSG:32713",
    bnd: [
      [-108, -80],
      [-102, 0],
    ],
    def: "+proj=utm +zone=13 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32713",
    cmt: "",
  },
  {
    label: "EPSG:32714 | WGS84 / UTM Zone 14S",
    crs: "WGS84 / UTM Zone 14S",
    value: "EPSG:32714",
    bnd: [
      [-102, -80],
      [-96, 0],
    ],
    def: "+proj=utm +zone=14 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32714",
    cmt: "",
  },
  {
    label: "EPSG:32715 | WGS84 / UTM Zone 15S",
    crs: "WGS84 / UTM Zone 15S",
    value: "EPSG:32715",
    bnd: [
      [-96, -80],
      [-90, 0],
    ],
    def: "+proj=utm +zone=15 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32715",
    cmt: "",
  },
  {
    label: "EPSG:32716 | WGS84 / UTM Zone 16S",
    crs: "WGS84 / UTM Zone 16S",
    value: "EPSG:32716",
    bnd: [
      [-90, -80],
      [-84, 0],
    ],
    def: "+proj=utm +zone=16 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32716",
    cmt: "",
  },
  {
    label: "EPSG:32717 | WGS84 / UTM Zone 17S",
    crs: "WGS84 / UTM Zone 17S",
    value: "EPSG:32717",
    bnd: [
      [-84, -80],
      [-78, 0],
    ],
    def: "+proj=utm +zone=17 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32717",
    cmt: "",
  },
  {
    label: "EPSG:32718 | WGS84 / UTM Zone 18S",
    crs: "WGS84 / UTM Zone 18S",
    value: "EPSG:32718",
    bnd: [
      [-78, -80],
      [-72, 0],
    ],
    def: "+proj=utm +zone=18 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32718",
    cmt: "",
  },
  {
    label: "EPSG:32719 | WGS84 / UTM Zone 19S",
    crs: "WGS84 / UTM Zone 19S",
    value: "EPSG:32719",
    bnd: [
      [-72, -80],
      [-66, 0],
    ],
    def: "+proj=utm +zone=19 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32719",
    cmt: "",
  },
  {
    label: "EPSG:32720 | WGS84 / UTM Zone 20S",
    crs: "WGS84 / UTM Zone 20S",
    value: "EPSG:32720",
    bnd: [
      [-66, -80],
      [-60, 0],
    ],
    def: "+proj=utm +zone=20 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32720",
    cmt: "",
  },
  {
    label: "EPSG:32721 | WGS84 / UTM Zone 21S",
    crs: "WGS84 / UTM Zone 21S",
    value: "EPSG:32721",
    bnd: [
      [-60, -80],
      [-54, 0],
    ],
    def: "+proj=utm +zone=21 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32721",
    cmt: "",
  },
  {
    label: "EPSG:32722 | WGS84 / UTM Zone 22S",
    crs: "WGS84 / UTM Zone 22S",
    value: "EPSG:32722",
    bnd: [
      [-54, -80],
      [-48, 0],
    ],
    def: "+proj=utm +zone=22 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32722",
    cmt: "",
  },
  {
    label: "EPSG:32723 | WGS84 / UTM Zone 23S",
    crs: "WGS84 / UTM Zone 23S",
    value: "EPSG:32723",
    bnd: [
      [-48, -80],
      [-42, 0],
    ],
    def: "+proj=utm +zone=23 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32723",
    cmt: "",
  },
  {
    label: "EPSG:32724 | WGS84 / UTM Zone 24S",
    crs: "WGS84 / UTM Zone 24S",
    value: "EPSG:32724",
    bnd: [
      [-42, -80],
      [-36, 0],
    ],
    def: "+proj=utm +zone=24 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32724",
    cmt: "",
  },
  {
    label: "EPSG:32725 | WGS84 / UTM Zone 25S",
    crs: "WGS84 / UTM Zone 25S",
    value: "EPSG:32725",
    bnd: [
      [-36, -80],
      [-30, 0],
    ],
    def: "+proj=utm +zone=25 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32725",
    cmt: "",
  },
  {
    label: "EPSG:32726 | WGS84 / UTM Zone 26S",
    crs: "WGS84 / UTM Zone 26S",
    value: "EPSG:32726",
    bnd: [
      [-30, -80],
      [-24, 0],
    ],
    def: "+proj=utm +zone=26 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32726",
    cmt: "",
  },
  {
    label: "EPSG:32727 | WGS84 / UTM Zone 27S",
    crs: "WGS84 / UTM Zone 27S",
    value: "EPSG:32727",
    bnd: [
      [-24, -80],
      [-18, 0],
    ],
    def: "+proj=utm +zone=27 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32727",
    cmt: "",
  },
  {
    label: "EPSG:32728 | WGS84 / UTM Zone 28S",
    crs: "WGS84 / UTM Zone 28S",
    value: "EPSG:32728",
    bnd: [
      [-18, -80],
      [-12, 0],
    ],
    def: "+proj=utm +zone=28 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32728",
    cmt: "",
  },
  {
    label: "EPSG:32729 | WGS84 / UTM Zone 29S",
    crs: "WGS84 / UTM Zone 29S",
    value: "EPSG:32729",
    bnd: [
      [-12, -80],
      [-6, 0],
    ],
    def: "+proj=utm +zone=29 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32729",
    cmt: "",
  },
  {
    label: "EPSG:32730 | WGS84 / UTM Zone 30S",
    crs: "WGS84 / UTM Zone 30S",
    value: "EPSG:32730",
    bnd: [
      [-6, -80],
      [0, 0],
    ],
    def: "+proj=utm +zone=30 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32730",
    cmt: "",
  },
  {
    label: "EPSG:32731 | WGS84 / UTM Zone 31S",
    crs: "WGS84 / UTM Zone 31S",
    value: "EPSG:32731",
    bnd: [
      [0, -80],
      [6, 0],
    ],
    def: "+proj=utm +zone=31 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32731",
    cmt: "",
  },
  {
    label: "EPSG:32732 | WGS84 / UTM Zone 32S",
    crs: "WGS84 / UTM Zone 32S",
    value: "EPSG:32732",
    bnd: [
      [6, -80],
      [12, 0],
    ],
    def: "+proj=utm +zone=32 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32732",
    cmt: "",
  },
  {
    label: "EPSG:32733 | WGS84 / UTM Zone 33S",
    crs: "WGS84 / UTM Zone 33S",
    value: "EPSG:32733",
    bnd: [
      [12, -80],
      [18, 0],
    ],
    def: "+proj=utm +zone=33 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32733",
    cmt: "",
  },
  {
    label: "EPSG:32734 | WGS84 / UTM Zone 34S",
    crs: "WGS84 / UTM Zone 34S",
    value: "EPSG:32734",
    bnd: [
      [18, -80],
      [24, 0],
    ],
    def: "+proj=utm +zone=34 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32734",
    cmt: "",
  },
  {
    label: "EPSG:32735 | WGS84 / UTM Zone 35S",
    crs: "WGS84 / UTM Zone 35S",
    value: "EPSG:32735",
    bnd: [
      [24, -80],
      [30, 0],
    ],
    def: "+proj=utm +zone=35 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32735",
    cmt: "",
  },
  {
    label: "EPSG:32736 | WGS84 / UTM Zone 36S",
    crs: "WGS84 / UTM Zone 36S",
    value: "EPSG:32736",
    bnd: [
      [30, -80],
      [36, 0],
    ],
    def: "+proj=utm +zone=36 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32736",
    cmt: "",
  },
  {
    label: "EPSG:32737 | WGS84 / UTM Zone 37S",
    crs: "WGS84 / UTM Zone 37S",
    value: "EPSG:32737",
    bnd: [
      [36, -80],
      [42, 0],
    ],
    def: "+proj=utm +zone=37 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32737",
    cmt: "",
  },
  {
    label: "EPSG:32738 | WGS84 / UTM Zone 38S",
    crs: "WGS84 / UTM Zone 38S",
    value: "EPSG:32738",
    bnd: [
      [42, -80],
      [48, 0],
    ],
    def: "+proj=utm +zone=38 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32738",
    cmt: "",
  },
  {
    label: "EPSG:32739 | WGS84 / UTM Zone 39S",
    crs: "WGS84 / UTM Zone 39S",
    value: "EPSG:32739",
    bnd: [
      [48, -80],
      [54, 0],
    ],
    def: "+proj=utm +zone=39 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32739",
    cmt: "",
  },
  {
    label: "EPSG:32740 | WGS84 / UTM Zone 40S",
    crs: "WGS84 / UTM Zone 40S",
    value: "EPSG:32740",
    bnd: [
      [54, -80],
      [60, 0],
    ],
    def: "+proj=utm +zone=40 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32740",
    cmt: "",
  },
  {
    label: "EPSG:32741 | WGS84 / UTM Zone 41S",
    crs: "WGS84 / UTM Zone 41S",
    value: "EPSG:32741",
    bnd: [
      [60, -80],
      [66, 0],
    ],
    def: "+proj=utm +zone=41 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32741",
    cmt: "",
  },
  {
    label: "EPSG:32742 | WGS84 / UTM Zone 42S",
    crs: "WGS84 / UTM Zone 42S",
    value: "EPSG:32742",
    bnd: [
      [66, -80],
      [72, 0],
    ],
    def: "+proj=utm +zone=42 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32742",
    cmt: "",
  },
  {
    label: "EPSG:32743 | WGS84 / UTM Zone 43S",
    crs: "WGS84 / UTM Zone 43S",
    value: "EPSG:32743",
    bnd: [
      [72, -80],
      [78, 0],
    ],
    def: "+proj=utm +zone=43 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32743",
    cmt: "",
  },
  {
    label: "EPSG:32744 | WGS84 / UTM Zone 44S",
    crs: "WGS84 / UTM Zone 44S",
    value: "EPSG:32744",
    bnd: [
      [78, -80],
      [84, 0],
    ],
    def: "+proj=utm +zone=44 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32744",
    cmt: "",
  },
  {
    label: "EPSG:32745 | WGS84 / UTM Zone 45S",
    crs: "WGS84 / UTM Zone 45S",
    value: "EPSG:32745",
    bnd: [
      [84, -80],
      [90, 0],
    ],
    def: "+proj=utm +zone=45 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32745",
    cmt: "",
  },
  {
    label: "EPSG:32746 | WGS84 / UTM Zone 46S",
    crs: "WGS84 / UTM Zone 46S",
    value: "EPSG:32746",
    bnd: [
      [90, -80],
      [96, 0],
    ],
    def: "+proj=utm +zone=46 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32746",
    cmt: "",
  },
  {
    label: "EPSG:32747 | WGS84 / UTM Zone 47S",
    crs: "WGS84 / UTM Zone 47S",
    value: "EPSG:32747",
    bnd: [
      [96, -80],
      [102, 0],
    ],
    def: "+proj=utm +zone=47 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32747",
    cmt: "",
  },
  {
    label: "EPSG:32748 | WGS84 / UTM Zone 48S",
    crs: "WGS84 / UTM Zone 48S",
    value: "EPSG:32748",
    bnd: [
      [102, -80],
      [108, 0],
    ],
    def: "+proj=utm +zone=48 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32748",
    cmt: "",
  },
  {
    label: "EPSG:32749 | WGS84 / UTM Zone 49S",
    crs: "WGS84 / UTM Zone 49S",
    value: "EPSG:32749",
    bnd: [
      [108, -80],
      [114, 0],
    ],
    def: "+proj=utm +zone=49 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32749",
    cmt: "",
  },
  {
    label: "EPSG:32750 | WGS84 / UTM Zone 50S",
    crs: "WGS84 / UTM Zone 50S",
    value: "EPSG:32750",
    bnd: [
      [114, -80],
      [120, 0],
    ],
    def: "+proj=utm +zone=50 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32750",
    cmt: "",
  },
  {
    label: "EPSG:32751 | WGS84 / UTM Zone 51S",
    crs: "WGS84 / UTM Zone 51S",
    value: "EPSG:32751",
    bnd: [
      [120, -80],
      [126, 0],
    ],
    def: "+proj=utm +zone=51 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32751",
    cmt: "",
  },
  {
    label: "EPSG:32752 | WGS84 / UTM Zone 52S",
    crs: "WGS84 / UTM Zone 52S",
    value: "EPSG:32752",
    bnd: [
      [126, -80],
      [132, 0],
    ],
    def: "+proj=utm +zone=52 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32752",
    cmt: "",
  },
  {
    label: "EPSG:32753 | WGS84 / UTM Zone 53S",
    crs: "WGS84 / UTM Zone 53S",
    value: "EPSG:32753",
    bnd: [
      [132, -80],
      [138, 0],
    ],
    def: "+proj=utm +zone=53 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32753",
    cmt: "",
  },
  {
    label: "EPSG:32754 | WGS84 / UTM Zone 54S",
    crs: "WGS84 / UTM Zone 54S",
    value: "EPSG:32754",
    bnd: [
      [138, -80],
      [144, 0],
    ],
    def: "+proj=utm +zone=54 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32754",
    cmt: "",
  },
  {
    label: "EPSG:32755 | WGS84 / UTM Zone 55S",
    crs: "WGS84 / UTM Zone 55S",
    value: "EPSG:32755",
    bnd: [
      [144, -80],
      [150, 0],
    ],
    def: "+proj=utm +zone=55 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32755",
    cmt: "",
  },
  {
    label: "EPSG:32756 | WGS84 / UTM Zone 56S",
    crs: "WGS84 / UTM Zone 56S",
    value: "EPSG:32756",
    bnd: [
      [150, -80],
      [156, 0],
    ],
    def: "+proj=utm +zone=56 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32756",
    cmt: "",
  },
  {
    label: "EPSG:32757 | WGS84 / UTM Zone 57S",
    crs: "WGS84 / UTM Zone 57S",
    value: "EPSG:32757",
    bnd: [
      [156, -80],
      [162, 0],
    ],
    def: "+proj=utm +zone=57 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32757",
    cmt: "",
  },
  {
    label: "EPSG:32758 | WGS84 / UTM Zone 58S",
    crs: "WGS84 / UTM Zone 58S",
    value: "EPSG:32758",
    bnd: [
      [162, -80],
      [168, 0],
    ],
    def: "+proj=utm +zone=58 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32758",
    cmt: "",
  },
  {
    label: "EPSG:32759 | WGS84 / UTM Zone 59S",
    crs: "WGS84 / UTM Zone 59S",
    value: "EPSG:32759",
    bnd: [
      [168, -80],
      [174, 0],
    ],
    def: "+proj=utm +zone=59 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32759",
    cmt: "",
  },
  {
    label: "EPSG:32760 | WGS84 / UTM Zone 60S",
    crs: "WGS84 / UTM Zone 60S",
    value: "EPSG:32760",
    bnd: [
      [174, -80],
      [180, 0],
    ],
    def: "+proj=utm +zone=60 +south +datum=WGS84 +units=m +no_defs +type=crs",
    src: "https://epsg.io/32760",
    cmt: "",
  },
];

export const crsGroupedOptions = [
  {
    label: "Asia",
    options: asiaOptions,
  },
  {
    label: "AustraliaOceania",
    options: australiaOceania,
  },
  {
    label: "Europe",
    options: europeOptions,
  },
  {
    label: "NorthAmerica",
    options: northAmericaOptions,
  },
  {
    label: "SouthMiddleAmerica",
    options: southMiddleAmericaOptions,
  },
  {
    label: "Worldwide",
    options: worldwideOptions,
  },
];

export const allCrsOptions = [
  ...asiaOptions,
  ...australiaOceania,
  ...europeOptions,
  ...northAmericaOptions,
  ...southMiddleAmericaOptions,
  ...worldwideOptions,
];
