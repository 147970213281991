import React from "react";
import { FormSettingsSeparator } from "../Common/Form/FormSeparator/index";
import { AlertCircle } from "react-feather";
import {
  setNrOfXProcessors,
  setNrOfYProcessors,
} from "../../redux/actions/simulationSettingsActions";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const ProcessorSettings = () => {
  const nrOfXProcessors = useSelector(
    (state) => state.simulationSettings.nrOfXProcessors
  );
  const nrOfYProcessors = useSelector(
    (state) => state.simulationSettings.nrOfYProcessors
  );

  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <FormSettingsSeparator label={t("ProcessorSettings")} />
      <label className="form-label">{t("TotalNoOfProcessors")}</label>
      {nrOfXProcessors * nrOfYProcessors > 54 && (
        <div className="setup-warning">
          <AlertCircle size={"15px"} />
          <div className="setup-warning-info">
            {`${t("CpuCoresNumberError")}`} - 54
          </div>
        </div>
      )}
      <input
        className="setup-input"
        name="num"
        value={nrOfXProcessors * nrOfYProcessors}
        disabled
      />
      <label className="form-label ">{t("NoProcessorsInXDirection")}</label>
      <input
        className="setup-input"
        type="number"
        name="num"
        min="4"
        max="100"
        step="2"
        value={nrOfXProcessors}
        onChange={(e) => dispatch(setNrOfXProcessors(e.target.value))}
      />
      <label className="form-label ">{t("NoProcessorsInYDirection")}</label>
      <input
        className="setup-input"
        type="number"
        name="num"
        min="4"
        max="60"
        step="2"
        value={nrOfYProcessors}
        onChange={(e) => dispatch(setNrOfYProcessors(e.target.value))}
      />
    </div>
  );
};

export default ProcessorSettings;
