import React, { useEffect, useState, useCallback } from "react";
import { Loader } from "../../../Components/Loader/index";
import { Save } from "react-feather";
import { downloadBlobAsync } from "../../../api/storageApi";
import { getDownloadLink } from "../../../api/storageApi";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Report.scss";

const Report = () => {
  const { t } = useTranslation();

  const reportUrl = useSelector((state) => state.results.reportUrl);
  const [isPdfDownloading, setIsPdfDowloading] = useState(false);
  const [isDocxDownloading, setIsDocxDowloading] = useState(false);
  const [reportHtml, setReportHtml] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await downloadBlobAsync(reportUrl.reportHtml);
        const reportHtml = response.data;

        setReportHtml(reportHtml);
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();
  }, []);

  const downloadReportPdf = useCallback(async () => {
    if (isPdfDownloading) return;
    setIsPdfDowloading(true);
    const url = await getDownloadLink(reportUrl.reportPdf);
    const link = document.createElement("a");
    link.download = "file.pdf";
    link.href = url;
    link.click();
    setIsPdfDowloading(false);
  }, [isPdfDownloading]);

  const downloadReportDocx = useCallback(async () => {
    if (isDocxDownloading) return;
    setIsDocxDowloading(true);
    const url = await getDownloadLink(reportUrl.reportDocx);
    const link = document.createElement("a");
    link.download = "download";
    link.href = url;
    link.click();
    setIsDocxDowloading(false);
  }, [isDocxDownloading]);

  return (
    <>
      {!!reportHtml ? (
        <>
          <div
            style={{ height: "100%", marginTop: "32px" }}
            dangerouslySetInnerHTML={{
              __html: reportHtml,
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "50px",
            }}
          >
            <button
              className="raster-settings-btn"
              onClick={downloadReportPdf}
              style={{ marginTop: "45px" }}
            >
              <div
                className="btn-content-wrapper btn-palm-mon-content-wrapper"
                style={{ justifyContent: "flexStart" }}
              >
                <Save color={"#494949"} size={"16px"} strokeWidth={"2px"} />
                <div className="raster-btn-lbl">
                  {t("DownloadResults")} .pdf
                </div>
              </div>
            </button>
            <button
              className="raster-settings-btn"
              onClick={downloadReportDocx}
              style={{ marginTop: "15px" }}
            >
              <div
                className="btn-content-wrapper btn-palm-mon-content-wrapper"
                style={{ justifyContent: "flexStart" }}
              >
                <Save color={"#494949"} size={"16px"} strokeWidth={"2px"} />
                <div className="raster-btn-lbl">
                  {t("DownloadResults")} .docx
                </div>
              </div>
            </button>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default withRouter(Report);
