import React, { useRef, useEffect } from "react";
import { Download, Trash } from "react-feather";
import { useTranslation } from "react-i18next";
import { Loader } from "../../Loader";
import "./FileListItem.scss";

const addTitleToSvg = (svgRef, title) => {
  if (!svgRef || !svgRef.current) {
    return;
  }
  const titleEl = document.createElementNS(
    "http://www.w3.org/2000/svg",
    "title"
  );
  titleEl.textContent = title;
  svgRef.current.prepend(titleEl);
};

export const FileListItem = (props) => {
  const Icon = (icon, className) => {
    const IconComponent = icon;
    return icon ? <IconComponent size={13} className={className} /> : null;
  };
  const { t } = useTranslation();
  const trashIconRef = useRef(null);
  const downloadIconRef = useRef(null);
  useEffect(() => {
    addTitleToSvg(downloadIconRef, t("Download"));
  }, [t]);
  return (
    <div className="file-item">
      <div className="file-card">
        <div className="file-card-wrapper">
          {Icon(props.icon, "file-card__icon--main")}
        </div>
      </div>
      <div className="file-card__label">{props.label}</div>
      <div className="file-card__icons">
        <Download
          ref={downloadIconRef}
          className="file-card__icon"
          onClick={props.onDownload}
        />
        {props.isDeleting ? (
          <Loader size="x-small" />
        ) : (
          !props.readonly && (
            <button
              className="delete-file-list-btn"
              disabled={props.disabled}
              title={
                props.disabled
                  ? t("ExternalFileDeleteDisabledInfo")
                  : t("Delete")
              }
            >
              <Trash
                ref={trashIconRef}
                className={`file-card__icon file-card__icon--red file-card__icon--delete ${
                  props.disabled ? "file-card__icon--disabled" : ""
                }`}
                onClick={props.onDelete}
              />
            </button>
          )
        )}
      </div>
    </div>
  );
};
