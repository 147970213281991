import React from "react";
import Select from "react-select";
import {
  ExpertHelpLabel,
  ExpertInput,
  ExpertTimeAccordion,
} from "../../ExpertUtils/ExpertComponent";
import { FormSettingsSeparator } from "../../../../Components/Common/Form/FormSeparator/index";
import { helpLink } from "../../expertConst";
import { changeExpertInput } from "../../../../redux/actions/expertActions";
import { selectSetupStyle } from "../../../../Components/Common/PaletteDropdown/index";
import { useSelector, useDispatch } from "react-redux";

const [page, subpage] = ["output", "volume3d"];

const Volume = () => {
  const volume3d = useSelector(
    (state) => state.expert.expertJson.output.volume3d
  );
  const childRasterParameters = useSelector(
    (state) => state.projects?.currentCase?.childRasterParameters
  );
  const childRasterParamsOnMount = useSelector(
    (state) => state.rasterArea?.currentCase?.childRasterParameters
  );
  const dispatch = useDispatch();

  return (
    <>
      <div style={{ marginBottom: -20 }} />
      <FormSettingsSeparator
        style={{ fontSize: "17px" }}
        label={"General Settings"}
      />
      <ExpertHelpLabel
        label={"Vertical Limit of 3D Volume Output [-]"}
        helpLink={`${helpLink}/runtime_parameters#nz_do3d`}
      />
      <ExpertInput
        page={page}
        subpage={subpage}
        section="parameters"
        subkey="vol3d_vlimit"
        min={1}
        max={100}
        step={1}
        constValue={volume3d.parameters.vol3d_vlimit}
      />
      {(childRasterParameters || childRasterParamsOnMount) && (
        <>
          <ExpertHelpLabel
            label={"Vertical Limit of 3D Volume Output Child [-]"}
            helpLink={`${helpLink}/runtime_parameters#nz_do3d`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="parameters"
            subkey="vol3d_vlimit_child"
            min={1}
            max={100}
            step={20}
            constValue={volume3d.parameters.vol3d_vlimit_child}
          />
        </>
      )}
      <div style={{ marginBottom: "-12px" }} />
      <FormSettingsSeparator
        style={{ fontSize: "17px" }}
        label={"Averaged Output"}
      />
      <ExpertHelpLabel
        label={"Activate averaged output"}
        helpLink={`${helpLink}/iofiles#DATA_3D_AV_NETCDF`}
      />
      <Select
        isSearchable={false}
        styles={selectSetupStyle}
        options={[
          {
            value: "True",
            label: "True",
          },
          { value: "False", label: "False" },
        ]}
        value={{
          label: !!volume3d.averaged.vol3d_av_isActive ? "True" : "False",
          value: !!volume3d.averaged.vol3d_av_isActive ? "True" : "False",
        }}
        onChange={(e) =>
          dispatch(
            changeExpertInput({
              page: page,
              subpage: subpage,
              key: "averaged",
              subkey: "vol3d_av_isActive",
              value: e.value === "True" ? true : false,
            })
          )
        }
      />
      <FormSettingsSeparator
        style={{ fontSize: "17px" }}
        label={"Instantaneous output"}
      />
      <ExpertHelpLabel
        label={"Activate instantaneous output"}
        helpLink={`${helpLink}/iofiles#DATA_3D_NETCDF`}
      />
      <Select
        isSearchable={false}
        styles={selectSetupStyle}
        options={[
          {
            value: "True",
            label: "True",
          },
          { value: "False", label: "False" },
        ]}
        value={{
          label: !!volume3d.instantaneous.vol3d_inst_isActive
            ? "True"
            : "False",
          value: !!volume3d.instantaneous.vol3d_inst_isActive
            ? "True"
            : "False",
        }}
        onChange={(e) =>
          dispatch(
            changeExpertInput({
              page: page,
              subpage: subpage,
              key: "instantaneous",
              subkey: "vol3d_inst_isActive",
              value: e.value === "True" ? true : false,
            })
          )
        }
      />
      <div style={{ marginBottom: 12 }} />
      <ExpertHelpLabel
        label={"Instantaneous Output Intervall [s]"}
        helpLink={`${helpLink}/runtime_parameters#dt_do3d`}
      />
      <ExpertTimeAccordion
        page={page}
        subpage={subpage}
        section="instantaneous"
        subkey="vol3d_dt_do3d"
        min={1}
        max={86400}
        constValue={volume3d.instantaneous.vol3d_dt_do3d}
      />
    </>
  );
};

export default Volume;
