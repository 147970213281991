import React, { useState, useEffect } from "react";
import Select from "react-select";
import ExpertCheckboxTable from "../../ExpertUtils/ExpertCheckboxTable/ExpertCheckboxTable";
import { ExpertTempAccordion } from "../../ExpertUtils/ExpertComponent";
import { ExpertHelpLabel } from "../../ExpertUtils/ExpertComponent";
import { FormSettingsSeparator } from "../../../../Components/Common/Form/FormSeparator/index";
import { indoorClimateTableData } from "../../expertConst";
import { selectSetupStyle } from "../../../../Components/Common/PaletteDropdown/index";
import { helpLink } from "../../expertConst";
import { changeExpertDropdownInput } from "../../../../redux/actions/expertActions";
import { useSelector, useDispatch } from "react-redux";

const [page, subpage] = ["modules", "indoor_climate"];

const IndoorClimate = (props) => {
  const indoor_climate = useSelector(
    (state) => state.expert.expertJson.modules.indoor_climate
  );

  // const expertTempKey = useSelector((state) => state.expert.expertTempKey);
  // const expertTempValue = useSelector((state) => state.expert.expertTempValue);

  const [checkboxTableData, setCheckboxTableData] = useState(
    indoorClimateTableData
  );

  useEffect(() => {
    const rowDataFromRedux = indoorClimateTableData;
    rowDataFromRedux.rowData = indoor_climate.output_quantities;
    setCheckboxTableData(checkboxTableData);
  }, []);

  const dispatch = useDispatch();

  return (
    <>
      <ExpertHelpLabel label={"Activate Module"} helpLink={""} />
      <Select
        isSearchable={false}
        styles={selectSetupStyle}
        options={[
          {
            value: "True",
            label: "True",
          },
          { value: "False", label: "False" },
        ]}
        value={{
          label: !!indoor_climate.isActive ? "True" : "False",
          value: !!indoor_climate.isActive ? "True" : "False",
        }}
        onChange={(e) =>
          dispatch(
            changeExpertDropdownInput({
              page: page,
              subpage: subpage,
              key: "",
              subkey: "isActive",
              value: e.value,
            })
          )
        }
      />

      {indoor_climate.isActive && (
        <>
          <FormSettingsSeparator
            style={{ fontSize: "17px" }}
            label={"Parameters"}
          />
          <ExpertHelpLabel
            label={"Activate During Spinup"}
            helpLink={`${helpLink}/indoor_parameters#indoor_during_spinup`}
          />
          <Select
            isSearchable={false}
            styles={selectSetupStyle}
            options={[
              {
                value: "True",
                label: "True",
              },
              { value: "False", label: "False" },
            ]}
            value={{
              label: !!indoor_climate.parameters.indoor_during_spinup
                ? "True"
                : "False",
              value: !!indoor_climate.parameters.indoor_during_spinup
                ? "True"
                : "False",
            }}
            onChange={(e) =>
              dispatch(
                changeExpertDropdownInput({
                  page: page,
                  subpage: subpage,
                  key: "parameters",
                  subkey: "indoor_during_spinup",
                  value: e.value,
                })
              )
            }
          />
          <div style={{ marginBottom: 12 }} />
          <ExpertHelpLabel
            label={"Initial Indoor Temperature [K]"}
            helpLink={`${helpLink}/indoor_parameters#initial_indoor_temperature`}
          />
          <ExpertTempAccordion
            page={page}
            subpage={subpage}
            section="parameters"
            subkey="initial_indoor_temperature"
            min={273.14}
            max={373}
            step={0.01}
            constValue={indoor_climate.parameters.initial_indoor_temperature}
          />
          <FormSettingsSeparator
            style={{ fontSize: "17px" }}
            label={"Output Quantities"}
          />
          <div
            className={"table-animation"}
            style={{ marginTop: "12px", minWidth: "805px" }}
          >
            <ExpertCheckboxTable
              tableData={checkboxTableData}
              rowData={props.rowData}
            />
          </div>
        </>
      )}
    </>
  );
};

export default IndoorClimate;
