export const definitionsJson = {
  bh: {
    n: "bh",
    u: 5,
    cl: 0,
    cm: 999999999,
    bg: 1,
    md: 15,
    t: 3,
    p: 1,
    s: 0.1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  z0p: {
    n: "z0p",
    u: 5,
    cl: 0,
    cm: 999999999,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/land_surface_parameters#z0_pavement",
  },
  z0hp: {
    n: "z0hp",
    u: 5,
    cl: 0,
    cm: 999999999,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/land_surface_parameters#z0h_pavement",
  },
  emiss: {
    n: "emiss",
    u: 0,
    cl: 0,
    cm: 1,
    md: 15,
    t: 3,
    p: 2,
    s: 0.01,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/radiation_parameters#emissivity",
  },
  albedo: {
    n: "albedo",
    u: 0,
    cl: 1,
    cm: 42,
    md: 15,
    t: 3,
    p: 2,
    s: 1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/radiation_parameters#albedo_type",
  },
  rcmin: {
    n: "rcmin",
    u: 7,
    cl: 0,
    cm: 999999999,
    md: 15,
    t: 3,
    p: 2,
    s: 1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/land_surface_parameters#min_canopy_resistance",
  },
  lai: {
    n: "lai",
    u: 6,
    cl: 0,
    cm: 999999999,
    md: 15,
    t: 3,
    p: 2,
    s: 0.1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/land_surface_parameters#leaf_area_index",
  },
  cveg: {
    n: "cveg",
    u: 0,
    cl: 0,
    cm: 1,
    md: 15,
    t: 3,
    p: 2,
    s: 0.01,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/land_surface_parameters#vegetation_coverage",
  },
  canoprcoef: {
    n: "canoprcoef",
    u: 2,
    cl: 0,
    cm: 1,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/land_surface_parameters#canopy_resistance_coefficient",
  },
  z0v: {
    n: "z0v",
    u: 5,
    cl: 0,
    cm: 999999999,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/land_surface_parameters#z0_vegetation",
  },
  z0hv: {
    n: "z0hv",
    u: 5,
    cl: 0,
    cm: 999999999,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/land_surface_parameters#z0h_vegetation",
  },
  lambs: {
    n: "lambs",
    u: 9,
    cl: 0,
    cm: 999999999,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/land_surface_parameters#lambda_surface_stable",
  },
  lambu: {
    n: "lambu",
    u: 9,
    cl: 0,
    cm: 999999999,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/land_surface_parameters#lambda_surface_unstable",
  },
  fswin: {
    n: "fswin",
    u: 0,
    cl: 0,
    cm: 1,
    md: 15,
    t: 3,
    p: 2,
    s: 0.01,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/land_surface_parameters#f_shortwave_incoming",
  },
  csurf: {
    n: "csurf",
    u: 3,
    cl: 0,
    cm: 999999999,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/land_surface_parameters#c_surface",
  },
  watert: {
    n: "watert",
    u: 14,
    cl: 1,
    cm: 99,
    md: 15,
    t: 3,
    p: 1,
    s: 0.01,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/land_surface_parameters#water_temperature",
  },
  z0w: {
    n: "z0w",
    u: 5,
    cl: 0,
    cm: 999999999,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/land_surface_parameters#z0_water",
  },
  z0hw: {
    n: "z0hw",
    u: 5,
    cl: 0,
    cm: 999999999,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/land_surface_parameters#z0h_water",
  },
  wallfrac: {
    n: "wallfrac",
    u: 0,
    cl: 0,
    cm: 999999999,
    bg: 1,
    md: 15,
    t: 3,
    p: 1,
    s: 0.01,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  windfrac: {
    n: "windfrac",
    u: 0,
    cl: 0,
    cm: 999999999,
    bg: 1,
    md: 15,
    t: 3,
    p: 1,
    s: 0.01,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  greenfrac_wa: {
    n: "greenfrac_wa",
    u: 0,
    cl: 0,
    cm: 999999999,
    bg: 1,
    md: 15,
    t: 3,
    p: 1,
    s: 0.01,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  greenfrac_ro: {
    n: "greenfrac_ro",
    u: 0,
    cl: 0,
    cm: 999999999,
    bg: 1,
    md: 15,
    t: 3,
    p: 1,
    s: 0.01,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  wallfrac_ro: {
    n: "wallfrac_ro",
    u: 0,
    cl: 0,
    cm: 999999999,
    bg: 1,
    md: 15,
    t: 3,
    p: 1,
    s: 0.01,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  windfrac_ro: {
    n: "windfrac_ro",
    u: 0,
    cl: 0,
    cm: 999999999,
    bg: 1,
    md: 15,
    t: 3,
    p: 1,
    s: 0.01,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  storey_height: {
    n: "storey_height",
    u: 5,
    cl: 0,
    cm: 999999999,
    bg: 2,
    md: 15,
    t: 3,
    p: 2,
    s: 0.1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  ceiling_height: {
    n: "ceiling_height",
    u: 5,
    cl: 0,
    cm: 999999999,
    bg: 2,
    md: 15,
    t: 3,
    p: 2,
    s: 0.1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  ground_h: {
    n: "ground_h",
    u: 5,
    cl: 0,
    cm: 999999999,
    bg: 2,
    md: 15,
    t: 3,
    p: 2,
    s: 0.1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  d_wall1: {
    n: "d_wall1",
    u: 5,
    cl: 0,
    cm: 999999999,
    bg: 3,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  d_wall2: {
    n: "d_wall2",
    u: 5,
    cl: 0,
    cm: 999999999,
    bg: 3,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  d_wall3: {
    n: "d_wall3",
    u: 5,
    cl: 0,
    cm: 999999999,
    bg: 3,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  d_wall4: {
    n: "d_wall4",
    u: 5,
    cl: 0,
    cm: 999999999,
    bg: 3,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  c_w1: {
    n: "c_w1",
    u: 3,
    cl: 0,
    cm: 999999999,
    bg: 3,
    md: 15,
    t: 3,
    p: 2,
    s: 1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  c_w2: {
    n: "c_w2",
    u: 3,
    cl: 0,
    cm: 999999999,
    bg: 3,
    md: 15,
    t: 3,
    p: 2,
    s: 1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  c_w3: {
    n: "c_w3",
    u: 3,
    cl: 0,
    cm: 999999999,
    bg: 3,
    md: 15,
    t: 3,
    p: 2,
    s: 1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  c_w4: {
    n: "c_w4",
    u: 3,
    cl: 0,
    cm: 999999999,
    bg: 3,
    md: 15,
    t: 3,
    p: 2,
    s: 1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  lamb_w1: {
    n: "lamb_w1",
    u: 9,
    cl: 0,
    cm: 999999999,
    bg: 3,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  lamb_w2: {
    n: "lamb_w2",
    u: 9,
    cl: 0,
    cm: 999999999,
    bg: 3,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  lamb_w3: {
    n: "lamb_w3",
    u: 9,
    cl: 0,
    cm: 999999999,
    bg: 3,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  lamb_w4: {
    n: "lamb_w4",
    u: 9,
    cl: 0,
    cm: 999999999,
    bg: 3,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  lamb_surf: {
    n: "lamb_surf",
    u: 9,
    cl: 0,
    cm: 999999999,
    bg: 3,
    md: 15,
    t: 3,
    p: 2,
    s: 1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  c_surf_b: {
    n: "c_surf_b",
    u: 9,
    cl: 0,
    cm: 999999999,
    bg: 3,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  e_wallfrac: {
    n: "e_wallfrac",
    u: 0,
    cl: 0,
    cm: 1,
    bg: 3,
    md: 15,
    t: 3,
    p: 2,
    s: 0.01,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  a_wallfrac: {
    n: "a_wallfrac",
    u: 0,
    cl: 1,
    cm: 42,
    bg: 3,
    md: 15,
    t: 3,
    p: 2,
    s: 1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/radiation_parameters#albedo_type",
  },
  z0_m_b: {
    n: "z0_m_b",
    u: 5,
    cl: 0,
    cm: 999999999,
    bg: 3,
    md: 15,
    t: 3,
    p: 2,
    s: 0.0001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  z0h_h_b: {
    n: "z0h_h_b",
    u: 5,
    cl: 0,
    cm: 999999999,
    bg: 3,
    md: 15,
    t: 3,
    p: 2,
    s: 0.0001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  d_std_w1: {
    n: "d_std_w1",
    u: 5,
    cl: 0,
    cm: 999999999,
    bg: 4,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  d_std_w2: {
    n: "d_std_w2",
    u: 5,
    cl: 0,
    cm: 999999999,
    bg: 4,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  d_std_w3: {
    n: "d_std_w3",
    u: 5,
    cl: 0,
    cm: 999999999,
    bg: 4,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  d_std_w4: {
    n: "d_std_w4",
    u: 5,
    cl: 0,
    cm: 999999999,
    bg: 4,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  c_std_1: {
    n: "c_std_1",
    u: 3,
    cl: 0,
    cm: 999999999,
    bg: 4,
    md: 15,
    t: 3,
    p: 2,
    s: 1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  c_std_2: {
    n: "c_std_2",
    u: 3,
    cl: 0,
    cm: 999999999,
    bg: 4,
    md: 15,
    t: 3,
    p: 2,
    s: 1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  c_std_3: {
    n: "c_std_3",
    u: 3,
    cl: 0,
    cm: 999999999,
    bg: 4,
    md: 15,
    t: 3,
    p: 2,
    s: 1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  c_window4: {
    n: "c_window4",
    u: 3,
    cl: 0,
    cm: 999999999,
    bg: 4,
    md: 15,
    t: 3,
    p: 2,
    s: 1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  lamb_std_1: {
    n: "lamb_std_1",
    u: 9,
    cl: 0,
    cm: 999999999,
    bg: 4,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  lamb_std_2: {
    n: "lamb_std_2",
    u: 9,
    cl: 0,
    cm: 999999999,
    bg: 4,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  lamb_std_3: {
    n: "lamb_std_3",
    u: 9,
    cl: 0,
    cm: 999999999,
    bg: 4,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  lamb_window4: {
    n: "lamb_window4",
    u: 9,
    cl: 0,
    cm: 999999999,
    bg: 4,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  e_windfrac: {
    n: "e_windfrac",
    u: 0,
    cl: 0,
    cm: 1,
    bg: 4,
    md: 15,
    t: 3,
    p: 2,
    s: 0.01,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  t_windfrac: {
    n: "t_windfrac",
    u: 0,
    cl: 0,
    cm: 1,
    bg: 4,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  a_windfrac: {
    n: "a_windfrac",
    u: 0,
    cl: 1,
    cm: 42,
    bg: 4,
    md: 15,
    t: 3,
    p: 2,
    s: 1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/radiation_parameters#albedo_type",
  },
  d_roof_1: {
    n: "d_roof_1",
    u: 5,
    cl: 0,
    cm: 999999999,
    bg: 5,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  d_roof_2: {
    n: "d_roof_2",
    u: 5,
    cl: 0,
    cm: 999999999,
    bg: 5,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  d_roof_3: {
    n: "d_roof_3",
    u: 5,
    cl: 0,
    cm: 999999999,
    bg: 5,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  d_roof_4: {
    n: "d_roof_4",
    u: 5,
    cl: 0,
    cm: 999999999,
    bg: 5,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  c_roof_1: {
    n: "c_roof_1",
    u: 3,
    cl: 0,
    cm: 999999999,
    bg: 5,
    md: 15,
    t: 3,
    p: 2,
    s: 1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  c_roof_2: {
    n: "c_roof_2",
    u: 3,
    cl: 0,
    cm: 999999999,
    bg: 5,
    md: 15,
    t: 3,
    p: 2,
    s: 1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  c_roof_3: {
    n: "c_roof_3",
    u: 3,
    cl: 0,
    cm: 999999999,
    bg: 5,
    md: 15,
    t: 3,
    p: 2,
    s: 1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  c_w4_roof: {
    n: "c_w4_roof",
    u: 3,
    cl: 0,
    cm: 999999999,
    bg: 5,
    md: 15,
    t: 3,
    p: 2,
    s: 1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  lamb_roof_1: {
    n: "lamb_roof_1",
    u: 9,
    cl: 0,
    cm: 999999999,
    bg: 5,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  lamb_roof_2: {
    n: "lamb_roof_2",
    u: 9,
    cl: 0,
    cm: 999999999,
    bg: 5,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  lamb_roof_3: {
    n: "lamb_roof_3",
    u: 9,
    cl: 0,
    cm: 999999999,
    bg: 5,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  lambda_w4_roof: {
    n: "lambda_w4_roof",
    u: 9,
    cl: 0,
    cm: 999999999,
    bg: 5,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  e_roof: {
    n: "e_roof",
    u: 0,
    cl: 0,
    cm: 1,
    bg: 5,
    md: 15,
    t: 3,
    p: 2,
    s: 0.01,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  a_roof: {
    n: "a_roof",
    u: 0,
    cl: 1,
    cm: 42,
    bg: 5,
    md: 15,
    t: 3,
    p: 2,
    s: 1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/radiation_parameters#albedo_type",
  },
  d_roof_w1: {
    n: "d_roof_w1",
    u: 5,
    cl: 0,
    cm: 999999999,
    bg: 6,
    md: 15,
    t: 3,
    p: 2,
    s: 0.01,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  d_roof_w2: {
    n: "d_roof_w2",
    u: 5,
    cl: 0,
    cm: 999999999,
    bg: 6,
    md: 15,
    t: 3,
    p: 2,
    s: 0.01,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  d_roof_w3: {
    n: "d_roof_w3",
    u: 5,
    cl: 0,
    cm: 999999999,
    bg: 6,
    md: 15,
    t: 3,
    p: 2,
    s: 0.01,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  d_roof_w4: {
    n: "d_roof_w4",
    u: 5,
    cl: 0,
    cm: 999999999,
    bg: 6,
    md: 15,
    t: 3,
    p: 2,
    s: 0.01,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  c_roof_w1: {
    n: "c_roof_w1",
    u: 3,
    cl: 0,
    cm: 999999999,
    bg: 6,
    md: 15,
    t: 3,
    p: 2,
    s: 1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  c_roof_w2: {
    n: "c_roof_w2",
    u: 3,
    cl: 0,
    cm: 999999999,
    bg: 6,
    md: 15,
    t: 3,
    p: 2,
    s: 1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  c_roof_w3: {
    n: "c_roof_w3",
    u: 3,
    cl: 0,
    cm: 999999999,
    bg: 6,
    md: 15,
    t: 3,
    p: 2,
    s: 1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  c_window4_roof: {
    n: "c_window4_roof",
    u: 3,
    cl: 0,
    cm: 999999999,
    bg: 6,
    md: 15,
    t: 3,
    p: 2,
    s: 1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  lamb_roof_w1: {
    n: "lamb_roof_w1",
    u: 9,
    cl: 0,
    cm: 999999999,
    bg: 6,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  lamb_roof_w2: {
    n: "lamb_roof_w2",
    u: 9,
    cl: 0,
    cm: 999999999,
    bg: 6,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  lamb_roof_w3: {
    n: "lamb_roof_w3",
    u: 9,
    cl: 0,
    cm: 999999999,
    bg: 6,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  lambda_window4_roof: {
    n: "lambda_window4_roof",
    u: 9,
    cl: 0,
    cm: 999999999,
    bg: 6,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  e_roof_w: {
    n: "e_roof_w",
    u: 0,
    cl: 0,
    cm: 1,
    bg: 6,
    md: 15,
    t: 3,
    p: 2,
    s: 0.01,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  t_roof_w: {
    n: "t_roof_w",
    u: 0,
    cl: 0,
    cm: 1,
    bg: 6,
    md: 15,
    t: 3,
    p: 2,
    s: 0.01,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  a_roof_w: {
    n: "a_roof_w",
    u: 0,
    cl: 1,
    cm: 42,
    bg: 6,
    md: 15,
    t: 3,
    p: 2,
    s: 1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/radiation_parameters#albedo_type",
  },
  t_roof_grsurf: {
    n: "t_roof_grsurf",
    u: 0,
    cl: 1,
    cm: 2,
    bg: 7,
    md: 15,
    t: 3,
    p: 2,
    s: 1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  LAI_ro: {
    n: "LAI_ro",
    u: 0,
    cl: 0,
    cm: 999999999,
    bg: 7,
    md: 15,
    t: 3,
    p: 2,
    s: 0.1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  a_roof_grsurf: {
    n: "a_roof_grsurf",
    u: 0,
    cl: 1,
    cm: 42,
    bg: 7,
    md: 15,
    t: 3,
    p: 2,
    s: 1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  e_roof_grsurf: {
    n: "e_roof_grsurf",
    u: 0,
    cl: 0,
    cm: 1,
    bg: 7,
    md: 15,
    t: 3,
    p: 2,
    s: 0.01,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  LAI_wa: {
    n: "LAI_wa",
    u: 0,
    cl: 0,
    cm: 999999999,
    bg: 7,
    md: 15,
    t: 3,
    p: 2,
    s: 0.1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  c_surf_green: {
    n: "c_surf_green",
    u: 3,
    cl: 0,
    cm: 999999999,
    bg: 7,
    md: 15,
    t: 3,
    p: 2,
    s: 1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  lamb_surf_green: {
    n: "lamb_surf_green",
    u: 9,
    cl: 0,
    cm: 999999999,
    bg: 7,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  e_greenfrac: {
    n: "e_greenfrac",
    u: 0,
    cl: 0,
    cm: 1,
    bg: 7,
    md: 15,
    t: 3,
    p: 2,
    s: 0.01,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  a_greenfrac: {
    n: "a_greenfrac",
    u: 0,
    cl: 1,
    cm: 42,
    bg: 7,
    md: 15,
    t: 3,
    p: 2,
    s: 1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/radiation_parameters#albedo_type",
  },
  shading_fac: {
    n: "shading_fac",
    u: 0,
    cl: 0,
    cm: 1,
    bg: 8,
    md: 15,
    t: 3,
    p: 2,
    s: 0.01,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  g_value: {
    n: "g_value",
    u: 0,
    cl: 0,
    cm: 1,
    bg: 8,
    md: 15,
    t: 3,
    p: 2,
    s: 0.01,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  u_value: {
    n: "u_value",
    u: 11,
    cl: 0,
    cm: 999999999,
    bg: 8,
    md: 15,
    t: 3,
    p: 2,
    s: 0.01,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  c_surf_wind: {
    n: "c_surf_wind",
    u: 3,
    cl: 0,
    cm: 999999999,
    bg: 8,
    md: 15,
    t: 3,
    p: 2,
    s: 1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  lamb_surf_wind: {
    n: "lamb_surf_wind",
    u: 9,
    cl: 0,
    cm: 999999999,
    bg: 8,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  targ_t_sum: {
    n: "targ_t_sum",
    u: 4,
    cl: 1,
    cm: 99,
    bg: 9,
    md: 15,
    t: 3,
    p: 2,
    s: 0.1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  targ_t_win: {
    n: "targ_t_win",
    u: 4,
    cl: 1,
    cm: 99,
    bg: 9,
    md: 15,
    t: 3,
    p: 2,
    s: 0.1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  additi_gains: {
    n: "additi_gains",
    u: 10,
    cl: 0,
    cm: 999999999,
    bg: 9,
    md: 15,
    t: 3,
    p: 2,
    s: 0.1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  basic_gains: {
    n: "basic_gains",
    u: 10,
    cl: 0,
    cm: 999999999,
    bg: 9,
    md: 15,
    t: 3,
    p: 2,
    s: 0.1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  bairflow: {
    n: "bairflow",
    u: 1,
    cl: 0,
    cm: 999999999,
    bg: 9,
    md: 15,
    t: 3,
    p: 2,
    s: 0.1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  aairflow_occ: {
    n: "aairflow_occ",
    u: 1,
    cl: 0,
    cm: 999999999,
    bg: 9,
    md: 15,
    t: 3,
    p: 2,
    s: 0.1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  heatrec_eff: {
    n: "heatrec_eff",
    u: 0,
    cl: 0,
    cm: 1,
    bg: 10,
    md: 15,
    t: 3,
    p: 2,
    s: 0.01,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  activesurf_fac: {
    n: "activesurf_fac",
    u: 0,
    cl: 0,
    cm: 999999999,
    bg: 10,
    md: 15,
    t: 3,
    p: 2,
    s: 0.1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  factor_a: {
    n: "factor_a",
    u: 3,
    cl: 0,
    cm: 999999999,
    bg: 10,
    md: 15,
    t: 3,
    p: 2,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  lambda_at: {
    n: "lambda_at",
    u: 0,
    cl: 0,
    cm: 999999999,
    bg: 10,
    md: 15,
    t: 3,
    p: 2,
    s: 0.1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  maxheat: {
    n: "maxheat",
    u: 8,
    cl: 0,
    cm: 999999999,
    bg: 10,
    md: 15,
    t: 3,
    p: 2,
    s: 0.1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  maxcool: {
    n: "maxcool",
    u: 8,
    cl: -999999999,
    cm: 0,
    bg: 10,
    md: 15,
    t: 3,
    p: 2,
    s: 0.1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  antro_heat_fac: {
    n: "antro_heat_fac",
    u: 0,
    cl: -999999999,
    cm: 999999999,
    bg: 10,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  antro_cool_fac: {
    n: "antro_cool_fac",
    u: 0,
    cl: -999999999,
    cm: 999999999,
    bg: 10,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  groundfrac_gf: {
    n: "groundfrac_gf",
    u: 0,
    cl: 0,
    cm: 999999999,
    bg: 11,
    md: 15,
    t: 3,
    p: 2,
    s: 0.01,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  windfrac_gf: {
    n: "windfrac_gf",
    u: 0,
    cl: 0,
    cm: 999999999,
    bg: 11,
    md: 15,
    t: 3,
    p: 2,
    s: 0.01,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  greenfrac_gf: {
    n: "greenfrac_gf",
    u: 0,
    cl: 0,
    cm: 999999999,
    bg: 11,
    md: 15,
    t: 3,
    p: 2,
    s: 0.01,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  greenfrac_ro_gf: {
    n: "greenfrac_ro_gf",
    u: 0,
    cl: 0,
    cm: 999999999,
    bg: 11,
    md: 15,
    t: 3,
    p: 2,
    s: 0.01,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  LAI_wall_gf: {
    n: "LAI_wall_gf",
    u: 6,
    cl: 0,
    cm: 999999999,
    bg: 11,
    md: 15,
    t: 3,
    p: 2,
    s: 0.1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  d_ff_1: {
    n: "d_ff_1",
    u: 5,
    cl: 0,
    cm: 999999999,
    bg: 11,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  d_ff_2: {
    n: "d_ff_2",
    u: 5,
    cl: 0,
    cm: 999999999,
    bg: 11,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  d_ff_3: {
    n: "d_ff_3",
    u: 5,
    cl: 0,
    cm: 999999999,
    bg: 11,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  d_ff_4: {
    n: "d_ff_4",
    u: 5,
    cl: 0,
    cm: 999999999,
    bg: 11,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  c_1_wall_gf: {
    n: "c_1_wall_gf",
    u: 3,
    cl: 0,
    cm: 999999999,
    bg: 11,
    md: 15,
    t: 3,
    p: 2,
    s: 1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  c_2_wall_gf: {
    n: "c_2_wall_gf",
    u: 3,
    cl: 0,
    cm: 999999999,
    bg: 11,
    md: 15,
    t: 3,
    p: 2,
    s: 1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  c_3_wall_gf: {
    n: "c_3_wall_gf",
    u: 3,
    cl: 0,
    cm: 999999999,
    bg: 11,
    md: 15,
    t: 3,
    p: 2,
    s: 1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  c_w4_wall_gf: {
    n: "c_w4_wall_gf",
    u: 3,
    cl: 0,
    cm: 999999999,
    bg: 11,
    md: 15,
    t: 3,
    p: 2,
    s: 1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  lamb_1_wall_gf: {
    n: "lamb_1_wall_gf",
    u: 9,
    cl: 0,
    cm: 999999999,
    bg: 11,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  lamb_2_wall_gf: {
    n: "lamb_2_wall_gf",
    u: 9,
    cl: 0,
    cm: 999999999,
    bg: 11,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  lamb_3_wall_gf: {
    n: "lamb_3_wall_gf",
    u: 9,
    cl: 0,
    cm: 999999999,
    bg: 11,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  lamb_w4_wall_gf: {
    n: "lamb_w4_wall_gf",
    u: 9,
    cl: 0,
    cm: 999999999,
    bg: 11,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  e_wallfrac_gf: {
    n: "e_wallfrac_gf",
    u: 0,
    cl: 0,
    cm: 1,
    bg: 11,
    md: 15,
    t: 3,
    p: 2,
    s: 0.01,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  e_greenfrac_gf: {
    n: "e_greenfrac_gf",
    u: 0,
    cl: 0,
    cm: 1,
    bg: 11,
    md: 15,
    t: 3,
    p: 2,
    s: 0.01,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  e_windowfrac_gf: {
    n: "e_windowfrac_gf",
    u: 0,
    cl: 0,
    cm: 1,
    bg: 11,
    md: 15,
    t: 3,
    p: 2,
    s: 0.01,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  t_windowfrac_gf: {
    n: "t_windowfrac_gf",
    u: 0,
    cl: 0,
    cm: 1,
    bg: 11,
    md: 15,
    t: 3,
    p: 2,
    s: 0.01,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  z0_m_b_gf: {
    n: "z0_m_b_gf",
    u: 5,
    cl: 0,
    cm: 999999999,
    bg: 11,
    md: 15,
    t: 3,
    p: 2,
    s: 0.0001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  z0_h_b_gf: {
    n: "z0_h_b_gf",
    u: 5,
    cl: 0,
    cm: 999999999,
    bg: 11,
    md: 15,
    t: 3,
    p: 2,
    s: 0.0001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  a_gf_w: {
    n: "a_gf_w",
    u: 0,
    cl: 1,
    cm: 42,
    bg: 11,
    md: 15,
    t: 3,
    p: 2,
    s: 1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/radiation_parameters#albedo_type",
  },
  d_ff_w1: {
    n: "d_ff_w1",
    u: 5,
    cl: 0,
    cm: 999999999,
    bg: 11,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  d_ff_w2: {
    n: "d_ff_w2",
    u: 5,
    cl: 0,
    cm: 999999999,
    bg: 11,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  d_ff_w3: {
    n: "d_ff_w3",
    u: 5,
    cl: 0,
    cm: 999999999,
    bg: 11,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  d_ff_w4: {
    n: "d_ff_w4",
    u: 5,
    cl: 0,
    cm: 999999999,
    bg: 11,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  c_ff_w1: {
    n: "c_ff_w1",
    u: 3,
    cl: 0,
    cm: 999999999,
    bg: 11,
    md: 15,
    t: 3,
    p: 2,
    s: 1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  c_ff_w2: {
    n: "c_ff_w2",
    u: 3,
    cl: 0,
    cm: 999999999,
    bg: 11,
    md: 15,
    t: 3,
    p: 2,
    s: 1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  c_ff_w3: {
    n: "c_ff_w3",
    u: 3,
    cl: 0,
    cm: 999999999,
    bg: 11,
    md: 15,
    t: 3,
    p: 2,
    s: 1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  c_window4_gf: {
    n: "c_window4_gf",
    u: 3,
    cl: 0,
    cm: 999999999,
    bg: 11,
    md: 15,
    t: 3,
    p: 2,
    s: 1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  lamb_ff_w1: {
    n: "lamb_ff_w1",
    u: 9,
    cl: 0,
    cm: 999999999,
    bg: 11,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  lamb_ff_w2: {
    n: "lamb_ff_w2",
    u: 9,
    cl: 0,
    cm: 999999999,
    bg: 11,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  lamb_ff_w3: {
    n: "lamb_ff_w3",
    u: 9,
    cl: 0,
    cm: 999999999,
    bg: 11,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  lamb_window4_gf: {
    n: "lamb_window4_gf",
    u: 9,
    cl: 0,
    cm: 999999999,
    bg: 11,
    md: 15,
    t: 3,
    p: 2,
    s: 0.001,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/tec/usm#Buildingdatabase",
  },
  a_ff_w: {
    n: "a_ff_w",
    u: 0,
    cl: 1,
    cm: 42,
    bg: 11,
    md: 15,
    t: 3,
    p: 2,
    s: 1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/radiation_parameters#albedo_type",
  },
  a_ff_grsurf: {
    n: "a_ff_grsurf",
    u: 0,
    cl: 1,
    cm: 42,
    bg: 11,
    md: 15,
    t: 3,
    p: 2,
    s: 1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/radiation_parameters#albedo_type",
  },
  t_alpha: {
    n: "t_alpha",
    u: 0,
    cl: 0,
    cm: 999999999,
    md: 15,
    t: 3,
    p: 2,
    s: 0.1,
    l: "https://palm_gui.pages.fraunhofer.de/palmgui_handbuch/rasterization.html#d-b%C3%A4ume-tree-patches-und-single-trees",
  },
  t_beta: {
    n: "t_beta",
    u: 0,
    cl: 0,
    cm: 999999999,
    md: 15,
    t: 3,
    p: 2,
    s: 0.1,
    l: "https://palm_gui.pages.fraunhofer.de/palmgui_handbuch/rasterization.html#d-b%C3%A4ume-tree-patches-und-single-trees",
  },
  th: {
    n: "th",
    u: 5,
    cl: 0,
    cm: 999999999,
    md: 15,
    t: 3,
    p: 1,
    s: 0.1,
    l: "https://palm_gui.pages.fraunhofer.de/palmgui_handbuch/rasterization.html#d-b%C3%A4ume-tree-patches-und-single-trees",
  },
  tree_shape: {
    n: "tree_shape",
    u: 0,
    cl: 1,
    cm: 6,
    md: 15,
    t: 3,
    p: 1,
    s: 1,
    l: "https://palm_gui.pages.fraunhofer.de/palmgui_handbuch/rasterization.html#d-b%C3%A4ume-tree-patches-und-single-trees",
  },
  crown_ratio: {
    n: "crown_ratio",
    u: 0,
    cl: 0,
    cm: 999999999,
    md: 15,
    t: 3,
    p: 2,
    s: 0.1,
    l: "https://palm_gui.pages.fraunhofer.de/palmgui_handbuch/rasterization.html#d-b%C3%A4ume-tree-patches-und-single-trees",
  },
  crown_dia: {
    n: "crown_dia",
    u: 5,
    cl: 0,
    cm: 999999999,
    md: 15,
    t: 3,
    p: 1,
    s: 0.1,
    l: "https://palm_gui.pages.fraunhofer.de/palmgui_handbuch/rasterization.html#d-b%C3%A4ume-tree-patches-und-single-trees",
  },
  t_lai_s: {
    n: "t_lai_s",
    u: 0,
    cl: 0,
    cm: 999999999,
    md: 15,
    t: 3,
    p: 1,
    s: 0.1,
    l: "https://palm_gui.pages.fraunhofer.de/palmgui_handbuch/rasterization.html#d-b%C3%A4ume-tree-patches-und-single-trees",
  },
  t_lai_w: {
    n: "t_lai_w",
    u: 0,
    cl: 0,
    cm: 999999999,
    md: 15,
    t: 3,
    p: 2,
    s: 0.1,
    l: "https://palm_gui.pages.fraunhofer.de/palmgui_handbuch/rasterization.html#d-b%C3%A4ume-tree-patches-und-single-trees",
  },
  t_lad_max: {
    n: "t_lad_max",
    u: 5,
    cl: 0,
    cm: 999999999,
    md: 15,
    t: 3,
    p: 2,
    s: 0.1,
    l: "https://palm_gui.pages.fraunhofer.de/palmgui_handbuch/rasterization.html#d-b%C3%A4ume-tree-patches-und-single-trees",
  },
  rat_lad_bad: {
    n: "rat_lad_bad",
    u: 0,
    cl: 0,
    cm: 1,
    md: 15,
    t: 3,
    p: 2,
    s: 0.1,
    l: "https://palm_gui.pages.fraunhofer.de/palmgui_handbuch/rasterization.html#d-b%C3%A4ume-tree-patches-und-single-trees",
  },
  trunk_dia: {
    n: "trunk_dia",
    u: 5,
    cl: 0,
    cm: 999999999,
    md: 15,
    t: 3,
    p: 2,
    s: 0.1,
    l: "https://palm_gui.pages.fraunhofer.de/palmgui_handbuch/rasterization.html#d-b%C3%A4ume-tree-patches-und-single-trees",
  },
  t_age: {
    n: "t_age",
    u: 12,
    cl: 0,
    cm: 999999999,
    md: 15,
    t: 3,
    p: 2,
    s: 1,
    l: "https://palm_gui.pages.fraunhofer.de/palmgui_handbuch/rasterization.html#d-b%C3%A4ume-tree-patches-und-single-trees",
  },
  emis_type_street: {
    n: "emis_type_street",
    u: 0,
    cl: 0,
    cm: 999999999,
    md: 15,
    t: 2,
    p: 1,
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/chememi",
  },
  ea: {
    n: "ea",
    u: 15,
    cl: 0,
    cm: 999999999,
    md: 15,
    t: 3,
    p: 1,
    s: 0.01,
    l: null,
  },
  eb: {
    n: "eb",
    u: 15,
    cl: 0,
    cm: 999999999,
    md: 15,
    t: 3,
    p: 1,
    s: 0.01,
    l: null,
  },
  emis_source_low: {
    n: "emis_source_low",
    u: 5,
    cl: 0,
    cm: 999999999,
    md: 15,
    t: 3,
    p: 1,
    s: 0.1,
    l: null,
  },
  emis_source_high: {
    n: "emis_source_high",
    u: 5,
    cl: 3,
    cm: 999999999,
    md: 15,
    t: 3,
    p: 1,
    s: 0.1,
    l: null,
  },
  emis_vdis_bot: {
    n: "emis_vdis_bot",
    u: 0,
    cl: 0.1,
    cm: 0.8,
    md: 15,
    t: 3,
    p: 2,
    s: 0.1,
    l: null,
  },
  emis_vdis_mid: {
    n: "emis_vdis_mid",
    u: 0,
    cl: 0.1,
    cm: 0.8,
    md: 15,
    t: 3,
    p: 2,
    s: 0.1,
    l: null,
  },
  emis_vdis_top: {
    n: "emis_vdis_top",
    u: 0,
    cl: 0.1,
    cm: 0.8,
    md: 15,
    t: 3,
    p: 2,
    s: 0.1,
    l: null,
  },
  ph: {
    n: "ph",
    u: 5,
    cl: 0,
    cm: 99,
    md: 15,
    t: 3,
    p: 1,
    s: 0.1,
    l: null,
  },
  name: {
    n: "name",
    u: 0,
    cl: 0,
    cm: 999999999,
    md: 15,
    t: 2,
    p: 1,
    l: null,
  },

  t: {
    n: "t",
    t: 4,
    sel: [
      ["1", "building"],
      ["2", "vegetation"],
      ["3", "pavement"],
      ["4", "water"],
      ["5", "tree patches"],
      ["6", "single tree"],
      ["7", "street"],
      ["8", "point_emission"],
      ["9", "point_of_interest"],
    ],
    l: "https://palm_gui.pages.fraunhofer.de/palmgui_handbuch/domainmap.html#sec-citymodelvisualization",
  },
  t1: {
    n: "t1",
    t: 4,
    sel: [
      [1, "Residential<1950"],
      [2, "Residential1950-2000"],
      [3, "Residential>2000"],
      [4, "Office<1950"],
      [5, "Office1950-2000"],
      [6, "Office>2000"],
    ],
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/urban_surface_parameters#building_type",
  },
  t2: {
    n: "t2",
    t: 4,
    sel: [
      [1, "bare_soil"],
      [2, "crops_mixed_farmin"],
      [3, "short_grass"],
      [4, "evergreen_needleleaf_trees"],
      [5, "deciduous_needleleaf_trees"],
      [6, "evergreen_broadleaf_trees"],
      [7, "deciduous_broadleaf_trees"],
      [8, "tall_grass"],
      [9, "desert"],
      [10, "tundra"],
      [11, "irrigated_crops"],
      [12, "semidesert"],
      [13, "not_implemented_yet"],
      [14, "bogs_and_marshes"],
      [15, "evergreen_shrubs"],
      [16, "deciduous_shrubs"],
      [17, "mixed_forest_woodland"],
      [18, "interrupted_forest"],
    ],
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/land_surface_parameters#vegetation_type",
  },
  t3: {
    n: "t3",
    t: 4,
    sel: [
      [1, "asphalt/concrete_mix"],
      [2, "asphalt_asphalt_concrete"],
      [3, "concrete_portland_concrete"],
      [4, "sett"],
      [5, "paving_stones"],
      [6, "cobblestone"],
      [7, "metal"],
      [8, "wood"],
      [9, "gravel"],
      [10, "fine_gravel"],
      [11, "pebblestone"],
      [12, "woodchips"],
      [13, "tartan_sports"],
      [14, "artificial_turf_sports"],
      [15, "clay_sports"],
    ],
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/land_surface_parameters#pavement_type",
  },
  t4: {
    n: "t4",
    t: 4,
    sel: [
      [1, "lake"],
      [2, "river"],
      [3, "ocean"],
      [4, "pond"],
      [5, "fountain"],
    ],
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/land_surface_parameters#water_type",
  },
  t5: {
    n: "t5",
    t: 4,
    sel: [
      [1, "default"],
      [2, "coniferus_forest"],
      [3, "decidous_forest"],
      [4, "mixed_forest"],
    ],
    l: "https://palm_gui.pages.fraunhofer.de/palmgui_handbuch/rasterization.html#d-b%C3%A4ume-tree-patches-und-single-trees",
  },
  t6: {
    n: "t6",
    t: 4,
    sel: [
      [1, "Default"],
      [2, "Abies"],
      [3, "Acer"],
      [4, "Aesculus"],
      [5, "Ailanthus"],
      [6, "Alnus"],
      [7, "Amelanchier"],
      [8, "Betula"],
      [9, "Buxus"],
      [10, "Calocedrus"],
      [11, "Caragana"],
      [12, "Carpinus"],
      [13, "Carya"],
      [14, "Castanea"],
      [15, "Catalpa"],
      [16, "Cedrus"],
      [17, "Celtis"],
      [18, "Cercidiphyllum"],
      [19, "Cercis"],
      [20, "Chamaecyparis"],
      [21, "Cladrastis"],
      [22, "Cornus"],
      [23, "Corylus"],
      [24, "Cotinus"],
      [25, "Crataegus"],
      [26, "Cryptomeria"],
      [27, "Cupressocyparis"],
      [28, "Cupressus"],
      [29, "Cydonia"],
      [30, "Davidia"],
      [31, "Elaeagnus"],
      [32, "Euodia"],
      [33, "Euonymus"],
      [34, "Fagus"],
      [35, "Fraxinus"],
      [36, "Ginkgo"],
      [37, "Gleditsia"],
      [38, "Gymnocladus"],
      [39, "Hippophae"],
      [40, "Ilex"],
      [41, "Juglans"],
      [42, "Juniperus"],
      [43, "Koelreuteria"],
      [44, "Laburnum"],
      [45, "Larix"],
      [46, "Ligustrum"],
      [47, "Liquidambar"],
      [48, "Liriodendron"],
      [49, "Lonicera"],
      [50, "Magnolia"],
      [51, "Malus"],
      [52, "Metasequoia"],
      [53, "Morus"],
      [54, "Ostrya"],
      [55, "Parrotia"],
      [56, "Paulownia"],
      [57, "Phellodendron"],
      [58, "Picea"],
      [59, "Pinus"],
      [60, "Platanus"],
      [61, "Populus"],
      [62, "Prunus"],
      [63, "Pseudotsuga"],
      [64, "Ptelea"],
      [65, "Pterocaria"],
      [66, "Pterocarya"],
      [67, "Pyrus"],
      [68, "Quercus"],
      [69, "Rhamnus"],
      [70, "Rhus"],
      [71, "Robinia"],
      [72, "Salix"],
      [73, "Sambucus"],
      [74, "Sasa"],
      [75, "Sequoiadendron"],
      [76, "Sophora"],
      [77, "Sorbus"],
      [78, "Syringa"],
      [79, "Tamarix"],
      [80, "Taxodium"],
      [81, "Taxus"],
      [82, "Thuja"],
      [83, "Tilia"],
      [84, "Tsuga"],
      [85, "Ulmus"],
      [86, "Zelkova"],
      [87, "Zenobia"],
    ],
    l: "https://palm_gui.pages.fraunhofer.de/palmgui_handbuch/rasterization.html#d-b%C3%A4ume-tree-patches-und-single-trees",
  },
  t7: {
    n: "t7",
    t: 4,
    sel: [
      [0, "No Emission (Overwrite Only)"],
      [1, "Unclassified"],
      [2, "Cycleway"],
      [3, "Footway / Pedestrian"],
      [4, "Path"],
      [5, "Track"],
      [6, "Living Street"],
      [7, "Service"],
      [8, "Residential"],
      [9, "Tertiary"],
      [10, "Tertiary Link"],
      [11, "Secondary"],
      [12, "Secondary Link"],
      [13, "Primary"],
      [14, "Primary Link"],
      [15, "Trunk"],
      [16, "Trunk Link"],
      [17, "Motorway"],
      [18, "Motorway Link"],
      [19, "Raceway"],
    ],
    l: "https://palm.muk.uni-hannover.de/trac/wiki/doc/app/chememi",
  },
  t8: {
    n: "t8",
    t: 4,
    sel: [
      [1, "GasFiredDomesticHeating"],
      [2, "OilFiredDomesticHeating"],
      [3, "CoalFiredDomesticHeating"],
      [4, "Wood-FiredDomesticHeating"],
      [5, "Other"],
    ],
    l: null,
  },
  t9: {
    n: "t9",
    t: 4,
    sel: [[1, "Point of interest"]],
    l: null,
  },
};
