import React from "react";
import WizardForm, {
  Step,
} from "../../../Components/Common/WizardForm/WizardForm";
import ApplicationField from "../ApplicationField/ApplicationField";
import Geodata from "../Geodata/Geodata";
import { Loader } from "../../../Components/Loader";
import CheckFilesLoader from "../NewProjectWizard/CheckFilesLoader";
import {
  checkGeojsonFile,
  checkGeotiffFile,
  resetCheckFileState,
  resetGeojsonCheckFile,
  resetGeotiffCheckFile,
} from "../../../redux/actions/checkFileActions";
import { checkExistingProjectCaseName } from "../../../redux/actions/checkNameActions";
import * as actions from "../../../redux/actions/caseActions";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import "./index.scss";

class NewCaseWizard extends React.Component {
  constructor(props) {
    super();
    this.state = {
      caseName: "",
      caseInformation: "",
      geoJson: null,
      geoTiff: null,
      staticDriver: null,
      geodataTypeIndex: 0,
      currentStep: 1,
      cloneRequest: null,
      applicationFieldId: 0,
    };
    this.onInputChange = this.onInputChange.bind(this);
    this.onGeodataTypeIndexChange = this.onGeodataTypeIndexChange.bind(this);
    this.onGeoTiffChange = this.onGeoTiffChange.bind(this);
    this.onStaticDriverChange = this.onStaticDriverChange.bind(this);
    this.onGeoJsonChange = this.onGeoJsonChange.bind(this);
    this.goNextStep = this.goNextStep.bind(this);
    this.goPreviousStep = this.goPreviousStep.bind(this);
    this.clearUploadedFile = this.clearUploadedFile.bind(this);
    this.cloneRequestChange = this.cloneRequestChange.bind(this);
    this.initNewCaseCreation = this.initNewCaseCreation.bind(this);
    this.getCaseToCreate = this.getCaseToCreate.bind(this);
  }

  componentDidMount() {
    this.props.resetCreateCase();
    this.props.resetCheckFileState();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.createdCaseId !== this.props.createdCaseId &&
      this.props.createdCaseId
    ) {
      this.props.history.push(
        `/projects/${this.props.loadedProject.id}/cases/${this.props.createdCaseId}`
      );
    }
    if (
      this.props.isSaving ||
      !this.props.isCreating ||
      this.state.geodataTypeIndex !== 0
    ) {
      return;
    }
    if (
      this.state.geoJson &&
      !this.props.geojsonCheckInProgress &&
      !this.props.geojsonUploadInProgress &&
      !this.props.uploadedGeojsonUrl &&
      !this.props.geojsonReady
    ) {
      this.props.checkGeojson({
        geojson: this.state.geoJson,
        geojsonUrl: `${new Date().getTime().toString()}/${
          this.props.loadedProject.name
        }/${this.state.geoJson.name}`,
      });
    }
    if (
      this.state.geoTiff &&
      this.props.geojsonReady &&
      !this.props.topographyReady &&
      !this.props.topographyUploadInProgress &&
      !this.props.topoConversionInProgress &&
      !this.props.uploadedTopographyUrl
    ) {
      this.props.convertTopography({
        topography: this.state.geoTiff,
        topographyUrl: `${new Date().getTime().toString()}/${
          this.props.loadedProject.name
        }/${this.state.geoTiff.name}`,
      });
    }

    if (
      !this.props.isSaving &&
      this.props.geojsonReady &&
      this.props.topographyReady
    ) {
      this.createCase();
    }
  }

  initNewCaseCreation() {
    if (this.state.geodataTypeIndex === 0) {
      this.props.initCreateCase({
        geojson: this.state.geoJson,
        geoTiff: this.state.geoTiff,
      });
    } else {
      this.createCase();
    }
    this.props.resetCheckFileState();
  }

  createCase() {
    const caseToCreate = this.getCaseToCreate();
    if (this.state.cloneRequest) {
      this.props.cloneCase(caseToCreate);
    } else {
      this.props.createCase(caseToCreate);
    }
  }

  getCaseToCreate() {
    let caseToCreate = null;
    if (this.state.cloneRequest) {
      caseToCreate = {
        ...this.state.cloneRequest,
        projectId: this.props.loadedProject.id,
        name: this.state.caseName,
        description: this.state.caseInformation,
      };
    } else {
      caseToCreate = {
        projectId: this.props.loadedProject.id,
        name: this.state.caseName,
        description: this.state.caseInformation,
        applicationField: this.state.applicationFieldId,
        type: this.state.geodataTypeIndex,
      };
    }

    switch (this.state.geodataTypeIndex) {
      case 0: {
        caseToCreate.originalGeojsonUrl = this.props.uploadedGeojsonUrl;
        caseToCreate.verifiedGeojsonUrl = this.props.geojsonCheckResultUrl;
        caseToCreate.originalTopographyUrl = this.props.uploadedTopographyUrl;
        caseToCreate.topographyUrl = this.props.topoConversionResultUrl;
        break;
      }
      case 1: {
        if (!this.state.cloneRequest) {
          caseToCreate.staticDriver = this.state.staticDriver;
        }
        break;
      }
      default:
        break;
    }
    return caseToCreate;
  }

  onInputChange(e) {
    const { name, value } = e.target;
    if (name === "caseName") {
      this.props.checkExistingProjectCaseName({
        caseName: value,
        caseList: this.props.loadedProject.caseDetailsDto,
      });
    }
    this.setState({
      [name]: value,
    });
  }

  clearUploadedFile(fieldName) {
    if (fieldName === "geoTiff") {
      this.props.resetGeotiffCheckFile();
    } else if (fieldName === "geoJson") {
      this.props.resetGeojsonCheckFile();
    }
    this.setState({
      [fieldName]: null,
    });
  }

  onGeodataTypeIndexChange(geodataTypeIndex) {
    this.setState({ geodataTypeIndex });
  }

  onGeoTiffChange(file) {
    this.props.checkGeotiffFile(file);
    if (file === undefined) {
      return;
    } else if (file.type === "image/tiff") {
      this.setState({ geoTiff: file });
    } else {
      this.setState({
        geoTiff: {
          name: file.name,
          size: null,
        },
      });
    }
  }

  onStaticDriverChange(file) {
    if (!file) {
      return;
    } else if (file.name.endsWith(".nc")) {
      this.setState({ staticDriver: file });
    }
  }

  async onGeoJsonChange(file) {
    const geojsonString = await file.text();
    if (
      file.type === "application/json" ||
      file.name.substr(-7) === "geojson"
    ) {
      const geojsonParsed = JSON.parse(geojsonString);
      const epsg = geojsonParsed.crs.properties.name?.split(":").pop();

      if (epsg !== undefined && epsg === this.props.loadedProject.epsg) {
        this.props.checkGeojsonFile({ geojson: file, epsg: epsg });
      } else if (epsg !== undefined && epsg !== this.props.loadedProject.epsg) {
        this.props.checkGeojsonFile({
          geojson: file,
          epsg: "WrongNewCaseGeojsonEpsg",
        });
      } else {
        this.props.checkGeojsonFile({ geojson: file, epsg: "" });
      }
    } else {
      this.props.checkGeojsonFile({ geojson: file, epsg: "" });
    }

    if (file === undefined) {
      return;
    } else if (
      file.type === "application/json" ||
      file.type === "" ||
      file.type === "application/geo+json"
    ) {
      this.setState({ geoJson: file });
    } else {
      this.setState({
        geoJson: {
          name: file.name,
          size: null,
        },
      });
    }
  }

  cloneRequestChange(request) {
    this.setState({ cloneRequest: request });
  }

  goNextStep() {
    this.setState({ currentStep: this.state.currentStep + 1 });
  }

  goPreviousStep() {
    this.setState({ currentStep: this.state.currentStep - 1 });
  }

  render() {
    const { t } = this.props;

    if (this.props.isCreating) {
      const steps = [];
      if (this.state.geoJson?.name) {
        steps.push({
          text: t("UploadingGeojson"),
          active: this.props.geojsonUploadInProgress,
        });
        steps.push({
          text: t("GeojsonCheckInProgress"),
          active: this.props.geojsonCheckInProgress,
        });
      }
      if (this.state.geoTiff?.name) {
        steps.push({
          text: t("UploadingTopography"),
          active: this.props.topographyUploadInProgress,
        });
        steps.push({
          text: t("TopoConvInProgress"),
          active: this.props.topoConversionInProgress,
        });
      }
      return steps.length > 0 ? <CheckFilesLoader steps={steps} /> : <Loader />;
    }

    return (
      <>
        <WizardForm>
          <Step
            isActive={this.state.currentStep === 1}
            text={t("CreateCase")}
            currentStep={this.state.currentStep}
          ></Step>
          <Step
            isActive={this.state.currentStep === 2}
            text={t("UploadData")}
            currentStep={this.state.currentStep}
          ></Step>
        </WizardForm>
        {this.state.currentStep === 1 && (
          <ApplicationField
            caseName={this.state.caseName}
            caseInformation={this.state.caseInformation}
            onInputChange={this.onInputChange}
            goNextStep={this.goNextStep}
            applicationFieldId={this.state.applicationFieldId}
          />
        )}
        {this.state.currentStep === 2 && (
          <Geodata
            applicationField={this.state.applicationFieldId}
            loadedProject={this.props.loadedProject}
            submit={this.initNewCaseCreation}
            geoJson={this.state.geoJson}
            geoTiff={this.state.geoTiff}
            staticDriver={this.state.staticDriver}
            geojsonErrorMessage={t(this.props.checkFile.geojsonErrorMessage)}
            geotiffErrorMessage={t(this.props.checkFile.geotiffErrorMessage)}
            geojsonName={this.props.checkFile.geojsonName}
            geotiffName={this.props.checkFile.geotiffName}
            onGeoJsonChange={this.onGeoJsonChange}
            onGeoTiffChange={this.onGeoTiffChange}
            onStaticDriverChange={this.onStaticDriverChange}
            onGeodataTypeIndexChange={this.onGeodataTypeIndexChange}
            goPreviousStep={this.goPreviousStep}
            clearUploadedFile={this.clearUploadedFile}
            cloneRequestChange={this.cloneRequestChange}
            errors={this.props.errors}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loadedProject: state.projects.loadedProject,
    isSaving: state.newCaseWizard.isSaving,
    createdCaseId: state.newCaseWizard.createdCaseId,
    isCreating: state.newCaseWizard.isCreating,
    geojsonCheckResultUrl: state.newCaseWizard.geojsonCheckResultUrl,
    geojsonCheckInProgress: state.newCaseWizard.geojsonCheckInProgress,
    topoConversionInProgress: state.newCaseWizard.topoConversionInProgress,
    topoConversionResultUrl: state.newCaseWizard.topoConversionResultUrl,
    geojsonUploadInProgress: state.newCaseWizard.geojsonUploadInProgress,
    topographyUploadInProgress: state.newCaseWizard.topographyUploadInProgress,
    topographyReady: state.newCaseWizard.topographyReady,
    geojsonReady: state.newCaseWizard.geojsonReady,
    uploadedGeojsonUrl: state.newCaseWizard.uploadedGeojsonUrl,
    uploadedTopographyUrl: state.newCaseWizard.uploadedTopographyUrl,
    errors: state.newCaseWizard.errors,
    checkFile: state.checkFile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initCreateCase: (data) => dispatch(actions.initCreateCase(data)),
    createCase: (data) => dispatch(actions.createCase(data)),
    cloneCase: (request) => dispatch(actions.cloneCase(request)),
    checkGeojson: (geojson) => dispatch(actions.checkGeojson(geojson)),
    convertTopography: (topo) => dispatch(actions.convertTopography(topo)),
    resetCreateCase: () => dispatch(actions.resetCreateCase()),
    checkGeojsonFile: (payload) => dispatch(checkGeojsonFile(payload)),
    checkGeotiffFile: (payload) => dispatch(checkGeotiffFile(payload)),
    resetGeojsonCheckFile: () => dispatch(resetGeojsonCheckFile()),
    resetGeotiffCheckFile: () => dispatch(resetGeotiffCheckFile()),
    resetCheckFileState: () => dispatch(resetCheckFileState()),
    checkExistingProjectCaseName: (payload) =>
      dispatch(checkExistingProjectCaseName(payload)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(NewCaseWizard))
);
