import React from "react";
import Select from "react-select";
import { crsGroupedOptions } from "./osmConst";
import { setOsmCrs } from "../../../redux/actions/toolsActions";
import { useSelector, useDispatch } from "react-redux";

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  paddingBottom: "12px",
  color: "rgb(45, 58, 81)",
  borderBottom: ".5px solid rgba(45, 58, 81,.5)",
  marginBottom: "12px",
};
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};

const formatGroupLabel = (crsGroupedOptions) => {
  return (
    <div style={groupStyles}>
      <span>{crsGroupedOptions.label}</span>
      <span style={groupBadgeStyles}>{crsGroupedOptions.options.length}</span>
    </div>
  );
};

function CrsSelect(props) {
  const dispatch = useDispatch();
  const osmCrs = useSelector((state) => state.tools.osmCrs);

  return (
    <Select
      value={osmCrs ? osmCrs : crsGroupedOptions[0].options[0]}
      options={crsGroupedOptions}
      formatGroupLabel={formatGroupLabel}
      styles={props.newProjectWizard ? newProjStyle : styles}
      onChange={(e) => dispatch(setOsmCrs(e))}
      menuPlacement={props.menuPlacement}
      components={
        props.newProjectWizard && {
          IndicatorSeparator: () => null,
        }
      }
    />
  );
}

export default CrsSelect;

const styles = {
  valueContainer: (provided) => ({
    ...provided,
    width: "190px",
    minHeight: "28px",
    height: 28,
    fontSize: "11px",
    fontFamily: "Segoe UI",
    fontWeight: 600,
  }),
  control: (base, state) => ({
    ...base,
    width: "100%",
    minHeight: 28,
    height: 28,
    border: "1px solid #a2a2a2",
    borderRadius: "2px",
    outline: state.isFocused ? "0px solid white" : "0px solid white",
    boxShadow: state.isFocused ? "none" : "none",
    "&:hover": {
      boxShadow: "none",
    },
  }),
  container: (provided) => ({
    ...provided,
    width: "190px",
    minHeight: 28,
    height: 28,
    fontFamily: "Segoe UI",
  }),
  singleValue: (provided) => ({
    ...provided,
    paddingBottom: 1,
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    minHeight: 28,
    height: 28,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 3,
    minHeight: 28,
    height: 28,
  }),
  option: (provided) => {
    return {
      ...provided,
      fontFamily: "Segoe UI",
      fontSize: "12px",
    };
  },
};

const newProjStyle = {
  valueContainer: (provided) => ({
    ...provided,
    width: 300,
    minHeight: 31,
    height: 31,
    fontSize: 12,
    fontFamily: "Segoe UI",
  }),
  control: (base, state) => ({
    ...base,
    width: 300,
    minHeight: 31,
    height: 31,
    border: "1px solid #a2a2a2",
    borderRadius: "2px",
    outline: state.isFocused ? "0px solid white" : "0px solid white",
    boxShadow: state.isFocused ? "none" : "none",
    "&:hover": {
      boxShadow: "none",
    },
  }),
  container: (provided) => ({
    ...provided,
    width: 300,
    minHeight: 31,
    height: 31,
    fontFamily: "Segoe UI",
  }),
  singleValue: (provided) => ({
    ...provided,
    paddingBottom: 1,
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    minHeight: 31,
    height: 31,
  }),
  // clearIndicator: (provided) => ({
  //   ...provided,
  //   padding: "3px",
  // }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 3,
    svg: {
      width: 15,
      height: 15,
      color: "#495057",
    },
  }),
  option: (provided, { isSelected }) => {
    return {
      ...provided,
      fontFamily: "Segoe UI",
      fontSize: 12,
      color: isSelected ? "#fff" : "hsl(0, 0%, 20%)",
    };
  },
};
