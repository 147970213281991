import React, { useEffect } from "react";
import Carousel from "./Carousel/Carousel";
import StaticDriverContainer from "./StaticDriver/StaticDriverContainer";
import Report from "./Report/Report";
import CreatePageMenu from "./CreatePageMenu/CreatePageMenu";
import { Loader } from "../../Components/Loader/index";
import {
  loadResults,
  loadLayerSettingsJson,
  setResultsCrsDef,
  exitResults,
} from "../../redux/actions/resultsActions";
import { downloadBlobAsync } from "../../api/storageApi";
import { useSelector, useDispatch } from "react-redux";
import "./Results.scss";

const Results = () => {
  const dispatch = useDispatch();

  const currentViewIndex = useSelector(
    (state) => state.results.currentViewIndex
  );
  const loadedProject = useSelector((state) => state.projects.loadedProject);
  const resultsJsonCarouselOptions = useSelector(
    (state) => state.results.resultsJsonCarouselOptions
  );
  const resultsJsonOrigin = useSelector(
    (state) => state.results.resultsJsonOrigin
  );
  const currentCase = useSelector((state) => state.projects.currentCase);

  useEffect(() => {
    return () => {
      dispatch(exitResults());
    };
  }, []);

  useEffect(() => {
    if (currentCase) {
      dispatch(setResultsCrsDef(loadedProject.epsg));
    }
    if (
      !currentCase ||
      currentCase?.postProcessingJobs.length === 0 ||
      resultsJsonOrigin
    )
      return;

    const resultsJsonUrl = currentCase.postProcessingJobs.find((x) => {
      return x.reportJsonUrl;
    }).reportJsonUrl;

    const layerSettingsJsonUrl = currentCase.postProcessingJobs.find((x) => {
      return x.palmOutputJsonUrl;
    }).palmOutputJsonUrl;

    const fetchData = async () => {
      const resultsJson = await downloadBlobAsync(resultsJsonUrl);
      const layerSettingsJson = await downloadBlobAsync(layerSettingsJsonUrl);
      dispatch(loadResults(resultsJson));
      dispatch(loadLayerSettingsJson(layerSettingsJson));
    };
    fetchData();
  }, [currentCase]);

  return (
    <>
      {!resultsJsonOrigin ? (
        <Loader />
      ) : (
        <div className="results-wrapper">
          <Carousel />
          <div className="results-content-wrapper">
            {resultsJsonCarouselOptions.length &&
            resultsJsonCarouselOptions[currentViewIndex] === "Report" ? (
              <div className="report-container">
                <Report />
              </div>
            ) : resultsJsonCarouselOptions[currentViewIndex] ===
              "CreatePage" ? (
              <div className={`create-page-container`}>
                <CreatePageMenu />
              </div>
            ) : (
              <div
                className={`results-content-container  results-content-map-container`}
              >
                <StaticDriverContainer />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Results;
