import { types } from "../types/tools";
import { cloneDeep } from "lodash";

const osmAreaInitialState = {
  isDrawingExtent: false,
  isExtentDrawn: false,
  extentCoords: [
    [52.475, 13.42],
    [52.475, 13.42],
  ],
  inDrawExtentMode: false,
  isAreaValid: false,
  area: null,
  isCrsValid: false,
  requestExtentCoords: null,
  osmNotification: null,
};

const initialState = {
  inOsmDownloadMapView: false,
  osmCrs: {
    label: "EPSG:25832 | ETRS89 / UTM zone 32N",
    crs: "ETRS89 / UTM zone 32N",
    value: "EPSG:25832",
    bnd: [
      [6, 32.88],
      [12, 84.73],
    ],
    def: "+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    src: "https://epsg.io/25832",
    cmt: "GUI-Check Bounds derived from WGS84-Bounds and Area of Use from www.epsg.io",
  },
  objectsToRequest: [
    {
      name: "Building",
      value: true,
    },
    {
      name: "Vegetation",
      value: true,
    },
    {
      name: "Pavement",
      value: true,
    },
    {
      name: "Water",
      value: true,
    },
    {
      name: "TreePatch",
      value: true,
    },
    {
      name: "Treesingle",
      value: true,
    },
    {
      name: "StreetTypes",
      value: false,
    },
  ],
  bldMethod: 0,
  bldStorey: 3,
  bldFillVal: 20,
  trMethod: 0,
  trFillValPatches: 12,
  trFillValSingle: 12,
  rwMethod: 0,
  rwLanes: [4, 9, 12, 16, 20, 24],
  ...osmAreaInitialState,
};

const toolsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_TOOLS: {
      return {
        ...state,
        ...osmAreaInitialState,
        inOsmDownloadMapView: !state.inOsmDownloadMapView,
      };
    }
    case types.TOGGLE_DRAW_EXTENT: {
      return {
        ...state,
        inDrawExtentMode: !state.inDrawExtentMode,
        isDrawingExtent: false,
      };
    }
    case types.DELETE_AREA: {
      return {
        ...state,
        ...osmAreaInitialState,
        inDrawExtentMode: true,
      };
    }
    case types.SET_OSM_CRS: {
      return {
        ...state,
        osmCrs: action.payload,
      };
    }
    case types.TOGGLE_OBJECT_TO_REQUEST: {
      const indexOfObject = action.payload;

      const objectsToRequest = cloneDeep(state.objectsToRequest);
      objectsToRequest[indexOfObject].value =
        !objectsToRequest[indexOfObject].value;

      return {
        ...state,
        objectsToRequest: objectsToRequest,
      };
    }

    case types.SET_BLDG_METHOD: {
      return {
        ...state,
        bldMethod: action.payload,
      };
    }
    case types.SET_BLDG_STOREY_HEIGHT: {
      return {
        ...state,
        bldStorey: action.payload,
      };
    }
    case types.SET_BLDG_FILL_VALUE: {
      return {
        ...state,
        bldFillVal: action.payload,
      };
    }
    case types.SET_TREE_METHOD: {
      return {
        ...state,
        trMethod: action.payload,
      };
    }
    case types.SET_TREE_PATCH_MISS_VALUE: {
      return {
        ...state,
        trFillValPatches: action.payload,
      };
    }
    case types.SET_TREE_MISS_VALUE: {
      return {
        ...state,
        trFillValSingle: action.payload,
      };
    }
    case types.SET_ROAD_WIDTH_METHOD: {
      return {
        ...state,
        rwMethod: action.payload,
      };
    }
    case types.SET_ROAD_WIDTH_LANES: {
      const rwLanes = cloneDeep(state.rwLanes);
      rwLanes[action.payload.index] = action.payload.value;
      return {
        ...state,
        rwLanes: rwLanes,
      };
    }
    case types.START_AREA: {
      const pointCoords = [action.payload.lat, action.payload.lng];
      return {
        ...state,
        isExtentDrawn: false,
        isDrawingExtent: true,
        extentCoords: [pointCoords, pointCoords],
      };
    }
    case types.GET_OSM_CURSOR_COORDS: {
      const extentCoords = cloneDeep(state.extentCoords);
      extentCoords[1] = action.payload;
      return {
        ...state,
        extentCoords: extentCoords,
      };
    }
    case types.FINISH_AREA: {
      const pointCoords = [action.payload.lat, action.payload.lng];
      const area = (action.payload.area / 1000000).toFixed(3);
      const extentCoords = state.extentCoords;
      let isAreaValid = false;
      extentCoords[1] = pointCoords;

      if (area < 50 && area > 0.0) {
        isAreaValid = true;
      }

      return {
        ...state,
        extentCoords: extentCoords,
        requestExtentCoords: extentCoords,
        isDrawingExtent: false,
        isExtentDrawn: true,
        isAreaValid: isAreaValid,
        area: area,
        isCrsValid: action.payload.isPointInside,
      };
    }
    case types.ADD_OSM_NOTIFICATION: {
      return {
        ...state,
        osmNotification: action.payload,
      };
    }
    default:
      return state;
  }
};

export default toolsReducer;
