import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
  ExpertTempAccordion,
  ExpertTimeAccordion,
} from "../../ExpertUtils/ExpertComponent";
import {
  ExpertHelpLabel,
  ExpertInput,
  ExpertVectorInput,
} from "../../ExpertUtils/ExpertComponent";
import ExpertVectorTable from "../../ExpertUtils/ExpertVectorTable";
import { FormSettingsSeparator } from "../../../../Components/Common/Form/FormSeparator/index";
import { helpLink } from "../../expertConst";
import { allCrsOptions } from "../../../Tools/OsmDownload/osmConst";
import { reprojectUtmToWgPoint } from "../../../../common/utmDef";
import {
  changeExpertInput,
  changeExpertDropdownInput,
} from "../../../../redux/actions/expertActions";
import { selectSetupStyle } from "../../../../Components/Common/PaletteDropdown/index";
import { useSelector, useDispatch } from "react-redux";

const [page, subpage] = ["initialization", "template_dynamic_upload"];
const boundaryCounditionsOptions = [
  { label: "neumann", value: "neumann" },
  { label: "dirichlet", value: "dirichlet" },
];
const DynamicUpload = () => {
  const dynamic_upload = useSelector(
    (state) => state.expert.expertJson.initialization.template_dynamic_upload
  );
  const files = useSelector((state) => state.projects?.loadedProject?.files);

  const [centerWg, setCenterWg] = useState({ lat: 0, lng: 0 });
  const [filesOptions, setFilesOptions] = useState([]);

  const crs = useSelector((state) => state.projects?.loadedProject?.epsg);
  const rasterParameters = useSelector(
    (state) => state.projects?.currentCase?.rasterParameters
  );

  useEffect(() => {
    if (rasterParameters) {
      const crsDef = allCrsOptions.find((x) => x.value === `EPSG:${crs}`).def;
      const utmLng = (rasterParameters.x_max + rasterParameters.x_min) / 2;
      const utmLat = (rasterParameters.y_max + rasterParameters.y_min) / 2;
      let centerWg = reprojectUtmToWgPoint([utmLng, utmLat], crsDef);
      centerWg = { lng: centerWg.lng.toFixed(3), lat: centerWg.lat.toFixed(3) };
      setCenterWg(centerWg);
    }
    const ncFiles = files.filter((x) => {
      return x.type === "";
    });
    ncFiles.push({ name: "no files selected" });
    setFilesOptions(ncFiles);
  }, []);
  const dispatch = useDispatch();

  return (
    <>
      <ExpertHelpLabel label={"Activate Module"} />
      <Select
        isSearchable={false}
        styles={selectSetupStyle}
        options={[
          {
            value: "True",
            label: "True",
          },
        ]}
        value={{
          label: "True",
          value: "True",
        }}
        isDisabled
      />
      <div style={{ marginTop: 12 }} />

      <ExpertHelpLabel label={"Select Uploaded Dynamic Driver"} />
      <Select
        isSearchable={false}
        styles={selectSetupStyle}
        options={filesOptions?.map((x) => {
          return {
            label: x.name,
            value: x.name,
          };
        })}
        value={{
          label: dynamic_upload.name_dynamic_driver,
          value: dynamic_upload.name_dynamic_driver,
        }}
        isDisabled={!files?.some((x) => x.type === "")}
        onChange={(e) =>
          dispatch(
            changeExpertInput({
              page: page,
              subpage: subpage,
              key: "",
              subkey: "name_dynamic_driver",
              value: e.value,
            })
          )
        }
      />

      {dynamic_upload.isActive && (
        <>
          <FormSettingsSeparator style={{ fontSize: "17px" }} label={"Mode"} />
          <ExpertHelpLabel
            label={"Initializing actions"}
            helpLink={`${helpLink}/initialization_parameters#initializing_actions`}
          />
          <Select
            isSearchable={false}
            styles={selectSetupStyle}
            options={[
              {
                value: "inifor",
                label: "inifor",
              },
              {
                value: "set_constant_profiles",
                label: "set_constant_profiles",
              },
            ]}
            value={{
              label: dynamic_upload.mode.initializing_actions,
              value: dynamic_upload.mode.initializing_actions,
            }}
            onChange={(e) =>
              dispatch(
                changeExpertInput({
                  page: page,
                  subpage: subpage,
                  key: "mode",
                  subkey: "initializing_actions",
                  value: e.value,
                })
              )
            }
          />
          <div style={{ marginTop: 12 }} />

          <FormSettingsSeparator
            style={{ fontSize: "17px" }}
            label={"Initialization"}
          />
          <ExpertHelpLabel
            label={"No thermal effects"}
            helpLink={`${helpLink}/initialization_parameters#neutral`}
          />
          <Select
            isSearchable={false}
            styles={selectSetupStyle}
            options={[
              {
                value: "True",
                label: "True",
              },
              { value: "False", label: "False" },
            ]}
            value={{
              label: !!dynamic_upload.boundary_conditions.neutral
                ? "True"
                : "False",
              value: !!dynamic_upload.boundary_conditions.neutral
                ? "True"
                : "False",
            }}
            onChange={(e) =>
              dispatch(
                changeExpertDropdownInput({
                  page: page,
                  subpage: subpage,
                  key: "boundary_conditions",
                  subkey: "neutral",
                  value: e.value,
                })
              )
            }
          />
          <div style={{ marginBottom: 12 }} />

          {!dynamic_upload.boundary_conditions.neutral && (
            <>
              <ExpertHelpLabel
                label={"Initial potential Temperature at surface"}
                helpLink={`${helpLink}/initialization_parameters#pt_surface`}
              />
              <ExpertTempAccordion
                page={page}
                subpage={subpage}
                section="initialization"
                subkey="pt_surface"
                min={240}
                max={350}
                step={0.01}
                constValue={dynamic_upload.initialization.pt_surface}
              />
              <div style={{ marginBottom: 12 }} />
            </>
          )}
          <ExpertHelpLabel
            label={"Include moisture calculation"}
            helpLink={`${helpLink}/initialization_parameters#humidity`}
          />
          <Select
            isSearchable={false}
            styles={selectSetupStyle}
            options={[
              {
                value: "True",
                label: "True",
              },
              { value: "False", label: "False" },
            ]}
            value={{
              label: !!dynamic_upload.initialization.humidity
                ? "True"
                : "False",
              value: !!dynamic_upload.initialization.humidity
                ? "True"
                : "False",
            }}
            onChange={(e) =>
              dispatch(
                changeExpertDropdownInput({
                  page: page,
                  subpage: subpage,
                  key: "initialization",
                  subkey: "humidity",
                  value: e.value,
                })
              )
            }
          />
          <div style={{ marginBottom: 12 }} />

          {dynamic_upload.initialization.humidity && (
            <>
              <ExpertHelpLabel
                label={"Initial mixing ratio at surface"}
                helpLink={`${helpLink}/initialization_parameters#q_surface`}
              />
              <ExpertInput
                page={page}
                subpage={subpage}
                section="initialization"
                subkey="q_surface"
                min={0}
                max={0.05}
                step={0.000001}
                constValue={dynamic_upload.initialization.q_surface}
              />

              <ExpertHelpLabel
                label={"Check for supersaturation in initial setup"}
                helpLink={`${helpLink}/initialization_parameters#check_realistic_q`}
              />
              <Select
                isSearchable={false}
                styles={selectSetupStyle}
                options={[
                  {
                    value: "True",
                    label: "True",
                  },
                  { value: "False", label: "False" },
                ]}
                value={{
                  label: !!dynamic_upload.initialization.check_realistic_q
                    ? "True"
                    : "False",
                  value: !!dynamic_upload.initialization.check_realistic_q
                    ? "True"
                    : "False",
                }}
                onChange={(e) =>
                  dispatch(
                    changeExpertDropdownInput({
                      page: page,
                      subpage: subpage,
                      key: "initialization",
                      subkey: "check_realistic_q",
                      value: e.value,
                    })
                  )
                }
              />
              <div style={{ marginBottom: 12 }} />

              <ExpertHelpLabel
                label={"Calculate surface moisture during spinup"}
                helpLink={`${helpLink}/initialization_parameters#calc_soil_moisture_during_spinup`}
              />
              <Select
                isSearchable={false}
                styles={selectSetupStyle}
                options={[
                  {
                    value: "True",
                    label: "True",
                  },
                  { value: "False", label: "False" },
                ]}
                value={{
                  label: !!dynamic_upload.initialization
                    .calc_soil_moisture_during_spinup
                    ? "True"
                    : "False",
                  value: !!dynamic_upload.initialization
                    .calc_soil_moisture_during_spinup
                    ? "True"
                    : "False",
                }}
                onChange={(e) =>
                  dispatch(
                    changeExpertDropdownInput({
                      page: page,
                      subpage: subpage,
                      key: "initialization",
                      subkey: "calc_soil_moisture_during_spinup",
                      value: e.value,
                    })
                  )
                }
              />
              <div style={{ marginBottom: 12 }} />
            </>
          )}
          <ExpertHelpLabel label={"Use geostrophic wind"} />
          <Select
            isSearchable={false}
            styles={selectSetupStyle}
            options={[
              {
                value: "True",
                label: "True",
              },
              { value: "False", label: "False" },
            ]}
            value={{
              label: !!dynamic_upload.initialization.use_geostrophic_wind
                ? "True"
                : "False",
              value: !!dynamic_upload.initialization.use_geostrophic_wind
                ? "True"
                : "False",
            }}
            onChange={(e) =>
              dispatch(
                changeExpertDropdownInput({
                  page: page,
                  subpage: subpage,
                  key: "initialization",
                  subkey: "use_geostrophic_wind",
                  value: e.value,
                })
              )
            }
          />
          <div style={{ marginBottom: 12 }} />

          {dynamic_upload.initialization.use_geostrophic_wind &&
            dynamic_upload.mode.initializing_actions ===
              "set_constant_profiles" && (
              <>
                <ExpertHelpLabel
                  label={
                    "Geostrophic wind at surface in x direction (west-east)"
                  }
                  helpLink={`${helpLink}/initialization_parameters#ug_surface`}
                />
                <ExpertInput
                  page={page}
                  subpage={subpage}
                  section="initialization"
                  subkey="ug_surface"
                  min={-20}
                  max={20}
                  step={0.01}
                  constValue={dynamic_upload.initialization.ug_surface}
                />
                <ExpertHelpLabel
                  label={
                    "Geostrophic wind at surface in y direction (south-north)"
                  }
                  helpLink={`${helpLink}/initialization_parameters#vg_surface`}
                />
                <ExpertInput
                  page={page}
                  subpage={subpage}
                  section="initialization"
                  subkey="vg_surface"
                  min={-20}
                  max={20}
                  step={0.01}
                  constValue={dynamic_upload.initialization.vg_surface}
                />
              </>
            )}

          <ExpertHelpLabel
            label={"Surface spinup duration [s]"}
            helpLink={`${helpLink}/initialization_parameters#spinup_time`}
          />
          <ExpertTimeAccordion
            page={page}
            subpage={subpage}
            section="initialization"
            subkey="spinup_time"
            min={0}
            max={172800}
            constValue={dynamic_upload.initialization.spinup_time}
          />
          <div style={{ marginBottom: 12 }} />

          {dynamic_upload.initialization.spinup_time > 0 && (
            <>
              <ExpertHelpLabel
                label={"Timestep for surface spinup"}
                helpLink={`${helpLink}/initialization_parameters#dt_spinup`}
              />
              <ExpertInput
                page={page}
                subpage={subpage}
                section="initialization"
                subkey="dt_spinup"
                min={1}
                max={60}
                step={0.01}
                constValue={dynamic_upload.initialization.dt_spinup}
              />
              <ExpertHelpLabel
                label={
                  "Amplitude of near surface air temperature for surface spinup"
                }
                helpLink={`${helpLink}/initialization_parameters#spinup_pt_amplitude`}
              />
              <ExpertInput
                page={page}
                subpage={subpage}
                section="initialization"
                subkey="spinup_pt_amplitude"
                min={0}
                max={30}
                step={0.1}
                constValue={dynamic_upload.initialization.spinup_pt_amplitude}
              />
              <ExpertHelpLabel
                label={
                  "Mean value of near surface air temperature for surface spinup [K]"
                }
                helpLink={`${helpLink}/initialization_parameters#spinup_pt_mean`}
              />
              <ExpertTempAccordion
                page={page}
                subpage={subpage}
                section="initialization"
                subkey="spinup_pt_mean"
                min={240}
                max={350}
                step={0.01}
                constValue={dynamic_upload.initialization.spinup_pt_mean}
              />
            </>
          )}

          <ExpertHelpLabel
            label={"Temperature Gradient: Number of Height Levels [-]"}
            helpLink={`${helpLink}/initialization_parameters#pt_vertical_gradient`}
          />
          <ExpertVectorInput
            template={subpage}
            mainKey={"pt_vertical_lvlsnbr"}
            subkey={"pt_vertical"}
            firstRowKey={"pt_vertical_gradiant"}
            secondRowKey={"pt_vertical_gradiant_level"}
            maxRowsCounter={10}
            constValue={dynamic_upload.initialization.pt_vertical_lvlsnbr}
          />
          <ExpertVectorTable
            columnDefs={[
              {
                headerName:
                  "Height from which temperature gradiant is acting [m]",
                templateName: subpage,
                parentField: "pt_vertical",
                field: "pt_vertical_gradiant_level",
                cellStyle: { "white-space": "normal" },
                min: 0,
                max: 10000,
                width: 190,
              },
              {
                headerName:
                  "Temperature gradient of initial temperature profile [k/100m]",
                templateName: subpage,
                parentField: "pt_vertical",
                field: "pt_vertical_gradiant",
                width: 190,
                min: -10,
                max: 10,
                cellStyle: { "white-space": "normal" },
              },
            ]}
            rowData={dynamic_upload.initialization.pt_vertical}
          />
          <div style={{ marginBottom: 20 }} />

          <ExpertHelpLabel
            label={"Humidity Gradient: Number of Height Levels [-]"}
            helpLink={`${helpLink}/initialization_parameters#q_vertical_gradient`}
          />
          <ExpertVectorInput
            template={subpage}
            mainKey={"q_vertical_lvlsnbr"}
            subkey={"q_vertical"}
            firstRowKey={"q_vertical_gradiant"}
            secondRowKey={"q_vertical_gradiant_level"}
            maxRowsCounter={10}
            constValue={dynamic_upload.initialization.q_vertical_lvlsnbr}
          />
          <ExpertVectorTable
            columnDefs={[
              {
                headerName: "Height from which humidity gradiant is acting [m]",
                templateName: subpage,
                parentField: "q_vertical",
                field: "q_vertical_gradiant_level",
                cellStyle: { "white-space": "normal" },
                min: 0,
                max: 10000,
                width: 190,
              },
              {
                headerName:
                  "Humidity gradient of initial temperature profile [1/100m]",
                templateName: subpage,
                parentField: "q_vertical",
                field: "q_vertical_gradiant",
                width: 190,
                min: -0.01,
                max: 0.01,
                cellStyle: { "white-space": "normal" },
              },
            ]}
            rowData={dynamic_upload.initialization.q_vertical}
          />
          <div style={{ marginBottom: 20 }} />

          <ExpertHelpLabel
            label={"U-Component Gradient: Number of Height Levels [-]"}
            helpLink={`${helpLink}/initialization_parameters#ug_vertical_gradient`}
          />
          <ExpertVectorInput
            template={subpage}
            mainKey={"ug_vertical_lvlsnbr"}
            subkey={"ug_vertical"}
            firstRowKey={"ug_vertical_gradiant"}
            secondRowKey={"ug_vertical_gradiant_level"}
            maxRowsCounter={20}
            constValue={dynamic_upload.initialization.ug_vertical_lvlsnbr}
          />
          <ExpertVectorTable
            columnDefs={[
              {
                headerName:
                  "Height from which gradiant of u component of geostrophic wind is acting [m]",
                templateName: subpage,
                parentField: "ug_vertical",
                field: "ug_vertical_gradiant_level",
                cellStyle: { "white-space": "normal" },
                min: 0,
                max: 10000,
                width: 190,
              },
              {
                headerName:
                  "Gradient of initial profile of u component of geostrophic wind [m/s/100m]",
                templateName: subpage,
                parentField: "ug_vertical",
                field: "ug_vertical_gradiant",
                width: 190,
                min: -10,
                max: 10,
                cellStyle: { "white-space": "normal" },
              },
            ]}
            rowData={dynamic_upload.initialization.ug_vertical}
          />
          <div style={{ marginBottom: 20 }} />

          <ExpertHelpLabel
            label={"V-Component Gradient: Number of Height Levels [-]"}
            helpLink={`${helpLink}/initialization_parameters#ug_vertical_gradient`}
          />
          <ExpertVectorInput
            template={subpage}
            mainKey={"vg_vertical_lvlsnbr"}
            subkey={"vg_vertical"}
            firstRowKey={"vg_vertical_gradiant"}
            secondRowKey={"vg_vertical_gradiant_level"}
            maxRowsCounter={20}
            constValue={dynamic_upload.initialization.vg_vertical_lvlsnbr}
          />
          <ExpertVectorTable
            columnDefs={[
              {
                headerName:
                  "Height from which gradiant of u component of geostrophic wind is acting [m]",
                templateName: subpage,
                parentField: "vg_vertical",
                field: "vg_vertical_gradiant_level",
                cellStyle: { "white-space": "normal" },
                min: 0,
                max: 10000,
                width: 190,
              },
              {
                headerName:
                  "Gradient of initial profile of v component of geostrophic wind [m/s/100m]",
                templateName: subpage,
                parentField: "vg_vertical",
                field: "vg_vertical_gradiant",
                width: 190,
                min: -10,
                max: 10,
                cellStyle: { "white-space": "normal" },
              },
            ]}
            rowData={dynamic_upload.initialization.vg_vertical}
          />
          <FormSettingsSeparator
            style={{ fontSize: "17px" }}
            label={"Boundary Conditions"}
          />
          <ExpertHelpLabel
            label={"Boundary condition bottom: TKE"}
            helpLink={`${helpLink}/initialization_parameters#bc_e_b`}
          />
          <Select
            isSearchable={false}
            styles={selectSetupStyle}
            options={boundaryCounditionsOptions}
            value={{
              value: dynamic_upload.boundary_conditions.bc_e_b,
              label: dynamic_upload.boundary_conditions.bc_e_b,
            }}
            onChange={(e) =>
              dispatch(
                changeExpertInput({
                  page: page,
                  subpage: subpage,
                  key: "boundary_conditions",
                  subkey: "bc_e_b",
                  value: e.value,
                })
              )
            }
          />
          <div style={{ marginTop: 12 }} />
          <ExpertHelpLabel
            label={"Boundary conditions north south"}
            helpLink={`${helpLink}/initialization_parameters#bc_p_b`}
          />
          <Select
            isSearchable={false}
            styles={selectSetupStyle}
            options={boundaryCounditionsOptions}
            value={{
              value: dynamic_upload.boundary_conditions.bc_p_b,
              label: dynamic_upload.boundary_conditions.bc_p_b,
            }}
            onChange={(e) =>
              dispatch(
                changeExpertInput({
                  page: page,
                  subpage: subpage,
                  key: "boundary_conditions",
                  subkey: "bc_p_b",
                  value: e.value,
                })
              )
            }
          />
          <div style={{ marginTop: 12 }} />

          {!dynamic_upload.initialization.neutral && (
            <>
              <ExpertHelpLabel
                label={"Boundary condition bottom: potential temperature"}
                helpLink={`${helpLink}/initialization_parameters#bc_pt_b`}
              />
              <Select
                isSearchable={false}
                styles={selectSetupStyle}
                options={boundaryCounditionsOptions}
                value={{
                  value: dynamic_upload.boundary_conditions.bc_pt_b,
                  label: dynamic_upload.boundary_conditions.bc_pt_b,
                }}
                onChange={(e) =>
                  dispatch(
                    changeExpertInput({
                      page: page,
                      subpage: subpage,
                      key: "boundary_conditions",
                      subkey: "bc_pt_b",
                      value: e.value,
                    })
                  )
                }
              />
              <div style={{ marginTop: 12 }} />
            </>
          )}

          {dynamic_upload.initialization.humidity && (
            <>
              <ExpertHelpLabel
                label={"Boundary condition bottom: water vapor / mixing ratio"}
                helpLink={`${helpLink}/initialization_parameters#bc_q_b`}
              />
              <Select
                isSearchable={false}
                styles={selectSetupStyle}
                options={boundaryCounditionsOptions}
                value={{
                  value: dynamic_upload.boundary_conditions.bc_q_b,
                  label: dynamic_upload.boundary_conditions.bc_q_b,
                }}
                onChange={(e) =>
                  dispatch(
                    changeExpertInput({
                      page: page,
                      subpage: subpage,
                      key: "boundary_conditions",
                      subkey: "bc_q_b",
                      value: e.value,
                    })
                  )
                }
              />
            </>
          )}
          <div style={{ marginTop: 12 }} />
          <ExpertHelpLabel
            label={"Boundary condition bottom: horizontal wind"}
            helpLink={`${helpLink}/initialization_parameters#bc_uv_b`}
          />
          <Select
            isSearchable={false}
            styles={selectSetupStyle}
            options={boundaryCounditionsOptions}
            value={{
              value: dynamic_upload.boundary_conditions.bc_uv_b,
              label: dynamic_upload.boundary_conditions.bc_uv_b,
            }}
            onChange={(e) =>
              dispatch(
                changeExpertInput({
                  page: page,
                  subpage: subpage,
                  key: "boundary_conditions",
                  subkey: "bc_uv_b",
                  value: e.value,
                })
              )
            }
          />
          <div style={{ marginTop: 12 }} />
          <ExpertHelpLabel
            label={"Boundary condition top: pertubation pressure"}
            helpLink={`${helpLink}/initialization_parameters#bc_p_t`}
          />
          <Select
            isSearchable={false}
            styles={selectSetupStyle}
            options={boundaryCounditionsOptions}
            value={{
              value: dynamic_upload.boundary_conditions.bc_p_t,
              label: dynamic_upload.boundary_conditions.bc_p_t,
            }}
            onChange={(e) =>
              dispatch(
                changeExpertInput({
                  page: page,
                  subpage: subpage,
                  key: "boundary_conditions",
                  subkey: "bc_p_t",
                  value: e.value,
                })
              )
            }
          />
          <div style={{ marginTop: 12 }} />
          {!dynamic_upload.initialization.neutral && (
            <>
              <ExpertHelpLabel
                label={"Boundary condition top: potential temperature"}
                helpLink={`${helpLink}/initialization_parameters#bc_pt_t`}
              />
              <Select
                isSearchable={false}
                styles={selectSetupStyle}
                options={[
                  { label: "initial_gradient", value: "initial_gradient" },
                  { label: "neumann", value: "neumann" },
                  { label: "dirichlet", value: "dirichlet" },
                ]}
                value={{
                  value: dynamic_upload.boundary_conditions.bc_pt_t,
                  label: dynamic_upload.boundary_conditions.bc_pt_t,
                }}
                onChange={(e) =>
                  dispatch(
                    changeExpertInput({
                      page: page,
                      subpage: subpage,
                      key: "boundary_conditions",
                      subkey: "bc_pt_t",
                      value: e.value,
                    })
                  )
                }
              />
              <div style={{ marginTop: 12 }} />
            </>
          )}
          {dynamic_upload.initialization.humidity && (
            <>
              <ExpertHelpLabel
                label={"Boundary condition top: water vapor / mixing ratio"}
                helpLink={`${helpLink}/initialization_parameters#bc_q_t`}
              />
              <Select
                isSearchable={false}
                styles={selectSetupStyle}
                options={boundaryCounditionsOptions}
                value={{
                  value: dynamic_upload.boundary_conditions.bc_q_t,
                  label: dynamic_upload.boundary_conditions.bc_q_t,
                }}
                onChange={(e) =>
                  dispatch(
                    changeExpertInput({
                      page: page,
                      subpage: subpage,
                      key: "boundary_conditions",
                      subkey: "bc_q_t",
                      value: e.value,
                    })
                  )
                }
              />
              <div style={{ marginTop: 12 }} />
            </>
          )}
          <ExpertHelpLabel
            label={"Boundary condition top: horizontal wind"}
            helpLink={`${helpLink}/initialization_parameters#bc_uv_t`}
          />
          <Select
            isSearchable={false}
            styles={selectSetupStyle}
            options={boundaryCounditionsOptions}
            value={{
              value: dynamic_upload.boundary_conditions.bc_uv_t,
              label: dynamic_upload.boundary_conditions.bc_uv_t,
            }}
            onChange={(e) =>
              dispatch(
                changeExpertInput({
                  page: page,
                  subpage: subpage,
                  key: "boundary_conditions",
                  subkey: "bc_uv_t",
                  value: e.value,
                })
              )
            }
          />
          <div style={{ marginTop: 12 }} />

          <FormSettingsSeparator
            style={{ fontSize: "17px" }}
            label={"Numerics"}
          />
          <ExpertHelpLabel
            label={"Rayleigh dampening factor [-]"}
            helpLink={`${helpLink}/initialization_parameters#rayleigh_damping_factor`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="numerics"
            subkey="rayleigh_dampening_factor"
            min={0}
            max={1}
            step={0.01}
            constValue={dynamic_upload.numerics.rayleigh_dampening_factor}
          />
          <ExpertHelpLabel
            label={
              "Rayleigh dampening height (0 ist converted to PALM-Default) [m]"
            }
            helpLink={`${helpLink}/initialization_parameters#rayleigh_damping_height`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="numerics"
            subkey="rayleigh_dampening_height"
            min={0}
            max={5000}
            step={1}
            constValue={dynamic_upload.numerics.rayleigh_dampening_height}
          />
          <ExpertHelpLabel
            label={"Poisson equation solver"}
            helpLink={`${helpLink}/initialization_parameters#psolver`}
          />
          <Select
            isSearchable={false}
            styles={selectSetupStyle}
            options={[
              {
                value: "poisfft",
                label: "poisfft",
              },
              {
                value: "multigrid",
                label: "multigrid",
              },
              {
                value: "multigrid_noopt",
                label: "multigrid_noopt",
              },
            ]}
            value={{
              label: dynamic_upload.numerics.psolver,
              value: dynamic_upload.numerics.psolver,
            }}
            onChange={(e) =>
              dispatch(
                changeExpertInput({
                  page: page,
                  subpage: subpage,
                  key: "numerics",
                  subkey: "psolver",
                  value: e.value,
                })
              )
            }
          />
          <div style={{ marginTop: 12 }} />
          <ExpertHelpLabel
            label={"Multigrid solver"}
            helpLink={`${helpLink}/initialization_parameters#cycle_mg`}
          />
          <Select
            isSearchable={false}
            styles={selectSetupStyle}
            options={[
              {
                value: "w",
                label: "w",
              },
              {
                value: "v",
                label: "v",
              },
            ]}
            value={{
              label: dynamic_upload.numerics.cycle_mg,
              value: dynamic_upload.numerics.cycle_mg,
            }}
            onChange={(e) =>
              dispatch(
                changeExpertInput({
                  page: page,
                  subpage: subpage,
                  key: "numerics",
                  subkey: "cycle_mg",
                  value: e.value,
                })
              )
            }
          />
          <div style={{ marginTop: 12 }} />
          <ExpertHelpLabel
            label={"Number of cycles of multigrid solver [-]"}
            helpLink={`${helpLink}/initialization_parameters#mg_cycles`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="numerics"
            subkey="mg_cycles"
            min={1}
            max={6}
            step={1}
            constValue={dynamic_upload.numerics.mg_cycles}
          />

          <FormSettingsSeparator
            style={{ fontSize: "17px" }}
            label={"Physics"}
          />
          <ExpertHelpLabel
            label={
              "Angular velocity of rotating system (Coriolis-Parameter) [1/s]"
            }
            helpLink={`${helpLink}/initialization_parameters#omega`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="physics"
            subkey="omega"
            min={0}
            max={0.0001}
            step={0.00001}
            constValue={dynamic_upload.physics.omega}
          />
          <ExpertHelpLabel
            label={"Latitude of simulation is given by static_driver"}
          />
          <input className="setup-input" value={centerWg.lat} disabled />
          <ExpertHelpLabel
            label={"Longitude of simulation is given by static_driver"}
          />
          <input className="setup-input" value={centerWg.lng} disabled />
        </>
      )}
    </>
  );
};

export default DynamicUpload;
