export const palmTypes = {
  building: { unselected: "#ea5e5e", selected: "#c85e5e" },
  vegetation: { unselected: "#4dd599", selected: "#2dbe7f" },
  pavement: { unselected: "#5f6769", selected: "#8c9597" },
  water: { unselected: "#42dee1", selected: "#19a1a3" },
  treePatches: { unselected: "#007944", selected: "#00b365" },
  street: { unselected: "#e6b300", selected: "#FFA500" },
};

const elevation = {
  default: [
    "#6565B0",
    "#6A6AC4",
    "#6588C8",
    "#6573F8",
    "#65BDF9",
    "#7FF8BB",
    "#BAF661",
    "#F7EB62",
    "#FF7878",
  ],
  greysRev: [
    "#000000",
    "#252525",
    "#525252",
    "#737373",
    "#969696",
    "#BDBDBD",
    "#D9D9D9",
    "#F0F0F0",
    "#FFFFFF",
  ],
  topo: [
    "#4C00FF",
    "#004CFF",
    "#00E5FF",
    "#00FF4D",
    "#4DFF00",
    "#E6FF00",
    "#FFFF00",
    "#FFDE59",
    "#FFE0B3",
  ],
  terrain: [
    "#00A600",
    "#3EBB00",
    "#8BD000",
    "#E6E600",
    "#E8C32E",
    "#EBB25E",
    "#EDB48E",
    "#F0C9C0",
    "#F2F2F2",
  ],
  wikipedia: [
    "#ACD0A5",
    "#A8C68F",
    "#D1D7AB",
    "#EFEBC0",
    "#DED6A3",
    "#CAB982",
    "#B9985A",
    "#AC9A7C",
    "#CAC3B8",
  ],
};

export const results = {
  ylOrRd: [
    "#FFFFCC",
    "#FFEDA0",
    "#FED976",
    "#FEB24C",
    "#FD8D3C",
    "#FC4E2A",
    "#E31A1C",
    "#BD0026",
    "#800026",
  ],
  ylOrBr: [
    "#FFFFE5",
    "#FFF7BC",
    "#FEE391",
    "#FEC44F",
    "#FE9929",
    "#EC7014",
    "#CC4C02",
    "#993404",
    "#662506",
  ],
  ylGnBu: [
    "#FFFFD9",
    "#EDF8B1",
    "#C7E9B4",
    "#7FCDBB",
    "#41B6C4",
    "#1D91C0",
    "#225EA8",
    "#253494",
    "#081D58",
  ],
  ylGn: [
    "#FFFFE5",
    "#F7FCB9",
    "#D9F0A3",
    "#ADDD8E",
    "#78C679",
    "#41AB5D",
    "#238443",
    "#006837",
    "#004529",
  ],
  reds: [
    "#FFF5F0",
    "#FEE0D2",
    "#FCBBA1",
    "#FC9272",
    "#FB6A4A",
    "#EF3B2C",
    "#CB181D",
    "#A50F15",
    "#67000D",
  ],
  rdPu: [
    "#FFF7F3",
    "#FDE0DD",
    "#FCC5C0",
    "#FA9FB5",
    "#F768A1",
    "#DD3497",
    "#AE017E",
    "#7A0177",
    "#49006A",
  ],
  purples: [
    "#FCFBFD",
    "#EFEDF5",
    "#DADAEB",
    "#BCBDDC",
    "#9E9AC8",
    "#807DBA",
    "#6A51A3",
    "#54278F",
    "#3F007D",
  ],
  puRd: [
    "#F7F4F9",
    "#E7E1EF",
    "#D4B9DA",
    "#C994C7",
    "#DF65B0",
    "#E7298A",
    "#CE1256",
    "#980043",
    "#67001F",
  ],
  puBuGn: [
    "#FFF7FB",
    "#ECE2F0",
    "#D0D1E6",
    "#A6BDDB",
    "#67A9CF",
    "#3690C0",
    "#02818A",
    "#016C59",
    "#014636",
  ],
  puBu: [
    "#FFF7FB",
    "#ECE7F2",
    "#D0D1E6",
    "#A6BDDB",
    "#74A9CF",
    "#3690C0",
    "#0570B0",
    "#045A8D",
    "#023858",
  ],
  orRd: [
    "#FFF7EC",
    "#FEE8C8",
    "#FDD49E",
    "#FDBB84",
    "#FC8D59",
    "#EF6548",
    "#D7301F",
    "#B30000",
    "#7F0000",
  ],
  oranges: [
    "#FFF5EB",
    "#FEE6CE",
    "#FDD0A2",
    "#FDAE6B",
    "#FD8D3C",
    "#F16913",
    "#D94801",
    "#A63603",
    "#7F2704",
  ],
  greys: [
    "#FFFFFF",
    "#F0F0F0",
    "#D9D9D9",
    "#BDBDBD",
    "#969696",
    "#737373",
    "#525252",
    "#252525",
    "#000000",
  ],
  greens: [
    "#F7FCF5",
    "#E5F5E0",
    "#C7E9C0",
    "#A1D99B",
    "#74C476",
    "#41AB5D",
    "#238B45",
    "#006D2C",
    "#00441B",
  ],
  gnBu: [
    "#F7FCF0",
    "#E0F3DB",
    "#CCEBC5",
    "#A8DDB5",
    "#7BCCC4",
    "#4EB3D3",
    "#2B8CBE",
    "#0868AC",
    "#084081",
  ],
  buPu: [
    "#F7FCFD",
    "#E0ECF4",
    "#BFD3E6",
    "#9EBCDA",
    "#8C96C6",
    "#8C6BB1",
    "#88419D",
    "#810F7C",
    "#4D004B",
  ],
  blues: [
    "#F7FBFF",
    "#DEEBF7",
    "#C6DBEF",
    "#9ECAE1",
    "#6BAED6",
    "#4292C6",
    "#2171B5",
    "#08519C",
    "#08306B",
  ],
  spectral: [
    "#3288BD",
    "#66C2A5",
    "#ABDDA4",
    "#E6F598",
    "#FFFFBF",
    "#FEE08B",
    "#FDAE61",
    "#F46D43",
    "#D53E4F",
  ],
  rdYlGn: [
    "#D73027",
    "#F46D43",
    "#FDAE61",
    "#FEE08B",
    "#FFFFBF",
    "#D9EF8B",
    "#A6D96A",
    "#66BD63",
    "#1A9850",
  ],
  buYlRd: [
    "#4575B4",
    "#74ADD1",
    "#ABD9E9",
    "#E0F3F8",
    "#FFFFBF",
    "#FEE090",
    "#FDAE61",
    "#F46D43",
    "#D73027",
  ],
  rdGy: [
    "#B2182B",
    "#D6604D",
    "#F4A582",
    "#FDDBC7",
    "#FFFFFF",
    "#E0E0E0",
    "#BABABA",
    "#878787",
    "#4D4D4D",
  ],
  rdBu: [
    "#B2182B",
    "#D6604D",
    "#F4A582",
    "#FDDBC7",
    "#F7F7F7",
    "#D1E5F0",
    "#92C5DE",
    "#4393C3",
    "#2166AC",
  ],
  puOr: [
    "#B35806",
    "#E08214",
    "#FDB863",
    "#FEE0B6",
    "#F7F7F7",
    "#D8DAEB",
    "#B2ABD2",
    "#8073AC",
    "#542788",
  ],
  pRGn: [
    "#762A83",
    "#9970AB",
    "#C2A5CF",
    "#E7D4E8",
    "#F7F7F7",
    "#D9F0D3",
    "#A6DBA0",
    "#5AAE61",
    "#1B7837",
  ],
  piYG: [
    "#C51B7D",
    "#DE77AE",
    "#F1B6DA",
    "#FDE0EF",
    "#F7F7F7",
    "#E6F5D0",
    "#B8E186",
    "#7FBC41",
    "#4D9221",
  ],
  brBG: [
    "#8C510A",
    "#BF812D",
    "#DFC27D",
    "#F6E8C3",
    "#F5F5F5",
    "#C7EAE5",
    "#80CDC1",
    "#35978F",
    "#01665E",
  ],
  viridis: [
    "#440154FF",
    "#472D7BFF",
    "#3B528BFF",
    "#2C728EFF",
    "#21908CFF",
    "#27AD81FF",
    "#5DC863FF",
    "#AADC32FF",
    "#FDE725FF",
  ],
  plasma: [
    "#0D0887FF",
    "#4C02A1FF",
    "#7E03A8FF",
    "#A92395FF",
    "#CC4678FF",
    "#E56B5DFF",
    "#F89441FF",
    "#FDC328FF",
    "#F0F921FF",
  ],
  magma: [
    "#000004FF",
    "#1D1147FF",
    "#51127CFF",
    "#822681FF",
    "#B63679FF",
    "#E65164FF",
    "#FB8861FF",
    "#FEC287FF",
    "#FCFDBFFF",
  ],
  inferno: [
    "#000004FF",
    "#210C4AFF",
    "#56106EFF",
    "#89226AFF",
    "#BB3754FF",
    "#E35932FF",
    "#F98C0AFF",
    "#F9C932FF",
    "#FCFFA4FF",
  ],
  cividis: [
    "#00204DFF",
    "#05366EFF",
    "#414D6BFF",
    "#61646FFF",
    "#7C7B78FF",
    "#9B9477FF",
    "#BCAF6FFF",
    "#E0CB5EFF",
    "#FFEA46FF",
  ],
  rainbow: [
    "#FF0000",
    "#FFAA00",
    "#AAFF00",
    "#00FF00",
    "#00FFAA",
    "#00AAFF",
    "#0000FF",
    "#AA00FF",
    "#FF00AA",
  ],
  heat: [
    "#FF0000",
    "#FF2A00",
    "#FF5500",
    "#FF8000",
    "#FFAA00",
    "#FFD500",
    "#FFFF00",
    "#FFFF40",
    "#FFFFBF",
  ],
  cyanMagenta: [
    "#80FFFF",
    "#9FFFFF",
    "#BFFFFF",
    "#DFFFFF",
    "#FFFFFF",
    "#FFDFFF",
    "#FFBFFF",
    "#FF9FFF",
    "#FF80FF",
  ],
  default: [
    "#6565B0",
    "#6A6AC4",
    "#6588C8",
    "#6573F8",
    "#65BDF9",
    "#7FF8BB",
    "#BAF661",
    "#F7EB62",
    "#FF7878",
  ],
  greysRev: [
    "#000000",
    "#252525",
    "#525252",
    "#737373",
    "#969696",
    "#BDBDBD",
    "#D9D9D9",
    "#F0F0F0",
    "#FFFFFF",
  ],

  topo: [
    "#4C00FF",
    "#004CFF",
    "#00E5FF",
    "#00FF4D",
    "#4DFF00",
    "#E6FF00",
    "#FFFF00",
    "#FFDE59",
    "#FFE0B3",
  ],
  terrain: [
    "#00A600",
    "#3EBB00",
    "#8BD000",
    "#E6E600",
    "#E8C32E",
    "#EBB25E",
    "#EDB48E",
    "#F0C9C0",
    "#F2F2F2",
  ],
  wikipedia: [
    "#ACD0A5",
    "#A8C68F",
    "#D1D7AB",
    "#EFEBC0",
    "#DED6A3",
    "#CAB982",
    "#B9985A",
    "#AC9A7C",
    "#CAC3B8",
  ],
};

const temperatureResults = {
  ...results,
};
const windResults = {
  ...results,
};
const radiation = {
  ...results,
};
const coldAirDrainageResults = {
  ...results,
};
const humidityResuts = {
  ...results,
};
const heatFluxesResults = {
  ...results,
};

const differencePlots = {
  spectral: [
    "#9E0142",
    "#D53E4F",
    "#F46D43",
    "#FDAE61",
    "#FEE08B",
    "#FFFFBF",
    "#E6F598",
    "#ABDDA4",
    "#66C2A5",
    "#3288BD",
    "#5E4FA2",
  ],
  rdYlGn: [
    "#A50026",
    "#D73027",
    "#F46D43",
    "#FDAE61",
    "#FEE08B",
    "#FFFFBF",
    "#D9EF8B",
    "#A6D96A",
    "#66BD63",
    "#1A9850",
    "#006837",
  ],
  buYlRd: [
    "#313695",
    "#4575B4",
    "#74ADD1",
    "#ABD9E9",
    "#E0F3F8",
    "#FFFFBF",
    "#FEE090",
    "#FDAE61",
    "#F46D43",
    "#D73027",
    "#A50026",
  ],
  rdGy: [
    "#67001F",
    "#B2182B",
    "#D6604D",
    "#F4A582",
    "#FDDBC7",
    "#FFFFFF",
    "#E0E0E0",
    "#BABABA",
    "#878787",
    "#4D4D4D",
    "#1A1A1A",
  ],
  rdBu: [
    "#67001F",
    "#B2182B",
    "#D6604D",
    "#F4A582",
    "#FDDBC7",
    "#F7F7F7",
    "#D1E5F0",
    "#92C5DE",
    "#4393C3",
    "#2166AC",
    "#053061",
  ],
  puOr: [
    "#7F3B08",
    "#B35806",
    "#E08214",
    "#FDB863",
    "#FEE0B6",
    "#F7F7F7",
    "#D8DAEB",
    "#B2ABD2",
    "#8073AC",
    "#542788",
    "#2D004B",
  ],
  pRGn: [
    "#40004B",
    "#762A83",
    "#9970AB",
    "#C2A5CF",
    "#E7D4E8",
    "#F7F7F7",
    "#D9F0D3",
    "#A6DBA0",
    "#5AAE61",
    "#1B7837",
    "#00441B",
  ],
  piYG: [
    "#8E0152",
    "#C51B7D",
    "#DE77AE",
    "#F1B6DA",
    "#FDE0EF",
    "#F7F7F7",
    "#E6F5D0",
    "#B8E186",
    "#7FBC41",
    "#4D9221",
    "#276419",
  ],
  brBG: [
    "#543005",
    "#8C510A",
    "#BF812D",
    "#DFC27D",
    "#F6E8C3",
    "#F5F5F5",
    "#C7EAE5",
    "#80CDC1",
    "#35978F",
    "#01665E",
    "#003C30",
  ],
};

const charts = {
  accent: [
    "#7FC97F",
    "#BEAED4",
    "#FDC086",
    "#FFFF99",
    "#386CB0",
    "#F0027F",
    "#BF5B17",
    "#666666",
  ],
  dark2: [
    "#1B9E77",
    "#D95F02",
    "#7570B3",
    "#E7298A",
    "#66A61E",
    "#E6AB02",
    "#A6761D",
    "#666666",
  ],
  paired: [
    "#A6CEE3",
    "#1F78B4",
    "#B2DF8A",
    "#33A02C",
    "#FB9A99",
    "#E31A1C",
    "#FDBF6F",
    "#FF7F00",
  ],
  pastel1: [
    "#FBB4AE",
    "#B3CDE3",
    "#CCEBC5",
    "#DECBE4",
    "#FED9A6",
    "#FFFFCC",
    "#E5D8BD",
    "#FDDAEC",
  ],
  pastel2: [
    "#B3E2CD",
    "#FDCDAC",
    "#CBD5E8",
    "#F4CAE4",
    "#E6F5C9",
    "#FFF2AE",
    "#F1E2CC",
    "#CCCCCC",
  ],
  set1: [
    "#E41A1C",
    "#377EB8",
    "#4DAF4A",
    "#984EA3",
    "#FF7F00",
    "#FFFF33",
    "#A65628",
    "#F781BF",
  ],
  set2: [
    "#66C2A5",
    "#FC8D62",
    "#8DA0CB",
    "#E78AC3",
    "#A6D854",
    "#FFD92F",
    "#E5C494",
    "#B3B3B3",
  ],
  set3: [
    "#8DD3C7",
    "#FFFFB3",
    "#BEBADA",
    "#FB8072",
    "#80B1D3",
    "#FDB462",
    "#B3DE69",
    "#FCCDE5",
  ],
};

export const palettes = {
  defaults: {
    elevation: "default",
    temperatureResults: "buYlRd",
    windResults: "viridis",
    radiation: "rdPu",
    coldAirDrainageResults: "blues",
    humidityResuts: "ylGn",
    heatFluxesResults: "buPu",
    differencePlots: "spectral",
    charts: "set1",
  },
  colors: {
    palmTypes,
    elevation,
    temperatureResults,
    windResults,
    radiation,
    coldAirDrainageResults,
    humidityResuts,
    heatFluxesResults,
    differencePlots,
    charts,
  },
};

// export const colorCategory = {
//   Chemistry: "rdpu",
//   ColdAir: "blues",
//   DensityConcentration: "purples",
//   HeatFlux: "buylrd",
//   MoistureFlux: "brBG",
//   Moisture: "ylgnbu",
//   Radiation: "rdpu",
//   Roughness: "purples",
//   Temperature: "buylrd",
//   Turbulence: "viridis",
//   Vegetation: "greensInv",
//   Wind: "blues",
//   Ratio: "greys",
//   Categories: "rdpu",
//   Palmtypes: "objectTypes",
//   Difference: "rdbu",
//   Biomet: "spectral",
//   Heights: "spectral",
//   Topography: "topo",
//   petCat: "petClassification",
//   utcCat: "utcClassification",
//   percCat: "percCat",
// };

export const customCategoryColors = {
  ylOrRd: [
    "#FFFFCC",
    "#FFEDA0",
    "#FED976",
    "#FEB24C",
    "#FD8D3C",
    "#FC4E2A",
    "#E31A1C",
    "#BD0026",
    "#800026",
  ],
  ylOrBr: [
    "#FFFFE5",
    "#FFF7BC",
    "#FEE391",
    "#FEC44F",
    "#FE9929",
    "#EC7014",
    "#CC4C02",
    "#993404",
    "#662506",
  ],
  ylGnBu: [
    "#FFFFD9",
    "#EDF8B1",
    "#C7E9B4",
    "#7FCDBB",
    "#41B6C4",
    "#1D91C0",
    "#225EA8",
    "#253494",
    "#081D58",
  ],
  ylGn: [
    "#FFFFE5",
    "#F7FCB9",
    "#D9F0A3",
    "#ADDD8E",
    "#78C679",
    "#41AB5D",
    "#238443",
    "#006837",
    "#004529",
  ],
  reds: [
    "#FFF5F0",
    "#FEE0D2",
    "#FCBBA1",
    "#FC9272",
    "#FB6A4A",
    "#EF3B2C",
    "#CB181D",
    "#A50F15",
    "#67000D",
  ],
  rdPu: [
    "#FFF7F3",
    "#FDE0DD",
    "#FCC5C0",
    "#FA9FB5",
    "#F768A1",
    "#DD3497",
    "#AE017E",
    "#7A0177",
    "#49006A",
  ],
  purples: [
    "#FCFBFD",
    "#EFEDF5",
    "#DADAEB",
    "#BCBDDC",
    "#9E9AC8",
    "#807DBA",
    "#6A51A3",
    "#54278F",
    "#3F007D",
  ],
  puRd: [
    "#F7F4F9",
    "#E7E1EF",
    "#D4B9DA",
    "#C994C7",
    "#DF65B0",
    "#E7298A",
    "#CE1256",
    "#980043",
    "#67001F",
  ],
  puBuGn: [
    "#FFF7FB",
    "#ECE2F0",
    "#D0D1E6",
    "#A6BDDB",
    "#67A9CF",
    "#3690C0",
    "#02818A",
    "#016C59",
    "#014636",
  ],
  puBu: [
    "#FFF7FB",
    "#ECE7F2",
    "#D0D1E6",
    "#A6BDDB",
    "#74A9CF",
    "#3690C0",
    "#0570B0",
    "#045A8D",
    "#023858",
  ],
  orRd: [
    "#FFF7EC",
    "#FEE8C8",
    "#FDD49E",
    "#FDBB84",
    "#FC8D59",
    "#EF6548",
    "#D7301F",
    "#B30000",
    "#7F0000",
  ],
  oranges: [
    "#FFF5EB",
    "#FEE6CE",
    "#FDD0A2",
    "#FDAE6B",
    "#FD8D3C",
    "#F16913",
    "#D94801",
    "#A63603",
    "#7F2704",
  ],
  greys: [
    "#FFFFFF",
    "#F0F0F0",
    "#D9D9D9",
    "#BDBDBD",
    "#969696",
    "#737373",
    "#525252",
    "#252525",
    "#000000",
  ],
  greens: [
    "#F7FCF5",
    "#E5F5E0",
    "#C7E9C0",
    "#A1D99B",
    "#74C476",
    "#41AB5D",
    "#238B45",
    "#006D2C",
    "#00441B",
  ],
  gnBu: [
    "#F7FCF0",
    "#E0F3DB",
    "#CCEBC5",
    "#A8DDB5",
    "#7BCCC4",
    "#4EB3D3",
    "#2B8CBE",
    "#0868AC",
    "#084081",
  ],
  buPu: [
    "#F7FCFD",
    "#E0ECF4",
    "#BFD3E6",
    "#9EBCDA",
    "#8C96C6",
    "#8C6BB1",
    "#88419D",
    "#810F7C",
    "#4D004B",
  ],
  blues: [
    "#F7FBFF",
    "#DEEBF7",
    "#C6DBEF",
    "#9ECAE1",
    "#6BAED6",
    "#4292C6",
    "#2171B5",
    "#08519C",
    "#08306B",
  ],
  spectral: [
    "#3288BD",
    "#66C2A5",
    "#ABDDA4",
    "#E6F598",
    "#FFFFBF",
    "#FEE08B",
    "#FDAE61",
    "#F46D43",
    "#D53E4F",
  ],
  rdYlGn: [
    "#D73027",
    "#F46D43",
    "#FDAE61",
    "#FEE08B",
    "#FFFFBF",
    "#D9EF8B",
    "#A6D96A",
    "#66BD63",
    "#1A9850",
  ],
  buYlRd: [
    "#4575B4",
    "#74ADD1",
    "#ABD9E9",
    "#E0F3F8",
    "#FFFFBF",
    "#FEE090",
    "#FDAE61",
    "#F46D43",
    "#D73027",
  ],
  rdGy: [
    "#B2182B",
    "#D6604D",
    "#F4A582",
    "#FDDBC7",
    "#FFFFFF",
    "#E0E0E0",
    "#BABABA",
    "#878787",
    "#4D4D4D",
  ],
  rdBu: [
    "#B2182B",
    "#D6604D",
    "#F4A582",
    "#FDDBC7",
    "#F7F7F7",
    "#D1E5F0",
    "#92C5DE",
    "#4393C3",
    "#2166AC",
  ],
  puOr: [
    "#B35806",
    "#E08214",
    "#FDB863",
    "#FEE0B6",
    "#F7F7F7",
    "#D8DAEB",
    "#B2ABD2",
    "#8073AC",
    "#542788",
  ],
  pRGn: [
    "#762A83",
    "#9970AB",
    "#C2A5CF",
    "#E7D4E8",
    "#F7F7F7",
    "#D9F0D3",
    "#A6DBA0",
    "#5AAE61",
    "#1B7837",
  ],
  piYG: [
    "#C51B7D",
    "#DE77AE",
    "#F1B6DA",
    "#FDE0EF",
    "#F7F7F7",
    "#E6F5D0",
    "#B8E186",
    "#7FBC41",
    "#4D9221",
  ],
  brBG: [
    "#8C510A",
    "#BF812D",
    "#DFC27D",
    "#F6E8C3",
    "#F5F5F5",
    "#C7EAE5",
    "#80CDC1",
    "#35978F",
    "#01665E",
  ],
  accent: [
    "#7FC97F",
    "#BEAED4",
    "#FDC086",
    "#FFFF99",
    "#386CB0",
    "#F0027F",
    "#BF5B17",
    "#666666",
  ],
  dark2: [
    "#1B9E77",
    "#D95F02",
    "#7570B3",
    "#E7298A",
    "#66A61E",
    "#E6AB02",
    "#A6761D",
    "#666666",
  ],
  paired: [
    "#A6CEE3",
    "#1F78B4",
    "#B2DF8A",
    "#33A02C",
    "#FB9A99",
    "#E31A1C",
    "#FDBF6F",
    "#FF7F00",
  ],
  pastel1: [
    "#FBB4AE",
    "#B3CDE3",
    "#CCEBC5",
    "#DECBE4",
    "#FED9A6",
    "#FFFFCC",
    "#E5D8BD",
    "#FDDAEC",
  ],
  pastel2: [
    "#B3E2CD",
    "#FDCDAC",
    "#CBD5E8",
    "#F4CAE4",
    "#E6F5C9",
    "#FFF2AE",
    "#F1E2CC",
    "#CCCCCC",
  ],
  set1: [
    "#E41A1C",
    "#377EB8",
    "#4DAF4A",
    "#984EA3",
    "#FF7F00",
    "#FFFF33",
    "#A65628",
    "#F781BF",
  ],
  set2: [
    "#66C2A5",
    "#FC8D62",
    "#8DA0CB",
    "#E78AC3",
    "#A6D854",
    "#FFD92F",
    "#E5C494",
    "#B3B3B3",
  ],
  set3: [
    "#8DD3C7",
    "#FFFFB3",
    "#BEBADA",
    "#FB8072",
    "#80B1D3",
    "#FDB462",
    "#B3DE69",
    "#FCCDE5",
  ],
  category: [
    "#0070c0",
    "#00b0f0",
    "#00b050",
    "#92d050",
    "#ffff00",
    "#ffbf00",
    "#ff0000",
    "#c00000",
    "#6f30a0",
  ],
};

export const defaultWindStreamColors = [
  "orrd",
  "pubu",
  "bupu",
  "oranges",
  "bugn",
  "ylorbr",
  "ylgn",
  "reds",
  "rdpu",
  "greens",
  "ylgnbu",
  "purples",
  "gnbu",
  "greys",
  "ylorrd",
  "purd",
  "blues",
  "pubugn",
  "viridis",
  "spectral",
  "rdylgn",
  "rdbu",
  "piyg",
  "prgn",
  "rdylbu",
  "brbg",
  "rdgy",
  "puor",
  "set2",
  "accent",
  "set1",
  "set3",
  "dark2",
  "paired",
  "pastel2",
  "pastel1",
];
