import React from "react";
import FormFileBrowser from "./FormFileBrowser";
import { XCircle, CheckCircle } from "react-feather";

function FormFileBrowserWithValidation(props) {
  return (
    <div className="text-input-container">
      <FormFileBrowser
        name={props.name}
        label={props.label}
        fileName={props.fileName}
        accept={props.accept}
        disabled={props.disabled}
        isClearable
        onChange={props.onChange}
        clearUploadedFile={props.clearUploadedFile}
      />
      {props.fileName !== "" && (
        <div className="validation-container">
          {props.error === "" ? (
            <>
              <CheckCircle width="17" stroke="green" strokeWidth="2" />
              <div className="validation-wrapper"></div>
            </>
          ) : (
            <>
              <XCircle width="50" stroke="#fc3939e3" strokeWidth="2" />
              <div className="validation-wrapper">{props.error}</div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default FormFileBrowserWithValidation;
