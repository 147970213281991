import { apiRequest } from "./axiosApi";

const apiUrl = process.env.REACT_APP_FRONT_SERVICE_API;

export async function requestRasterizationPreviewAsync(caseId, payload) {
  await apiRequest({
    method: "post",
    url: `${apiUrl}/projects/cases/${caseId}/raster-preview`,
    data: payload,
  });
}

export async function requestStaticDriverAsync(caseId, payload) {
  await apiRequest({
    method: "post",
    url: `${apiUrl}/projects/cases/${caseId}/static-driver`,
    data: payload,
  });
}
