import React from "react";
import Select, { components } from "react-select";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { setRasterizationPriority } from "../../../redux/actions/rasterAreaActions";
import { rasterizationPriorityOptions } from "./RasterizationSettingsConst";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function RasterPriority() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const rasterizationPriorityOrderedList = useSelector(
    (state) => state.rasterArea.rasterizationPriorityOrderedList
  );

  const onChange = (selectedOptions) => {
    dispatch(setRasterizationPriority(selectedOptions));
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(
      rasterizationPriorityOrderedList,
      oldIndex,
      newIndex
    );
    dispatch(setRasterizationPriority(newValue));
  };

  return (
    <SortableSelect
      useDragHandle
      axis="xy"
      onSortEnd={onSortEnd}
      distance={4}
      getHelperDimensions={({ node }) => node.getBoundingClientRect()}
      isMulti
      options={rasterizationPriorityOptions.map((x) => {
        return { label: t(`${x}`), value: x };
      })}
      value={rasterizationPriorityOrderedList.map((x) => {
        return { label: t(`${x}`), value: x };
      })}
      onChange={onChange}
      components={{
        MultiValue: SortableMultiValue,
        MultiValueLabel: SortableMultiValueLabel,
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }}
      closeMenuOnSelect={false}
      isClearable={false}
      styles={styles}
      openMenuOnClick={false}
      classNamePrefix="select"
    />
  );
}

const SortableMultiValue = SortableElement((props) => {
  const onMouseDown = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { ...props.innerProps, onMouseDown };
  return <components.MultiValue {...props} innerProps={innerProps} />;
});

const SortableMultiValueLabel = SortableHandle((props) => (
  <components.MultiValueLabel {...props} />
));

const SortableSelect = SortableContainer(Select);

function arrayMove(array, from, to) {
  const slicedArray = array.slice();
  slicedArray.splice(
    to < 0 ? array.length + to : to,
    0,
    slicedArray.splice(from, 1)[0]
  );
  return slicedArray;
}

const styles = {
  multiValue: (provided) => ({
    ...provided,
    zIndex: "10000",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    fontSize: "12px",
    color: "black",
    cursor: "pointer",
    paddingLeft: "6px",
    paddingRight: "6px",
  }),
  valueContainer: (provided) => ({
    ...provided,
    fontSize: "12px",
    border: "0px solid",
    padding: "0px",
    paddingRight: "3px",
  }),
  control: (provided) => ({
    ...provided,
    border: "0px solid",
    margin: "2px 0px 2px 0px",
    padding: "4px 0px 0px 0px",
    boxShadow: "0px",
  }),
  multiValueRemove: (base) => {
    return { ...base, display: "none" };
  },
};
