import React from "react";
import { Loader } from "../../../Components/Loader";
import "./CheckFilesLoader.scss";

function CheckFilesLoader(props) {
  return (
    <div className="new-proj-loader-wrapper">
      <div>
        <Loader size={"check-file"} />
        <div className="check-status-container">
          {props.steps.map((s) => {
            return (
              <div
                className={`check-status ${
                  s.active ? "check-status-active" : ""
                }`}
              >
                {s.text}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default CheckFilesLoader;
