import { apiRequest, multiFormRequest } from "./axiosApi";

const apiUrl = process.env.REACT_APP_FRONT_SERVICE_API;

export async function getUserSettingsAsync() {
  const response = await apiRequest({
    method: "get",
    url: `${apiUrl}/settings`,
  });
  return response.data;
}
export async function saveUserSettingsAsync(settings) {
  const formData = new FormData();
  formData.append("PreferredLanguage", settings.preferredLanguage || "");

  if (settings.picture) {
    formData.append("Picture", settings.picture);
  }
  formData.append("PictureUrl", settings.pictureUrl || "");
  formData.append(
    "VisualizationPresets.InputPalmTypesPalette",
    (settings.visualizationPresets.inputPalmTypesPalette &&
      JSON.stringify(settings.visualizationPresets.inputPalmTypesPalette)) ||
      ""
  );
  formData.append(
    "VisualizationPresets.InputElevationPalette",
    settings.visualizationPresets.inputElevationPalette || ""
  );
  formData.append(
    "VisualizationPresets.SimulationTemperaturePalette",
    settings.visualizationPresets.simulationTemperaturePalette || ""
  );
  formData.append(
    "VisualizationPresets.SimulationWindPalette",
    settings.visualizationPresets.simulationWindPalette || ""
  );
  formData.append(
    "VisualizationPresets.SimulationColdAirDrainagePalette",
    settings.visualizationPresets.simulationColdAirDrainagePalette || ""
  );
  formData.append(
    "VisualizationPresets.SimulationRadiationPalette",
    settings.visualizationPresets.simulationRadiationPalette || ""
  );
  formData.append(
    "VisualizationPresets.SimulationHumidityPalette",
    settings.visualizationPresets.simulationHumidityPalette || ""
  );
  formData.append(
    "VisualizationPresets.SimulationHeatFluxesPalette",
    settings.visualizationPresets.simulationHeatFluxesPalette || ""
  );
  formData.append(
    "VisualizationPresets.SimulationDifferencesPalette",
    settings.visualizationPresets.simulationDifferencesPalette || ""
  );
  formData.append(
    "VisualizationPresets.SimulationChartPalette",
    settings.visualizationPresets.simulationChartPalette || ""
  );

  formData.append("wsMaps", JSON.stringify(settings.wsMaps));
  return await multiFormRequest({
    method: "post",
    url: `${apiUrl}/settings`,
    data: formData,
  });
}
