import React from "react";
import { NavLink, Link } from "react-router-dom";
import Icon from "../../Common/Icon";
import "./index.scss";

const SidebarItem = (props) => {
  return props.disabled ? (
    <span
      className={`sidebar-item${
        props.class ? ` ${props.class}` : ""
      } sidebar-item-disabled`}
    >
      {props.icon && <Icon className="sidebar-item__icon" icon={props.icon} />}
      <div>{props.label}</div>
    </span>
  ) : (
    <NavLink
      className={`sidebar-item${props.class ? ` ${props.class}` : ""}`}
      exact
      to={props.route}
    >
      {props.icon && <Icon className="sidebar-item__icon" icon={props.icon} />}
      <div>{props.label}</div>
    </NavLink>
  );
};
export const SidebarLink = (props) => {
  return props.disabled ? (
    <span className={`sidebar-item sidebar-item-disabled`}>
      {props.icon && <Icon className="sidebar-item__icon" icon={props.icon} />}
      <div>{props.label}</div>
    </span>
  ) : (
    <Link
      className={`sidebar-item sidebar-link`}
      to={{ pathname: props.route }}
      target="_blank"
      rel="noopener noreferrer"
    >
      {props.icon && <Icon className="sidebar-item__icon" icon={props.icon} />}
      <div>{props.label}</div>
    </Link>
  );
};

export default SidebarItem;
