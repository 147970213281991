import React from "react";
import Results from "../Results/Results";
import { withRouter } from "react-router-dom";
import "./CaseResults.scss";

const CaseResults = () => {
  // const dispatch = useDispatch();
  // const { id, caseId } = useParams();
  // const projectId = useSelector((state) => state.projects?.loadedProject?.id);
  // const caseDto = useSelector((state) =>
  //   state.projects?.loadedProject?.caseDetailsDto.find((c) => c.id === caseId)
  // );

  return <Results />;
};

export default withRouter(CaseResults);
