import React from "react";
import Switch from "react-switch";
import { Copy } from "react-feather";
import { objectTypeIndex } from "../consts/consts.js";
import { switchColor } from "../../../common/Colors";
import { useTranslation } from "react-i18next";
import "./Form.scss";

const IdLine = (props) => {
  const { t } = useTranslation();

  return (
    <div className="id-line">
      <label className="id-label">
        <div
          style={{ marginRight: "12px" }}
          className={`${
            props.switchActiveColor === 9 ? "poi-copy-disabled" : ""
          }`}
          title={t("AssignData")}
        >
          <Copy
            onClick={props.toggleCopyPropsMode}
            size={"12px"}
            style={{ cursor: "pointer" }}
          />
        </div>
        {props.labelId}
      </label>
      <label className="switch-label">{props.labelSwitch}</label>

      {props.singleTreeActive && (
        <button
          className="tree-coord-btn"
          onClick={props.onTreeCoordIconClick}
          title={t(`GoToTreeList`)}
        />
      )}
      {props.emissionActive && (
        <button
          className="tree-coord-btn home-coord-btn"
          onClick={props.onEmissionIconClick}
          title={t(`GoToEmissionList`)}
        />
      )}
      {props.mapPoiActive && (
        <button
          className="tree-coord-btn map-poi-coord-btn"
          onClick={props.onMapPoiIconClick}
          title={t(`GoToEmissionList`)}
        />
      )}
      <Switch
        onChange={props.onChange}
        checked={props.checked}
        checkedIcon={false}
        uncheckedIcon={false}
        onColor={
          props.switchActiveColor === objectTypeIndex.building
            ? switchColor.building
            : props.switchActiveColor === objectTypeIndex.vegetation
            ? switchColor.vegetation
            : props.switchActiveColor === objectTypeIndex.pavement
            ? switchColor.pavement
            : props.switchActiveColor === objectTypeIndex.water
            ? switchColor.water
            : props.switchActiveColor === objectTypeIndex.treePatches
            ? switchColor.treePatches
            : props.switchActiveColor === objectTypeIndex.singleTree
            ? switchColor.singleTree
            : props.switchActiveColor === objectTypeIndex.street
            ? switchColor.street
            : props.switchActiveColor === objectTypeIndex.emission
            ? switchColor.emission
            : props.switchActiveColor === objectTypeIndex.mapPointOfInterest
            ? switchColor.mapPoi
            : switchColor.default
        }
        offColor="#888"
        height={13}
        width={30}
        handleDiameter={9}
      />
    </div>
  );
};
export { IdLine };
